import React from "react";
import { createRoot } from "react-dom/client";
import App from "./App";
import { RecoilRoot } from 'recoil';
import reportWebVitals from "./reportWebVitals";
import { BrowserRouter } from "react-router-dom";
import {
  CssBaseline,
  ThemeProvider,
  createTheme,
  StyledEngineProvider,
} from "@mui/material";
import { AuthProvider } from "oidc-react"; 
import oidcConfig from "./keycloakConfig"; 
import GlobalStyles from "./global";

const muiTheme = createTheme({
  palette: {
    secondary: {
      light: "rgba(0,229,164,1)",
      main: "rgba(0,200,144,1)",
      dark: "rgba(0,170,122,1)",
    },
  },
});

const container = document.getElementById("root");
const root = createRoot(container!);

root.render(
  <AuthProvider {...oidcConfig}>
    <BrowserRouter>
      <StyledEngineProvider injectFirst>
        <ThemeProvider theme={muiTheme}>
          <CssBaseline />
          <GlobalStyles />
          <RecoilRoot>
            <App />
          </RecoilRoot>
        </ThemeProvider>
      </StyledEngineProvider>
    </BrowserRouter>
  </AuthProvider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
