import React, { createContext } from "react";
import { Outlet, useLocation } from "react-router-dom";

// Define the shape of the context data for better TypeScript support
interface OverlayContextType {
  closeOverlay: () => void;
  selectedL1: string | null;
  setSelectedL1: React.Dispatch<React.SetStateAction<string | null>>;
  selectedL2: string | null;
  setSelectedL2: React.Dispatch<React.SetStateAction<string | null>>;
  selectedL3: string | null;
  setSelectedL3: React.Dispatch<React.SetStateAction<string | null>>;
  selectedL4: string | null;
  setSelectedL4: React.Dispatch<React.SetStateAction<string | null>>;
}

// Create the context with a default value
export const OverlayContext = createContext<OverlayContextType>({
  closeOverlay: () => {},
  selectedL1: null,
  setSelectedL1: () => {},
  selectedL2: null,
  setSelectedL2: () => {},
  selectedL3: null,
  setSelectedL3: () => {},
  selectedL4: null,
  setSelectedL4: () => {},
});

import { FrameBoardMain, MainBoardSubMenuButton, MainBoardSubMenuButtonContainer } from "../styled-components";

const Board27001ContestoLeadershipOrganizzazione: React.FC = () => {
  const location = useLocation();

  const isActive = (path: string) => {
    return location.pathname === "/Cybersecurity/GestioneCompliance" && path === "CampoApplicazione";
  };

  return (
    <FrameBoardMain>
      <MainBoardSubMenuButtonContainer>
        {/* <MainBoardSubMenuButton to="/Cybersecurity/GestioneCompliance/RuoliCompliance" className={isActive("RuoliCompliance") ? "active" : ""}>Ruoli Compliance</MainBoardSubMenuButton> */}
        <MainBoardSubMenuButton to="/Cybersecurity/GestioneCompliance/CampoApplicazione" className={isActive("CampoApplicazione") ? "active" : ""}>
          Campo di applicazione
        </MainBoardSubMenuButton>
        <MainBoardSubMenuButton to="/Cybersecurity/GestioneCompliance/Adempimenti">Adempimenti</MainBoardSubMenuButton>
        <MainBoardSubMenuButton to="/Cybersecurity/GestioneCompliance/ProcessiCompliance">Processi Compliance</MainBoardSubMenuButton>
        {/* <MainBoardSubMenuButton to="/Cybersecurity/GestioneCompliance/ProcedureCompliance">Procedure Compliance</MainBoardSubMenuButton> */}
        <MainBoardSubMenuButton to="/Cybersecurity/GestioneCompliance/RuoliCompliance">Ruoli Compliance</MainBoardSubMenuButton>
        <MainBoardSubMenuButton to="/Cybersecurity/GestioneCompliance/TopManagement">Top Management</MainBoardSubMenuButton>
      </MainBoardSubMenuButtonContainer>

      <Outlet />
    </FrameBoardMain>
  );
};

export default Board27001ContestoLeadershipOrganizzazione;
