import React from "react";

import {
  ButtonIsoActive,
  ButtonIsoActiveText,
  ButtonIsoInactive,
  ButtonIsoInactiveText,
  ButtonNotification,
  ButtonUser,
  FrameButtonIso,
  FrameTopGradient,
  FrameUserSearch,
  FrameUserSearchAuto,
  IcoUserNotification,
} from "./styled-components";

const IsoMenu: React.FC = () => {
  return (
    <FrameTopGradient>
      <FrameButtonIso>
        <ButtonIsoActive>
          <ButtonIsoActiveText>Cybersecurity</ButtonIsoActiveText>
        </ButtonIsoActive>
        <ButtonIsoInactive>
          <ButtonIsoInactiveText>Privacy</ButtonIsoInactiveText>
        </ButtonIsoInactive>
        <ButtonIsoInactive>
          <ButtonIsoInactiveText>Qualità</ButtonIsoInactiveText>
        </ButtonIsoInactive>
        <ButtonIsoInactive>
          <ButtonIsoInactiveText>Ambiente</ButtonIsoInactiveText>
        </ButtonIsoInactive>
        <ButtonIsoInactive>
          <ButtonIsoInactiveText>Lavoro</ButtonIsoInactiveText>
        </ButtonIsoInactive>
        <ButtonIsoInactive>
          <ButtonIsoInactiveText>Anticorruzione</ButtonIsoInactiveText>
        </ButtonIsoInactive>

        <ButtonIsoInactive>
          <ButtonIsoInactiveText>SGI</ButtonIsoInactiveText>
        </ButtonIsoInactive>
      </FrameButtonIso>
      <FrameUserSearch>
        <FrameUserSearchAuto>
          <ButtonNotification>
            <IcoUserNotification alt="" src="/notification-off.svg" />
          </ButtonNotification>
          <ButtonUser>
            <IcoUserNotification alt="" src="/user.svg" />
          </ButtonUser>
        </FrameUserSearchAuto>
      </FrameUserSearch>
    </FrameTopGradient>
  );
};

export default IsoMenu;
