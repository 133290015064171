import dayjs from "dayjs";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useRecoilState } from "recoil";

import SchedaGenerale from "./cybersecurity-board-org-org-L4-Show-sub/organigramma-L4-Show-1-SchedaGenerale";
import Organizzazione from "./cybersecurity-board-org-org-L4-Show-sub/organigramma-L4-Show-2-Organizzazione";
import { default as Infrastruttura, default as Parti } from "./cybersecurity-board-org-org-L4-Show-sub/organigramma-L4-Show-3-Infrastruttura";
import Processi from "./cybersecurity-board-org-org-L4-Show-sub/organigramma-L4-Show-4-Processi";
import Compliance from "./cybersecurity-board-org-org-L4-Show-sub/organigramma-L4-Show-6-GestioneCompliance";
import Documentazione from "./cybersecurity-board-org-org-L4-Show-sub/organigramma-L4-Show-7-Documentazione";

import { ButtonGrey, ButtonText } from "../../styled-components";

import { URL_DirectorList, useToken } from "../../../../API";
import {
  showL4ComponenteOrganizzativa,
  showL4DocPath,
  showL4Name,
  showL4RuoliEnte,
  showL4SuperioreDiretto,
  showL4Surname,
  showL4Title,
  showL4UnitaOperative,
  showL4birthDate,
  showL4birthPlace,
  showL4domicileAddress,
  showL4fiscalCode,
  showL4gender,
  showL4idDocumentNumber,
  showL4personalEmail,
  showL4privatePhone,
  showL4residenceAddress,
  showL4workEmail,
  showL4workPhone,
} from "./L4-Show-recoil";
import {
  Backdrop,
  BackdropTransparent,
  CloseMessageButton,
  CreaSubMenuButtonMultiple,
  FrameTopGradient100,
  MessageContainer,
  MessageboxText,
  OverlayComponentBackgroundAdd1,
  OverlayComponentWhiteAdd1,
  OverlayComponentWhiteButtonFrameAdd1,
  OverlayComponentWhiteTitleAdd1,
  SubMenuButtonContainerOrganigrammaMultipleShort,
} from "./cybersecurity-board-organizzazione-organigramma-styled-components";
import { useOverlayControlL4Show } from "./cybersecurity-board-organizzazione-persone";

const YourAddL1Component = () => {
  const [surname, setSurname] = useRecoilState(showL4Surname);
  const [name, setName] = useRecoilState(showL4Name);
  const [birthDate, setBirthDate] = useRecoilState(showL4birthDate);
  const [birthPlace, setBirthPlace] = useRecoilState(showL4birthPlace);
  const [gender, setGender] = useRecoilState(showL4gender);
  const [fiscalCode, setFiscalCode] = useRecoilState(showL4fiscalCode);
  const [residenceAddress, setResidenceAddress] = useRecoilState(showL4residenceAddress);
  const [domicileAddress, setDomicileAddress] = useRecoilState(showL4domicileAddress);
  const [workEmail, setWorkEmail] = useRecoilState(showL4workEmail);
  const [personalEmail, setPersonalEmail] = useRecoilState(showL4personalEmail);
  const [workPhone, setWorkPhone] = useRecoilState(showL4workPhone);
  const [privatePhone, setPrivatePhone] = useRecoilState(showL4privatePhone);
  const [docPath, setDocPath] = useRecoilState(showL4DocPath);
  const [Title, setTitle] = useRecoilState(showL4Title);

  const [idDocumentNumber, setIdDocumentNumber] = useRecoilState(showL4idDocumentNumber);
  const [ruoliEnte, setRuoliEnte] = useRecoilState(showL4RuoliEnte);
  const [unitaOperative, setUnitaOperative] = useRecoilState(showL4UnitaOperative);
  const [superioreDiretto, setSuperioreDiretto] = useRecoilState(showL4SuperioreDiretto);
  const [componenteOrganizzativa, setComponenteOrganizzativa] = useRecoilState(showL4ComponenteOrganizzativa);

  const { toggleOverlayL4, isVisibleL4, isAnimatingL4, closeOverlayL4 } = useOverlayControlL4Show("/Cybersecurity/Organizzazione/Persone/VisualizzaL4/1");

  const navigate = useNavigate();

  const token = useToken();
  const [data, setData] = useState<DirectorDataItem[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const [isMessageBoxVisible, setIsMessageBoxVisible] = useState(false);
  const [message, setMessage] = useState("");

  type DirectorApiContentItem = {
    id: string;
    alias: string;
  };

  type DirectorDataItem = {
    director_id: string;
    director_name: string;
  };

  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true);
      try {
        if (!token) throw new Error("User is not authenticated");

        const response = await fetch(URL_DirectorList, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });

        if (!response.ok) {
          throw new Error(`API request failed: ${response.statusText}`);
        }

        const responseData = await response.json();
        console.log("Response Data:", responseData);

        const transformedData: DirectorDataItem[] = responseData.map((item: DirectorApiContentItem) => {
          return {
            director_id: item.id,
            director_name: item.alias,
          };
        });

        console.log("Transformed Data:", transformedData);
        setData(transformedData);
      } catch (err) {
        setError(err instanceof Error ? err.message : "An unknown error occurred");
        setIsMessageBoxVisible(true);
      } finally {
        setIsLoading(false);
      }
    };

    fetchData();
  }, [token]);

  const directorNameToId = new Map(data.map((item) => [item.director_name, item.director_id]));

  const handleCloseAndNavigate = () => {
    setIsMessageBoxVisible(false);
  };

  const handleClose = () => {
    closeOverlayL4();

    setSurname("");
    setName("");
    setBirthDate(dayjs());
    setBirthPlace("");
    setGender("");
    setFiscalCode("");
    setResidenceAddress("");
    setDomicileAddress("");
    setWorkEmail("");
    setPersonalEmail("");
    setWorkPhone("");
    setPrivatePhone("");
    setTitle("");
  };

  const [activeComponent, setActiveComponent] = useState("SchedaGenerale");

  const changeActiveComponent = (componentName: string) => {
    setActiveComponent(componentName);
  };

  const renderActiveComponent = () => {
    switch (activeComponent) {
      case "SchedaGenerale":
        return <SchedaGenerale />;
      case "Organizzazione":
        return <Organizzazione />;
      case "Infrastruttura":
        return <Infrastruttura />;
      case "ProcessiGenerali":
        return <Processi />;
      case "PartiInteressate":
        return <Parti />;
      case "SistemaCompliance":
        return <Compliance />;
      case "Documentazione":
        return <Documentazione />;
      default:
        return <SchedaGenerale />;
    }
  };

  return (
    <OverlayComponentBackgroundAdd1>
      {isLoading && (
        <BackdropTransparent>
          <MessageContainer>
            <MessageboxText>Loading...</MessageboxText>
          </MessageContainer>
        </BackdropTransparent>
      )}

      {isMessageBoxVisible && (
        <Backdrop>
          <MessageContainer>
            <p>
              <MessageboxText>{message}</MessageboxText>
            </p>
            <CloseMessageButton onClick={handleCloseAndNavigate}>
              <MessageboxText>Close</MessageboxText>
            </CloseMessageButton>
          </MessageContainer>
        </Backdrop>
      )}

      {error && (
        <Backdrop>
          <MessageContainer>
            <p>
              <MessageboxText>{error}</MessageboxText>
            </p>
            <CloseMessageButton
              onClick={() => {
                setIsMessageBoxVisible(false);
                setError(null);
                closeOverlayL4();
              }}
            >
              <MessageboxText>Close</MessageboxText>
            </CloseMessageButton>
          </MessageContainer>
        </Backdrop>
      )}

      <FrameTopGradient100 />
      <OverlayComponentWhiteTitleAdd1>SCHEDA PERSONALE DI {Title.toUpperCase()}</OverlayComponentWhiteTitleAdd1>
      <OverlayComponentWhiteAdd1>
        <SubMenuButtonContainerOrganigrammaMultipleShort>
          <CreaSubMenuButtonMultiple onClick={() => changeActiveComponent("SchedaGenerale")} to="/Cybersecurity/Organizzazione/Persone/VisualizzaL4/1">
            Scheda generale
          </CreaSubMenuButtonMultiple>
          <CreaSubMenuButtonMultiple onClick={() => changeActiveComponent("Organizzazione")} to="/Cybersecurity/Organizzazione/Persone/VisualizzaL4/2">
            Organizzazione
          </CreaSubMenuButtonMultiple>
          <CreaSubMenuButtonMultiple onClick={() => changeActiveComponent("Infrastruttura")} to="/Cybersecurity/Organizzazione/Persone/VisualizzaL4/3">
            Infrastruttura
          </CreaSubMenuButtonMultiple>
          <CreaSubMenuButtonMultiple onClick={() => changeActiveComponent("ProcessiGenerali")} to="/Cybersecurity/Organizzazione/Persone/VisualizzaL4/4">
            Processi generali
          </CreaSubMenuButtonMultiple>
          {/* <CreaSubMenuButtonMultiple
            onClick={() => changeActiveComponent("PartiInteressate")}
            to="/Cybersecurity/Organizzazione/UnitaOperative/CreaL2/5"
          >
            Parti interessate
          </CreaSubMenuButtonMultiple> */}
          <CreaSubMenuButtonMultiple onClick={() => changeActiveComponent("SistemaCompliance")} to="/Cybersecurity/Organizzazione/Persone/VisualizzaL4/6">
            Sistema gestione Compliance
          </CreaSubMenuButtonMultiple>
          <CreaSubMenuButtonMultiple onClick={() => changeActiveComponent("Documentazione")} to="/Cybersecurity/Organizzazione/Persone/VisualizzaL4/7">
            Documentazione
          </CreaSubMenuButtonMultiple>
        </SubMenuButtonContainerOrganigrammaMultipleShort>

        {renderActiveComponent()}
      </OverlayComponentWhiteAdd1>

      <OverlayComponentWhiteButtonFrameAdd1>
        <ButtonGrey onClick={handleClose}>
          <ButtonText>Chiudi</ButtonText>
        </ButtonGrey>
      </OverlayComponentWhiteButtonFrameAdd1>
    </OverlayComponentBackgroundAdd1>
  );
};

export default YourAddL1Component;
