import dayjs from "dayjs";
import React, { Suspense, useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useRecoilState } from "recoil";
import {
  isLoadingRec,
  isMessageBoxVisibleClosingRec,
  isMessageBoxVisibleRec,
  reFetchIndicatorBusinessProcesses,
  SelectedL2ID,
  setErrorRec,
  setMessageRec
} from "../../../../recoil";
import { OverlayContext } from "../cybersecurity-board-contestoleadership-organizzazione";

import SchedaGenerale from "./cybersecurity-board-processi-business-Show-sub/processigenerali-businessprocessi-Show-1-SchedaGenerale";

import Compliance from "./cybersecurity-board-processi-business-Show-sub/processigenerali-businessprocessi-Show-6-GestioneCompliance";
import Documentazione, { useInitiateUpload } from "./cybersecurity-board-processi-business-Show-sub/processigenerali-businessprocessi-Show-7-Doc";

import { ButtonGrey, ButtonText } from "../../styled-components";

import {
  Backdrop,
  BackdropTransparent,
  CloseMessageButton,
  CreaSubMenuButtonMultiple,
  FrameTopGradient100,
  MessageboxText,
  MessageContainer,
  OverlayComponentBackgroundAdd1,
  OverlayComponentWhiteAdd1,
  OverlayComponentWhiteButtonFrameAdd1,
  OverlayComponentWhiteTitleAdd1,
  SubMenuButtonContainerOrganigrammaMultipleShort,
} from "../../cybersecurity/cybersecurity-board-contestoleadership-organizzazione-sub/cybersecurity-board-organizzazione-organigramma-styled-components";

import {
  addProcessSelectedCheckboxesProcessiState,
  Processi_2_isDropdownOpenLV1,
  ProcessiAttachedFilesInfo,
  ProcessiComplianceDate,
  ProcessiComplianceResponsible,
  ProcessiComplianceResponsibleID,
  ProcessiConfirmedLV1List,
  ProcessiDescription,
  ProcessiDocumentsAttached,
  ProcessiDocumentsPath,
  ProcessiID,
  ProcessiIsDropdownOpenDirectorsCompliance,
  ProcessiKind,
  ProcessiName,
  ProcessiObjective,
  ProcessiOrganizzazioneSelectedL1,
  ProcessiOrganizzazioneSelectedL1ID,
  ProcessiSelectionBodyState,
  ProcessSelectionData,
  SingleProcessDataState
} from "./Processi-Add-recoil";

import { URL_Process, URL_ProcessesSelected, useApi, useToken } from "../../../../API";
import { PATH_Processi } from "../../../../docPaths";

export const useHandleSave = () => {
  const [isLoading, setIsLoading] = useRecoilState(isLoadingRec);
  const [error, setError] = useRecoilState(setErrorRec);
  const [isMessageBoxVisible, setIsMessageBoxVisible] = useRecoilState(isMessageBoxVisibleRec);
  const [message, setMessage] = useRecoilState(setMessageRec);

  const { closeOverlay } = useContext(OverlayContext);
  const { post, patch } = useApi();

  const [attachedFilesInfo, setAttachedFilesInfo] = useRecoilState(ProcessiAttachedFilesInfo);
  const [documentsAttached, setDocumentsAttached] = useRecoilState(ProcessiDocumentsAttached);
  const [docPath, setDocPath] = useRecoilState(ProcessiDocumentsPath);
  const [ID, setID] = useRecoilState(ProcessiID);
  const [name, setName] = useRecoilState(ProcessiName);
  const [complianceResponsible, setComplianceResponsible] = useRecoilState(ProcessiComplianceResponsible);
  const [complianceDate, setComplianceDate] = useRecoilState(ProcessiComplianceDate);
  const [description, setDescription] = useRecoilState(ProcessiDescription);
  const [kind, setKind] = useRecoilState(ProcessiKind);
  const [objective, setObjective] = useRecoilState(ProcessiObjective);
  const [organizzazioneDirettore, setOrganizzazioneDirettore] = useRecoilState(ProcessiComplianceResponsible);
  const [organizzazioneDirettoreID, setOrganizzazioneDirettoreID] = useRecoilState(ProcessiComplianceResponsibleID);
  const [isDropdownOpenDirectors, setIsDropdownOpenDirectors] = useRecoilState(ProcessiIsDropdownOpenDirectorsCompliance);
  const initiateUpload = useInitiateUpload();
  const { fetchSelectionData, updateSelection } = useHandleUpdateSelection();
  const [reFetchIndicator, setRefetchIndicator] = useRecoilState(reFetchIndicatorBusinessProcesses);
  const [selectedCheckboxes, setSelectedCheckboxes] = useRecoilState(addProcessSelectedCheckboxesProcessiState);

  const handleSave = async (processID: string) => {
    if (!kind || !name) {
      setMessage("Si prega di compilare tutti i campi obbligatori: denominazione e categoria");
      setIsMessageBoxVisible(true);
      return;
    }

    const newData = {
      id: processID,
      name: name,
      kind: kind,
      objective: objective,
      description: description,
      has_compliance_responsible: organizzazioneDirettoreID,
      compliance_responsible_assignment_start: complianceDate,
      compliance_responsible: null,
      documents_folder_link: PATH_Processi,
    };

    try {
      setIsLoading(true);
      console.log(JSON.stringify(newData));
      const responseData = await patch(URL_Process, newData);

      initiateUpload();

      const fetchedData = await fetchSelectionData();
      if (fetchedData) {
        await updateSelection(fetchedData);
        console.log(JSON.stringify(fetchedData));
      }

      console.log("Process modified successfully:", responseData);

      setMessage("Processo modificato con successo");
      setIsMessageBoxVisible(true);

      setName("");
      setKind("");
      setObjective("");
      setDescription("");
      setOrganizzazioneDirettore("");
      setOrganizzazioneDirettoreID("");

      setRefetchIndicator((prev) => prev + 1);
    } catch (error) {
      console.error("Error creating Process:", error);
      setMessage(`Error creating Process: ${error instanceof Error ? error.message : "Unknown error"}`);
      setIsMessageBoxVisible(true);
    } finally {
      setIsLoading(false);
    }
  };

  return { handleSave };
};

export const useHandleUpdateSelection = () => {
  const [isLoading, setIsLoading] = useRecoilState(isLoadingRec);
  const [error, setError] = useRecoilState(setErrorRec);
  const [isMessageBoxVisible, setIsMessageBoxVisible] = useRecoilState(isMessageBoxVisibleRec);
  const [message, setMessage] = useRecoilState(setMessageRec);
  const [data, setData] = useRecoilState(ProcessiSelectionBodyState);
  const [reFetchIndicator, setRefetchIndicator] = useRecoilState(reFetchIndicatorBusinessProcesses);
  const [selectedCheckboxes, setSelectedCheckboxes] = useRecoilState(addProcessSelectedCheckboxesProcessiState);
  const [ID, setID] = useRecoilState(ProcessiID);
  const [isMessageBoxVisibleClosing, setIsMessageBoxVisibleClosing] = useRecoilState(isMessageBoxVisibleClosingRec);

  const { get, patch } = useApi();

  const fetchSelectionData = async (): Promise<ProcessSelectionData[] | null> => {
    setIsLoading(true);
    try {
      const response = await get(`${URL_ProcessesSelected}${ID}`);
      console.log(`Fetched selection data: `, response);
      setData(response);

      return response;
    } catch (error) {
      console.error("Error fetching selection data:", error);
      setMessage(`Error fetching data: ${error instanceof Error ? error.message : "Unknown error"}`);
      setIsMessageBoxVisibleClosing(true);
    } finally {
      setIsLoading(false);
    }
    return null;
  };

  const updateSelection = async (dataToUpdate: ProcessSelectionData[]) => {
    setIsLoading(true);
    try {
      const updatedItems = dataToUpdate.map((item: ProcessSelectionData) => ({
        ...item,
        selected: selectedCheckboxes.includes(item.content.has_compliance_process),
      }));

      console.log(`Updating selection with data: `, updatedItems);
      const response = await patch(`${URL_ProcessesSelected}${ID}`, updatedItems);

      setRefetchIndicator((prev) => prev + 1);
    } catch (error) {
      console.error("Error updating selection:", error);
      setMessage(`Error updating selection: ${error instanceof Error ? error.message : "Unknown error"}`);
      setIsMessageBoxVisibleClosing(true);
    } finally {
      setIsLoading(false);
    }
  };

  return { fetchSelectionData, updateSelection, data, isLoading, message };
};

const MainComponent = () => {
  const token = useToken();

  const [attachedFilesInfo, setAttachedFilesInfo] = useRecoilState(ProcessiAttachedFilesInfo);
  const [complianceDate, setComplianceDate] = useRecoilState(ProcessiComplianceDate);
  const [documentsAttached, setDocumentsAttached] = useRecoilState(ProcessiDocumentsAttached);
  const [documentsPath, setDocumentsPath] = useRecoilState(ProcessiDocumentsPath);
  const [ID, setID] = useRecoilState(ProcessiID);
  const [name, setName] = useRecoilState(ProcessiName);
  const [kind, setKind] = useRecoilState(ProcessiKind);
  const [organizzazioneSelectedL1, setOrganizzazioneSelectedL1] = useRecoilState(ProcessiOrganizzazioneSelectedL1);
  const [organizzazioneSelectedL1ID, setOrganizzazioneSelectedL1ID] = useRecoilState(ProcessiOrganizzazioneSelectedL1ID);
  const [confirmedLV1List, setConfirmedLV1List] = useRecoilState(ProcessiConfirmedLV1List);
  const [organizzazioneDirettore, setOrganizzazioneDirettore] = useRecoilState(ProcessiComplianceResponsible);
  const [organizzazioneDirettoreID, setOrganizzazioneDirettoreID] = useRecoilState(ProcessiComplianceResponsibleID);
  const [isDropdownOpenDirectors, setIsDropdownOpenDirectors] = useRecoilState(ProcessiIsDropdownOpenDirectorsCompliance);
  const [isDropdownOpenLV1, setIsDropdownOpenLV1] = useRecoilState(Processi_2_isDropdownOpenLV1);

  const [selectedCheckboxes, setSelectedCheckboxes] = useRecoilState(addProcessSelectedCheckboxesProcessiState);

  const [L2ID, setL2ID] = useRecoilState(SelectedL2ID);

  const { handleSave } = useHandleSave();

  const { closeOverlay } = useContext(OverlayContext);

  const navigate = useNavigate();

  const [isLoading, setIsLoading] = useRecoilState(isLoadingRec);
  const [error, setError] = useRecoilState(setErrorRec);
  const [isMessageBoxVisible, setIsMessageBoxVisible] = useRecoilState(isMessageBoxVisibleRec);
  const [isMessageBoxVisibleClosing, setIsMessageBoxVisibleClosing] = useRecoilState(isMessageBoxVisibleClosingRec);
  const [message, setMessage] = useRecoilState(setMessageRec);

  const handleCloseAndNavigate = () => {
    setIsMessageBoxVisible(false);
    setIsMessageBoxVisibleClosing(false);
    closeOverlay();
    setSelectedCheckboxes([]);
  };

  const handleCloseMessage = () => {
    setIsMessageBoxVisibleClosing(false);
    setIsMessageBoxVisible(false);
    setSelectedCheckboxes([]);
  };

  const handleSaveAndUpload = async () => {
    await handleSave(ID);
  };

  const [objective, setObjective] = useRecoilState(ProcessiObjective);
  const [description, setDescription] = useRecoilState(ProcessiDescription);

  const handleClose = () => {
    closeOverlay();
    setSelectedCheckboxes([]);
    setAttachedFilesInfo([]);
    setDocumentsAttached([]);
    setDocumentsPath("");

    setName("");
    setKind("");
    setObjective("");
    setDescription("");
    setComplianceDate(null);
    setOrganizzazioneDirettoreID("");
    setOrganizzazioneDirettore("");
  };

  const [activeComponent, setActiveComponent] = useState("SchedaGenerale");

  const changeActiveComponent = (componentName: string) => {
    setActiveComponent(componentName);
  };

  const renderActiveComponent = () => {
    switch (activeComponent) {
      case "SchedaGenerale":
        return <SchedaGenerale />;

      case "SistemaCompliance":
        return <Compliance />;
      case "Documentazione":
        return <Documentazione />;
      default:
        return <SchedaGenerale />;
    }
  };
  setID(L2ID);

  const [processData, setProcessData] = useRecoilState(SingleProcessDataState);
  const [reFetchIndicator, setRefetchIndicator] = useRecoilState(reFetchIndicatorBusinessProcesses);

  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true);
      try {
        if (!token) throw new Error("User is not authenticated");

        const response = await fetch(`${URL_Process}/${L2ID}`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });

        if (!response.ok) {
          throw new Error(`API request failed: ${response.statusText}`);
        }

        const responseData = await response.json();
        console.log("Response Data:", responseData);

        setProcessData({
          id: responseData.id,
          name: responseData.name,
          kind: responseData.kind,
          objective: responseData.objective,
          description: responseData.description,
          has_compliance_responsible: responseData.has_compliance_responsible,
          compliance_responsible_assignment_start: dayjs(responseData.compliance_responsible_assignment_start),
        });

        console.log("Transformed Data:", responseData);

        setName(responseData.name);
        setKind(responseData.kind);
        setComplianceDate(dayjs(responseData.compliance_responsible_assignment_start));
        setOrganizzazioneDirettoreID(responseData.has_compliance_responsible);
        setObjective(responseData.objective);
        setDescription(responseData.description);
      } catch (err) {
        setError(err instanceof Error ? err.message : "An unknown error occurred");
        setIsMessageBoxVisible(true);
      } finally {
        setIsLoading(false);
      }
    };

    fetchData();
  }, [token, reFetchIndicator]);

  return (
    <OverlayComponentBackgroundAdd1>
      {isLoading && (
        <BackdropTransparent>
          <MessageContainer>
            <MessageboxText>Loading...</MessageboxText>
          </MessageContainer>
        </BackdropTransparent>
      )}

      {isMessageBoxVisible && (
        <Backdrop>
          <MessageContainer>
            <p>
              <MessageboxText>{message}</MessageboxText>
            </p>
            <CloseMessageButton onClick={handleCloseAndNavigate}>
              <MessageboxText>Close</MessageboxText>
            </CloseMessageButton>
          </MessageContainer>
        </Backdrop>
      )}

      {isMessageBoxVisibleClosing && (
        <Backdrop>
          <MessageContainer>
            <p>
              <MessageboxText>{message}</MessageboxText>
            </p>
            <CloseMessageButton onClick={handleCloseMessage}>
              <MessageboxText>Close</MessageboxText>
            </CloseMessageButton>
          </MessageContainer>
        </Backdrop>
      )}

      {error && (
        <Backdrop>
          <MessageContainer>
            <p>
              <MessageboxText>{error}</MessageboxText>
            </p>
            <CloseMessageButton
              onClick={() => {
                setIsMessageBoxVisible(false);
                setError(null);
                closeOverlay();
              }}
            >
              <MessageboxText>Close</MessageboxText>
            </CloseMessageButton>
          </MessageContainer>
        </Backdrop>
      )}

      <FrameTopGradient100 />
      <OverlayComponentWhiteTitleAdd1>VISUALIZZA PROCESSO {name}</OverlayComponentWhiteTitleAdd1>
      <OverlayComponentWhiteAdd1>
        <SubMenuButtonContainerOrganigrammaMultipleShort>
          <CreaSubMenuButtonMultiple onClick={() => changeActiveComponent("SchedaGenerale")} to="/Cybersecurity/ProcessiGenerali/BusinessProcessi/VisualizzaBusinessProcessi/1">
            Scheda generale
          </CreaSubMenuButtonMultiple>

          {/* <CreaSubMenuButtonMultiple
            onClick={() => changeActiveComponent("Organizzazione")}
            to="/Cybersecurity/ProcessiGenerali/BusinessProcessi/VisualizzaBusinessProcessi/2"
          >
            Organizzazione
          </CreaSubMenuButtonMultiple> */}
          {/* <CreaSubMenuButtonMultiple
            onClick={() => changeActiveComponent("Infrastruttura")}
            to="/Cybersecurity/ProcessiGenerali/BusinessProcessi/VisualizzaBusinessProcessi/3"
          >
            Infrastruttura
          </CreaSubMenuButtonMultiple>
          <CreaSubMenuButtonMultiple
            onClick={() => changeActiveComponent("ProcessiGenerali")}
            to="/Cybersecurity/ProcessiGenerali/BusinessProcessi/VisualizzaBusinessProcessi/4"
          >
            Processi generali
          </CreaSubMenuButtonMultiple> */}
          {/* <CreaSubMenuButtonMultiple
            onClick={() => changeActiveComponent("PartiInteressate")}
            to="/Cybersecurity/Infrastruttura/UnitaOperative/CreaL2/5"
          >
            Parti interessate
          </CreaSubMenuButtonMultiple> */}
          <CreaSubMenuButtonMultiple onClick={() => changeActiveComponent("SistemaCompliance")} to="/Cybersecurity/ProcessiGenerali/BusinessProcessi/VisualizzaBusinessProcessi/6">
            Sistema gestione Compliance
          </CreaSubMenuButtonMultiple>
          <CreaSubMenuButtonMultiple onClick={() => changeActiveComponent("Documentazione")} to="/Cybersecurity/ProcessiGenerali/BusinessProcessi/VisualizzaBusinessProcessi/7">
            Documentazione
          </CreaSubMenuButtonMultiple>
        </SubMenuButtonContainerOrganigrammaMultipleShort>
        <Suspense fallback={<div>Loading...</div>}>{renderActiveComponent()}</Suspense>
      </OverlayComponentWhiteAdd1>

      <OverlayComponentWhiteButtonFrameAdd1>
        <ButtonGrey onClick={handleClose}>
          <ButtonText>Chiudi</ButtonText>
        </ButtonGrey>
        {/* <ButtonGrey onClick={handleSaveAndUpload}>
          <ButtonText>Salva</ButtonText>
        </ButtonGrey> */}
      </OverlayComponentWhiteButtonFrameAdd1>
    </OverlayComponentBackgroundAdd1>
  );
};

export default MainComponent;
