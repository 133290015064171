import InfoIcon from "@mui/icons-material/InfoOutlined";
import Tooltip from "@mui/material/Tooltip";
import React from "react";
import { useRecoilState } from "recoil";
import { TAB_DESCRIPTION_GLOBAL, TAB_DESCRIPTION_L1_1 } from "../../tabs-description";
import {
  CreaContainerColumn,
  DescriptionContainerCreaMultiple,
  DescriptionContainerCreaText,
  FlexContainerCrea,
  IconContainer,
  IconSquare,
  InputContainer,
  InputDescriptionText,
  InputTextbox,
  OverlayComponentWhiteAdd1Multiple
} from "../cybersecurity-board-organizzazione-organigramma-styled-components";
import {
  addL1SchedaGeneraleFax,
  addL1SchedaGeneraleIndirizzo,
  addL1SchedaGeneraleNomeAbbreviato,
  addL1SchedaGeneraleNomeCompleto,
  addL1SchedaGeneralePEC,
  addL1SchedaGeneraleTelefono,
} from "../L1-Add-Recoil";

const MainComponent = () => {
  const [nomeCompleto, setNomeCompleto] = useRecoilState(addL1SchedaGeneraleNomeCompleto);
  const [nomeAbbreviato, setNomeAbbreviato] = useRecoilState(addL1SchedaGeneraleNomeAbbreviato);
  const [Indirizzo, setIndirizzo] = useRecoilState(addL1SchedaGeneraleIndirizzo);
  const [Telefono, setTelefono] = useRecoilState(addL1SchedaGeneraleTelefono);
  const [Fax, setFax] = useRecoilState(addL1SchedaGeneraleFax);
  const [PEC, setPEC] = useRecoilState(addL1SchedaGeneralePEC);

  return (
    <OverlayComponentWhiteAdd1Multiple>
      <FlexContainerCrea>
        <CreaContainerColumn>
          <InputContainer>
            <InputDescriptionText>
              Nome completo
              <Tooltip title="Campo obbligatorio">
                <InfoIcon
                  style={{
                    fontSize: "14px",
                    color: "#04446c",
                    cursor: "pointer",
                    marginLeft: "2px",
                  }}
                />
              </Tooltip>
            </InputDescriptionText>
            <InputTextbox placeholder="" value={nomeCompleto} onChange={(e) => setNomeCompleto(e.target.value)} />
            <IconContainer />
          </InputContainer>

          <InputContainer>
            <InputDescriptionText>
              Nome abbreviato
              <Tooltip title="Campo obbligatorio">
                <InfoIcon
                  style={{
                    fontSize: "14px",
                    color: "#04446c",
                    cursor: "pointer",
                    marginLeft: "2px",
                  }}
                />
              </Tooltip>
            </InputDescriptionText>
            <InputTextbox placeholder="" value={nomeAbbreviato} onChange={(e) => setNomeAbbreviato(e.target.value)} />
            <IconContainer />
          </InputContainer>

          <InputContainer>
            <InputDescriptionText>Indirizzo sede</InputDescriptionText>

            <InputTextbox placeholder="" value={Indirizzo} onChange={(e) => setIndirizzo(e.target.value)} />

            <IconContainer />
          </InputContainer>
        </CreaContainerColumn>

        <CreaContainerColumn>
          <InputContainer>
            <InputDescriptionText>Telefono</InputDescriptionText>
            <InputTextbox placeholder="" value={Telefono} onChange={(e) => setTelefono(e.target.value)} />
            <IconContainer />
          </InputContainer>

          <InputContainer>
            <InputDescriptionText>Fax</InputDescriptionText>
            <InputTextbox placeholder="" value={Fax} onChange={(e) => setFax(e.target.value)} />
            <IconContainer />
          </InputContainer>
          <InputContainer>
            <InputDescriptionText>Email</InputDescriptionText>
            <InputTextbox placeholder="" value={PEC} onChange={(e) => setPEC(e.target.value)} />
            <IconContainer />
          </InputContainer>
        </CreaContainerColumn>
      </FlexContainerCrea>
      <DescriptionContainerCreaMultiple>
        <IconSquare src="/crea-L1-schedagenerale.svg" alt="Description Icon" />
        <DescriptionContainerCreaText>{TAB_DESCRIPTION_L1_1 ? TAB_DESCRIPTION_L1_1 : TAB_DESCRIPTION_GLOBAL}</DescriptionContainerCreaText>
      </DescriptionContainerCreaMultiple>
    </OverlayComponentWhiteAdd1Multiple>
  );
};

export default MainComponent;
