import React, { Suspense, useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useRecoilState } from "recoil";
import { isLoadingRec, isMessageBoxVisibleRec, reFetchIndicatorComplianceProcesses, setErrorRec, setMessageRec } from "../../../../recoil";
import { OverlayContext } from "../cybersecurity-board-contestoleadership-organizzazione";

import SchedaGenerale from "./cybersecurity-board-compliance-processi-Add-sub/gestionecompliance-processicompliance-Add-1-SchedaGenerale";

import Compliance from "./cybersecurity-board-compliance-processi-Add-sub/gestionecompliance-processicompliance-Add-6-GestioneCompliance";
import Documentazione, { useInitiateUploadProcessiCompliance } from "./cybersecurity-board-compliance-processi-Add-sub/gestionecompliance-processicompliance-Add-7-Documentazione";

import { ButtonGrey, ButtonText } from "../../styled-components";

import {
  Backdrop,
  BackdropTransparent,
  CloseMessageButton,
  CreaSubMenuButtonMultiple,
  FrameTopGradient100,
  MessageContainer,
  MessageboxText,
  OverlayComponentBackgroundAdd1,
  OverlayComponentWhiteAdd1,
  OverlayComponentWhiteButtonFrameAdd1,
  OverlayComponentWhiteTitleAdd1,
  SubMenuButtonContainerOrganigrammaMultipleShort,
} from "../../cybersecurity/cybersecurity-board-contestoleadership-organizzazione-sub/cybersecurity-board-organizzazione-organigramma-styled-components";

import {
  ProcessiComplianceAttachedFilesInfo,
  ProcessiComplianceComplianceDate,
  ProcessiComplianceComplianceResponsible,
  ProcessiComplianceComplianceResponsibleID,
  ProcessiComplianceConfirmedLV1List,
  ProcessiComplianceDescription,
  ProcessiComplianceDocumentsAttached,
  ProcessiComplianceDocumentsPath,
  ProcessiComplianceID,
  ProcessiComplianceIsDropdownOpenDirectorsCompliance,
  ProcessiComplianceKind,
  ProcessiComplianceName,
  ProcessiComplianceNota,
  ProcessiComplianceObjective,
  ProcessiComplianceOrganizzazioneSelectedL1,
  ProcessiComplianceOrganizzazioneSelectedL1ID,
  ProcessiComplianceRifNorm,
  ProcessiCompliance_2_isDropdownOpenLV1,
} from "./ProcessiCompliance-Add-recoil";

import { URL_ComplianceProcess, useApi, useToken } from "../../../../API";
import { PATH_ProcessiCompliance } from "../../../../docPaths";

function generateUUID(): string {
  return "xxxxxxxx-xxxx-xxxx-yxxx-xxxxxxxxxxxx".replace(/[xy]/g, (c) => {
    const r = (Math.random() * 16) | 0;
    const v = c === "x" ? r : (r & 0x3) | 0x8;
    return v.toString(16);
  });
}

function useGenerateUUID(): string {
  const [ProcessoID, setProcessoID] = useRecoilState<string>(ProcessiComplianceID);

  useEffect(() => {
    const newUUID = generateUUID();
    setProcessoID(newUUID);
    console.log("New place ID: ", newUUID);
  }, [setProcessoID]);

  return ProcessoID;
}

export const useHandleSave = () => {
  const [isLoading, setIsLoading] = useRecoilState(isLoadingRec);
  const [error, setError] = useRecoilState(setErrorRec);
  const [isMessageBoxVisible, setIsMessageBoxVisible] = useRecoilState(isMessageBoxVisibleRec);
  const [message, setMessage] = useRecoilState(setMessageRec);

  const { closeOverlay } = useContext(OverlayContext);
  const { post } = useApi();

  const [attachedFilesInfo, setAttachedFilesInfo] = useRecoilState(ProcessiComplianceAttachedFilesInfo);
  const [documentsAttached, setDocumentsAttached] = useRecoilState(ProcessiComplianceDocumentsAttached);
  const [docPath, setDocPath] = useRecoilState(ProcessiComplianceDocumentsPath);
  const [ID, setID] = useRecoilState(ProcessiComplianceID);
  const [name, setName] = useRecoilState(ProcessiComplianceName);
  const [complianceResponsible, setComplianceResponsible] = useRecoilState(ProcessiComplianceComplianceResponsible);
  const [complianceDate, setComplianceDate] = useRecoilState(ProcessiComplianceComplianceDate);
  const [description, setDescription] = useRecoilState(ProcessiComplianceDescription);
  const [kind, setKind] = useRecoilState(ProcessiComplianceKind);
  const [objective, setObjective] = useRecoilState(ProcessiComplianceObjective);
  const [rifNorm, setrifNorm] = useRecoilState(ProcessiComplianceRifNorm);
  const [nota, setNota] = useRecoilState(ProcessiComplianceNota);
  const initiateUpload = useInitiateUploadProcessiCompliance();
  const [organizzazioneDirettore, setOrganizzazioneDirettore] = useRecoilState(ProcessiComplianceComplianceResponsible);
  const [organizzazioneDirettoreID, setOrganizzazioneDirettoreID] = useRecoilState(ProcessiComplianceComplianceResponsibleID);
  const [isDropdownOpenDirectors, setIsDropdownOpenDirectors] = useRecoilState(ProcessiComplianceIsDropdownOpenDirectorsCompliance);
  const [reFetchIndicator, setRefetchIndicator] = useRecoilState(reFetchIndicatorComplianceProcesses);

  const handleSave = async () => {
    if (!kind || !name) {
      setMessage("Si prega di compilare tutti i campi obbligatori: denominazione e categoria");
      setIsMessageBoxVisible(true);
      return;
    }

    const newData = {
      id: ID,
      name: name,
      kind: kind,
      norm_ref: rifNorm,
      note: nota,
      description: description,
      has_compliance_responsible: organizzazioneDirettoreID,
      compliance_responsible_assignment_start: complianceDate,
      documents_folder_link: PATH_ProcessiCompliance,
    };

    try {
      setIsLoading(true);
      console.log(JSON.stringify(newData));
      const responseData = await post(URL_ComplianceProcess, newData);
      initiateUpload();
      console.log("Compliance process created successfully:", responseData);
      setMessage("Compliance process created successfully!");
      setRefetchIndicator((prev) => prev + 1);
      setIsMessageBoxVisible(true);
      setName("");
      setKind("");
      setrifNorm("");
      setNota("");
      setDescription("");
      setOrganizzazioneDirettoreID("");
      setOrganizzazioneDirettore("");
    } catch (error) {
      console.error("Error creating Compliance process:", error);
      setMessage(`Error creating Compliance process: ${error instanceof Error ? error.message : "Unknown error"}`);
      setIsMessageBoxVisible(true);
    } finally {
      setIsLoading(false);
    }
  };

  return { handleSave };
};

const MainComponent = () => {
  const token = useToken();

  const [attachedFilesInfo, setAttachedFilesInfo] = useRecoilState(ProcessiComplianceAttachedFilesInfo);
  const [complianceDate, setComplianceDate] = useRecoilState(ProcessiComplianceComplianceDate);
  const [documentsAttached, setDocumentsAttached] = useRecoilState(ProcessiComplianceDocumentsAttached);
  const [documentsPath, setDocumentsPath] = useRecoilState(ProcessiComplianceDocumentsPath);
  const [ID, setID] = useRecoilState(ProcessiComplianceID);
  const [name, setName] = useRecoilState(ProcessiComplianceName);
  const [organizzazioneSelectedL1, setOrganizzazioneSelectedL1] = useRecoilState(ProcessiComplianceOrganizzazioneSelectedL1);
  const [organizzazioneSelectedL1ID, setOrganizzazioneSelectedL1ID] = useRecoilState(ProcessiComplianceOrganizzazioneSelectedL1ID);
  const [confirmedLV1List, setConfirmedLV1List] = useRecoilState(ProcessiComplianceConfirmedLV1List);
  const [organizzazioneDirettore, setOrganizzazioneDirettore] = useRecoilState(ProcessiComplianceComplianceResponsible);
  const [organizzazioneDirettoreID, setOrganizzazioneDirettoreID] = useRecoilState(ProcessiComplianceComplianceResponsibleID);
  const [isDropdownOpenDirectors, setIsDropdownOpenDirectors] = useRecoilState(ProcessiComplianceIsDropdownOpenDirectorsCompliance);
  const [isDropdownOpenLV1, setIsDropdownOpenLV1] = useRecoilState(ProcessiCompliance_2_isDropdownOpenLV1);

  const { handleSave } = useHandleSave();
  const initiateUpload = useInitiateUploadProcessiCompliance();

  const { closeOverlay } = useContext(OverlayContext);

  const navigate = useNavigate();

  const [isLoading, setIsLoading] = useRecoilState(isLoadingRec);
  const [error, setError] = useRecoilState(setErrorRec);
  const [isMessageBoxVisible, setIsMessageBoxVisible] = useRecoilState(isMessageBoxVisibleRec);
  const [message, setMessage] = useRecoilState(setMessageRec);
  const [reFetchIndicator, setRefetchIndicator] = useRecoilState(reFetchIndicatorComplianceProcesses);

  useGenerateUUID();

  const handleCloseAndNavigate = () => {
    setIsMessageBoxVisible(false);
    closeOverlay();
  };

  const handleSaveAndUpload = async () => {
    await handleSave();
    initiateUpload();
  };

  const handleClose = () => {
    closeOverlay();

    setAttachedFilesInfo([]);
    setDocumentsAttached([]);
    setDocumentsPath("");
  };

  const [activeComponent, setActiveComponent] = useState("SchedaGenerale");

  const changeActiveComponent = (componentName: string) => {
    setActiveComponent(componentName);
  };

  const renderActiveComponent = () => {
    switch (activeComponent) {
      case "SchedaGenerale":
        return <SchedaGenerale />;

      case "SistemaCompliance":
        return <Compliance />;
      case "Documentazione":
        return <Documentazione />;
      default:
        return <SchedaGenerale />;
    }
  };

  return (
    <OverlayComponentBackgroundAdd1>
      {isLoading && (
        <BackdropTransparent>
          <MessageContainer>
            <MessageboxText>Loading...</MessageboxText>
          </MessageContainer>
        </BackdropTransparent>
      )}

      {isMessageBoxVisible && (
        <Backdrop>
          <MessageContainer>
            <p>
              <MessageboxText>{message}</MessageboxText>
            </p>
            <CloseMessageButton onClick={handleCloseAndNavigate}>
              <MessageboxText>Close</MessageboxText>
            </CloseMessageButton>
          </MessageContainer>
        </Backdrop>
      )}

      {error && (
        <Backdrop>
          <MessageContainer>
            <p>
              <MessageboxText>{error}</MessageboxText>
            </p>
            <CloseMessageButton
              onClick={() => {
                setIsMessageBoxVisible(false);
                setError(null);
                closeOverlay();
              }}
            >
              <MessageboxText>Close</MessageboxText>
            </CloseMessageButton>
          </MessageContainer>
        </Backdrop>
      )}

      <FrameTopGradient100 />
      <OverlayComponentWhiteTitleAdd1>NUOVO PROCESSO COMPLIANCE</OverlayComponentWhiteTitleAdd1>
      <OverlayComponentWhiteAdd1>
        <SubMenuButtonContainerOrganigrammaMultipleShort>
          <CreaSubMenuButtonMultiple onClick={() => changeActiveComponent("SchedaGenerale")} to="/Cybersecurity/GestioneCompliance/ProcessiCompliance/CreaProcessiCompliance/1">
            Scheda generale
          </CreaSubMenuButtonMultiple>

          {/* <CreaSubMenuButtonMultiple
            onClick={() => changeActiveComponent("Organizzazione")}
            to="/Cybersecurity/GestioneCompliance/ProcessiCompliance/CreaProcessiCompliance/2"
          >
            Organizzazione
          </CreaSubMenuButtonMultiple> */}
          {/* <CreaSubMenuButtonMultiple
            onClick={() => changeActiveComponent("Infrastruttura")}
            to="/Cybersecurity/GestioneCompliance/ProcessiCompliance/CreaProcessiCompliance/3"
          >
            Infrastruttura
          </CreaSubMenuButtonMultiple>
          <CreaSubMenuButtonMultiple
            onClick={() => changeActiveComponent("ProcessiGenerali")}
            to="/Cybersecurity/GestioneCompliance/ProcessiCompliance/CreaProcessiCompliance/4"
          >
            Processi generali
          </CreaSubMenuButtonMultiple> */}
          {/* <CreaSubMenuButtonMultiple
            onClick={() => changeActiveComponent("PartiInteressate")}
            to="/Cybersecurity/Infrastruttura/UnitaOperative/CreaL2/5"
          >
            Parti interessate
          </CreaSubMenuButtonMultiple> */}
          <CreaSubMenuButtonMultiple onClick={() => changeActiveComponent("SistemaCompliance")} to="/Cybersecurity/GestioneCompliance/ProcessiCompliance/CreaProcessiCompliance/6">
            Sistema gestione Compliance
          </CreaSubMenuButtonMultiple>
          <CreaSubMenuButtonMultiple onClick={() => changeActiveComponent("Documentazione")} to="/Cybersecurity/GestioneCompliance/ProcessiCompliance/CreaProcessiCompliance/7">
            Documentazione
          </CreaSubMenuButtonMultiple>
        </SubMenuButtonContainerOrganigrammaMultipleShort>
        <Suspense fallback={<div>Loading...</div>}>{renderActiveComponent()}</Suspense>
      </OverlayComponentWhiteAdd1>

      <OverlayComponentWhiteButtonFrameAdd1>
        <ButtonGrey onClick={handleClose}>
          <ButtonText>Annulla</ButtonText>
        </ButtonGrey>
        <ButtonGrey onClick={handleSaveAndUpload}>
          <ButtonText>Crea</ButtonText>
        </ButtonGrey>
      </OverlayComponentWhiteButtonFrameAdd1>
    </OverlayComponentBackgroundAdd1>
  );
};

export default MainComponent;
