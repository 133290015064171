import { atom } from "recoil";

export const showL3isVisible = atom({
  key: "showL3isVisible",
  default: false,
});

export const showL3isAnimating = atom({
  key: "showL3isAnimating",
  default: false,
});

export const showL3initialPath = atom({
  key: "showL3initialPath",
  default: "",
});
