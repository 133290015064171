import React, { createContext } from "react";
import { Outlet, useLocation } from "react-router-dom";

// Define the shape of the context data for better TypeScript support
interface OverlayContextType {
  closeOverlay: () => void;
  selectedL1: string | null;
  setSelectedL1: React.Dispatch<React.SetStateAction<string | null>>;
  selectedL2: string | null;
  setSelectedL2: React.Dispatch<React.SetStateAction<string | null>>;
  selectedL3: string | null;
  setSelectedL3: React.Dispatch<React.SetStateAction<string | null>>;
  selectedL4: string | null;
  setSelectedL4: React.Dispatch<React.SetStateAction<string | null>>;
}

// Create the context with a default value
export const OverlayContext = createContext<OverlayContextType>({
  closeOverlay: () => {},
  selectedL1: null,
  setSelectedL1: () => {},
  selectedL2: null,
  setSelectedL2: () => {},
  selectedL3: null,
  setSelectedL3: () => {},
  selectedL4: null,
  setSelectedL4: () => {},
});

import { FrameBoardMain, MainBoardSubMenuButton, MainBoardSubMenuButtonContainer } from "../styled-components";

const Board27001ContestoLeadershipOrganizzazione: React.FC = () => {
  const location = useLocation();

  const isActive = (path: string) => {
    // Check if the current location is the index route of Organizzazione
    return location.pathname === "/Cybersecurity/PartiInteressate" && path === "PartiInteressate";
  };

  return (
    <FrameBoardMain>
      <MainBoardSubMenuButtonContainer>
        <MainBoardSubMenuButton to="/Cybersecurity/PartiInteressate/PartiInteressate" className={isActive("PartiInteressate") ? "active" : ""}>
          Parti interessate
        </MainBoardSubMenuButton>
        <MainBoardSubMenuButton to="/Cybersecurity/PartiInteressate/Clienti">Clienti</MainBoardSubMenuButton>
        <MainBoardSubMenuButton to="/Cybersecurity/PartiInteressate/Fornitori">Fornitori</MainBoardSubMenuButton>
        <MainBoardSubMenuButton to="/Cybersecurity/PartiInteressate/AltriEnti">Altri enti</MainBoardSubMenuButton>
      </MainBoardSubMenuButtonContainer>

      <Outlet />
    </FrameBoardMain>
  );
};

export default Board27001ContestoLeadershipOrganizzazione;
