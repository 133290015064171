import { useAuth } from "oidc-react";
import React from "react";

import ApiCheck from "../../check-API";
import { ButtonGrey, FrameBoardMain } from "../styled-components";

const Board27001Organizzazione: React.FC = () => {
  const auth = useAuth();

  const onLogout = () => {
    auth.signOut();
  };

  return (
    <FrameBoardMain>
      <div style={{ marginBottom: "1rem" }}></div>
      <ButtonGrey onClick={onLogout}>Logout</ButtonGrey>
      <div style={{ marginBottom: "1rem" }}></div>
      <ApiCheck />
    </FrameBoardMain>
  );
};

export default Board27001Organizzazione;
