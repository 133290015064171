import React from "react";
import { useLocation } from "react-router-dom";
import styled from "styled-components";

import { useToken } from "../../../API";
import { FrameBoardMain, MainBoardSubMenuButton, MainBoardSubMenuButtonContainer } from "../styled-components";

const Board27001Organizzazione: React.FC = () => {
  const token = useToken();

  const location = useLocation();

  const isActive = (path: string) => {
    return location.pathname === "/Cybersecurity/PartiInteressate" && path === "PartiInteressate";
  };

  const ImageContainer = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    width: 100%;
  `;

  const StyledImage = styled.img`
    max-width: 90%;
    height: auto;
  `;

  return (
    <FrameBoardMain>
      <MainBoardSubMenuButtonContainer>
        <MainBoardSubMenuButton to="/Cybersecurity/PianoStrategico" className={isActive("PianoStrategico") ? "active" : ""}>
          Piano strategico
        </MainBoardSubMenuButton>
        {/* <MainBoardSubMenuButton to="/Cybersecurity/PartiInteressate/Clienti">Clienti</MainBoardSubMenuButton>
        <MainBoardSubMenuButton to="/Cybersecurity/PartiInteressate/Fornitori">Fornitori</MainBoardSubMenuButton>
        <MainBoardSubMenuButton to="/Cybersecurity/PartiInteressate/AltriEnti">Altri enti</MainBoardSubMenuButton> */}
      </MainBoardSubMenuButtonContainer>

      <ImageContainer>
        <StyledImage src={"../piano_strategico.png"} alt={"alt"} />
      </ImageContainer>
    </FrameBoardMain>
  );
};

export default Board27001Organizzazione;
