import InfoIcon from "@mui/icons-material/InfoOutlined";
import React, { useContext, useEffect, useRef, useState } from "react";
import styled from "styled-components";
import { OverlayContext } from "../../cybersecurity-board-contestoleadership-organizzazione";

import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { Accordion, AccordionDetails, AccordionSummary, Box, FormControl, FormControlLabel, FormLabel, Grid, Radio, RadioGroup, Tooltip, Typography } from "@mui/material";
import { useRecoilState } from "recoil";
import {
  Backdrop,
  BackdropTransparent,
  CloseMessageButton,
  CreaContainerColumn,
  CustomDropdownItem,
  CustomDropdownList,
  DescriptionContainerCreaMultiple,
  DescriptionContainerCreaText,
  DropDownMenuScrollbar,
  FlexContainerCrea,
  IconContainer,
  IconSquare,
  InputContainer,
  InputDescriptionText,
  InputDescriptionTextTriple,
  InputTextbox,
  InputTextboxTriple,
  LastPopupButtonContainer,
  LastPopupCheckboxContainer,
  LastPopupHeaderContainer,
  LastPopupScrollbar,
  LastPopupSearch,
  LastPopupSearchIcon,
  LastPopupSearchInputContainer,
  LastPopupTitleContainer,
  MessageContainer,
  MessageboxText,
  OverlayComponentWhiteAdd1Multiple,
  TriangleContainer
} from "../../cybersecurity-board-contestoleadership-organizzazione-sub/cybersecurity-board-organizzazione-organigramma-styled-components";
import {
  PartiLegalForm,
  PartiOrganizzazioneDH,
  PartiOrganizzazioneDirettore,
  PartiOrganizzazioneDirettoreID,
  PartiOrganizzazioneModello,
  PartiOrganizzazioneOrdinaria,
  PartiOrganizzazionePersonale,
  PartiOrganizzazioneSelectedL1,
  PartiOrganizzazioneSelectedL1ID,
  PartiselectedCategory,
  PartiselectedCategoryID,
  PartiselectedLegalFormID,
} from "../Parti-Add-recoil";

import CancelIcon from "@mui/icons-material/Clear";
import { URL_DirectorList, URL_LV1List, URL_LegalForm, URL_OrgModel, useToken } from "../../../../../API";
import { ButtonGrey, ButtonText } from "../../../styled-components";
import { TAB_DESCRIPTION_GLOBAL, TAB_DESCRIPTION_PARTI_2 } from "../../tabs-description";

const MainComponent = () => {
  const token = useToken();  

  const [data, setData] = useState<DirectorDataItem[]>([]);
  const [dataLegalForm, setDataLegalForm] = useState<LegalFormDataItem[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const [isMessageBoxVisible, setIsMessageBoxVisible] = useState(false);
  const [message, setMessage] = useState("");

  const [LV1List, setLV1List] = useRecoilState(PartiOrganizzazioneSelectedL1);
  const [LV1ListId, setLV1ListId] = useRecoilState(PartiOrganizzazioneSelectedL1ID);
  const [director, setDirector] = useRecoilState(PartiOrganizzazioneDirettore);
  const [directorId, setDirectorId] = useRecoilState(PartiOrganizzazioneDirettoreID);

  const [legalForm, setLegalForm] = useRecoilState(PartiLegalForm);
  const [legalFormId, setLegalFormId] = useRecoilState(PartiselectedLegalFormID);
  const [Modello, setModello] = useRecoilState(PartiOrganizzazioneModello);
  const [Personale, setPersonale] = useRecoilState(PartiOrganizzazionePersonale);
  const [category, setCategory] = useRecoilState(PartiselectedCategory);
  const [categoryID, setCategoryID] = useRecoilState(PartiselectedCategoryID);
  const [DH, setDH] = useRecoilState(PartiOrganizzazioneDH);
  const [Ordinaria, setOrdinaria] = useRecoilState(PartiOrganizzazioneOrdinaria);

  const { closeOverlay } = useContext(OverlayContext);

  const handleCloseAndNavigate = () => {
    setIsMessageBoxVisible(false);  
    closeOverlay();
  };

  const categoryOptions = [
    { name: "Cliente", id: "thirdparty.client" },
    { name: "Fornitore", id: "thirdparty.supplier" },
    { name: "Altro ente", id: "thirdparty.other" },
  ];

   

  type DirectorApiContentItem = {
    id: string;
    alias: string;
  };

  type DirectorDataItem = {
    director_id: string;
    director_name: string;
  };

  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true);
      try {
         
        if (!token) throw new Error("User is not authenticated");

         
        const response = await fetch(URL_DirectorList, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });

         
        if (!response.ok) {
          throw new Error(`API request failed: ${response.statusText}`);
        }

         
        const responseData = await response.json();
        console.log("Response Data:", responseData);

         
        const transformedData: DirectorDataItem[] = responseData.map((item: DirectorApiContentItem) => {
          return {
            director_id: item.id,
            director_name: item.alias,  

             
          };
        });

        console.log("Transformed Data:", transformedData);
        setData(transformedData);
      } catch (err) {
         
        setError(err instanceof Error ? err.message : "An unknown error occurred");
        setIsMessageBoxVisible(true);
      } finally {
        setIsLoading(false);
      }
    };

     
    fetchData();
  }, [token]);  

   

   

   
  const directorNameToId = new Map(data.map((item) => [item.director_name, item.director_id]));

  const handleDirectorChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;
    setDirector(value);

     
    if (directorNameToId.has(value)) {
      setDirectorId(directorNameToId.get(value) || "");
      console.log(directorNameToId.get(value) || "");
    }
  };

   

   

  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const dropdownRef = useRef<HTMLDivElement>(null);

   
  useEffect(() => {
    function handleClickOutside(event: MouseEvent) {
       
      if (dropdownRef.current && event.target instanceof Node && !dropdownRef.current.contains(event.target)) {
        setIsDropdownOpen(false);
      }
    }

     
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
       
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const handleDropdownToggle = () => {
     
    setDirector("");

     
    setDirectorId("");

     

    setIsDropdownOpen(!isDropdownOpen);
  };

  const handleOptionSelect = (selectedDirectorId: string) => {
     
    const selectedDirectorName = data.find((item) => item.director_id === selectedDirectorId)?.director_name;

    if (selectedDirectorName) {
       
      setDirector(selectedDirectorName);

       
      setDirectorId(selectedDirectorId);
    }

     
    setIsDropdownOpen(false);
  };

  useEffect(() => {
    console.log("Selected Director ID:", directorId);
  }, [directorId]);  

   
  const [isDropdownOpenCategory, setIsDropdownOpenCategory] = useState(false);
  const dropdownRefCategory = useRef<HTMLDivElement>(null);
   
  const handleDropdownToggleCategory = () => {
    setIsDropdownOpenCategory(!isDropdownOpenCategory);
  };

   
  const clearCategorySelection = () => {
    setCategoryID("");
    setIsDropdownOpenCategory(false);
  };

   
  const handleOptionSelectCategory = (id: string) => {
    const selectedCategory = categoryOptions.find((option) => option.id === id);
    if (selectedCategory) {
      setCategory(selectedCategory.name);
      setCategoryID(selectedCategory.id);
    }
    setIsDropdownOpenCategory(false);
  };

   
  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (dropdownRefCategory.current && event.target instanceof Node && !dropdownRefCategory.current.contains(event.target)) {
        setIsDropdownOpenCategory(false);
      }
    };

     
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
       
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

   

  type LegalFormApiContentItem = {
    id: string;
    category: string;
  };

  type LegalFormDataItem = {
    legalform_id: string;
    legalform_name: string;
  };

  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true);
      try {
         
        if (!token) throw new Error("User is not authenticated");

         
        const response = await fetch(URL_LegalForm, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });

         
        if (!response.ok) {
          throw new Error(`API request failed: ${response.statusText}`);
        }

         
        const responseData = await response.json();
        console.log("Response Data:", responseData);

         
        const transformedData: LegalFormDataItem[] = responseData
          .map((item: LegalFormApiContentItem) => ({
            legalform_id: item.id,
            legalform_name: item.category,
          }))
          .sort((a: LegalFormDataItem, b: LegalFormDataItem) => a.legalform_name.localeCompare(b.legalform_name));  

        console.log("Transformed Data:", transformedData);
        setDataLegalForm(transformedData);
      } catch (err) {
         
        setError(err instanceof Error ? err.message : "An unknown error occurred");
        setIsMessageBoxVisible(true);
      } finally {
        setIsLoading(false);
      }
    };

     
    fetchData();
  }, [token]);  

   

   

   
  const legalFormNameToId = new Map(dataLegalForm.map((item) => [item.legalform_id, item.legalform_name]));

  const handleLegalFormChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;
    setLegalForm(value);

     
    if (legalFormNameToId.has(value)) {
      setLegalFormId(legalFormNameToId.get(value) || "");
      console.log(legalFormNameToId.get(value) || "");
    }
  };

   

   

  const [isDropdownOpenLegalForm, setIsDropdownOpenLegalForm] = useState(false);
  const dropdownRefLegalForm = useRef<HTMLDivElement>(null);

   
  useEffect(() => {
    function handleClickOutsideLegalForm(event: MouseEvent) {
       
      if (dropdownRefLegalForm.current && event.target instanceof Node && !dropdownRefLegalForm.current.contains(event.target)) {
        setIsDropdownOpenLegalForm(false);
      }
    }

     
    document.addEventListener("mousedown", handleClickOutsideLegalForm);
    return () => {
       
      document.removeEventListener("mousedown", handleClickOutsideLegalForm);
    };
  }, []);

  const handleDropdownToggleLegalForm = () => {
     
    setLegalForm("");

     
    setLegalForm("");

     

    setIsDropdownOpenLegalForm(!isDropdownOpenLegalForm);
  };

  const handleOptionSelectLegalForm = (selectedLegalFormId: string) => {
     
    const selectedLegalFormName = dataLegalForm.find((item) => item.legalform_id === selectedLegalFormId)?.legalform_name;

    if (selectedLegalFormName) {
       
      setLegalForm(selectedLegalFormName);

       
      setLegalFormId(selectedLegalFormId);
    }

     
    setIsDropdownOpenLegalForm(false);
  };

  useEffect(() => {
    console.log("Selected Legal Form ID:", legalFormId);
  }, [legalFormId]);  

   

  type LV1ApiContentItem = {
    id: string;
    alias: string;
  };

  type LV1DataItem = {
    LV1_id: string;
    LV1_name: string;
  };

  const [dataLV1, setDataLV1] = useState<LV1DataItem[]>([]);

  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true);
      try {
         
        if (!token) throw new Error("User is not authenticated");

         
        const response = await fetch(URL_LV1List, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });

         
        if (!response.ok) {
          throw new Error(`API request failed: ${response.statusText}`);
        }

         
        const responseData = await response.json();
        console.log("Response dataLV1:", responseData);

         
        const contentArray = responseData.content;  

         
        if (!Array.isArray(contentArray)) {
          throw new Error("Expected content to be an array");
        }

         
        const transformedData: LV1DataItem[] = contentArray.map((item: LV1ApiContentItem) => {
          return {
            LV1_id: item.id,
            LV1_name: item.alias,  
             
          };
        });

        console.log("Transformed dataLV1:", transformedData);
        setDataLV1(transformedData);
      } catch (err) {
         
        setError(err instanceof Error ? err.message : "An unknown error occurred");
        setIsMessageBoxVisible(true);
      } finally {
        setIsLoading(false);
      }
    };

     
    fetchData();
  }, [token]);  

   

   

   
  const LV1NameToId = new Map(dataLV1.map((item) => [item.LV1_name, item.LV1_id]));

  const handleLV1Change = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;
    setLV1List(value);

     
    if (LV1NameToId.has(value)) {
      setLV1ListId(LV1NameToId.get(value) || "");
      console.log(LV1NameToId.get(value) || "");
    }
  };

   

  const [isDropdownOpenLV1, setIsDropdownOpenLV1] = useState(false);
  const dropdownRefLV1 = useRef<HTMLDivElement>(null);

   
  useEffect(() => {
    function handleClickOutside(event: MouseEvent) {
       
      if (dropdownRefLV1.current && event.target instanceof Node && !dropdownRefLV1.current.contains(event.target)) {
        setIsDropdownOpenLV1(false);
      }
    }

     
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
       
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const handleDropdownToggleLV1 = () => {
    setLV1List("");
    setLV1ListId("");
    setIsDropdownOpenLV1(!isDropdownOpenLV1);
  };

  const handleOptionSelectLV1 = (selectedDirectorId: string) => {
     
    const selectedLV1Name = dataLV1.find((item) => item.LV1_id === selectedDirectorId)?.LV1_name;

    if (selectedLV1Name) {
       
      setLV1List(selectedLV1Name);

       
      setLV1ListId(selectedDirectorId);
    }

     
    setIsDropdownOpenLV1(false);
  };

  useEffect(() => {
    console.log("Selected LV1ListId ID:", LV1ListId);
  }, [LV1ListId]);  

  const ClearLV1 = () => {
    setLV1List("");
    setLV1ListId("");
  };

  const ClearLV2 = () => {
    setDirector("");
    setDirectorId("");
  };

  const ClearCategory = () => {
    setCategory("");
    setCategoryID("");
  };

  const ClearLegalForm = () => {
    setLegalForm("");
    setLegalFormId("");
  };
   

   

  const [isVisible, setIsVisible] = useState(false);
  const togglePopup = () => {
    setIsVisible(!isVisible);
  };

  interface LastPopupProps {
    isVisible: boolean;
  }

  const LastPopupBlack = styled.div<LastPopupProps>`
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);  
    z-index: 99;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: opacity 0.3s ease, visibility 0.3s ease;
    opacity: ${({ isVisible }) => (isVisible ? 1 : 0)};
    visibility: ${({ isVisible }) => (isVisible ? "visible" : "hidden")};
  `;

  const LastPopup = styled.div`
    font-family: var(--font-titillium-web);
    font-size: var(--font-size-micro);
    color: var(--color-steelblue-700);
    position: fixed;
    right: 0px;
    top: 0px;
    bottom: 0px;
    width: 75%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-color: var(--color-aliceblue);

    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.25);
     
    z-index: 100;
  `;

   

  const [Modeldata, setModelData] = useState<Model[]>([]);
  const [selectedModelId, setSelectedModelId] = useState("");
  const [selectedModelName, setSelectedModelName] = useState("");
  const selectedModel = Modeldata.find((model) => model.id === selectedModelId);

  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true);
      try {
        if (!token) throw new Error("User is not authenticated");

        const infoResponse = await fetch(`${URL_OrgModel}/info`, {
          headers: { Authorization: `Bearer ${token}` },
        });

        if (!infoResponse.ok) {
          throw new Error(`API request failed: ${infoResponse.statusText}`);
        }

        const { total } = await infoResponse.json();

        const dataResponse = await fetch(`${URL_OrgModel}?size=${total}`, {
          headers: { Authorization: `Bearer ${token}` },
        });

        if (!dataResponse.ok) {
          throw new Error(`API request failed: ${dataResponse.statusText}`);
        }

        const responseData: { content: Model[] } = await dataResponse.json();

        if (responseData && Array.isArray(responseData.content)) {
          setModelData(responseData.content);
           
          setSelectedModelId(responseData.content[0].id);
        } else {
          console.error("responseData.content is not an array or does not exist:", responseData);
        }
      } catch (err) {
        setError(err instanceof Error ? err.message : "An unknown error occurred");
      } finally {
        setIsLoading(false);
      }
    };

    fetchData();
  }, [token]);  

  const handleModelChange = (newModelId: string) => {
    setSelectedModelId(newModelId);
    setModello(newModelId);
    console.log(newModelId);
  };

  interface Model {
    id: string;
     
    roles: Role[];  

    model_name: string;  
    responsible_role: {
       
      id: string;
      role_description: string;
      responsibility: string;
    };
  }

  interface ModelSelectorProps {
    models: Model[];
    onModelChange: (newModelId: string) => void;  
  }

   
  const ModelSelector: React.FC<ModelSelectorProps> = ({ models, onModelChange }) => {
    return (
      <FormControl component="fieldset">
        <FormLabel component="legend" sx={{ color: "#04446c", padding: "8px" }}></FormLabel>

        <RadioGroup aria-label="model" name="model" value={selectedModelId} onChange={(event) => onModelChange(event.target.value)} sx={{ padding: "5px 10px 10px 20px" }}>
          {models.map((model) => (
            <FormControlLabel
              key={model.id}
              value={model.id}
              control={<Radio sx={{ "& .MuiSvgIcon-root": { fontSize: "1rem" } }} />}  
              label={model.model_name}
              sx={{
                color: "primary.main",
                "& .MuiTypography-root": { fontSize: "0.875rem" },
              }}  
            />
          ))}
        </RadioGroup>
      </FormControl>
    );
  };

   
  interface Role {
    id: string;
    role_description: string;
    responsibility: string;
     
  }

   
  interface RolesAccordionProps {
    roles: Role[];
  }

  const RolesAccordion: React.FC<RolesAccordionProps & { responsibleRole: Model["responsible_role"] }> = ({ roles, responsibleRole }) => {
    return (
      <Box sx={{ mt: 2 }}>
        {/* Accordion for Responsible Role */}
        <Accordion>
          <AccordionSummary expandIcon={<ExpandMoreIcon />}>
            <Typography>{responsibleRole.role_description}</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography>{responsibleRole.responsibility}</Typography>
          </AccordionDetails>
        </Accordion>

        {/* Existing Role Accordions */}
        {roles.map((role: Role) => (
          <Accordion key={role.id}>
            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
              <Typography>{role.role_description}</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography>{role.responsibility}</Typography>
            </AccordionDetails>
          </Accordion>
        ))}
      </Box>
    );
  };

   

  return (
    <OverlayComponentWhiteAdd1Multiple>
      {isLoading && (
        <BackdropTransparent>
          <MessageContainer>
            <MessageboxText>Loading...</MessageboxText>
          </MessageContainer>
        </BackdropTransparent>
      )}

      {isMessageBoxVisible && (
        <Backdrop>
          <MessageContainer>
            <p>
              <MessageboxText>{message}</MessageboxText>
            </p>
            <CloseMessageButton onClick={handleCloseAndNavigate}>
              <MessageboxText>Close</MessageboxText>
            </CloseMessageButton>
          </MessageContainer>
        </Backdrop>
      )}

      {error && (
        <Backdrop>
          <MessageContainer>
            <p>
              <MessageboxText>{error}</MessageboxText>
            </p>
            <CloseMessageButton
              onClick={() => {
                setIsMessageBoxVisible(false);
                setError(null);
                closeOverlay();
              }}
            >
              <MessageboxText>Close</MessageboxText>
            </CloseMessageButton>
          </MessageContainer>
        </Backdrop>
      )}
      <FlexContainerCrea>
        <CreaContainerColumn>
          {/* <InputContainer>
            <InputDescriptionText>Forma giuridica</InputDescriptionText>
            <InputTextboxButton onClick={togglePopup}>
            <ArrowButtonText>Apri per selezionare</ArrowButtonText> </InputTextboxButton>
            <IconContainer />
            <TriangleContainer>▶</TriangleContainer>
          </InputContainer> */}

          <InputContainer>
            <InputDescriptionText>
              Forma giuridica
              <Tooltip title="Campo obbligatorio">
                <InfoIcon
                  style={{
                    fontSize: "14px",
                    color: "#04446c",
                    cursor: "pointer",
                    marginLeft: "2px",
                  }}
                />
              </Tooltip>
            </InputDescriptionText>

            <InputTextbox list="legalform-list" value={legalForm} onChange={handleLegalFormChange} onClick={handleDropdownToggleLegalForm} placeholder="Seleziona" />
            {legalForm && (
              <CancelIcon
                style={{
                  fontSize: "16px",
                  cursor: "pointer",
                  position: "absolute",
                  right: "30px",
                  top: "32%",
                  transform: "translateY(-50%)",
                  color: "#c6c6c6",  
                }}
                onClick={ClearLegalForm}  
              />
            )}
            <TriangleContainer>
              {isDropdownOpenLegalForm ? "▲" : "▼"} {/* Change icons as needed */}
            </TriangleContainer>

            {isDropdownOpenLegalForm && (
              <CustomDropdownList>
                <DropDownMenuScrollbar ref={dropdownRefLegalForm}>
                  {dataLegalForm
                    .filter((item) => item.legalform_name.toLowerCase().includes(legalForm.toLowerCase()))
                    .map((filteredItem, index) => (
                      <CustomDropdownItem key={index} onClick={() => handleOptionSelectLegalForm(filteredItem.legalform_id)}>
                        {filteredItem.legalform_name}
                      </CustomDropdownItem>
                    ))}
                </DropDownMenuScrollbar>
              </CustomDropdownList>
            )}
            <IconContainer />
          </InputContainer>

          <InputContainer>
            <InputDescriptionText>Componente organizzativa</InputDescriptionText>

            <InputTextbox list="l1-list" value={LV1List} onChange={handleLV1Change} onClick={handleDropdownToggleLV1} placeholder="Seleziona" />
            {LV1List && (
              <CancelIcon
                style={{
                  fontSize: "16px",
                  cursor: "pointer",
                  position: "absolute",
                  right: "30px",
                  top: "32%",
                  transform: "translateY(-50%)",
                  color: "#c6c6c6",  
                }}
                onClick={ClearLV1}  
              />
            )}
            <TriangleContainer>
              {isDropdownOpenLV1 ? "▲" : "▼"} {/* Change icons as needed */}
            </TriangleContainer>

            {isDropdownOpenLV1 && (
              <CustomDropdownList>
                <DropDownMenuScrollbar ref={dropdownRefLV1}>
                  {dataLV1
                    .filter((item) => item.LV1_name.toLowerCase().includes(LV1List.toLowerCase()))
                    .map((filteredItem, index) => (
                      <CustomDropdownItem key={index} onClick={() => handleOptionSelectLV1(filteredItem.LV1_id)}>
                        {filteredItem.LV1_name}
                      </CustomDropdownItem>
                    ))}
                </DropDownMenuScrollbar>
              </CustomDropdownList>
            )}
            <IconContainer />
          </InputContainer>

          <InputContainer>
            <InputDescriptionTextTriple>Ambito d'interesse {DH.length}/255</InputDescriptionTextTriple>
            <InputTextboxTriple placeholder="" value={DH} onChange={(e) => setDH(e.target.value)} maxLength={255} />
            {/* <IconContainer /> */}
          </InputContainer>
        </CreaContainerColumn>

        <CreaContainerColumn>
          <InputContainer>
            <InputDescriptionText>
              Categoria
              <Tooltip title="Campo obbligatorio">
                <InfoIcon
                  style={{
                    fontSize: "14px",
                    color: "#04446c",
                    cursor: "pointer",
                    marginLeft: "2px",
                  }}
                />
              </Tooltip>
            </InputDescriptionText>
            <InputTextbox placeholder="Seleziona" value={category} readOnly onClick={handleDropdownToggleCategory} />
            {categoryID && (
              <CancelIcon
                style={{
                  fontSize: "16px",
                  cursor: "pointer",
                  position: "absolute",
                  right: "30px",
                  top: "32%",
                  transform: "translateY(-50%)",
                  color: "#c6c6c6",
                }}
                onClick={clearCategorySelection}  
              />
            )}
            <TriangleContainer>
              {isDropdownOpenCategory ? "▲" : "▼"} {/* Change icons as needed */}
            </TriangleContainer>
            {isDropdownOpenCategory && (
              <CustomDropdownList>
                <DropDownMenuScrollbar ref={dropdownRefCategory}>
                  {categoryOptions.map((option, index) => (
                    <CustomDropdownItem key={index} onClick={() => handleOptionSelectCategory(option.id)}>
                      {option.name}
                    </CustomDropdownItem>
                  ))}
                </DropDownMenuScrollbar>
              </CustomDropdownList>
            )}
            <IconContainer />
          </InputContainer>

          <InputContainer>
            <InputDescriptionText>Legale rappresentante</InputDescriptionText>
            <InputTextbox placeholder="" value={Ordinaria} onChange={(e) => setOrdinaria(e.target.value)} />
            <IconContainer />
          </InputContainer>

          <InputContainer>
            <InputDescriptionText>Responsabile</InputDescriptionText>

            <InputTextbox list="directors-list" value={director} onChange={handleDirectorChange} onClick={handleDropdownToggle} placeholder="Seleziona" />
            {director && (
              <CancelIcon
                style={{
                  fontSize: "16px",
                  cursor: "pointer",
                  position: "absolute",
                  right: "30px",
                  top: "32%",
                  transform: "translateY(-50%)",
                  color: "#c6c6c6",  
                }}
                onClick={ClearLV2}  
              />
            )}
            <TriangleContainer>
              {isDropdownOpen ? "▲" : "▼"} {/* Change icons as needed */}
            </TriangleContainer>

            {isDropdownOpen && (
              <CustomDropdownList>
                <DropDownMenuScrollbar ref={dropdownRef}>
                  {data
                    .filter((item) => item.director_name.toLowerCase().includes(director.toLowerCase()))
                    .map((filteredItem, index) => (
                      <CustomDropdownItem key={index} onClick={() => handleOptionSelect(filteredItem.director_id)}>
                        {filteredItem.director_name}
                      </CustomDropdownItem>
                    ))}
                </DropDownMenuScrollbar>
              </CustomDropdownList>
            )}
            <IconContainer />
          </InputContainer>

          {/* <InputContainer>
            <InputDescriptionText>Personale</InputDescriptionText>
            <InputTextbox
              placeholder=""
              value={Personale}
              onChange={(e) => setPersonale(e.target.value)}
            />
            <IconContainer />
          </InputContainer> */}
        </CreaContainerColumn>

        <LastPopupBlack isVisible={isVisible}>
          <LastPopup>
            <LastPopupTitleContainer>SELEZIONA MODELLO</LastPopupTitleContainer>
            <LastPopupCheckboxContainer>
              <LastPopupHeaderContainer>
                <LastPopupSearchInputContainer>
                  <LastPopupSearch placeholder="Cerca" />
                  <LastPopupSearchIcon src="/search.svg" />
                </LastPopupSearchInputContainer>
              </LastPopupHeaderContainer>

              <LastPopupScrollbar>
                {/* MUI TreeView to visualize the data */}
                <Grid container spacing={2} sx={{ width: "100%" }}>
                  <Grid item xs={4}>
                    <Box
                      sx={{
                        backgroundColor: "#fff",  
                        borderRadius: "4px",  
                        boxShadow: "0 2px 4px rgba(0, 0, 0, 0.2)",  
                        padding: "0px 10px 10px 5px",  
                        marginTop: "16px",
                        marginLeft: "20px",
                      }}
                    >
                      <ModelSelector models={Modeldata} onModelChange={handleModelChange} />
                    </Box>
                  </Grid>
                  <Grid item xs={8}>
                    {selectedModelId && selectedModel && selectedModel.roles && <RolesAccordion roles={selectedModel.roles} responsibleRole={selectedModel.responsible_role} />}
                  </Grid>
                </Grid>
              </LastPopupScrollbar>
            </LastPopupCheckboxContainer>
            <LastPopupButtonContainer>
              <ButtonGrey onClick={togglePopup}>
                <ButtonText>Chiudi</ButtonText>
              </ButtonGrey>
            </LastPopupButtonContainer>
          </LastPopup>
        </LastPopupBlack>
      </FlexContainerCrea>
      <DescriptionContainerCreaMultiple>
        <IconSquare src="/crea-L2-organizzazione.svg" alt="Description Icon" />
        <DescriptionContainerCreaText>{TAB_DESCRIPTION_PARTI_2 ? TAB_DESCRIPTION_PARTI_2 : TAB_DESCRIPTION_GLOBAL}</DescriptionContainerCreaText>
      </DescriptionContainerCreaMultiple>
    </OverlayComponentWhiteAdd1Multiple>
  );
};

export default MainComponent;
