import React, { useState } from "react";
import { Outlet, useLocation, useNavigate } from "react-router-dom";
import styled from "styled-components";
import { useToken } from "../../../API";
import { FrameBoardMain, MainBoardSubMenuButton, MainBoardSubMenuButtonContainer } from "../styled-components";
import { ButtonDescriptionText } from "./cybersecurity-board-contestoleadership-infrastruttura-sub/cybersecurity-board-infrastruttura-organigramma-styled-components";
import { OverlayContext } from "./cybersecurity-board-contestoleadership-organizzazione";
import {
  CreaContainerColumn,
  DocumentazioneBG,
  FadeOutComponent,
  FlexContainerCrea,
  FrameTopGradient100,
  IconContainer,
  InputContainer,
  InputTextboxButton,
  TriangleContainer
} from "./cybersecurity-board-contestoleadership-organizzazione-sub/cybersecurity-board-organizzazione-organigramma-styled-components";

interface OverlayComponentProps {
  isAnimating: boolean;
}

const OverlayComponentAdd1 = styled.div<OverlayComponentProps>`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  z-index: 10;
  transition: opacity 300ms ease-in-out, visibility 300ms ease-in-out;
  opacity: ${(props) => (props.isAnimating ? "1" : "0")};
  visibility: ${(props) => (props.isAnimating ? "visible" : "hidden")};
`;

const Board27001Organizzazione: React.FC = () => {
  const token = useToken();
  const navigate = useNavigate();

  const [selectedL1, setSelectedL1] = useState<string | null>(null);
  const [selectedL2, setSelectedL2] = useState<string | null>(null);
  const [selectedL3, setSelectedL3] = useState<string | null>(null);
  const [selectedL4, setSelectedL4] = useState<string | null>(null);

  //////////////////// TOGGLE OVERLAY 1 ////////////////////
  const [isOverlayVisibleAdd1, setIsOverlayVisibleAdd1] = useState(false);
  const [isOverlayAnimatingAdd1, setIsOverlayAnimatingAdd1] = useState(false);

  const toggleOverlayAdd1 = () => {
    if (!isOverlayVisibleAdd1) {
      if (window.location.pathname === "/Cybersecurity/Documentazione") {
        navigate(`LetteraIncarico/CreaLetteraIncarico/1`);
      } else {
        navigate(`${window.location.pathname}/CreaLetteraIncarico/1`);
      }

      setIsOverlayVisibleAdd1(true); // Show the overlay
      setTimeout(() => setIsOverlayAnimatingAdd1(true), 10); // Start animation after it's visible
    } else {
      setIsOverlayAnimatingAdd1(false); // Start hiding animation
      setTimeout(() => setIsOverlayVisibleAdd1(false), 310); // Wait for animation to finish before hiding
    }
  };

  // Function to close the overlay
  const closeOverlayAdd1 = () => {
    // setDirectorComplianceIdLV1("");
    // setDirectorComplianceLV1("");
    // setDirectorIdLV1("");
    // setDirectorLV1("");
    // setComplianceDateLV1(dayjs());
    // setFaxLV1("");
    // setPECLV1("");
    // setTelefonoLV1("");
    // setIndirizzoLV1("");
    // setNomeCompletoLV1("");
    // setNomeAbbreviatoLV1("");
    // setDocumentsAttachedLV1([]);
    // setAttachedFileInfoLV1([]);
    // setDocumentsPathLV1('');

    navigate(`/Cybersecurity/Documentazione`);
    setIsOverlayAnimatingAdd1(false); // Start the hide animation
    // Use a timeout equal to the transition duration to set visibility to false
    setTimeout(() => {
      setIsOverlayVisibleAdd1(false);
      // fetchDataLV1();
    }, 300); // 300ms is the duration of the exit transition
  };
  const location = useLocation();

  const isActive = (path: string) => {
    // Check if the current location is the index route of Organizzazione
    return location.pathname === "/Cybersecurity/Documentazione" && path === "Documentazione";
  };

  return (
    <FrameBoardMain>
      <MainBoardSubMenuButtonContainer>
        <MainBoardSubMenuButton to="/Cybersecurity/Documentazione" className={isActive("Documentazione") ? "active" : ""}>
          Documentazione
        </MainBoardSubMenuButton>
      </MainBoardSubMenuButtonContainer>

      <FlexContainerCrea>
        <CreaContainerColumn>
          <DocumentazioneBG>
            <InputContainer>
              <InputTextboxButton>
                <ButtonDescriptionText>CISO</ButtonDescriptionText>
                <IconContainer />
                <TriangleContainer>▶</TriangleContainer>
              </InputTextboxButton>
            </InputContainer>

            <InputContainer>
              <InputTextboxButton onClick={toggleOverlayAdd1}>
                <ButtonDescriptionText>Lettere d'incarico</ButtonDescriptionText>
                <IconContainer />
                <TriangleContainer>▶</TriangleContainer>
              </InputTextboxButton>
            </InputContainer>

            <InputContainer>
              <InputTextboxButton>
                <ButtonDescriptionText>Policy</ButtonDescriptionText>
                <IconContainer />
                <TriangleContainer>▶</TriangleContainer>
              </InputTextboxButton>
            </InputContainer>

            <InputContainer>
              <InputTextboxButton>
                <ButtonDescriptionText>Procedure</ButtonDescriptionText>
                <IconContainer />
                <TriangleContainer>▶</TriangleContainer>
              </InputTextboxButton>
            </InputContainer>

            <InputContainer>
              <InputTextboxButton>
                <ButtonDescriptionText>Pianificazione</ButtonDescriptionText>
                <IconContainer />
                <TriangleContainer>▶</TriangleContainer>
              </InputTextboxButton>
            </InputContainer>

            <InputContainer>
              <InputTextboxButton>
                <ButtonDescriptionText>Analisi efficienza e revisione</ButtonDescriptionText>
                <IconContainer />
                <TriangleContainer>▶</TriangleContainer>
              </InputTextboxButton>
            </InputContainer>

            <InputContainer>
              <InputTextboxButton>
                <ButtonDescriptionText>Audit</ButtonDescriptionText>
                <IconContainer />
                <TriangleContainer>▶</TriangleContainer>
              </InputTextboxButton>
            </InputContainer>
          </DocumentazioneBG>
        </CreaContainerColumn>

        <CreaContainerColumn>
          <DocumentazioneBG>
            <InputContainer>
              <InputTextboxButton>
                <ButtonDescriptionText>Formazione</ButtonDescriptionText>
                <IconContainer />
                <TriangleContainer>▶</TriangleContainer>
              </InputTextboxButton>
            </InputContainer>

            <InputContainer>
              <InputTextboxButton>
                <ButtonDescriptionText>Risk Management</ButtonDescriptionText>

                <IconContainer />
                <TriangleContainer>▶</TriangleContainer>
              </InputTextboxButton>
            </InputContainer>

            <InputContainer>
              <InputTextboxButton>
                <ButtonDescriptionText>Registri</ButtonDescriptionText>
                <IconContainer />
                <TriangleContainer>▶</TriangleContainer>
              </InputTextboxButton>
            </InputContainer>

            <InputContainer>
              <InputTextboxButton>
                <ButtonDescriptionText>Sicurezza dati</ButtonDescriptionText>
                <IconContainer />
                <TriangleContainer>▶</TriangleContainer>
              </InputTextboxButton>
            </InputContainer>

            <InputContainer>
              <InputTextboxButton>
                <ButtonDescriptionText>Data breach</ButtonDescriptionText>
                <IconContainer />
                <TriangleContainer>▶</TriangleContainer>
              </InputTextboxButton>
            </InputContainer>

            <InputContainer>
              <InputTextboxButton>
                <ButtonDescriptionText>Terze parti</ButtonDescriptionText>
                <IconContainer />
                <TriangleContainer>▶</TriangleContainer>
              </InputTextboxButton>
            </InputContainer>

            <InputContainer>
              <InputTextboxButton>
                <ButtonDescriptionText>Richieste d'accesso</ButtonDescriptionText>
                <IconContainer />
                <TriangleContainer>▶</TriangleContainer>
              </InputTextboxButton>
            </InputContainer>
          </DocumentazioneBG>
        </CreaContainerColumn>

        <CreaContainerColumn>
          <DocumentazioneBG>
            <InputContainer>
              <InputTextboxButton>
                <ButtonDescriptionText>Conservazione dati</ButtonDescriptionText>
                <IconContainer />
                <TriangleContainer>▶</TriangleContainer>
              </InputTextboxButton>
            </InputContainer>

            <InputContainer>
              <InputTextboxButton>
                <ButtonDescriptionText>Informative e consensi</ButtonDescriptionText>
                <IconContainer />
                <TriangleContainer>▶</TriangleContainer>
              </InputTextboxButton>
            </InputContainer>

            <InputContainer>
              <InputTextboxButton>
                <ButtonDescriptionText>Altro</ButtonDescriptionText>
                <IconContainer />
                <TriangleContainer>▶</TriangleContainer>
              </InputTextboxButton>
            </InputContainer>
          </DocumentazioneBG>
        </CreaContainerColumn>
      </FlexContainerCrea>

      <OverlayContext.Provider
        value={{
          closeOverlay: closeOverlayAdd1,
          selectedL1,
          setSelectedL1,
          selectedL2,
          setSelectedL2,
          selectedL3,
          setSelectedL3,
          selectedL4,
          setSelectedL4,
        }}
      >
        {isOverlayVisibleAdd1 && (
          <OverlayComponentAdd1 isAnimating={isOverlayAnimatingAdd1}>
            {/* <ButtonClose src="/close.svg" onClick={closeOverlayAdd1} /> */}
            <FadeOutComponent>
              <FrameTopGradient100></FrameTopGradient100>
            </FadeOutComponent>
            <Outlet />
          </OverlayComponentAdd1>
        )}
      </OverlayContext.Provider>
    </FrameBoardMain>
  );
};

export default Board27001Organizzazione;
