import { Accordion, AccordionDetails, AccordionSummary, Box, Checkbox, FormControlLabel, Typography } from "@mui/material";
import Divider from "@mui/material/Divider";
import React, { useCallback, useContext, useEffect, useRef, useState } from "react";
import { useRecoilState } from "recoil";
import styled from "styled-components";
import { isLoadingRec, isMessageBoxVisibleRec, SelectedL4ID, setErrorRec, setMessageRec } from "../../../../../recoil";
import { OverlayContext } from "../../cybersecurity-board-contestoleadership-organizzazione";
import {
  Backdrop,
  BackdropTransparent,
  CloseMessageButton,
  CreaContainerColumn,
  CustomDropdownItem,
  CustomDropdownList,
  DescriptionContainerCreaMultiple,
  DescriptionContainerCreaText,
  DropDownMenuScrollbar,
  FlexContainerCrea,
  IconContainer,
  IconSquare,
  InputContainer,
  InputDescriptionText,
  InputDescriptionTextDatePicker,
  InputTextbox,
  InputTextboxButton,
  LastPopupButtonContainer,
  LastPopupCheckboxContainer,
  LastPopupHeaderContainer,
  LastPopupScrollbar,
  LastPopupSearch,
  LastPopupSearchIcon,
  LastPopupSearchInputContainer,
  LastPopupTitleContainer,
  MessageboxText,
  MessageContainer,
  OverlayComponentWhiteAdd1Multiple,
  TriangleContainer,
} from "../../cybersecurity-board-contestoleadership-organizzazione-sub/cybersecurity-board-organizzazione-organigramma-styled-components";

import CancelIcon from "@mui/icons-material/Clear";
import { DatePicker } from "@mui/x-date-pickers";
import dayjs from "dayjs";
import { URL_DirectorList, URL_ProcessesSelected, useToken } from "../../../../../API";
import { ArrowButtonText, ButtonGrey, ButtonText } from "../../../styled-components";
import {
  BusinessProcess,
  BusinessProcesses,
  GroupedData,
  L2AddComplianceDataState,
  L2AddComplianceDate,
  L2AddComplianceisVisible,
  L2AddComplianceProcessByUnitByProcess,
  L2AddComplianceSearchTerm,
  L2AddDirectorCompliance,
  L2AddDirectorComplianceID,
  L2AddUnitComplianceAssociationState,
  showL2selectedCheckboxesProcessiState,
} from "../L2-Add-recoil";

interface RolesAccordionProps {}

interface LastPopupProps {
  isVisible: boolean;
}

const LastPopupBlack = styled.div<LastPopupProps>`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 99;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: opacity 0.3s ease, visibility 0.3s ease;
  opacity: ${({ isVisible }) => (isVisible ? 1 : 0)};
  visibility: ${({ isVisible }) => (isVisible ? "visible" : "hidden")};
`;

const LastPopup = styled.div`
  font-family: var(--font-titillium-web);
  font-size: var(--font-size-micro);
  color: var(--color-steelblue-700);
  position: fixed;
  right: 0px;
  top: 0px;
  bottom: 0px;
  width: 75%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: var(--color-aliceblue);

  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.25);

  z-index: 100;
`;

interface LastPopupProps2 {
  isVisible: boolean;
}

const LastPopupBlack2 = styled.div<LastPopupProps2>`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 99;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: opacity 0.3s ease, visibility 0.3s ease;
  opacity: ${({ isVisible }) => (isVisible ? 1 : 0)};
  visibility: ${({ isVisible }) => (isVisible ? "visible" : "hidden")};
`;

const LastPopup2 = styled.div`
  font-family: var(--font-titillium-web);
  font-size: var(--font-size-micro);
  color: var(--color-steelblue-700);
  position: fixed;
  right: 0px;
  top: 0px;
  bottom: 0px;
  width: 75%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: var(--color-aliceblue);

  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.25);

  z-index: 100;
`;

const useFetchAggrData = (token: string | null | undefined) => {
  const [isLoading, setIsLoading] = useRecoilState(isLoadingRec);
  const [error, setError] = useRecoilState<string | null>(setErrorRec);
  const [isMessageBoxVisible, setIsMessageBoxVisible] = useRecoilState(isMessageBoxVisibleRec);
  const [message, setMessage] = useRecoilState(setMessageRec);
  const [complianceData, setComplianceData] = useRecoilState(L2AddComplianceDataState);
  const [selectedId, setSelectedId] = useRecoilState(L2AddComplianceProcessByUnitByProcess);
  const [directorAliases, setDirectorAliases] = useState<{ [key: string]: string }>({});
  const [selectedCheckboxes, setSelectedCheckboxes] = useRecoilState(showL2selectedCheckboxesProcessiState);

  useEffect(() => {
    const fetchDirectorAliases = async () => {
      try {
        const response = await fetch(URL_DirectorList, {
          headers: { Authorization: `Bearer ${token}` },
        });
        if (!response.ok) {
          throw new Error(`Director list API request failed: ${response.statusText}`);
        }
        const data = await response.json();
        const aliasesMap = data.reduce((acc: { [key: string]: string }, item: { id: string; alias: string }) => {
          acc[item.id] = item.alias;
          return acc;
        }, {});
        setDirectorAliases(aliasesMap);
      } catch (err) {
        console.error("Failed to fetch director aliases:", err);
        setError(err instanceof Error ? err.message : "An unknown error occurred while fetching director aliases");
      }
    };

    const fetchComplianceData = async () => {
      setIsLoading(true);
      setError(null);
      const groupedData: GroupedData = {};
      try {
        const businessProcesses: BusinessProcesses = {};

        for (const id of selectedCheckboxes) {
          const url = `${URL_ProcessesSelected}${id}`;

          const response = await fetch(url, {
            headers: { Authorization: `Bearer ${token}` },
          });
          if (!response.ok) {
            throw new Error(`API request failed for ${id}: ${response.statusText}`);
          }
          const data = await response.json();
          console.log("Raw Data for ID", id, ":", data);

          if (Array.isArray(data)) {
            data
              .filter((item) => item.selected)
              .forEach((item) => {
                const businessId = item.content.has_business_process;
                const businessProcess = item.content.business_process;
                const complianceProcess = item.content.compliance_process;

                const kind = businessProcess.kind;

                if (!groupedData[kind]) {
                  groupedData[kind] = {
                    kind,
                    businessProcesses: {},
                  };
                }

                if (!groupedData[kind].businessProcesses[businessId]) {
                  groupedData[kind].businessProcesses[businessId] = {
                    id: businessId,
                    name: businessProcess.name,
                    kind: businessProcess.kind,
                    complianceProcesses: [],
                  };
                }

                groupedData[kind].businessProcesses[businessId].complianceProcesses.push({
                  id: complianceProcess.id,
                  name: complianceProcess.name,
                  description: complianceProcess.description,
                  kind: complianceProcess.kind,
                  norm_ref: complianceProcess.norm_ref,
                  note: complianceProcess.note,
                  has_compliance_responsible: complianceProcess.has_compliance_responsible,
                  compliance_responsible_assignment_start: complianceProcess.compliance_responsible_assignment_start || "da assegnare",
                  compliance_responsible_alias: directorAliases[complianceProcess.id] || "da assegnare",
                });
              });
          } else {
            setError("Data received is not in expected format for ID " + id);
            setComplianceData({});
          }
        }
        console.log("Refactored body: ", groupedData);
        setComplianceData(groupedData);
      } catch (err) {
        setError(err instanceof Error ? err.message : "An unknown error occurred");
      } finally {
        setIsLoading(false);
      }
    };

    fetchDirectorAliases();
    fetchComplianceData();
  }, [token, selectedCheckboxes]);

  return { isLoading, error, complianceData };
};

const RolesAccordion: React.FC<RolesAccordionProps> = () => {
  const [complianceData, setComplianceData] = useRecoilState(L2AddComplianceDataState);
  const [isLoading, setIsLoading] = useRecoilState(isLoadingRec);
  const [error, setError] = useRecoilState<string | null>(setErrorRec);
  const [isMessageBoxVisible, setIsMessageBoxVisible] = useRecoilState(isMessageBoxVisibleRec);
  const [message, setMessage] = useRecoilState(setMessageRec);
  const [searchTerm, setSearchTerm] = useRecoilState(L2AddComplianceSearchTerm);
  const [selectedCheckboxes, setSelectedCheckboxes] = useRecoilState(showL2selectedCheckboxesProcessiState);

  console.log("Accordion data: ", complianceData);

  const handleCheckboxChange = useCallback(
    (itemId: string, checked: boolean) => {
      console.log("Checkbox ID before update: ", itemId);
      setSelectedCheckboxes((currentSelected) => {
        const updatedSelected = checked ? [...currentSelected, itemId] : currentSelected.filter((id) => id !== itemId);

        console.log("Updated selected checkboxes inside callback: ", updatedSelected);
        return updatedSelected;
      });
    },
    [setSelectedCheckboxes]
  );

  const filteredComplianceData = (searchTerm: string, complianceData: GroupedData): GroupedData => {
    const filteredData: GroupedData = {};

    for (const [kindKey, kindValue] of Object.entries(complianceData)) {
      const filteredBusinessProcesses = Object.values(kindValue.businessProcesses).filter(
        (businessProcess) =>
          businessProcess.name.toLowerCase().includes(searchTerm.toLowerCase()) ||
          businessProcess.kind.toLowerCase().includes(searchTerm.toLowerCase()) ||
          businessProcess.complianceProcesses.some(
            (complianceProcess) =>
              complianceProcess.name.toLowerCase().includes(searchTerm.toLowerCase()) ||
              complianceProcess.description.toLowerCase().includes(searchTerm.toLowerCase()) ||
              complianceProcess.norm_ref.toLowerCase().includes(searchTerm.toLowerCase()) ||
              complianceProcess.compliance_responsible_alias.toLowerCase().includes(searchTerm.toLowerCase())
          )
      );

      if (filteredBusinessProcesses.length > 0) {
        filteredData[kindKey] = {
          kind: kindValue.kind,
          businessProcesses: filteredBusinessProcesses.reduce((acc, businessProcess) => {
            acc[businessProcess.id] = businessProcess;
            return acc;
          }, {} as { [id: string]: BusinessProcess }),
        };
      }
    }

    return filteredData;
  };

  return (
    <Box sx={{ mt: 2 }}>
      {Object.entries(filteredComplianceData(searchTerm, complianceData)).map(([categoryKey, category]) => (
        <Accordion key={categoryKey} expanded={true}>
          <AccordionSummary expandIcon={null}>
            <Typography color="#04446c">{category.kind}</Typography>
          </AccordionSummary>
          <AccordionDetails sx={{ flexDirection: "column" }}>
            {isLoading && <Typography>Loading...</Typography>}
            {error && <Typography color="error">{error}</Typography>}
            {Object.values(category.businessProcesses).map((businessProcess, roleIndex) => (
              <Accordion expanded={true} key={businessProcess.id} sx={{ mt: 1, boxShadow: "0px 2px 6px rgba(0, 0, 0, 0.25)" }}>
                <AccordionSummary sx={{ paddingTop: "0px", fontStyle: "italic", marginBottom: "-15px" }}>
                  <Typography>{businessProcess.name}:</Typography>
                </AccordionSummary>
                <AccordionDetails>
                  {businessProcess.complianceProcesses.map((complianceProcess, index) => (
                    <Box key={complianceProcess.id} sx={{ mt: 1 }}>
                      <Divider sx={{ my: 2, marginTop: "0px" }} />
                      <Typography>
                        <span style={{ color: "#04446C" }}>Denominazione:</span> {complianceProcess.name}
                      </Typography>
                      <Typography>
                        <span style={{ color: "#04446C" }}>Descrizione:</span> {complianceProcess.description}
                      </Typography>
                      <Typography>
                        <span style={{ color: "#04446C" }}>Riferimento normativo:</span> {complianceProcess.norm_ref}
                      </Typography>
                      <Typography>
                        <span style={{ color: "#04446C" }}>Responsabile:</span> {complianceProcess.compliance_responsible_alias}
                      </Typography>
                      <Typography>
                        <span style={{ color: "#04446C" }}>Data inizio incarico:</span> {complianceProcess.compliance_responsible_assignment_start}
                      </Typography>

                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={true}
                            onChange={(e) => handleCheckboxChange(complianceProcess.name, e.target.checked)}
                            inputProps={{ "aria-label": "Is Responsible" }}
                            disabled={false}
                            sx={{
                              color: "#425a77",
                              "& .MuiSvgIcon-root": { fontSize: 16 },
                              "&.Mui-checked": { color: "#425a77" },
                              padding: "0px",
                              marginRight: "10px",
                            }}
                          />
                        }
                        label="Questo processo Compliance é associato alla persona selezionata"
                        sx={{
                          marginTop: "10px",
                          marginLeft: "0px",
                          color: "#425a77",
                          display: "flex",
                          flexDirection: "row",
                          justifyContent: "flex-start",
                          alignItems: "center",
                        }}
                        labelPlacement="end"
                      />
                    </Box>
                  ))}
                </AccordionDetails>
              </Accordion>
            ))}
          </AccordionDetails>
        </Accordion>
      ))}
    </Box>
  );
};

interface SearchInputProps {
  value: string;
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
}

const SearchInput: React.FC<SearchInputProps> = React.memo(({ value, onChange }) => {
  const inputRef = useRef<HTMLInputElement>(null);
  useEffect(() => {
    if (inputRef.current) {
      inputRef.current.focus();
    }
  }, []);
  return <LastPopupSearch ref={inputRef} type="text" value={value} onChange={onChange} placeholder="Cerca" />;
});

const MainComponent = () => {
  const [searchTerm, setSearchTerm] = useRecoilState(L2AddComplianceSearchTerm);

  const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchTerm(event.target.value);
  };

  const [L4ID, setL4ID] = useRecoilState(SelectedL4ID);
  const token = useToken();
  const [isLoading, setIsLoading] = useRecoilState(isLoadingRec);
  const [error, setError] = useRecoilState<string | null>(setErrorRec);
  const [isMessageBoxVisible, setIsMessageBoxVisible] = useRecoilState(isMessageBoxVisibleRec);
  const [message, setMessage] = useRecoilState(setMessageRec);

  const [complianceData, setComplianceData] = useRecoilState(L2AddComplianceDataState);

  const [date, setDate] = useRecoilState(L2AddComplianceDate);

  const [director, setDirector] = useRecoilState(L2AddDirectorCompliance);
  const [directorID, setDirectorID] = useRecoilState(L2AddDirectorComplianceID);

  const [, setSelectedUnitAssociations] = useRecoilState(L2AddUnitComplianceAssociationState);

  useFetchAggrData(token);

  const { closeOverlay } = useContext(OverlayContext);

  const handleCloseAndNavigate = () => {
    setIsMessageBoxVisible(false);
    closeOverlay();
  };

  const [isVisible, setIsVisible] = useRecoilState(L2AddComplianceisVisible);
  const togglePopup = () => {
    setIsVisible(!isVisible);
  };

  const ClearLV1 = () => {
    setDirector("");
    setDirectorID("");
  };

  const [data, setData] = useState<DirectorDataItem[]>([]);

  type DirectorApiContentItem = {
    id: string;
    alias: string;
  };

  type DirectorDataItem = {
    director_id: string;
    director_name: string;
  };

  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true);
      try {
        if (!token) throw new Error("User is not authenticated");

        const response = await fetch(URL_DirectorList, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });

        if (!response.ok) {
          throw new Error(`API request failed: ${response.statusText}`);
        }

        const responseData = await response.json();
        console.log("Response Data:", responseData);

        const transformedData: DirectorDataItem[] = responseData.map((item: DirectorApiContentItem) => {
          return {
            director_id: item?.id,
            director_name: item?.alias,
          };
        });

        console.log("Transformed Data:", transformedData);
        setData(transformedData);
      } catch (err) {
        setError(err instanceof Error ? err.message : "An unknown error occurred");
        setIsMessageBoxVisible(true);
      } finally {
        setIsLoading(false);
      }
    };

    fetchData();
  }, [token]);

  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const dropdownRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    function handleClickOutside(event: MouseEvent) {
      if (dropdownRef.current && event.target instanceof Node && !dropdownRef.current.contains(event.target)) {
        setIsDropdownOpen(false);
      }
    }

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const handleDropdownToggle = () => {
    setDirector("");

    setDirectorID("");

    setIsDropdownOpen(!isDropdownOpen);
  };

  const handleOptionSelect = (selectedDirectorId: string) => {
    const selectedDirectorName = data.find((item) => item.director_id === selectedDirectorId)?.director_name;

    if (selectedDirectorName) {
      setDirector(selectedDirectorName);

      setDirectorID(selectedDirectorId);
    }

    setIsDropdownOpen(false);
  };

  useEffect(() => {
    console.log("Selected Director ID:", directorID);
  }, [directorID]);

  const directorNameToId = new Map(data.map((item) => [item.director_name, item.director_id]));

  const handleDirectorChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;
    setDirector(value);

    if (directorNameToId.has(value)) {
      setDirectorID(directorNameToId.get(value) || "");
      console.log(directorNameToId.get(value) || "");
    }
  };

  return (
    <OverlayComponentWhiteAdd1Multiple>
      {isLoading && (
        <BackdropTransparent>
          <MessageContainer>
            <MessageboxText>Loading...</MessageboxText>
          </MessageContainer>
        </BackdropTransparent>
      )}

      {isMessageBoxVisible && (
        <Backdrop>
          <MessageContainer>
            <p>
              <MessageboxText>{message}</MessageboxText>
            </p>
            <CloseMessageButton onClick={handleCloseAndNavigate}>
              <MessageboxText>Close</MessageboxText>
            </CloseMessageButton>
          </MessageContainer>
        </Backdrop>
      )}

      {error && (
        <Backdrop>
          <MessageContainer>
            <p>
              <MessageboxText>{error}</MessageboxText>
            </p>
            <CloseMessageButton
              onClick={() => {
                setIsMessageBoxVisible(false);
                setError(null);
                closeOverlay();
              }}
            >
              <MessageboxText>Close</MessageboxText>
            </CloseMessageButton>
          </MessageContainer>
        </Backdrop>
      )}
      <FlexContainerCrea>
        <CreaContainerColumn>
          <InputContainer>
            <InputDescriptionText>Responsabile Compliance</InputDescriptionText>

            <InputTextbox list="directors-list" value={director} onChange={handleDirectorChange} onClick={handleDropdownToggle} placeholder="Seleziona" />
            {director && (
              <CancelIcon
                style={{
                  fontSize: "16px",
                  cursor: "pointer",
                  position: "absolute",
                  right: "30px",
                  top: "32%",
                  transform: "translateY(-50%)",
                  color: "#c6c6c6",
                }}
                onClick={ClearLV1}
              />
            )}
            <TriangleContainer>
              {isDropdownOpen ? "▲" : "▼"} {/* Change icons as needed */}
            </TriangleContainer>

            {isDropdownOpen && (
              <CustomDropdownList>
                <DropDownMenuScrollbar ref={dropdownRef}>
                  {data
                    .filter((item) => item.director_name.toLowerCase().includes(director.toLowerCase()))
                    .map((filteredItem, index) => (
                      <CustomDropdownItem key={index} onClick={() => handleOptionSelect(filteredItem.director_id)}>
                        {filteredItem.director_name}
                      </CustomDropdownItem>
                    ))}
                </DropDownMenuScrollbar>
              </CustomDropdownList>
            )}
            <IconContainer />
          </InputContainer>

          <InputContainer>
            <InputDescriptionText>Processi Compliance</InputDescriptionText>
            <InputTextboxButton onClick={togglePopup}>
              <ArrowButtonText>Apri per visualizzare</ArrowButtonText>
            </InputTextboxButton>
            <IconContainer />
            <TriangleContainer>▶</TriangleContainer>
          </InputContainer>
        </CreaContainerColumn>

        <CreaContainerColumn>
          <InputContainer>
            <InputDescriptionTextDatePicker>Data inizio incarico</InputDescriptionTextDatePicker>
            <DatePicker
              value={date}
              format="DD-MM-YYYY"
              onChange={(newDate: dayjs.Dayjs | null) => setDate(newDate)}
              sx={{
                "& .MuiInputBase-input": {
                  height: "2px",
                },
                ".MuiInputBase-root": {
                  borderRadius: "0px",
                  backgroundColor: "#ffffff",
                },
                ".MuiInputBase-input": {
                  color: "#0e163d",
                  fontSize: "15px",
                  fontFamily: "Titillium Web",
                },

                ".MuiSvgIcon-root": {
                  color: "#808080",
                  fontSize: "1.25rem",
                },

                width: "100%",
              }}
            />

            <IconContainer />
          </InputContainer>
        </CreaContainerColumn>

        <LastPopupBlack isVisible={isVisible}>
          <LastPopup>
            <LastPopupTitleContainer>LISTA PROCESSI COMPLIANCE</LastPopupTitleContainer>
            <LastPopupCheckboxContainer>
              <LastPopupHeaderContainer>
                <LastPopupSearchInputContainer>
                  <SearchInput value={searchTerm} onChange={handleSearchChange} />
                  <LastPopupSearchIcon src="/search.svg" />
                </LastPopupSearchInputContainer>
              </LastPopupHeaderContainer>

              <LastPopupScrollbar>
                <RolesAccordion />
              </LastPopupScrollbar>
            </LastPopupCheckboxContainer>
            <LastPopupButtonContainer>
              <div style={{ display: "flex", gap: "20px", justifyContent: "center" }}>
                <ButtonGrey onClick={togglePopup}>
                  <ButtonText>Annulla</ButtonText>
                </ButtonGrey>

                <ButtonGrey onClick={togglePopup}>
                  <ButtonText>Salva</ButtonText>
                </ButtonGrey>
              </div>
            </LastPopupButtonContainer>
          </LastPopup>
        </LastPopupBlack>
      </FlexContainerCrea>
      <DescriptionContainerCreaMultiple>
        <IconSquare src="/description_show_compliance.svg" alt="Description Icon" />
        <DescriptionContainerCreaText>
          La scheda specifica gli elementi del sistema di gestione della Compliance definita basandosi sulla struttura organizzativa mappata precedentemente e in linea con il contesto attuale. Questo
          assicura il rispetto delle normative vigenti e la trasparenza nell'ambito della sicurezza delle informazioni.
        </DescriptionContainerCreaText>
      </DescriptionContainerCreaMultiple>
    </OverlayComponentWhiteAdd1Multiple>
  );
};

export default MainComponent;
