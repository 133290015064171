import { Accordion, AccordionDetails, AccordionSummary, Box, Checkbox, FormControl, FormControlLabel, FormLabel, Grid, Radio, RadioGroup, Typography } from "@mui/material";
import Divider from "@mui/material/Divider";
import React, { useCallback, useContext, useEffect, useMemo, useRef, useState } from "react";
import { useRecoilState } from "recoil";
import styled from "styled-components";
import { isLoadingRec, isMessageBoxVisibleRec, SelectedL4ID, setErrorRec, setMessageRec } from "../../../../../recoil";
import { OverlayContext } from "../../cybersecurity-board-contestoleadership-organizzazione";
import {
  Backdrop,
  BackdropTransparent,
  CloseMessageButton,
  CreaContainerColumn,
  DescriptionContainerCreaMultiple,
  DescriptionContainerCreaText,
  FlexContainerCrea,
  IconContainer,
  IconSquare,
  InputContainer,
  InputDescriptionText,
  InputTextboxButton,
  LastPopupButtonContainer,
  LastPopupCheckboxContainer,
  LastPopupHeaderContainer,
  LastPopupScrollbar,
  LastPopupSearch,
  LastPopupSearchIcon,
  LastPopupSearchInputContainer,
  LastPopupTitleContainer,
  MessageboxText,
  MessageContainer,
  OverlayComponentWhiteAdd1Multiple,
  TriangleContainer
} from "../cybersecurity-board-organizzazione-organigramma-styled-components";

import { URL_ProcessList, useToken } from "../../../../../API";
import { ArrowButtonText, ButtonGrey, ButtonText } from "../../../styled-components";

import { TAB_DESCRIPTION_GLOBAL, TAB_DESCRIPTION_L2_4 } from "../../tabs-description";
import {
  addL2ComplianceData,
  addL2ComplianceDataState,
  addL2Model,
  addL2ModelDataState,
  addL2Role,
  addL2RoleState,
  addL2searchTerm,
  addL2selectedAggrId,
  addL2selectedCheckboxesProcessiState,
  addL2selectedModelId,
  addL2selectedModelName,
} from "../L2-Add-recoil";

interface RolesAccordionProps {
  token: string | null | undefined;
}

interface ModelSelectorProps {
  models: addL2Model[];
  selectedModelId: string;
  onModelChange: (newModelId: string) => void;
}

type GroupedModels = {
  [key: string]: {
    id: string;
    organizational_unit: {
      id: string;
      prefLabel: string;
    };
    company_role: addL2Role[];
  };
};

interface LastPopupProps {
  isVisible: boolean;
}

const LastPopupBlack = styled.div<LastPopupProps>`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 99;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: opacity 0.3s ease, visibility 0.3s ease;
  opacity: ${({ isVisible }) => (isVisible ? 1 : 0)};
  visibility: ${({ isVisible }) => (isVisible ? "visible" : "hidden")};
`;

const LastPopup = styled.div`
  font-family: var(--font-titillium-web);
  font-size: var(--font-size-micro);
  color: var(--color-steelblue-700);
  position: fixed;
  right: 0px;
  top: 0px;
  bottom: 0px;
  width: 75%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: var(--color-aliceblue);

  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.25);

  z-index: 100;
`;

const ModelSelector: React.FC<ModelSelectorProps> = ({ models, onModelChange, selectedModelId }) => {
  return (
    <FormControl component="fieldset">
      <FormLabel component="legend" sx={{ color: "#6f8aab", padding: "8px" }}></FormLabel>
      <RadioGroup aria-label="model" name="model" value={selectedModelId} onChange={(event) => onModelChange(event.target.value)} sx={{ padding: "5px 10px 10px 20px" }}>
        {models.map((model) => (
          <FormControlLabel
            key={model.organizational_unit.id}
            value={model.organizational_unit.id}
            control={
              <Radio
                sx={{
                  "& .MuiSvgIcon-root": { fontSize: "1rem", color: "#6f8aab" },
                }}
              />
            }
            label={model.organizational_unit.prefLabel}
            sx={{
              color: "#6f8aab",
              "& .MuiTypography-root": { fontSize: "0.875rem" },
            }}
          />
        ))}
      </RadioGroup>
    </FormControl>
  );
};

const RolesAccordion: React.FC<RolesAccordionProps> = ({ token }) => {
  const { complianceData } = useFetchComplianceData(token);
  const [isLoading, setIsLoading] = useRecoilState(isLoadingRec);
  const [error, setError] = useRecoilState<string | null>(setErrorRec);
  const [searchTerm, setSearchTerm] = useRecoilState(addL2searchTerm);
  const [selectedCheckboxes, setSelectedCheckboxes] = useRecoilState(addL2selectedCheckboxesProcessiState);

  const handleCheckboxChange = useCallback(
    (itemId: string, checked: boolean) => {
      console.log("Checkbox ID before update: ", itemId);
      setSelectedCheckboxes((currentSelected) => {
        const updatedSelected = checked ? [...currentSelected, itemId] : currentSelected.filter((id) => id !== itemId);

        console.log("Updated selected checkboxes inside callback: ", updatedSelected);
        return updatedSelected;
      });
    },
    [setSelectedCheckboxes]
  );

  const filteredComplianceData = useMemo(
    () =>
      searchTerm
        ? complianceData.filter(
            (dataItem) =>
              dataItem.name.toLowerCase().includes(searchTerm.toLowerCase()) ||
              dataItem.description.toLowerCase().includes(searchTerm.toLowerCase()) ||
              dataItem.kind.toLowerCase().includes(searchTerm.toLowerCase()) ||
              dataItem.objective.toLowerCase().includes(searchTerm.toLowerCase())
          )
        : complianceData,
    [complianceData, searchTerm]
  );

  return (
    <Box sx={{ mt: 2, ml: 4, width: "100%" }}>
      <Accordion expanded={true}>
        <AccordionSummary
          sx={{
            height: "10px",
            minHeight: "10px !important",
            padding: "0px",
            alignItems: "center",
          }}
        >
          <Typography color="#04446c"></Typography>
        </AccordionSummary>
        <AccordionDetails sx={{ flexDirection: "column" }}>
          {isLoading && <Typography>Loading...</Typography>}
          {error && <Typography color="error">{error}</Typography>}
          {Object.entries(groupComplianceByBusinessProcess(filteredComplianceData)).length > 0 ? (
            Object.entries(groupComplianceByBusinessProcess(filteredComplianceData)).map(([businessProcessId, complianceItems]) => (
              <Accordion expanded={true} key={businessProcessId} sx={{ mt: 1, boxShadow: "0px 2px 6px rgba(0, 0, 0, 0.25)" }}>
                <AccordionSummary
                  sx={{
                    paddingTop: "0px",
                    fontWeight: 500,
                    fontStyle: "italic",
                    marginBottom: "-20px",
                  }}
                >
                  <Typography>{complianceItems[0]?.name}</Typography>
                </AccordionSummary>
                <AccordionDetails>
                  {complianceItems.map((item, index) => (
                    <Box key={index} sx={{ mt: 1 }}>
                      <Divider sx={{ my: 2 }} />
                      <Typography>
                        <br />
                      </Typography>
                      <Typography>Categoria: {item.kind}</Typography>
                      <Typography>
                        <br />
                      </Typography>
                      <Typography>Obiettivo: {item.objective}</Typography>
                      <Typography>
                        <br />
                      </Typography>
                      <Typography>Descrizione: {item.description}</Typography>
                      <Typography>
                        <br />
                      </Typography>
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={selectedCheckboxes.includes(item.id)}
                            onChange={(e) => handleCheckboxChange(item.id, e.target.checked)}
                            inputProps={{ "aria-label": "Is Responsible" }}
                            disabled={false}
                            sx={{
                              color: "#04446c",
                              "& .MuiSvgIcon-root": { fontSize: 16 },
                              "&.Mui-checked": { color: "#04446c" },
                              padding: "0px",
                              marginRight: "10px",
                            }}
                          />
                        }
                        label="Questo processo é associato all'unità selezionata"
                        sx={{
                          marginTop: "10px",
                          marginLeft: "0px",
                          color: "#04446c",
                          display: "flex",
                          flexDirection: "row",
                          justifyContent: "flex-start",
                          alignItems: "center",
                        }}
                        labelPlacement="end"
                      />
                      <Typography>
                        <br />
                      </Typography>
                    </Box>
                  ))}
                </AccordionDetails>
              </Accordion>
            ))
          ) : (
            <Typography sx={{ textAlign: "center", width: "100%", my: 2 }}>Nessun dato esistente.</Typography>
          )}
        </AccordionDetails>
      </Accordion>
    </Box>
  );
};

const useFetchComplianceData = (token: string | null | undefined) => {
  const [complianceData, setComplianceData] = useRecoilState(addL2ComplianceDataState);
  const [isLoading, setIsLoading] = useRecoilState(isLoadingRec);
  const [error, setError] = useRecoilState(setErrorRec);
  const [selectedCheckboxes, setSelectedCheckboxes] = useRecoilState(addL2selectedCheckboxesProcessiState);
  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true);
      setError(null);

      try {
        const response = await fetch(URL_ProcessList, {
          headers: { Authorization: `Bearer ${token}` },
        });
        if (!response.ok) {
          throw new Error(`API request failed: ${response.statusText}`);
        }
        const data = await response.json();
        setComplianceData(data);
      } catch (err) {
        setError(err instanceof Error ? err.message : "An unknown error occurred");
      } finally {
        setIsLoading(false);
      }
    };

    if (token) {
      fetchData();
    }
  }, [token, setIsLoading, setError, setComplianceData]);

  return { complianceData };
};

const groupComplianceByBusinessProcess = (complianceData: addL2ComplianceData[]) => {
  return complianceData.reduce((acc, item) => {
    const bpId = item.id;
    if (!acc[bpId]) {
      acc[bpId] = [];
    }
    acc[bpId].push(item);
    return acc;
  }, {} as { [key: string]: addL2ComplianceData[] });
};

const useHandleModelChange = () => {
  const [Modeldata, setModelData] = useRecoilState(addL2ModelDataState);
  const [, setSelectedModelId] = useRecoilState(addL2selectedModelId);
  const [, setSelectedModelRoles] = useRecoilState(addL2RoleState);

  const handleModelChange = useCallback(
    (newModelId: string) => {
      setSelectedModelId(newModelId);

      const newModel = Modeldata.find((model) => model.organizational_unit.id === newModelId);
      if (newModel) {
        setSelectedModelRoles(newModel.company_role);
      } else {
        setSelectedModelRoles([]);
      }
    },
    [Modeldata, setSelectedModelId, setSelectedModelRoles]
  );

  return handleModelChange;
};

interface SearchInputProps {
  value: string;
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
}

const SearchInput: React.FC<SearchInputProps> = React.memo(({ value, onChange }) => {
  const inputRef = useRef<HTMLInputElement>(null);

  return <LastPopupSearch ref={inputRef} type="text" value={value} onChange={onChange} placeholder="Cerca" />;
});

const MainComponent = () => {
  const [L4ID, setL4ID] = useRecoilState(SelectedL4ID);
  const token = useToken();
  const [isLoading, setIsLoading] = useRecoilState(isLoadingRec);
  const [error, setError] = useRecoilState<string | null>(setErrorRec);
  const [isMessageBoxVisible, setIsMessageBoxVisible] = useRecoilState(isMessageBoxVisibleRec);
  const [message, setMessage] = useRecoilState(setMessageRec);
  const [searchTerm, setSearchTerm] = useRecoilState(addL2searchTerm);
  const handleModelChange = useHandleModelChange();
  const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchTerm(event.target.value);
  };

  const { closeOverlay } = useContext(OverlayContext);

  const handleCloseAndNavigate = () => {
    setIsMessageBoxVisible(false);
    closeOverlay();
  };

  const [isVisible, setIsVisible] = useState(false);
  const togglePopup = () => {
    setIsVisible(!isVisible);
  };

  const [ComplianceData, setComplianceData] = useRecoilState(addL2ComplianceDataState);
  const [Modeldata, setModelData] = useRecoilState(addL2ModelDataState);
  const [selectedModelId, setSelectedModelId] = useRecoilState(addL2selectedModelId);
  const [selectedModelName, setSelectedModelName] = useRecoilState(addL2selectedModelName);
  const [selectedAggrId, setSelectedAggrId] = useRecoilState(addL2selectedAggrId);
  const selectedModel = Modeldata.find((model) => model.organizational_unit.id === selectedModelId);
  const [selectedModelRoles, setSelectedModelRoles] = useRecoilState(addL2RoleState);

  const { complianceData } = useFetchComplianceData(token);

  useEffect(() => {
    if (complianceData) {
      setComplianceData(complianceData);
    }
  }, [complianceData]);

  return (
    <OverlayComponentWhiteAdd1Multiple>
      {isLoading && (
        <BackdropTransparent>
          <MessageContainer>
            <MessageboxText>Loading...</MessageboxText>
          </MessageContainer>
        </BackdropTransparent>
      )}

      {isMessageBoxVisible && (
        <Backdrop>
          <MessageContainer>
            <p>
              <MessageboxText>{message}</MessageboxText>
            </p>
            <CloseMessageButton onClick={handleCloseAndNavigate}>
              <MessageboxText>Close</MessageboxText>
            </CloseMessageButton>
          </MessageContainer>
        </Backdrop>
      )}

      {error && (
        <Backdrop>
          <MessageContainer>
            <p>
              <MessageboxText>{error}</MessageboxText>
            </p>
            <CloseMessageButton
              onClick={() => {
                setIsMessageBoxVisible(false);
                setError(null);
                closeOverlay();
              }}
            >
              <MessageboxText>Close</MessageboxText>
            </CloseMessageButton>
          </MessageContainer>
        </Backdrop>
      )}
      <FlexContainerCrea>
        <CreaContainerColumn>
          {/*       
        <InputContainer>
            <InputDescriptionText>Ruoli</InputDescriptionText>
            <InputTextboxButton onClick={togglePopup2} >

            <ArrowButtonText>Clicca per visualizzare</ArrowButtonText>
            </InputTextboxButton>
            <IconContainer />
            <TriangleContainer>▶</TriangleContainer>
          </InputContainer> */}

          <InputContainer>
            <InputDescriptionText>Processi generali</InputDescriptionText>
            <InputTextboxButton onClick={togglePopup}>
              <ArrowButtonText>Apri per modificare</ArrowButtonText>
            </InputTextboxButton>
            <IconContainer />
            <TriangleContainer>▶</TriangleContainer>
          </InputContainer>
        </CreaContainerColumn>

        <CreaContainerColumn></CreaContainerColumn>

        <LastPopupBlack isVisible={isVisible}>
          <LastPopup>
            <LastPopupTitleContainer>LISTA PROCESSI GENERALI</LastPopupTitleContainer>
            <LastPopupCheckboxContainer>
              <LastPopupHeaderContainer>
                <LastPopupSearchInputContainer>
                  <SearchInput value={searchTerm} onChange={handleSearchChange} />
                  <LastPopupSearchIcon src="/search.svg" />
                </LastPopupSearchInputContainer>
              </LastPopupHeaderContainer>

              <LastPopupScrollbar>
                <Grid container spacing={2} sx={{ width: "100%" }}>
                  {/* <Grid item xs={4}>
                    <Box
                      sx={{
                        backgroundColor: "#fff",  
                        borderRadius: "4px",  
                        boxShadow: "0 2px 4px rgba(0, 0, 0, 0.2)",  
                        padding: "0px 10px 10px 5px",  
                        marginTop: "16px",
                        marginLeft: "20px",
                      }}
                    >
                      <ModelSelector models={Modeldata} onModelChange={handleModelChange} selectedModelId={selectedModelId} />
                    </Box>
                  </Grid> */}

                  {complianceData && <RolesAccordion token={token} />}
                </Grid>
              </LastPopupScrollbar>
            </LastPopupCheckboxContainer>
            <LastPopupButtonContainer>
              <div
                style={{
                  display: "flex",
                  gap: "20px",
                  justifyContent: "center",
                }}
              >
                <ButtonGrey onClick={togglePopup}>
                  <ButtonText>Annulla</ButtonText>
                </ButtonGrey>

                <ButtonGrey onClick={togglePopup}>
                  <ButtonText>Salva</ButtonText>
                </ButtonGrey>
              </div>
            </LastPopupButtonContainer>
          </LastPopup>
        </LastPopupBlack>
      </FlexContainerCrea>
      <DescriptionContainerCreaMultiple>
        <IconSquare src="/description_show_process.svg" alt="Description Icon" />
        <DescriptionContainerCreaText>{TAB_DESCRIPTION_L2_4 ? TAB_DESCRIPTION_L2_4 : TAB_DESCRIPTION_GLOBAL}</DescriptionContainerCreaText>
      </DescriptionContainerCreaMultiple>
    </OverlayComponentWhiteAdd1Multiple>
  );
};

export default MainComponent;
