import { ChevronRight, ExpandMore } from "@mui/icons-material";
import { styled as muiStyled } from "@mui/system";
import { TreeItem, TreeView } from "@mui/x-tree-view";
import React, { useEffect, useRef, useState } from "react";
import { Outlet, useNavigate } from "react-router-dom";
import styled from "styled-components";
import { OverlayContext } from "../cybersecurity-board-contestoleadership-organizzazione";

import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";

import { MainBoardContentContainerInfrastruttura } from "../../styled-components";

import { useRecoilState } from "recoil";

import { SelectedL1ID, SelectedL2ID, SelectedL3ID, SelectedL4ID } from "../../../../recoil";

import CancelIcon from "@mui/icons-material/Clear";
import { URL_AssetTreeByPlace, URL_LV1List, URL_LV1_Detailed, URL_LV2List, URL_PlacesByUnit, useApi, useToken } from "../../../../API";
import {
  Backdrop,
  BackdropTransparent,
  CloseMessageButton,
  CustomDropdownItem,
  FadeOutComponent,
  FileButtonArrowGrey,
  FileButtonWhiteText,
  FrameTopGradient100,
  MainBoardContentContainerColumn,
  MessageContainer,
  MessageboxText,
  Search,
  SearchBarWithIconsRoot,
  Title,
  TitleText
} from "../cybersecurity-board-contestoleadership-organizzazione-sub/cybersecurity-board-organizzazione-organigramma-styled-components";
import {
  CustomDropdownListInfrastruttura,
  DropDownMenuScrollbarInfrastruttura,
  InputContainerHorizontal,
  InputTextboxHorizontal,
  LastPopupScrollbarInfrastrutturaL3,
  MainBoardContentContainerColumnInfratruttura50,
  MainBoardContentContainerColumnInfratrutturaContain,
  MainBoardContentContainerColumnInfratrutturaHorizontal,
  MainBoardContentContainerFileHorizontal,
  TitleTextHorizontal,
  TriangleContainerInfrastruttura
} from "./cybersecurity-board-infrastruttura-organigramma-styled-components";

const FileButtonWhite = styled.button<{ isActive: boolean }>`
  cursor: pointer;
  border: none;
  padding: 0;
  background-color: ${(props) => (props.isActive ? "#f3f7fb" : "var(--color-white)")};
  /* box-shadow: ${(props) => (props.isActive ? "0px 2px 3px rgba(0, 0, 0, 0.25)" : "0px 0px 0px rgba(0, 0, 0, 0.25)")}; */
  position: relative;
  border-bottom: 1px solid #f1f1f1;
  box-sizing: border-box;
  width: 100%;
  height: 50px;
  overflow: hidden;
  flex-shrink: 0;
  &:hover {
    background-color: #e9eff7;
  }
`;

interface OverlayComponentProps {
  isAnimating: boolean;
}

const OverlayComponentAdd1 = styled.div<OverlayComponentProps>`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  z-index: 10;
  transition: opacity 300ms ease-in-out, visibility 300ms ease-in-out;
  opacity: ${(props) => (props.isAnimating ? "1" : "0")};
  visibility: ${(props) => (props.isAnimating ? "visible" : "hidden")};
`;

interface ApiResponse {
  value: {
    id: string;
    creationTs: string;
    updateTs: string;
    createdBy: string;
    updatedBy: string;
    name: string;
    sub_category_of?: string;
  };
  leaves?: ApiResponse[];
}
interface TreebeardNode {
  id: string;
  name: string;
  children?: TreebeardNode[];
  toggled?: boolean;
}

const transformApiResponseToTreebeardFormat = (apiResponse: ApiResponse | ApiResponse[]): TreebeardNode | TreebeardNode[] => {
  const transformNode = (node: ApiResponse): TreebeardNode => ({
    id: node.value.id,
    name: node.value.name,
    children: node.leaves?.map(transformNode),
    toggled: false,
  });

  if (!Array.isArray(apiResponse)) {
    return transformNode(apiResponse);
  }

  return apiResponse.map(transformNode);
};

interface SearchBarProps {
  searchTerm: string;
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  onAddClick: () => void;
  onDeleteClick: () => void;
  onEditClick: () => void;
  onShowClick: () => void;
}

interface SearchBarSmallProps {
  searchTerm: string;
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;

  onShowClick: () => void;
}

const SearchBar = React.forwardRef<HTMLInputElement, SearchBarProps>(({ searchTerm, onChange, onAddClick, onDeleteClick, onEditClick, onShowClick }, ref) => {
  return (
    <SearchBarWithIconsRoot>
      <Search placeholder="Cerca" value={searchTerm} onChange={onChange} ref={ref} />
      {/* <LineDivider /> */}
      {/* <IconsGroup>
          <IconButton>
            <BackgroundIcon
              loading="eager"
              alt=""
              src="/doc.svg"
              onClick={onShowClick}
            />
          </IconButton>
          <IconButton>
            <BackgroundIcon
              loading="eager"
              alt=""
              src="/edit.svg"
              onClick={onEditClick}
            />
          </IconButton>
          <IconButton>
            <BackgroundIcon
              loading="eager"
              alt=""
              src="/delete.svg"
              onClick={onDeleteClick}
            />
          </IconButton>
          <IconButton>
            <BackgroundIcon
              loading="eager"
              alt=""
              src="/add.svg"
              onClick={onAddClick}  
            />
          </IconButton>
        </IconsGroup> */}
      {/* Icons and other elements */}
    </SearchBarWithIconsRoot>
  );
});

const SearchBarSmall = React.forwardRef<HTMLInputElement, SearchBarSmallProps>(({ searchTerm, onChange, onShowClick }, ref) => {
  return (
    <SearchBarWithIconsRoot>
      <Search placeholder="Cerca" value={searchTerm} onChange={onChange} ref={ref} />
      {/* <LineDivider />
        <IconsGroup>
          <IconButton>
            <BackgroundIcon
              loading="eager"
              alt=""
              src="/doc.svg"
              onClick={onShowClick}
            />
          </IconButton>
        </IconsGroup> */}
      {/* Icons and other elements */}
    </SearchBarWithIconsRoot>
  );
});

interface ValueItem {
  id: string;
  alias: string;
}

interface NestedItem {
  value: ValueItem;
  leaves: NestedItem[];
}

type DataItem = {
  id: string;
  organization_id: string;
  site_id: string;
  text: string;
};

const CybersecurityOrganigramma = () => {
  const token = useToken();
  const [L1ID, setL1ID] = useRecoilState(SelectedL1ID);
  const [L2ID, setL2ID] = useRecoilState(SelectedL2ID);
  const [L3ID, setL3ID] = useRecoilState(SelectedL3ID);
  const [L4ID, setL4ID] = useRecoilState(SelectedL4ID);

  const [message, setMessage] = useState("");
  const [isMessageBoxVisible, setIsMessageBoxVisible] = useState(false);

  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);

  const { get } = useApi();
  const { del } = useApi();

  type ApiContentItem = {
    id: string;
    has_place: string;
    has_unit: string;
    place: {
      id: string;
      name: string;
      has_site: string;
    };
  };

  const [selectedSiteId, setSelectedSiteId] = useState(" ");
  const [selectedUnitId, setSelectedUnitId] = useState(" ");

  const fetchDataLV1 = async () => {
    setIsLoading(true);
    try {
      const data = await get(URL_PlacesByUnit);
      if (Array.isArray(data)) {
        const seenPlaceIds = new Set();
        let transformedData: DataItem[] = data
          .filter((item: ApiContentItem) => {
            if (seenPlaceIds.has(item.place.id)) {
              return false;
            }
            seenPlaceIds.add(item.place.id);
            return true;
          })
          .map((item: ApiContentItem) => ({
            id: item.place.id,
            has_place: item.place.id,
            site_id: item.place.has_site,
            organization_id: item.has_unit,
            text: `${item.place.name}`,
          }));

        if (selectedSiteId.trim() !== "") {
          transformedData = transformedData.filter((item) => item.site_id === selectedSiteId);
        }

        if (selectedUnitId.trim() !== "") {
          transformedData = transformedData.filter((item) => item.organization_id === selectedUnitId);
        }

        setL1Data(transformedData);
      }
    } catch (err) {
      console.error("Error fetching data:", err);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchDataLV1();
  }, [selectedSiteId, selectedUnitId]);

  useEffect(() => {
    fetchDataLV1();
  }, [selectedSiteId]);

  const handleSearchChange = (level: "L1" | "L2" | "L3" | "L4") => (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    switch (level) {
      case "L1":
        setSearchTermL1(value);
        break;
      case "L2":
        setSearchTermL2(value);
        break;
      case "L3":
        setSearchTermL3(value);
        break;
      case "L4":
        setSearchTermL4(value);
        break;
    }
  };

  const [isOverlayVisibleAdd1, setIsOverlayVisibleAdd1] = useState(false);
  const [isOverlayAnimatingAdd1, setIsOverlayAnimatingAdd1] = useState(false);
  const [isOverlayVisibleEdit1, setIsOverlayVisibleEdit1] = useState(false);
  const [isOverlayAnimatingEdit1, setIsOverlayAnimatingEdit1] = useState(false);
  const [isOverlayVisibleShow1, setIsOverlayVisibleShow1] = useState(false);
  const [isOverlayAnimatingShow1, setIsOverlayAnimatingShow1] = useState(false);

  const [isOverlayVisibleAdd2, setIsOverlayVisibleAdd2] = useState(false);
  const [isOverlayAnimatingAdd2, setIsOverlayAnimatingAdd2] = useState(false);

  const [isOverlayVisibleAdd3, setIsOverlayVisibleAdd3] = useState(false);
  const [isOverlayAnimatingAdd3, setIsOverlayAnimatingAdd3] = useState(false);

  const [isOverlayVisibleAdd4, setIsOverlayVisibleAdd4] = useState(false);
  const [isOverlayAnimatingAdd4, setIsOverlayAnimatingAdd4] = useState(false);

  const navigate = useNavigate();

  const searchInputRefL1 = useRef<HTMLInputElement>(null);
  const searchInputRefL2 = useRef<HTMLInputElement>(null);
  const searchInputRefL3 = useRef<HTMLInputElement>(null);
  const searchInputRefL4 = useRef<HTMLInputElement>(null);

  const [searchTermL1, setSearchTermL1] = useState("");
  const [searchTermL2, setSearchTermL2] = useState("");
  const [searchTermL3, setSearchTermL3] = useState("");
  const [searchTermL4, setSearchTermL4] = useState("");

  const [breadcrumb, setBreadcrumb] = useState<DataItem[]>([]);

  const [l1Data, setL1Data] = useState<DataItem[]>([]);
  const [selectedL1, setSelectedL1] = useState<string | null>(null);
  const [l2Data, setL2Data] = useState<DataItem[]>([]);
  const [selectedL2, setSelectedL2] = useState<string | null>(null);
  const [l3Data, setL3Data] = useState<DataItem[]>([]);
  const [selectedL3, setSelectedL3] = useState<string | null>(null);
  const [l4Data, setL4Data] = useState<DataItem[]>([]);
  const [selectedL4, setSelectedL4] = useState<string | null>(null);

  const [nestedData, setNestedData] = useState<NestedItem[]>([]);

  useEffect(() => {
    setFilteredL1Data(filterData(l1Data, searchTermL1));
  }, [l1Data, searchTermL1]);

  useEffect(() => {
    const filteredL2Data = filterData(l2Data, searchTermL2);
    setFilteredL2Data(filteredL2Data);
  }, [l2Data, searchTermL2]);

  useEffect(() => {
    const filteredL3Data = filterData(l3Data, searchTermL3);
    setFilteredL3Data(filteredL3Data);
  }, [l3Data, searchTermL3]);

  useEffect(() => {
    const filteredL4Data = filterData(l4Data, searchTermL4);
    setFilteredL4Data(filteredL4Data);
  }, [l4Data, searchTermL4]);

  const [filteredL1Data, setFilteredL1Data] = useState<DataItem[]>([]);
  const [filteredL2Data, setFilteredL2Data] = useState<DataItem[]>([]);
  const [filteredL3Data, setFilteredL3Data] = useState<DataItem[]>([]);
  const [filteredL4Data, setFilteredL4Data] = useState<DataItem[]>([]);

  const filterData = (data: DataItem[], searchTerm: string) => {
    return data.filter((item) => item.text.toLowerCase().includes(searchTerm.toLowerCase()));
  };

  const [displayableData, setDisplayableData] = useState<{
    L1: DataItem[];
    L2: DataItem[];
    L3: DataItem[];
    L4: DataItem[];
  }>({
    L1: [],
    L2: [],
    L3: [],
    L4: [],
  });

  const sortDataItems = (data: DataItem[]) => {
    return data.sort((a: DataItem, b: DataItem) => a.text.localeCompare(b.text));
  };

  const [triggerL1Selection, setTriggerL1Selection] = useState(true);
  const [triggerL2Selection, setTriggerL2Selection] = useState(false);
  const [triggerL3Selection, setTriggerL3Selection] = useState(false);
  const [triggerL4Selection, setTriggerL4Selection] = useState(false);

  useEffect(() => {
    if (triggerL1Selection && l1Data.length > 0) {
      handleSelectL1(l1Data[0].id);
      setTriggerL1Selection(false);
    }
  }, [triggerL1Selection, l1Data]);

  useEffect(() => {
    if (triggerL2Selection && l2Data.length > 0) {
      handleSelectL2(l2Data[0].id);
      setTriggerL2Selection(false);
    }
  }, [triggerL3Selection, l3Data]);

  useEffect(() => {
    if (triggerL3Selection && l3Data.length > 0) {
      handleSelectL3(l3Data[0].id);
      setTriggerL3Selection(false);
    }
  }, [triggerL3Selection, l3Data]);

  useEffect(() => {
    if (triggerL4Selection && l4Data.length > 0) {
      handleSelectL4(l4Data[0].id);
      setTriggerL4Selection(false);
    }
  }, [triggerL4Selection, l4Data]);

  const handleSelectL1 = async (id: string) => {
    const selectedItem = l1Data.find((item) => item.id === id);
    if (selectedItem) {
      setL1ID(id);
      setSelectedL1(id);
      console.log("LV1-infr-ID: ", id);
      setSelectedL2(null);
      setSelectedL3(null);
      setSelectedL4(null);
      try {
        setIsLoading(true);

        setL3Data([]);
        setFilteredL3Data([]);
        setSelectedL3(null);

        setL4Data([]);
        setFilteredL4Data([]);
        setSelectedL4(null);
        setDisplayableData((prevData) => ({
          ...prevData,
          L2: [],
          L3: [],
          L4: [],
        }));
      } catch (error) {
        if (error instanceof Error) {
          setError(error.message);
        } else {
          setError("An unknown error occurred");
        }
      } finally {
        setIsLoading(false);
      }
    }
  };

  const [rawApiData, setRawApiData] = useState<AssetTreeItem[] | null>(null);

  const [expandedNodeIds, setExpandedNodeIds] = useState<string[]>([]);

  const [l2Categories, setL2Categories] = useState<MUITreeItem[]>([]);
  const [l3Assets, setL3Assets] = useState<MUITreeItem[]>([]);

  const CustomTreeItem = muiStyled(TreeItem)(({ theme }) => ({
    "& .MuiTreeItem-content": {
      backgroundColor: theme.palette.background.transparent,
      borderRadius: "0px",
      fontWeight: "regular",
      fontSize: "13px",

      "&:hover": {
        backgroundColor: theme.palette.action.hover,
      },
      "&.Mui-selected": {
        backgroundColor: "#c8d6e8",
        color: theme.palette.primary.contrastText,
        fontWeight: "regular",
        fontSize: "13px",
      },
      "&.Mui-focused, &.Mui-selected.Mui-focused": {
        backgroundColor: "#c8d6e8",

        fontWeight: "regular",
        fontSize: "13px",
      },
    },
    "& .MuiTreeItem-label": {
      fontWeight: "regular",
      fontSize: "12px",
      fontFamily: "Titillium Web",
      color: "#04446c",
    },
  }));

  interface AssetTreeItem {
    value: AssetCategory;
    leaves?: AssetTreeItem[];
  }

  interface AssetCategory {
    id: string;
    creationTs: string;
    updateTs: string;
    createdBy: string;
    updatedBy: string;
    name: string;
    sub_category_of?: string;
    assets?: Asset[];
    leaves?: AssetTreeItem[];
  }

  interface Asset {
    id: string;
    creationTs: string;
    updateTs: string;
    createdBy: string;
    updatedBy: string;
    name: string;
    hasOrganization: string;
    hasCategory: string;
    inventoryN: string;
    hasResponsible: string;
    hasPlace: string;
    state: string;
    category?: AssetCategory;
    container?: any;
  }

  interface MUITreeItem {
    id: string;
    name: string;
    children?: MUITreeItem[];
  }

  const transformToMUITreeFormat = (apiData: AssetTreeItem[]): MUITreeItem[] => {
    const processNode = (node: AssetTreeItem, parentId: string = "", isL3: boolean = false): MUITreeItem => {
      const nodeValue = node.value;
      const uniqueId = parentId ? `${parentId}-${nodeValue.id}` : nodeValue.id;

      const leafNodes = node.leaves?.map((leaf) => processNode(leaf, uniqueId, true)) || [];

      return {
        id: uniqueId,
        name: nodeValue.name,
        children: [...leafNodes],
      };
    };

    return apiData.map((topLevelCategory) => processNode(topLevelCategory));
  };

  const renderTreeView = (nodes: MUITreeItem[], searchTerm: string) => {
    const filteredNodes = filterTree(nodes, searchTerm);
    return filteredNodes.map((node) => (
      <CustomTreeItem key={node.id} nodeId={node.id} label={node.name} onClick={() => handleCategoryClick(node.id)}>
        {/* Recursively render children if they exist */}
        {node.children && renderTreeView(node.children, searchTerm)}
      </CustomTreeItem>
    ));
  };

  useEffect(() => {
    if (l2Categories.length > 0) {
      handleCategoryClick(l2Categories[0].id);
    } else {
      setL3Assets([]);
    }
  }, [l2Categories]);

  const [LV2List, setLV2List] = useState("");
  const [LV2ListId, setLV2ListId] = useState("");

  useEffect(() => {
    const fetchData = async () => {
      if (!token) {
        console.error("No token available, user might not be authenticated");
        return;
      }

      const apiUrl = LV2ListId ? `${URL_AssetTreeByPlace}${selectedL1}/${LV2ListId}` : `${URL_AssetTreeByPlace}${selectedL1}`;

      try {
        const response = await fetch(apiUrl, {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
        });

        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }

        const apiData = await response.json();

        const transformedData = transformToMUITreeFormat(apiData);
        setL2Categories(transformedData);
        setRawApiData(apiData);
        console.log("TransDATA", transformedData);
      } catch (error) {
        console.error("Failed to fetch data:", error);
      }
    };

    fetchData();
  }, [token, selectedL1, LV2ListId]);

  const handleCategoryClick = (nodeId: string) => {
    const parts = nodeId.split("-");
    const categoryId = parts.pop();

    if (!categoryId) {
      console.error("Invalid category ID");
      return;
    }

    console.log("Category ID: ", categoryId);

    const findNodeInRawData = (id: string, nodes: AssetTreeItem[] | null): AssetTreeItem | null => {
      if (nodes === null) {
        return null;
      }
      for (const node of nodes) {
        if (node.value.id === id) {
          return node;
        } else if (node.leaves) {
          const found = findNodeInRawData(id, node.leaves);
          if (found) return found;
        }
      }
      return null;
    };

    const extractAssetsFromRawNode = (node: AssetTreeItem | null, seenIds = new Set<string>()): MUITreeItem[] => {
      let assets: MUITreeItem[] = [];
      if (node) {
        node.value.assets?.forEach((asset) => {
          if (!seenIds.has(asset.id)) {
            assets.push({
              id: asset.id,
              name: asset.name,
              children: undefined,
            });
            seenIds.add(asset.id);
          }
        });

        node.leaves?.forEach((leaf) => {
          assets = [...assets, ...extractAssetsFromRawNode(leaf, seenIds)];
        });
      }
      return assets;
    };

    const rawNode = findNodeInRawData(categoryId, rawApiData);

    const assetLeaves = extractAssetsFromRawNode(rawNode);

    setL3Assets(assetLeaves);
  };

  const filterTree = (nodes: MUITreeItem[], searchTerm: string): MUITreeItem[] => {
    if (!searchTerm) {
      return nodes;
    }

    const lowerCaseSearchTerm = searchTerm.toLowerCase();

    const recursiveFilter = (nodeList: MUITreeItem[]): MUITreeItem[] => {
      return nodeList.reduce((acc: MUITreeItem[], node) => {
        const nameMatches = node.name.toLowerCase().includes(lowerCaseSearchTerm);

        let childrenMatches = false;
        let filteredChildren: MUITreeItem[] = [];
        if (node.children) {
          filteredChildren = recursiveFilter(node.children);
          childrenMatches = filteredChildren.length > 0;
        }

        if (nameMatches || childrenMatches) {
          acc.push({
            ...node,

            ...(filteredChildren.length && { children: filteredChildren }),
          });
        }

        return acc;
      }, []);
    };

    return recursiveFilter(nodes);
  };

  const getAllNodeIds = (nodes: MUITreeItem[]): string[] => {
    let ids: string[] = [];
    nodes.forEach((node) => {
      ids.push(node.id);
      if (node.children) {
        ids = ids.concat(getAllNodeIds(node.children));
      }
    });
    return ids;
  };

  useEffect(() => {
    if (l2Categories) {
      const allNodeIds = getAllNodeIds(l2Categories);
      setExpandedNodeIds(allNodeIds);
    }
  }, [l2Categories]);

  const handleAssetClick = (assetId: string) => {
    console.log("Asset clicked:", assetId);
  };

  const handleSelectL2 = async (id: string) => {
    const selectedItem = nestedData.find((item) => item.value.id === id);
    if (selectedItem) {
      setL2ID(id);
      setSelectedL2(id);
      console.log("LV2ID: ", id);
      setSelectedL3(null);
      setSelectedL4(null);
      const newL3Data = sortDataItems(
        selectedItem.leaves.map((leaf) => ({
          id: leaf.value.id,
          text: leaf.value.alias,
          site_id: "null",
          organization_id: "null",
        }))
      );
      setL3Data(newL3Data);
      if (newL3Data.length > 0) {
        setTriggerL3Selection(true);
      }
      setFilteredL3Data(filterData(newL3Data, searchTermL3));

      setL4Data([]);
      setFilteredL4Data([]);
      setSelectedL4(null);
    }
  };

  const handleSelectL3 = async (l3Id: string) => {
    if (selectedL2) {
      const selectedL2Item = nestedData.find((item) => item.value.id === selectedL2);

      const selectedL3Item = selectedL2Item?.leaves.find((leaf) => leaf.value.id === l3Id);

      if (selectedL3Item) {
        setL3ID(l3Id);
        setSelectedL3(l3Id);
        console.log("LV3ID: ", l3Id);
        setSelectedL4(null);

        const newL4Data = selectedL3Item.leaves.map((leaf) => ({
          id: leaf.value.id,
          text: leaf.value.alias,
          site_id: "null",
          organization_id: "null",
        }));

        setL4Data(sortDataItems(newL4Data));
        if (newL4Data.length > 0) {
          setTriggerL4Selection(true);
        }

        setFilteredL4Data(sortDataItems(newL4Data));
      }
    } else {
      console.error("No L2 item selected. Cannot select L3.");
    }
  };

  const handleSelectL4 = async (id: string) => {
    setL4ID(id);
    setSelectedL4(id);
    console.log("LV4ID: ", id);
  };

  const handleDeleteL1 = async () => {
    if (!selectedL1) {
      setMessage("No item is selected for deletion.");
      setIsMessageBoxVisible(true);
      return;
    }

    if (window.confirm("Are you sure you want to delete this item?")) {
      try {
        setIsLoading(true);
        await del(URL_LV1_Detailed, selectedL1);

        setL1Data((prevData) => prevData.filter((item) => item.id !== selectedL1));

        setMessage(`Site with ID ${selectedL1} has been deleted.`);
        setIsMessageBoxVisible(true);
        setTriggerL1Selection(true);
      } catch (error) {
        if (error instanceof Error) {
          setError(error.message);
        } else {
          setError("An unknown error occurred");
        }
      } finally {
        setIsLoading(false);
      }
    }
  };

  const toggleOverlayAdd1 = () => {
    if (!isOverlayVisibleAdd1) {
      if (window.location.pathname === "/Cybersecurity/Infrastruttura") {
        navigate(`Infrastruttura/CreaCategoria/1`);
      } else {
        navigate(`${window.location.pathname}/CreaCategoria/1`);
      }

      setIsOverlayVisibleAdd1(true);
      setTimeout(() => setIsOverlayAnimatingAdd1(true), 10);
    } else {
      setIsOverlayAnimatingAdd1(false);
      setTimeout(() => setIsOverlayVisibleAdd1(false), 310);
    }
  };

  const closeOverlayAdd1 = () => {
    navigate(`/Cybersecurity/Infrastruttura/StrutturaAsset`);
    setIsOverlayAnimatingAdd1(false);

    setTimeout(() => {
      setIsOverlayVisibleAdd1(false);
      fetchDataLV1();
    }, 300);
  };

  const toggleOverlayEdit1 = () => {
    if (!isOverlayVisibleEdit1) {
      if (window.location.pathname === "/Cybersecurity/Infrastruttura") {
        navigate(`Infrastruttura/ModificaL1`);
      } else {
        navigate(`${window.location.pathname}/ModificaL1`);
      }

      setIsOverlayVisibleEdit1(true);
      setTimeout(() => setIsOverlayAnimatingEdit1(true), 10);
    } else {
      setIsOverlayAnimatingEdit1(false);
      setTimeout(() => setIsOverlayVisibleEdit1(false), 310);
    }
  };

  const closeOverlayEdit1 = () => {
    navigate(`/Cybersecurity/Infrastruttura/StrutturaAsset`);
    setIsOverlayAnimatingEdit1(false);

    setTimeout(() => {
      setIsOverlayVisibleEdit1(false);
      fetchDataLV1();
    }, 300);
  };

  const toggleOverlayShow1 = () => {
    if (!isOverlayVisibleShow1) {
      if (window.location.pathname === "/Cybersecurity/Infrastruttura") {
        navigate(`Infrastruttura/VisualizzaL1`);
      } else {
        navigate(`${window.location.pathname}/VisualizzaL1`);
      }

      setIsOverlayVisibleShow1(true);
      setTimeout(() => setIsOverlayAnimatingShow1(true), 10);
    } else {
      setIsOverlayAnimatingShow1(false);
      setTimeout(() => setIsOverlayVisibleShow1(false), 310);
    }
  };

  const closeOverlayShow1 = () => {
    navigate(`/Cybersecurity/Infrastruttura/StrutturaAsset`);
    setIsOverlayAnimatingShow1(false);

    setTimeout(() => {
      setIsOverlayVisibleShow1(false);
      fetchDataLV1();
    }, 300);
  };

  const toggleOverlayAdd2 = () => {
    if (!isOverlayVisibleAdd2) {
      if (window.location.pathname === "/Cybersecurity/Infrastruttura") {
        navigate(`Infrastruttura/CreaAsset/1`);
      } else {
        navigate(`${window.location.pathname}/CreaAsset/1`);
      }

      setIsOverlayVisibleAdd2(true);
      setTimeout(() => setIsOverlayAnimatingAdd2(true), 10);
    } else {
      setIsOverlayAnimatingAdd2(false);
      setTimeout(() => setIsOverlayVisibleAdd2(false), 310);
    }
  };

  const closeOverlayAdd2 = () => {
    navigate(`/Cybersecurity/Infrastruttura/StrutturaAsset`);
    setIsOverlayAnimatingAdd2(false);

    setTimeout(() => {
      setIsOverlayVisibleAdd2(false);
    }, 300);
  };

  type LV1ApiContentItem = {
    id: string;
    alias: string;
  };

  type LV1DataItem = {
    LV1_id: string;
    LV1_name: string;
  };

  const [data, setData] = useState<LV1DataItem[]>([]);

  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true);
      try {
        if (!token) throw new Error("User is not authenticated");

        const response = await fetch(URL_LV1List, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });

        if (!response.ok) {
          throw new Error(`API request failed: ${response.statusText}`);
        }

        const responseData = await response.json();
        console.log("Response Data:", responseData);

        const contentArray = responseData.content;

        if (!Array.isArray(contentArray)) {
          throw new Error("Expected content to be an array");
        }

        const transformedData: LV1DataItem[] = contentArray.map((item: LV1ApiContentItem) => {
          return {
            LV1_id: item.id,
            LV1_name: item.alias,
          };
        });

        console.log("Transformed Data:", transformedData);
        setData(transformedData);
      } catch (err) {
        setError(err instanceof Error ? err.message : "An unknown error occurred");
        setIsMessageBoxVisible(true);
      } finally {
        setIsLoading(false);
      }
    };

    fetchData();
  }, [token]);

  const [LV1List, setLV1List] = useState("");
  const [LV1ListId, setLV1ListId] = useState("");

  const directorNameToId = new Map(data.map((item) => [item.LV1_name, item.LV1_id]));

  const handleDirectorChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;
    setLV1List(value);

    if (directorNameToId.has(value)) {
      setLV1ListId(directorNameToId.get(value) || "");
      console.log(directorNameToId.get(value) || "");
    }
  };

  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const dropdownRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    function handleClickOutside(event: MouseEvent) {
      if (dropdownRef.current && event.target instanceof Node && !dropdownRef.current.contains(event.target)) {
        setIsDropdownOpen(false);
      }
    }

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const handleDropdownToggle = () => {
    setLV1List("");
    setLV1ListId("");
    setIsDropdownOpen(!isDropdownOpen);
  };

  const handleOptionSelect = (selectedDirectorId: string) => {
    const selectedDirectorName = data.find((item) => item.LV1_id === selectedDirectorId)?.LV1_name;

    if (selectedDirectorName) {
      setLV1List(selectedDirectorName);

      setLV1ListId(selectedDirectorId);
    }

    setIsDropdownOpen(false);
  };

  useEffect(() => {
    console.log("Selected LV1ListId ID:", LV1ListId);
    setSelectedSiteId(LV1ListId);
  }, [LV1ListId]);

  type ApiResponseItem = {
    id: string;
    name: string;
    responsible_name: string;
    responsible_id: string;
    site_name: string;
    site_id: string;
    email: string;
    phone: string;
  };

  type TransformedDataItem = {
    LV2_id: string;
    LV2_name: string;
  };

  const [LV2data, setLV2Data] = useState<TransformedDataItem[]>([]);

  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true);
      try {
        if (!token) throw new Error("User is not authenticated");

        const response = await fetch(URL_LV2List, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });

        if (!response.ok) {
          throw new Error(`API request failed: ${response.statusText}`);
        }

        const responseData: ApiResponseItem[] = await response.json();
        console.log("Response data:", responseData);

        const transformedData = responseData.map((item) => ({
          LV2_id: item.id,
          LV2_name: item.name,
        }));

        console.log("Transformed data:", transformedData);
        setLV2Data(transformedData);
      } catch (err) {
        setError(err instanceof Error ? err.message : "An unknown error occurred");
        setIsMessageBoxVisible(true);
      } finally {
        setIsLoading(false);
      }
    };

    fetchData();
  }, [token]);

  const directorNameToIdLV2 = new Map(LV2data.map((item) => [item.LV2_name, item.LV2_id]));

  const handleDirectorChangeLV2 = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;
    setLV2List(value);

    if (directorNameToIdLV2.has(value)) {
      setLV2ListId(directorNameToIdLV2.get(value) || "");
      console.log(directorNameToIdLV2.get(value) || "");
    }
  };

  const [isDropdownOpenLV2, setIsDropdownOpenLV2] = useState(false);
  const dropdownRefLV2 = useRef<HTMLDivElement>(null);

  useEffect(() => {
    function handleClickOutside(event: MouseEvent) {
      if (dropdownRefLV2.current && event.target instanceof Node && !dropdownRefLV2.current.contains(event.target)) {
        setIsDropdownOpenLV2(false);
      }
    }

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const handleDropdownToggleLV2 = () => {
    setLV2List("");
    setLV2ListId("");
    setIsDropdownOpenLV2(!isDropdownOpenLV2);
  };

  const handleOptionSelectLV2 = (selectedDirectorIdLV2: string) => {
    const selectedDirectorNameLV2 = LV2data.find((item) => item.LV2_id === selectedDirectorIdLV2)?.LV2_name;

    if (selectedDirectorNameLV2) {
      setLV2List(selectedDirectorNameLV2);

      setLV2ListId(selectedDirectorIdLV2);
    }

    setIsDropdownOpenLV2(false);
  };

  useEffect(() => {
    console.log("Selected LV2ListId ID:", LV2ListId);
    setSelectedUnitId(LV2ListId);
  }, [LV2ListId]);

  const ClearLV1 = () => {
    setLV1List("");
    setLV1ListId("");
  };

  const ClearLV2 = () => {
    setLV2List("");
    setLV2ListId("");
  };

  return (
    <MainBoardContentContainerColumnInfratrutturaContain>
      <MainBoardContentContainerColumnInfratrutturaHorizontal>
        <TitleTextHorizontal>
          <TitleText>Filtra per:</TitleText>
        </TitleTextHorizontal>

        <InputContainerHorizontal>
          <InputTextboxHorizontal list="directors-list" value={LV1List} onChange={handleDirectorChange} onClick={handleDropdownToggle} placeholder="Componente organizzativa" />
          {LV1List && (
            <CancelIcon
              style={{
                fontSize: "16px",
                cursor: "pointer",
                position: "absolute",
                right: "30px",
                top: "50%",
                transform: "translateY(-50%)",
                color: "#c6c6c6",
              }}
              onClick={ClearLV1}
            />
          )}
          <TriangleContainerInfrastruttura>
            {isDropdownOpen ? "▲" : "▼"} {/* Change icons as needed */}
          </TriangleContainerInfrastruttura>

          {isDropdownOpen && (
            <CustomDropdownListInfrastruttura>
              <DropDownMenuScrollbarInfrastruttura ref={dropdownRef}>
                {data
                  .filter((item) => item.LV1_name.toLowerCase().includes(LV1List.toLowerCase()))
                  .map((filteredItem, index) => (
                    <CustomDropdownItem key={index} onClick={() => handleOptionSelect(filteredItem.LV1_id)}>
                      {filteredItem.LV1_name}
                    </CustomDropdownItem>
                  ))}
              </DropDownMenuScrollbarInfrastruttura>
            </CustomDropdownListInfrastruttura>
          )}
        </InputContainerHorizontal>

        {/* <LineDividerHorizontal />
        <IconsGroup>
          <IconButton>
            <BackgroundIcon
              loading="eager"
              alt=""
              src="/doc.svg"
              onClick={() => {}}
            />
          </IconButton>
        </IconsGroup> */}

        <TitleTextHorizontal>
          <TitleText>oppure per: </TitleText>
        </TitleTextHorizontal>

        <InputContainerHorizontal>
          <InputTextboxHorizontal list="directors-list-LV2" value={LV2List} onChange={handleDirectorChangeLV2} onClick={handleDropdownToggleLV2} placeholder="Unitá operativa" />
          {LV2List && (
            <CancelIcon
              style={{
                fontSize: "16px",
                cursor: "pointer",
                position: "absolute",
                right: "30px",
                top: "50%",
                transform: "translateY(-50%)",
                color: "#c6c6c6",
              }}
              onClick={ClearLV2}
            />
          )}
          <TriangleContainerInfrastruttura>
            {isDropdownOpenLV2 ? "▲" : "▼"} {/* Change icons as needed */}
          </TriangleContainerInfrastruttura>

          {isDropdownOpenLV2 && (
            <CustomDropdownListInfrastruttura>
              <DropDownMenuScrollbarInfrastruttura ref={dropdownRefLV2}>
                {LV2data.filter((item) => item.LV2_name.toLowerCase().includes(LV2List.toLowerCase())).map((filteredItem, index) => (
                  <CustomDropdownItem key={index} onClick={() => handleOptionSelectLV2(filteredItem.LV2_id)}>
                    {filteredItem.LV2_name}
                  </CustomDropdownItem>
                ))}
              </DropDownMenuScrollbarInfrastruttura>
            </CustomDropdownListInfrastruttura>
          )}
        </InputContainerHorizontal>
        {/* 
        <LineDividerHorizontal />
        <IconsGroup>
          <IconButton>
            <BackgroundIcon
              loading="eager"
              alt=""
              src="/doc.svg"
              onClick={() => {}}
            />
          </IconButton>
        </IconsGroup> */}
      </MainBoardContentContainerColumnInfratrutturaHorizontal>

      <MainBoardContentContainerInfrastruttura>
        {/* Repeat the structure for L2, L3, and L4, mapping through l2Data, l3Data, and l4Data respectively */}
        {/* and using handleSelectL2, handleSelectL3 for onClick handlers */}

        {/* L2 Column */}
        <MainBoardContentContainerColumn>
          <MainBoardContentContainerFileHorizontal>
            <Title>
              <TitleText>L1: LUOGO</TitleText>
            </Title>
            <SearchBarSmall searchTerm={searchTermL1} onChange={handleSearchChange("L1")} onShowClick={toggleOverlayShow1} ref={searchInputRefL1} />

            <LastPopupScrollbarInfrastrutturaL3>
              {/* Map through L1 data (static for now, could be fetched from an API) */}
              {/* TODO: Replace with actual L1 data */}
              {filteredL1Data.map((item) => (
                <FileButtonWhite key={item.id} isActive={selectedL1 === item.id} onClick={() => handleSelectL1(item.id)}>
                  <FileButtonWhiteText>{item.text}</FileButtonWhiteText>
                  <FileButtonArrowGrey src="/arrow-line-right-grey.svg" alt="Arrow Icon" />
                </FileButtonWhite>
              ))}
            </LastPopupScrollbarInfrastrutturaL3>
          </MainBoardContentContainerFileHorizontal>
        </MainBoardContentContainerColumn>
        {/* L3 Column */}
        <MainBoardContentContainerColumnInfratruttura50>
          <MainBoardContentContainerFileHorizontal>
            <Title>
              <TitleText>L2: CATEGORIA</TitleText>
            </Title>
            <SearchBar
              searchTerm={searchTermL2}
              onChange={handleSearchChange("L2")}
              onAddClick={toggleOverlayAdd1}
              onShowClick={toggleOverlayShow1}
              onEditClick={() => {}}
              onDeleteClick={() => {}}
              ref={searchInputRefL2}
            />
            <LastPopupScrollbarInfrastrutturaL3>
              {l2Categories && (
                <TreeView
                  expanded={expandedNodeIds}
                  aria-label="file system navigator"
                  defaultCollapseIcon={<ExpandMore />}
                  defaultExpandIcon={<ChevronRight />}
                  sx={{
                    flexGrow: 1,
                    overflowY: "auto",
                    "& .MuiTreeItem-root": {
                      fontFamily: "Titillium Web, Arial",
                      fontSize: "13px",
                      color: "#04446c",
                    },
                  }}
                >
                  {renderTreeView(l2Categories, searchTermL2)}
                </TreeView>
              )}
            </LastPopupScrollbarInfrastrutturaL3>
          </MainBoardContentContainerFileHorizontal>
        </MainBoardContentContainerColumnInfratruttura50>
        {/* L4 Column */}
        <MainBoardContentContainerColumn>
          <MainBoardContentContainerFileHorizontal>
            <Title>
              <TitleText>L3: ASSET</TitleText>
            </Title>
            <SearchBar
              searchTerm={searchTermL3}
              onChange={handleSearchChange("L3")}
              onEditClick={() => {}}
              onDeleteClick={() => {}}
              onAddClick={toggleOverlayAdd2}
              onShowClick={toggleOverlayShow1}
              ref={searchInputRefL3}
            />

            <LastPopupScrollbarInfrastrutturaL3>
              {/* Map through L1 data (static for now, could be fetched from an API) */}
              {/* TODO: Replace with actual L1 data */}
              <div style={{ flex: 1 }}>
                <List component="nav">
                  {l3Assets
                    .filter((asset) => asset.name.toLowerCase().includes(searchTermL3.toLowerCase()))
                    .map((asset) => (
                      <ListItem key={asset.id} button onClick={() => handleAssetClick(asset.id)}>
                        <ListItemText
                          primary={asset.name}
                          primaryTypographyProps={{
                            style: { color: "#04446c", fontFamily: "Titillium Web", fontSize: "13px" },
                          }}
                        />
                      </ListItem>
                    ))}
                </List>
              </div>
            </LastPopupScrollbarInfrastrutturaL3>
          </MainBoardContentContainerFileHorizontal>
        </MainBoardContentContainerColumn>

        <OverlayContext.Provider
          value={{
            closeOverlay: closeOverlayAdd1,
            selectedL1,
            setSelectedL1,
            selectedL2,
            setSelectedL2,
            selectedL3,
            setSelectedL3,
            selectedL4,
            setSelectedL4,
          }}
        >
          {isOverlayVisibleAdd1 && (
            <OverlayComponentAdd1 isAnimating={isOverlayAnimatingAdd1}>
              {/* <ButtonClose src="/close.svg" onClick={closeOverlayAdd1} /> */}
              <FadeOutComponent>
                <FrameTopGradient100></FrameTopGradient100>
              </FadeOutComponent>
              <Outlet />
            </OverlayComponentAdd1>
          )}
        </OverlayContext.Provider>

        <OverlayContext.Provider
          value={{
            closeOverlay: closeOverlayEdit1,
            selectedL1,
            setSelectedL1,
            selectedL2,
            setSelectedL2,
            selectedL3,
            setSelectedL3,
            selectedL4,
            setSelectedL4,
          }}
        >
          {isOverlayVisibleEdit1 && (
            <OverlayComponentAdd1 isAnimating={isOverlayAnimatingEdit1}>
              {/* <ButtonClose src="/close.svg" onClick={closeOverlayEdit1} /> */}
              <FadeOutComponent>
                <FrameTopGradient100></FrameTopGradient100>
              </FadeOutComponent>

              <Outlet />
            </OverlayComponentAdd1>
          )}
        </OverlayContext.Provider>

        <OverlayContext.Provider
          value={{
            closeOverlay: closeOverlayShow1,
            selectedL1,
            setSelectedL1,
            selectedL2,
            setSelectedL2,
            selectedL3,
            setSelectedL3,
            selectedL4,
            setSelectedL4,
          }}
        >
          {isOverlayVisibleShow1 && (
            <OverlayComponentAdd1 isAnimating={isOverlayAnimatingShow1}>
              {/* <ButtonClose src="/close.svg" onClick={closeOverlayShow1} /> */}
              <FadeOutComponent>
                <FrameTopGradient100></FrameTopGradient100>
              </FadeOutComponent>

              <Outlet />
            </OverlayComponentAdd1>
          )}
        </OverlayContext.Provider>

        <OverlayContext.Provider
          value={{
            closeOverlay: closeOverlayAdd2,
            selectedL1,
            setSelectedL1,
            selectedL2,
            setSelectedL2,
            selectedL3,
            setSelectedL3,
            selectedL4,
            setSelectedL4,
          }}
        >
          {isOverlayVisibleAdd2 && (
            <OverlayComponentAdd1 isAnimating={isOverlayAnimatingAdd2}>
              {/* <ButtonClose src="/close.svg" onClick={closeOverlayAdd2} /> */}
              <FadeOutComponent>
                <FrameTopGradient100></FrameTopGradient100>
              </FadeOutComponent>

              <Outlet />
            </OverlayComponentAdd1>
          )}
        </OverlayContext.Provider>
        {isMessageBoxVisible && (
          <Backdrop>
            <MessageContainer>
              <p>
                <MessageboxText>{message}</MessageboxText>
              </p>
              <CloseMessageButton
                onClick={() => {
                  setIsMessageBoxVisible(false);
                }}
              >
                <MessageboxText>Close</MessageboxText>
              </CloseMessageButton>
            </MessageContainer>
          </Backdrop>
        )}

        {isLoading && (
          <BackdropTransparent>
            <MessageContainer>
              <MessageboxText>Loading...</MessageboxText>
            </MessageContainer>
          </BackdropTransparent>
        )}

        {error && (
          <Backdrop>
            <MessageContainer>
              <p>
                <MessageboxText>{error}</MessageboxText>
              </p>
              <CloseMessageButton
                onClick={() => {
                  setIsMessageBoxVisible(false);
                  setError(null);
                }}
              >
                <MessageboxText>Close</MessageboxText>
              </CloseMessageButton>
            </MessageContainer>
          </Backdrop>
        )}

        {/* {nestedData && (
        <div>
          <h2>API Data:</h2>
          <pre>{JSON.stringify(nestedData, null, 2)}</pre>
        </div>
      )} */}
      </MainBoardContentContainerInfrastruttura>
    </MainBoardContentContainerColumnInfratrutturaContain>
  );
};

export default CybersecurityOrganigramma;
