import { useAuth } from "oidc-react";
import React, { Suspense, useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useRecoilState } from "recoil";
import { URL_GAP_Analysis, useApi } from "./../../../API";
import { PATH_Controlli } from "./../../../docPaths";
import { isLoadingRec, isMessageBoxVisibleRec, reFetchIndicatorPlaces, SelectedL3ID, setErrorRec, setMessageRec } from "./../../../recoil";
import {
  Backdrop,
  BackdropTransparent,
  CloseMessageButton,
  CreaSubMenuButtonMultiple,
  FrameTopGradient100,
  MessageboxText,
  MessageContainer,
  OverlayComponentBackgroundAdd1,
  OverlayComponentWhiteAdd1,
  OverlayComponentWhiteButtonFrameAdd1,
  OverlayComponentWhiteTitleAdd1,
  SubMenuButtonContainerOrganigrammaMultipleShort,
} from "./../cybersecurity/cybersecurity-board-contestoleadership-organizzazione-sub/cybersecurity-board-organizzazione-organigramma-styled-components";
import { ButtonGrey, ButtonText } from "./../styled-components";
import { LuoghiAttachedFilesInfo, LuoghiDocumentsAttached, LuoghiDocumentsPath } from "./cybersecurity-board-contestoleadership-infrastruttura-sub/Luoghi-Add-recoil";
import { OverlayContext } from "./cybersecurity-board-contestoleadership-organizzazione";
import { addL4AttachedFilesInfo, addL4DocumentsAttached } from "./cybersecurity-board-contestoleadership-organizzazione-sub/L4-Add-recoil";
import { editL4DocPath } from "./cybersecurity-board-contestoleadership-organizzazione-sub/L4-Edit-recoil";
import { useControlliOverlay } from "./cybersecurity-board-gap-analysis-controlli";
import SchedaGenerale from "./cybersecurity-board-gap-analysis-controlli-Edit/gap-analysis-Edit-1-SchedaGenerale";
import Organizzazione from "./cybersecurity-board-gap-analysis-controlli-Edit/gap-analysis-Edit-2-Criteri";
import Infrastruttura from "./cybersecurity-board-gap-analysis-controlli-Edit/gap-analysis-Edit-3-Note";
import Documentazione, { useInitiateUpload } from "./cybersecurity-board-gap-analysis-controlli-Edit/gap-analysis-Edit-7-Documentazione";
import {
  GAP_categoria,
  GAP_controlID,
  GAP_descrizione_controllo,
  GAP_ID,
  GAP_notes_done,
  GAP_notes_ev,
  GAP_notes_todo,
  GAP_obbligo,
  GAP_obbligo_label,
  GAP_priority,
  GAP_priority_label,
  GAP_val_appr,
  GAP_val_calc,
  reFetchIndicatorGAPControlli,
} from "./gap-recoil";

function generateUUID(): string {
  return "xxxxxxxx-xxxx-xxxx-yxxx-xxxxxxxxxxxx".replace(/[xy]/g, (c) => {
    const r = (Math.random() * 16) | 0;
    const v = c === "x" ? r : (r & 0x3) | 0x8;
    return v.toString(16);
  });
}

function useGenerateUUID(): string {
  const [gapID, setGapID] = useRecoilState<string>(GAP_ID);

  useEffect(() => {
    // Generate UUID and set it
    const newUUID = generateUUID();
    setGapID(newUUID);
    console.log("New place ID: ", newUUID);
  }, [setGapID]); // This effect should run only once when the component mounts

  return gapID;
}

export const useHandleSave = () => {
  const [isLoading, setIsLoading] = useRecoilState(isLoadingRec);
  const [error, setError] = useRecoilState(setErrorRec);
  const [isMessageBoxVisible, setIsMessageBoxVisible] = useRecoilState(isMessageBoxVisibleRec);
  const [message, setMessage] = useRecoilState(setMessageRec);
  const { closeOverlay } = useContext(OverlayContext);
  const { post } = useApi();
  const [reFetchIndicator, setRefetchIndicator] = useRecoilState(reFetchIndicatorGAPControlli);
  const [gapID, setGapId] = useRecoilState(GAP_ID);
  const [controlID, setControlId] = useRecoilState(GAP_controlID);
  const [categoria, setCategoria] = useRecoilState(GAP_categoria);
  const [descrizione_controllo, setDescrizioneControllo] = useRecoilState(GAP_descrizione_controllo);
  const [notes_done, setNotesDone] = useRecoilState(GAP_notes_done);
  const [notes_ev, setNotesEv] = useRecoilState(GAP_notes_ev);
  const [notes_todo, setNotesTodo] = useRecoilState(GAP_notes_todo);
  const [obbligo, setObbligo] = useRecoilState(GAP_obbligo);
  const [priority, setPriority] = useRecoilState(GAP_priority);
  const [val_appr, setValueAppro] = useRecoilState(GAP_val_appr);
  const [val_calc, setValueCalc] = useRecoilState(GAP_val_calc);
  const [gapPrioritaLabel, setGapPrioritaLabel] = useRecoilState(GAP_priority_label);
  const [gapObbligoLabel, setGapObbligoLabel] = useRecoilState(GAP_obbligo_label);
  const [selectedControlID] = useRecoilState(SelectedL3ID);

  const auth = useAuth(); // Use oidc-react's useAuth hook
  const token = auth.isLoading || !auth.userData ? null : auth.userData.access_token;

  const dataObbligo = [
    { label: "Libero", value: "libero" },
    { label: "Consigliato", value: "consigliato" },
    { label: "Obbligatorio", value: "obbligatorio" },
  ];

  const dataPriorita = [
    { label: "Non definita", value: "nd" },
    { label: "Bassa", value: "bassa" },
    { label: "Media", value: "media" },
    { label: "Alta", value: "alta" },
  ];

  interface DataItem {
    id: string;
    control_id: string;
    control_category: string;
    control_description: string;
    control_duty: string;
    control_priority: string;
    control_value_1: number;
    control_value_2: number;
    control_note_1: string;
    control_note_2: string;
    control_note_3: string;
    has_documents_folder_link: string;
  }

  const { patch } = useApi();

  useEffect(() => {
    const fetchControlData = async () => {
      try {
        const response = await fetch(`${URL_GAP_Analysis}/${selectedControlID}`, {
          method: "GET", // Explicitly define the method, even if it's 'GET'
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`, // Adding the Authorization header
          },
        });
        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }
        const data: DataItem = await response.json();
        console.log(`Fetched asset data: `, data);

        if (data) {
          setGapId(data.id);
          setControlId(data.control_id);
          setCategoria(data.control_category);
          setDescrizioneControllo(data.control_description);
          setNotesDone(data.control_note_1);
          setNotesEv(data.control_note_3);
          setNotesTodo(data.control_note_2);
          setObbligo(data.control_duty);
          setPriority(data.control_priority);
          setValueAppro(data.control_value_2.toString()); // Convert number to string if necessary
          setValueCalc(data.control_value_1.toString()); // Convert number to string if necessary

          // Map 'obbligo' ID to label
          const obbligoLabel = dataObbligo.find((item) => item.value === data.control_duty)?.label || "";

          setObbligo(data.control_duty);
          setGapObbligoLabel(obbligoLabel);

          // Map 'priorita' ID to label
          const prioritaLabel = dataPriorita.find((item) => item.value === data.control_priority)?.label || "";

          setPriority(data.control_priority);
          setGapPrioritaLabel(prioritaLabel);
        }
      } catch (error) {
        console.error("Error fetching control data:", error);
      }
    };

    fetchControlData();
  }, [selectedControlID]);

  const handleSave = async () => {
    if (!controlID) {
      setMessage("Si prega di compilare tutti i campi obbligatori: ID controllo");
      setIsMessageBoxVisible(true);
      return; // Exit the function early
    }

    const newData = {
      id: gapID,
      control_id: controlID,
      control_category: categoria,
      control_description: descrizione_controllo,
      control_duty: obbligo,
      control_priority: priority,
      control_value_1: parseFloat(val_calc),
      control_value_2: parseFloat(val_appr),
      control_note_1: notes_done,
      control_note_2: notes_todo,
      control_note_3: notes_ev,
      has_documents_folder_link: `${PATH_Controlli}/${controlID}`,
    };

    try {
      setIsLoading(true);
      console.log(JSON.stringify(newData));
      const responseData = await patch(URL_GAP_Analysis, newData);

      console.log("Controllo modificato con successo:", responseData);
      //setMessage("Controllo modificato con successo");
      //setIsMessageBoxVisible(true);
      closeOverlay();
      setRefetchIndicator((prev) => prev + 1);
    } catch (error) {
      console.error("Error creating Controls:", error);
      setMessage(`Error durante la creazione del controllo: ${error instanceof Error ? error.message : "Unknown error"}`);
      setIsMessageBoxVisible(true);
    } finally {
      setIsLoading(false);
    }
  };

  return { handleSave };
};

const MainComponent = () => {
  const [attachedFilesInfo, setAttachedFilesInfo] = useRecoilState(LuoghiAttachedFilesInfo);
  const [documentsAttached, setDocumentsAttached] = useRecoilState(LuoghiDocumentsAttached);
  const [documentsPath, setDocumentsPath] = useRecoilState(LuoghiDocumentsPath);
  const [docPath, setDocPath] = useRecoilState(editL4DocPath);
  const [docAttached, setDocAttached] = useRecoilState(addL4DocumentsAttached);
  const [filesInfo, setFilesInfo] = useRecoilState(addL4AttachedFilesInfo);
  const { handleSave } = useHandleSave();
  const initiateUpload = useInitiateUpload();
  const { closeOverlay } = useContext(OverlayContext);
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useRecoilState(isLoadingRec);
  const [error, setError] = useRecoilState(setErrorRec);
  const [isMessageBoxVisible, setIsMessageBoxVisible] = useRecoilState(isMessageBoxVisibleRec);
  const [message, setMessage] = useRecoilState(setMessageRec);
  const [reFetchIndicator, setRefetchIndicator] = useRecoilState(reFetchIndicatorPlaces);

  // CUSTOM HOOKS

  useGenerateUUID();
  const [gapID, setGapId] = useRecoilState(GAP_ID);
  const [controlID, setControlId] = useRecoilState(GAP_controlID);
  const [categoria, setCategoria] = useRecoilState(GAP_categoria);
  const [descrizione_controllo, setDescrizioneControllo] = useRecoilState(GAP_descrizione_controllo);
  const [notes_done, setNotesDone] = useRecoilState(GAP_notes_done);
  const [notes_ev, setNotesEv] = useRecoilState(GAP_notes_ev);
  const [notes_todo, setNotesTodo] = useRecoilState(GAP_notes_todo);
  const [obbligo, setObbligo] = useRecoilState(GAP_obbligo);
  const [priority, setPriority] = useRecoilState(GAP_priority);
  const [val_appr, setValueAppro] = useRecoilState(GAP_val_appr);
  const [val_calc, setValueCalc] = useRecoilState(GAP_val_calc);
  const [gapPrioritaLabel, setGapPrioritaLabel] = useRecoilState(GAP_priority_label);
  const [gapObbligoLabel, setGapObbligoLabel] = useRecoilState(GAP_obbligo_label);

  ///////////////// MESSAGE CLOSE AND NAVIGATE /////////

  const handleCloseAndNavigate = () => {
    closeOverlay();
    closeOverlayControlli();
    setIsMessageBoxVisible(false);
    setAttachedFilesInfo([]);
    setDocumentsAttached([]);
    setDocumentsPath("");
    setDocPath("");
    setDocAttached([]);
    setFilesInfo([]);
    // Delay resetting the strings by 1 second
    setTimeout(() => {
      setDocumentsPath("");
      setDocPath("");
      setDocAttached([]);
      setFilesInfo([]);
      setGapId("");
      setControlId("");
      setCategoria("");
      setDescrizioneControllo("");
      setNotesDone("");
      setNotesEv("");
      setNotesTodo("");
      setObbligo("");
      setPriority("");
      setValueAppro("");
      setValueCalc("");
      setGapPrioritaLabel("");
      setGapObbligoLabel("");
    }, 1000); // 1000 milliseconds = 1 second
  };

  ///////////////// SAVE AND UPLOAD /////////

  const handleSaveAndUpload = async () => {
    await handleSave();
    initiateUpload(); // Now you can call this function directly
    setDocPath("");
    setDocAttached([]);
    setFilesInfo([]);
    setDocPath("");
    setDocAttached([]);
    setFilesInfo([]);
    handleClose();
    setTimeout(() => {
      setDocPath("");
      setDocAttached([]);
      setFilesInfo([]);
      setGapId("");
      setControlId("");
      setCategoria("");
      setDescrizioneControllo("");
      setNotesDone("");
      setNotesEv("");
      setNotesTodo("");
      setObbligo("");
      setPriority("");
      setValueAppro("");
      setValueCalc("");
      setGapPrioritaLabel("");
      setGapObbligoLabel("");
    }, 1000); // 1000 milliseconds = 1 second
  };

  ///////////////// MESSAGE ANNULLA BUTTON /////////
  const { closeOverlayControlli, toggleOverlayAdd2 } = useControlliOverlay();

  const handleClose = () => {
    //closeOverlay();
    closeOverlayControlli();
    setDocPath("");
    setDocAttached([]);
    setFilesInfo([]);
    setDocPath("");
    setDocAttached([]);
    setFilesInfo([]);
    setTimeout(() => {
      setDocPath("");
      setDocAttached([]);
      setFilesInfo([]);

      setGapId("");
      setControlId("");
      setCategoria("");
      setDescrizioneControllo("");
      setNotesDone("");
      setNotesEv("");
      setNotesTodo("");
      setObbligo("");
      setPriority("");
      setValueAppro("");
      setValueCalc("");
      setGapPrioritaLabel("");
      setGapObbligoLabel("");
    }, 1000); // 1000 milliseconds = 1 second
  };

  ////////////////////CHANGE ACTIVE COMPONENT///////////

  const [activeComponent, setActiveComponent] = useState("SchedaGenerale");

  const changeActiveComponent = (componentName: string) => {
    setActiveComponent(componentName);
  };

  const renderActiveComponent = () => {
    switch (activeComponent) {
      case "SchedaGenerale":
        return <SchedaGenerale />;
      case "Organizzazione":
        return <Organizzazione />;
      case "Infrastruttura":
        return <Infrastruttura />;
      // case "ProcessiGenerali":
      //   return <Processi />;
      // case "PartiInteressate":
      //   return <Parti />;
      //case "SistemaCompliance":
      //  return <Compliance />;
      case "Documentazione":
        return <Documentazione />;
      default:
        return <SchedaGenerale />;
    }
  };

  return (
    <OverlayComponentBackgroundAdd1>
      {isLoading && (
        <BackdropTransparent>
          <MessageContainer>
            <MessageboxText>Loading...</MessageboxText>
          </MessageContainer>
        </BackdropTransparent>
      )}

      {isMessageBoxVisible && (
        <Backdrop>
          <MessageContainer>
            <p>
              <MessageboxText>{message}</MessageboxText>
            </p>
            <CloseMessageButton onClick={handleCloseAndNavigate}>
              <MessageboxText>Close</MessageboxText>
            </CloseMessageButton>
          </MessageContainer>
        </Backdrop>
      )}

      {error && (
        <Backdrop>
          <MessageContainer>
            <p>
              <MessageboxText>{error}</MessageboxText>
            </p>
            <CloseMessageButton
              onClick={() => {
                setIsMessageBoxVisible(false);
                setError(null);
                closeOverlay();
              }}
            >
              <MessageboxText>Close</MessageboxText>
            </CloseMessageButton>
          </MessageContainer>
        </Backdrop>
      )}

      <FrameTopGradient100 />
      <OverlayComponentWhiteTitleAdd1>MODIFICA CONTROLLO {controlID}</OverlayComponentWhiteTitleAdd1>
      <OverlayComponentWhiteAdd1>
        <SubMenuButtonContainerOrganigrammaMultipleShort>
          <CreaSubMenuButtonMultiple onClick={() => changeActiveComponent("SchedaGenerale")} to="/Cybersecurity/Controlli/ModificaControllo/1">
            Scheda generale
          </CreaSubMenuButtonMultiple>

          <CreaSubMenuButtonMultiple onClick={() => changeActiveComponent("Organizzazione")} to="/Cybersecurity/Controlli/ModificaControllo/2">
            Criteri
          </CreaSubMenuButtonMultiple>

          <CreaSubMenuButtonMultiple onClick={() => changeActiveComponent("Infrastruttura")} to="/Cybersecurity/Controlli/ModificaControllo/3">
            Roadmap
          </CreaSubMenuButtonMultiple>
          {/* <CreaSubMenuButtonMultiple
            onClick={() => changeActiveComponent("ProcessiGenerali")}
            to="/Cybersecurity/Infrastruttura/Luoghi/CreaLuoghi/4"
          >
            Processi generali
          </CreaSubMenuButtonMultiple> */}
          {/* <CreaSubMenuButtonMultiple
            onClick={() => changeActiveComponent("PartiInteressate")}
            to="/Cybersecurity/Infrastruttura/UnitaOperative/CreaL2/5"
          >
            Parti interessate
          </CreaSubMenuButtonMultiple> */}
          {/* <CreaSubMenuButtonMultiple
            onClick={() => changeActiveComponent("SistemaCompliance")}
            to="/Cybersecurity/Infrastruttura/Luoghi/CreaLuoghi/6"
          >
            Sistema gestione Compliance
          </CreaSubMenuButtonMultiple> */}
          <CreaSubMenuButtonMultiple onClick={() => changeActiveComponent("Documentazione")} to="/Cybersecurity/Controlli/ModificaControllo/7">
            Documentazione
          </CreaSubMenuButtonMultiple>
        </SubMenuButtonContainerOrganigrammaMultipleShort>
        <Suspense fallback={<div>Loading...</div>}>{renderActiveComponent()}</Suspense>
      </OverlayComponentWhiteAdd1>

      <OverlayComponentWhiteButtonFrameAdd1>
        <ButtonGrey onClick={handleClose}>
          <ButtonText>Annulla</ButtonText>
        </ButtonGrey>
        <ButtonGrey onClick={handleSaveAndUpload}>
          <ButtonText>Salva</ButtonText>
        </ButtonGrey>
      </OverlayComponentWhiteButtonFrameAdd1>
    </OverlayComponentBackgroundAdd1>
  );
};

export default MainComponent;
