import React, { useCallback, useContext, useEffect, useMemo, useState } from "react";
import { Outlet, useNavigate } from "react-router-dom";
import styled from "styled-components";
import { OverlayContext } from "../cybersecurity-board-contestoleadership-organizzazione";

import {
  Backdrop,
  BackdropTransparent,
  BackgroundIcon,
  CloseMessageButton,
  FadeOutComponent,
  IconButton,
  IconsGroup,
  MessageContainer,
  MessageboxText,
} from "../cybersecurity-board-contestoleadership-organizzazione-sub/cybersecurity-board-organizzazione-organigramma-styled-components";
import {
  Search,
  SearchIcon,
  SearchInputContainer,
  StyledTable,
  StyledTableItem,
  StyledTbody,
  StyledTd,
  StyledTh,
  StyledThead,
  TableContainer,
  TableItemText,
  TableScrollableDiv,
  TableTitleText,
  TopContainer,
} from "../cybersecurity-board-contestoleadership-organizzazione-sub/cybersecurity-board-organizzazione-unitaoperative-styled-components";

import { useRecoilState } from "recoil";

import { URL_PlacesFullList, URL_PlacesList, useApi, useToken } from "../../../../API";
import { SelectedL1ID, SelectedL2ID, SelectedL3ID, SelectedL4ID, isMessageBoxVisibleClosingRec, isMessageBoxVisibleRec, reFetchIndicatorPlaces } from "../../../../recoil";
import { StyledIdentificativo30Td, StyledIdentificativo30Th, StyledIdentificativoTd, StyledIdentificativoTh } from "./cybersecurity-board-infrastruttura-elencoasset-styled-components";
import { FrameTopGradient100 } from "./cybersecurity-board-infrastruttura-organigramma-styled-components";
import { addLuoghiDeleteConfirmationDialog } from "./Luoghi-Add-recoil";

interface DataItem {
  id: string;
  site_name: string;
  name: string;
  plan_ref: string;
  responsible_name: string;
  responsible_phone: string;
}

type SortableKeys = keyof DataItem;

interface RoleItem {
  responsibility: string;
  roleDescription: string;
}

type ApiContentItem = {
  id: string;
  site_name: string;
  name: string;
  plan_ref: string;
  roles: RoleItem[];
};

type StyledTrProps = {
  selected: boolean;
};

const StyledTr = styled.tr<StyledTrProps>`
  cursor: pointer;
  background-color: ${(props) => {
    return props.selected ? "#f3f7fb" : "#ffffff";
  }};

  &:hover {
    background-color: #e9eff7;
  }
`;

interface OverlayComponentProps {
  isAnimating: boolean;
}

const OverlayComponentAdd1 = styled.div<OverlayComponentProps>`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  z-index: 10;
  transition: opacity 300ms ease-in-out, visibility 300ms ease-in-out;
  opacity: ${(props) => (props.isAnimating ? "1" : "0")};
  visibility: ${(props) => (props.isAnimating ? "visible" : "hidden")};
`;

const useDeletePlace = () => {
  const { del } = useApi();
  const [reFetchIndicator, setRefetchIndicator] = useRecoilState(reFetchIndicatorPlaces);
  const [isConfirmDialogVisible, setIsConfirmDialogVisible] = useRecoilState(addLuoghiDeleteConfirmationDialog);
  const [L3ID, setL3ID] = useRecoilState(SelectedL3ID);

  const handleDelete = useCallback(async () => {
    if (!L3ID) return;

    const url = URL_PlacesFullList;
    try {
      await del(url, L3ID);
      console.log(`Deleted asset with ID: ${L3ID}`);
    } catch (error) {
      console.error("Failed to delete asset:", error);
    } finally {
      setRefetchIndicator((prev) => prev + 1);
      setIsConfirmDialogVisible(false);
    }
  }, [L3ID, del, setRefetchIndicator]);

  const showDeleteConfirmation = useCallback(() => {
    setIsConfirmDialogVisible(true);
  }, []);

  return {
    handleDelete,
    showDeleteConfirmation,
    isConfirmDialogVisible,
    setIsConfirmDialogVisible,
  };
};

const DataTable: React.FC<{ data: DataItem[] }> = ({ data }) => {
  const [isOverlayVisibleAdd2, setIsOverlayVisibleAdd2] = useState(false);
  const [isOverlayAnimatingAdd2, setIsOverlayAnimatingAdd2] = useState(false);

  const navigate = useNavigate();

  const toggleOverlayAdd2 = () => {
    if (!isOverlayVisibleAdd2) {
      if (window.location.pathname === "/Cybersecurity/Infrastruttura") {
        navigate(`Infrastruttura/CreaLuoghi/1`);
      } else {
        navigate(`${window.location.pathname}/CreaLuoghi/1`);
      }

      setIsOverlayVisibleAdd2(true);
      setTimeout(() => setIsOverlayAnimatingAdd2(true), 10);
    } else {
      setIsOverlayAnimatingAdd2(false);
      setTimeout(() => setIsOverlayVisibleAdd2(false), 310);
    }
  };

  const closeOverlayAdd2 = () => {
    navigate(`/Cybersecurity/Infrastruttura/Luoghi`);
    setIsOverlayAnimatingAdd2(false);

    setTimeout(() => {
      setIsOverlayVisibleAdd2(false);
    }, 300);
  };

  const [selectedL1, setSelectedL1] = useState<string | null>(null);
  const [selectedL2, setSelectedL2] = useState<string | null>(null);
  const [selectedL3, setSelectedL3] = useState<string | null>(null);
  const [selectedL4, setSelectedL4] = useState<string | null>(null);

  const [L1ID, setL1ID] = useRecoilState(SelectedL1ID);
  const [L2ID, setL2ID] = useRecoilState(SelectedL2ID);
  const [L3ID, setL3ID] = useRecoilState(SelectedL3ID);
  const [L4ID, setL4ID] = useRecoilState(SelectedL4ID);

  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const [isMessageBoxVisible, setIsMessageBoxVisible] = useRecoilState(isMessageBoxVisibleRec);
  const [isMessageBoxVisibleClosing, setIsMessageBoxVisibleClosing] = useRecoilState(isMessageBoxVisibleClosingRec);

  const [sortConfig, setSortConfig] = useState<{
    key: SortableKeys;
    direction: "ascending" | "descending";
  } | null>(null);

  const sortedData = useMemo(() => {
    let sortableItems = [...data];
    if (sortConfig !== null) {
      sortableItems.sort((a, b) => {
        const aValue = a[sortConfig.key];
        const bValue = b[sortConfig.key];

        if (typeof aValue === "string" && typeof bValue === "string") {
          return sortConfig.direction === "ascending" ? aValue.localeCompare(bValue) : bValue.localeCompare(aValue);
        }

        return 0;
      });
    }
    return sortableItems;
  }, [data, sortConfig]);

  const requestSort = (key: SortableKeys) => {
    let direction: "ascending" | "descending" = "ascending";
    if (sortConfig && sortConfig.key === key && sortConfig.direction === "ascending") {
      direction = "descending";
    }
    setSortConfig({ key, direction });
  };

  const [selectedId, setSelectedId] = useState<string | null>(null);

  const handleRowSelect = (id: string) => {
    setSelectedId((prevId) => {
      return id;
    });

    setL3ID(id);
  };

  useEffect(() => {
    console.log("L3ID now has the value:", L3ID);
  }, [L3ID]);

  const [globalFilterText, setGlobalFilterText] = useState("");

  const handleGlobalFilterChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setGlobalFilterText(event.target.value);
  };

  const [filterText, setFilterText] = useState("");

  const handleFilterChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setFilterText(event.target.value);
  };

  const filteredAndSortedData = useMemo(() => {
    const filteredData = data
      .filter((item) => {
        const itemSiteName = item.site_name?.toLowerCase() ?? "";
        return itemSiteName.includes(filterText.toLowerCase());
      })
      .filter((item) => {
        if (globalFilterText) {
          const allText = Object.values(item)
            .reduce((acc, cur) => {
              const value = (cur ?? "").toString().toLowerCase();
              return acc + value + " ";
            }, "")
            .trim();

          return allText.includes(globalFilterText.toLowerCase());
        }
        return true;
      });

    if (sortConfig !== null) {
      filteredData.sort((a, b) => {
        const aValue = (a[sortConfig.key] ?? "").toString();
        const bValue = (b[sortConfig.key] ?? "").toString();

        if (sortConfig.direction === "ascending") {
          return aValue.localeCompare(bValue);
        } else {
          return bValue.localeCompare(aValue);
        }
      });
    }

    return filteredData;
  }, [data, sortConfig, filterText, globalFilterText]);

  const { handleDelete, showDeleteConfirmation, isConfirmDialogVisible, setIsConfirmDialogVisible } = useDeletePlace();

  return (
    <TableContainer>
      <TopContainer>
        <SearchInputContainer>
          <Search placeholder="Filtra per riferimento" onChange={handleFilterChange} />
          <SearchIcon src="/funnel.svg" />
        </SearchInputContainer>
        <SearchInputContainer>
          <Search placeholder="Cerca in tutta la tabella" onChange={handleGlobalFilterChange} />
          <SearchIcon src="/search.svg" />
        </SearchInputContainer>

        <IconsGroup>
          {/* <IconButton>
            <BackgroundIcon loading="eager" alt="" src="/doc.svg" />
    </IconButton> */}
          {/* <IconButton>
            <BackgroundIcon loading="eager" alt="" src="/edit.svg" />
          </IconButton>*/}
          <IconButton onClick={() => showDeleteConfirmation()}>
            <BackgroundIcon loading="eager" alt="" src="/delete.svg" />
          </IconButton>
          <IconButton>
            <BackgroundIcon loading="eager" alt="" src="/add.svg" onClick={toggleOverlayAdd2} />
          </IconButton>
        </IconsGroup>
      </TopContainer>

      <StyledTable>
        <StyledThead>
          <StyledTh onClick={() => requestSort("site_name")}>
            <TableTitleText>▼ Riferimento</TableTitleText>
          </StyledTh>

          <StyledIdentificativo30Th onClick={() => requestSort("name")}>
            <TableTitleText>▼ Descrizione</TableTitleText>
          </StyledIdentificativo30Th>

          <StyledTh onClick={() => requestSort("responsible_name")}>
            <TableTitleText>▼ Responsabile</TableTitleText>
          </StyledTh>

          <StyledTh onClick={() => requestSort("responsible_phone")}>
            <TableTitleText>▼ Contatto</TableTitleText>
          </StyledTh>

          <StyledIdentificativoTh onClick={() => requestSort("plan_ref")}>
            <TableTitleText>▼ Identificativo</TableTitleText>
          </StyledIdentificativoTh>
        </StyledThead>
      </StyledTable>
      <TableScrollableDiv>
        <StyledTableItem>
          <StyledTbody>
            {filteredAndSortedData.map((item) => (
              <StyledTr key={item.id} onClick={() => handleRowSelect(item.id)} selected={selectedId === item.id}>
                <StyledTd title={item.site_name}>
                  <TableItemText>{item.site_name}</TableItemText>
                </StyledTd>
                <StyledIdentificativo30Td title={item.name}>
                  <TableItemText>{item.name}</TableItemText>
                </StyledIdentificativo30Td>
                <StyledTd title={item.responsible_name}>
                  <TableItemText>{item.responsible_name}</TableItemText>
                </StyledTd>
                <StyledTd title={item.responsible_phone}>
                  <TableItemText>{item.responsible_phone}</TableItemText>
                </StyledTd>
                <StyledIdentificativoTd title={item.plan_ref}>
                  <TableItemText>{item.plan_ref}</TableItemText>
                </StyledIdentificativoTd>
              </StyledTr>
            ))}
          </StyledTbody>
        </StyledTableItem>
      </TableScrollableDiv>

      <OverlayContext.Provider
        value={{
          closeOverlay: closeOverlayAdd2,
          selectedL1,
          setSelectedL1,
          selectedL2,
          setSelectedL2,
          selectedL3,
          setSelectedL3,
          selectedL4,
          setSelectedL4,
        }}
      >
        {isOverlayVisibleAdd2 && (
          <OverlayComponentAdd1 isAnimating={isOverlayAnimatingAdd2}>
            {/* <ButtonClose src="/close.svg" onClick={closeOverlayAdd2} /> */}
            <FadeOutComponent>
              <FrameTopGradient100></FrameTopGradient100>
            </FadeOutComponent>

            <Outlet />
          </OverlayComponentAdd1>
        )}
      </OverlayContext.Provider>
    </TableContainer>
  );
};

const Board27001Dashboard: React.FC = () => {
  const [L1ID, setL1ID] = useRecoilState(SelectedL1ID);
  const [L2ID, setL2ID] = useRecoilState(SelectedL2ID);
  const [L3ID, setL3ID] = useRecoilState(SelectedL3ID);
  const [L4ID, setL4ID] = useRecoilState(SelectedL4ID);

  const [data, setData] = useState<DataItem[]>([]);
  const [selectedL1, setSelectedL1] = useState<string | null>(null);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const [isMessageBoxVisible, setIsMessageBoxVisible] = useState(false);
  const [reFetchIndicator, setRefetchIndicator] = useRecoilState(reFetchIndicatorPlaces);
  const [isMessageBoxVisibleClosing, setIsMessageBoxVisibleClosing] = useRecoilState(isMessageBoxVisibleClosingRec);

  const token = useToken();

  const { closeOverlay } = useContext(OverlayContext);

  const handleCloseAndNavigate = () => {
    setIsMessageBoxVisibleClosing(false);
    closeOverlay();
  };

  useEffect(() => {
    const fetchData = async () => {
      interface PlaceItem {
        id: string;
        creationTs: string;
        updateTs: string;
        createdBy: string;
        updatedBy: string;
        name: string;
        site_name: string;
        plan_ref: string;
        responsible: {
          name: string;
          surname: string;
          personal_card?: {
            work_mobile_phone: string;
          };
        };
      }

      setIsLoading(true);
      try {
        if (!token) throw new Error("User is not authenticated");

        const infoResponse = await fetch(`${URL_PlacesList}/info`, {
          headers: { Authorization: `Bearer ${token}` },
        });

        if (!infoResponse.ok) {
          throw new Error(`API request failed: ${infoResponse.statusText}`);
        }

        const { total } = await infoResponse.json();

        const dataResponse = await fetch(`${URL_PlacesList}?size=${total}`, {
          headers: { Authorization: `Bearer ${token}` },
        });

        if (!dataResponse.ok) {
          throw new Error(`API request failed: ${dataResponse.statusText}`);
        }

        const responseData = await dataResponse.json();

        if (responseData && Array.isArray(responseData.content)) {
          const transformedData = responseData.content.map((item: PlaceItem) => ({
            id: item.id,
            site_name: item.site_name,
            name: item.name,
            plan_ref: item.plan_ref,
            responsible_name: (item.responsible?.name || "") + " " + (item.responsible?.surname || ""),
            responsible_phone: item.responsible?.personal_card?.work_mobile_phone,
          }));

          console.log("Transformed Data:", transformedData);
          setData(transformedData);
        } else {
          console.error("responseData.content is not an array or does not exist:", responseData);
        }
      } catch (err) {
        setError(err instanceof Error ? err.message : "An unknown error occurred");
        setIsMessageBoxVisible(true);
      } finally {
        setIsLoading(false);
      }
    };

    fetchData();
  }, [token, reFetchIndicator]);

  const { handleDelete, showDeleteConfirmation, isConfirmDialogVisible, setIsConfirmDialogVisible } = useDeletePlace();

  return (
    <div>
      {isMessageBoxVisible && (
        <Backdrop>
          <MessageContainer>
            <p>
              <MessageboxText>{error}</MessageboxText>
            </p>
            <CloseMessageButton onClick={() => setIsMessageBoxVisible(false)}>
              <MessageboxText>Close</MessageboxText>
            </CloseMessageButton>
          </MessageContainer>
        </Backdrop>
      )}

      {isMessageBoxVisibleClosing && (
        <Backdrop>
          <MessageContainer>
            <p>
              <MessageboxText>{error}</MessageboxText>
            </p>
            <CloseMessageButton onClick={handleCloseAndNavigate}>
              <MessageboxText>Close</MessageboxText>
            </CloseMessageButton>
          </MessageContainer>
        </Backdrop>
      )}

      {isLoading && (
        <BackdropTransparent>
          <MessageContainer>
            <MessageboxText>Loading...</MessageboxText>
          </MessageContainer>
        </BackdropTransparent>
      )}

      {error && (
        <Backdrop>
          <MessageContainer>
            <p>
              <MessageboxText>{error}</MessageboxText>
            </p>
            <CloseMessageButton
              onClick={() => {
                setIsMessageBoxVisible(false);
                setError(null);
              }}
            >
              <MessageboxText>Close</MessageboxText>
            </CloseMessageButton>
          </MessageContainer>
        </Backdrop>
      )}

      {isConfirmDialogVisible && (
        <Backdrop>
          <MessageContainer>
            <p>
              <MessageboxText>Sei sicuro di voler rimuovere questo luogo?</MessageboxText>
            </p>
            <div style={{ display: "flex", justifyContent: "center", gap: "20px" }}>
              <CloseMessageButton onClick={() => setIsConfirmDialogVisible(false)}>
                <MessageboxText>No</MessageboxText>
              </CloseMessageButton>
              <CloseMessageButton onClick={handleDelete}>
                <MessageboxText>Rimuovi</MessageboxText>
              </CloseMessageButton>
            </div>
          </MessageContainer>
        </Backdrop>
      )}

      <DataTable data={data} />
    </div>
  );
};

export default Board27001Dashboard;
