import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  FormControlLabel,
  FormLabel,
  Grid,
  IconButton,
  List,
  ListItem,
  ListItemText,
  Radio,
  RadioGroup,
  TextField,
  Typography
} from "@mui/material";
import Divider from "@mui/material/Divider";
import React, { ChangeEvent, useCallback, useEffect, useMemo, useRef, useState } from "react";
import { useRecoilState } from "recoil";
import styled from "styled-components";
import { SelectedL4ID, isLoadingRec, isMessageBoxVisibleRec, reFetchIndicatorL3, setErrorRec, setMessageRec } from "../../../../../recoil";
import {
  Backdrop,
  BackdropTransparent,
  BackgroundIcon,
  CloseMessageButton,
  CreaContainerColumn,
  DescriptionContainerCreaMultiple,
  DescriptionContainerCreaText,
  FlexContainerCrea,
  IconSquare,
  IconsGroup,
  InputContainerRoles,
  InputDescriptionTextRoles,
  LastPopupButtonContainer,
  LastPopupCheckboxContainer,
  LastPopupHeaderContainer,
  LastPopupScrollbar,
  LastPopupSearch,
  LastPopupSearchIcon,
  LastPopupSearchInputContainer,
  LastPopupTitleContainer,
  MessageContainer,
  MessageboxText,
  OverlayComponentWhiteAdd1Multiple,
  RoleListDescriptionScrollbar,
  RoleListScrollbar,
  SearchRoles
} from "../cybersecurity-board-organizzazione-organigramma-styled-components";

import { URL_RoleList, URL_RoleShort, useApi, useToken } from "../../../../../API";
import { ButtonGreen, ButtonGrey, ButtonText } from "../../../styled-components";

import { TAB_DESCRIPTION_GLOBAL, TAB_DESCRIPTION_L3_1 } from "../../tabs-description";
import {
  addL3ComlpianceDataState,
  addL3ComplianceData,
  addL3Model,
  addL3ModelDataState,
  addL3Role,
  addL3RoleID,
  addL3RoleListData,
  addL3RoleListDataID,
  addL3RoleState,
  addL3searchTerm,
  addL3selectedAggrId,
  addL3selectedCheckboxesProcessiState,
  addL3selectedModelId,
  addL3selectedModelName,
  roleDataInterface,
} from "../L3-Add-recoil";
import { useOverlayControlL3Show } from "../cybersecurity-board-organizzazione-ruoligenerali";
import {
  SearchIcon,
  SearchInputContainerSoloRoles,
  TopContainerRoles
} from "../cybersecurity-board-organizzazione-unitaoperative-styled-components";

interface RolesAccordionProps {
  role: addL3Role[];
  model: addL3Model;
  token: string | null | undefined;
}

interface ModelSelectorProps {
  models: addL3Model[];
  selectedModelId: string;
  onModelChange: (newModelId: string) => void;
}

type GroupedModels = {
  [key: string]: {
    id: string;
    organizational_unit: {
      id: string;
      prefLabel: string;
    };
    company_role: addL3Role[];
  };
};

interface LastPopupProps {
  isVisible: boolean;
}

const LastPopupBlack = styled.div<LastPopupProps>`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 99;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: opacity 0.3s ease, visibility 0.3s ease;
  opacity: ${({ isVisible }) => (isVisible ? 1 : 0)};
  visibility: ${({ isVisible }) => (isVisible ? "visible" : "hidden")};
`;

const LastPopup = styled.div`
  font-family: var(--font-titillium-web);
  font-size: var(--font-size-micro);
  color: var(--color-steelblue-700);
  position: fixed;
  right: 0px;
  top: 0px;
  bottom: 0px;
  width: 75%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: var(--color-aliceblue);

  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.25);

  z-index: 100;
`;

const ModelSelector: React.FC<ModelSelectorProps> = ({ models, onModelChange, selectedModelId }) => {
  return (
    <FormControl component="fieldset">
      <FormLabel component="legend" sx={{ color: "#6f8aab", padding: "8px" }}></FormLabel>
      <RadioGroup aria-label="model" name="model" value={selectedModelId} onChange={(event) => onModelChange(event.target.value)} sx={{ padding: "5px 10px 10px 20px" }}>
        {models.map((model) => (
          <FormControlLabel
            key={model.organizational_unit.id}
            value={model.organizational_unit.id}
            control={
              <Radio
                sx={{
                  "& .MuiSvgIcon-root": { fontSize: "1rem", color: "#6f8aab" },
                }}
              />
            }
            label={model.organizational_unit.prefLabel}
            sx={{
              color: "#6f8aab",
              "& .MuiTypography-root": { fontSize: "0.875rem" },
            }}
          />
        ))}
      </RadioGroup>
    </FormControl>
  );
};

const RolesAccordion: React.FC<RolesAccordionProps> = ({ role, model, token }) => {
  const [complianceData, setComplianceData] = useRecoilState(addL3ComlpianceDataState);
  const [isLoading, setIsLoading] = useRecoilState(isLoadingRec);
  const [error, setError] = useRecoilState<string | null>(setErrorRec);
  const [selectedAggrId, setSelectedAggrId] = useRecoilState(addL3selectedAggrId);
  const [searchTerm, setSearchTerm] = useRecoilState(addL3searchTerm);
  const [selectedCheckboxes, setSelectedCheckboxes] = useRecoilState(addL3selectedCheckboxesProcessiState);

  const handleCheckboxChange = useCallback(
    (itemId: string, checked: boolean) => {
      console.log("Checkbox ID before update: ", itemId);
      setSelectedCheckboxes((currentSelected) => {
        const updatedSelected = checked ? [...currentSelected, itemId] : currentSelected.filter((id) => id !== itemId);

        console.log("Updated selected checkboxes inside callback: ", updatedSelected);
        return updatedSelected;
      });
    },
    [setSelectedCheckboxes]
  );

  useEffect(() => {
    const initiallySelected = complianceData.filter((item) => item.selected).map((item) => item.id);
    setSelectedCheckboxes(initiallySelected);
  }, [complianceData, setSelectedCheckboxes]);

  const filteredComplianceData = searchTerm
    ? complianceData.filter(
        (dataItem) =>
          dataItem.process_unit_association.business_process.name.toLowerCase().includes(searchTerm.toLowerCase()) ||
          dataItem.process_unit_association.business_process.description.toLowerCase().includes(searchTerm.toLowerCase()) ||
          dataItem.process_unit_association.business_process.kind.toLowerCase().includes(searchTerm.toLowerCase()) ||
          dataItem.process_unit_association.business_process.objective.toLowerCase().includes(searchTerm.toLowerCase())
      )
    : complianceData;

  const filteredRoles = role.filter((singleRole) => {
    const roleMatchesSearchTerm = singleRole.role_description.toLowerCase().includes(searchTerm.toLowerCase());

    const complianceDataForRole = complianceData.filter(
      (dataItem) =>
        dataItem.process_unit_association.business_process.name.toLowerCase().includes(searchTerm.toLowerCase()) ||
        dataItem.process_unit_association.business_process.description.toLowerCase().includes(searchTerm.toLowerCase()) ||
        dataItem.process_unit_association.business_process.kind.toLowerCase().includes(searchTerm.toLowerCase()) ||
        dataItem.process_unit_association.business_process.objective.toLowerCase().includes(searchTerm.toLowerCase())
    );

    return roleMatchesSearchTerm || complianceDataForRole.length > 0;
  });

  if (!role) return null;

  const groupedByBusinessProcess = useMemo(() => groupComplianceByBusinessProcess(complianceData), [complianceData]);

  return (
    <Box sx={{ mt: 2 }}>
      {role.map((role, roleIndex) => (
        <Accordion key={role.id}>
          <AccordionSummary expandIcon={<ExpandMoreIcon />}>
            <Typography color="#04446c">{role.role_description}</Typography>
          </AccordionSummary>
          <AccordionDetails sx={{ flexDirection: "column" }}>
            {isLoading && <Typography>Loading...</Typography>}
            {error && <Typography color="error">{error}</Typography>}
            {Object.entries(groupComplianceByBusinessProcess(filteredComplianceData)).map(([businessProcessId, complianceItems]) => (
              <Accordion expanded={true} key={businessProcessId} sx={{ mt: 1, boxShadow: "0px 2px 6px rgba(0, 0, 0, 0.25)" }}>
                <AccordionSummary
                  sx={{
                    paddingTop: "0px",
                    fontWeight: 500,
                    fontStyle: "italic",
                    marginBottom: "-15px",
                  }}
                >
                  {/* Assuming the first item's business process name is representative of all items in the group */}

                  <Typography>{complianceItems[0]?.process_unit_association.business_process.name}</Typography>
                </AccordionSummary>
                <AccordionDetails>
                  {complianceItems.map((item, index) => (
                    <Box key={index} sx={{ mt: 1 }}>
                      {/* <Typography>{item.process_unit_association.business_process.name}</Typography> */}
                      <Divider sx={{ my: 2 }} />
                      <Typography>
                        <br />
                      </Typography>
                      <Typography>Categoria: {item.process_unit_association.business_process.kind}</Typography>
                      <Typography>
                        <br />
                      </Typography>
                      <Typography>Obiettivo: {item.process_unit_association.business_process.objective}</Typography>
                      <Typography>
                        <br />
                      </Typography>
                      <Typography>Descrizione: {item.process_unit_association.business_process.description}</Typography>

                      <Typography>
                        <br />
                      </Typography>
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={selectedCheckboxes.includes(item.id)}
                            onChange={(e) => handleCheckboxChange(item.id, e.target.checked)}
                            inputProps={{ "aria-label": "Is Responsible" }}
                            disabled={false}
                            sx={{
                              color: "#04446c",
                              "& .MuiSvgIcon-root": { fontSize: 16 },
                              "&.Mui-checked": { color: "#04446c" },
                              padding: "0px",
                              marginRight: "10px",
                            }}
                          />
                        }
                        label="Questo processo é associato alla persona selezionata"
                        sx={{
                          marginTop: "10px",

                          marginLeft: "0px",
                          color: "#04446c",
                          display: "flex",
                          flexDirection: "row",
                          justifyContent: "flex-start",
                          alignItems: "center",
                        }}
                        labelPlacement="end"
                      />
                      <Typography>
                        <br />
                      </Typography>
                    </Box>
                  ))}
                </AccordionDetails>
              </Accordion>
            ))}
          </AccordionDetails>
        </Accordion>
      ))}
    </Box>
  );
};

const groupComplianceByBusinessProcess = (complianceData: addL3ComplianceData[]) => {
  return complianceData.reduce((acc, item) => {
    const bpId = item.process_unit_association.business_process.id;
    if (!acc[bpId]) {
      acc[bpId] = [];
    }
    acc[bpId].push(item);
    return acc;
  }, {} as { [key: string]: addL3ComplianceData[] });
};

interface SearchInputProps {
  value: string;
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
}

const SearchInput: React.FC<SearchInputProps> = React.memo(({ value, onChange }) => {
  const inputRef = useRef<HTMLInputElement>(null);

  return <LastPopupSearch ref={inputRef} type="text" value={value} onChange={onChange} placeholder="Cerca" />;
});

const MainComponent = () => {
  const { toggleOverlayL3, isVisibleL3, isAnimatingL3, closeOverlayL3 } = useOverlayControlL3Show("/Cybersecurity/Organizzazione/Ruoli/CreaL3/1");

  const [L4ID, setL4ID] = useRecoilState(SelectedL4ID);
  const token = useToken();
  const [isLoading, setIsLoading] = useRecoilState(isLoadingRec);
  const [error, setError] = useRecoilState<string | null>(setErrorRec);
  const [isMessageBoxVisible, setIsMessageBoxVisible] = useRecoilState(isMessageBoxVisibleRec);
  const [message, setMessage] = useRecoilState(setMessageRec);
  const [searchTerm, setSearchTerm] = useRecoilState(addL3searchTerm);

  const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchTerm(event.target.value);
  };

  const handleCloseAndNavigate = () => {
    setIsMessageBoxVisible(false);
    closeOverlayL3();
  };

  const [isVisible, setIsVisible] = useState(false);
  const togglePopup = () => {
    setIsVisible(!isVisible);
  };

  const [ComplianceData, setComplianceData] = useRecoilState(addL3ComlpianceDataState);
  const [Modeldata, setModelData] = useRecoilState(addL3ModelDataState);
  const [selectedModelId, setSelectedModelId] = useRecoilState(addL3selectedModelId);
  const [selectedModelName, setSelectedModelName] = useRecoilState(addL3selectedModelName);
  const [selectedAggrId, setSelectedAggrId] = useRecoilState(addL3selectedAggrId);

  const [selectedModelRoles, setSelectedModelRoles] = useRecoilState(addL3RoleState);

  const [reFetchIndicator, setRefetchIndicator] = useRecoilState(reFetchIndicatorL3);

  const [roleData, setRoleData] = useRecoilState(addL3RoleListData);
  const [selectedRoleListId, setSelectedRoleListId] = useRecoilState(addL3RoleListDataID);
  const [selectedRole, setSelectedRole] = useState<roleDataInterface>();

  useEffect(() => {
    const fetchRoles = async () => {
      try {
        const response = await fetch(URL_RoleList, {
          method: "GET",
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        if (!response.ok) {
          throw new Error("Network response was not ok");
        }
        const data: roleDataInterface[] = await response.json();
        data.sort((a, b) => a.role_description.localeCompare(b.role_description));

        setRoleData(data);
        setSelectedRole(data[0]);
        setSelectedRoleListId(data[0].id);
      } catch (error) {
        console.error("There has been a problem with your fetch operation:", error);
      }
    };

    fetchRoles();
  }, [reFetchIndicator]);

  const handleRoleSubmit = async () => {
    try {
      const response = await fetch(URL_RoleShort, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify({
          id: newRole.roleId,
          role_description: newRole.roleName,
          responsibility: newRole.roleDescription,
        }),
      });
      if (!response.ok) {
        console.log();
        throw new Error("Network response was not ok");
      }

      const data = await response.json();
      console.log("Role created:", data);
      setRefetchIndicator((prev) => prev + 1);
      setIsConfirmDialogVisible(false);
      handleCloseDialog();
    } catch (error) {
      console.error("Failed to post new role:", error);
      setRefetchIndicator((prev) => prev + 1);
      setIsConfirmDialogVisible(false);
      handleCloseDialog();
    }
  };

  const handleSelectRoleList = (role: roleDataInterface) => {
    setSelectedRoleListId(role.id);
    setSelectedRole(role);
  };

  const handleRoleListSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchTerm(event.target.value.toLowerCase());
  };

  const handleOpenDialog = () => {
    const newUUID = generateUUID();
    setNewRole({
      roleId: newUUID,
      roleName: "",
      roleDescription: "",
      roleResponsibilities: "",
    });
    setOpenDialog(true);
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
    setNewRole({
      roleId: "",
      roleName: "",
      roleDescription: "",
      roleResponsibilities: "",
    });
  };

  const [openDialog, setOpenDialog] = useState(false);
  const [newRole, setNewRole] = useState({
    roleId: "",
    roleName: "",
    roleDescription: "",
    roleResponsibilities: "",
  });

  const handleInputChange = (e: ChangeEvent<HTMLInputElement>) => {
    setNewRole({ ...newRole, [e.target.name]: e.target.value });
  };

  function generateUUID(): string {
    return "xxxxxxxx-xxxx-xxxx-yxxx-xxxxxxxxxxxx".replace(/[xy]/g, (c) => {
      const r = (Math.random() * 16) | 0;
      const v = c === "x" ? r : (r & 0x3) | 0x8;
      return v.toString(16);
    });
  }

  function useGenerateUUID(): string {
    const [roleIdPost, setRoleIdPost] = useRecoilState<string>(addL3RoleID);

    useEffect(() => {
      const newUUID = generateUUID();
      setRoleIdPost(newUUID);
      console.log("New person ID: ", newUUID);
    }, [setRoleIdPost]);

    return roleIdPost;
  }

  useGenerateUUID();

  const useDeletePerson = () => {
    const { del } = useApi();
    const [reFetchIndicator, setRefetchIndicator] = useRecoilState(reFetchIndicatorL3);
    const [isConfirmDialogVisible, setIsConfirmDialogVisible] = useState(false);

    const handleDelete = useCallback(async () => {
      if (!selectedRoleListId) return;

      const url = URL_RoleShort;
      try {
        await del(url, `${selectedRoleListId}/cascade`);
        console.log(`Deleted role with ID: ${selectedRoleListId}`);
        setRefetchIndicator((prev) => prev + 1);
        setIsConfirmDialogVisible(false);
      } catch (error) {
        console.error("Failed to delete role:", error);
        setRefetchIndicator((prev) => prev + 1);
        setIsConfirmDialogVisible(false);
      }
    }, [selectedRoleListId, del, setRefetchIndicator]);

    const showDeleteConfirmation = useCallback(() => {
      setIsConfirmDialogVisible(true);
    }, []);

    return {
      handleDelete,
      showDeleteConfirmation,
      isConfirmDialogVisible,
      setIsConfirmDialogVisible,
    };
  };

  const { handleDelete, showDeleteConfirmation, isConfirmDialogVisible, setIsConfirmDialogVisible } = useDeletePerson();

  return (
    <OverlayComponentWhiteAdd1Multiple>
      <Dialog
        open={openDialog}
        onClose={handleCloseDialog}
        PaperProps={{
          sx: {
            borderRadius: "0px",
            width: "800px",
          },
        }}
      >
        <DialogTitle
          sx={{
            backgroundColor: "#C8D6E8",
            color: "#506987",
            padding: "15px",
            fontFamily: "Titillium-Web, sans-serif",
            fontSize: "14px",
            textAlign: "center",
            marginBottom: "20px",
          }}
        >
          AGGIUNGI RUOLO
        </DialogTitle>
        <DialogContent>
          <TextField
            autoFocus
            margin="dense"
            name="roleName"
            label="Ruolo"
            type="text"
            fullWidth
            variant="outlined"
            value={newRole.roleName}
            onChange={handleInputChange}
            InputLabelProps={{
              shrink: true,
            }}
            sx={{ marginBottom: 2 }}
          />
          <TextField
            margin="dense"
            name="roleDescription"
            label={`Descrizione ruolo (${newRole.roleDescription.length}/255)`}
            type="text"
            fullWidth
            multiline
            rows={6}
            variant="outlined"
            value={newRole.roleDescription}
            onChange={handleInputChange}
            InputLabelProps={{
              shrink: true,
            }}
            inputProps={{
              maxLength: 255,
            }}
          />
        </DialogContent>
        <DialogActions
          sx={{
            justifyContent: "center",
            marginBottom: "20px",
            "& > :not(:last-child)": { marginRight: "20px" },
          }}
        >
          <ButtonGreen onClick={handleCloseDialog}>
            <ButtonText>Annulla</ButtonText>
          </ButtonGreen>

          <ButtonGreen onClick={handleRoleSubmit}>
            <ButtonText>Crea</ButtonText>
          </ButtonGreen>
        </DialogActions>
      </Dialog>

      {isConfirmDialogVisible && (
        <Backdrop>
          <MessageContainer>
            <p>
              <MessageboxText>Sei sicuro di voler rimuovere questo ruolo?</MessageboxText>
            </p>
            <div style={{ display: "flex", justifyContent: "center", gap: "20px" }}>
              <CloseMessageButton onClick={() => setIsConfirmDialogVisible(false)}>
                <MessageboxText>No</MessageboxText>
              </CloseMessageButton>
              <CloseMessageButton onClick={handleDelete}>
                <MessageboxText>Rimuovi</MessageboxText>
              </CloseMessageButton>
            </div>
          </MessageContainer>
        </Backdrop>
      )}

      {isLoading && (
        <BackdropTransparent>
          <MessageContainer>
            <MessageboxText>Loading...</MessageboxText>
          </MessageContainer>
        </BackdropTransparent>
      )}

      {isMessageBoxVisible && (
        <Backdrop>
          <MessageContainer>
            <p>
              <MessageboxText>{message}</MessageboxText>
            </p>
            <CloseMessageButton onClick={handleCloseAndNavigate}>
              <MessageboxText>Close</MessageboxText>
            </CloseMessageButton>
          </MessageContainer>
        </Backdrop>
      )}

      {error && (
        <Backdrop>
          <MessageContainer>
            <p>
              <MessageboxText>{error}</MessageboxText>
            </p>
            <CloseMessageButton
              onClick={() => {
                setIsMessageBoxVisible(false);
                setError(null);
                closeOverlayL3();
              }}
            >
              <MessageboxText>Close</MessageboxText>
            </CloseMessageButton>
          </MessageContainer>
        </Backdrop>
      )}
      <FlexContainerCrea>
        <CreaContainerColumn>
          {/*       
        <InputContainer>
            <InputDescriptionText>Ruoli</InputDescriptionText>
            <InputTextboxButton onClick={togglePopup2} >

            <ArrowButtonText>Clicca per visualizzare</ArrowButtonText>
            </InputTextboxButton>
            <IconContainer />
            <TriangleContainer>▶</TriangleContainer>
          </InputContainer> */}

          {/* <InputContainer>
            <InputDescriptionText>Associazione modelli-ruoli</InputDescriptionText>
            <InputTextboxButton onClick={togglePopup}>
              <ArrowButtonText>Apri per modificare</ArrowButtonText>
            </InputTextboxButton>
            <IconContainer />
            <TriangleContainer>▶</TriangleContainer>
          </InputContainer> */}
          <InputContainerRoles>
            <InputDescriptionTextRoles>
              <TopContainerRoles>
                <SearchInputContainerSoloRoles>
                  <SearchRoles placeholder="Cerca nella lista ruoli" onChange={handleRoleListSearchChange} />
                  <SearchIcon src="/search.svg" />
                </SearchInputContainerSoloRoles>

                <IconsGroup>
                  {/* <IconButton>
                    <BackgroundIcon loading="eager" alt="" src="/doc.svg" />
                  </IconButton>
                  <IconButton>
                    <BackgroundIcon loading="eager" alt="" src="/edit.svg" />
                  </IconButton> */}
                  <IconButton>
                    <BackgroundIcon loading="eager" alt="" src="/delete.svg" onClick={showDeleteConfirmation} />
                  </IconButton>
                  <IconButton>
                    <BackgroundIcon loading="eager" alt="" src="/add.svg" onClick={handleOpenDialog} />
                  </IconButton>
                </IconsGroup>
              </TopContainerRoles>
            </InputDescriptionTextRoles>

            {roleData.length > 0 && (
              <List
                sx={{
                  width: "100%",
                  bgcolor: "background.paper",
                  border: "1px solid #e6e6e6",

                  borderRadius: "0px",
                  overflow: "hidden",
                  marginTop: "10px",
                }}
              >
                <RoleListScrollbar>
                  {roleData
                    .filter((role) => role.role_description.toLowerCase().includes(searchTerm))
                    .map((role) => (
                      <ListItem
                        key={role.id}
                        button
                        onClick={() => handleSelectRoleList(role)}
                        selected={role.id === selectedRoleListId}
                        disableRipple
                        sx={{
                          "&.Mui-selected": {
                            bgcolor: "#f3f7fb",
                            "&:hover": {
                              bgcolor: "#c8d6e8",
                            },
                          },
                          "&:hover": {
                            bgcolor: "#e9eff7",
                          },
                        }}
                      >
                        <ListItemText primary={role.role_description} />
                      </ListItem>
                    ))}
                </RoleListScrollbar>
                {roleData.filter((role) => role.role_description.toLowerCase().includes(searchTerm)).length === 0 && <Typography sx={{ margin: 2, textAlign: "center" }}>Nessun risultato</Typography>}
              </List>
            )}
          </InputContainerRoles>
        </CreaContainerColumn>
        <CreaContainerColumn>
          {selectedRole && (
            <Box
              sx={{
                marginTop: "-15px",
                padding: 2,
                paddingBottom: 5,
                border: "1px solid #e6e6e6",
                height: "271px",
                bgcolor: "background.paper",
              }}
            >
              <Typography sx={{ fontSize: "0.9rem", marginBottom: "10px" }}>Ruolo: {selectedRole.role_description}</Typography>
              <Divider sx={{ marginRight: 2 }} />
              <RoleListDescriptionScrollbar>
                <Typography
                  sx={{
                    mt: 1,
                    maxHeight: "200px",
                    paddingRight: "10px",
                  }}
                >
                  Responsabilità: {selectedRole.responsibility || "No responsibilities listed."}
                </Typography>
              </RoleListDescriptionScrollbar>
            </Box>
          )}
        </CreaContainerColumn>

        {/* <CreaContainerColumn></CreaContainerColumn> */}

        <LastPopupBlack isVisible={isVisible}>
          <LastPopup>
            <LastPopupTitleContainer>MODELLI E RUOLI</LastPopupTitleContainer>
            <LastPopupCheckboxContainer>
              <LastPopupHeaderContainer>
                <LastPopupSearchInputContainer>
                  <SearchInput value={searchTerm} onChange={handleSearchChange} />
                  <LastPopupSearchIcon src="/search.svg" />
                </LastPopupSearchInputContainer>
              </LastPopupHeaderContainer>

              <LastPopupScrollbar>
                <Grid container spacing={2} sx={{ width: "100%" }}>
                  <Grid item xs={4}>
                    <Box
                      sx={{
                        backgroundColor: "#fff",
                        borderRadius: "4px",
                        boxShadow: "0 2px 4px rgba(0, 0, 0, 0.2)",
                        padding: "0px 10px 10px 5px",
                        marginTop: "16px",
                        marginLeft: "20px",
                      }}
                    >
                      {/* <ModelSelector
                        models={Modeldata}
                        onModelChange={handleModelChange}
                        selectedModelId={selectedModelId}
                      /> */}
                    </Box>
                  </Grid>
                  <Grid item xs={8}>
                    {/* {selectedModelRoles && selectedModel && (
                       
                       
                       
                       
                       
                    )} */}
                  </Grid>
                </Grid>
              </LastPopupScrollbar>
            </LastPopupCheckboxContainer>
            <LastPopupButtonContainer>
              <div
                style={{
                  display: "flex",
                  gap: "20px",
                  justifyContent: "center",
                }}
              >
                <ButtonGrey onClick={togglePopup}>
                  <ButtonText>Annulla</ButtonText>
                </ButtonGrey>

                <ButtonGrey onClick={togglePopup}>
                  <ButtonText>Salva</ButtonText>
                </ButtonGrey>
              </div>
            </LastPopupButtonContainer>
          </LastPopup>
        </LastPopupBlack>
      </FlexContainerCrea>
      <DescriptionContainerCreaMultiple>
        <IconSquare src="/description_show_process.svg" alt="Description Icon" />
        <DescriptionContainerCreaText>{TAB_DESCRIPTION_L3_1 ? TAB_DESCRIPTION_L3_1 : TAB_DESCRIPTION_GLOBAL}</DescriptionContainerCreaText>
      </DescriptionContainerCreaMultiple>
    </OverlayComponentWhiteAdd1Multiple>
  );
};

export default MainComponent;
