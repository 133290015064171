import React from "react";
import { useLocation } from "react-router-dom";
import styled from "styled-components";
import { FrameBoardMain, MainBoardSubMenuButton, MainBoardSubMenuButtonContainer } from "../styled-components";


const Board27001Organizzazione: React.FC = () => {
  const location = useLocation();
  const isActive = (path: string) => {
    return location.pathname === "/Cybersecurity/PianificazioneOperativa" && path === "PianificazioneOperativa";
  };

  const ImageContainer = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    width: 100%;
    margin-top: 30px;
  `;

  const StyledImage = styled.img`
    max-width: 95%;
    height: auto;
  `;

  return (
    <FrameBoardMain>
      <MainBoardSubMenuButtonContainer>
        <MainBoardSubMenuButton to="/Cybersecurity/PianificazioneOperativa" className={isActive("PianificazioneOperativa") ? "active" : ""}>
          Pianificazione operativa
        </MainBoardSubMenuButton>
        {/* <MainBoardSubMenuButton to="/Cybersecurity/PartiInteressate/Clienti">Clienti</MainBoardSubMenuButton>
        <MainBoardSubMenuButton to="/Cybersecurity/PartiInteressate/Fornitori">Fornitori</MainBoardSubMenuButton>
        <MainBoardSubMenuButton to="/Cybersecurity/PartiInteressate/AltriEnti">Altri enti</MainBoardSubMenuButton> */}
      </MainBoardSubMenuButtonContainer>

      <ImageContainer>
        <StyledImage src={"../pianificazione_operativa.png"} alt={"alt"} />
      </ImageContainer>
    </FrameBoardMain>
  );
};

export default Board27001Organizzazione;
