import React, { useState, useEffect, useRef, useContext } from "react";
import InfoIcon from "@mui/icons-material/InfoOutlined";
import Tooltip from "@mui/material/Tooltip";
import {
  CreaContainerColumn,
  FlexContainerCrea,
  IconContainerLong,
  IconContainerLongDouble,
  InputContainer,
  InputDescriptionText,
  InputDescriptionTextDouble,
  InputTextbox,
  InputTextboxDouble,
  OverlayComponentWhiteAdd1Multiple,
} from "../../cybersecurity-board-contestoleadership-organizzazione-sub/cybersecurity-board-organizzazione-organigramma-styled-components";
import { useRecoilState, useRecoilValue } from "recoil";
import { SelectedL4ID } from "../../../../../recoil";
import {
  AdempimentiAttachedFilesInfo,
  AdempimentiComplianceDate,
  AdempimentiComplianceResponsible,
  AdempimentiDescription,
  AdempimentiID,
  AdempimentiDocumentsAttached,
  AdempimentiDocumentsPath,
  AdempimentiKind,
  AdempimentiName,
  AdempimentiObjective,
  AdempimentiRifNorm,
  AdempimentiNota,

} from "../Adempimenti-Add-recoil";
import { useToken } from "../../../../../API";

const MainComponent = () => {
  const token = useToken();

  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const [isMessageBoxVisible, setIsMessageBoxVisible] = useState(false);
  const [message, setMessage] = useState("");

  ////////////RECOIL

  const [L4ID, setL4ID] = useRecoilState(SelectedL4ID);

  const [attachedFilesInfo, setAttachedFilesInfo] = useRecoilState(AdempimentiAttachedFilesInfo);
  const [documentsAttached, setDocumentsAttached] = useRecoilState(AdempimentiDocumentsAttached);
  const [docPath, setDocPath] = useRecoilState(AdempimentiDocumentsPath);
  const [ID, setID] = useRecoilState(AdempimentiID);
  const [name, setName] = useRecoilState(AdempimentiName);
  const [complianceResponsible, setComplianceResponsible] = useRecoilState(AdempimentiComplianceResponsible);
  const [complianceDate, setComplianceDate] = useRecoilState(AdempimentiComplianceDate);
  const [description, setDescription] = useRecoilState(AdempimentiDescription);
  const [kind, setKind] = useRecoilState(AdempimentiKind);
  const [objective, setObjective] = useRecoilState(AdempimentiObjective);
  const [rifNorm, setrifNorm] = useRecoilState(AdempimentiRifNorm);
  const [nota, setNota] = useRecoilState(AdempimentiNota);

  return (
    <OverlayComponentWhiteAdd1Multiple>
      <FlexContainerCrea>
        <CreaContainerColumn>
          <InputContainer>
            <InputDescriptionText>
              Denominazione
              <Tooltip title="Campo obbligatorio">
                <InfoIcon style={{ fontSize: "14px", color: "#04446c", cursor: "pointer", marginLeft: "2px" }} />
              </Tooltip>
            </InputDescriptionText>
            <InputTextbox placeholder="" value={name} onChange={(e) => setName(e.target.value)} />
            <IconContainerLong />
          </InputContainer>

          <InputContainer>
            <InputDescriptionText>
              Riferimento normativo
              {/* <Tooltip title="Campo obbligatorio">
                <InfoIcon style={{ fontSize: "14px", color: "#04446c", cursor: "pointer", marginLeft:"2px" }} />
              </Tooltip> */}
            </InputDescriptionText>
            <InputTextbox placeholder="" value={rifNorm} onChange={(e) => setrifNorm(e.target.value)} />
            <IconContainerLong />
          </InputContainer>

          <InputContainer>
            <InputDescriptionTextDouble>Descrizione {description.length}/255</InputDescriptionTextDouble>
            <InputTextboxDouble maxLength={255} placeholder="" value={description} onChange={(e) => setDescription(e.target.value)} />
            <IconContainerLongDouble />
          </InputContainer>

          <InputContainer>
            <InputDescriptionTextDouble>Nota {nota.length}/255 </InputDescriptionTextDouble>
            <InputTextboxDouble maxLength={255} placeholder="" value={nota} onChange={(e) => setNota(e.target.value)} />
            <IconContainerLongDouble />
          </InputContainer>

        </CreaContainerColumn>

      </FlexContainerCrea>
      {/* <DescriptionContainerCreaMultiple>
        <IconSquare src="/crea-L1-schedagenerale.svg" alt="Description Icon" />
              <DescriptionContainerCreaText>
             
              Inserire i dettagli identificativi dell'unità operativa, comprensivi di un nome completo che rifletta le competenze e i servizi offerti, un nome abbreviato per una rapida identificazione, oltre ai principali canali di comunicazione per facilitare un contatto efficace e una pronta corrispondenza


              </DescriptionContainerCreaText>

        </DescriptionContainerCreaMultiple> */}
    </OverlayComponentWhiteAdd1Multiple>
  );
};

export default MainComponent;
