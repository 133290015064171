import React, { useCallback, useEffect, useMemo, useState } from "react";
import { Outlet, useNavigate } from "react-router-dom";
import styled from "styled-components";
import { OverlayContext } from "../cybersecurity-board-contestoleadership-organizzazione";

import {
  Backdrop,
  BackdropTransparent,
  BackgroundIcon,
  CloseMessageButton,
  FadeOutComponent,
  FrameTopGradient100,
  IconButton,
  IconsGroup,
  MessageContainer,
  MessageboxText,
} from "../cybersecurity-board-contestoleadership-organizzazione-sub/cybersecurity-board-organizzazione-organigramma-styled-components";
import {
  Search,
  SearchIcon,
  SearchInputContainer,
  StyledTable,
  StyledTableItem,
  StyledTbody,
  StyledThead,
  TableContainer,
  TableItemText,
  TableScrollableDiv,
  TableTitleText,
  TopContainer,
} from "../cybersecurity-board-contestoleadership-organizzazione-sub/cybersecurity-board-organizzazione-unitaoperative-styled-components";

import { useRecoilState } from "recoil";

import { URL_AssetDetails, URL_AssetList, useApi, useToken } from "../../../../API";
import { SelectedL1ID, SelectedL2ID, SelectedL3ID, SelectedL4ID, reFetchIndicatorAssetsList } from "../../../../recoil";
import {
  StyledCatTd,
  StyledCatTh,
  StyledClassificazioneTd,
  StyledClassificazioneTh,
  StyledDescrizioneAssetTd,
  StyledDescrizioneAssetTh,
  StyledInventarioTd,
  StyledInventarioTh,
  StyledStatoTd,
  StyledStatoTh,
} from "./cybersecurity-board-infrastruttura-elencoasset-styled-components";
import { AssetDeleteConfirmationDialog } from "./ElencoAsset-Add-recoil";
import { AssetSelectedID } from "./ElencoAsset-Edit-recoil";

interface DataItem {
  id: string;
  CategoryPath: string;
  name: string;
  inventoryN: string;
  state: string;
  category: {
    id: string;
    creationts: string;
    updatets: string;
    createdBy: string;
    updatedBy: string;
    name: string;
    sub_category_of: string;
  };
  categoryName: string;
}

type SortableKeys = keyof DataItem;

type ApiContentItem = {
  id: string;
  CategoryPath: string;
  name: string;
  inventoryN: string;
  state: string;
  category: {
    id: string;
    creationts: string;
    updatets: string;
    createdBy: string;
    updatedBy: string;
    name: string;
    sub_category_of: string;
  };
};

type StyledTrProps = {
  selected: boolean;
};

const StyledTr = styled.tr<StyledTrProps>`
  cursor: pointer;
  background-color: ${(props) => {
    return props.selected ? "#f3f7fb" : "#ffffff";
  }};

  &:hover {
    background-color: #e9eff7;
  }
`;

interface OverlayComponentProps {
  isAnimating: boolean;
}

const OverlayComponentAdd1 = styled.div<OverlayComponentProps>`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  z-index: 10;
  transition: opacity 300ms ease-in-out, visibility 300ms ease-in-out;
  opacity: ${(props) => (props.isAnimating ? "1" : "0")};
  visibility: ${(props) => (props.isAnimating ? "visible" : "hidden")};
`;

const useDeletePlace = () => {
  const { del } = useApi();
  const [reFetchIndicator, setRefetchIndicator] = useRecoilState(reFetchIndicatorAssetsList);
  const [isConfirmDialogVisible, setIsConfirmDialogVisible] = useRecoilState(AssetDeleteConfirmationDialog);
  const [L2ID, setL2ID] = useRecoilState(SelectedL2ID);

  const handleDelete = useCallback(async () => {
    if (!L2ID) return;

    const url = URL_AssetDetails;
    try {
      await del(url, L2ID);
      console.log(`Deleted asset with ID: ${L2ID}`);
    } catch (error) {
      console.error("Failed to delete asset:", error);
    } finally {
      setRefetchIndicator((prev) => prev + 1);
      setIsConfirmDialogVisible(false);
    }
  }, [L2ID, del, setRefetchIndicator]);

  const showDeleteConfirmation = useCallback(() => {
    setIsConfirmDialogVisible(true);
  }, []);

  return { handleDelete, showDeleteConfirmation, isConfirmDialogVisible, setIsConfirmDialogVisible };
};

const DataTable: React.FC<{ data: DataItem[] }> = ({ data }) => {
  const [isOverlayVisibleAdd2, setIsOverlayVisibleAdd2] = useState(false);
  const [isOverlayAnimatingAdd2, setIsOverlayAnimatingAdd2] = useState(false);

  const navigate = useNavigate();

  const toggleOverlayAdd2 = () => {
    if (!isOverlayVisibleAdd2) {
      if (window.location.pathname === "/Cybersecurity/Infrastruttura") {
        navigate(`Infrastruttura/CreaStatoAsset/1`);
      } else {
        navigate(`${window.location.pathname}/CreaStatoAsset/1`);
      }

      setIsOverlayVisibleAdd2(true);
      setTimeout(() => setIsOverlayAnimatingAdd2(true), 10);
    } else {
      setIsOverlayAnimatingAdd2(false);
      setTimeout(() => setIsOverlayVisibleAdd2(false), 310);
    }
  };

  const closeOverlayAdd2 = () => {
    navigate(`/Cybersecurity/Infrastruttura/StatoAsset`);
    setIsOverlayAnimatingAdd2(false);
    setIsOverlayAnimatingEdit(false);

    setTimeout(() => {
      setIsOverlayVisibleAdd2(false);
      setIsOverlayVisibleEdit(false);
    }, 300);
  };

  const [isOverlayVisibleEdit, setIsOverlayVisibleEdit] = useState(false);
  const [isOverlayAnimatingEdit, setIsOverlayAnimatingEdit] = useState(false);

  const toggleOverlayEdit = () => {
    if (!isOverlayVisibleEdit) {
      if (window.location.pathname === "/Cybersecurity/Infrastruttura") {
        navigate(`Infrastruttura/StatoAsset/ModificaStatoAsset/1`);
      } else {
        navigate(`${window.location.pathname}/ModificaStatoAsset/1`);
      }

      setIsOverlayVisibleEdit(true);
      setTimeout(() => setIsOverlayAnimatingEdit(true), 10);
    } else {
      setIsOverlayAnimatingEdit(false);
      setTimeout(() => setIsOverlayVisibleEdit(false), 310);
    }
  };

  const closeOverlayEdit = () => {
    navigate(`/Cybersecurity/Infrastruttura/StatoAsset`);
    setIsOverlayAnimatingEdit(false);

    setTimeout(() => {
      setIsOverlayVisibleEdit(false);
    }, 300);
  };

  const [selectedL1, setSelectedL1] = useState<string | null>(null);
  const [selectedL2, setSelectedL2] = useState<string | null>(null);
  const [selectedL3, setSelectedL3] = useState<string | null>(null);
  const [selectedL4, setSelectedL4] = useState<string | null>(null);

  const [L1ID, setL1ID] = useRecoilState(SelectedL1ID);
  const [L2ID, setL2ID] = useRecoilState(SelectedL2ID);
  const [L3ID, setL3ID] = useRecoilState(SelectedL3ID);
  const [L4ID, setL4ID] = useRecoilState(SelectedL4ID);

  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const [isMessageBoxVisible, setIsMessageBoxVisible] = useState(false);

  const [sortConfig, setSortConfig] = useState<{ key: SortableKeys; direction: "ascending" | "descending" } | null>(null);

  const sortedData = useMemo(() => {
    let sortableItems = [...data];
    if (sortConfig !== null) {
      sortableItems.sort((a, b) => {
        const aValue = a[sortConfig.key];
        const bValue = b[sortConfig.key];

        if (typeof aValue === "string" && typeof bValue === "string") {
          return sortConfig.direction === "ascending" ? aValue.localeCompare(bValue) : bValue.localeCompare(aValue);
        }

        return 0;
      });
    }
    return sortableItems;
  }, [data, sortConfig]);

  const requestSort = (key: SortableKeys) => {
    let direction: "ascending" | "descending" = "ascending";
    if (sortConfig && sortConfig.key === key && sortConfig.direction === "ascending") {
      direction = "descending";
    }
    setSortConfig({ key, direction });
  };

  const [selectedId, setSelectedId] = useRecoilState(AssetSelectedID);

  const handleRowSelect = (id: string) => {
    setSelectedId(id);

    setL2ID(id);
  };

  useEffect(() => {
    console.log("Asset ID now has the value:", selectedId);
  }, [selectedId]);

  const [globalFilterText, setGlobalFilterText] = useState("");

  const handleGlobalFilterChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setGlobalFilterText(event.target.value);
  };

  const [filterText, setFilterText] = useState("");

  const handleFilterChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setFilterText(event.target.value);
  };

  const filteredAndSortedData = useMemo(() => {
    const filteredData = data
      .filter((item) => {
        return item.CategoryPath.toLowerCase().includes(filterText.toLowerCase());
      })
      .filter((item) => {
        if (globalFilterText) {
          const allText = Object.values(item).join(" ").toLowerCase();
          return allText.includes(globalFilterText.toLowerCase());
        }
        return true;
      });

    if (sortConfig !== null) {
      filteredData.sort((a, b) => {
        const aValue = a[sortConfig.key];
        const bValue = b[sortConfig.key];

        if (typeof aValue === "string" && typeof bValue === "string") {
          return sortConfig.direction === "ascending" ? aValue.localeCompare(bValue) : bValue.localeCompare(aValue);
        }
        return 0;
      });
    }

    return filteredData;
  }, [data, sortConfig, filterText, globalFilterText]);

  type StateDisplay = {
    text: string;
    color: string;
  };

  const stateDisplayMapping: Record<string, StateDisplay> = {
    "asset.status.in_use": { text: "In uso", color: "#00c890" },
    "asset.status.eliminated": { text: "Ritirato", color: "#707070" },
    "asset.status.in_storage": { text: "In magazzino", color: "#1f78ff" },
    "asset.status.archived": { text: "Archiviato", color: "#ca7300" },

    "asset.status.in_transit": { text: "In transito", color: "#e6bb00" },
    "asset.status.missing": { text: "Mancante", color: "#970019" },
  };

  const ColoredText = styled.span`
    color: ${(props) => props.color};
    font-family: "Titillium Web";
    font-size: 12px;
    font-weight: bold;
  `;

  interface StateTextProps {
    state: string;
  }

  const StateText: React.FC<StateTextProps> = ({ state }) => {
    const display = stateDisplayMapping[state] || { text: state, color: "black" };
    return <ColoredText color={display.color}>{display.text}</ColoredText>;
  };

  const { handleDelete, showDeleteConfirmation, isConfirmDialogVisible, setIsConfirmDialogVisible } = useDeletePlace();

  return (
    <TableContainer>
      <TopContainer>
        <SearchInputContainer>
          <Search placeholder="Filtra per categoria" onChange={handleFilterChange} />
          <SearchIcon src="/funnel.svg" />
        </SearchInputContainer>
        <SearchInputContainer>
          <Search placeholder="Cerca in tutta la tabella" onChange={handleGlobalFilterChange} />
          <SearchIcon src="/search.svg" />
        </SearchInputContainer>

        <IconsGroup>
          {/* <IconButton>
            <BackgroundIcon loading="eager" alt="" src="/doc.svg" />
          </IconButton> */}
          <IconButton onClick={() => toggleOverlayEdit()}>
            <BackgroundIcon loading="eager" alt="" src="/edit.svg" />
          </IconButton>
          <IconButton onClick={() => showDeleteConfirmation()}>
            <BackgroundIcon loading="eager" alt="" src="/delete.svg" />
          </IconButton>
          <IconButton>
            <BackgroundIcon loading="eager" alt="" src="/add.svg" onClick={toggleOverlayAdd2} />
          </IconButton>
        </IconsGroup>
      </TopContainer>

      <StyledTable>
        <StyledThead>
          <StyledCatTh onClick={() => requestSort("categoryName")}>
            <TableTitleText>▼ Categoria</TableTitleText>
          </StyledCatTh>
          <StyledClassificazioneTh onClick={() => requestSort("CategoryPath")}>
            <TableTitleText>▼ Classificazione</TableTitleText>
          </StyledClassificazioneTh>
          <StyledDescrizioneAssetTh onClick={() => requestSort("name")}>
            <TableTitleText>▼ Descrizione asset</TableTitleText>
          </StyledDescrizioneAssetTh>
          <StyledInventarioTh onClick={() => requestSort("inventoryN")}>
            <TableTitleText>▼ Inventario n.</TableTitleText>
          </StyledInventarioTh>
          <StyledStatoTh onClick={() => requestSort("state")}>
            <TableTitleText>▼ Stato</TableTitleText>
          </StyledStatoTh>
        </StyledThead>
      </StyledTable>
      <TableScrollableDiv>
        <StyledTableItem>
          <StyledTbody>
            {filteredAndSortedData.map((item) => (
              <StyledTr key={item.id} onClick={() => handleRowSelect(item.id)} selected={selectedId === item.id}>
                {" "}
                <StyledCatTd title={item.categoryName}>
                  <TableItemText>{item.categoryName}</TableItemText>
                </StyledCatTd>
                <StyledClassificazioneTd title={item.CategoryPath}>
                  <TableItemText>{item.CategoryPath}</TableItemText>
                </StyledClassificazioneTd>
                <StyledDescrizioneAssetTd title={item.name}>
                  <TableItemText>{item.name}</TableItemText>
                </StyledDescrizioneAssetTd>
                <StyledInventarioTd title={item.inventoryN}>
                  <TableItemText>{item.inventoryN}</TableItemText>
                </StyledInventarioTd>
                <StyledStatoTd title={item.state}>
                  {" "}
                  <StateText state={item.state} />
                </StyledStatoTd>
              </StyledTr>
            ))}
          </StyledTbody>
        </StyledTableItem>
      </TableScrollableDiv>

      <OverlayContext.Provider value={{ closeOverlay: closeOverlayAdd2, selectedL1, setSelectedL1, selectedL2, setSelectedL2, selectedL3, setSelectedL3, selectedL4, setSelectedL4 }}>
        {isOverlayVisibleAdd2 && (
          <OverlayComponentAdd1 isAnimating={isOverlayAnimatingAdd2}>
            {/* <ButtonClose src="/close.svg" onClick={closeOverlayAdd2} /> */}
            <FadeOutComponent>
              <FrameTopGradient100></FrameTopGradient100>
            </FadeOutComponent>

            <Outlet />
          </OverlayComponentAdd1>
        )}

        {isOverlayVisibleEdit && (
          <OverlayComponentAdd1 isAnimating={isOverlayAnimatingEdit}>
            {/* <ButtonClose src="/close.svg" onClick={closeOverlayAdd2} /> */}
            <FadeOutComponent>
              <FrameTopGradient100></FrameTopGradient100>
            </FadeOutComponent>

            <Outlet />
          </OverlayComponentAdd1>
        )}
      </OverlayContext.Provider>
    </TableContainer>
  );
};

const Board27001Dashboard: React.FC = () => {
  const [L1ID, setL1ID] = useRecoilState(SelectedL1ID);
  const [L2ID, setL2ID] = useRecoilState(SelectedL2ID);
  const [L3ID, setL3ID] = useRecoilState(SelectedL3ID);
  const [L4ID, setL4ID] = useRecoilState(SelectedL4ID);

  const [data, setData] = useState<DataItem[]>([]);
  const [selectedL1, setSelectedL1] = useState<string | null>(null);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const [isMessageBoxVisible, setIsMessageBoxVisible] = useState(false);
  const { handleDelete, showDeleteConfirmation, isConfirmDialogVisible, setIsConfirmDialogVisible } = useDeletePlace();
  const [reFetchIndicator, setRefetchIndicator] = useRecoilState(reFetchIndicatorAssetsList);

  const token = useToken();

  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true);
      try {
        if (!token) throw new Error("User is not authenticated");

        const infoResponse = await fetch(`${URL_AssetList}/info`, {
          headers: { Authorization: `Bearer ${token}` },
        });

        if (!infoResponse.ok) {
          throw new Error(`API request for total failed: ${infoResponse.statusText}`);
        }

        const infoData = await infoResponse.json();
        const total = infoData.total;

        const dataResponse = await fetch(`${URL_AssetList}?size=${total}`, {
          headers: { Authorization: `Bearer ${token}` },
        });

        if (!dataResponse.ok) {
          throw new Error(`API request for data failed: ${dataResponse.statusText}`);
        }

        const responseData = await dataResponse.json();

        if (Array.isArray(responseData.content)) {
          const transformedData = responseData.content.map((item: ApiContentItem) => ({
            id: item.id,
            CategoryPath: item.CategoryPath,
            name: item.name,
            inventoryN: item.inventoryN,
            state: item.state,
            categoryName: item.category?.name,
          }));

          setData(transformedData);
        } else {
          throw new Error("Expected an array of items in content");
        }
      } catch (err) {
        setError(err instanceof Error ? err.message : "An unknown error occurred");
        setIsMessageBoxVisible(true);
      } finally {
        setIsLoading(false);
      }
    };

    fetchData();
  }, [token, reFetchIndicator]);

  return (
    <div>
      {isMessageBoxVisible && (
        <Backdrop>
          <MessageContainer>
            <p>
              <MessageboxText>{error}</MessageboxText>
            </p>
            <CloseMessageButton onClick={() => setIsMessageBoxVisible(false)}>
              <MessageboxText>Close</MessageboxText>
            </CloseMessageButton>
          </MessageContainer>
        </Backdrop>
      )}

      {isLoading && (
        <BackdropTransparent>
          <MessageContainer>
            <MessageboxText>Loading...</MessageboxText>
          </MessageContainer>
        </BackdropTransparent>
      )}

      {error && (
        <Backdrop>
          <MessageContainer>
            <p>
              <MessageboxText>{error}</MessageboxText>
            </p>
            <CloseMessageButton
              onClick={() => {
                setIsMessageBoxVisible(false);
                setError(null);
              }}
            >
              <MessageboxText>Close</MessageboxText>
            </CloseMessageButton>
          </MessageContainer>
        </Backdrop>
      )}

      {isConfirmDialogVisible && (
        <Backdrop>
          <MessageContainer>
            <p>
              <MessageboxText>Sei sicuro di voler rimuovere questo asset?</MessageboxText>
            </p>
            <div style={{ display: "flex", justifyContent: "center", gap: "20px" }}>
              <CloseMessageButton onClick={() => setIsConfirmDialogVisible(false)}>
                <MessageboxText>No</MessageboxText>
              </CloseMessageButton>
              <CloseMessageButton onClick={handleDelete}>
                <MessageboxText>Rimuovi</MessageboxText>
              </CloseMessageButton>
            </div>
          </MessageContainer>
        </Backdrop>
      )}

      <DataTable data={data} />
    </div>
  );
};

export default Board27001Dashboard;
