import React, { useState } from "react";
import { useRecoilState } from "recoil";
import { useToken } from "../../../../API";
import {
  LuoghiAttachedFilesInfo,
  LuoghiComplianceDate,
  LuoghiDocumentsAttached,
  LuoghiDocumentsPath,
  LuoghiEmail,
  LuoghiFax,
  LuoghiID,
  LuoghiIdentificativo,
  LuoghiIndirizzo,
  LuoghiName,
  LuoghiNomeAbbreviato,
  LuoghiTelefono,
} from "../cybersecurity-board-contestoleadership-infrastruttura-sub/Luoghi-Add-recoil";
import { GAP_notes_done, GAP_notes_todo } from "../gap-recoil";
import { SelectedL4ID } from "./../../../../recoil";
import {
  CreaContainerColumn,
  DescriptionContainerCreaMultiple,
  DescriptionContainerCreaText,
  FlexContainerCrea,
  IconSquare,
  InputContainer,
  InputDescriptionTextVerticalFull,
  InputTextboxVerticalFull,
  OverlayComponentWhiteAdd1Multiple
} from "./../cybersecurity-board-contestoleadership-organizzazione-sub/cybersecurity-board-organizzazione-organigramma-styled-components";
import { TAB_DESCRIPTION_CONTROLLI_3, TAB_DESCRIPTION_GLOBAL } from "./../tabs-description";

type PersonItem = {
  id: string;

  surname: string;
  name: string;
  has_personal_card: string;
  member_of_formal_organization: string;
  reports_to: string;
  has_smart_working_site: string;
  documents_folder_link: string;
  based_at: string;

  national_identification_number: string;
  date_of_birth: string;
  has_place_of_birth: string;
  has_primary_residence: string;
  has_postal_address: string;
  personal_email: string;
  work_email: string;
  work_mobile_phone: string;
  personal_mobile_phone: string;
  has_identity_document: string;
  has_citizenship: string;
};

type PersonApiContentItem = {
  id: string;
  creationTs: string;
  updateTs: string;
  createdBy: string;
  updatedBy: string;
  surname: string;
  name: string;
  has_personal_card: string;
  member_of_formal_organization: string;
  reports_to: string;
  has_smart_working_site: string;
  documents_folder_link: string;
  based_at: string;
  personal_card: {
    id: string;
    creationTs: string;
    updateTs: string;
    createdBy: string;
    updatedBy: string;
    national_identification_number: string;
    date_of_birth: string;
    has_place_of_birth: string;
    has_primary_residence: string;
    has_postal_address: string;
    personal_email: string;
    work_email: string;
    work_mobile_phone: string;
    personal_mobile_phone: string;
    has_identity_document: string;
    has_citizenship: string;
  };
};

const MainComponent = () => {
  const token = useToken();

  const [data, setData] = useState<PersonItem[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const [isMessageBoxVisible, setIsMessageBoxVisible] = useState(false);
  const [message, setMessage] = useState("");

  const [L4ID, setL4ID] = useRecoilState(SelectedL4ID);

  const [attachedFilesInfo, setAttachedFilesInfo] = useRecoilState(LuoghiAttachedFilesInfo);
  const [documentsAttached, setDocumentsAttached] = useRecoilState(LuoghiDocumentsAttached);
  const [docPath, setDocPath] = useRecoilState(LuoghiDocumentsPath);
  const [email, setEmail] = useRecoilState(LuoghiEmail);
  const [fax, setFax] = useRecoilState(LuoghiFax);
  const [ID, setID] = useRecoilState(LuoghiID);
  const [identificativo, setIdentificativo] = useRecoilState(LuoghiIdentificativo);
  const [indirizzo, setIndirizzo] = useRecoilState(LuoghiIndirizzo);
  const [name, setName] = useRecoilState(LuoghiName);
  const [nomeAbbreviato, setNomeAbbreviato] = useRecoilState(LuoghiNomeAbbreviato);
  const [telefono, setTelefono] = useRecoilState(LuoghiTelefono);
  const [complianceDate, setComplianceDate] = useRecoilState(LuoghiComplianceDate);
  const [gapNoteToDo, setGapNoteToDo] = useRecoilState(GAP_notes_todo);
  const [gapNoteDone, setGapNoteDone] = useRecoilState(GAP_notes_done);
  return (
    <OverlayComponentWhiteAdd1Multiple>
      <FlexContainerCrea>
        <CreaContainerColumn>
          <InputContainer>
            <InputDescriptionTextVerticalFull>
              Adempimenti compiuti
              {/* <Tooltip title="Campo obbligatorio">
                <InfoIcon
                  style={{
                    fontSize: "14px",
                    color: "#04446c",
                    cursor: "pointer",
                    marginLeft: "2px",
                  }}
                />
              </Tooltip> */}
            </InputDescriptionTextVerticalFull>
            <InputTextboxVerticalFull placeholder="" value={gapNoteDone} onChange={(e) => setGapNoteDone(e.target.value)} />
            {/* <IconContainer /> */}
          </InputContainer>
        </CreaContainerColumn>

        <CreaContainerColumn>
          <InputContainer>
            <InputDescriptionTextVerticalFull>
              Adempimenti da compiere
              {/* <Tooltip title="Campo obbligatorio">
                <InfoIcon style={{ fontSize: "14px", color: "#04446c", cursor: "pointer", marginLeft:"2px" }} />
              </Tooltip> */}
            </InputDescriptionTextVerticalFull>
            <InputTextboxVerticalFull placeholder="" value={gapNoteToDo} onChange={(e) => setGapNoteToDo(e.target.value)} />
            {/* <IconContainer /> */}
          </InputContainer>

          {/* <InputContainer>
            <InputDescriptionText>Fax</InputDescriptionText>
            <InputTextbox
              placeholder=""
              value={fax}
              onChange={(e) => setFax(e.target.value)}
            />
            <IconContainer />
          </InputContainer> */}
        </CreaContainerColumn>
      </FlexContainerCrea>
      <DescriptionContainerCreaMultiple>
        <IconSquare src="/crea-L1-schedagenerale.svg" alt="Description Icon" />
        <DescriptionContainerCreaText>{TAB_DESCRIPTION_CONTROLLI_3 ? TAB_DESCRIPTION_CONTROLLI_3 : TAB_DESCRIPTION_GLOBAL}</DescriptionContainerCreaText>
      </DescriptionContainerCreaMultiple>
    </OverlayComponentWhiteAdd1Multiple>
  );
};

export default MainComponent;
