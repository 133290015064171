import styled from "styled-components";
import { NavLink } from "react-router-dom";

export const TableScrollableDiv = styled.div`
  overflow-y: auto;
  width: 98.2%;
  margin-left: 10px;
  margin-right: auto;
  max-height: calc(85vh - 52px - 50px);
  border: 1px solid #e1e1e1;

  &::-webkit-scrollbar {
    width: 0px;
  }

  &::-webkit-scrollbar-track {
    background: #ffffff;
    border-radius: 10px;
  }

  &::-webkit-scrollbar-thumb {
    background: #f1f1f1;
    border-radius: 10px;
    &:hover {
      background: #425a77;
    }
  }
`;

export const TableScrollableDivPopup = styled.div`
  overflow-y: auto;
  width: 98.2%;
  margin-left: 10px;
  margin-right: auto;
  max-height: calc(78vh - 52px - 50px);
  border: 1px solid #e1e1e1;

  &::-webkit-scrollbar {
    width: 0px;
  }

  &::-webkit-scrollbar-track {
    background: #ffffff;
    border-radius: 10px;
  }

  &::-webkit-scrollbar-thumb {
    background: #f1f1f1;
    border-radius: 10px;
    &:hover {
      background: #425a77;
    }
  }
`;

export const TableScrollableDivCampoApplicazione = styled.div`
  overflow-y: auto;
  width: 100%;
  margin-left: 0px;
  margin-right: auto;
  max-height: calc(73vh - 52px - 50px);
  border: 1px solid #e1e1e1;

  &::-webkit-scrollbar {
    width: 0px;
  }

  &::-webkit-scrollbar-track {
    background: #ffffff;
    border-radius: 10px;
  }

  &::-webkit-scrollbar-thumb {
    background: #f1f1f1;
    border-radius: 10px;
    &:hover {
      background: #425a77;
    }
  }
`;

export const StyledTable = styled.table`
  table-layout: fixed;
  width: 98.2%;
  margin-left: 10px;
  margin-right: auto;
  border-collapse: collapse;
`;

export const StyledTableCampoApplicazione = styled.table`
  table-layout: fixed;
  width: 100%;
  margin-left: 0px;
  margin-right: auto;
  border-collapse: collapse;
`;

export const StyledTableItem = styled.table`
  flex: 1;
  table-layout: fixed;
  width: 100%;
  margin-left: auto;
  margin-right: auto;
  border-collapse: collapse;
`;
export const TableTitleText = styled.div`
  user-select: none;
  font-size: var(--font-size-micro);
  font-family: var(--font-titillium-web);
  color: #ffffff;
  text-align: left;

  &:hover {
    cursor: pointer;
  }
`;

export const TableItemText = styled.div`
  white-space: nowrap; /* Keep the text on a single line */
  overflow: hidden; /* Hide the overflow */
  text-overflow: ellipsis; /* Use ellipsis for overflow */

  font-size: var(--font-size-micro);
  font-family: var(--font-titillium-web);
  color: var(--color-steelblue-700);
  text-align: left;
`;

export const StyledThead = styled.thead`
  background-color: #425a77;
  color: white;
`;

export const StyledTh = styled.th`
  padding: 12px 15px;
  text-align: left;
`;

export const StyledTh10 = styled.th`
  padding: 12px 15px;
  text-align: left;
  width: 10%;
`;

export const StyledThComplianceRole = styled.th`
  padding: 12px 15px;
  text-align: left;
  width: 35%;
`;

export const StyledThRifNorm = styled.th`
  padding: 12px 15px;
  text-align: left;
  width: 15%;
`;

export const StyledGestioneTh = styled.th`
  padding: 12px 15px;
  text-align: left;
  width: 25%;
`;

export const StyledTd = styled.td`
  padding: 12px 15px;
  border-bottom: 1px solid #dddddd;
`;

export const StyledTd10 = styled.td`
  padding: 12px 15px;
  border-bottom: 1px solid #dddddd;
  width: 10%;
`;

export const StyledTdCentered = styled.td`
  padding: 12px 15px;
  border-bottom: 1px solid #dddddd;
  width: 10%;
  text-align: center;
`;

export const StyledTdComplianceRole = styled.td`
  padding: 12px 15px;
  border-bottom: 1px solid #dddddd;
  width: 35%;
`;

export const StyledTdRifNorm = styled.td`
  padding: 12px 15px;
  border-bottom: 1px solid #dddddd;
  width: 15%;
`;

export const StyledTdCategory = styled.td`
  padding: 12px 15px;
  border-bottom: 1px solid #dddddd;
  width: 18%;
`;

export const StyledTdGestione = styled.td`
  padding: 12px 15px;
  border-bottom: 1px solid #dddddd;
  width: 25%;
`;

export const StyledTdUnitaOperativa = styled.td`
  padding: 12px 15px;
  border-bottom: 1px solid #dddddd;
  width: 30%;
`;

export const StyledPlaceTd = styled.td`
  padding: 12px 15px;
  border-bottom: 1px solid #dddddd;
  width: 20%;
`;

export const StyledLuoghiTd = styled.td`
  padding: 12px 15px;
  border-bottom: 1px solid #dddddd;
  width: 35%;
`;

export const StyledComponenteTd = styled.td`
  padding: 12px 15px;
  border-bottom: 1px solid #dddddd;
  width: 50%;
`;

export const StyledTdCheckbox = styled.td`
  padding: 12px 15px;
  border-bottom: 1px solid #dddddd;
  width: 5%;
`;

export const StyledActionTh = styled(StyledTh)`
  width: 5%;
`;

export const StyledRuoligeneraliTh = styled(StyledTh)`
  width: 20%;
`;

export const StyledCategoryTh = styled(StyledTh)`
  width: 18%;
`;
export const StyledLuoghiTh = styled(StyledTh)`
  width: 35%;
`;
export const StyledComponenteTh = styled(StyledTh)`
  width: 50%;
`;

export const StyledUnitaOperativaTh = styled(StyledTh)`
  width: 30%;
`;

export const StyledPlanRefTh = styled(StyledTh)`
  width: 15%;
`;

export const StyledPersone1Th = styled(StyledTh)`
  width: 20%;
`;

export const StyledPersone2Th = styled(StyledTh)`
  width: 35%;
`;

export const StyledActionTd = styled(StyledTd)`
  width: 10%;
`;

export const StyledRuoliGeneraliTd = styled(StyledTd)`
  width: 20%;
`;

export const StyledPlanRefTd = styled(StyledTd)`
  width: 15%;
`;

export const StyledPersone1Td = styled(StyledTd)`
  width: 20%;
`;
export const StyledPersone2Td = styled(StyledTd)`
  width: 35%;
`;

export const StyledTbody = styled.tbody``;

export const TableContainer = styled.div`
  width: 100%;
  max-height: 85vh;
  overflow: hidden;
`;

export const TableContainerPopup = styled.div`
  width: 100%;
  padding-right: 0.1%;
  max-height: 78vh;
  overflow: hidden;
`;

export const TableContainerCampoApplicazione = styled.div`
  width: 100%;
  max-height: 73vh;
  overflow: hidden;
`;

export const TopContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 98.2%;
  margin-left: 10px;
  margin-right: auto;
  height: 52px;
  gap: 20px;
  padding: 0 0px;
  box-sizing: border-box;
`;

export const TopContainerRoles = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 0px;
  width: 100%;
  margin-left: 0px;
  margin-right: auto;
  height: 30x;
  gap: 10px;
  padding: 0 0px;
  box-sizing: border-box;
`;

export const TopContainerCampoApplicazione = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  margin-left: 0px;
  margin-right: auto;
  height: 52px;
  gap: 20px;
  padding: 0 0px;
  box-sizing: border-box;
`;

export const Search = styled.input`
  border: 1px solid #e1e1e1;
  overflow: hidden;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  padding: 0px 0px 0px 30px;
  margin-right: 0px;
  width: 100%;
  height: 30px;

  font-family: var(--font-titillium-web);
  font-size: var(--font-size-micro);
  line-height: 1.5;
  color: var(--color-lightslategray);

  outline: none;
  box-shadow: none;

  &::placeholder {
    color: #d1d1d1;
  }

  &:focus {
    border-color: var(--color-primary);
  }
`;

export const SearchPopup = styled.input`
  border: 1px solid #e1e1e1;
  overflow: hidden;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  padding: 0px 0px 0px 30px;
  margin-right: 0px;
  width: 100%;
  height: 30px;

  font-family: var(--font-titillium-web);
  font-size: var(--font-size-micro);
  line-height: 1.5;
  color: var(--color-lightslategray);

  outline: none;
  box-shadow: none;

  &::placeholder {
    color: #d1d1d1;
  }

  &:focus {
    border-color: var(--color-primary);
  }
`;

export const SearchCampoApplicazione = styled.input`
  border: 1px solid #e1e1e1;
  overflow: hidden;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  padding: 0px 0px 0px 30px;
  margin-right: 0px;
  width: 100%;
  height: 30px;

  font-family: var(--font-titillium-web);
  font-size: var(--font-size-micro);
  line-height: 1.5;
  color: var(--color-lightslategray);

  outline: none;
  box-shadow: none;

  &::placeholder {
    color: #d1d1d1;
  }

  &:focus {
    border-color: var(--color-primary);
  }
`;

export const SearchIcon = styled.img`
  position: absolute;
  left: 10px;
  top: 50%;
  transform: translateY(-50%);
  width: 16px;
  height: 16px;
  pointer-events: none;
`;

export const SearchInputContainer = styled.div`
  position: relative;
  width: 50%;
  margin-right: 0px;
`;

export const SearchInputContainerSolo = styled.div`
  position: relative;
  width: 100%;
  margin-right: 0px;
`;

export const SearchInputContainerSoloRoles = styled.div`
  position: relative;
  width: 100%;
  margin-right: 0px;
`;

export const CheckBoxScrollableDiv = styled.div`
  position: absolute;
  overflow-x: hidden;
  overflow-y: scroll;
  height: 98%;
  width: 100%;

  &::-webkit-scrollbar {
    width: 3px;
  }

  &::-webkit-scrollbar-track {
    background: #ffffff;
    border-radius: 10px;
  }

  &::-webkit-scrollbar-thumb {
    background: #c6c6c6;
    border-radius: 10px;
    &:hover {
      background: #425a77;
    }
  }
`;
