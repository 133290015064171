import dayjs, { Dayjs } from "dayjs";
import React, { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { OverlayContext } from "../cybersecurity-board-contestoleadership-organizzazione";

import { useRecoilState } from "recoil";

import { SelectedL1ID, isLoadingRec, isMessageBoxVisibleClosingRec, isMessageBoxVisibleRec, setErrorRec } from "../../../../recoil";

import {
  addL1AttachedFilesInfo,
  addL1ComplianceDate,
  addL1ComplianceDirettoreCompliance,
  addL1ComplianceDirettoreComplianceID,
  addL1DocumentsAttached,
  addL1DocumentsPath,
  addL1OrganizzazioneDirettore,
  addL1OrganizzazioneDirettoreID,
  addL1SchedaGeneraleFax,
  addL1SchedaGeneraleIndirizzo,
  addL1SchedaGeneraleNomeAbbreviato,
  addL1SchedaGeneraleNomeCompleto,
  addL1SchedaGeneralePEC,
  addL1SchedaGeneraleTelefono,
} from "../cybersecurity-board-contestoleadership-organizzazione-sub/L1-Add-Recoil";

import SchedaGenerale from "./cybersecurity-board-org-org-L1-Show-sub/organigramma-L1-Show-1-SchedaGenerale";
import Organizzazione from "./cybersecurity-board-org-org-L1-Show-sub/organigramma-L1-Show-2-Organizzazione";

import Compliance from "./cybersecurity-board-org-org-L1-Show-sub/organigramma-L1-Show-6-GestioneCompliance";
import Documentazione, { useInitiateUploadL1 } from "./cybersecurity-board-org-org-L1-Show-sub/organigramma-L1-Show-7-Documentazione";

import { ButtonGrey, ButtonText } from "../../styled-components";

import { URL_DirectorList, URL_LV1, URL_LV1_Detailed, useApi, useToken } from "../../../../API";
import {
  Backdrop,
  BackdropTransparent,
  CloseMessageButton,
  CreaSubMenuButtonMultiple,
  FrameTopGradient100,
  MessageContainer,
  MessageboxText,
  OverlayComponentBackgroundAdd1,
  OverlayComponentWhiteAdd1,
  OverlayComponentWhiteButtonFrameAdd1,
  OverlayComponentWhiteTitleAdd1,
  SubMenuButtonContainerOrganigrammaMultipleShort,
} from "./cybersecurity-board-organizzazione-organigramma-styled-components";

const YourAddL1Component = () => {
  const { closeOverlay } = useContext(OverlayContext);
  const [L1ID, setL1ID] = useRecoilState(SelectedL1ID);
  const navigate = useNavigate();

  const token = useToken();
  const [data, setData] = useState<DirectorDataItem[]>([]);
  const [dataLV1, setDataLV1] = useState<LV1DataItem>();
  const [isLoading, setIsLoading] = useRecoilState(isLoadingRec);
  const [error, setError] = useRecoilState(setErrorRec);
  const [isMessageBoxVisible, setIsMessageBoxVisible] = useRecoilState(isMessageBoxVisibleRec);
  const [isMessageBoxVisibleClosing, setIsMessageBoxVisibleClosing] = useRecoilState(isMessageBoxVisibleClosingRec);

  const [message, setMessage] = useState("");

  const { post } = useApi();

  const [directorLV1, setDirectorLV1] = useRecoilState(addL1OrganizzazioneDirettore);
  const [directorIdLV1, setDirectorIdLV1] = useRecoilState(addL1OrganizzazioneDirettoreID);
  const [directorComplianceLV1, setDirectorComplianceLV1] = useRecoilState(addL1ComplianceDirettoreCompliance);
  const [directorComplianceIdLV1, setDirectorComplianceIdLV1] = useRecoilState(addL1ComplianceDirettoreComplianceID);
  const [complianceDateLV1, setComplianceDateLV1] = useRecoilState(addL1ComplianceDate);
  const [FaxLV1, setFaxLV1] = useRecoilState(addL1SchedaGeneraleFax);
  const [IndirizzoLV1, setIndirizzoLV1] = useRecoilState(addL1SchedaGeneraleIndirizzo);
  const [NomeAbbreviatoLV1, setNomeAbbreviatoLV1] = useRecoilState(addL1SchedaGeneraleNomeAbbreviato);
  const [NomeCompletoLV1, setNomeCompletoLV1] = useRecoilState(addL1SchedaGeneraleNomeCompleto);
  const [PECLV1, setPECLV1] = useRecoilState(addL1SchedaGeneralePEC);
  const [TelefonoLV1, setTelefonoLV1] = useRecoilState(addL1SchedaGeneraleTelefono);

  const [AttachedFileInfoLV1, setAttachedFileInfoLV1] = useRecoilState(addL1AttachedFilesInfo);
  const [DocumentsAttachedLV1, setDocumentsAttachedLV1] = useRecoilState(addL1DocumentsAttached);
  const [DocumentsPathLV1, setDocumentsPathLV1] = useRecoilState(addL1DocumentsPath);

  const [docPath, setDocPath] = useRecoilState(addL1DocumentsPath);

  const initiateUpload = useInitiateUploadL1();

  const handleSave = async () => {
    if (!NomeCompletoLV1 || !NomeAbbreviatoLV1) {
      setMessage("Si prega di compilare tutti i campi obbligatori: Nome completo e Nome abbreviato");
      setIsMessageBoxVisibleClosing(true);
      return;
    }

    const newSiteData = {
      site_name: NomeCompletoLV1,
      site_short_name: NomeAbbreviatoLV1,
      fax: FaxLV1,
      site_telephone: TelefonoLV1,
      has_site_responsible: directorIdLV1,
      address: {
        country_name: "IT",
        region: "Lazio",
        locality: "Roma",
        postal_code: "00162",
        street_address: IndirizzoLV1,
        building_number: "",
        apartment_number: "",
      },
      site_email: PECLV1,
      documents_folder_link: DocumentsPathLV1,
      has_compliance_responsible: directorComplianceIdLV1,
      compliance_responsible_assignment_start: complianceDateLV1,
    };

    console.log(JSON.stringify(newSiteData));

    try {
      setIsLoading(true);

      const responseData = await post(URL_LV1, newSiteData);
      await initiateUpload();
      console.log("Site created successfully:", responseData);
      setMessage("Site created successfully!");
      setIsLoading(false);
      setIsMessageBoxVisible(true);

      setDirectorComplianceIdLV1("");
      setDirectorComplianceLV1("");
      setDirectorIdLV1("");
      setDirectorLV1("");
      setComplianceDateLV1(dayjs());
      setFaxLV1("");
      setPECLV1("");
      setTelefonoLV1("");
      setIndirizzoLV1("");
      setNomeCompletoLV1("");
      setNomeAbbreviatoLV1("");
      setDocumentsAttachedLV1([]);
      setAttachedFileInfoLV1([]);
      setDocumentsPathLV1("");
    } catch (error) {
      console.error("Error creating site:", error);
      setMessage(`Error creating site: ${error instanceof Error ? error.message : "Unknown error"}`);
      setIsLoading(false);
      setIsMessageBoxVisible(true);
    }
  };

  type LV1ApiContentItem = {
    site_name: string;
    site_short_name: string;
    has_site_address: string;
    site_email: string;
    site_telephone: string;
    has_site_responsible: string;
    fax: string;
    has_compliance_responsible: string;
    compliance_responsible_assignment_start: string;
    site_responsible: {
      name?: string;
      surname: string;
    };
    compliance_responsible: {
      name?: string;
      surname: string;
    };
  };

  type LV1DataItem = {
    site_name: string;
    site_short_name: string;
    has_site_address: string;
    site_email: string;
    site_telephone: string;
    has_site_responsible: string;
    fax: string;
    has_compliance_responsible: string;
    compliance_responsible_assignment_start: Dayjs;
    compliance_responsible_fullname: string;
    responsible_fullname: string;
    has_documents_folder_link: string;
  };

  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true);
      try {
        if (!token) throw new Error("User is not authenticated");

        const response = await fetch(`${URL_LV1_Detailed}/${L1ID}`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });

        if (!response.ok) {
          throw new Error(`API request failed: ${response.statusText}`);
        }

        const responseData = await response.json();
        console.log("Response Data:", responseData);

        const transformedData: LV1DataItem = {
          site_name: responseData.site_name,
          site_short_name: responseData.site_short_name,
          has_site_address: responseData.address?.street_address,
          site_email: responseData.site_email,
          site_telephone: responseData.site_telephone,
          has_site_responsible: responseData.has_site_responsible,
          fax: responseData.fax,
          has_documents_folder_link: responseData.has_documents_folder_link,
          has_compliance_responsible: responseData.has_compliance_responsible,
          compliance_responsible_assignment_start: dayjs(responseData.compliance_responsible_assignment_start),
          compliance_responsible_fullname: responseData.compliance_responsible?.name + " " + responseData.compliance_responsible?.surname,
          responsible_fullname: responseData.site_responsible?.name + " " + responseData.site_responsible?.surname,
        };

        console.log("Transformed Data:", transformedData);
        setDataLV1(transformedData);
        setNomeCompletoLV1(transformedData.site_name);
        setNomeAbbreviatoLV1(transformedData.site_short_name);
        setIndirizzoLV1(transformedData.has_site_address);
        setPECLV1(transformedData.site_email);
        setFaxLV1(transformedData.fax);
        setDocPath(transformedData.has_documents_folder_link);
        setDirectorLV1(transformedData.responsible_fullname);
        setDirectorComplianceLV1(transformedData.compliance_responsible_fullname);
        setComplianceDateLV1(transformedData.compliance_responsible_assignment_start);
      } catch (err) {
        setError(err instanceof Error ? err.message : "An unknown error occurred");
        setIsMessageBoxVisible(true);
      } finally {
        setIsLoading(false);
      }
    };

    fetchData();
  }, [token]);

  type DirectorApiContentItem = {
    id: string;
    alias: string;
  };

  type DirectorDataItem = {
    director_id: string;
    director_name: string;
  };

  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true);
      try {
        if (!token) throw new Error("User is not authenticated");

        const response = await fetch(URL_DirectorList, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });

        if (!response.ok) {
          throw new Error(`API request failed: ${response.statusText}`);
        }

        const responseData = await response.json();
        console.log("Response Data:", responseData);

        const transformedData: DirectorDataItem[] = responseData.map((item: DirectorApiContentItem) => {
          return {
            director_id: item.id,
            director_name: item.alias,
          };
        });

        console.log("Transformed Data:", transformedData);
        setData(transformedData);
      } catch (err) {
        setError(err instanceof Error ? err.message : "An unknown error occurred");
        setIsMessageBoxVisible(true);
      } finally {
        setIsLoading(false);
      }
    };

    fetchData();
  }, [token]);

  const handleCloseAndNavigate = () => {
    setIsMessageBoxVisible(false);
    closeOverlay();
  };

  const handleClose = () => {
    setIsMessageBoxVisibleClosing(false);
  };

  const handleCloseRecoil = () => {
    closeOverlay();
    setDirectorComplianceIdLV1("");
    setDirectorComplianceLV1("");
    setDirectorIdLV1("");
    setDirectorLV1("");
    setComplianceDateLV1(dayjs());
    setFaxLV1("");
    setPECLV1("");
    setTelefonoLV1("");
    setIndirizzoLV1("");
    setNomeCompletoLV1("");
    setNomeAbbreviatoLV1("");
    setDocumentsAttachedLV1([]);
    setAttachedFileInfoLV1([]);
    setDocumentsPathLV1("");
  };

  const [activeComponent, setActiveComponent] = useState("SchedaGenerale");

  const changeActiveComponent = (componentName: string) => {
    setActiveComponent(componentName);
  };

  const renderActiveComponent = () => {
    switch (activeComponent) {
      case "SchedaGenerale":
        return <SchedaGenerale />;
      case "Organizzazione":
        return <Organizzazione />;

      case "SistemaCompliance":
        return <Compliance />;
      case "Documentazione":
        return <Documentazione />;
      default:
        return <SchedaGenerale />;
    }
  };

  return (
    <OverlayComponentBackgroundAdd1>
      {isLoading && (
        <BackdropTransparent>
          <MessageContainer>
            <MessageboxText>Loading...</MessageboxText>
          </MessageContainer>
        </BackdropTransparent>
      )}

      {isMessageBoxVisible && (
        <Backdrop>
          <MessageContainer>
            <p>
              <MessageboxText>{message}</MessageboxText>
            </p>
            <CloseMessageButton onClick={handleCloseAndNavigate}>
              <MessageboxText>Close</MessageboxText>
            </CloseMessageButton>
          </MessageContainer>
        </Backdrop>
      )}

      {isMessageBoxVisibleClosing && (
        <Backdrop>
          <MessageContainer>
            <p>
              <MessageboxText>{message}</MessageboxText>
            </p>
            <CloseMessageButton onClick={handleClose}>
              <MessageboxText>Close</MessageboxText>
            </CloseMessageButton>
          </MessageContainer>
        </Backdrop>
      )}

      {error && (
        <Backdrop>
          <MessageContainer>
            <p>
              <MessageboxText>{error}</MessageboxText>
            </p>
            <CloseMessageButton
              onClick={() => {
                setIsMessageBoxVisible(false);
                setError(null);
                closeOverlay();
              }}
            >
              <MessageboxText>Close</MessageboxText>
            </CloseMessageButton>
          </MessageContainer>
        </Backdrop>
      )}

      <FrameTopGradient100 />
      <OverlayComponentWhiteTitleAdd1>VISUALIZZA {NomeCompletoLV1}</OverlayComponentWhiteTitleAdd1>
      <OverlayComponentWhiteAdd1>
        <SubMenuButtonContainerOrganigrammaMultipleShort>
          <CreaSubMenuButtonMultiple onClick={() => changeActiveComponent("SchedaGenerale")} to="/Cybersecurity/Organizzazione/Organigramma/VisualizzaL1/1">
            Scheda generale
          </CreaSubMenuButtonMultiple>
          <CreaSubMenuButtonMultiple onClick={() => changeActiveComponent("Organizzazione")} to="/Cybersecurity/Organizzazione/Organigramma/VisualizzaL1/2">
            Organizzazione
          </CreaSubMenuButtonMultiple>
          {/* <CreaSubMenuButtonMultiple
            onClick={() => changeActiveComponent("Infrastruttura")}
            to="/Cybersecurity/Organizzazione/Organigramma/VisualizzaL1/3"
          >
            Infrastruttura
          </CreaSubMenuButtonMultiple>
          <CreaSubMenuButtonMultiple
            onClick={() => changeActiveComponent("ProcessiGenerali")}
            to="/Cybersecurity/Organizzazione/Organigramma/VisualizzaL1/4"
          >
            Processi generali
          </CreaSubMenuButtonMultiple>
          <CreaSubMenuButtonMultiple
            onClick={() => changeActiveComponent("PartiInteressate")}
            to="/Cybersecurity/Organizzazione/Organigramma/VisualizzaL1/5"
          >
            Parti interessate
          </CreaSubMenuButtonMultiple> */}
          <CreaSubMenuButtonMultiple onClick={() => changeActiveComponent("SistemaCompliance")} to="/Cybersecurity/Organizzazione/Organigramma/VisualizzaL1/6">
            Sistema Compliance
          </CreaSubMenuButtonMultiple>
          <CreaSubMenuButtonMultiple onClick={() => changeActiveComponent("Documentazione")} to="/Cybersecurity/Organizzazione/Organigramma/VisualizzaL1/7">
            Documentazione
          </CreaSubMenuButtonMultiple>
        </SubMenuButtonContainerOrganigrammaMultipleShort>

        {renderActiveComponent()}
      </OverlayComponentWhiteAdd1>

      <OverlayComponentWhiteButtonFrameAdd1>
        <ButtonGrey onClick={handleCloseRecoil}>
          <ButtonText>Chiudi</ButtonText>
        </ButtonGrey>
        {/* <ButtonGrey onClick={handleSave}>
          <ButtonText>Crea</ButtonText>
        </ButtonGrey> */}
      </OverlayComponentWhiteButtonFrameAdd1>
    </OverlayComponentBackgroundAdd1>
  );
};

export default YourAddL1Component;
