import React, { useEffect, useRef, useState } from "react";
import { useRecoilState } from "recoil";
import {
  CreaContainerColumn,
  DescriptionContainerCreaMultiple,
  DescriptionContainerCreaText,
  FlexContainerCrea,
  IconContainer,
  IconSquare,
  InputContainer,
  InputDescriptionText,
  InputTextbox,
  OverlayComponentWhiteAdd1Multiple
} from "../cybersecurity-board-organizzazione-organigramma-styled-components";

import DownloadIcon from "@mui/icons-material/Download";
import { IconButton } from "@mui/material";
import { URL_FileManager, URL_FileManagerList, useToken } from "../../../../../API";
import {
  ListContainer,
  ListDescriptionText,
  ListScrollbar,
  ListTextbox
} from "../../cybersecurity-board-contestoleadership-infrastruttura-sub/cybersecurity-board-infrastruttura-organigramma-styled-components";
import { showL4AttachedFilesInfo, showL4DocPath, showL4DocumentsAttached } from "../L4-Show-recoil";

const useApi = () => {
  const token = useToken();

  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);

  const post = async (url: string, data: any, isFile: boolean = false) => {
    if (!token) {
      throw new Error("User is not authenticated");
    }
    try {
      if (isFile) {
        console.log("Sending as FormData (file):", data);
      } else {
        console.log("Sending as JSON string:", JSON.stringify(data));
      }

      const body = isFile ? data : JSON.stringify(data);

      console.log("Request body:", body);

      const response = await fetch(url, {
        method: "POST",

        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        body: isFile ? data : JSON.stringify(data),
      });

      const appError = response.headers.get("X-App-Error");
      if (appError) {
        console.error("X-App-Error:", appError);
      }

      let responseData;
      try {
        responseData = await response.json();
      } catch (parseError) {
        if (response.ok) {
          return null;
        } else {
          throw new Error(`API request failed: ${response.statusText}`);
        }
      }

      if (!response.ok) {
        console.error("Error response data:", responseData);

        throw new Error(`API request failed: ${response.statusText}`, responseData);
      }

      return responseData;
    } catch (error) {
      throw error;
    }
  };

  const get = async (url: string) => {
    if (!token) throw new Error("User is not authenticated");

    try {
      const response = await fetch(url, {
        method: "GET",

        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      console.log("Headers:", Array.from(response.headers.entries()));

      if (!response.ok) {
        const errorBody = await response.text();
        console.error("Error body:", errorBody);
        throw new Error(`API request failed: ${response.statusText}`);
      }

      const responseBody = await response.json();
      console.log("Response body:", responseBody);
      return responseBody;
    } catch (error) {
      console.error("Error during fetch:", error);

      console.log(`Request was made to URL: ${url} with token: ${token}`);
      throw error;
    }
  };

  const uploadFile = async (dir: string, file: File, fileName: string) => {
    const formData = new FormData();
    formData.append("file", file, fileName);

    const url = `${URL_FileManager}${encodeURIComponent(dir)}&file=${encodeURIComponent(fileName)}`;
    return await post(url, formData, true);
  };

  return { post, get, uploadFile };
};

const MainComponent = () => {
  const token = useToken();
  const { uploadFile } = useApi();

  const handleDownload = async (filePath: string) => {
    const pathSegments = filePath.split("/");

    const file = pathSegments.pop() || "defaultFileName";

    const dir = pathSegments.join("/");

    const url = `${URL_FileManager}${encodeURIComponent(dir)}&file=${encodeURIComponent(file)}`;

    try {
      const response = await fetch(url, {
        method: "GET",
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      if (!response.ok) {
        const errorHeader = response.headers.get("X-App-Error");
        console.error("Error header:", errorHeader);
        throw new Error(`API request failed: ${response.statusText}`);
      }

      response.headers.forEach((value, key) => {
        console.log(`Response header: ${key} = ${value}`);
      });

      const blob = await response.blob();
      const downloadUrl = window.URL.createObjectURL(blob);
      const link = document.createElement("a");
      link.href = downloadUrl;

      link.download = filePath.split("/").pop() || "";

      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      window.URL.revokeObjectURL(downloadUrl);
    } catch (error) {
      console.error("Error downloading file:", error);
    }
  };

  const [docPath, setDocPath] = useRecoilState(showL4DocPath);
  const [docAttached, setDocAttached] = useRecoilState(showL4DocumentsAttached);
  const [filesInfo, setFilesInfo] = useRecoilState(showL4AttachedFilesInfo);

  const fileInputRef = useRef<HTMLInputElement>(null);

  const removeFile = (index: number) => {
    setDocAttached((currentFiles) => currentFiles.filter((_, i) => i !== index));
    setFilesInfo((currentFilesInfo) => currentFilesInfo.filter((_, i) => i !== index));
  };

  const handleButtonClick = () => {
    if (fileInputRef.current !== null) {
      fileInputRef.current.click();
    }
  };

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const files = event.target.files;
    if (files) {
      const newFileList = Array.from(files);

      setDocAttached((currentFiles) => [...currentFiles, ...newFileList]);

      const newFileInfoArray = newFileList.map((file) => ({
        name: file.name,
        size: file.size,
        type: file.type,
      }));

      setFilesInfo((currentFilesInfo) => [...currentFilesInfo, ...newFileInfoArray]);
    }
  };

  const handleFileUpload = async (selectedFiles: FileList | null) => {
    if (selectedFiles) {
      for (let i = 0; i < selectedFiles.length; i++) {
        const file = selectedFiles[i];
        const dir = "directory";
        const fileName = file.name;
        try {
          await uploadFile(dir, file, fileName);
          console.log("File uploaded successfully:", fileName);
        } catch (error) {
          console.error("Error uploading file:", fileName, error);
        }
      }
    }
  };

  const initiateUpload = () => {
    if (docAttached && docAttached.length > 0) {
      const dataTransfer = new DataTransfer();

      docAttached.forEach((file) => dataTransfer.items.add(file));

      handleFileUpload(dataTransfer.files);
    } else {
      console.log("No files selected for upload.");
    }
  };

  type FileListItem = {
    id: string;
    path: string;
    name: string;
  };

  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);
  const [directoryContents, setDirectoryContents] = useState<FileListItem[]>([]);

  useEffect(() => {
    const fetchDirectoryContents = async () => {
      setIsLoading(true);
      setError(null);

      try {
        const response = await fetch(`${URL_FileManagerList}${docPath}`, {
          method: "GET",
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });

        if (!response.ok) {
          throw new Error("Failed to fetch directory contents");
        }

        const filePaths: string[] = await response.json();
        const transformedData: FileListItem[] = filePaths.map((filePath, index) => ({
          id: `file-${index}`,
          path: filePath,
          name: filePath.split("/").pop() || "",
        }));

        setDirectoryContents(transformedData);
      } catch (error: unknown) {
        if (error instanceof Error) {
          setError(error.message);
        } else {
          setError("An unexpected error occurred");
        }
      } finally {
        setIsLoading(false);
      }
    };

    fetchDirectoryContents();
  }, [docPath, token]);

  return (
    <OverlayComponentWhiteAdd1Multiple>
      <FlexContainerCrea>
        <CreaContainerColumn>
          <ListContainer>
            <ListDescriptionText>Documentazione</ListDescriptionText>
            <ListTextbox>
              <ListScrollbar>
                {directoryContents.map((file) => (
                  <li key={file.id} style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                    <span>{file.name}</span>
                    <IconButton onClick={() => handleDownload(file.path)} size="small" style={{ marginLeft: "auto" }}>
                      <DownloadIcon style={{ fontSize: "14px", color: "#cccccc" }} />
                    </IconButton>
                  </li>
                ))}
              </ListScrollbar>
            </ListTextbox>

            {/* <IconContainerList /> */}
          </ListContainer>

          {/* <div>

      <button onClick={handleDownload}>Download File</button>

    </div> */}
        </CreaContainerColumn>

        <CreaContainerColumn>
          <InputContainer>
            <InputDescriptionText>Nome cartella documentazione</InputDescriptionText>
            <InputTextbox placeholder="" value={docPath} onChange={(e) => setDocPath(e.target.value)} />
            <IconContainer />
          </InputContainer>
          {/* <button onClick={initiateUpload}>Upload Files</button> */}
        </CreaContainerColumn>
      </FlexContainerCrea>
      <DescriptionContainerCreaMultiple>
        <IconSquare src="/description_show_docs.svg" alt="Description Icon" />
        <DescriptionContainerCreaText>
          Nella scheda è descritto il percorso della cartella principale dove archiviare i documenti dell’entità selezionata e utilizzare la funzione di allegato per inserire direttamente la
          documentazione rilevante per la compliance in Cybersecurity in riferimento all’entità, garantendo così un accesso immediato e organizzato ai file essenziali.
        </DescriptionContainerCreaText>
      </DescriptionContainerCreaMultiple>
    </OverlayComponentWhiteAdd1Multiple>
  );
};

export default MainComponent;
