import { useAuth } from "oidc-react";

//import { env } from "./env";//(obsolete)

///////////// API LIST:

//const organizationIp = env.REACT_APP_ORGANIZATION_IP; //(obsolete)
//export const keycloakIp = env.REACT_APP_KEYCLOAK_IP; //(obsolete)
//const templaterIp = env.REACT_APP_TEMPLATER_IP; //(obsolete)

export const complereIp = `https://complere.ecubit.it`; //production
export const organizationIp = `https://complere.ecubit.it/organization`; //production
export const keycloakIp = 'https://auth.ecubit.it'; //production
const templaterIp = 'https://complere.ecubit.it/templater'; //production

export const localIp = `http://localhost:3000`; //development
// export const organizationIp = `http://localhost:8090`; //development
// export const keycloakIp = `http://localhost:8081`; //development
// const templaterIp = `http://localhost:8095`; //development

const URL = `${organizationIp}`
const URL_Minio = `${templaterIp}`

/////////////GLOBAL

export const URL_DocGen_Lettera = `${URL_Minio}/api/v1/template/lettera-incarico`
export const URL_DocNumber = `${URL}/api/v1/document-number/complere-cyber`

// LV1 
export const URL_LV1_Detailed = `${URL}/api/v1/site`; //:ID
export const URL_LV1List = `${URL}/api/v1/organization/organizations.ecubit01/sites`;
export const URL_LV1 = `${URL}/api/v1/site/organizations.ecubit01`

// LV2
export const URL_LV2Card = `${URL}/api/v1/organizationcard`
export const URL_LV2List = `${URL}/api/v1/units/minimal/organizations.ecubit01`
export const URL_L2ByL1 = `${URL}/api/v1/organization/organizations.ecubit01/units/`;
export const URL_LV2ListByLV1 = `${URL}/api/v1/organization/organizations.ecubit01/organigram`;
export const URL_ComplianceProcessesL2 = `${URL}/api/v1/unit-process-compliance-processes/selection/byunit/` //id

// LV3
export const URL_RoleList = `${URL}/api/v1/roles`;
export const URL_RoleShort = `${URL}/api/v1/role`;
export const URL_Model = `${URL}/api/v1/organizationmodel`
export const URL_RoleModels = `${URL}/api/v1/rolemodels`;
export const URL_RoleMOdelsAssociation = `${URL}/api/v1/rolemodels/selection/`; // :modelID

// PLACES
export const URL_PlacesFullList = `${URL}/api/v1/place`;
export const URL_PlacesList = `${URL}/api/v1/places-search`; // additional "total" const found in the source file
export const URL_PlacesByPersonSelection = `${URL}/api/v1/places/byperson/selection/`;
export const URL_PlacesByUnit = `${URL}/api/v1/places/byunit`;

// LV4
export const URL_LV4List_short = `${URL}/api/v1/person`;
export const URL_LV4List = `${URL}/api/v1/person/`;
export const URL_DirectorList = `${URL}/api/v1/persons/minimal/organizations.ecubit01`;
export const URL_PersonRolesCompliance = `${URL}/api/v1/personroles-compliance/`;
export const URL_PersonRoles = `${URL}/api/v1/personroles/`; // :personID
export const URL_PersonRolesAll = `${URL}/api/v1/personroles/all/organizations.ecubit01`;
export const URL_GetPatchPersonRoles = `${URL}/api/v1/personroles/selection/byperson/`; // :personID

// Assets
export const URL_AssetsByLV1 = `${URL}/api/v1/asset/tree/`;
export const URL_AssetsByPersonSelection = `${URL}/api/v1/assets/byperson/selection/`;
export const URL_AssetsByResponsible = `${URL}/api/v1/assets-search?has_responsible=`;
export const URL_AssetList = `${URL}/api/v1/assets-search` // additional "total" const found in the source file
export const URL_AssetTreeByPlace = `${URL}/api/v1/asset/tree/byplace/`;
export const URL_AssetDetails = `${URL}/api/v1/asset`
export const URL_CategoryTree = `${URL}/api/v1/assetcategory/tree`
export const URL_AssetProcesses = `${URL}/api/v1/processes/selection/byasset/` // PATCH, POST: needs asset ID
export const URL_CompliancebyAsset = `${URL}/api/v1/compliance-processes/selection/byasset/` // GET PATCH by asset ID

// Organization
export const URL_OrgModel = `${URL}/api/v1/organizationmodel-search`; // additional "info" and "size" consts found in the source file
export const URL_OrgCard = `${URL}/api/v1/organizationcard/`;

// Triple
export const URL_UnitProcessRolePerson = `${URL}/api/v1/unit-process-role-person/selection/byprocessroleperson/`;
export const URL_UnitComplianceProcessRolePerson = `${URL}/api/v1/unit-compliance-process-compliance-role-person/selection/bycomplianceroleperson/`;

// Minio File Manager
export const URL_FileManager = `${URL_Minio}/api/v1/storage?dir=`; // additional "file" const found in the source file (Usually in Documentazione)
export const URL_FileManagerList = `${URL_Minio}/api/v1/storage/list?dir=`;

//Parti interessate
//export const URL_AltriEnti = `${URL}/api/v1/ass-unit-third-party-organizations/`;
export const URL_AltriEnti = `${URL}/api/v1/ass-unit-third-party-organizations`; // noslash
export const URL_Parti = `${URL}/api/v1/third-party-organization`;
export const URL_PartiList = `${URL}/api/v1/third-party-organizations`;
export const URL_LegalForm = `${URL}/api/v1/entity-legal-forms`

//Processes
export const URL_Process = `${URL}/api/v1/process`;
export const URL_ProcessList = `${URL}/api/v1/processes`;
export const URL_ProcessPost = `${URL}/api/v1/processes/selection/byunit` //ID (obsolete)
export const URL_Cybersecurity = `${URL}/api/v1/processes/selection/domain/cybersecurity`;
export const URL_ProcessesSelected = `${URL}/api/v1/compliance-processes/selection/byprocess/`; //:id

//Business Process - Compliance Process association

//empty

//Compliance
export const URL_ComplianceRolesShort = `${URL}/api/v1/compliance-role`
export const URL_ComplianceProcess = `${URL}/api/v1/compliance-process`
export const URL_ComplianceProcessList = `${URL}/api/v1/compliance-processes`;
export const URL_ComplianceRoles = `${URL}/api/v1/compliance-roles-search`;
export const URL_ObligationsList = `${URL}/api/v1/adempimenti`;
export const URL_Obligations = `${URL}/api/v1/adempimento`;

//Adempimenti
export const URL_AdempimentiList = `${URL}/api/v1/adempimenti`;

//GAP Analysis
export const URL_GAP_Analysis = `${URL}/api/v1/gap-analysis`;

///////////////////////API LOGIC


export const useToken = () => {
  const auth = useAuth();

  if (!auth.userData) {
    return null; // User not authenticated
  }
  // Extract the token from auth.userData
  return auth.userData.access_token || null;
};

export const useApi = () => {
  const token = useToken();

  //////////POST 

  const post = async (url: string, data: any) => {
    if (!token) {
      throw new Error("User is not authenticated");
    }
    try {
      const response = await fetch(url, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify(data),
      });

      let responseData;
      try {
        responseData = await response.json();
      } catch (parseError) {
        if (response.ok) {
          return null;
        } else {
          throw new Error(`API request failed: ${response.statusText}`);
        }
      }

      if (!response.ok) {
        console.error("Error response data:", responseData);
        throw new Error(`API request failed: ${response.statusText}`);
      }

      return responseData;
    } catch (error) {
      throw error;
    }
  };

  /////////GET

  const getWithId = async (baseURL: string, id: string) => {
    const url = `${baseURL}/${id}`; // Construct the URL with the button ID
    // ... (the rest is similar to your existing 'get' method)
    const response = await fetch(url, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });

    if (!response.ok) {
      throw new Error(`API request failed: ${response.statusText}`);
    }

    return await response.json();
  };


  const get = async (url: string) => {
    if (!token) throw new Error("User is not authenticated");

    const response = await fetch(url, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });

    if (!response.ok) {
      throw new Error(`API request failed: ${response.statusText}`);
    }

    return await response.json();
  };

  /////////PATCH 

  const patch = async (url: string, data: any) => {
    if (!token) {
      throw new Error("User is not authenticated");
    }
    try {
      const response = await fetch(url, {
        method: "PATCH",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify(data),
      });

      let responseData;
      try {
        responseData = await response.json();
      } catch (parseError) {
        if (response.ok) {
          return null;
        } else {
          throw new Error(`API request failed: ${response.statusText}`);
        }
      }

      if (!response.ok) {
        console.error("Error response data:", responseData);
        throw new Error(`API request failed: ${response.statusText}`);
      }

      return responseData;
    } catch (error) {
      throw error;
    }
  };

  /////////DELETE

  const del = async (url: string, id: string) => {
    if (!token) {
      throw new Error("User is not authenticated");
    }
    const fullUrl = `${url}/${id}`;
    try {
      const response = await fetch(fullUrl, {
        method: 'DELETE',
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      if (!response.ok) {
        throw new Error(`API request failed: ${response.statusText}`);
      }
      return response.json(); // Depending on API, might not return a JSON body
    } catch (error) {
      throw error;
    }
  };

  // Return all methods as part of an object
  return { post, get, patch, del };
};