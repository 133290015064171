import InfoIcon from "@mui/icons-material/InfoOutlined";
import { Tooltip } from "@mui/material";
import { DatePicker } from "@mui/x-date-pickers";
import dayjs from "dayjs";
import React, { useEffect } from "react";
import { useRecoilState } from "recoil";
import { URL_LV4List, useToken } from "../../../../../API";
import { SelectedL4ID, isLoadingRec, isMessageBoxVisibleRec, setErrorRec, setMessageRec } from "../../../../../recoil";
import { InputContainer50 } from "../../cybersecurity-board-contestoleadership-infrastruttura-sub/cybersecurity-board-infrastruttura-organigramma-styled-components";
import {
  CreaContainerColumn,
  FlexContainerCrea,
  InputContainer,
  InputDescriptionText,
  InputTextbox,
  OverlayComponentWhiteAdd1Multiple
} from "../cybersecurity-board-organizzazione-organigramma-styled-components";
import {
  editL4ComponenteOrganizzativa,
  editL4DocPath,
  editL4DocumentsPath,
  editL4Name,
  editL4OrganizzazioneSelectedL1,
  editL4OrganizzazioneSelectedL1ID,
  editL4PersonItem,
  editL4PersonItemState,
  editL4PersonalCardId,
  editL4RuoliEnte,
  editL4SuperioreDiretto,
  editL4Surname,
  editL4Title,
  editL4UnitaOperative,
  editL4birthDate,
  editL4birthPlace,
  editL4domicileAddress,
  editL4fiscalCode,
  editL4gender,
  editL4idDocumentNumber,
  editL4personalEmail,
  editL4privatePhone,
  editL4residenceAddress,
  editL4workEmail,
  editL4workPhone,
} from "../L4-Edit-recoil";

const useFetchPersonData = (token: string | null | undefined) => {
  const [data, setData] = useRecoilState(editL4PersonItemState);
  const [isLoading, setIsLoading] = useRecoilState(isLoadingRec);
  const [error, setError] = useRecoilState<string | null>(setErrorRec);
  const [isMessageBoxVisible, setIsMessageBoxVisible] = useRecoilState(isMessageBoxVisibleRec);
  const [message, setMessage] = useRecoilState(setMessageRec);
  const [L4ID, setL4ID] = useRecoilState(SelectedL4ID);

  const [LV1List, setLV1List] = useRecoilState(editL4OrganizzazioneSelectedL1);
  const [LV1ListId, setLV1ListId] = useRecoilState(editL4OrganizzazioneSelectedL1ID);

  const [personalCardID, setPersonalCardID] = useRecoilState(editL4PersonalCardId);

  const [surname, setSurname] = useRecoilState(editL4Surname);
  const [name, setName] = useRecoilState(editL4Name);
  const [birthDate, setBirthDate] = useRecoilState(editL4birthDate);
  const [birthPlace, setBirthPlace] = useRecoilState(editL4birthPlace);
  const [gender, setGender] = useRecoilState(editL4gender);
  const [fiscalCode, setFiscalCode] = useRecoilState(editL4fiscalCode);
  const [residenceAddress, setResidenceAddress] = useRecoilState(editL4residenceAddress);
  const [domicileAddress, setDomicileAddress] = useRecoilState(editL4domicileAddress);
  const [workEmail, setWorkEmail] = useRecoilState(editL4workEmail);
  const [personalEmail, setPersonalEmail] = useRecoilState(editL4personalEmail);
  const [workPhone, setWorkPhone] = useRecoilState(editL4workPhone);
  const [privatePhone, setPrivatePhone] = useRecoilState(editL4privatePhone);
  const [docPath, setDocPath] = useRecoilState(editL4DocPath);
  const [Title, setTitle] = useRecoilState(editL4Title);
  const [superioreDiretto, setSuperioreDiretto] = useRecoilState(editL4SuperioreDiretto);
  const [componenteOrganizzativa, setComponenteOrganizzativa] = useRecoilState(editL4ComponenteOrganizzativa);

  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true);
      try {
        if (!token) throw new Error("User is not authenticated");

        const response = await fetch(`${URL_LV4List}${L4ID}`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });

        if (!response.ok) {
          throw new Error(`API request failed: ${response.statusText}`);
        }

        const responseData = await response.json();
        console.log("Response Data:", responseData);

        setPersonalCardID(responseData.personal_card.id);
        setSurname(responseData.surname);
        setName(responseData.name);
        setBirthDate(responseData.personal_card.date_of_birth ? dayjs(responseData.personal_card.date_of_birth) : null);
        setBirthPlace(responseData.personal_card.has_place_of_birth);
        setGender(responseData.personal_card.has_citizenship);
        setFiscalCode(responseData.personal_card.national_identification_number);
        setResidenceAddress(responseData.personal_card.has_primary_residence);
        setDomicileAddress(responseData.personal_card.has_postal_address);
        setWorkEmail(responseData.personal_card.work_email);
        setPersonalEmail(responseData.personal_card.personal_email);
        setWorkPhone(responseData.personal_card.work_mobile_phone);
        setPrivatePhone(responseData.personal_card.personal_mobile_phone);
        setSuperioreDiretto(responseData.reports_to);
        setLV1ListId(responseData.based_at);
        setDocPath(responseData.documents_folder_link);
        setTitle(`${responseData.surname} ${responseData.name}`);

        const transformedData: editL4PersonItem = {
          id: responseData.id,
          personal_card_id: responseData.personal_card_id,
          surname: responseData.surname,
          name: responseData.name,
          has_personal_card: responseData.has_personal_card,
          member_of_formal_organization: responseData.member_of_formal_organization,
          reports_to: responseData.reports_to,
          has_smart_working_site: responseData.has_smart_working_site,
          documents_folder_link: responseData.documents_folder_link,
          based_at: responseData.based_at,
          national_identification_number: responseData.personal_card.national_identification_number,
          date_of_birth: responseData.personal_card.date_of_birth,
          has_place_of_birth: responseData.personal_card.has_place_of_birth,
          has_primary_residence: responseData.personal_card.has_primary_residence,
          has_postal_address: responseData.personal_card.has_postal_address,
          personal_email: responseData.personal_card.personal_email,
          work_email: responseData.personal_card.work_email,
          work_mobile_phone: responseData.personal_card.work_mobile_phone,
          personal_mobile_phone: responseData.personal_card.personal_mobile_phone,
          has_identity_document: responseData.personal_card.has_identity_document,
          has_citizenship: responseData.personal_card.has_citizenship,
        };

        console.log("Transformed Data:", transformedData);
        setData([transformedData]);
      } catch (err) {
        setError(err instanceof Error ? err.message : "An unknown error occurred");
        setIsMessageBoxVisible(true);
      } finally {
        setIsLoading(false);
      }
    };

    if (token && L4ID) fetchData();
  }, [token, L4ID, setData]);
};

const MainComponent = () => {
  const [data, setData] = useRecoilState(editL4PersonItemState);
  const [isLoading, setIsLoading] = useRecoilState(isLoadingRec);
  const [error, setError] = useRecoilState<string | null>(setErrorRec);
  const [isMessageBoxVisible, setIsMessageBoxVisible] = useRecoilState(isMessageBoxVisibleRec);
  const [message, setMessage] = useRecoilState(setMessageRec);

  const [surname, setSurname] = useRecoilState(editL4Surname);
  const [name, setName] = useRecoilState(editL4Name);
  const [birthDate, setBirthDate] = useRecoilState(editL4birthDate);
  const [birthPlace, setBirthPlace] = useRecoilState(editL4birthPlace);
  const [gender, setGender] = useRecoilState(editL4gender);
  const [fiscalCode, setFiscalCode] = useRecoilState(editL4fiscalCode);
  const [residenceAddress, setResidenceAddress] = useRecoilState(editL4residenceAddress);
  const [domicileAddress, setDomicileAddress] = useRecoilState(editL4domicileAddress);
  const [workEmail, setWorkEmail] = useRecoilState(editL4workEmail);
  const [personalEmail, setPersonalEmail] = useRecoilState(editL4personalEmail);
  const [workPhone, setWorkPhone] = useRecoilState(editL4workPhone);
  const [privatePhone, setPrivatePhone] = useRecoilState(editL4privatePhone);
  const [docPath, setDocPath] = useRecoilState(editL4DocumentsPath);
  const [Title, setTitle] = useRecoilState(editL4Title);

  const [idDocumentNumber, setIdDocumentNumber] = useRecoilState(editL4idDocumentNumber);
  const [ruoliEnte, setRuoliEnte] = useRecoilState(editL4RuoliEnte);
  const [unitaOperative, setUnitaOperative] = useRecoilState(editL4UnitaOperative);
  const [superioreDiretto, setSuperioreDiretto] = useRecoilState(editL4SuperioreDiretto);
  const [componenteOrganizzativa, setComponenteOrganizzativa] = useRecoilState(editL4ComponenteOrganizzativa);

  const [L4ID, setL4ID] = useRecoilState(SelectedL4ID);

  const token = useToken();

  useFetchPersonData(token);

  useEffect(() => {
    if (birthDate) {
      console.log("Selected Birth Date: ", birthDate.format("YYYY-MM-DD"));
    }
  }, [birthDate]);
  return (
    <OverlayComponentWhiteAdd1Multiple>
      <FlexContainerCrea>
        <CreaContainerColumn>
          <InputContainer>
            <InputDescriptionText>
              Cognome
              <Tooltip title="Campo obbligatorio">
                <InfoIcon style={{ fontSize: "14px", color: "#04446c", cursor: "pointer", marginLeft: "2px" }} />
              </Tooltip>
            </InputDescriptionText>
            <InputTextbox placeholder="" value={surname} onChange={(e) => setSurname(e.target.value)} />
          </InputContainer>

          <InputContainer>
            <InputDescriptionText>
              Nome
              <Tooltip title="Campo obbligatorio">
                <InfoIcon style={{ fontSize: "14px", color: "#04446c", cursor: "pointer", marginLeft: "2px" }} />
              </Tooltip>
            </InputDescriptionText>
            <InputTextbox placeholder="" value={name} onChange={(e) => setName(e.target.value)} />
          </InputContainer>

          <InputContainer50>
            <InputContainer>
              <InputDescriptionText>Genere</InputDescriptionText>
              <InputTextbox placeholder="" value={gender} onChange={(e) => setGender(e.target.value)} />
            </InputContainer>
            <InputContainer>
              <InputDescriptionText>Data di nascita</InputDescriptionText>
              {/* <InputTextbox placeholder="" value={birthDate} onChange={(e) => setBirthDate(e.target.value)} /> */}
              <DatePicker
                value={birthDate}
                format="DD-MM-YYYY"
                onChange={(newDate: dayjs.Dayjs | null) => setBirthDate(newDate)}
                sx={{
                  "& .MuiInputBase-input": {
                    height: "2px",
                  },
                  ".MuiInputBase-root": {
                    borderRadius: "0px",
                    backgroundColor: "#ffffff",
                    marginBottom: "20px",
                    boxShadow: "0px 1px 2px rgba(0, 0, 0, 0.1)",
                    "& .MuiOutlinedInput-notchedOutline": {
                      borderColor: "#cccccc",
                    },
                  },

                  "&:hover .MuiOutlinedInput-notchedOutline": {
                    borderColor: "#cccccc",
                  },

                  "& .Mui-focused .MuiOutlinedInput-notchedOutline": {
                    borderColor: "#1a81c3",
                    borderWidth: "1px",
                    boxShadow: "0px 4px 3px rgba(0, 0, 0, 0.1)",
                  },
                  ".MuiInputBase-input": {
                    color: "#0e163d",
                    fontSize: "15px",
                    fontFamily: "Titillium Web",
                  },

                  ".MuiSvgIcon-root": {
                    color: "#808080",
                    fontSize: "1.25rem",
                  },

                  width: "100%",
                }}
              />
            </InputContainer>
          </InputContainer50>

          <InputContainer>
            <InputDescriptionText>Luogo di nascita</InputDescriptionText>
            <InputTextbox placeholder="" value={birthPlace} onChange={(e) => setBirthPlace(e.target.value)} />
          </InputContainer>

          <InputContainer>
            <InputDescriptionText>
              Codice fiscale
              <Tooltip title="Campo obbligatorio">
                <InfoIcon style={{ fontSize: "14px", color: "#04446c", cursor: "pointer", marginLeft: "2px" }} />
              </Tooltip>
            </InputDescriptionText>
            <InputTextbox placeholder="" value={fiscalCode} onChange={(e) => setFiscalCode(e.target.value)} />
          </InputContainer>
        </CreaContainerColumn>

        <CreaContainerColumn>
          <InputContainer>
            <InputDescriptionText>Indirizzo residenza</InputDescriptionText>
            <InputTextbox placeholder="" value={residenceAddress} onChange={(e) => setResidenceAddress(e.target.value)} />
          </InputContainer>

          <InputContainer>
            <InputDescriptionText>Indirizzo domiciliazione</InputDescriptionText>
            <InputTextbox placeholder="" value={domicileAddress} onChange={(e) => setDomicileAddress(e.target.value)} />
          </InputContainer>

          <InputContainer>
            <InputDescriptionText>Email lavorativa</InputDescriptionText>
            <InputTextbox placeholder="" value={workEmail} onChange={(e) => setWorkEmail(e.target.value)} />
          </InputContainer>

          <InputContainer>
            <InputDescriptionText>Email personale</InputDescriptionText>
            <InputTextbox placeholder="" value={personalEmail} onChange={(e) => setPersonalEmail(e.target.value)} />
          </InputContainer>

          <InputContainer50>
            <InputContainer>
              <InputDescriptionText>Cellulare lavoro</InputDescriptionText>
              <InputTextbox placeholder="" value={workPhone} onChange={(e) => setWorkPhone(e.target.value)} />
            </InputContainer>
            <InputContainer>
              <InputDescriptionText>Cellulare privato</InputDescriptionText>
              <InputTextbox placeholder="" value={privatePhone} onChange={(e) => setPrivatePhone(e.target.value)} />
            </InputContainer>
          </InputContainer50>
        </CreaContainerColumn>
      </FlexContainerCrea>
      {/* <DescriptionContainerCreaMultiple>
        <IconSquare src="/crea-L1-schedagenerale.svg" alt="Description Icon" />
              <DescriptionContainerCreaText>
             
              Inserire i dettagli identificativi dell'unità operativa, comprensivi di un nome completo che rifletta le competenze e i servizi offerti, un nome abbreviato per una rapida identificazione, oltre ai principali canali di comunicazione per facilitare un contatto efficace e una pronta corrispondenza


              </DescriptionContainerCreaText>

        </DescriptionContainerCreaMultiple> */}
    </OverlayComponentWhiteAdd1Multiple>
  );
};

export default MainComponent;
