import dayjs from "dayjs";
import React, { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { OverlayContext } from "../cybersecurity-board-contestoleadership-organizzazione";

import { useRecoilState } from "recoil";

import { isLoadingRec, isMessageBoxVisibleClosingRec, isMessageBoxVisibleRec, setErrorRec } from "../../../../recoil";

import {
  addL1AttachedFilesInfo,
  addL1ComplianceDate,
  addL1ComplianceDirettoreCompliance,
  addL1ComplianceDirettoreComplianceID,
  addL1DocumentsAttached,
  addL1DocumentsPath,
  addL1OrganizzazioneDirettore,
  addL1OrganizzazioneDirettoreID,
  addL1SchedaGeneraleFax,
  addL1SchedaGeneraleIndirizzo,
  addL1SchedaGeneraleNomeAbbreviato,
  addL1SchedaGeneraleNomeCompleto,
  addL1SchedaGeneralePEC,
  addL1SchedaGeneraleTelefono,
} from "../cybersecurity-board-contestoleadership-organizzazione-sub/L1-Add-Recoil";

import SchedaGenerale from "./cybersecurity-board-org-org-L1-Add-sub/organigramma-L1-Add-1-SchedaGenerale";
import Organizzazione from "./cybersecurity-board-org-org-L1-Add-sub/organigramma-L1-Add-2-Organizzazione";
import Compliance from "./cybersecurity-board-org-org-L1-Add-sub/organigramma-L1-Add-6-GestioneCompliance";
import Documentazione, { useInitiateUploadL1 } from "./cybersecurity-board-org-org-L1-Add-sub/organigramma-L1-Add-7-Documentazione";

import { ButtonGrey, ButtonText } from "../../styled-components";

import { URL_DirectorList, URL_LV1, useApi, useToken } from "../../../../API";
import { PATH_L1 } from "../../../../docPaths";
import {
  Backdrop,
  BackdropTransparent,
  CloseMessageButton,
  CreaSubMenuButtonMultiple,
  FrameTopGradient100,
  MessageContainer,
  MessageboxText,
  OverlayComponentBackgroundAdd1,
  OverlayComponentWhiteAdd1,
  OverlayComponentWhiteButtonFrameAdd1,
  OverlayComponentWhiteTitleAdd1,
  SubMenuButtonContainerOrganigrammaMultipleShort,
} from "./cybersecurity-board-organizzazione-organigramma-styled-components";

const YourAddL1Component = () => {
  const { closeOverlay } = useContext(OverlayContext);

  const navigate = useNavigate();

  const token = useToken();
  const [data, setData] = useState<DirectorDataItem[]>([]);
  const [isLoading, setIsLoading] = useRecoilState(isLoadingRec);
  const [error, setError] = useRecoilState(setErrorRec);
  const [isMessageBoxVisible, setIsMessageBoxVisible] = useRecoilState(isMessageBoxVisibleRec);
  const [isMessageBoxVisibleClosing, setIsMessageBoxVisibleClosing] = useRecoilState(isMessageBoxVisibleClosingRec);

  const [message, setMessage] = useState("");

  const { post } = useApi();

  const [directorLV1, setDirectorLV1] = useRecoilState(addL1OrganizzazioneDirettore);
  const [directorIdLV1, setDirectorIdLV1] = useRecoilState(addL1OrganizzazioneDirettoreID);
  const [directorComplianceLV1, setDirectorComplianceLV1] = useRecoilState(addL1ComplianceDirettoreCompliance);
  const [directorComplianceIdLV1, setDirectorComplianceIdLV1] = useRecoilState(addL1ComplianceDirettoreComplianceID);
  const [complianceDateLV1, setComplianceDateLV1] = useRecoilState(addL1ComplianceDate);
  const [FaxLV1, setFaxLV1] = useRecoilState(addL1SchedaGeneraleFax);
  const [IndirizzoLV1, setIndirizzoLV1] = useRecoilState(addL1SchedaGeneraleIndirizzo);
  const [NomeAbbreviatoLV1, setNomeAbbreviatoLV1] = useRecoilState(addL1SchedaGeneraleNomeAbbreviato);
  const [NomeCompletoLV1, setNomeCompletoLV1] = useRecoilState(addL1SchedaGeneraleNomeCompleto);
  const [PECLV1, setPECLV1] = useRecoilState(addL1SchedaGeneralePEC);
  const [TelefonoLV1, setTelefonoLV1] = useRecoilState(addL1SchedaGeneraleTelefono);

  const [AttachedFileInfoLV1, setAttachedFileInfoLV1] = useRecoilState(addL1AttachedFilesInfo);
  const [DocumentsAttachedLV1, setDocumentsAttachedLV1] = useRecoilState(addL1DocumentsAttached);
  const [DocumentsPathLV1, setDocumentsPathLV1] = useRecoilState(addL1DocumentsPath);

  const initiateUpload = useInitiateUploadL1();

  const handleSave = async () => {
    if (!NomeCompletoLV1 || !NomeAbbreviatoLV1) {
      setMessage("Si prega di compilare tutti i campi obbligatori: Nome completo e Nome abbreviato");
      setIsMessageBoxVisibleClosing(true);
      return;
    }

    const newSiteData = {
      site_name: NomeCompletoLV1,
      site_short_name: NomeAbbreviatoLV1,
      fax: FaxLV1,
      site_telephone: TelefonoLV1,
      has_site_responsible: directorIdLV1,
      address: {
        country_name: "IT",
        region: "Lazio",
        locality: "Roma",
        postal_code: "00162",
        street_address: IndirizzoLV1,
        building_number: "",
        apartment_number: "",
      },
      site_email: PECLV1,
      has_documents_folder_link: PATH_L1 + NomeAbbreviatoLV1,
      has_compliance_responsible: directorComplianceIdLV1,
      compliance_responsible_assignment_start: complianceDateLV1,
    };

    console.log(JSON.stringify(newSiteData));

    try {
      setIsLoading(true);

      const responseData = await post(URL_LV1, newSiteData);
      await initiateUpload();
      console.log("La componente organizzativa è stata creata con successo:", responseData);
      setMessage("La componente organizzativa è stata creata con successo!");
      setIsLoading(false);
      setIsMessageBoxVisible(true);

      setDirectorComplianceIdLV1("");
      setDirectorComplianceLV1("");
      setDirectorIdLV1("");
      setDirectorLV1("");
      setComplianceDateLV1(dayjs());
      setFaxLV1("");
      setPECLV1("");
      setTelefonoLV1("");
      setIndirizzoLV1("");
      setNomeCompletoLV1("");
      setNomeAbbreviatoLV1("");
      setDocumentsAttachedLV1([]);
      setAttachedFileInfoLV1([]);
      setDocumentsPathLV1("");
    } catch (error) {
      console.error("Error creating site:", error);
      setMessage(`Error creating site: ${error instanceof Error ? error.message : "Unknown error"}`);
      setIsLoading(false);
      setIsMessageBoxVisible(true);
    }
  };

  type DirectorApiContentItem = {
    id: string;
    alias: string;
  };

  type DirectorDataItem = {
    director_id: string;
    director_name: string;
  };

  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true);
      try {
        if (!token) throw new Error("User is not authenticated");

        const response = await fetch(URL_DirectorList, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });

        if (!response.ok) {
          throw new Error(`API request failed: ${response.statusText}`);
        }

        const responseData = await response.json();
        console.log("Response Data:", responseData);

        const transformedData: DirectorDataItem[] = responseData.map((item: DirectorApiContentItem) => {
          return {
            director_id: item.id,
            director_name: item.alias,
          };
        });

        console.log("Transformed Data:", transformedData);
        setData(transformedData);
      } catch (err) {
        setError(err instanceof Error ? err.message : "An unknown error occurred");
        setIsMessageBoxVisible(true);
      } finally {
        setIsLoading(false);
      }
    };

    fetchData();
  }, [token]);

  const handleCloseAndNavigate = () => {
    setIsMessageBoxVisible(false);
    closeOverlay();
  };

  const handleClose = () => {
    setIsMessageBoxVisibleClosing(false);
  };

  const [activeComponent, setActiveComponent] = useState("SchedaGenerale");

  const changeActiveComponent = (componentName: string) => {
    setActiveComponent(componentName);
  };

  const renderActiveComponent = () => {
    switch (activeComponent) {
      case "SchedaGenerale":
        return <SchedaGenerale />;
      case "Organizzazione":
        return <Organizzazione />;

      case "SistemaCompliance":
        return <Compliance />;
      case "Documentazione":
        return <Documentazione />;
      default:
        return <SchedaGenerale />;
    }
  };

  return (
    <OverlayComponentBackgroundAdd1>
      {isLoading && (
        <BackdropTransparent>
          <MessageContainer>
            <MessageboxText>Loading...</MessageboxText>
          </MessageContainer>
        </BackdropTransparent>
      )}

      {isMessageBoxVisible && (
        <Backdrop>
          <MessageContainer>
            <p>
              <MessageboxText>{message}</MessageboxText>
            </p>
            <CloseMessageButton onClick={handleCloseAndNavigate}>
              <MessageboxText>Close</MessageboxText>
            </CloseMessageButton>
          </MessageContainer>
        </Backdrop>
      )}

      {isMessageBoxVisibleClosing && (
        <Backdrop>
          <MessageContainer>
            <p>
              <MessageboxText>{message}</MessageboxText>
            </p>
            <CloseMessageButton onClick={handleClose}>
              <MessageboxText>Close</MessageboxText>
            </CloseMessageButton>
          </MessageContainer>
        </Backdrop>
      )}

      {error && (
        <Backdrop>
          <MessageContainer>
            <p>
              <MessageboxText>{error}</MessageboxText>
            </p>
            <CloseMessageButton
              onClick={() => {
                setIsMessageBoxVisible(false);
                setError(null);
                closeOverlay();
              }}
            >
              <MessageboxText>Close</MessageboxText>
            </CloseMessageButton>
          </MessageContainer>
        </Backdrop>
      )}

      <FrameTopGradient100 />
      <OverlayComponentWhiteTitleAdd1>NUOVA COMPONENTE ORGANIZZATIVA</OverlayComponentWhiteTitleAdd1>
      <OverlayComponentWhiteAdd1>
        <SubMenuButtonContainerOrganigrammaMultipleShort>
          <CreaSubMenuButtonMultiple onClick={() => changeActiveComponent("SchedaGenerale")} to="/Cybersecurity/Organizzazione/Organigramma/CreaL1/1">
            Scheda generale
          </CreaSubMenuButtonMultiple>
          <CreaSubMenuButtonMultiple onClick={() => changeActiveComponent("Organizzazione")} to="/Cybersecurity/Organizzazione/Organigramma/CreaL1/2">
            Organizzazione
          </CreaSubMenuButtonMultiple>
          {/* <CreaSubMenuButtonMultiple
            onClick={() => changeActiveComponent("Infrastruttura")}
            to="/Cybersecurity/Organizzazione/Organigramma/CreaL1/3"
          >
            Infrastruttura
          </CreaSubMenuButtonMultiple>
          <CreaSubMenuButtonMultiple
            onClick={() => changeActiveComponent("ProcessiGenerali")}
            to="/Cybersecurity/Organizzazione/Organigramma/CreaL1/4"
          >
            Processi generali
          </CreaSubMenuButtonMultiple>
          <CreaSubMenuButtonMultiple
            onClick={() => changeActiveComponent("PartiInteressate")}
            to="/Cybersecurity/Organizzazione/Organigramma/CreaL1/5"
          >
            Parti interessate
          </CreaSubMenuButtonMultiple> */}
          <CreaSubMenuButtonMultiple onClick={() => changeActiveComponent("SistemaCompliance")} to="/Cybersecurity/Organizzazione/Organigramma/CreaL1/6">
            Sistema Compliance
          </CreaSubMenuButtonMultiple>
          <CreaSubMenuButtonMultiple onClick={() => changeActiveComponent("Documentazione")} to="/Cybersecurity/Organizzazione/Organigramma/CreaL1/7">
            Documentazione
          </CreaSubMenuButtonMultiple>
        </SubMenuButtonContainerOrganigrammaMultipleShort>

        {renderActiveComponent()}
      </OverlayComponentWhiteAdd1>

      <OverlayComponentWhiteButtonFrameAdd1>
        <ButtonGrey onClick={closeOverlay}>
          <ButtonText>Annulla</ButtonText>
        </ButtonGrey>
        <ButtonGrey onClick={handleSave}>
          <ButtonText>Crea</ButtonText>
        </ButtonGrey>
      </OverlayComponentWhiteButtonFrameAdd1>
    </OverlayComponentBackgroundAdd1>
  );
};

export default YourAddL1Component;
