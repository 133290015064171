import React, { useState, useEffect, useMemo } from "react";
import styled from "styled-components";
import { useNavigate, Routes, Route, Outlet } from "react-router-dom";
import { OverlayContext } from "../cybersecurity-board-contestoleadership-organizzazione";
import {
  IconButton,
  IconsGroup,
  BackgroundIcon,
  Backdrop,
  BackdropTransparent,
  MessageContainer,
  MessageboxText,
  CloseMessageButton,
  FadeOutComponent,
  FrameTopGradient100,
} from "./cybersecurity-board-gestionecompliance-organigramma-styled-components";
import {
  StyledTable,
  StyledTbody,
  StyledTd,
  StyledTh,
  StyledThead,
  TableItemText,
  TableTitleText,
  TableScrollableDiv,
  TableContainer,
  TopContainer,
  Search,
  SearchIcon,
  SearchInputContainer,
  StyledTableItem,
  StyledGestioneTh,
  StyledTdGestione,
} from "../cybersecurity-board-contestoleadership-organizzazione-sub/cybersecurity-board-organizzazione-unitaoperative-styled-components";

import { useRecoilState, useRecoilValue } from "recoil";

import {
  SelectedL1ID,
  SelectedL2ID,
  SelectedL3ID,
  SelectedL4ID,
  selectedItemsAsset,
  addL2OrganizzazioneDirettore,
  addL2OrganizzazioneDirettoreID,
  addL2OrganizzazioneSelectedL1,
  addL2OrganizzazioneSelectedL1ID,
} from "../../../../recoil";
import { URL_LV2List, useToken } from "../../../../API";

interface DataItem {
  id: string;
  name: string;
  responsible_name: string;
  responsible_id: string;
  site_name: string;
  site_id: string;
  email: string;
  phone: string;
  responsible_role_id: string;
  responsible_role: string;
}

type SortableKeys = keyof DataItem;

type ApiContentItem = {
  id: string;
  name: string;
  responsible_name: string;
  responsible_id: string;
  site_name: string;
  site_id: string;
  email: string;
  phone: string;
  responsible_role_id: string;
  responsible_role: string;
};

type StyledTrProps = {
  selected: boolean;
};

const StyledTr = styled.tr<StyledTrProps>`
  cursor: pointer;
  background-color: ${(props) => {
    return props.selected ? "#f3f7fb" : "#ffffff";
  }};

  &:hover {
    background-color: #e9eff7;
  }
`;

interface OverlayComponentProps {
  isAnimating: boolean;
}

const OverlayComponentAdd1 = styled.div<OverlayComponentProps>`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  z-index: 10;
  transition: opacity 300ms ease-in-out, visibility 300ms ease-in-out;
  opacity: ${(props) => (props.isAnimating ? "1" : "0")};
  visibility: ${(props) => (props.isAnimating ? "visible" : "hidden")};
`;

const DataTable: React.FC<{ data: DataItem[] }> = ({ data }) => {
  const [selectedAssets, setSelectedAssets] = useRecoilState(selectedItemsAsset);

  const navigate = useNavigate();

  const [isOverlayVisibleAdd2, setIsOverlayVisibleAdd2] = useState(false);
  const [isOverlayAnimatingAdd2, setIsOverlayAnimatingAdd2] = useState(false);

  const toggleOverlayAdd2 = () => {
    if (!isOverlayVisibleAdd2) {
      if (window.location.pathname === "/Cybersecurity/GestioneCompliance") {
        navigate(`GestioneCompliance/CreaTopManagement/1`);
      } else {
        navigate(`${window.location.pathname}/CreaTopManagement/1`);
      }

      setIsOverlayVisibleAdd2(true);
      setTimeout(() => setIsOverlayAnimatingAdd2(true), 10);
    } else {
      setIsOverlayAnimatingAdd2(false);
      setTimeout(() => setIsOverlayVisibleAdd2(false), 310);
    }
  };

  const closeOverlayAdd2 = () => {
    setSelectedAssets([]);
    setDirector("");
    setDirectorId("");
    setL1List("");
    setL1ListID("");
    navigate(`/Cybersecurity/GestioneCompliance/TopManagement`);
    setIsOverlayAnimatingAdd2(false);

    setTimeout(() => {
      setIsOverlayVisibleAdd2(false);
    }, 300);
  };

  const [selectedL1, setSelectedL1] = useState<string | null>(null);
  const [selectedL2, setSelectedL2] = useState<string | null>(null);
  const [selectedL3, setSelectedL3] = useState<string | null>(null);
  const [selectedL4, setSelectedL4] = useState<string | null>(null);

  const [L1ID, setL1ID] = useRecoilState(SelectedL1ID);
  const [L2ID, setL2ID] = useRecoilState(SelectedL2ID);
  const [L3ID, setL3ID] = useRecoilState(SelectedL3ID);
  const [L4ID, setL4ID] = useRecoilState(SelectedL4ID);
  const [director, setDirector] = useRecoilState(addL2OrganizzazioneDirettore);
  const [directorId, setDirectorId] = useRecoilState(addL2OrganizzazioneDirettoreID);
  const [L1List, setL1List] = useRecoilState(addL2OrganizzazioneSelectedL1);
  const [L1ListID, setL1ListID] = useRecoilState(addL2OrganizzazioneSelectedL1ID);

  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const [isMessageBoxVisible, setIsMessageBoxVisible] = useState(false);

  const [sortConfig, setSortConfig] = useState<{ key: SortableKeys; direction: "ascending" | "descending" } | null>(null);

  const sortedData = useMemo(() => {
    let sortableItems = [...data];
    if (sortConfig !== null) {
      sortableItems.sort((a, b) => {
        const aValue = a[sortConfig.key];
        const bValue = b[sortConfig.key];

        if (typeof aValue === "string" && typeof bValue === "string") {
          return sortConfig.direction === "ascending" ? aValue.localeCompare(bValue) : bValue.localeCompare(aValue);
        }

        return 0;
      });
    }
    return sortableItems;
  }, [data, sortConfig]);

  const requestSort = (key: SortableKeys) => {
    let direction: "ascending" | "descending" = "ascending";
    if (sortConfig && sortConfig.key === key && sortConfig.direction === "ascending") {
      direction = "descending";
    }
    setSortConfig({ key, direction });
  };

  const [selectedId, setSelectedId] = useState<string | null>(null);

  const handleRowSelect = (id: string) => {
    setSelectedId((prevId) => {
      return id;
    });

    setL2ID(id);
  };

  useEffect(() => {
    console.log("L2ID now has the value:", L2ID);
  }, [L2ID]);

  const [globalFilterText, setGlobalFilterText] = useState("");

  const handleGlobalFilterChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setGlobalFilterText(event.target.value);
  };

  const [filterText, setFilterText] = useState("");

  const handleFilterChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setFilterText(event.target.value);
  };

  const filteredAndSortedData = useMemo(() => {
    const filteredData = data
      .filter((item) => {
        return item.site_name.toLowerCase().includes(filterText.toLowerCase());
      })
      .filter((item) => {
        if (globalFilterText) {
          const allText = Object.values(item).join(" ").toLowerCase();
          return allText.includes(globalFilterText.toLowerCase());
        }
        return true;
      });

    if (sortConfig !== null) {
      filteredData.sort((a, b) => {
        const aValue = a[sortConfig.key];
        const bValue = b[sortConfig.key];

        if (typeof aValue === "string" && typeof bValue === "string") {
          return sortConfig.direction === "ascending" ? aValue.localeCompare(bValue) : bValue.localeCompare(aValue);
        }
        return 0;
      });
    }

    return filteredData;
  }, [data, sortConfig, filterText, globalFilterText]);

  return (
    <TableContainer>
      <TopContainer>
        <SearchInputContainer>
          <Search placeholder="Filtra per componente" onChange={handleFilterChange} />
          <SearchIcon src="/funnel.svg" />
        </SearchInputContainer>
        <SearchInputContainer>
          <Search placeholder="Cerca in tutta la tabella" onChange={handleGlobalFilterChange} />
          <SearchIcon src="/search.svg" />
        </SearchInputContainer>

        {/* <IconsGroup>
        <IconButton>
            <BackgroundIcon loading="eager" alt="" src="/doc.svg" />
          </IconButton>
          <IconButton>
            <BackgroundIcon loading="eager" alt="" src="/edit.svg" />
          </IconButton>
          <IconButton>
            <BackgroundIcon loading="eager" alt="" src="/delete.svg" />
          </IconButton>
          <IconButton>
          <BackgroundIcon
            loading="eager"
            alt=""
            src="/add.svg"
            onClick={toggleOverlayAdd2}  
          />
          </IconButton>
        </IconsGroup> */}
      </TopContainer>

      <StyledTable>
        <StyledThead>
          <StyledTh onClick={() => requestSort("site_name")}>
            <TableTitleText>▼ Comp. organizzativa</TableTitleText>
          </StyledTh>
          <StyledGestioneTh onClick={() => requestSort("name")}>
            <TableTitleText>▼ Unità operativa</TableTitleText>
          </StyledGestioneTh>
          <StyledTh onClick={() => requestSort("responsible_role_id")}>
            <TableTitleText>▼ Ruolo</TableTitleText>
          </StyledTh>
          <StyledTh onClick={() => requestSort("responsible_name")}>
            <TableTitleText>▼ Nome </TableTitleText>
          </StyledTh>
          <StyledTh onClick={() => requestSort("phone")}>
            <TableTitleText>▼ Telefono</TableTitleText>
          </StyledTh>
          <StyledTh onClick={() => requestSort("email")}>
            <TableTitleText>▼ Email</TableTitleText>
          </StyledTh>
        </StyledThead>
      </StyledTable>
      <TableScrollableDiv>
        <StyledTableItem>
          <StyledTbody>
            {filteredAndSortedData.map((item) => (
              <StyledTr key={item.id} onClick={() => handleRowSelect(item.id)} selected={selectedId === item.id}>
                <StyledTd title={item.site_name}>
                  <TableItemText>{item.site_name}</TableItemText>
                </StyledTd>
                <StyledTdGestione title={item.name}>
                  <TableItemText>{item.name}</TableItemText>
                </StyledTdGestione>
                <StyledTd title={item.responsible_role_id}>
                  <TableItemText>{item.responsible_role_id}</TableItemText>
                </StyledTd>
                <StyledTd title={item.responsible_name}>
                  <TableItemText>{item.responsible_name}</TableItemText>
                </StyledTd>
                <StyledTd title={item.phone}>
                  <TableItemText>{item.phone}</TableItemText>
                </StyledTd>
                <StyledTd title={item.email}>
                  <TableItemText>{item.email}</TableItemText>
                </StyledTd>
              </StyledTr>
            ))}
          </StyledTbody>
        </StyledTableItem>
      </TableScrollableDiv>

      <OverlayContext.Provider value={{ closeOverlay: closeOverlayAdd2, selectedL1, setSelectedL1, selectedL2, setSelectedL2, selectedL3, setSelectedL3, selectedL4, setSelectedL4 }}>
        {isOverlayVisibleAdd2 && (
          <OverlayComponentAdd1 isAnimating={isOverlayAnimatingAdd2}>
            {/* <ButtonClose src="/close.svg" onClick={closeOverlayAdd2} /> */}
            <FadeOutComponent>
              <FrameTopGradient100></FrameTopGradient100>
            </FadeOutComponent>

            <Outlet />
          </OverlayComponentAdd1>
        )}
      </OverlayContext.Provider>
    </TableContainer>
  );
};

const Board27001Dashboard: React.FC = () => {
  const token = useToken();
  const [L1ID, setL1ID] = useRecoilState(SelectedL1ID);
  const [L2ID, setL2ID] = useRecoilState(SelectedL2ID);
  const [L3ID, setL3ID] = useRecoilState(SelectedL3ID);
  const [L4ID, setL4ID] = useRecoilState(SelectedL4ID);

  const [data, setData] = useState<DataItem[]>([]);

  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const [isMessageBoxVisible, setIsMessageBoxVisible] = useState(false);

  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true);
      try {
        if (!token) throw new Error("User is not authenticated");

        const response = await fetch(URL_LV2List, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });

        if (!response.ok) {
          throw new Error(`API request failed: ${response.statusText}`);
        }

        const responseData = await response.json();
        console.log("Response Data:", responseData);

        const transformedData: DataItem[] = responseData.map((item: ApiContentItem) => {
          return {
            id: item.id,
            name: item.name,
            responsible_name: item.responsible_name,
            responsible_id: item.responsible_id,
            site_name: item.site_name,
            site_id: item.site_id,
            email: item.email,
            phone: item.phone,
            responsible_role_id: item.responsible_role_id,
            responsible_role: item.responsible_role,
          };
        });

        console.log("Transformed Data:", transformedData);
        setData(transformedData);
      } catch (err) {
        setError(err instanceof Error ? err.message : "An unknown error occurred");
        setIsMessageBoxVisible(true);
      } finally {
        setIsLoading(false);
      }
    };

    fetchData();
  }, [token]);

  return (
    <div>
      {isMessageBoxVisible && (
        <Backdrop>
          <MessageContainer>
            <p>
              <MessageboxText>{error}</MessageboxText>
            </p>
            <CloseMessageButton onClick={() => setIsMessageBoxVisible(false)}>
              <MessageboxText>Close</MessageboxText>
            </CloseMessageButton>
          </MessageContainer>
        </Backdrop>
      )}

      {isLoading && (
        <BackdropTransparent>
          <MessageContainer>
            <MessageboxText>Loading...</MessageboxText>
          </MessageContainer>
        </BackdropTransparent>
      )}

      {error && (
        <Backdrop>
          <MessageContainer>
            <p>
              <MessageboxText>{error}</MessageboxText>
            </p>
            <CloseMessageButton
              onClick={() => {
                setIsMessageBoxVisible(false);
                setError(null);
              }}
            >
              <MessageboxText>Close</MessageboxText>
            </CloseMessageButton>
          </MessageContainer>
        </Backdrop>
      )}

      <DataTable data={data} />
    </div>
  );
};

export default Board27001Dashboard;
