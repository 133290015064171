import React, { useCallback, useEffect, useMemo, useState, } from 'react';
import { Outlet, useNavigate } from "react-router-dom";
import { useRecoilState } from 'recoil';
import styled from 'styled-components';
import { URL_AdempimentiList, URL_Obligations, useApi, useToken } from '../../../../API';
import {
  SelectedL1ID,
  SelectedL2ID,
  SelectedL3ID,
  SelectedL4ID,
  addL2OrganizzazioneDirettore,
  addL2OrganizzazioneDirettoreID,
  addL2OrganizzazioneSelectedL1,
  addL2OrganizzazioneSelectedL1ID,
  reFetchIndicatorObligations,
  selectedItemsAsset,
} from '../../../../recoil';
import { OverlayContext } from '../cybersecurity-board-contestoleadership-organizzazione';
import { Search, SearchIcon, SearchInputContainer, StyledTable, StyledTableItem, StyledTbody, StyledTd, StyledTh, StyledThead, TableContainer, TableItemText, TableScrollableDiv, TableTitleText, TopContainer } from "../cybersecurity-board-contestoleadership-organizzazione-sub/cybersecurity-board-organizzazione-unitaoperative-styled-components";
import { AdempimentiDeleteConfirmationDialog } from './Adempimenti-Add-recoil';
import { Backdrop, BackdropTransparent, BackgroundIcon, CloseMessageButton, FadeOutComponent, FrameTopGradient100, IconButton, IconsGroup, MessageContainer, MessageboxText, } from "./cybersecurity-board-gestionecompliance-organigramma-styled-components";

interface DataItem {
  id: string;
  name: string;
  description: string;
  normRef: string;
  note: string;

}

type SortableKeys = keyof DataItem;

type ApiContentItem = {
  id: string;
  name: string;
  description: string;
  normRef: string;
  note: string;

};

type StyledTrProps = {
  selected: boolean;
}

const StyledTr = styled.tr<StyledTrProps>`
  cursor: pointer;
  background-color: ${props => {

    return props.selected ? '#f3f7fb' : '#ffffff';
  }};

  &:hover {
    background-color: #e9eff7;  
  }
`;

interface OverlayComponentProps {
  isAnimating: boolean;
}


const OverlayComponentAdd1 = styled.div<OverlayComponentProps>`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  z-index: 10;
  transition: opacity 300ms ease-in-out, visibility 300ms ease-in-out;
  opacity: ${(props) => (props.isAnimating ? "1" : "0")};
  visibility: ${(props) => (props.isAnimating ? "visible" : "hidden")};
`;



const useDeleteObligation = () => {

  const { del } = useApi();
  const [reFetchIndicator, setRefetchIndicator] = useRecoilState(reFetchIndicatorObligations);
  const [isConfirmDialogVisible, setIsConfirmDialogVisible] = useRecoilState(AdempimentiDeleteConfirmationDialog);
  const [L2ID, setL2ID] = useRecoilState(SelectedL2ID);

  const handleDelete = useCallback(async () => {
    if (!L2ID) return;

    const url = URL_Obligations;
    try {
      await del(url, L2ID);
      console.log(`Deleted obligation with ID: ${L2ID}`);

    } catch (error) {
      console.error('Failed to delete obligation:', error);
    } finally {
      setIsConfirmDialogVisible(false);
      setRefetchIndicator(prev => prev + 1);
    }
  }, [L2ID, del, setRefetchIndicator, setIsConfirmDialogVisible]);

  const showDeleteConfirmation = useCallback(() => {
    setIsConfirmDialogVisible(true);
  }, []);

  return { handleDelete, showDeleteConfirmation, isConfirmDialogVisible, setIsConfirmDialogVisible };
};





const DataTable: React.FC<{ data: DataItem[] }> = ({ data }) => {





  const [selectedAssets, setSelectedAssets] = useRecoilState(selectedItemsAsset);



  const navigate = useNavigate();

  const [isOverlayVisibleAdd2, setIsOverlayVisibleAdd2] = useState(false);
  const [isOverlayAnimatingAdd2, setIsOverlayAnimatingAdd2] = useState(false);

  const toggleOverlayAdd2 = () => {
    if (!isOverlayVisibleAdd2) {

      if (window.location.pathname === "/Cybersecurity/GestioneCompliance") {
        navigate(`GestioneCompliance/CreaAdempimenti/1`);
      } else {
        navigate(`${window.location.pathname}/CreaAdempimenti/1`);
      }

      setIsOverlayVisibleAdd2(true);
      setTimeout(() => setIsOverlayAnimatingAdd2(true), 10);
    } else {
      setIsOverlayAnimatingAdd2(false);
      setTimeout(() => setIsOverlayVisibleAdd2(false), 310);
    }
  };

  const closeOverlayAdd2 = () => {
    setSelectedAssets([]);
    setDirector('');
    setDirectorId('');
    setL1List('');
    setL1ListID('');
    navigate(`/Cybersecurity/GestioneCompliance/Adempimenti`);
    setIsOverlayAnimatingAdd2(false);

    setTimeout(() => {
      setIsOverlayVisibleAdd2(false);
    }, 300);
  };

  const [selectedL1, setSelectedL1] = useState<string | null>(null);
  const [selectedL2, setSelectedL2] = useState<string | null>(null);
  const [selectedL3, setSelectedL3] = useState<string | null>(null);
  const [selectedL4, setSelectedL4] = useState<string | null>(null);
  const [L1ID, setL1ID] = useRecoilState(SelectedL1ID);
  const [L2ID, setL2ID] = useRecoilState(SelectedL2ID);
  const [L3ID, setL3ID] = useRecoilState(SelectedL3ID);
  const [L4ID, setL4ID] = useRecoilState(SelectedL4ID);
  const [director, setDirector] = useRecoilState(addL2OrganizzazioneDirettore);
  const [directorId, setDirectorId] = useRecoilState(addL2OrganizzazioneDirettoreID);
  const [L1List, setL1List] = useRecoilState(addL2OrganizzazioneSelectedL1);
  const [L1ListID, setL1ListID] = useRecoilState(addL2OrganizzazioneSelectedL1ID);

  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const [isMessageBoxVisible, setIsMessageBoxVisible] = useState(false);

  const [sortConfig, setSortConfig] = useState<{ key: SortableKeys; direction: 'ascending' | 'descending' } | null>(null);

  const sortedData = useMemo(() => {
    let sortableItems = [...data];
    if (sortConfig !== null) {
      sortableItems.sort((a, b) => {
        const aValue = a[sortConfig.key];
        const bValue = b[sortConfig.key];

        if (typeof aValue === 'string' && typeof bValue === 'string') {
          return sortConfig.direction === 'ascending' ? aValue.localeCompare(bValue) : bValue.localeCompare(aValue);
        }
        return 0;
      });
    }
    return sortableItems;
  }, [data, sortConfig]);

  const requestSort = (key: SortableKeys) => {
    let direction: 'ascending' | 'descending' = 'ascending';
    if (sortConfig && sortConfig.key === key && sortConfig.direction === 'ascending') {
      direction = 'descending';
    }
    setSortConfig({ key, direction });
  };

  const [selectedId, setSelectedId] = useState<string | null>(null);
  const handleRowSelect = (id: string) => {
    setSelectedId(prevId => {

      return id;
    });

    setL2ID(id);

  };

  useEffect(() => {

    console.log("L2ID now has the value:", L2ID);

  }, [L2ID]);

  const [globalFilterText, setGlobalFilterText] = useState('');

  const handleGlobalFilterChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setGlobalFilterText(event.target.value);
  };

  const [filterText, setFilterText] = useState('');

  const handleFilterChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setFilterText(event.target.value);
  };

  const filteredAndSortedData = useMemo(() => {
    const filteredData = data
      .filter(item => {
        return item.normRef.toLowerCase().includes(filterText.toLowerCase());
      })
      .filter(item => {

        if (globalFilterText) {
          const allText = Object.values(item).join(' ').toLowerCase();
          return allText.includes(globalFilterText.toLowerCase());
        }
        return true;
      });

    if (sortConfig !== null) {
      filteredData.sort((a, b) => {
        const aValue = a[sortConfig.key];
        const bValue = b[sortConfig.key];

        if (typeof aValue === 'string' && typeof bValue === 'string') {
          return sortConfig.direction === 'ascending' ? aValue.localeCompare(bValue) : bValue.localeCompare(aValue);
        }
        return 0;
      });
    }

    return filteredData;
  }, [data, sortConfig, filterText, globalFilterText]);

  const { handleDelete, showDeleteConfirmation, isConfirmDialogVisible, setIsConfirmDialogVisible } = useDeleteObligation();

  return (
    <TableContainer>
      <TopContainer>
        <SearchInputContainer>
          <Search placeholder='Filtra per riferimento' onChange={handleFilterChange} />
          <SearchIcon src="/funnel.svg" />
        </SearchInputContainer>
        <SearchInputContainer>
          <Search placeholder='Cerca in tutta la tabella' onChange={handleGlobalFilterChange} />
          <SearchIcon src="/search.svg" />
        </SearchInputContainer>

        <IconsGroup>
          {/* <IconButton>
            <BackgroundIcon loading="eager" alt="" src="/doc.svg" />
          </IconButton>
          <IconButton>
            <BackgroundIcon loading="eager" alt="" src="/edit.svg" />
          </IconButton> */}
          <IconButton onClick={() => showDeleteConfirmation()}>
            <BackgroundIcon loading="eager" alt="" src="/delete.svg" />
          </IconButton>
          <IconButton>
            <BackgroundIcon
              loading="eager"
              alt=""
              src="/add.svg"
              onClick={toggleOverlayAdd2}
            />
          </IconButton>
        </IconsGroup>
      </TopContainer>

      <StyledTable>
        <StyledThead>

          <StyledTh onClick={() => requestSort('normRef')}><TableTitleText>▼ Rif. normativa</TableTitleText></StyledTh>
          <StyledTh onClick={() => requestSort('name')}><TableTitleText>▼ Categoria</TableTitleText></StyledTh>
          <StyledTh onClick={() => requestSort('description')}><TableTitleText>▼ Descrizione</TableTitleText></StyledTh>
          {/* <StyledTh onClick={() => requestSort('note')}><TableTitleText>▼ Nota</TableTitleText></StyledTh>
      
        */}

        </StyledThead>
      </StyledTable>
      <TableScrollableDiv>
        <StyledTableItem>
          <StyledTbody>

            {filteredAndSortedData.map(item => (
              <StyledTr
                key={item.id}
                onClick={() => handleRowSelect(item.id)}
                selected={selectedId === item.id}
              >
                <StyledTd title={item.normRef}><TableItemText>{item.normRef}</TableItemText></StyledTd>
                <StyledTd title={item.name}><TableItemText>{item.name}</TableItemText></StyledTd>
                <StyledTd title={item.description}><TableItemText>{item.description}</TableItemText></StyledTd>
                {/* <StyledTd><TableItemText>{item.note}</TableItemText></StyledTd>
            
              */}
              </StyledTr>
            ))}

          </StyledTbody>

        </StyledTableItem>
      </TableScrollableDiv>

      <OverlayContext.Provider value={{ closeOverlay: closeOverlayAdd2, selectedL1, setSelectedL1, selectedL2, setSelectedL2, selectedL3, setSelectedL3, selectedL4, setSelectedL4 }}>

        {isOverlayVisibleAdd2 && (
          <OverlayComponentAdd1 isAnimating={isOverlayAnimatingAdd2}>
            {/* <ButtonClose src="/close.svg" onClick={closeOverlayAdd2} /> */}
            <FadeOutComponent><FrameTopGradient100></FrameTopGradient100></FadeOutComponent>

            <Outlet />
          </OverlayComponentAdd1>
        )}
      </OverlayContext.Provider>

    </TableContainer>
  );
};

const Board27001Dashboard: React.FC = () => {

  const token = useToken();
  const [L1ID, setL1ID] = useRecoilState(SelectedL1ID);
  const [L2ID, setL2ID] = useRecoilState(SelectedL2ID);
  const [L3ID, setL3ID] = useRecoilState(SelectedL3ID);
  const [L4ID, setL4ID] = useRecoilState(SelectedL4ID);
  const [data, setData] = useState<DataItem[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const [isMessageBoxVisible, setIsMessageBoxVisible] = useState(false);
  const [reFetchIndicator, setRefetchIndicator] = useRecoilState(reFetchIndicatorObligations);

  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true);
      try {

        if (!token) throw new Error("User is not authenticated");

        const response = await fetch(URL_AdempimentiList, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });

        if (!response.ok) {
          throw new Error(`API request failed: ${response.statusText}`);
        }

        const responseData = await response.json();
        console.log("Response Data:", responseData);
        const transformedData: DataItem[] = responseData.map((item: ApiContentItem) => {
          return {
            id: item.id,
            normRef: item.normRef,
            name: item.name,
            description: item.description,
            note: item.note,


          };
        });

        console.log("Transformed Data:", transformedData);
        setData(transformedData);

      } catch (err) {

        setError(err instanceof Error ? err.message : 'An unknown error occurred');
        setIsMessageBoxVisible(true);
      } finally {
        setIsLoading(false);
      }
    };

    fetchData();
  }, [token, reFetchIndicator]);

  const { handleDelete, showDeleteConfirmation, isConfirmDialogVisible, setIsConfirmDialogVisible } = useDeleteObligation();
  return (

    <div>

      {
        isMessageBoxVisible && (
          <Backdrop>
            <MessageContainer>
              <p><MessageboxText>{error}</MessageboxText></p>
              <CloseMessageButton onClick={() => setIsMessageBoxVisible(false)}>
                <MessageboxText>Close</MessageboxText>
              </CloseMessageButton>
            </MessageContainer>
          </Backdrop>
        )
      }

      {isLoading && (
        <BackdropTransparent>
          <MessageContainer>
            <MessageboxText>Loading...</MessageboxText>
          </MessageContainer>
        </BackdropTransparent>
      )}

      {error && (
        <Backdrop>
          <MessageContainer>
            <p>
              <MessageboxText>{error}</MessageboxText>
            </p>
            <CloseMessageButton
              onClick={() => {
                setIsMessageBoxVisible(false);
                setError(null);
              }}
            >
              <MessageboxText>Close</MessageboxText>
            </CloseMessageButton>
          </MessageContainer>
        </Backdrop>
      )}

      {
        isConfirmDialogVisible && (
          <Backdrop>
            <MessageContainer>
              <p><MessageboxText>Sei sicuro di voler rimuovere questo adempimento?</MessageboxText></p>
              <div style={{ display: 'flex', justifyContent: 'center', gap: '20px' }}>
                <CloseMessageButton onClick={() => setIsConfirmDialogVisible(false)}>
                  <MessageboxText>No</MessageboxText>
                </CloseMessageButton>
                <CloseMessageButton onClick={handleDelete}>
                  <MessageboxText>Rimuovi</MessageboxText>
                </CloseMessageButton>
              </div>
            </MessageContainer>
          </Backdrop>
        )
      }

      <DataTable data={data} />

    </div>
  );
};

export default Board27001Dashboard;