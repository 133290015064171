import { DatePicker } from "@mui/x-date-pickers";
import dayjs from "dayjs";
import React, { RefObject, useCallback, useContext, useEffect, useRef, useState } from "react";
import { OverlayContext } from "../../cybersecurity-board-contestoleadership-organizzazione";

import CancelIcon from "@mui/icons-material/Cancel";
import { useRecoilState } from "recoil";
import { SelectedL1ID, isLoadingRec, isMessageBoxVisibleRec, setErrorRec, setMessageRec } from "../../../../../recoil";
import {
  Backdrop,
  BackdropTransparent,
  CloseMessageButton,
  CreaContainerColumn,
  CustomDropdownItem,
  CustomDropdownList,
  DescriptionContainerCreaMultiple,
  DescriptionContainerCreaText,
  DropDownMenuScrollbar,
  FlexContainerCrea,
  IconContainer,
  IconSquare,
  InputContainer,
  InputDescriptionText,
  InputTextbox,
  MessageContainer,
  MessageboxText,
  OverlayComponentWhiteAdd1Multiple,
  TriangleContainer,
} from "../../cybersecurity-board-contestoleadership-organizzazione-sub/cybersecurity-board-organizzazione-organigramma-styled-components";
import { LuoghiComplianceDate, addLuoghiOrganizzazioneDirettoreCompliance, addLuoghiOrganizzazioneDirettoreComplianceID, addLuoghi_2_isDropdownOpenDirectorsCompliance } from "../Luoghi-Add-recoil";

import { URL_DirectorList, URL_LV1List, useToken } from "../../../../../API";
import { TAB_DESCRIPTION_GLOBAL, TAB_DESCRIPTION_LUOGHI_6 } from "../../tabs-description";
import {
  DirectorDataItem,
  LV1DataItem,
  LV1DataItemState,
  LuoghiOrganizzazioneSelectedL1,
  LuoghiOrganizzazioneSelectedL1ID,
  addLuoghiConfirmedLV1List,
  addLuoghi_2_isDropdownOpenLV1,
  directorDataState,
} from "../Luoghi-Add-recoil";

type DirectorApiContentItem = {
  id: string;
  alias: string;
};

interface SearchInputProps {
  value: string;
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
}

type LV1ApiContentItem = {
  id: string;
  alias: string;
};

const useHandleCloseAndNavigate = () => {
  const [, setIsMessageBoxVisible] = useRecoilState(isMessageBoxVisibleRec);
  const { closeOverlay } = useContext(OverlayContext);
  const handleCloseAndNavigate = () => {
    setIsMessageBoxVisible(false);
  };

  return handleCloseAndNavigate;
};

const useFetchDataLv1 = (token: string | undefined | null) => {
  const [dataLV1, setDataLV1] = useRecoilState(LV1DataItemState);
  const [isLoading, setIsLoading] = useRecoilState(isLoadingRec);
  const [error, setError] = useRecoilState(setErrorRec);
  const [isMessageBoxVisible, setIsMessageBoxVisible] = useRecoilState(isMessageBoxVisibleRec);
  const [message, setMessage] = useRecoilState(setMessageRec);

  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true);

      try {
        if (!token) throw new Error("User is not authenticated");

        const response = await fetch(URL_LV1List, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });

        if (!response.ok) {
          throw new Error(`API request failed: ${response.statusText}`);
        }

        const responseData = await response.json();
        console.log("Response dataLV1:", responseData);

        const contentArray = responseData.content;

        if (!Array.isArray(contentArray)) {
          throw new Error("Expected content to be an array");
        }

        const transformedData: LV1DataItem[] = contentArray.map((item: LV1ApiContentItem) => {
          return {
            LV1_id: item.id,
            LV1_name: item.alias,
          };
        });

        console.log("Transformed dataLV1:", transformedData);
        setDataLV1(transformedData);
      } catch (err) {
        setError(err instanceof Error ? err.message : "An unknown error occurred");
        setIsMessageBoxVisible(true);
      } finally {
        setIsLoading(false);
      }
    };

    fetchData();
  }, [token]);

  return dataLV1;
};

const useFetchDirectorData = (token: string | null | undefined) => {
  const [dataDirector, setDataDirector] = useRecoilState(directorDataState);

  const [isLoading, setIsLoading] = useRecoilState(isLoadingRec);
  const [error, setError] = useRecoilState(setErrorRec);
  const [isMessageBoxVisible, setIsMessageBoxVisible] = useRecoilState(isMessageBoxVisibleRec);
  const [message, setMessage] = useRecoilState(setMessageRec);

  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true);
      try {
        if (!token) throw new Error("User is not authenticated");

        const response = await fetch(URL_DirectorList, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });

        if (!response.ok) {
          throw new Error(`API request failed: ${response.statusText}`);
        }

        const responseData = await response.json();
        console.log("Response Data:", responseData);

        const transformedData: DirectorDataItem[] = responseData.map((item: DirectorApiContentItem) => {
          return {
            director_id: item.id,
            director_name: item.alias,
          };
        });

        console.log("Transformed Data:", transformedData);
        setDataDirector(transformedData);
      } catch (err) {
        setError(err instanceof Error ? err.message : "An unknown error occurred");
        setIsMessageBoxVisible(true);
      } finally {
        setIsLoading(false);
      }
    };
    fetchData();
  }, [token]);
};

const useHandleLV1Change = () => {
  const [LV1List, setLV1List] = useRecoilState(LuoghiOrganizzazioneSelectedL1);

  return useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      setLV1List(event.target.value);
    },
    [setLV1List]
  );
};

export const useDropdownToggleLV1 = () => {
  const [isDropdownOpenLV1, setIsDropdownOpenLV1] = useRecoilState(addLuoghi_2_isDropdownOpenLV1);
  const [, setConfirmedLV1List] = useRecoilState(addLuoghiConfirmedLV1List);
  const [LV1List] = useRecoilState(LuoghiOrganizzazioneSelectedL1);

  const handleDropdownToggleLV1 = () => {
    if (!isDropdownOpenLV1) {
      setConfirmedLV1List(LV1List);
    }
    setIsDropdownOpenLV1(!isDropdownOpenLV1);
  };

  return handleDropdownToggleLV1;
};

const useHandleOptionSelectLV1 = (data: LV1DataItem[]) => {
  const [dataLV1, setDataLV1] = useRecoilState(LV1DataItemState);
  const [LV1List, setLV1List] = useRecoilState(LuoghiOrganizzazioneSelectedL1);
  const handleDropdownToggleLV1 = useDropdownToggleLV1();
  const [confirmedLV1List, setConfirmedLV1List] = useRecoilState(addLuoghiConfirmedLV1List);
  const [LV1ListId, setLV1ListId] = useRecoilState(LuoghiOrganizzazioneSelectedL1ID);
  const [L1ID, setL1ID] = useRecoilState(SelectedL1ID);
  const [isDropdownOpenLV1, setIsDropdownOpenLV1] = useRecoilState(addLuoghi_2_isDropdownOpenLV1);

  const handleOptionSelectLV1 = useCallback(
    (selectedLV1Id: string) => {
      console.log(`Handle LV1 ID: ${selectedLV1Id}`);
      console.log("Selected ID:", selectedLV1Id);
      console.log("DataLV1:", dataLV1);
      const selectedLV1Name = dataLV1.find((item) => item.LV1_id === selectedLV1Id)?.LV1_name;

      if (selectedLV1Name) {
        setLV1List(selectedLV1Name);
        setConfirmedLV1List(selectedLV1Name);
        setLV1ListId(selectedLV1Id);
        setL1ID(selectedLV1Id);
        setIsDropdownOpenLV1(false);
      }
    },
    [dataLV1]
  );

  return handleOptionSelectLV1;
};

const useOutsideClickLV1 = (ref: RefObject<HTMLDivElement>) => {
  const [LV1List, setLV1List] = useRecoilState(LuoghiOrganizzazioneSelectedL1);
  const [isDropdownOpenLV1, setIsDropdownOpenLV1] = useRecoilState(addLuoghi_2_isDropdownOpenLV1);
  const [confirmedLV1List, setConfirmedLV1List] = useRecoilState(addLuoghiConfirmedLV1List);

  useEffect(() => {
    function handleClickOutside(event: MouseEvent) {
      if (ref.current && !ref.current.contains(event.target as Node)) {
        if (isDropdownOpenLV1) {
          setLV1List(confirmedLV1List);
          setIsDropdownOpenLV1(false);
        }
      }
    }

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [ref, confirmedLV1List, isDropdownOpenLV1, setLV1List, setIsDropdownOpenLV1]);
};

const useHandleOptionSelectDirector = () => {
  const [isDropdownOpen, setIsDropdownOpen] = useRecoilState(addLuoghi_2_isDropdownOpenDirectorsCompliance);
  const [dataDirector] = useRecoilState(directorDataState);
  const [, setDirector] = useRecoilState(addLuoghiOrganizzazioneDirettoreCompliance);
  const [, setDirectorId] = useRecoilState(addLuoghiOrganizzazioneDirettoreComplianceID);

  const handleSelectDirector = useCallback(
    (selectedDirectorId: string) => {
      const selectedDirector = dataDirector.find((director) => director.director_id === selectedDirectorId);

      if (selectedDirector) {
        setDirector(selectedDirector.director_name);
        setDirectorId(selectedDirector.director_id);
        setIsDropdownOpen(false);
      }
    },
    [dataDirector, setDirector, setDirectorId, setIsDropdownOpen]
  );

  return handleSelectDirector;
};

const useHandleDirectorChange = () => {
  const [dataDirector] = useRecoilState(directorDataState);
  const [, setDirector] = useRecoilState(addLuoghiOrganizzazioneDirettoreCompliance);
  const [, setDirectorId] = useRecoilState(addLuoghiOrganizzazioneDirettoreComplianceID);

  const handleDirectorChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      const value = event.target.value;

      const directorNameToId = new Map(dataDirector.map((item) => [item.director_name, item.director_id]));

      setDirector(value);

      if (directorNameToId.has(value)) {
        setDirectorId(directorNameToId.get(value) || "");
      }
    },
    [dataDirector, setDirector, setDirectorId]
  );

  return handleDirectorChange;
};

const useDropdownToggleDirectors = () => {
  const [, setDirector] = useRecoilState(addLuoghiOrganizzazioneDirettoreCompliance);
  const [, setDirectorId] = useRecoilState(addLuoghiOrganizzazioneDirettoreComplianceID);
  const [isDropdownOpen, setIsDropdownOpen] = useRecoilState(addLuoghi_2_isDropdownOpenDirectorsCompliance);

  const handleDropdownToggleDirector = useCallback(() => {
    setDirector("");
    setDirectorId("");
    setIsDropdownOpen(!isDropdownOpen);
  }, [isDropdownOpen, setDirector, setDirectorId, setIsDropdownOpen]);

  return handleDropdownToggleDirector;
};

const useOutsideClickDirectors = (ref: RefObject<HTMLDivElement>) => {
  const [isDropdownOpenDirectors, setIsDropdownOpenDirectors] = useRecoilState(addLuoghi_2_isDropdownOpenDirectorsCompliance);

  useEffect(() => {
    function handleClickOutside(event: MouseEvent) {
      if (ref.current && !ref.current.contains(event.target as Node)) {
        if (isDropdownOpenDirectors) {
          setIsDropdownOpenDirectors(false);
        }
      }
    }

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [isDropdownOpenDirectors, setIsDropdownOpenDirectors /* confirmedDirector, setDirector */]);
};

const useClearLV2 = () => {
  const [, setL1ID] = useRecoilState(SelectedL1ID);
  const [, setDirector] = useRecoilState(addLuoghiOrganizzazioneDirettoreCompliance);
  const [, setDirectorId] = useRecoilState(addLuoghiOrganizzazioneDirettoreComplianceID);

  const clearLV2 = useCallback(() => {
    setL1ID("");
    setDirector("");
    setDirectorId("");
  }, [setL1ID, setDirector, setDirectorId]);

  return clearLV2;
};

const MainComponent = () => {
  const token = useToken();

  const [dataDirector, setDataDirector] = useRecoilState(directorDataState);

  const [isLoading, setIsLoading] = useRecoilState(isLoadingRec);
  const [error, setError] = useRecoilState(setErrorRec);
  const [isMessageBoxVisible, setIsMessageBoxVisible] = useRecoilState(isMessageBoxVisibleRec);
  const [message, setMessage] = useRecoilState(setMessageRec);

  const [LV1List, setLV1List] = useRecoilState(LuoghiOrganizzazioneSelectedL1);
  const [LV1ListId, setLV1ListId] = useRecoilState(LuoghiOrganizzazioneSelectedL1ID);
  const [director, setDirector] = useRecoilState(addLuoghiOrganizzazioneDirettoreCompliance);
  const [directorId, setDirectorId] = useRecoilState(addLuoghiOrganizzazioneDirettoreComplianceID);
  const [L1ID, setL1ID] = useRecoilState(SelectedL1ID);

  const [dataLV1, setDataLV1] = useState<LV1DataItem[]>([]);
  const finalDataLV1 = useFetchDataLv1(token);

  const [complianceDate, setComplianceDate] = useRecoilState(LuoghiComplianceDate);

  const [confirmedLV1List, setConfirmedLV1List] = useRecoilState(addLuoghiConfirmedLV1List);

  const [isDropdownOpenLV1, setIsDropdownOpenLV1] = useRecoilState(addLuoghi_2_isDropdownOpenLV1);
  const [isDropdownOpenDirectors, setIsDropdownOpenDirectors] = useRecoilState(addLuoghi_2_isDropdownOpenDirectorsCompliance);

  const dropdownRefDirector = useRef<HTMLDivElement>(null);
  const dropdownRefLV1 = useRef<HTMLDivElement>(null);

  const handleCloseAndNavigate = useHandleCloseAndNavigate();

  const handleDropdownToggleLV1 = useDropdownToggleLV1();
  const handleOptionSelectLV1 = useHandleOptionSelectLV1(dataLV1);
  const handleLV1Change = useHandleLV1Change();

  const handleDirectorChange = useHandleDirectorChange();
  const handleOptionSelectDirector = useHandleOptionSelectDirector();
  const handleDropdownToggleDirectors = useDropdownToggleDirectors();

  const ClearLV2 = useClearLV2();

  const { closeOverlay } = useContext(OverlayContext);

  useOutsideClickLV1(dropdownRefLV1);
  useOutsideClickDirectors(dropdownRefDirector);

  useFetchDirectorData(token);

  useEffect(() => {
    const selectedLV1 = finalDataLV1.find((item) => item.LV1_id === LV1ListId);

    if (selectedLV1) {
      setLV1List(selectedLV1.LV1_name);
    }
  }, [LV1ListId, finalDataLV1, setLV1List]);

  return (
    <OverlayComponentWhiteAdd1Multiple>
      {isLoading && (
        <BackdropTransparent>
          <MessageContainer>
            <MessageboxText>Loading...</MessageboxText>
          </MessageContainer>
        </BackdropTransparent>
      )}

      {isMessageBoxVisible && (
        <Backdrop>
          <MessageContainer>
            <p>
              <MessageboxText>{message}</MessageboxText>
            </p>
            <CloseMessageButton onClick={handleCloseAndNavigate}>
              <MessageboxText>Close</MessageboxText>
            </CloseMessageButton>
          </MessageContainer>
        </Backdrop>
      )}

      {error && (
        <Backdrop>
          <MessageContainer>
            <p>
              <MessageboxText>{error}</MessageboxText>
            </p>
            <CloseMessageButton
              onClick={() => {
                setIsMessageBoxVisible(false);
                setError(null);
                closeOverlay();
              }}
            >
              <MessageboxText>Close</MessageboxText>
            </CloseMessageButton>
          </MessageContainer>
        </Backdrop>
      )}
      <FlexContainerCrea>
        <CreaContainerColumn>
          <InputContainer>
            <InputDescriptionText>Responsabile Compliance</InputDescriptionText>

            <InputTextbox list="directors-list" value={director} onChange={handleDirectorChange} onClick={handleDropdownToggleDirectors} placeholder="Seleziona" />
            {director && (
              <CancelIcon
                style={{
                  fontSize: "16px",
                  cursor: "pointer",
                  position: "absolute",
                  right: "30px",
                  top: "32%",
                  transform: "translateY(-50%)",
                  color: "#c6c6c6",
                }}
                onClick={ClearLV2}
              />
            )}
            <TriangleContainer>
              {isDropdownOpenDirectors ? "▲" : "▼"} {/* Change icons as needed */}
            </TriangleContainer>

            {isDropdownOpenDirectors && (
              <CustomDropdownList>
                <DropDownMenuScrollbar ref={dropdownRefDirector}>
                  {dataDirector
                    .filter((item) => item.director_name.toLowerCase().includes(director.toLowerCase()))
                    .map((filteredItem, index) => (
                      <CustomDropdownItem key={index} onClick={() => handleOptionSelectDirector(filteredItem.director_id)}>
                        {filteredItem.director_name}
                      </CustomDropdownItem>
                    ))}
                </DropDownMenuScrollbar>
              </CustomDropdownList>
            )}
            <IconContainer />
          </InputContainer>
        </CreaContainerColumn>

        <CreaContainerColumn>
          <InputContainer>
            <InputDescriptionText>Data inizio incarico</InputDescriptionText>
            {/* <InputTextbox placeholder="" value={birthDate} onChange={(e) => setBirthDate(e.target.value)} /> */}
            <DatePicker
              value={complianceDate}
              format="DD-MM-YYYY"
              onChange={(newDate: dayjs.Dayjs | null) => setComplianceDate(newDate)}
              sx={{
                "& .MuiInputBase-input": {
                  height: "2px",
                },
                ".MuiInputBase-root": {
                  borderRadius: "0px",
                  backgroundColor: "#ffffff",
                  marginBottom: "20px",
                  boxShadow: "0px 1px 2px rgba(0, 0, 0, 0.1)",
                  "& .MuiOutlinedInput-notchedOutline": {
                    borderColor: "#cccccc",
                  },
                },

                "&:hover .MuiOutlinedInput-notchedOutline": {
                  borderColor: "#cccccc",
                },

                "& .Mui-focused .MuiOutlinedInput-notchedOutline": {
                  borderColor: "#1a81c3",
                  borderWidth: "1px",
                  boxShadow: "0px 4px 3px rgba(0, 0, 0, 0.1)",
                },
                ".MuiInputBase-input": {
                  color: "#0e163d",
                  fontSize: "15px",
                  fontFamily: "Titillium Web",
                },

                ".MuiSvgIcon-root": {
                  color: "#808080",
                  fontSize: "1.25rem",
                },

                width: "100%",
              }}
            />
          </InputContainer>
        </CreaContainerColumn>
      </FlexContainerCrea>
      <DescriptionContainerCreaMultiple>
        <IconSquare src="/description_show_compliance.svg" alt="Description Icon" />
        <DescriptionContainerCreaText>{TAB_DESCRIPTION_LUOGHI_6 ? TAB_DESCRIPTION_LUOGHI_6 : TAB_DESCRIPTION_GLOBAL}</DescriptionContainerCreaText>
      </DescriptionContainerCreaMultiple>
    </OverlayComponentWhiteAdd1Multiple>
  );
};

export default MainComponent;
