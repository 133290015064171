import InfoIcon from "@mui/icons-material/InfoOutlined";
import { Tooltip } from "@mui/material";
import React, { useContext, useEffect, useRef, useState } from "react";
import styled from "styled-components";
import { OverlayContext } from "../../cybersecurity-board-contestoleadership-organizzazione";

import { useRecoilState } from "recoil";
import {
  Backdrop,
  BackdropTransparent,
  CloseMessageButton,
  CreaContainerColumn,
  CustomDropdownItem,
  CustomDropdownList,
  DescriptionContainerCreaMultiple,
  DescriptionContainerCreaText,
  DropDownMenuScrollbar,
  FlexContainerCrea,
  IconContainer,
  IconSquare,
  InputContainer,
  InputDescriptionText,
  InputTextbox,
  MessageboxText,
  MessageContainer,
  OverlayComponentWhiteAdd1Multiple,
  TriangleContainer,
} from "../../cybersecurity-board-contestoleadership-organizzazione-sub/cybersecurity-board-organizzazione-organigramma-styled-components";

import CancelIcon from "@mui/icons-material/Clear";
import { URL_DirectorList, URL_L2ByL1, URL_LV1List, URL_PlacesByUnit, useToken } from "../../../../../API";
import { addAssetDirector, addAssetDirectorID, addAssetLV1List, addAssetLV1ListID, addAssetLV2List, addAssetLV2ListID, addAssetLV3List, addAssetLV3ListID } from "../ElencoAsset-Add-recoil";

const MainComponent = () => {
  const token = useToken();

  const [data, setData] = useState<DirectorDataItem[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const [isMessageBoxVisible, setIsMessageBoxVisible] = useState(false);
  const [isMessageBoxVisibleClosing, setIsMessageBoxVisibleClosing] = useState(false);
  const [message, setMessage] = useState("");

  const [LV1List, setLV1List] = useRecoilState(addAssetLV1List);
  const [LV1ListId, setLV1ListId] = useRecoilState(addAssetLV1ListID);

  const [LV2List, setLV2List] = useRecoilState(addAssetLV2List);
  const [LV2ListId, setLV2ListId] = useRecoilState(addAssetLV2ListID);

  const [LV3List, setLV3List] = useRecoilState(addAssetLV3List);
  const [LV3ListId, setLV3ListId] = useRecoilState(addAssetLV3ListID);

  const [director, setDirector] = useRecoilState(addAssetDirector);
  const [directorId, setDirectorId] = useRecoilState(addAssetDirectorID);
  const { closeOverlay } = useContext(OverlayContext);

  const handleCloseAndNavigate = () => {
    setIsMessageBoxVisible(false);
    closeOverlay();
  };

  const handleClose = () => {
    setIsMessageBoxVisible(false);
  };

  type DirectorApiContentItem = {
    id: string;
    alias: string;
  };

  type DirectorDataItem = {
    director_id: string;
    director_name: string;
  };

  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true);
      try {
        if (!token) throw new Error("User is not authenticated");

        const response = await fetch(URL_DirectorList, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });

        if (!response.ok) {
          throw new Error(`API request failed: ${response.statusText}`);
        }

        const responseData = await response.json();
        console.log("Response Data:", responseData);

        const transformedData: DirectorDataItem[] = responseData.map((item: DirectorApiContentItem) => {
          return {
            director_id: item.id,
            director_name: item.alias,
          };
        });

        console.log("Transformed Data:", transformedData);
        setData(transformedData);
      } catch (err) {
        setError(err instanceof Error ? err.message : "An unknown error occurred");
        setIsMessageBoxVisible(true);
      } finally {
        setIsLoading(false);
      }
    };

    fetchData();
  }, [token]);

  const directorNameToId = new Map(data.map((item) => [item.director_name, item.director_id]));

  const handleDirectorChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;
    setDirector(value);

    if (directorNameToId.has(value)) {
      setDirectorId(directorNameToId.get(value) || "");
      console.log(directorNameToId.get(value) || "");
    }
  };

  const handleDirectorChangeOnLoad = (id: string) => {
    const foundItem = data.find((item) => item.director_id === id);

    if (foundItem) {
      setDirector(foundItem.director_name);
    } else {
      setDirector("");
    }
  };

  useEffect(() => {
    if (directorId) {
      handleDirectorChangeOnLoad(directorId);
    } else {
      setDirector("");
    }
  }, [directorId, data]);

  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const dropdownRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    function handleClickOutside(event: MouseEvent) {
      if (dropdownRef.current && event.target instanceof Node && !dropdownRef.current.contains(event.target)) {
        setIsDropdownOpen(false);
      }
    }

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const handleDropdownToggle = () => {
    setDirector("");

    setDirectorId("");

    setIsDropdownOpen(!isDropdownOpen);
  };

  const handleOptionSelect = (selectedDirectorId: string) => {
    const selectedDirectorName = data.find((item) => item.director_id === selectedDirectorId)?.director_name;

    if (selectedDirectorName) {
      setDirector(selectedDirectorName);

      setDirectorId(selectedDirectorId);
    }

    setIsDropdownOpen(false);
  };

  useEffect(() => {
    console.log("Selected Director ID:", directorId);
  }, [directorId]);

  const ClearDirectors = () => {
    setDirector("");
    setDirectorId("");
  };

  type LV1ApiContentItem = {
    id: string;
    alias: string;
  };

  type LV1DataItem = {
    LV1_id: string;
    LV1_name: string;
  };

  const [dataLV1, setDataLV1] = useState<LV1DataItem[]>([]);

  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true);
      try {
        if (!token) throw new Error("User is not authenticated");

        const response = await fetch(URL_LV1List, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });

        if (!response.ok) {
          throw new Error(`API request failed: ${response.statusText}`);
        }

        const responseData = await response.json();
        console.log("Response dataLV1:", responseData);

        const contentArray = responseData.content;

        if (!Array.isArray(contentArray)) {
          throw new Error("Expected content to be an array");
        }

        const transformedData: LV1DataItem[] = contentArray.map((item: LV1ApiContentItem) => {
          return {
            LV1_id: item.id,
            LV1_name: item.alias,
          };
        });

        console.log("Transformed dataLV1:", transformedData);
        setDataLV1(transformedData);
      } catch (err) {
        setError(err instanceof Error ? err.message : "An unknown error occurred");
        setIsMessageBoxVisible(true);
      } finally {
        setIsLoading(false);
      }
    };

    fetchData();
  }, [token, LV1ListId]);

  const LV1NameToId = new Map(dataLV1.map((item) => [item.LV1_name, item.LV1_id]));

  const handleLV1Change = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;
    setLV1List(value);

    if (LV1NameToId.has(value)) {
      setLV1ListId(LV1NameToId.get(value) || "");
      console.log(LV1NameToId.get(value) || "");
    }
  };

  const handleLV1ChangeOnLoad = (id: string) => {
    const foundItem = dataLV1.find((item) => item.LV1_id === id);

    if (foundItem) {
      setLV1List(foundItem.LV1_name);
    } else {
      setLV1List("");
    }
  };

  useEffect(() => {
    if (LV1ListId) {
      handleLV1ChangeOnLoad(LV1ListId);
    } else {
      setLV1List("");
    }
  }, [LV1ListId, dataLV1]);

  const [isDropdownOpenLV1, setIsDropdownOpenLV1] = useState(false);
  const dropdownRefLV1 = useRef<HTMLDivElement>(null);

  useEffect(() => {
    function handleClickOutside(event: MouseEvent) {
      if (dropdownRefLV1.current && event.target instanceof Node && !dropdownRefLV1.current.contains(event.target)) {
        setIsDropdownOpenLV1(false);
      }
    }

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const handleDropdownToggleLV1 = () => {
    setLV1List("");
    setLV1ListId("");
    setIsDropdownOpenLV1(!isDropdownOpenLV1);
  };

  const handleOptionSelectLV1 = (selectedDirectorId: string) => {
    const selectedLV1Name = dataLV1.find((item) => item.LV1_id === selectedDirectorId)?.LV1_name;

    if (selectedLV1Name) {
      setLV1List(selectedLV1Name);

      setLV1ListId(selectedDirectorId);
    }

    setIsDropdownOpenLV1(false);
  };

  useEffect(() => {
    console.log("Selected LV1ListId ID:", LV1ListId);
  }, [LV1ListId]);

  const ClearLV1 = () => {
    setLV1List("");
    setLV1ListId("");
    setLV2List("");
    setLV2ListId("");
    setLV3List("");
    setLV3ListId("");
  };

  type LV2ApiContentItem = {
    id: string;
    alias: string;
  };

  type LV2DataItem = {
    LV2_id: string;
    LV2_name: string;
  };

  const [dataLV2, setDataLV2] = useState<LV2DataItem[]>([]);

  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true);

      if (!LV1ListId) {
        setIsLoading(false);
        setDataLV2([]);
        setDataLV3([]);
        return;
      }

      try {
        if (!token) throw new Error("User is not authenticated");

        const response = await fetch(`${URL_L2ByL1}${LV1ListId}`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });

        if (!response.ok) {
          throw new Error(`API request failed: ${response.statusText}`);
        }

        const responseData = await response.json();
        console.log("Response dataLV2:", responseData);

        const contentArray = responseData.content;

        if (!Array.isArray(contentArray)) {
          throw new Error("Expected content to be an array");
        }

        const transformedData: LV2DataItem[] = contentArray.map((item: LV2ApiContentItem) => {
          return {
            LV2_id: item.id,
            LV2_name: item.alias,
          };
        });

        console.log("Transformed dataLV2:", transformedData);
        setDataLV2(transformedData);
      } catch (err) {
        setError(err instanceof Error ? err.message : "An unknown error occurred");
        setIsMessageBoxVisible(true);
      } finally {
        setIsLoading(false);
      }
    };

    fetchData();
  }, [token, LV1ListId]);

  const LV2NameToId = new Map(dataLV2.map((item) => [item.LV2_name, item.LV2_id]));

  const handleLV2Change = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;
    setLV2List(value);

    if (LV2NameToId.has(value)) {
      setLV2ListId(LV2NameToId.get(value) || "");
      console.log(LV2NameToId.get(value) || "");
    }
  };

  const handleLV2ChangeOnLoad = (id: string) => {
    const foundItem = dataLV2.find((item) => item.LV2_id === id);

    if (foundItem) {
      setLV2List(foundItem.LV2_name);
    } else {
      setLV2List("");
    }
  };

  useEffect(() => {
    if (LV2ListId) {
      handleLV2ChangeOnLoad(LV2ListId);
    } else {
      setLV2List("");
    }
  }, [LV2ListId, dataLV2]);

  const [isDropdownOpenLV2, setIsDropdownOpenLV2] = useState(false);
  const dropdownRefLV2 = useRef<HTMLDivElement>(null);

  useEffect(() => {
    function handleClickOutside(event: MouseEvent) {
      if (dropdownRefLV2.current && event.target instanceof Node && !dropdownRefLV2.current.contains(event.target)) {
        setIsDropdownOpenLV2(false);
      }
    }

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const handleDropdownToggleLV2 = () => {
    if (!LV1ListId) {
      setIsMessageBoxVisible(true);
      setMessage("Si prega di selezionare una componente organizzativa");
    } else {
      setLV2List("");
      setLV2ListId("");
      setIsDropdownOpenLV2(!isDropdownOpenLV2);
    }
  };

  const handleOptionSelectLV2 = (selectedLV2Id: string) => {
    const selectedLV2Name = dataLV2.find((item) => item.LV2_id === selectedLV2Id)?.LV2_name;

    if (selectedLV2Name) {
      setLV2List(selectedLV2Name);

      setLV2ListId(selectedLV2Id);
    }

    setIsDropdownOpenLV2(false);
  };

  useEffect(() => {
    console.log("Selected LV2ListId ID:", LV2ListId);
  }, [LV2ListId]);

  const ClearLV2 = () => {
    setLV2List("");
    setLV2ListId("");
    setLV3List("");
    setLV3ListId("");
  };

  type LV3ApiContentItem = {
    content: {
      place: {
        id: string;
        name: string;
      };
    };
  };

  type LV3DataItem = {
    LV3_id: string;
    LV3_name: string;
  };

  const [dataLV3, setDataLV3] = useState<LV3DataItem[]>([]);

  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true);

      if (!LV1ListId || !LV2ListId) {
        setIsLoading(false);
        setDataLV1([]);
        setDataLV2([]);
        return;
      }

      try {
        if (!token) throw new Error("User is not authenticated");

        const response = await fetch(`${URL_PlacesByUnit}/${LV2ListId}/${LV1ListId}`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });

        if (!response.ok) {
          throw new Error(`API request failed: ${response.statusText}`);
        }

        const responseData = await response.json();
        console.log("Response dataLV3:", responseData);

        const contentArray = responseData;

        const transformedData: LV3DataItem[] = contentArray.map((item: LV3ApiContentItem) => {
          return {
            LV3_id: item.content.place.id,
            LV3_name: item.content.place.name,
          };
        });

        console.log("Transformed dataLV3:", transformedData);
        setDataLV3(transformedData);
      } catch (err) {
        setError(err instanceof Error ? err.message : "An unknown error occurred");
        setIsMessageBoxVisible(true);
      } finally {
        setIsLoading(false);
      }
    };

    fetchData();
  }, [token, LV2ListId, LV1ListId]);

  const LV3NameToId = new Map(dataLV3.map((item) => [item.LV3_name, item.LV3_id]));

  const handleLV3Change = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;
    setLV3List(value);

    if (LV3NameToId.has(value)) {
      setLV3ListId(LV3NameToId.get(value) || "");
      console.log(LV3NameToId.get(value) || "");
    }
  };

  const handleLV3ChangeOnLoad = (id: string) => {
    const foundItem = dataLV3.find((item) => item.LV3_id === id);

    if (foundItem) {
      setLV3List(foundItem.LV3_name);
    } else {
      setLV3List("");
    }
  };

  useEffect(() => {
    if (LV3ListId) {
      handleLV3ChangeOnLoad(LV3ListId);
    } else {
      setLV3List("");
    }
  }, [LV3ListId, dataLV3]);

  const [isDropdownOpenLV3, setIsDropdownOpenLV3] = useState(false);
  const dropdownRefLV3 = useRef<HTMLDivElement>(null);

  useEffect(() => {
    function handleClickOutside(event: MouseEvent) {
      if (dropdownRefLV3.current && event.target instanceof Node && !dropdownRefLV3.current.contains(event.target)) {
        setIsDropdownOpenLV3(false);
      }
    }

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const handleDropdownToggleLV3 = () => {
    if (!LV2ListId) {
      setIsMessageBoxVisible(true);
      setMessage("Si prega di selezionare un`unità operativa");
    } else {
      setLV3List("");
      setLV3ListId("");
      setIsDropdownOpenLV3(!isDropdownOpenLV3);
    }
  };

  const handleOptionSelectLV3 = (selectedLV3Id: string) => {
    const selectedLV3Name = dataLV3.find((item) => item.LV3_id === selectedLV3Id)?.LV3_name;

    if (selectedLV3Name) {
      setLV3List(selectedLV3Name);

      setLV3ListId(selectedLV3Id);
    }

    setIsDropdownOpenLV3(false);
  };

  useEffect(() => {
    console.log("Selected LV2ListId ID:", LV3ListId);
  }, [LV3ListId]);

  const ClearLV3 = () => {
    setLV3List("");
    setLV3ListId("");
  };

  const [isVisible, setIsVisible] = useState(false);
  const togglePopup = () => {
    setIsVisible(!isVisible);
  };

  interface LastPopupProps {
    isVisible: boolean;
  }

  const LastPopupBlack = styled.div<LastPopupProps>`
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 99;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: opacity 0.3s ease, visibility 0.3s ease;
    opacity: ${({ isVisible }) => (isVisible ? 1 : 0)};
    visibility: ${({ isVisible }) => (isVisible ? "visible" : "hidden")};
  `;

  const LastPopup = styled.div`
    font-family: var(--font-titillium-web);
    font-size: var(--font-size-micro);
    color: var(--color-steelblue-700);
    position: fixed;
    right: 0px;
    top: 0px;
    bottom: 0px;
    width: 75%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-color: var(--color-aliceblue);

    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.25);

    z-index: 100;
  `;

  return (
    <OverlayComponentWhiteAdd1Multiple>
      {isLoading && (
        <BackdropTransparent>
          <MessageContainer>
            <MessageboxText>Loading...</MessageboxText>
          </MessageContainer>
        </BackdropTransparent>
      )}

      {isMessageBoxVisible && (
        <Backdrop>
          <MessageContainer>
            <p>
              <MessageboxText>{message}</MessageboxText>
            </p>
            <CloseMessageButton onClick={handleClose}>
              <MessageboxText>Close</MessageboxText>
            </CloseMessageButton>
          </MessageContainer>
        </Backdrop>
      )}

      {isMessageBoxVisibleClosing && (
        <Backdrop>
          <MessageContainer>
            <p>
              <MessageboxText>{message}</MessageboxText>
            </p>
            <CloseMessageButton onClick={handleCloseAndNavigate}>
              <MessageboxText>Close</MessageboxText>
            </CloseMessageButton>
          </MessageContainer>
        </Backdrop>
      )}

      {error && (
        <Backdrop>
          <MessageContainer>
            <p>
              <MessageboxText>{error}</MessageboxText>
            </p>
            <CloseMessageButton
              onClick={() => {
                setIsMessageBoxVisible(false);
                setError(null);
                closeOverlay();
              }}
            >
              <MessageboxText>Close</MessageboxText>
            </CloseMessageButton>
          </MessageContainer>
        </Backdrop>
      )}
      <FlexContainerCrea>
        <CreaContainerColumn>
          <InputContainer>
            <InputDescriptionText>
              1. Componente organizzativa
              <Tooltip title="Campo obbligatorio">
                <InfoIcon
                  style={{
                    fontSize: "14px",
                    color: "#04446c",
                    cursor: "pointer",
                    marginLeft: "2px",
                  }}
                />
              </Tooltip>
            </InputDescriptionText>

            <InputTextbox list="l1-list" value={LV1List} onChange={handleLV1Change} onClick={handleDropdownToggleLV1} placeholder="Seleziona" />
            {LV1List && (
              <CancelIcon
                style={{
                  fontSize: "16px",
                  cursor: "pointer",
                  position: "absolute",
                  right: "30px",
                  top: "32%",
                  transform: "translateY(-50%)",
                  color: "#c6c6c6",
                }}
                onClick={ClearLV1}
              />
            )}
            <TriangleContainer>
              {isDropdownOpenLV1 ? "▲" : "▼"} {/* Change icons as needed */}
            </TriangleContainer>

            {isDropdownOpenLV1 && (
              <CustomDropdownList>
                <DropDownMenuScrollbar ref={dropdownRefLV1}>
                  {dataLV1
                    .filter((item) => item.LV1_name.toLowerCase().includes(LV1List.toLowerCase()))
                    .map((filteredItem, index) => (
                      <CustomDropdownItem key={index} onClick={() => handleOptionSelectLV1(filteredItem.LV1_id)}>
                        {filteredItem.LV1_name}
                      </CustomDropdownItem>
                    ))}
                </DropDownMenuScrollbar>
              </CustomDropdownList>
            )}
            <IconContainer />
          </InputContainer>

          <InputContainer>
            <InputDescriptionText>
              2. Unità operativa
              <Tooltip title="Campo obbligatorio">
                <InfoIcon
                  style={{
                    fontSize: "14px",
                    color: "#04446c",
                    cursor: "pointer",
                    marginLeft: "2px",
                  }}
                />
              </Tooltip>
            </InputDescriptionText>

            <InputTextbox list="l2-list" value={LV2List} onChange={handleLV2Change} onClick={handleDropdownToggleLV2} placeholder="Seleziona" />
            {LV2List && (
              <CancelIcon
                style={{
                  fontSize: "16px",
                  cursor: "pointer",
                  position: "absolute",
                  right: "30px",
                  top: "32%",
                  transform: "translateY(-50%)",
                  color: "#c6c6c6",
                }}
                onClick={ClearLV2}
              />
            )}
            <TriangleContainer>
              {isDropdownOpenLV2 && LV1ListId ? "▲" : "▼"} {/* Change icons as needed */}
            </TriangleContainer>

            {isDropdownOpenLV2 && LV1ListId && (
              <CustomDropdownList>
                <DropDownMenuScrollbar ref={dropdownRefLV2}>
                  {dataLV2
                    .filter((item) => item.LV2_name.toLowerCase().includes(LV2List.toLowerCase()))
                    .map((filteredItem, index) => (
                      <CustomDropdownItem key={index} onClick={() => handleOptionSelectLV2(filteredItem.LV2_id)}>
                        {filteredItem.LV2_name}
                      </CustomDropdownItem>
                    ))}
                </DropDownMenuScrollbar>
              </CustomDropdownList>
            )}
            <IconContainer />
          </InputContainer>

          <InputContainer>
            <InputDescriptionText>
              3. Luogo
              <Tooltip title="Campo obbligatorio">
                <InfoIcon
                  style={{
                    fontSize: "14px",
                    color: "#04446c",
                    cursor: "pointer",
                    marginLeft: "2px",
                  }}
                />
              </Tooltip>
            </InputDescriptionText>

            <InputTextbox list="l3-list" value={LV3List} onChange={handleLV3Change} onClick={handleDropdownToggleLV3} placeholder="Seleziona" />
            {LV3List && (
              <CancelIcon
                style={{
                  fontSize: "16px",
                  cursor: "pointer",
                  position: "absolute",
                  right: "30px",
                  top: "32%",
                  transform: "translateY(-50%)",
                  color: "#c6c6c6",
                }}
                onClick={ClearLV3}
              />
            )}
            <TriangleContainer>
              {isDropdownOpenLV3 && LV2ListId ? "▲" : "▼"} {/* Change icons as needed */}
            </TriangleContainer>

            {isDropdownOpenLV3 && LV2ListId && (
              <CustomDropdownList>
                <DropDownMenuScrollbar ref={dropdownRefLV3}>
                  {dataLV3
                    .filter((item) => item.LV3_name.toLowerCase().includes(LV3List.toLowerCase()))
                    .map((filteredItem, index) => (
                      <CustomDropdownItem key={index} onClick={() => handleOptionSelectLV3(filteredItem.LV3_id)}>
                        {filteredItem.LV3_name}
                      </CustomDropdownItem>
                    ))}
                </DropDownMenuScrollbar>
              </CustomDropdownList>
            )}
            <IconContainer />
          </InputContainer>
        </CreaContainerColumn>

        <CreaContainerColumn>
          <InputContainer>
            <InputDescriptionText>Responsabile Asset</InputDescriptionText>

            <InputTextbox list="directors-list" value={director} onChange={handleDirectorChange} onClick={handleDropdownToggle} placeholder="Seleziona" />
            {director && (
              <CancelIcon
                style={{
                  fontSize: "16px",
                  cursor: "pointer",
                  position: "absolute",
                  right: "30px",
                  top: "32%",
                  transform: "translateY(-50%)",
                  color: "#c6c6c6",
                }}
                onClick={ClearDirectors}
              />
            )}
            <TriangleContainer>
              {isDropdownOpen ? "▲" : "▼"} {/* Change icons as needed */}
            </TriangleContainer>

            {isDropdownOpen && (
              <CustomDropdownList>
                <DropDownMenuScrollbar ref={dropdownRef}>
                  {data
                    .filter((item) => item.director_name.toLowerCase().includes(director.toLowerCase()))
                    .map((filteredItem, index) => (
                      <CustomDropdownItem key={index} onClick={() => handleOptionSelect(filteredItem.director_id)}>
                        {filteredItem.director_name}
                      </CustomDropdownItem>
                    ))}
                </DropDownMenuScrollbar>
              </CustomDropdownList>
            )}
            <IconContainer />
          </InputContainer>
        </CreaContainerColumn>
      </FlexContainerCrea>
      <DescriptionContainerCreaMultiple>
        <IconSquare src="/crea-L2-organizzazione.svg" alt="Description Icon" />
        <DescriptionContainerCreaText>
          Indicare il nome del Direttore a capo dell'unità, selezionare il modello della struttura organizzativa interna e elencare le principali attività cliniche e amministrative, sia quelle
          specialistiche previste dal Day Hospital sia quelle ordinarie, per delineare compiutamente il funzionamento e le competenze dell'unità
        </DescriptionContainerCreaText>
      </DescriptionContainerCreaMultiple>
    </OverlayComponentWhiteAdd1Multiple>
  );
};

export default MainComponent;
