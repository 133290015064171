import React from "react";
import { useLocation } from "react-router-dom";
import styled from "styled-components";
import { FrameBoardMain, MainBoardSubMenuButton, MainBoardSubMenuButtonContainer } from "../styled-components";

const Board27001Organizzazione: React.FC = () => {
  const location = useLocation();
  const isActive = (path: string) => {
    return location.pathname === "/Cybersecurity/RiskManagement" && path === "RiskManagement";
  };

  const ImageContainer = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    width: 100%;
    margin-top: 90px;
  `;

  const StyledImage = styled.img`
    max-width: 90%;
    height: auto;
  `;

  return (
    <FrameBoardMain>
      <MainBoardSubMenuButtonContainer>
        <MainBoardSubMenuButton to="/Cybersecurity/RiskManagement" className={isActive("RiskManagement") ? "active" : ""}>
          Risk management
        </MainBoardSubMenuButton>
        <MainBoardSubMenuButton to="/Cybersecurity/Metodo">Metodo</MainBoardSubMenuButton>
        <MainBoardSubMenuButton to="/Cybersecurity/Esempio">Esempio</MainBoardSubMenuButton>
      </MainBoardSubMenuButtonContainer>

      <ImageContainer>
        <StyledImage src={"../risk_management.png"} alt={"alt"} />
      </ImageContainer>
    </FrameBoardMain>
  );
};

export default Board27001Organizzazione;
