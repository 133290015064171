import React, { useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import styled from "styled-components";

import {
  ArrowGrey,
  ArrowGreyRight,
  FrameButtonIcon,
  IcoButtonIcon,
  LineMagenta50,
  NavButtonWhite,
  NavButtonWhiteChild,
  NavButtonWhiteChildFocused,
  NavButtonWhiteChildText,
  NavButtonWhiteText
} from "./styled-components";

type SubMenuProps = {
  show: boolean;
  children: React.ReactNode;
};

interface StyledSubMenuProps {
  show: boolean;
}

const StyledSubMenu = styled.div<StyledSubMenuProps>`
  transition: max-height 0.3s ease-out;
  overflow: hidden;
  max-height: 0;
  display: flex;
  flex-direction: column;
  align-items: start;
  padding-left: 0px;
  width: 100%;
  padding: 0;
  margin: 0;

  ${({ show }) =>
    show
      ? `
      max-height: 200px;  
    `
      : ""}
`;

const SubMenu: React.FC<SubMenuProps> = ({ show, children }) => <StyledSubMenu show={show}>{children}</StyledSubMenu>;

const AccordionWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 0;
  margin: 0;

  gap: 0px;
`;

type MenuItemChild = {
  content: JSX.Element;
  path: string;
  id: string;
  onClick: () => void;
};

type MenuItem = {
  id: string;
  type?: string;
  path?: string;
  title: string;
  iconDir: string;
  children?: MenuItemChild[];
};

const menuItems: MenuItem[] = [
  {
    type: "solo",
    id: "dashboard",
    title: "Dashboard",
    iconDir: "/dashboard.svg",

    path: "/Cybersecurity/GapAnalysis",
    children: [],
  },
  {
    id: "contesto-leadership",
    title: "Contesto e leadership",
    iconDir: "/contesto-leadership.svg",
    children: [
      {
        id: "/Cybersecurity/Organizzazione",
        content: <p>Organizzazione</p>,
        path: "/Cybersecurity/Organizzazione",
        onClick: () => {
          console.log("Contesto e leadership Content 2 clicked");
        },
      },
      {
        id: "parti-interessate",
        path: "/Cybersecurity/PartiInteressate",
        content: <p>Parti interessate</p>,
        onClick: () => {
          console.log("Contesto e leadership Content 2 clicked");
        },
      },
      {
        id: "/Cybersecurity/Infrastruttura",
        path: "/Cybersecurity/Infrastruttura",
        content: <p>Infrastruttura</p>,
        onClick: () => {
          console.log("Contesto e leadership Content 2 clicked");
        },
      },
      {
        id: "processi-generali",
        path: "/Cybersecurity/ProcessiGenerali",
        content: <p>Processi generali</p>,
        onClick: () => {
          console.log("Contesto e leadership Content 2 clicked");
        },
      },
      {
        id: "gestione-compliance",
        path: "/Cybersecurity/GestioneCompliance",
        content: <p>Sistema gestione Compliance</p>,
        onClick: () => {
          console.log("Contesto e leadership Content 2 clicked");
        },
      },
    ],
  },
  {
    id: "pianificazione-strategica",
    title: "Pianificazione strategica",
    iconDir: "/pianificazione-strategica.svg",
    children: [
      {
        id: "piano-strategico",
        path: "/Cybersecurity/PianoStrategico",
        content: <p>Piano strategico</p>,
        onClick: () => {
          console.log("Dashboard Content 2 clicked");
        },
      },
      {
        id: "processi-compliance",
        path: "/Cybersecurity/ProcessiCompliance",
        content: <p>Processi Compliance</p>,
        onClick: () => {
          console.log("Dashboard Content 1 clicked");
        },
      },
      {
        id: "gap-analysis",
        path: "/Cybersecurity/GapAnalysis",
        content: <p>Gap Analysis</p>,
        onClick: () => {
          console.log("Dashboard Content 1 clicked");
        },
      },
    ],
  },
  {
    id: "supporto",
    title: "Supporto",
    iconDir: "/supporto.svg",
    children: [
      {
        id: "risorse-materiali",
        path: "/Cybersecurity/RisorseMateriali",
        content: <p>Risorse materiali</p>,
        onClick: () => {
          console.log("Dashboard Content 1 clicked");
        },
      },
      {
        id: "risorseumane",
        path: "/Cybersecurity/RisorseUmane",
        content: <p>Risorse umane</p>,
        onClick: () => {
          console.log("Dashboard Content 2 clicked");
        },
      },
      {
        id: "altrerisorse",
        path: "/Cybersecurity/AltreRisorse",
        content: <p>Altre risorse</p>,
        onClick: () => {
          console.log("Dashboard Content 2 clicked");
        },
      },
      {
        id: "documentazione",
        path: "/Cybersecurity/Documentazione",
        content: <p>Documentazione</p>,
        onClick: () => {
          console.log("Dashboard Content 2 clicked");
        },
      },
    ],
  },
  {
    id: "gestione",
    title: "Gestione operativa",
    iconDir: "/gestione-operativa.svg",
    children: [
      {
        id: "pianificazione-operativa",
        path: "/Cybersecurity/PianificazioneOperativa",
        content: <p>Pianificazione operativa</p>,
        onClick: () => {
          console.log("Dashboard Content 1 clicked");
        },
      },
      {
        id: "monitoraggio",
        path: "/Cybersecurity/Monitoraggio",
        content: <p>Monitoraggio</p>,
        onClick: () => {
          console.log("Dashboard Content 2 clicked");
        },
      },
    ],
  },
  {
    type: "solo",
    id: "analisi-revisione",
    title: "Analisi e revisione",
    iconDir: "/analisi-revisione.svg",
    path: "/Cybersecurity/AnalisiRevisione",
    children: [],
  },
];

const AccordionMenu: React.FC = () => {
  const location = useLocation();
  const currentPath = location.pathname;

  const dashboardIndex = menuItems.findIndex((item) => item.id === "dashboard");
  const [openIndex, setOpenIndex] = useState<null | number>(null);
  const navigate = useNavigate();
  const [activeIndex, setActiveIndex] = useState<null | number>(dashboardIndex);

  const handleFatherButtonClick = (index: number) => {
    setOpenIndex((prevIndex) => {
      if (prevIndex === index) {
        setActiveIndex(null);
        return null;
      } else {
        setActiveIndex(index);
        return index;
      }
    });
  };

  const handleChildButtonClick = (path: string) => {
    navigate(path);
  };

  const handleSoloButtonClick = (path: string, index: number) => {
    setOpenIndex(null);
    setActiveIndex(index);
    if (path) {
      navigate(path);
    } else {
      console.error("No path defined for this menu item");
    }
  };

  const arrowIconSourceClosed = "/arrow-grey-bottom.svg";
  const arrowIconSourceOpen = "/arrow-grey-top.svg";

  const lineMagenta50Source = "/line-magenta-50.svg";
  const lineMagenta40Source = "/line-magenta-40.svg";

  return (
    <AccordionWrapper>
      {menuItems.map((item, index) => (
        <div key={index}>
          {item.type === "solo" ? (
            <NavButtonWhite onClick={() => item.path && handleSoloButtonClick(item.path, index)}>
              <FrameButtonIcon>
                <IcoButtonIcon alt="" src={item.iconDir} />
              </FrameButtonIcon>
              <NavButtonWhiteText>{item.title}</NavButtonWhiteText>
              <ArrowGreyRight src="/arrow-grey-right.svg" alt="Arrow Icon" />
              {activeIndex === index && <LineMagenta50 src={lineMagenta50Source} alt="Line Magenta" />}
            </NavButtonWhite>
          ) : (
            <>
              <NavButtonWhite onClick={() => handleFatherButtonClick(index)}>
                <FrameButtonIcon>
                  <IcoButtonIcon alt="" src={item.iconDir} />
                </FrameButtonIcon>
                <NavButtonWhiteText>{item.title}</NavButtonWhiteText>
                <ArrowGrey src={openIndex === index ? arrowIconSourceOpen : arrowIconSourceClosed} alt="Arrow Icon" />
                {openIndex === index && <LineMagenta50 src={lineMagenta50Source} alt="Line Magenta" />}
              </NavButtonWhite>
              <StyledSubMenu show={openIndex === index}>
                {item.children?.map((child, childIndex) => {
                  const isFocused = currentPath.includes(child.path);

                  return (
                    <React.Fragment key={childIndex}>
                      {isFocused ? (
                        <NavButtonWhiteChildFocused onClick={() => handleChildButtonClick(child.path)}>
                          <NavButtonWhiteChildText>{child.content}</NavButtonWhiteChildText>
                        </NavButtonWhiteChildFocused>
                      ) : (
                        <NavButtonWhiteChild onClick={() => handleChildButtonClick(child.path)}>
                          <NavButtonWhiteChildText>{child.content}</NavButtonWhiteChildText>
                        </NavButtonWhiteChild>
                      )}
                    </React.Fragment>
                  );
                })}
              </StyledSubMenu>
            </>
          )}
        </div>
      ))}
    </AccordionWrapper>
  );
};
export default AccordionMenu;
