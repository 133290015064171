import CancelIcon from "@mui/icons-material/Clear";
import dayjs from "dayjs";
import React, { useEffect, useRef, useState } from "react";
import { useRecoilState } from "recoil";
import { URL_DirectorList, URL_PersonRolesCompliance, useToken } from "../../../../../API";
import {
  TemplaterComplianceRole,
  TemplaterComplianceRoleId,
  TemplaterComplianceRoleResponsibility,
  TemplaterDate,
  TemplaterDateEnd,
  TemplaterDateStart,
  TemplaterDirector,
  TemplaterDirectorId,
  TemplaterDirectory,
  TemplaterDocN,
  TemplaterName,
  TemplaterSurname,
} from "../../../../../recoil";
import {
  CreaContainerColumn,
  CustomDropdownItem,
  CustomDropdownList,
  DescriptionContainerCreaMultiple,
  DescriptionContainerCreaText,
  DropDownMenuScrollbar,
  FlexContainerCrea,
  IconContainer,
  IconSquare,
  InputContainer,
  InputDescriptionText,
  InputTextbox,
  OverlayComponentWhiteAdd1Multiple,
  TriangleContainer
} from "../../cybersecurity-board-contestoleadership-organizzazione-sub/cybersecurity-board-organizzazione-organigramma-styled-components";

const MainComponent = () => {
  const token = useToken();

  const [date, setDate] = useRecoilState(TemplaterDate);
  const [dateStart, setDateStart] = useRecoilState(TemplaterDateStart);
  const [dateEnd, setDateEnd] = useRecoilState(TemplaterDateEnd);
  const [DocN, setDocN] = useRecoilState(TemplaterDocN);
  const [Name, setName] = useRecoilState(TemplaterName);
  const [Surname, setSurname] = useRecoilState(TemplaterSurname);
  const [ComplianceRole, setComplianceRole] = useRecoilState(TemplaterComplianceRole);
  const [ComplianceRoleId, setComplianceRoleId] = useRecoilState(TemplaterComplianceRoleId);
  const [ComplianceRoleResponsibility, setComplianceRoleResponsibility] = useRecoilState(TemplaterComplianceRoleResponsibility);
  const [Director, setDirector] = useRecoilState(TemplaterDirector);
  const [DirectorId, setDirectorId] = useRecoilState(TemplaterDirectorId);
  const [Directory, setDirectory] = useRecoilState(TemplaterDirectory);

  useEffect(() => {
    setDate(dayjs().format("DD-MM-YYYY"));
  }, []);

  const [dataCompliance, setDataCompliance] = useState<ComplianceRoleDataItem[]>([]);
  const [data, setData] = useState<DirectorDataItem[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const [isMessageBoxVisible, setIsMessageBoxVisible] = useState(false);
  const [message, setMessage] = useState("");

  type DirectorApiContentItem = {
    id: string;
    alias: string;
  };

  type DirectorDataItem = {
    director_id: string;
    director_name: string;
  };

  type ComplianceRoleApiContentItem = {
    compliance_role: {
      id: string;
      role_description: string;
      responsibility: string;
    };
    person: {
      name: string;
      surname: string;
      documents_folder_link: string;
    };

    unit: {
      compliance_responsible_assignment_start: string;
    };
  };

  type ComplianceRoleDataItem = {
    compliance_id: string;
    compliance_name: string;
    compliance_responsibility: string;
    compliance_date_start: string;
    person_name: string;
    person_surname: string;
    documents_folder: string;
  };

  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true);
      try {
        if (!token) throw new Error("User is not authenticated");

        const response = await fetch(`${URL_PersonRolesCompliance}${DirectorId}`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });

        if (!response.ok) {
          throw new Error(`API request failed: ${response.statusText}`);
        }

        const responseData = await response.json();
        console.log("Response Data:", responseData);

        const transformedData: ComplianceRoleDataItem[] = responseData.map((item: ComplianceRoleApiContentItem) => {
          return {
            compliance_id: item.compliance_role.id,
            compliance_name: item.compliance_role.role_description,
            compliance_responsibility: item.compliance_role.responsibility,
            documents_folder: item.person.documents_folder_link,
            person_name: item.person.name,
            person_surname: item.person.surname,
            compliance_date_start: item.person.surname,
          };
        });

        console.log("Transformed Data:", transformedData);
        setDataCompliance(transformedData);
      } catch (err) {
        setError(err instanceof Error ? err.message : "An unknown error occurred");
        setIsMessageBoxVisible(true);
      } finally {
        setIsLoading(false);
      }
    };

    fetchData();
  }, [DirectorId, token]);

  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true);
      try {
        if (!token) throw new Error("User is not authenticated");

        const response = await fetch(`${URL_DirectorList}`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });

        if (!response.ok) {
          throw new Error(`API request failed: ${response.statusText}`);
        }

        const responseData = await response.json();
        console.log("Response Data:", responseData);

        const transformedData: DirectorDataItem[] = responseData.map((item: DirectorApiContentItem) => {
          return {
            director_id: item.id,
            director_name: item.alias,
          };
        });

        console.log("Transformed Data:", transformedData);
        setData(transformedData);
      } catch (err) {
        setError(err instanceof Error ? err.message : "An unknown error occurred");
        setIsMessageBoxVisible(true);
      } finally {
        setIsLoading(false);
      }
    };

    fetchData();
  }, [token]);

  const directorNameToId = new Map(data.map((item) => [item.director_name, item.director_id]));

  const handleDirectorChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;
    setDirector(value);

    if (directorNameToId.has(value)) {
      setDirectorId(directorNameToId.get(value) || "");
      console.log(directorNameToId.get(value) || "");
    }
  };

  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const dropdownRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    function handleClickOutside(event: MouseEvent) {
      if (dropdownRef.current && event.target instanceof Node && !dropdownRef.current.contains(event.target)) {
        setIsDropdownOpen(false);
      }
    }

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const handleDropdownToggle = () => {
    setDirector("");

    setDirectorId("");

    setIsDropdownOpen(!isDropdownOpen);
  };

  const handleOptionSelect = (selectedDirectorId: string) => {
    const selectedDirectorName = data.find((item) => item.director_id === selectedDirectorId)?.director_name;

    if (selectedDirectorName) {
      setDirector(selectedDirectorName);

      setDirectorId(selectedDirectorId);
    }

    setIsDropdownOpen(false);
    setComplianceRole("");
    setComplianceRoleId("");
  };

  useEffect(() => {
    console.log("Selected Director ID:", DirectorId);
  }, [DirectorId]);

  const ClearLV1 = () => {
    setDirector("");
    setDirectorId("");
    setComplianceRole("");
    setComplianceRoleId("");
    setIsDropdownOpen(false);
  };

  const complianceNameToId = new Map(data.map((item) => [item.director_name, item.director_id]));

  const handleComplianceChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;
    setComplianceRole(value);

    if (complianceNameToId.has(value)) {
      setComplianceRoleId(complianceNameToId.get(value) || "");
      console.log(complianceNameToId.get(value) || "");
    }
  };

  const [isDropdownComplianceOpen, setIsDropdownComplianceOpen] = useState(false);
  const dropdownComplianceRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    function handleClickOutside(event: MouseEvent) {
      if (dropdownComplianceRef.current && event.target instanceof Node && !dropdownComplianceRef.current.contains(event.target)) {
        setIsDropdownComplianceOpen(false);
      }
    }

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const handleDropdownComplianceToggle = () => {
    setComplianceRole("");

    setComplianceRoleId("");

    setIsDropdownComplianceOpen(!isDropdownOpen);
  };

  const handleOptionComplianceSelect = (selectedComplianceRoleId: string) => {
    const selectedComplianceRole = dataCompliance.find((item) => item.compliance_id === selectedComplianceRoleId);

    if (selectedComplianceRole) {
      const todayDateFormatted = dayjs().format("DD-MM-YYYY");

      setComplianceRole(selectedComplianceRole.compliance_name);
      setComplianceRoleId(selectedComplianceRoleId);
      setComplianceRoleResponsibility(selectedComplianceRole.compliance_responsibility);
      setDirectory(selectedComplianceRole.documents_folder);
      setName(selectedComplianceRole.person_name);
      setSurname(selectedComplianceRole.person_surname);
      setDateStart(todayDateFormatted);
    }

    setIsDropdownComplianceOpen(false);
  };

  useEffect(() => {
    console.log("Selected Compliance ID:", ComplianceRoleId, "Directory: ", Directory, "Responsibility: ", ComplianceRoleResponsibility);
  }, [ComplianceRoleId]);

  const ClearLV2 = () => {
    setComplianceRole("");
    setComplianceRoleId("");
    setIsDropdownComplianceOpen(false);
  };

  function generateRandom8DigitNumber() {
    return Math.floor(Math.random() * 1000000);
  }

  useEffect(() => {
    const currentYear = dayjs().format("YYYY");

    const newDocN = `${currentYear}${generateRandom8DigitNumber().toString()}`;

    setDocN(newDocN);
  }, []);

  return (
    <OverlayComponentWhiteAdd1Multiple>
      <FlexContainerCrea>
        <CreaContainerColumn>
          <InputContainer>
            <InputDescriptionText>Nome e cognome</InputDescriptionText>

            <InputTextbox list="directors-list" value={Director} onChange={handleDirectorChange} onClick={handleDropdownToggle} placeholder="Seleziona" />
            {Director && (
              <CancelIcon
                style={{
                  fontSize: "16px",
                  cursor: "pointer",
                  position: "absolute",
                  right: "30px",
                  top: "32%",
                  transform: "translateY(-50%)",
                  color: "#c6c6c6",
                }}
                onClick={ClearLV1}
              />
            )}
            <TriangleContainer>
              {isDropdownOpen ? "▲" : "▼"} {/* Change icons as needed */}
            </TriangleContainer>

            {isDropdownOpen && (
              <CustomDropdownList>
                <DropDownMenuScrollbar ref={dropdownRef}>
                  {data
                    .filter((item) => item.director_name.toLowerCase().includes(Director.toLowerCase()))
                    .map((filteredItem, index) => (
                      <CustomDropdownItem key={index} onClick={() => handleOptionSelect(filteredItem.director_id)}>
                        {filteredItem.director_name}
                      </CustomDropdownItem>
                    ))}
                </DropDownMenuScrollbar>
              </CustomDropdownList>
            )}
            <IconContainer />
          </InputContainer>

          <InputContainer>
            <InputDescriptionText>Ruolo compliance</InputDescriptionText>

            <InputTextbox list="compliance-list" value={ComplianceRole} onChange={handleComplianceChange} onClick={handleDropdownComplianceToggle} placeholder="Seleziona" />
            {ComplianceRole && (
              <CancelIcon
                style={{
                  fontSize: "16px",
                  cursor: "pointer",
                  position: "absolute",
                  right: "30px",
                  top: "32%",
                  transform: "translateY(-50%)",
                  color: "#c6c6c6",
                }}
                onClick={ClearLV2}
              />
            )}
            <TriangleContainer>
              {isDropdownComplianceOpen ? "▲" : "▼"} {/* Change icons as needed */}
            </TriangleContainer>

            {isDropdownComplianceOpen && (
              <CustomDropdownList>
                <DropDownMenuScrollbar ref={dropdownComplianceRef}>
                  {dataCompliance
                    .filter((item) => item.compliance_name.toLowerCase().includes(ComplianceRole.toLowerCase()))
                    .map((filteredItem, index) => (
                      <CustomDropdownItem key={index} onClick={() => handleOptionComplianceSelect(filteredItem.compliance_id)}>
                        {filteredItem.compliance_name}
                      </CustomDropdownItem>
                    ))}
                </DropDownMenuScrollbar>
              </CustomDropdownList>
            )}
            <IconContainer />
          </InputContainer>
          <InputContainer>
            <InputDescriptionText>Documento numero:</InputDescriptionText>
            <InputTextbox disabled={true} placeholder="" value={DocN} onChange={(e) => setDocN(e.target.value)} />
            <IconContainer />
          </InputContainer>
        </CreaContainerColumn>

        <CreaContainerColumn>
          <InputContainer>
            <InputDescriptionText>Data inizio incarico:</InputDescriptionText>
            <InputTextbox disabled={true} placeholder="" value={dateStart} onChange={(e) => setDateStart(e.target.value)} />
            <IconContainer />
          </InputContainer>

          <InputContainer>
            <InputDescriptionText>Data fine incarico:</InputDescriptionText>
            <InputTextbox disabled={true} placeholder="" value={dateEnd} onChange={(e) => setDateEnd(e.target.value)} />
            <IconContainer />
          </InputContainer>

          <InputContainer>
            <InputDescriptionText>Data di oggi:</InputDescriptionText>
            <InputTextbox disabled={true} placeholder="" value={date} onChange={(e) => setDate(e.target.value)} />
            <IconContainer />
          </InputContainer>
        </CreaContainerColumn>
      </FlexContainerCrea>
      <DescriptionContainerCreaMultiple>
        <IconSquare src="/crea-L1-schedagenerale.svg" alt="Description Icon" />
        <DescriptionContainerCreaText>
          Inserire i dettagli identificativi dell'unità operativa, comprensivi di un nome completo che rifletta le competenze e i servizi offerti, un nome abbreviato per una rapida identificazione,
          oltre ai principali canali di comunicazione per facilitare un contatto efficace e una pronta corrispondenza
        </DescriptionContainerCreaText>
      </DescriptionContainerCreaMultiple>
    </OverlayComponentWhiteAdd1Multiple>
  );
};

export default MainComponent;
