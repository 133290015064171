import React, { useCallback, useEffect, useMemo, useState } from "react";
import { Outlet, useLocation, useNavigate } from "react-router-dom";
import styled from "styled-components";
import { OverlayContext } from "../cybersecurity-board-contestoleadership-organizzazione";

import {
  Backdrop,
  BackdropTransparent,
  BackgroundIcon,
  CloseMessageButton,
  FadeOutComponent,
  FrameTopGradient100,
  IconButton,
  IconsGroup,
  MessageContainer,
  MessageboxText,
} from "./cybersecurity-board-organizzazione-organigramma-styled-components";
import {
  Search,
  SearchIcon,
  SearchInputContainer,
  StyledPersone1Td,
  StyledPersone1Th,
  StyledPersone2Td,
  StyledPersone2Th,
  StyledTable,
  StyledTableItem,
  StyledTbody,
  StyledTd,
  StyledTh,
  StyledThead,
  TableContainer,
  TableItemText,
  TableScrollableDiv,
  TableTitleText,
  TopContainer,
} from "./cybersecurity-board-organizzazione-unitaoperative-styled-components";

import { useRecoilState } from "recoil";

import { URL_LV4List_short, URL_PersonRolesAll, useApi, useToken } from "../../../../API";
import { SelectedL1ID, SelectedL2ID, SelectedL3ID, SelectedL4ID, reFetchIndicatorL4 } from "../../../../recoil";
import { showL4initialPath, showL4isAnimating, showL4isVisible } from "./L4-Show-recoil";

interface DataItem {
  id: string;
  person_name: string;
  organization_id: string;
  organization_name: string;
  person_id: string;
  role_id: string;
  role_name: string;
  work_mobile_phone: string;
  work_email: string;
}

type SortableKeys = keyof DataItem;

type ApiContentItem = {
  id: string;
  person_name: string;
  organization_id: string;
  organization_name: string;
  person_id: string;
  role_id: string;
  role_name: string;
  work_mobile_phone: string;
  work_email: string;
};

type StyledTrProps = {
  selected: boolean;
};

const StyledTr = styled.tr<StyledTrProps>`
  cursor: pointer;
  background-color: ${(props) => {
    return props.selected ? "#f3f7fb" : "#ffffff";
  }};

  &:hover {
    background-color: #e9eff7;
  }
`;

interface OverlayComponentProps {
  isAnimating: boolean;
}

export function useOverlayControlL4Show(initialPath: string) {
  const [isVisibleL4, setIsVisibleL4] = useRecoilState(showL4isVisible);
  const [isAnimatingL4, setIsAnimatingL4] = useRecoilState(showL4isAnimating);
  const [originalPath, setOriginalPath] = useRecoilState(showL4initialPath);

  const navigate = useNavigate();

  const openOverlayL4 = useCallback(() => {
    navigate(initialPath);
    setIsVisibleL4(true);
    setTimeout(() => setIsAnimatingL4(true), 10);
  }, [navigate, initialPath]);

  const closeOverlayL4 = useCallback(() => {
    setIsAnimatingL4(false);
    setTimeout(() => {
      setIsVisibleL4(false);
      navigate(originalPath);
    }, 300);
  }, [navigate]);

  const toggleOverlayL4 = useCallback(() => {
    if (!isVisibleL4) {
      openOverlayL4();
    } else {
      closeOverlayL4();
    }
  }, [isVisibleL4, openOverlayL4, closeOverlayL4]);

  return {
    isVisibleL4,
    isAnimatingL4,
    toggleOverlayL4,
    openOverlayL4,
    closeOverlayL4,
  };
}

const OverlayComponentAdd1 = styled.div<OverlayComponentProps>`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  z-index: 10;
  transition: opacity 300ms ease-in-out, visibility 300ms ease-in-out;
  opacity: ${(props) => (props.isAnimating ? "1" : "0")};
  visibility: ${(props) => (props.isAnimating ? "visible" : "hidden")};
`;

const useDeletePerson = () => {
  const { del } = useApi();
  const [reFetchIndicator, setRefetchIndicator] = useRecoilState(reFetchIndicatorL4);
  const [isConfirmDialogVisible, setIsConfirmDialogVisible] = useState(false);
  const [L4ID, setL4ID] = useRecoilState(SelectedL4ID);

  const handleDelete = useCallback(async () => {
    if (!L4ID) return;

    const url = URL_LV4List_short;
    try {
      await del(url, L4ID);
      console.log(`Deleted person with ID: ${L4ID}`);
      setRefetchIndicator((prev) => prev + 1);
      setIsConfirmDialogVisible(false);
    } catch (error) {
      console.error("Failed to delete person:", error);
    }
  }, [L4ID, del, setRefetchIndicator]);

  const showDeleteConfirmation = useCallback(() => {
    setIsConfirmDialogVisible(true);
  }, []);

  return { handleDelete, showDeleteConfirmation, isConfirmDialogVisible, setIsConfirmDialogVisible };
};

const DataTable: React.FC<{ data: DataItem[] }> = ({ data }) => {
  const [isOverlayVisibleAdd2, setIsOverlayVisibleAdd2] = useState(false);
  const [isOverlayAnimatingAdd2, setIsOverlayAnimatingAdd2] = useState(false);

  const navigate = useNavigate();

  const toggleOverlayAdd2 = () => {
    if (!isOverlayVisibleAdd2) {
      if (window.location.pathname === "/Cybersecurity/Persone") {
        navigate(`Persone/CreaL4/1`);
      } else {
        navigate(`${window.location.pathname}/CreaL4/1`);
      }

      setIsOverlayVisibleAdd2(true);
      setTimeout(() => setIsOverlayAnimatingAdd2(true), 10);
    } else {
      setIsOverlayAnimatingAdd2(false);
      setTimeout(() => setIsOverlayVisibleAdd2(false), 310);
    }
  };

  const closeOverlayAdd2 = () => {
    navigate(`/Cybersecurity/Organizzazione/Persone`);
    setIsOverlayAnimatingAdd2(false);

    setTimeout(() => {
      setIsOverlayVisibleAdd2(false);
    }, 300);

    setIsOverlayAnimatingShow2(false);

    setTimeout(() => {
      setIsOverlayVisibleShow2(false);
    }, 300);

    setIsOverlayAnimatingEdit2(false);

    setTimeout(() => {
      setIsOverlayVisibleEdit2(false);
    }, 300);
  };

  const [isOverlayVisibleShow2, setIsOverlayVisibleShow2] = useState(false);
  const [isOverlayAnimatingShow2, setIsOverlayAnimatingShow2] = useState(false);

  const toggleOverlayShow2 = () => {
    if (!isOverlayVisibleShow2) {
      if (window.location.pathname === "/Cybersecurity/Persone") {
        navigate(`Persone/VisualizzaL4/1`);
      } else {
        navigate(`${window.location.pathname}/VisualizzaL4/1`);
      }

      setIsOverlayVisibleShow2(true);
      setTimeout(() => setIsOverlayAnimatingShow2(true), 10);
    } else {
      setIsOverlayAnimatingShow2(false);
      setTimeout(() => setIsOverlayVisibleShow2(false), 310);
    }
  };

  const closeOverlayShow2 = () => {
    navigate(`/Cybersecurity/Organizzazione/Persone`);
    setIsOverlayAnimatingShow2(false);

    setTimeout(() => {
      setIsOverlayVisibleShow2(false);
    }, 300);
  };

  const [isOverlayVisibleEdit2, setIsOverlayVisibleEdit2] = useState(false);
  const [isOverlayAnimatingEdit2, setIsOverlayAnimatingEdit2] = useState(false);

  const toggleOverlayEdit2 = () => {
    if (!isOverlayVisibleEdit2) {
      if (window.location.pathname === "/Cybersecurity/Persone") {
        navigate(`Persone/ModificaL4/1`);
      } else {
        navigate(`${window.location.pathname}/ModificaL4/1`);
      }

      setIsOverlayVisibleEdit2(true);
      setTimeout(() => setIsOverlayAnimatingEdit2(true), 10);
    } else {
      setIsOverlayAnimatingEdit2(false);
      setTimeout(() => setIsOverlayVisibleEdit2(false), 310);
    }
  };

  const closeOverlayEdit2 = () => {
    navigate(`/Cybersecurity/Organizzazione/Persone`);
    setIsOverlayAnimatingEdit2(false);

    setTimeout(() => {
      setIsOverlayVisibleEdit2(false);
    }, 300);
  };

  const [selectedL1, setSelectedL1] = useState<string | null>(null);
  const [selectedL2, setSelectedL2] = useState<string | null>(null);
  const [selectedL3, setSelectedL3] = useState<string | null>(null);
  const [selectedL4, setSelectedL4] = useState<string | null>(null);

  const [L1ID, setL1ID] = useRecoilState(SelectedL1ID);
  const [L2ID, setL2ID] = useRecoilState(SelectedL2ID);
  const [L3ID, setL3ID] = useRecoilState(SelectedL3ID);
  const [L4ID, setL4ID] = useRecoilState(SelectedL4ID);

  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const [isMessageBoxVisible, setIsMessageBoxVisible] = useState(false);

  const [sortConfig, setSortConfig] = useState<{ key: SortableKeys; direction: "ascending" | "descending" } | null>(null);

  const sortedData = useMemo(() => {
    let sortableItems = [...data];
    if (sortConfig !== null) {
      sortableItems.sort((a, b) => {
        const aValue = a[sortConfig.key];
        const bValue = b[sortConfig.key];

        if (typeof aValue === "string" && typeof bValue === "string") {
          return sortConfig.direction === "ascending" ? aValue.localeCompare(bValue) : bValue.localeCompare(aValue);
        }

        return 0;
      });
    }
    return sortableItems;
  }, [data, sortConfig]);

  const requestSort = (key: SortableKeys) => {
    let direction: "ascending" | "descending" = "ascending";
    if (sortConfig && sortConfig.key === key && sortConfig.direction === "ascending") {
      direction = "descending";
    }
    setSortConfig({ key, direction });
  };

  const [selectedId, setSelectedId] = useState<string | null>(null);

  const handleRowSelect = (id: string) => {
    setSelectedId((prevId) => {
      return id;
    });

    setL4ID(id);
  };

  useEffect(() => {
    console.log("L4ID now has the value:", L4ID);
  }, [L4ID]);

  const [globalFilterText, setGlobalFilterText] = useState("");

  const handleGlobalFilterChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setGlobalFilterText(event.target.value);
  };

  const [filterText, setFilterText] = useState("");

  const handleFilterChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setFilterText(event.target.value);
  };

  const filteredAndSortedData = useMemo(() => {
    const filteredData = data
      .filter((item) => {
        return item.person_name.toLowerCase().includes(filterText.toLowerCase());
      })
      .filter((item) => {
        if (globalFilterText) {
          const allText = Object.values(item).join(" ").toLowerCase();
          return allText.includes(globalFilterText.toLowerCase());
        }
        return true;
      });

    if (sortConfig !== null) {
      filteredData.sort((a, b) => {
        const aValue = a[sortConfig.key];
        const bValue = b[sortConfig.key];

        if (typeof aValue === "string" && typeof bValue === "string") {
          return sortConfig.direction === "ascending" ? aValue.localeCompare(bValue) : bValue.localeCompare(aValue);
        }
        return 0;
      });
    }

    return filteredData;
  }, [data, sortConfig, filterText, globalFilterText]);

  const { handleDelete, showDeleteConfirmation, isConfirmDialogVisible, setIsConfirmDialogVisible } = useDeletePerson();
  const [isVisibleShow, setIsVisibleShow] = useRecoilState(showL4isVisible);
  const [isAnimatingShow, setIsAnimatingShow] = useRecoilState(showL4isAnimating);
  const { toggleOverlayL4, isVisibleL4, isAnimatingL4, closeOverlayL4 } = useOverlayControlL4Show("/Cybersecurity/Organizzazione/Persone/VisualizzaL4/1");
  const [originalPath, setOriginalPath] = useRecoilState(showL4initialPath);
  const location = useLocation();

  const handleShowL4 = () => {
    setOriginalPath(location.pathname);
    toggleOverlayL4();
  };

  return (
    <TableContainer>
      <TopContainer>
        <SearchInputContainer>
          <Search placeholder="Filtra per nome e cognome" onChange={handleFilterChange} />
          <SearchIcon src="/funnel.svg" />
        </SearchInputContainer>
        <SearchInputContainer>
          <Search placeholder="Cerca in tutta la tabella" onChange={handleGlobalFilterChange} />
          <SearchIcon src="/search.svg" />
        </SearchInputContainer>

        <IconsGroup>
          <IconButton>
            <BackgroundIcon loading="eager" alt="" src="/doc.svg" onClick={handleShowL4} />
          </IconButton>
          <IconButton>
            <BackgroundIcon loading="eager" alt="" src="/edit.svg" onClick={toggleOverlayEdit2} />
          </IconButton>
          <IconButton>
            <BackgroundIcon loading="eager" alt="" src="/delete.svg" onClick={() => showDeleteConfirmation()} />
          </IconButton>
          <IconButton>
            <BackgroundIcon loading="eager" alt="" src="/add.svg" onClick={toggleOverlayAdd2} />
          </IconButton>
        </IconsGroup>
      </TopContainer>

      <StyledTable>
        <StyledThead>
          <StyledPersone1Th onClick={() => requestSort("person_name")}>
            <TableTitleText>▼ Nome e cognome</TableTitleText>
          </StyledPersone1Th>
          <StyledPersone2Th onClick={() => requestSort("organization_name")}>
            <TableTitleText>▼ Unità operativa</TableTitleText>
          </StyledPersone2Th>
          <StyledTh onClick={() => requestSort("work_mobile_phone")}>
            <TableTitleText>▼ Telefono</TableTitleText>
          </StyledTh>
          <StyledTh onClick={() => requestSort("work_email")}>
            <TableTitleText>▼ Email</TableTitleText>
          </StyledTh>
        </StyledThead>
      </StyledTable>
      <TableScrollableDiv>
        <StyledTableItem>
          <StyledTbody>
            {filteredAndSortedData.map((item) => (
              <StyledTr key={item.id} onClick={() => handleRowSelect(item.person_id)} selected={selectedId === item.person_id}>
                <StyledPersone1Td title={item.person_name}>
                  <TableItemText>{item.person_name}</TableItemText>
                </StyledPersone1Td>
                <StyledPersone2Td title={item.organization_name}>
                  <TableItemText>{item.organization_name}</TableItemText>
                </StyledPersone2Td>
                <StyledTd title={item.work_mobile_phone}>
                  <TableItemText>{item.work_mobile_phone}</TableItemText>
                </StyledTd>
                <StyledTd title={item.work_email}>
                  <TableItemText>{item.work_email}</TableItemText>
                </StyledTd>
              </StyledTr>
            ))}
          </StyledTbody>
        </StyledTableItem>
      </TableScrollableDiv>
      <OverlayContext.Provider value={{ closeOverlay: closeOverlayAdd2, selectedL1, setSelectedL1, selectedL2, setSelectedL2, selectedL3, setSelectedL3, selectedL4, setSelectedL4 }}>
        {isConfirmDialogVisible && (
          <Backdrop>
            <MessageContainer>
              <p>
                <MessageboxText>Sei sicuro di voler rimuovere questa persona?</MessageboxText>
              </p>
              <div style={{ display: "flex", justifyContent: "center", gap: "20px" }}>
                <CloseMessageButton onClick={() => setIsConfirmDialogVisible(false)}>
                  <MessageboxText>No</MessageboxText>
                </CloseMessageButton>
                <CloseMessageButton onClick={handleDelete}>
                  <MessageboxText>Rimuovi</MessageboxText>
                </CloseMessageButton>
              </div>
            </MessageContainer>
          </Backdrop>
        )}

        {isOverlayVisibleAdd2 && (
          <OverlayComponentAdd1 isAnimating={isOverlayAnimatingAdd2}>
            {/* <ButtonClose src="/close.svg" onClick={closeOverlayAdd2} /> */}
            <FadeOutComponent>
              <FrameTopGradient100></FrameTopGradient100>
            </FadeOutComponent>

            <Outlet />
          </OverlayComponentAdd1>
        )}
      </OverlayContext.Provider>

      <OverlayContext.Provider value={{ closeOverlay: closeOverlayAdd2, selectedL1, setSelectedL1, selectedL2, setSelectedL2, selectedL3, setSelectedL3, selectedL4, setSelectedL4 }}>
        {isOverlayVisibleEdit2 && (
          <OverlayComponentAdd1 isAnimating={isOverlayAnimatingEdit2}>
            {/* <ButtonClose src="/close.svg" onClick={closeOverlayAdd2} /> */}
            <FadeOutComponent>
              <FrameTopGradient100></FrameTopGradient100>
            </FadeOutComponent>

            <Outlet />
          </OverlayComponentAdd1>
        )}
      </OverlayContext.Provider>

      <OverlayContext.Provider value={{ closeOverlay: closeOverlayAdd2, selectedL1, setSelectedL1, selectedL2, setSelectedL2, selectedL3, setSelectedL3, selectedL4, setSelectedL4 }}>
        {isVisibleShow && (
          <OverlayComponentAdd1 isAnimating={isAnimatingShow}>
            {/* <ButtonClose src="/close.svg" onClick={closeOverlayAdd2} /> */}
            <FadeOutComponent>
              <FrameTopGradient100></FrameTopGradient100>
            </FadeOutComponent>

            <Outlet />
          </OverlayComponentAdd1>
        )}
      </OverlayContext.Provider>
    </TableContainer>
  );
};

const Board27001Dashboard: React.FC = () => {
  const [L1ID, setL1ID] = useRecoilState(SelectedL1ID);
  const [L2ID, setL2ID] = useRecoilState(SelectedL2ID);
  const [L3ID, setL3ID] = useRecoilState(SelectedL3ID);
  const [L4ID, setL4ID] = useRecoilState(SelectedL4ID);
  const [reFetchIndicator, setRefetchIndicator] = useRecoilState(reFetchIndicatorL4);

  const [data, setData] = useState<DataItem[]>([]);
  const [selectedL1, setSelectedL1] = useState<string | null>(null);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const [isMessageBoxVisible, setIsMessageBoxVisible] = useState(false);

  const token = useToken();

  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true);
      try {
        if (!token) throw new Error("User is not authenticated");

        const response = await fetch(URL_PersonRolesAll, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });

        if (!response.ok) {
          throw new Error(`API request failed: ${response.statusText}`);
        }

        const responseData = await response.json();
        console.log("Response Data:", responseData);

        const transformedData: DataItem[] = responseData.map((item: ApiContentItem) => {
          return {
            id: item.id,
            person_name: item.person_name,
            person_id: item.person_id,
            organization_id: item.organization_id,
            organization_name: item.organization_name,
            role_id: item.role_id,
            role_name: item.role_name,
            work_mobile_phone: item.work_mobile_phone,
            work_email: item.work_email,
          };
        });

        console.log("Transformed Data:", transformedData);
        setData(transformedData);
      } catch (err) {
        setError(err instanceof Error ? err.message : "An unknown error occurred");
        setIsMessageBoxVisible(true);
      } finally {
        setIsLoading(false);
      }
    };

    fetchData();
  }, [token, reFetchIndicator]);

  const { handleDelete, showDeleteConfirmation, isConfirmDialogVisible, setIsConfirmDialogVisible } = useDeletePerson();

  return (
    <div>
      {isMessageBoxVisible && (
        <Backdrop>
          <MessageContainer>
            <p>
              <MessageboxText>{error}</MessageboxText>
            </p>
            <CloseMessageButton onClick={() => setIsMessageBoxVisible(false)}>
              <MessageboxText>Close</MessageboxText>
            </CloseMessageButton>
          </MessageContainer>
        </Backdrop>
      )}

      {isLoading && (
        <BackdropTransparent>
          <MessageContainer>
            <MessageboxText>Loading...</MessageboxText>
          </MessageContainer>
        </BackdropTransparent>
      )}

      {error && (
        <Backdrop>
          <MessageContainer>
            <p>
              <MessageboxText>{error}</MessageboxText>
            </p>
            <CloseMessageButton
              onClick={() => {
                setIsMessageBoxVisible(false);
                setError(null);
              }}
            >
              <MessageboxText>Close</MessageboxText>
            </CloseMessageButton>
          </MessageContainer>
        </Backdrop>
      )}

      <DataTable data={data} />
    </div>
  );
};

export default Board27001Dashboard;
