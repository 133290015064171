import React from "react";
import { useLocation } from "react-router-dom";
import styled from "styled-components";

import { FrameBoardMain, MainBoardSubMenuButton, MainBoardSubMenuButtonContainer } from "../styled-components";

const Board27001Organizzazione: React.FC = () => {
  const location = useLocation();
  const isActive = (path: string) => {
    return location.pathname === "/Cybersecurity/RiskManagement" && path === "RiskManagement";
  };

  const ImageContainer = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    height: 80%;
    width: 80%;
    margin-top: 80px;
  `;

  const StyledImage = styled.img`
    max-width: 100%;
    height: auto;
    margin-top: -150px;
    margin-left: 200px;
  `;

  return (
    <FrameBoardMain>
      <MainBoardSubMenuButtonContainer>
        <MainBoardSubMenuButton to="/Cybersecurity/RiskManagement" className={isActive("RiskManagement") ? "active" : ""}>
          Risk management
        </MainBoardSubMenuButton>

        <MainBoardSubMenuButton to="/Cybersecurity/Metodo">Metodo</MainBoardSubMenuButton>

        <MainBoardSubMenuButton to="/Cybersecurity/Esempio">Esempio</MainBoardSubMenuButton>
      </MainBoardSubMenuButtonContainer>

      <ImageContainer>
        <StyledImage src={"../esempio.png"} alt={"alt"} />
      </ImageContainer>
    </FrameBoardMain>
  );
};

export default Board27001Organizzazione;
