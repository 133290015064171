import React from "react";
import { useLocation } from "react-router-dom";
import styled from "styled-components";
import { FrameBoardMain, MainBoardSubMenuButton, MainBoardSubMenuButtonContainer } from "../styled-components";

const Board27001Organizzazione: React.FC = () => {
  const location = useLocation();
  const isActive = (path: string) => {
    return location.pathname === "/Cybersecurity/StrumentiAggiuntivi" && path === "StrumentiAggiuntivi";
  };

  const ImageContainer = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    width: 100%;
    margin-top: -100px;
  `;

  const StyledImage = styled.img`
    max-width: 90%;
    height: auto;
  `;

  return (
    <FrameBoardMain>
      <MainBoardSubMenuButtonContainer>
        <MainBoardSubMenuButton to="/Cybersecurity/StrumentiAggiuntivi" className={isActive("StrumentiAggiuntivi") ? "active" : ""}>
          Strumentia aggiuntivi
        </MainBoardSubMenuButton>
        {/* <MainBoardSubMenuButton to="/Cybersecurity/PartiInteressate/Clienti">Clienti</MainBoardSubMenuButton>
        <MainBoardSubMenuButton to="/Cybersecurity/PartiInteressate/Fornitori">Fornitori</MainBoardSubMenuButton>
        <MainBoardSubMenuButton to="/Cybersecurity/PartiInteressate/AltriEnti">Altri enti</MainBoardSubMenuButton> */}
      </MainBoardSubMenuButtonContainer>

      <ImageContainer>
        <StyledImage src={"../strumenti_aggiuntivi.png"} alt={"alt"} />
      </ImageContainer>
    </FrameBoardMain>
  );
};

export default Board27001Organizzazione;
