import React, { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { OverlayContext } from "../cybersecurity-board-contestoleadership-organizzazione";

import { useRecoilState } from "recoil";

import SchedaGenerale from "./cybersecurity-board-infrastruttura-elencoasset-Add/infrastruttura-elencoasset-Add-1-SchedaGenerale";
import Organizzazione from "./cybersecurity-board-infrastruttura-elencoasset-Add/infrastruttura-elencoasset-Add-2-Organizzazione";

import Compliance from "./cybersecurity-board-infrastruttura-elencoasset-Add/infrastruttura-elencoasset-Add-6-GestioneCompliance";
import Documentazione, { useInitiateUploadAsset } from "./cybersecurity-board-infrastruttura-elencoasset-Add/infrastruttura-elencoasset-Add-7-Documentazione";

import { ButtonGrey, ButtonText } from "../../styled-components";

import dayjs from "dayjs";
import { URL_AssetDetails, URL_DirectorList, useApi, useToken } from "../../../../API";
import { PATH_Assets } from "../../../../docPaths";
import { reFetchIndicatorAssetsList } from "../../../../recoil";
import {
  Backdrop,
  BackdropTransparent,
  CloseMessageButton,
  CreaSubMenuButtonMultiple,
  FrameTopGradient100,
  MessageContainer,
  MessageboxText,
  OverlayComponentBackgroundAdd1,
  OverlayComponentWhiteAdd1,
  OverlayComponentWhiteButtonFrameAdd1,
  OverlayComponentWhiteTitleAdd1,
  SubMenuButtonContainerOrganigrammaMultipleShort,
} from "../../cybersecurity/cybersecurity-board-contestoleadership-organizzazione-sub/cybersecurity-board-organizzazione-organigramma-styled-components";
import {
  AssetAttachedFilesInfo,
  AssetComplianceDate,
  AssetDocumentsAttached,
  AssetID,
  AssetIdentificativo,
  AssetName,
  AssetSelectedCategoryNode,
  addAssetAssetStatusID,
  addAssetDirectorCompliance,
  addAssetDirectorComplianceID,
  addAssetDirectorID,
  addAssetLV1List,
  addAssetLV1ListID,
  addAssetLV2List,
  addAssetLV2ListID,
  addAssetLV3List,
  addAssetLV3ListID,
  addAssetSelectedCategoryID,
  addAssetSpecificheTecniche,
} from "./ElencoAsset-Add-recoil";

function generateUUID(): string {
  return "xxxxxxxx-xxxx-xxxx-yxxx-xxxxxxxxxxxx".replace(/[xy]/g, (c) => {
    const r = (Math.random() * 16) | 0;
    const v = c === "x" ? r : (r & 0x3) | 0x8;
    return v.toString(16);
  });
}

function useGenerateUUID(): string {
  const [assID, setAssID] = useRecoilState<string>(AssetID);

  useEffect(() => {
    const newUUID = generateUUID();
    setAssID(newUUID);
    console.log("New asset ID: ", newUUID);
  }, [setAssID]);

  return assID;
}

const YourAddL1Component = () => {
  const [assID, setAssID] = useRecoilState(AssetID);
  const [LV1List, setLV1List] = useRecoilState(addAssetLV1List);
  const [LV2List, setLV2List] = useRecoilState(addAssetLV2List);
  const [LV3List, setLV3List] = useRecoilState(addAssetLV3List);
  const [selectedL1, setSelectedL1] = useRecoilState(addAssetLV1ListID);
  const [selectedL2, setSelectedL2] = useRecoilState(addAssetLV2ListID);
  const [selectedL3, setSelectedL3] = useRecoilState(addAssetLV3ListID);
  const [nomeCompleto, setNomeCompleto] = useRecoilState(AssetName);
  const [identificativo, setIdentificativo] = useRecoilState(AssetIdentificativo);
  const [specifiche, setSpecifiche] = useRecoilState(addAssetSpecificheTecniche);
  const [status, setStatus] = useRecoilState(addAssetAssetStatusID);
  const [category, setCategory] = useRecoilState(addAssetSelectedCategoryID);
  const [director, setDirector] = useRecoilState(addAssetDirectorID);
  const [ComplianceDate, setComplianceDate] = useRecoilState(AssetComplianceDate);
  const [ComplianceDirettoreCompliance, setComplianceDirettoreCompliance] = useRecoilState(addAssetDirectorCompliance);
  const [ComplianceDirettoreComplianceID, setComplianceDirettoreComplianceID] = useRecoilState(addAssetDirectorComplianceID);
  const [AttachedFilesInfo, setAttachedFilesInfo] = useRecoilState(AssetAttachedFilesInfo);
  const [DocumentsAttached, setDocumentsAttached] = useRecoilState(AssetDocumentsAttached);
  const [DocumentsPath, setDocumentsPath] = useRecoilState(AssetIdentificativo);
  const [selectedNodeId, setSelectedNodeId] = useRecoilState(AssetSelectedCategoryNode);

  useGenerateUUID();
  const { closeOverlay } = useContext(OverlayContext);

  const navigate = useNavigate();

  const token = useToken();
  const [data, setData] = useState<DirectorDataItem[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const [isMessageBoxVisible, setIsMessageBoxVisible] = useState(false);
  const [isMessageBoxVisibleClosing, setIsMessageBoxVisibleClosing] = useState(false);
  const [message, setMessage] = useState("");
  const [reFetchIndicator, setRefetchIndicator] = useRecoilState(reFetchIndicatorAssetsList);

  const { post } = useApi();

  const initiateUpload = useInitiateUploadAsset();

  const handleSave = async () => {
    if (!AssetName || !identificativo || !status || !category || !selectedL1 || !selectedL2 || !selectedL3) {
      setIsMessageBoxVisibleClosing(true);
      setMessage("Si prega di compilare tutti i campi obbligatori: Nome, Identificativo, Stato, Categoria, Componente organizzativa, Unità operativa e Luogo");
      return;
    }

    const newSiteData = {
      id: assID,
      name: nomeCompleto,
      hasOrganization: `organizations.ecubit02`,
      hasCategory: category,
      inventoryN: identificativo,
      hasResponsible: director,
      hasUnit: selectedL2,
      hasPlace: selectedL3,
      state: status,
      has_description: specifiche,
      has_cybersecurity_responsible: ComplianceDirettoreComplianceID,
      cybersecurity_responsible_assignment_start: ComplianceDate ? dayjs(ComplianceDate).format("YYYY-MM-DD HH:mm:ss") : null,
      has_documents_folder_link: `${PATH_Assets}${identificativo}`,
    };

    console.log(JSON.stringify(newSiteData));

    try {
      setIsLoading(true);

      const responseData = await post(URL_AssetDetails, newSiteData);
      initiateUpload();
      setRefetchIndicator((prev) => prev + 1);
      console.log("Asset created successfully:", responseData);
      setMessage("Asset created successfully!");
      setIsLoading(false);
      setIsMessageBoxVisible(true);

      setNomeCompleto("");
      setCategory("");
      setIdentificativo("");
      setDirector("");
      setSelectedL1("");
      setSelectedL2("");
      setSelectedL3("");
      setLV1List("");
      setLV2List("");
      setLV3List("");
      setStatus("");
      setSpecifiche("");
      setAttachedFilesInfo([]);
      setComplianceDirettoreCompliance("");
      setComplianceDirettoreComplianceID("");
      setDocumentsAttached([]);
      setDocumentsPath("");
      setSelectedNodeId("");
    } catch (error) {
      console.error("Error creating asset:", error);
      setMessage(`Error creating asset: ${error instanceof Error ? error.message : "Unknown error"}`);
      setIsLoading(false);
      setIsMessageBoxVisibleClosing(true);
    }
  };

  type DirectorApiContentItem = {
    id: string;
    alias: string;
  };

  type DirectorDataItem = {
    director_id: string;
    director_name: string;
  };

  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true);
      try {
        if (!token) throw new Error("User is not authenticated");

        const response = await fetch(URL_DirectorList, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });

        if (!response.ok) {
          throw new Error(`API request failed: ${response.statusText}`);
        }

        const responseData = await response.json();
        console.log("Response Data:", responseData);

        const transformedData: DirectorDataItem[] = responseData.map((item: DirectorApiContentItem) => {
          return {
            director_id: item.id,
            director_name: item.alias,
          };
        });

        console.log("Transformed Data:", transformedData);
        setData(transformedData);
      } catch (err) {
        setError(err instanceof Error ? err.message : "An unknown error occurred");
        setIsMessageBoxVisible(true);
      } finally {
        setIsLoading(false);
      }
    };

    fetchData();
  }, [token]);

  const directorNameToId = new Map(data.map((item) => [item.director_name, item.director_id]));

  const handleCloseAndNavigate = () => {
    setIsMessageBoxVisible(false);
    closeOverlay();
  };

  const handleClose = () => {
    setIsMessageBoxVisibleClosing(false);
  };

  const [activeComponent, setActiveComponent] = useState("SchedaGenerale");

  const changeActiveComponent = (componentName: string) => {
    setActiveComponent(componentName);
  };

  const renderActiveComponent = () => {
    switch (activeComponent) {
      case "SchedaGenerale":
        return <SchedaGenerale />;
      case "Organizzazione":
        return <Organizzazione />;

      case "SistemaCompliance":
        return <Compliance />;
      case "Documentazione":
        return <Documentazione />;
      default:
        return <SchedaGenerale />;
    }
  };

  return (
    <OverlayComponentBackgroundAdd1>
      {isLoading && (
        <BackdropTransparent>
          <MessageContainer>
            <MessageboxText>Loading...</MessageboxText>
          </MessageContainer>
        </BackdropTransparent>
      )}

      {isMessageBoxVisible && (
        <Backdrop>
          <MessageContainer>
            <p>
              <MessageboxText>{message}</MessageboxText>
            </p>
            <CloseMessageButton onClick={handleCloseAndNavigate}>
              <MessageboxText>Chiudi</MessageboxText>
            </CloseMessageButton>
          </MessageContainer>
        </Backdrop>
      )}

      {isMessageBoxVisibleClosing && (
        <Backdrop>
          <MessageContainer>
            <p>
              <MessageboxText>{message}</MessageboxText>
            </p>
            <CloseMessageButton onClick={handleClose}>
              <MessageboxText>Chiudi</MessageboxText>
            </CloseMessageButton>
          </MessageContainer>
        </Backdrop>
      )}

      {error && (
        <Backdrop>
          <MessageContainer>
            <p>
              <MessageboxText>{error}</MessageboxText>
            </p>
            <CloseMessageButton
              onClick={() => {
                setIsMessageBoxVisible(false);
                setError(null);
                closeOverlay();
              }}
            >
              <MessageboxText>Close</MessageboxText>
            </CloseMessageButton>
          </MessageContainer>
        </Backdrop>
      )}

      <FrameTopGradient100 />
      <OverlayComponentWhiteTitleAdd1>NUOVO ASSET</OverlayComponentWhiteTitleAdd1>
      <OverlayComponentWhiteAdd1>
        <SubMenuButtonContainerOrganigrammaMultipleShort>
          <CreaSubMenuButtonMultiple onClick={() => changeActiveComponent("SchedaGenerale")} to="/Cybersecurity/Infrastruttura/StatoAsset/CreaStatoAsset/1">
            Scheda generale
          </CreaSubMenuButtonMultiple>
          <CreaSubMenuButtonMultiple onClick={() => changeActiveComponent("Organizzazione")} to="/Cybersecurity/Infrastruttura/StatoAsset/CreaStatoAsset/2">
            Organizzazione
          </CreaSubMenuButtonMultiple>
          {/* <CreaSubMenuButtonMultiple
            onClick={() => changeActiveComponent("Infrastruttura")}
            to="/Cybersecurity/Organizzazione/StatoAsset/CreaStatoAsset/3"
          >
            Infrastruttura
          </CreaSubMenuButtonMultiple> */}
          {/* <CreaSubMenuButtonMultiple
            onClick={() => changeActiveComponent("ProcessiGenerali")}
            to="/Cybersecurity/Infrastruttura/StatoAsset/CreaStatoAsset/4"
          >
            Processi generali
          </CreaSubMenuButtonMultiple> */}
          {/* <CreaSubMenuButtonMultiple
            onClick={() => changeActiveComponent("Infrastruttura")}
            to="/Cybersecurity/Organizzazione/StatoAsset/CreaStatoAsset/5"
          >
            Parti interessate
          </CreaSubMenuButtonMultiple> */}
          <CreaSubMenuButtonMultiple onClick={() => changeActiveComponent("SistemaCompliance")} to="/Cybersecurity/Infrastruttura/StatoAsset/CreaStatoAsset/6">
            Sistema gestione Compliance
          </CreaSubMenuButtonMultiple>
          <CreaSubMenuButtonMultiple onClick={() => changeActiveComponent("Documentazione")} to="/Cybersecurity/Infrastruttura/StatoAsset/CreaStatoAsset/7">
            Documentazione
          </CreaSubMenuButtonMultiple>
        </SubMenuButtonContainerOrganigrammaMultipleShort>

        {renderActiveComponent()}
      </OverlayComponentWhiteAdd1>

      <OverlayComponentWhiteButtonFrameAdd1>
        <ButtonGrey onClick={closeOverlay}>
          <ButtonText>Annulla</ButtonText>
        </ButtonGrey>
        <ButtonGrey onClick={handleSave}>
          <ButtonText>Crea</ButtonText>
        </ButtonGrey>
      </OverlayComponentWhiteButtonFrameAdd1>
    </OverlayComponentBackgroundAdd1>
  );
};

export default YourAddL1Component;
