import React from "react";
import { useNavigate } from "react-router-dom";
import Accordion from "./accordion";

import {
  ArrowWhiteRight,
  FrameButtonIcon,
  FrameLeftNav,
  FrameNavButtons,
  FrameNavLogo,
  IcoButtonIcon,
  NavButtonBlue,
  NavButtonBlueText,
  PicLogoIcon,
  ScrollableDivNav
} from "./styled-components";

interface NavButtonBlueTextProps {
  to: string;
  children: React.ReactNode;
}

const NavButton: React.FC<NavButtonBlueTextProps> = ({ to, children }) => {
  const navigate = useNavigate();

  const handleNavigate = () => {
    navigate(to);
  };

  return <NavButtonBlue onClick={handleNavigate}>{children}</NavButtonBlue>;
};

const IsoMenu: React.FC = () => {
  return (
    <FrameLeftNav>
      {/* <FrameNavWorkspace>
         <WorkspaceLine1>WORKSPACE 1234567890</WorkspaceLine1>
         <WorkspaceLine2>Ecubit srl</WorkspaceLine2>
        </FrameNavWorkspace>
        */}
      <ScrollableDivNav>
        <FrameNavButtons>
          <Accordion />

          <NavButton to="Cybersecurity/Documentazione">
            <NavButtonBlueText>Documentazione</NavButtonBlueText>
            <ArrowWhiteRight alt="" src="/arrow-white-right.svg" />
            <FrameButtonIcon>
              <IcoButtonIcon alt="" src="/documentazione.svg" />
            </FrameButtonIcon>
          </NavButton>

          <NavButton to="Cybersecurity/RiskManagement">
            <NavButtonBlueText>Risk management</NavButtonBlueText>
            <ArrowWhiteRight alt="" src="/arrow-white-right.svg" />
            <FrameButtonIcon>
              <IcoButtonIcon alt="" src="/documentazione.svg" />
            </FrameButtonIcon>
          </NavButton>

          <NavButton to="Cybersecurity/StrumentiAggiuntivi">
            <NavButtonBlueText>Strumenti aggiuntivi</NavButtonBlueText>
            <ArrowWhiteRight alt="" src="/arrow-white-right.svg" />
            <FrameButtonIcon>
              <IcoButtonIcon alt="" src="/documentazione.svg" />
            </FrameButtonIcon>
          </NavButton>
        </FrameNavButtons>
      </ScrollableDivNav>
      <FrameNavLogo>
        <PicLogoIcon alt="" src="/logo.svg" />
      </FrameNavLogo>
    </FrameLeftNav>
  );
};

export default IsoMenu;
