import dayjs from "dayjs";
import { atom } from "recoil";

//ADD L2 SCHEDA GENERALE

export const addL2OrganizationID = atom({
  key: "addL2OrganizationID",
  default: "",
});

export const addL2SchedaGeneraleNomeCompleto = atom({
  key: "addL2SchedaGeneraleNomeCompleto",
  default: "",
});

export const addL2SchedaGeneraleNomeAbbreviato = atom({
  key: "addL2SchedaGeneraleNomeAbbreviato",
  default: "",
});

export const addL2SchedaGeneraleReferente = atom({
  key: "addL2SchedaGeneraleReferente",
  default: "",
});

export const addL2SchedaGeneraleIndirizzo = atom({
  key: "addL2SchedaGeneraleIndirizzo",
  default: "",
});

export const addL2SchedaGeneraleEmail = atom({
  key: "addL2SchedaGeneraleEmail",
  default: "",
});

export const addL2SchedaGeneraleTelefono = atom({
  key: "addL2SchedaGeneraleTelefono",
  default: "",
});

export const addL2SchedaGeneraleFax = atom({
  key: "addL2SchedaGeneraleFax",
  default: "",
});

export const addL2SchedaGeneralePEC = atom({
  key: "addL2SchedaGeneralePEC",
  default: "",
});

//ADD L2 ORGANIZZAZIONE

export const addL2OrganizzazioneSelectedL1 = atom({
  key: "addL2OrganizzazioneSelectedL1",
  default: "",
});

export const addL2OrganizzazioneSelectedL1ID = atom({
  key: "addL2OrganizzazioneSelectedL1ID",
  default: "",
});

export const addL2OrganizzazioneDirettore = atom({
  key: "addL2OrganizzazioneDirettore",
  default: "",
});

export const addL2OrganizzazioneDirettoreID = atom({
  key: "addL2OrganizzazioneDirettoreID",
  default: "",
});

export const addL2OrganizzazioneModello = atom({
  key: "addL2OrganizzazioneModello",
  default: "",
});

export const addL2OrganizzazionePersonale = atom({
  key: "addL2OrganizzazionePersonale",
  default: "",
});

export const addL2OrganizzazioneDH = atom({
  key: "addL2OrganizzazioneDH",
  default: "",
});

export const addL2OrganizzazioneOrdinaria = atom({
  key: "addL2OrganizzazioneOrdinaria",
  default: "",
});

//ADD L2 INFRASTRUTTURA

export const selectedItemsAsset = atom<string[]>({
  key: "selectedItemsAsset", // Unique ID (with respect to other atoms/selectors)
  default: [], // Default value (aka initial value)
});

//ADD L2 COMPLIANCE

export const addL2ComplianceDirettoreCompliance = atom({
  key: "addL2ComplianceDirettoreCompliance",
  default: "",
});

export const addL2ComplianceDirettoreComplianceID = atom({
  key: "addL2ComplianceDirettoreComplianceID",
  default: "",
});

export const addL2ComplianceDate = atom<dayjs.Dayjs | null>({
  key: "addL2ComplianceDate",
  //default: dayjs(), // Initialize with the current date
  default: null,
});

//ADD L2 DOCUMENTS

export const addL2DocumentsPath = atom({
  key: "addL2DocumentsPath",
  default: "",
});

// Assuming addL2DocumentsAttached is defined to store File objects
export const addL2DocumentsAttached = atom<File[]>({
  key: "addL2DocumentsAttached",
  default: [],
});

export const addL2AttachedFilesInfo = atom<Array<{ name: string; size: number; type: string } | null>>({
  key: "addL2AttachedFilesInfo",
  default: [],
});

// STATES

export interface addL2ComplianceData {
  id: string;
  name: string;
  kind: string;
  objective: string;
  description: string;
  has_compliance_responsible: string;
  compliance_responsible_assignment_start: string;
}

export const addL2ComplianceDataState = atom<addL2ComplianceData[]>({
  key: "addL2ComplianceDataState", // Unique ID (with respect to other atoms/selectors)
  default: [], // Default value (aka initial value)
});

export const addL2isConfirmDialogVisible = atom<boolean>({
  key: "addL2isConfirmDialogVisible", // Unique ID (with respect to other atoms/selectors)
  default: false, // Default value (aka initial value)
});

type CheckboxProcessiIdType = string;

export const addL2selectedCheckboxesProcessiState = atom<CheckboxProcessiIdType[]>({
  key: "addL2selectedCheckboxesProcessiState",
  default: [], // default to an empty array
});

export const showL2selectedCheckboxesProcessiState = atom<CheckboxProcessiIdType[]>({
  key: "showL2selectedCheckboxesProcessiState",
  default: [], // default to an empty array
});

export interface L2ShowProcess {
  content: {
    id: string;
    has_business_process: string;
    business_process: {
      name: string;
      kind: string;
      objective: string;
      description: string;
    };
  };
  selected: boolean;
}

export const showL2ProcessDataState = atom<L2ShowProcess[]>({
  key: "showL2ProcessDataState", // Unique ID (with respect to other atoms/selectors)
  default: [], // Default value (aka initial value)
});

export const addL2searchTerm = atom({
  key: "addL2searchTerm",
  default: "",
});

//// PROCESSI STATE

export interface addL2Role {
  id: string;
  role_description: string;
  responsibility: string;
}

export interface addL2Model {
  id: string;
  organizational_unit: {
    id: string;
    prefLabel: string;
  };
  company_role: addL2Role[];
}

export const addL2ModelDataState = atom<addL2Model[]>({
  key: "addL2ModelDataState", // Unique ID (with respect to other atoms/selectors)
  default: [], // Default value (aka initial value)
});

export const addL2RoleState = atom<addL2Role[]>({
  key: "addL2RoleState", // Unique ID (with respect to other atoms/selectors)
  default: [], // Default value (aka initial value)
});

export const addL2selectedAggrId = atom({
  key: "addL2selectedAggrId",
  default: "",
});

export const addL2selectedModelId = atom({
  key: "addL2selectedModelId",
  default: "",
});

export const addL2selectedModelName = atom({
  key: "addL2selectedModelName",
  default: "",
});

export type FileListItem = {
  id: string;
  path: string;
  name: string;
};

export const showL2FileListItemState = atom<FileListItem[]>({
  key: "showL2FileListItemState",
  default: [],
});

export const showL2isVisible = atom({
  key: "showL2isVisible",
  default: false,
});

export const showL2isAnimating = atom({
  key: "showL2isAnimating",
  default: false,
});

export const showL2initialPath = atom({
  key: "showL2initialPath",
  default: "",
});

//AGGIUNGI COMPLIANCE

export interface L2AddComplianceData {
  id: string;
  selected: boolean;

  compliance_process: {
    id: string;
    name: string;
    description: string;
    kind: string;
    norm_ref: string;
    note: string;
  };

  business_process: {
    id: string;
    name: string;
    kind: string;
  };
}

//

export interface ComplianceProcess {
  id: string;
  name: string;
  description: string;
  kind: string;
  norm_ref: string;
  note: string;
  has_compliance_responsible: string;
  compliance_responsible_assignment_start: string;
  compliance_responsible_alias: string;
}

export interface BusinessProcess {
  id: string;
  name: string;
  kind: string;
  complianceProcesses: ComplianceProcess[];
}

export type BusinessProcesses = Record<string, BusinessProcess>;

export interface GroupedData {
  [kind: string]: {
    kind: string;
    businessProcesses: { [id: string]: BusinessProcess };
  };
}

//
export const L2AddComplianceDataState = atom<GroupedData>({
  key: "L2AddComplianceDataState",
  default: {},
});

export const L2AddComplianceDataStateAdd = atom<L2AddComplianceData[]>({
  key: "L2AddComplianceDataStateAdd",
  default: [],
});

export interface L2AddUnitComplianceAssociation {
  compliance_id: string;
  compliance_name: string;
  compliance_description: string;
  compliance_kind: string;
  business_id: string;
  business_name: string;
  business_description: string;
  business_kind: string;
  business_objective: string;
  selected: boolean;
}

export const L2AddUnitComplianceAssociationState = atom<L2AddUnitComplianceAssociation[]>({
  key: "editAssetUnitComplianceAssociationState",
  default: [],
});

export const L2AddComplianceisVisible = atom({
  key: "L2AddComplianceisVisible",
  default: false,
});

export const L2AddComplianceSearchTerm = atom({
  key: "L2AddComplianceSearchTerm",
  default: "",
});

type CheckboxComplianceIdType = string;

export const L2AddselectedCheckboxesComplianceState = atom<CheckboxComplianceIdType[]>({
  key: "L2AddselectedCheckboxesComplianceState",
  default: [], // default to an empty array
});

export const L2AddComplianceDate = atom<dayjs.Dayjs | null>({
  key: "L2AddComplianceDate",
  //default: dayjs(),
  default: null,
});

export const L2AddDirectorCompliance = atom({
  key: "L2AddDirectorCompliance",
  default: "",
});

export const L2AddDirectorComplianceID = atom({
  key: "L2AddDirectorComplianceID",
  default: "",
});

export const L2AddComplianceProcessByUnitByProcess = atom({
  key: "L2AddComplianceProcessByUnitByProcess",
  default: "",
});

export interface Unit {
  id: string;
  creationTs: string;
  updateTs: string;
  createdBy: string;
  updatedBy: string;
  prefLabel: string;
  subOrganizationOf: string;
  hasOrganizationalCard: string;
  isOrganizationalUnit: boolean;
  hasUnitResponsible: string;
  isFormalOrganization: boolean;
  hasDomain: string;
  hasEntityLegalForm: string;
  identifier: string;
  hasRegisteredSite: string;
  hasModel: string;
  hasPrimarySite: string;
  pec: string;
  hasLegalRepresentative: string;
  hasDocumentsFolderLink: string;
  ordinary_activity: string;
  dh_activity: string;
  has_compliance_responsible: string;
  compliance_responsible_assignment_start: string;
  compliance_responsible: string | null;
  primary_site: {
    id: string;
    creationTs: string;
    updateTs: string;
    createdBy: string;
    updatedBy: string;
    site_name: string;
    site_short_name: string;
    has_site_address: string;
    site_email: string;
    site_telephone: string;
    has_site_responsible: string;
    fax: string;
    has_compliance_responsible: string;
    compliance_responsible_assignment_start: string;
    has_documents_folder_link: string;
    compliance_responsible: string | null;
    site_responsible: string | null;
  };
  responsible: {
    id: string;
    creationTs: string;
    updateTs: string;
    createdBy: string;
    updatedBy: string;
    surname: string;
    name: string;
    has_personal_card: string;
    member_of_formal_organization: string;
    reports_to: string;
    has_smart_working_site: string;
    documents_folder_link: string;
    based_at: string;
  };
  organizationalCard: any; // Use specific type if known
  organizationModel: any; // Use specific type if known
  entityLegalForm: any; // Use specific type if known
}

export interface ComplianceDataGen {
  content: {
    id: string;
    creationTs: string | null;
    updateTs: string | null;
    createdBy: string;
    updatedBy: string;
    has_business_process: string;
    has_unit: string;
    unit: Unit;
    business_process: BusinessProcess;
  };
  selected: boolean;
}

export const complianceDataState = atom<ComplianceDataGen[]>({
  key: "complianceDataState",
  default: [], // Default value (aka initial value)
});
