import React, { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { OverlayContext } from "../cybersecurity-board-contestoleadership-organizzazione";

import { useRecoilState } from "recoil";

import {
  addL2AttachedFilesInfo,
  addL2ComplianceDate,
  addL2ComplianceDirettoreCompliance,
  addL2ComplianceDirettoreComplianceID,
  addL2DocumentsAttached,
  addL2DocumentsPath,
  addL2OrganizationID,
  addL2OrganizzazioneDH,
  addL2OrganizzazioneDirettore,
  addL2OrganizzazioneDirettoreID,
  addL2OrganizzazioneModello,
  addL2OrganizzazioneOrdinaria,
  addL2OrganizzazionePersonale,
  addL2OrganizzazioneSelectedL1,
  addL2OrganizzazioneSelectedL1ID,
  addL2SchedaGeneraleEmail,
  addL2SchedaGeneraleFax,
  addL2SchedaGeneraleIndirizzo,
  addL2SchedaGeneraleNomeAbbreviato,
  addL2SchedaGeneraleNomeCompleto,
  addL2SchedaGeneralePEC,
  addL2SchedaGeneraleReferente,
  addL2SchedaGeneraleTelefono,
  addL2selectedCheckboxesProcessiState,
  complianceDataState
} from "./L2-Add-recoil";

import SchedaGenerale from "./cybersecurity-board-org-org-L2-Add-sub/organigramma-L2-Add-1-SchedaGenerale";
import Organizzazione from "./cybersecurity-board-org-org-L2-Add-sub/organigramma-L2-Add-2-Organizzazione";

import Processi from "./cybersecurity-board-org-org-L2-Add-sub/organigramma-L2-Add-4-Processi";

import Documentazione, { useInitiateUploadL2 } from "./cybersecurity-board-org-org-L2-Add-sub/organigramma-L2-Add-7-Documentazione";

import { ButtonGrey, ButtonText } from "../../styled-components";

import {
  Backdrop,
  BackdropTransparent,
  CloseMessageButton,
  CreaSubMenuButtonMultiple,
  FrameTopGradient100,
  MessageboxText,
  MessageContainer,
  OverlayComponentBackgroundAdd1,
  OverlayComponentWhiteAdd1,
  OverlayComponentWhiteButtonFrameAdd1,
  OverlayComponentWhiteTitleAdd1,
  SubMenuButtonContainerOrganigrammaMultipleShort,
} from "./cybersecurity-board-organizzazione-organigramma-styled-components";

import { URL_DirectorList, URL_LV2Card, URL_ProcessPost, useApi, useToken } from "../../../../API";
import { PATH_L2 } from "../../../../docPaths";
import { isLoadingRec, isMessageBoxVisibleClosingRec, isMessageBoxVisibleRec, reFetchIndicatorL2, setErrorRec, setMessageRec } from "../../../../recoil";

function generateUUID(): string {
  return "xxxxxxxx-xxxx-xxxx-yxxx-xxxxxxxxxxxx".replace(/[xy]/g, (c) => {
    const r = (Math.random() * 16) | 0;
    const v = c === "x" ? r : (r & 0x3) | 0x8;
    return v.toString(16);
  });
}

function useGenerateUUID(): string {
  const [organizationIdPost, setOrganizationIdPost] = useRecoilState<string>(addL2OrganizationID);

  useEffect(() => {
    const newUUID = generateUUID();
    setOrganizationIdPost(newUUID);
    console.log("New person ID: ", newUUID);
  }, [setOrganizationIdPost]);

  return organizationIdPost;
}

export const useFetchSelectionData = (token: string | null | undefined) => {
  const [ID, setID] = useRecoilState(addL2OrganizationID);
  const [complianceData, setComplianceData] = useRecoilState(complianceDataState);
  const [isLoading, setIsLoading] = useRecoilState(isLoadingRec);
  const [error, setError] = useRecoilState(setErrorRec);
  const [nomeCompleto, setNomeCompleto] = useRecoilState(addL2SchedaGeneraleNomeCompleto);
  const [nomeAbbreviato, setNomeAbbreviato] = useRecoilState(addL2SchedaGeneraleNomeAbbreviato);
  const [componenteOrg, setComponenteOrg] = useRecoilState(addL2OrganizzazioneSelectedL1ID);
  const [isMessageBoxVisibleClosing, setIsMessageBoxVisibleClosing] = useRecoilState(isMessageBoxVisibleClosingRec);
  const [message, setMessage] = useRecoilState(setMessageRec);

  const fetchSelectionData = async () => {
    if (!nomeCompleto || !nomeAbbreviato || !componenteOrg) {
      return;
    }
    setIsLoading(true);
    setError(null);

    const url = `${URL_ProcessPost}/${ID}`;

    try {
      const response = await fetch(url, {
        headers: { Authorization: `Bearer ${token}` },
      });
      if (!response.ok) {
        throw new Error(`API request failed: ${response.statusText}`);
      }
      const data = await response.json();
      setComplianceData(data);
      console.log(`fetchSelectionData: `, data);
    } catch (err) {
      setError(err instanceof Error ? err.message : "An unknown error occurred");
    } finally {
      setIsLoading(false);
    }
  };
  return { fetchSelectionData, isLoading, error };
};

export const useProcessSave = () => {
  const [ID] = useRecoilState(addL2OrganizationID);
  const [complianceData] = useRecoilState(complianceDataState);
  const [isLoading, setIsLoading] = useRecoilState(isLoadingRec);
  const [isMessageBoxVisibleClosing, setIsMessageBoxVisibleClosing] = useRecoilState(isMessageBoxVisibleClosingRec);
  const [message, setMessage] = useRecoilState(setMessageRec);
  const [selectedCheckboxes, setSelectedCheckboxes] = useRecoilState(addL2selectedCheckboxesProcessiState);
  const [error, setError] = useRecoilState(setErrorRec);
  const token = useToken();
  const [nomeCompleto, setNomeCompleto] = useRecoilState(addL2SchedaGeneraleNomeCompleto);
  const [nomeAbbreviato, setNomeAbbreviato] = useRecoilState(addL2SchedaGeneraleNomeAbbreviato);

  const handleSaveProcessi = async () => {
    setIsLoading(true);
    try {
      const updatedProcesses = complianceData.map((dataItem) => ({
        ...dataItem,
        selected: selectedCheckboxes.includes(dataItem.content.business_process.id),
      }));

      console.log("Checkboxes Processes for Submission:", selectedCheckboxes);
      console.log("Updated Processes for Submission:", updatedProcesses);

      const requestBody = JSON.stringify(updatedProcesses);
      console.log("Sending selected Body:", requestBody);

      const url = `${URL_ProcessPost}/${ID}`;
      const response = await fetch(url, {
        method: "PATCH",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        body: requestBody,
      });

      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      } else {
        console.log("Response OK");
        setSelectedCheckboxes([]);
      }
    } catch (error) {
      console.error("Error during update process:", error);
      setError(error instanceof Error ? error.message : "An unknown error occurred");
      setMessage(`Error during update: ${error instanceof Error ? error.message : "Unknown error"}`);
    } finally {
      setIsLoading(false);
    }
  };

  return {
    handleSaveProcessi,
    isLoading,
    error,
    message,

    setError,
    setIsLoading,
    setMessage,
  };
};

export function useSaveOrganization() {
  const [ID, setID] = useRecoilState(addL2OrganizationID);
  const [nomeCompleto, setNomeCompleto] = useRecoilState(addL2SchedaGeneraleNomeCompleto);
  const [nomeAbbreviato, setNomeAbbreviato] = useRecoilState(addL2SchedaGeneraleNomeAbbreviato);
  const [Email, setEmail] = useRecoilState(addL2SchedaGeneraleEmail);
  const [Telefono, setTelefono] = useRecoilState(addL2SchedaGeneraleTelefono);
  const [Fax, setFax] = useRecoilState(addL2SchedaGeneraleFax);
  const [PEC, setPEC] = useRecoilState(addL2SchedaGeneralePEC);
  const [Referente, setReferente] = useRecoilState(addL2SchedaGeneraleReferente);
  const [Indirizzo, setIndirizzo] = useRecoilState(addL2SchedaGeneraleIndirizzo);
  const [Modello, setModello] = useRecoilState(addL2OrganizzazioneModello);
  const [AttachedFilesInfo, setAttachedFilesInfo] = useRecoilState(addL2AttachedFilesInfo);
  const [ComplianceDate, setComplianceDate] = useRecoilState(addL2ComplianceDate);
  const [ComplianceDirettoreCompliance, setComplianceDirettoreCompliance] = useRecoilState(addL2ComplianceDirettoreCompliance);
  const [ComplianceDirettoreComplianceID, setComplianceDirettoreComplianceID] = useRecoilState(addL2ComplianceDirettoreComplianceID);
  const [DocumentsAttached, setDocumentsAttached] = useRecoilState(addL2DocumentsAttached);
  const [DocumentsPath, setDocumentsPath] = useRecoilState(addL2DocumentsPath);
  const [OrganizzazioneDH, setOrganizzazioneDH] = useRecoilState(addL2OrganizzazioneDH);
  const [OrganizzazioneDirettore, setOrganizzazioneDirettore] = useRecoilState(addL2OrganizzazioneDirettore);
  const [OrganizzazioneDirettoreID, setOrganizzazioneDirettoreID] = useRecoilState(addL2OrganizzazioneDirettoreID);
  const [OrganizzazioneOrdinaria, setOrganizzazioneOrdinaria] = useRecoilState(addL2OrganizzazioneOrdinaria);
  const [OrganizzazionePersonale, setOrganizzazionePersonale] = useRecoilState(addL2OrganizzazionePersonale);
  const [OrganizzazioneSelectedL1, setOrganizzazioneSelectedL1] = useRecoilState(addL2OrganizzazioneSelectedL1);
  const [OrganizzazioneSelectedL1ID, setOrganizzazioneSelectedL1ID] = useRecoilState(addL2OrganizzazioneSelectedL1ID);
  const inititateUpload = useInitiateUploadL2();
  const [selectedCheckboxes, setSelectedCheckboxes] = useRecoilState(addL2selectedCheckboxesProcessiState);
  const [complianceData, setComplianceData] = useRecoilState(complianceDataState);
  const [reFetchIndicator, setRefetchIndicator] = useRecoilState(reFetchIndicatorL2);

  const [isLoading, setIsLoading] = useRecoilState(isLoadingRec);
  const [error, setError] = useState(setErrorRec);
  const [isMessageBoxVisible, setIsMessageBoxVisible] = useRecoilState(isMessageBoxVisibleRec);
  const [isMessageBoxVisibleClosing, setIsMessageBoxVisibleClosing] = useRecoilState(isMessageBoxVisibleClosingRec);

  const [message, setMessage] = useRecoilState(setMessageRec);

  const token = useToken();
  const { post } = useApi();

  const newSiteData = {
    id: ID,
    prefLabel: nomeCompleto,
    subOrganizationOf: "organizations.ecubit01",
    hasOrganizationalCard: "",
    isOrganizationalUnit: true,
    isThirdPartyOrganization: false,
    isFormalOrganization: false,
    hasUnitResponsible: OrganizzazioneDirettoreID,
    hasDomain: "",
    hasEntityLegalForm: "",
    identifier: "",
    hasPrimarySite: OrganizzazioneSelectedL1ID,
    hasRegisteredSite: OrganizzazioneSelectedL1ID,
    pec: PEC,
    dh_activity: OrganizzazioneDH,
    ordinary_activity: OrganizzazioneOrdinaria,
    hasModel: Modello,
    compliance_responsible_assignment_start: ComplianceDate,
    has_compliance_responsible: ComplianceDirettoreComplianceID,
    hasLegalRepresentative: "",
    hasDocumentsFolderLink: PATH_L2 + nomeAbbreviato,

    organizationalCard: {
      id: "",
      alt_label: nomeAbbreviato,
      email: Email,
      main_web_site: Indirizzo,
      telephone: Telefono,
      has_contact_person: Referente,
      fax: Fax,
    },
    entityLegalForm: null,
  };

  const [componenteOrg, setComponenteOrg] = useRecoilState(addL2OrganizzazioneSelectedL1ID);

  const handleSave = async () => {
    if (!nomeCompleto || !nomeAbbreviato || !componenteOrg) {
      setMessage("Si prega di compilare tutti i campi obbligatori: Nome completo, Nome abbreviato e Componente organizzativa");
      setIsMessageBoxVisibleClosing(true);
      return;
    }

    console.log(JSON.stringify(newSiteData));

    try {
      setIsLoading(true);

      const responseData = await post(URL_LV2Card, newSiteData);

      inititateUpload();
      setRefetchIndicator((prev) => prev + 1);
      console.log("Unità operativa creata con successo: ", responseData);
      setMessage("Unità operativa creata con successo.");

      setIsLoading(false);
      setIsMessageBoxVisible(true);

      setNomeCompleto("");
      setNomeAbbreviato("");
      setEmail("");
      setTelefono("");
      setFax("");
      setPEC("");
      setReferente("");
      setIndirizzo("");
      setModello("");
      setAttachedFilesInfo([]);
      setOrganizzazioneSelectedL1("");
      setOrganizzazioneSelectedL1ID("");
      setComplianceDate(null);
      setComplianceDirettoreCompliance("");
      setComplianceDirettoreComplianceID("");
      setDocumentsAttached([]);
      setDocumentsPath("");
      setOrganizzazioneDH("");
      setOrganizzazioneDirettore("");
      setOrganizzazioneDirettoreID("");
      setOrganizzazioneOrdinaria("");
      setOrganizzazionePersonale("");
    } catch (error) {
      console.error("Error creating unit:", error);
      setMessage(`Error creating unit: ${error instanceof Error ? error.message : "Unknown error"}`);
      setIsLoading(false);
      setIsMessageBoxVisible(true);
    }
  };

  return { handleSave };
}

const YourAddL1Component = () => {
  const [ID, setID] = useRecoilState(addL2OrganizationID);
  const [nomeCompleto, setNomeCompleto] = useRecoilState(addL2SchedaGeneraleNomeCompleto);
  const [nomeAbbreviato, setNomeAbbreviato] = useRecoilState(addL2SchedaGeneraleNomeAbbreviato);
  const [Email, setEmail] = useRecoilState(addL2SchedaGeneraleEmail);
  const [Telefono, setTelefono] = useRecoilState(addL2SchedaGeneraleTelefono);
  const [Fax, setFax] = useRecoilState(addL2SchedaGeneraleFax);
  const [PEC, setPEC] = useRecoilState(addL2SchedaGeneralePEC);
  const [Referente, setReferente] = useRecoilState(addL2SchedaGeneraleReferente);
  const [Indirizzo, setIndirizzo] = useRecoilState(addL2SchedaGeneraleIndirizzo);
  const [Modello, setModello] = useRecoilState(addL2OrganizzazioneModello);
  const [AttachedFilesInfo, setAttachedFilesInfo] = useRecoilState(addL2AttachedFilesInfo);
  const [ComplianceDate, setComplianceDate] = useRecoilState(addL2ComplianceDate);
  const [ComplianceDirettoreCompliance, setComplianceDirettoreCompliance] = useRecoilState(addL2ComplianceDirettoreCompliance);
  const [ComplianceDirettoreComplianceID, setComplianceDirettoreComplianceID] = useRecoilState(addL2ComplianceDirettoreComplianceID);
  const [DocumentsAttached, setDocumentsAttached] = useRecoilState(addL2DocumentsAttached);
  const [DocumentsPath, setDocumentsPath] = useRecoilState(addL2DocumentsPath);
  const [OrganizzazioneDH, setOrganizzazioneDH] = useRecoilState(addL2OrganizzazioneDH);
  const [OrganizzazioneDirettore, setOrganizzazioneDirettore] = useRecoilState(addL2OrganizzazioneDirettore);
  const [OrganizzazioneDirettoreID, setOrganizzazioneDirettoreID] = useRecoilState(addL2OrganizzazioneDirettoreID);
  const [OrganizzazioneOrdinaria, setOrganizzazioneOrdinaria] = useRecoilState(addL2OrganizzazioneOrdinaria);
  const [OrganizzazionePersonale, setOrganizzazionePersonale] = useRecoilState(addL2OrganizzazionePersonale);
  const [OrganizzazioneSelectedL1, setOrganizzazioneSelectedL1] = useRecoilState(addL2OrganizzazioneSelectedL1);
  const [OrganizzazioneSelectedL1ID, setOrganizzazioneSelectedL1ID] = useRecoilState(addL2OrganizzazioneSelectedL1ID);

  const [selectedCheckboxes, setSelectedCheckboxes] = useRecoilState(addL2selectedCheckboxesProcessiState);

  useGenerateUUID();

  const token = useToken();

  const { closeOverlay } = useContext(OverlayContext);

  const navigate = useNavigate();

  const { handleSave } = useSaveOrganization();
  const { fetchSelectionData } = useFetchSelectionData(token);
  const { handleSaveProcessi } = useProcessSave();
  const [complianceData, setComplianceData] = useRecoilState(complianceDataState);

  const [data, setData] = useState<DirectorDataItem[]>([]);
  const [isLoading, setIsLoading] = useRecoilState(isLoadingRec);
  const [error, setError] = useRecoilState<string | null>(setErrorRec);
  const [isMessageBoxVisible, setIsMessageBoxVisible] = useRecoilState(isMessageBoxVisibleRec);
  const [isMessageBoxVisibleClosing, setIsMessageBoxVisibleClosing] = useRecoilState(isMessageBoxVisibleClosingRec);

  const [message, setMessage] = useRecoilState(setMessageRec);

  const [complianceDataFetched, setComplianceDataFetched] = useState(false);

  const organizationLifecycle = async () => {
    try {
      setIsLoading(true);
      await handleSave();
      await fetchSelectionData();
      setComplianceDataFetched(true);
    } catch (err) {
      console.error("Error in organization lifecycle:", error);
      setError(err instanceof Error ? err.message : "An unknown error occurred");
      setIsMessageBoxVisible(true);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (complianceDataFetched && complianceData.length > 0 && selectedCheckboxes.length > 0) {
      handleSaveProcessi();
    }
  }, [complianceDataFetched, complianceData, selectedCheckboxes]);

  type DirectorApiContentItem = {
    id: string;
    alias: string;
  };

  type DirectorDataItem = {
    director_id: string;
    director_name: string;
  };

  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true);
      try {
        if (!token) throw new Error("User is not authenticated");

        const response = await fetch(URL_DirectorList, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });

        if (!response.ok) {
          throw new Error(`API request failed: ${response.statusText}`);
        }

        const responseData = await response.json();
        console.log("Response Data:", responseData);

        const transformedData: DirectorDataItem[] = responseData.map((item: DirectorApiContentItem) => {
          return {
            director_id: item.id,
            director_name: item.alias,
          };
        });

        console.log("Transformed Data:", transformedData);
        setData(transformedData);
      } catch (err) {
        setError(err instanceof Error ? err.message : "An unknown error occurred");
        setIsMessageBoxVisible(true);
      } finally {
        setIsLoading(false);
      }
    };

    fetchData();
  }, [token]);

  const directorNameToId = new Map(data.map((item) => [item.director_name, item.director_id]));

  const handleCloseAndNavigate = () => {
    setIsMessageBoxVisible(false);
    closeOverlay();
  };

  const handleClose = () => {
    setIsMessageBoxVisibleClosing(false);
  };

  const [activeComponent, setActiveComponent] = useState("SchedaGenerale");

  const changeActiveComponent = (componentName: string) => {
    setActiveComponent(componentName);
  };

  const renderActiveComponent = () => {
    switch (activeComponent) {
      case "SchedaGenerale":
        return <SchedaGenerale />;
      case "Organizzazione":
        return <Organizzazione />;

      case "ProcessiGenerali":
        return <Processi />;

      case "Documentazione":
        return <Documentazione />;
      default:
        return <SchedaGenerale />;
    }
  };

  return (
    <OverlayComponentBackgroundAdd1>
      {isLoading && (
        <BackdropTransparent>
          <MessageContainer>
            <MessageboxText>Loading...</MessageboxText>
          </MessageContainer>
        </BackdropTransparent>
      )}

      {isMessageBoxVisible && (
        <Backdrop>
          <MessageContainer>
            <p>
              <MessageboxText>{message}</MessageboxText>
            </p>
            <CloseMessageButton onClick={handleCloseAndNavigate}>
              <MessageboxText>Close</MessageboxText>
            </CloseMessageButton>
          </MessageContainer>
        </Backdrop>
      )}

      {isMessageBoxVisibleClosing && (
        <Backdrop>
          <MessageContainer>
            <p>
              <MessageboxText>{message}</MessageboxText>
            </p>
            <CloseMessageButton onClick={handleClose}>
              <MessageboxText>Close</MessageboxText>
            </CloseMessageButton>
          </MessageContainer>
        </Backdrop>
      )}

      {error && (
        <Backdrop>
          <MessageContainer>
            <p>
              <MessageboxText>{error}</MessageboxText>
            </p>
            <CloseMessageButton
              onClick={() => {
                setIsMessageBoxVisible(false);
                setError(null);
              }}
            >
              <MessageboxText>Close</MessageboxText>
            </CloseMessageButton>
          </MessageContainer>
        </Backdrop>
      )}

      <FrameTopGradient100 />
      <OverlayComponentWhiteTitleAdd1>NUOVA UNITÀ OPERATIVA</OverlayComponentWhiteTitleAdd1>
      <OverlayComponentWhiteAdd1>
        <SubMenuButtonContainerOrganigrammaMultipleShort>
          <CreaSubMenuButtonMultiple onClick={() => changeActiveComponent("SchedaGenerale")} to="/Cybersecurity/Organizzazione/UnitaOperative/CreaL2/1">
            Scheda generale
          </CreaSubMenuButtonMultiple>
          <CreaSubMenuButtonMultiple onClick={() => changeActiveComponent("Organizzazione")} to="/Cybersecurity/Organizzazione/UnitaOperative/CreaL2/2">
            Organizzazione
          </CreaSubMenuButtonMultiple>
          {/* <CreaSubMenuButtonMultiple
            onClick={() => changeActiveComponent("Infrastruttura")}
            to="/Cybersecurity/Organizzazione/UnitaOperative/CreaL2/3"
          >
            Infrastruttura
          </CreaSubMenuButtonMultiple> */}
          <CreaSubMenuButtonMultiple onClick={() => changeActiveComponent("ProcessiGenerali")} to="/Cybersecurity/Organizzazione/UnitaOperative/CreaL2/4">
            Processi generali
          </CreaSubMenuButtonMultiple>
          {/* <CreaSubMenuButtonMultiple
            onClick={() => changeActiveComponent("PartiInteressate")}
            to="/Cybersecurity/Organizzazione/UnitaOperative/CreaL2/5"
          >
            Parti interessate
          </CreaSubMenuButtonMultiple> */}
          {/* <CreaSubMenuButtonMultiple onClick={() => changeActiveComponent("SistemaCompliance")} to="/Cybersecurity/Organizzazione/UnitaOperative/CreaL2/6">
            Sistema gestione Compliance
          </CreaSubMenuButtonMultiple> */}
          <CreaSubMenuButtonMultiple onClick={() => changeActiveComponent("Documentazione")} to="/Cybersecurity/Organizzazione/UnitaOperative/CreaL2/7">
            Documentazione
          </CreaSubMenuButtonMultiple>
        </SubMenuButtonContainerOrganigrammaMultipleShort>

        {renderActiveComponent()}
      </OverlayComponentWhiteAdd1>

      <OverlayComponentWhiteButtonFrameAdd1>
        <ButtonGrey onClick={closeOverlay}>
          <ButtonText>Annulla</ButtonText>
        </ButtonGrey>
        <ButtonGrey onClick={organizationLifecycle}>
          <ButtonText>Crea</ButtonText>
        </ButtonGrey>
      </OverlayComponentWhiteButtonFrameAdd1>
    </OverlayComponentBackgroundAdd1>
  );
};

export default YourAddL1Component;
