import { useAuth } from "oidc-react";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { Outlet, useLocation, useNavigate } from "react-router-dom";
import styled from "styled-components";
import { OverlayContext } from "../cybersecurity-board-contestoleadership-organizzazione";
import {
  Backdrop,
  BackdropTransparent,
  BackgroundIcon,
  CloseMessageButton,
  FadeOutComponent,
  FrameTopGradient100,
  IconButton,
  IconsGroup,
  MessageContainer,
  MessageboxText,
} from "./cybersecurity-board-organizzazione-organigramma-styled-components";
import {
  Search,
  SearchIcon,
  SearchInputContainer,
  StyledTable,
  StyledTableItem,
  StyledTbody,
  StyledTd,
  StyledTdUnitaOperativa,
  StyledTh,
  StyledThead,
  StyledUnitaOperativaTh,
  TableContainer,
  TableItemText,
  TableScrollableDiv,
  TableTitleText,
  TopContainer
} from "./cybersecurity-board-organizzazione-unitaoperative-styled-components";

import { useRecoilState } from "recoil";

import { URL_LV2Card, URL_LV2List, useApi } from "../../../../API";
import {
  SelectedL1ID,
  SelectedL2ID,
  SelectedL3ID,
  SelectedL4ID,
  addL2OrganizzazioneDirettore,
  addL2OrganizzazioneDirettoreID,
  addL2OrganizzazioneSelectedL1,
  addL2OrganizzazioneSelectedL1ID,
  reFetchIndicatorL2,
  selectedItemsAsset
} from "../../../../recoil";
import { addL2isConfirmDialogVisible, showL2initialPath, showL2isAnimating, showL2isVisible } from "./L2-Add-recoil";

interface DataItem {
  id: string;
  name: string;
  responsible_name: string;
  responsible_id: string;
  site_name: string;
  site_id: string;
  email: string;
  phone: string;
}

type SortableKeys = keyof DataItem;

type ApiContentItem = {
  id: string;
  name: string;
  responsible_name: string;
  responsible_id: string;
  site_name: string;
  site_id: string;
  email: string;
  phone: string;
};

type StyledTrProps = {
  selected: boolean;
};

const StyledTr = styled.tr<StyledTrProps>`
  cursor: pointer;
  background-color: ${(props) => {
    return props.selected ? "#f3f7fb" : "#ffffff";
  }};

  &:hover {
    background-color: #e9eff7;
  }
`;

interface OverlayComponentProps {
  isAnimating: boolean;
}

export function useOverlayControlL2Show(initialPath: string) {
  const [isVisibleL2, setIsVisibleL2] = useRecoilState(showL2isVisible);
  const [isAnimatingL2, setIsAnimatingL2] = useRecoilState(showL2isAnimating);
  const [originalPath, setOriginalPath] = useRecoilState(showL2initialPath);

  const navigate = useNavigate();

  const openOverlayL2 = useCallback(() => {
    navigate(initialPath);
    setIsVisibleL2(true);
    setTimeout(() => setIsAnimatingL2(true), 10);
  }, [navigate, initialPath]);

  const closeOverlayL2 = useCallback(() => {
    setIsAnimatingL2(false);
    setTimeout(() => {
      setIsVisibleL2(false);
      navigate(originalPath);
    }, 300);
  }, [navigate]);

  const toggleOverlayL2 = useCallback(() => {
    if (!isVisibleL2) {
      openOverlayL2();
    } else {
      closeOverlayL2();
    }
  }, [isVisibleL2, openOverlayL2, closeOverlayL2]);

  return {
    isVisibleL2,
    isAnimatingL2,
    toggleOverlayL2,
    openOverlayL2,
    closeOverlayL2,
  };
}

const OverlayComponentAdd1 = styled.div<OverlayComponentProps>`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  z-index: 10;
  transition: opacity 300ms ease-in-out, visibility 300ms ease-in-out;
  opacity: ${(props) => (props.isAnimating ? "1" : "0")};
  visibility: ${(props) => (props.isAnimating ? "visible" : "hidden")};
`;

const useDeletePerson = () => {
  const { del } = useApi();
  const [reFetchIndicator, setRefetchIndicator] = useRecoilState(reFetchIndicatorL2);
  const [isConfirmDialogVisible, setIsConfirmDialogVisible] = useRecoilState(addL2isConfirmDialogVisible);
  const [L2ID, setL2ID] = useRecoilState(SelectedL2ID);

  const handleDelete = useCallback(async () => {
    if (!L2ID) return;

    const url = `${URL_LV2Card}`;
    const url_id = `${L2ID}/cascade`;
    try {
      await del(url, url_id);
      console.log(`Deleted unit with ID: ${L2ID}`);
    } catch (error) {
      console.error("Failed to delete unit:", error);
    } finally {
      setRefetchIndicator((prev) => prev + 1);
      setIsConfirmDialogVisible(false);
    }
  }, [L2ID, del, setRefetchIndicator]);

  const showDeleteConfirmation = useCallback(() => {
    setIsConfirmDialogVisible(true);
  }, []);

  return {
    handleDelete,
    showDeleteConfirmation,
    isConfirmDialogVisible,
    setIsConfirmDialogVisible,
  };
};

const DataTable: React.FC<{ data: DataItem[] }> = ({ data }) => {
  const [selectedAssets, setSelectedAssets] = useRecoilState(selectedItemsAsset);

  const navigate = useNavigate();

  const [isOverlayVisibleShow2, setIsOverlayVisibleShow2] = useState(false);
  const [isOverlayVisibleAdd2, setIsOverlayVisibleAdd2] = useState(false);
  const [isOverlayAnimatingShow2, setIsOverlayAnimatingShow2] = useState(false);
  const [isOverlayAnimatingAdd2, setIsOverlayAnimatingAdd2] = useState(false);

  const toggleOverlayAdd2 = () => {
    if (!isOverlayVisibleAdd2) {
      if (window.location.pathname === "/Cybersecurity/UnitaOperative") {
        navigate(`UnitaOperative/CreaL2/1`);
      } else {
        navigate(`${window.location.pathname}/CreaL2/1`);
      }

      setIsOverlayVisibleAdd2(true);
      setTimeout(() => setIsOverlayAnimatingAdd2(true), 10);
    } else {
      setIsOverlayAnimatingAdd2(false);
      setTimeout(() => setIsOverlayVisibleAdd2(false), 310);
    }
  };

  const toggleOverlayShow2 = () => {
    if (!isOverlayVisibleShow2) {
      if (window.location.pathname === "/Cybersecurity/UnitaOperative") {
        navigate(`UnitaOperative/VisualizzaL2/1`);
      } else {
        navigate(`${window.location.pathname}/VisualizzaL2/1`);
      }

      setIsOverlayVisibleShow2(true);
      setTimeout(() => setIsOverlayAnimatingShow2(true), 10);
    } else {
      setIsOverlayAnimatingShow2(false);
      setTimeout(() => setIsOverlayVisibleShow2(false), 310);
    }
  };

  const closeOverlayAdd2 = () => {
    setSelectedAssets([]);
    setDirector("");
    setDirectorId("");
    setL1List("");
    setL1ListID("");
    navigate(`/Cybersecurity/Organizzazione/UnitaOperative`);
    setIsOverlayAnimatingAdd2(false);
    setIsOverlayAnimatingShow2(false);

    setTimeout(() => {
      setIsOverlayVisibleAdd2(false);
      setIsOverlayVisibleShow2(false);
    }, 300);
  };

  const [selectedL1, setSelectedL1] = useState<string | null>(null);
  const [selectedL2, setSelectedL2] = useState<string | null>(null);
  const [selectedL3, setSelectedL3] = useState<string | null>(null);
  const [selectedL4, setSelectedL4] = useState<string | null>(null);

  const [L1ID, setL1ID] = useRecoilState(SelectedL1ID);
  const [L2ID, setL2ID] = useRecoilState(SelectedL2ID);
  const [L3ID, setL3ID] = useRecoilState(SelectedL3ID);
  const [L4ID, setL4ID] = useRecoilState(SelectedL4ID);
  const [director, setDirector] = useRecoilState(addL2OrganizzazioneDirettore);
  const [directorId, setDirectorId] = useRecoilState(addL2OrganizzazioneDirettoreID);
  const [L1List, setL1List] = useRecoilState(addL2OrganizzazioneSelectedL1);
  const [L1ListID, setL1ListID] = useRecoilState(addL2OrganizzazioneSelectedL1ID);

  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const [isMessageBoxVisible, setIsMessageBoxVisible] = useState(false);

  const [sortConfig, setSortConfig] = useState<{
    key: SortableKeys;
    direction: "ascending" | "descending";
  } | null>(null);

  const sortedData = useMemo(() => {
    let sortableItems = [...data];
    if (sortConfig !== null) {
      sortableItems.sort((a, b) => {
        const aValue = a[sortConfig.key];
        const bValue = b[sortConfig.key];

        if (typeof aValue === "string" && typeof bValue === "string") {
          return sortConfig.direction === "ascending" ? aValue.localeCompare(bValue) : bValue.localeCompare(aValue);
        }

        return 0;
      });
    }
    return sortableItems;
  }, [data, sortConfig]);

  const requestSort = (key: SortableKeys) => {
    let direction: "ascending" | "descending" = "ascending";
    if (sortConfig && sortConfig.key === key && sortConfig.direction === "ascending") {
      direction = "descending";
    }
    setSortConfig({ key, direction });
  };

  const [selectedId, setSelectedId] = useState<string | null>(null);

  const handleRowSelect = (id: string) => {
    setSelectedId((prevId) => {
      return id;
    });

    setL2ID(id);
  };

  useEffect(() => {
    console.log("L2ID now has the value:", L2ID);
  }, [L2ID]);

  const [globalFilterText, setGlobalFilterText] = useState("");

  const handleGlobalFilterChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setGlobalFilterText(event.target.value);
  };

  const [filterText, setFilterText] = useState("");

  const handleFilterChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setFilterText(event.target.value);
  };

  const filteredAndSortedData = useMemo(() => {
    const filteredData = data
      .filter((item) => {
        return item.site_name.toLowerCase().includes(filterText.toLowerCase());
      })
      .filter((item) => {
        if (globalFilterText) {
          const allText = Object.values(item).join(" ").toLowerCase();
          return allText.includes(globalFilterText.toLowerCase());
        }
        return true;
      });

    if (sortConfig !== null) {
      filteredData.sort((a, b) => {
        const aValue = a[sortConfig.key];
        const bValue = b[sortConfig.key];

        if (typeof aValue === "string" && typeof bValue === "string") {
          return sortConfig.direction === "ascending" ? aValue.localeCompare(bValue) : bValue.localeCompare(aValue);
        }
        return 0;
      });
    }

    return filteredData;
  }, [data, sortConfig, filterText, globalFilterText]);

  const { handleDelete, showDeleteConfirmation, isConfirmDialogVisible, setIsConfirmDialogVisible } = useDeletePerson();

  const [isVisibleShow, setIsVisibleShow] = useRecoilState(showL2isVisible);
  const [isAnimatingShow, setIsAnimatingShow] = useRecoilState(showL2isAnimating);
  const { toggleOverlayL2, isVisibleL2, isAnimatingL2, closeOverlayL2 } = useOverlayControlL2Show("/Cybersecurity/Organizzazione/UnitaOperative/VisualizzaL2/1");
  const [originalPath, setOriginalPath] = useRecoilState(showL2initialPath);
  const location = useLocation();

  const handleShowL2 = () => {
    setOriginalPath(location.pathname);
    toggleOverlayL2();
  };

  return (
    <TableContainer>
      <TopContainer>
        <SearchInputContainer>
          <Search placeholder="Filtra per componente" onChange={handleFilterChange} />
          <SearchIcon src="/funnel.svg" />
        </SearchInputContainer>
        <SearchInputContainer>
          <Search placeholder="Cerca in tutta la tabella" onChange={handleGlobalFilterChange} />
          <SearchIcon src="/search.svg" />
        </SearchInputContainer>

        <IconsGroup>
          <IconButton onClick={() => handleShowL2()}>
            <BackgroundIcon loading="eager" alt="" src="/doc.svg" />
          </IconButton>
          {/* <IconButton>
            <BackgroundIcon loading="eager" alt="" src="/edit.svg" />
          </IconButton> */}
          <IconButton onClick={() => showDeleteConfirmation()}>
            <BackgroundIcon loading="eager" alt="" src="/delete.svg" />
          </IconButton>
          <IconButton>
            <BackgroundIcon loading="eager" alt="" src="/add.svg" onClick={toggleOverlayAdd2} />
          </IconButton>
        </IconsGroup>
      </TopContainer>

      <StyledTable>
        <StyledThead>
          <StyledUnitaOperativaTh onClick={() => requestSort("site_name")}>
            <TableTitleText>▼ Comp. organizzativa</TableTitleText>
          </StyledUnitaOperativaTh>
          <StyledUnitaOperativaTh onClick={() => requestSort("name")}>
            <TableTitleText>▼ Unità operativa</TableTitleText>
          </StyledUnitaOperativaTh>
          <StyledTh onClick={() => requestSort("responsible_name")}>
            <TableTitleText>▼ Direttore</TableTitleText>
          </StyledTh>
          <StyledTh onClick={() => requestSort("phone")}>
            <TableTitleText>▼ Telefono</TableTitleText>
          </StyledTh>
          <StyledTh onClick={() => requestSort("email")}>
            <TableTitleText>▼ Email</TableTitleText>
          </StyledTh>
        </StyledThead>
      </StyledTable>
      <TableScrollableDiv>
        <StyledTableItem>
          <StyledTbody>
            {filteredAndSortedData.map((item) => (
              <StyledTr key={item.id} onClick={() => handleRowSelect(item.id)} selected={selectedId === item.id}>
                <StyledTdUnitaOperativa title={item.site_name}>
                  <TableItemText>{item.site_name}</TableItemText>
                </StyledTdUnitaOperativa>
                <StyledTdUnitaOperativa title={item.name}>
                  <TableItemText>{item.name}</TableItemText>
                </StyledTdUnitaOperativa>
                <StyledTd title={item.responsible_name}>
                  <TableItemText>{item.responsible_name}</TableItemText>
                </StyledTd>
                <StyledTd title={item.phone}>
                  <TableItemText>{item.phone}</TableItemText>
                </StyledTd>
                <StyledTd title={item.email}>
                  <TableItemText>{item.email}</TableItemText>
                </StyledTd>
              </StyledTr>
            ))}
          </StyledTbody>
        </StyledTableItem>
      </TableScrollableDiv>

      <OverlayContext.Provider
        value={{
          closeOverlay: closeOverlayAdd2,
          selectedL1,
          setSelectedL1,
          selectedL2,
          setSelectedL2,
          selectedL3,
          setSelectedL3,
          selectedL4,
          setSelectedL4,
        }}
      >
        {isOverlayVisibleAdd2 && (
          <OverlayComponentAdd1 isAnimating={isOverlayAnimatingAdd2}>
            {/* <ButtonClose src="/close.svg" onClick={closeOverlayAdd2} /> */}
            <FadeOutComponent>
              <FrameTopGradient100></FrameTopGradient100>
            </FadeOutComponent>

            <Outlet />
          </OverlayComponentAdd1>
        )}

        {isVisibleShow && (
          <OverlayComponentAdd1 isAnimating={isAnimatingShow}>
            {/* <ButtonClose src="/close.svg" onClick={closeOverlayAdd2} /> */}
            <FadeOutComponent>
              <FrameTopGradient100></FrameTopGradient100>
            </FadeOutComponent>

            <Outlet />
          </OverlayComponentAdd1>
        )}
      </OverlayContext.Provider>
    </TableContainer>
  );
};

const Board27001Dashboard: React.FC = () => {
  const [L1ID, setL1ID] = useRecoilState(SelectedL1ID);
  const [L2ID, setL2ID] = useRecoilState(SelectedL2ID);
  const [L3ID, setL3ID] = useRecoilState(SelectedL3ID);
  const [L4ID, setL4ID] = useRecoilState(SelectedL4ID);
  const [reFetchIndicator, setRefetchIndicator] = useRecoilState(reFetchIndicatorL2);

  const [data, setData] = useState<DataItem[]>([]);

  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const [isMessageBoxVisible, setIsMessageBoxVisible] = useState(false);

  const auth = useAuth();
  const token = auth.isLoading || !auth.userData ? null : auth.userData.access_token;

  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true);
      try {
        if (!token) throw new Error("User is not authenticated");

        const response = await fetch(URL_LV2List, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });

        if (!response.ok) {
          throw new Error(`API request failed: ${response.statusText}`);
        }

        const responseData = await response.json();
        console.log("Response Data:", responseData);

        const transformedData: DataItem[] = responseData.map((item: ApiContentItem) => {
          return {
            id: item.id,
            name: item.name,
            responsible_name: item.responsible_name,
            responsible_id: item.responsible_id,
            site_name: item.site_name,
            site_id: item.site_id,
            email: item.email,
            phone: item.phone,
          };
        });

        console.log("Transformed Data:", transformedData);
        setData(transformedData);
      } catch (err) {
        setError(err instanceof Error ? err.message : "An unknown error occurred");
        setIsMessageBoxVisible(true);
      } finally {
        setIsLoading(false);
      }
    };

    fetchData();
  }, [token, reFetchIndicator]);

  const { handleDelete, showDeleteConfirmation, isConfirmDialogVisible, setIsConfirmDialogVisible } = useDeletePerson();

  return (
    <div>
      {isMessageBoxVisible && (
        <Backdrop>
          <MessageContainer>
            <p>
              <MessageboxText>{error}</MessageboxText>
            </p>
            <CloseMessageButton onClick={() => setIsMessageBoxVisible(false)}>
              <MessageboxText>Close</MessageboxText>
            </CloseMessageButton>
          </MessageContainer>
        </Backdrop>
      )}
      {isConfirmDialogVisible && (
        <Backdrop>
          <MessageContainer>
            <p>
              <MessageboxText>Sei sicuro di voler rimuovere questa unità?</MessageboxText>
            </p>
            <div style={{ display: "flex", justifyContent: "center", gap: "20px" }}>
              <CloseMessageButton onClick={() => setIsConfirmDialogVisible(false)}>
                <MessageboxText>No</MessageboxText>
              </CloseMessageButton>
              <CloseMessageButton onClick={handleDelete}>
                <MessageboxText>Rimuovi</MessageboxText>
              </CloseMessageButton>
            </div>
          </MessageContainer>
        </Backdrop>
      )}

      {isLoading && (
        <BackdropTransparent>
          <MessageContainer>
            <MessageboxText>Loading...</MessageboxText>
          </MessageContainer>
        </BackdropTransparent>
      )}

      {error && (
        <Backdrop>
          <MessageContainer>
            <p>
              <MessageboxText>{error}</MessageboxText>
            </p>
            <CloseMessageButton
              onClick={() => {
                setIsMessageBoxVisible(false);
                setError(null);
              }}
            >
              <MessageboxText>Close</MessageboxText>
            </CloseMessageButton>
          </MessageContainer>
        </Backdrop>
      )}

      <DataTable data={data} />
    </div>
  );
};

export default Board27001Dashboard;
