import { CheckCircle, ChevronRight, ExpandMore } from '@mui/icons-material';
import { styled as muiStyled } from '@mui/system';
import { TreeItem, TreeView } from '@mui/x-tree-view';
import React, { useEffect, useState } from 'react';
import { useRecoilState } from "recoil";
import styled from "styled-components";
import { URL_AssetsByLV1, useApi } from "../../../../../API";
import {
  Backdrop,
  BackdropTransparent,
  CloseMessageButton,
  CreaContainerColumn,
  DescriptionContainerCreaMultiple,
  DescriptionContainerCreaText,
  FlexContainerCrea,
  IconContainer,
  IconSquare,
  InputContainer,
  InputDescriptionText,
  InputTextboxButton,
  LastPopupButtonContainer,
  LastPopupCheckboxContainer,
  LastPopupHeaderContainer,
  LastPopupScrollbar,
  LastPopupSearch,
  LastPopupSearchIcon,
  LastPopupSearchInputContainer,
  LastPopupTitleContainer,
  MessageContainer,
  MessageboxText,
  OverlayComponentWhiteAdd1Multiple,
  TriangleContainer
} from "../../cybersecurity-board-contestoleadership-organizzazione-sub/cybersecurity-board-organizzazione-organigramma-styled-components";

import { ButtonGrey, ButtonText } from "../../../styled-components";

import {
  SelectedL1ID,
  SelectedL2ID,
  SelectedL3ID,
  SelectedL4ID,
} from "../../../../../recoil";

import {
  addL2OrganizzazioneSelectedL1,
  addL2OrganizzazioneSelectedL1ID,
  selectedItemsAsset,
} from '../../cybersecurity-board-contestoleadership-organizzazione-sub/L2-Add-recoil';

interface LastPopupProps {
  isVisible: boolean;
}

const LastPopupBlack = styled.div<LastPopupProps>`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);  
  z-index: 99;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: opacity 0.3s ease, visibility 0.3s ease;
  opacity: ${({ isVisible }) => (isVisible ? 1 : 0)};
  visibility: ${({ isVisible }) => (isVisible ? "visible" : "hidden")};
`;

const LastPopup = styled.div`
  font-family: var(--font-titillium-web);
  font-size: var(--font-size-micro);
  color: var(--color-steelblue-700);
  position: fixed;
  right: 0px;
  top: 0px;
  bottom: 0px;
  width: 75%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: var(--color-aliceblue);

  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.25);
   
  z-index: 100;
`;

const CustomTreeItem = muiStyled(TreeItem)(({ theme }) => ({
  '& .MuiTreeItem-content': {
    backgroundColor: theme.palette.background.transparent,
    borderRadius: '0px',
    '&:hover': {
      backgroundColor: theme.palette.action.hover,
    },
    '&.Mui-selected': {
      backgroundColor: '#c8d6e8',
      color: theme.palette.primary.contrastText,
      fontWeight: 'regular',
      fontSize: '15px',
    },
    '&.Mui-focused, &.Mui-selected.Mui-focused': {
      backgroundColor: '#c8d6e8',
      fontWeight: 'regular',
      fontSize: '15px',
    },
  },
  '& .MuiTreeItem-label': {

    fontWeight: 'regular',
    fontSize: '16px',
    fontFamily: 'Titillium Web',
    color: '#04446c',
  },
}));

interface Place {
  id: string;
  creationTs: string;
  updateTs: string;
  createdBy: string;
  updatedBy: string;
  name: string;
  has_site: string;
  plan_ref: string;
  assetTree?: AssetTreeItem[];
}

interface AssetTreeItem {
  value: AssetCategory;
  leaves?: AssetTreeItem[];
}

interface AssetCategory {
  id: string;
  creationTs: string;
  updateTs: string;
  createdBy: string;
  updatedBy: string;
  name: string;
  sub_category_of?: string;
  assets?: Asset[];
  leaves?: AssetTreeItem[];
}

interface Asset {
  id: string;
  creationTs: string;
  updateTs: string;
  createdBy: string;
  updatedBy: string;
  name: string;
  hasOrganization: string;
  hasCategory: string;
  inventoryN: string;
  hasResponsible: string;
  hasPlace: string;
  state: string;
  category?: AssetCategory;
  container?: any;
}


interface ArboristNode {
  id: string;
  name: string;
  isLeaf: boolean;
  children?: ArboristNode[];
}


const MainComponent = () => {

  const [selectedAssets, setSelectedAssets] = useRecoilState(selectedItemsAsset);

  const handleLeafClick = (event: React.MouseEvent, nodeId: string, isLeaf: boolean): void => {

    if (isLeaf) {
      event.stopPropagation();
      console.log("Full node ID:", nodeId);
      setSelectedAssets((prev) => {
        if (prev.includes(nodeId)) {
          return prev.filter((id) => id !== nodeId);
        } else {
          return [...prev, nodeId];
        }

      });
    }
    console.log("Updated Selected Items:", selectedAssets);
  };

  const renderTreeItems = (nodes: ArboristNode[]): JSX.Element[] => {
    return nodes.map((node) => (
      <CustomTreeItem
        key={node.id}
        nodeId={node.id}
        label={
          <div style={{ display: 'flex', alignItems: 'center' }}>
            {node.isLeaf && (
              selectedAssets.includes(node.id) ? (
                <CheckCircle style={{ color: '#309ee5', marginRight: 10, fontSize: 15 }} />
              ) : (
                <CheckCircle style={{ color: '#cccccc', marginRight: 10, fontSize: 15 }} />
              )
            )}
            {node.name}
          </div>
        }
        onClick={(event) => node.isLeaf && handleLeafClick(event, node.id, node.isLeaf)}
        sx={{
          '& .MuiTreeItem-content': selectedAssets.includes(node.id) && node.isLeaf ? {

          } : {},

        }}
      >
        {node.children ? renderTreeItems(node.children) : null}
      </CustomTreeItem>
    ));
  };




  const [L1ID, setL1ID] = useRecoilState(SelectedL1ID);
  const [L2ID, setL2ID] = useRecoilState(SelectedL2ID);
  const [L3ID, setL3ID] = useRecoilState(SelectedL3ID);
  const [L4ID, setL4ID] = useRecoilState(SelectedL4ID);
  const [LV1List, setLV1List] = useRecoilState(addL2OrganizzazioneSelectedL1);
  const [LV1ListId, setLV1ListId] = useRecoilState(addL2OrganizzazioneSelectedL1ID);

  const { post, get, patch } = useApi();

  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const [isMessageBoxVisible, setIsMessageBoxVisible] = useState(false);

  const [isVisible, setIsVisible] = useState(false);

  const togglePopup = () => {
    setIsVisible(!isVisible);
  };

  const [nodes, setNodes] = useState<ArboristNode[]>([]);

  useEffect(() => {
    console.log("LV1LISTID: ", LV1ListId);
    const fetchData = async () => {

      try {
        const response = await get(
          `${URL_AssetsByLV1}${LV1ListId}`


        );
        if (Array.isArray(response)) {

          const transformedData: ArboristNode[] =
            transformToArboristFormat(response);
          setNodes(transformedData);
        } else {

        }
      } catch (error) {

      }
    };

    fetchData();
  }, []);



  const transformToArboristFormat = (apiData: Place[]): ArboristNode[] => {

    const processNode = (
      node: AssetCategory | AssetTreeItem,
      parentId: string
    ): ArboristNode => {
      const { id, name, assets, leaves } = "value" in node ? node.value : node;
      const combinedId = `${parentId}-${id}`;
      const newNode: ArboristNode = {
        id: combinedId,
        name,
        children: [],
        isLeaf: false,
      };

      if (assets && assets.length > 0) {
        const assetNodes: ArboristNode[] = assets.map((asset: Asset) => ({
          id: `${combinedId}-${asset.id}`,
          name: asset.name,
          children: [],
          isLeaf: true,
        }));
        newNode.children!.push(...assetNodes);
      }

      if (leaves && leaves.length > 0) {
        const leafNodes: ArboristNode[] = leaves.map(
          (leaf: AssetTreeItem) => processNode(leaf, combinedId)
        );
        newNode.children!.push(...leafNodes);
      }

      if (newNode.children!.length === 0) {
        newNode.isLeaf = true;
      }
      return newNode;
    };

    return apiData.map((place: Place) => ({
      id: place.id,
      name: place.name,
      children:
        place.assetTree?.map(
          (item: AssetTreeItem) => processNode(item, place.id)
        ) ?? [],
      isLeaf: !place.assetTree || place.assetTree.length === 0,
    }));
  };

  const treeData = nodes.map((node) => ({
    ...node,
    key: node.id,
  }));

  useEffect(() => {
    console.log("TreeData is: ", treeData);
  }, [treeData]);


  return (
    <OverlayComponentWhiteAdd1Multiple>
      <FlexContainerCrea>
        <LastPopupBlack isVisible={isVisible}>
          <LastPopup>
            <LastPopupTitleContainer>SELEZIONA ASSET</LastPopupTitleContainer>
            <LastPopupCheckboxContainer>
              <LastPopupHeaderContainer>
                <LastPopupSearchInputContainer>
                  <LastPopupSearch placeholder="Cerca" />
                  <LastPopupSearchIcon src="/search.svg" />
                </LastPopupSearchInputContainer>
              </LastPopupHeaderContainer>

              <LastPopupScrollbar>
                {/* MUI TreeView to visualize the data */}
                {nodes && (
                  <TreeView
                    aria-label="file system navigator"
                    defaultCollapseIcon={<ExpandMore />}
                    defaultExpandIcon={<ChevronRight />}
                    sx={{ flexGrow: 1, overflowY: 'auto' }}
                  >
                    {renderTreeItems(nodes)}
                  </TreeView>
                )}
              </LastPopupScrollbar>
            </LastPopupCheckboxContainer>
            <LastPopupButtonContainer>
              <ButtonGrey onClick={togglePopup}>
                <ButtonText>Salva </ButtonText>
              </ButtonGrey>
            </LastPopupButtonContainer>
          </LastPopup>
        </LastPopupBlack>

        <CreaContainerColumn>
          <InputContainer>
            <InputDescriptionText>Luoghi</InputDescriptionText>
            <InputTextboxButton onClick={togglePopup} />
            <IconContainer />
            <TriangleContainer>▶</TriangleContainer>
          </InputContainer>

          <InputContainer>
            <InputDescriptionText>Asset</InputDescriptionText>
            <InputTextboxButton onClick={togglePopup} />
            <IconContainer />
            <TriangleContainer>▶</TriangleContainer>
          </InputContainer>
        </CreaContainerColumn>

        <CreaContainerColumn></CreaContainerColumn>
      </FlexContainerCrea>
      <DescriptionContainerCreaMultiple>
        <IconSquare src="/crea-L2-organizzazione.svg" alt="Description Icon" />
        <DescriptionContainerCreaText>
          Indicare il nome del Direttore a capo dell'unità, selezionare il
          modello della struttura organizzativa interna e elencare le principali
          attività cliniche e amministrative, sia quelle specialistiche previste
          dal Day Hospital sia quelle ordinarie, per delineare compiutamente il
          funzionamento e le competenze dell'unità
        </DescriptionContainerCreaText>
      </DescriptionContainerCreaMultiple>

      {isLoading && (
        <BackdropTransparent>
          <MessageContainer>
            <MessageboxText>Loading...</MessageboxText>
          </MessageContainer>
        </BackdropTransparent>
      )}

      {error && (
        <Backdrop>
          <MessageContainer>
            <p>
              <MessageboxText>{error}</MessageboxText>
            </p>
            <CloseMessageButton
              onClick={() => {
                setIsMessageBoxVisible(false);
                setError(null);
                togglePopup();
              }}
            >
              <MessageboxText>Close</MessageboxText>
            </CloseMessageButton>
          </MessageContainer>
        </Backdrop>
      )}
    </OverlayComponentWhiteAdd1Multiple>
  );
};

export default MainComponent;
