import { NavLink } from "react-router-dom";
import styled from "styled-components";
export const BoardScrollableDiv = styled.div`
  overflow-y: hidden;
  height: 88%;

  &::-webkit-scrollbar {
    width: 3px;
  }

  &::-webkit-scrollbar-track {
    background: #ffffff;
    border-radius: 10px;
  }

  &::-webkit-scrollbar-thumb {
    background: #f1f1f1;
    border-radius: 10px;
    &:hover {
      background: #425a77;
    }
  }
`;

export const Title = styled.div`
  display: flex;
  align-items: center;
  justify-content: left;
  width: 100%;
  height: 30px;
  background-color: transparent;
  padding-bottom: 0px;
`;

export const TitleText = styled.div`
  font-size: var(--font-size-micro);
  font-family: var(--font-titillium-web);
  color: var(--color-steelblue-700);
  text-align: center;
`;

export const SearchGroup = styled.div`
  display: flex;
  flex-grow: 1;
  align-items: center;
`;

export const IconsGroup = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
`;

export const Search = styled.input`
  border: 1px solid #e1e1e1;
  overflow: hidden;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  padding: 0px 0px 0px 8px;
  width: 100%;

  font-family: var(--font-titillium-web);
  font-size: var(--font-size-micro);
  line-height: 1.5;
  color: var(--color-lightslategray);

  outline: none;
  box-shadow: none;

  &::placeholder {
    color: #d1d1d1;
  }

  &:focus {
    border-color: var(--color-primary);
  }
`;

export const LineDivider = styled.div`
  position: relative;
  border-right: 1px solid #d6d6d6;
  box-sizing: border-box;
  width: 1px;
  height: 20px;
`;
export const BackgroundIcon = styled.img`
  position: absolute;
  height: 100%;
  width: 100%;
  top: 0%;
  right: 0%;
  bottom: 0%;
  left: 0%;
  max-width: 100%;
  overflow: hidden;
  max-height: 100%;
`;
export const IconButton = styled.button`
  position: relative;
  width: 15px;
  height: 15px;
  overflow: hidden;
  flex-shrink: 0;
  border: none;
  background: transparent;
  padding: 0;
  cursor: pointer;

  &:hover {
    filter: brightness(0.8);
  }

  &:focus {
    outline: none;
  }
`;

export const SearchBarWithIconsRoot = styled.div`
  position: relative;
  background-color: #fff;

  width: 100%;
  height: 35px;
  overflow: hidden;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  padding-top: 0px;
  padding-right: 0px;
  padding-bottom: 10px;
  padding-left: 0px;
  box-sizing: border-box;
  gap: 10px;
  text-align: left;
  font-size: 15px;
  color: var(--color-lightslategray);
  font-family: var(--font-titillium-web);

  border-bottom: 1px solid #f1f1f1;
`;
export const MainBoardContentContainerColumn = styled.div`
  position: relative;
  width: 25%;
  height: 100%;
`;

export const MainBoardContentContainerFile = styled.div`
  position: relative;
  width: 100%;
  height: 96%;
  background-color: #ffffff;
  padding: 8px;

  margin-top: 10px;

  border-bottom: 1px solid #f1f1f1;
  overflow: hidden;
`;

export const FileButtonWhite = styled.button`
  cursor: pointer;
  border: none;
  padding: 0;
  background-color: var(--color-white);
  position: relative;
  border-bottom: 1px solid #f1f1f1;
  box-sizing: border-box;
  width: 100%;
  height: 50px;
  overflow: hidden;
  flex-shrink: 0;
  &:hover {
    background-color: #d6d6d6;
  }
`;

export const FileButtonWhiteText = styled.div`
  position: absolute;
  top: 50%;
  left: 5px;
  transform: translateY(-50%);
  font-size: var(--font-size-micro);
  font-family: var(--font-titillium-web);

  color: var(--color-steelblue-700);

  text-align: left;
  width: 210px;
  max-height: 32px;
  line-height: 16px;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  overflow: hidden;
`;

export const FileButtonArrowGrey = styled.img`
  position: absolute;
  height: 15%;
  width: 6%;
  top: 42%;
  bottom: 42.4%;
  right: 9px;
  max-width: 100%;
  overflow: hidden;
  max-height: 100%;
  object-fit: contain;
`;

export const ButtonClose = styled.img`
  cursor: pointer;
  position: absolute;
  height: 30px;
  width: 30px;
  top: 1.7%;
  right: 0%;
  bottom: 0%;
  left: 15.5%;
  max-width: 100%;
  overflow: hidden;
  max-height: 100%;
  object-fit: contain;

  &:hover {
    filter: brightness(0.8);
  }
`;

export const OverlayComponentBackgroundAdd1 = styled.div`
  position: absolute;
  width: 81.9%;
  top: 0%;
  left: 18.1%;
  right: 0;
  bottom: 0%;
  background: var(--color-aliceblue);

  background-size: cover;
  border: 1px solid #d6d6d6;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  display: flex;
  justify-content: felx-start;
  align-items: center;
  flex-direction: column;
`;

export const OverlayComponentWhiteAdd1 = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background: var(--color-white);

  border: 5px solid #ffffff;
  box-shadow: 0px 8px 8px rgba(0, 0, 0, 0.15);
  width: 80%;
  height: 120%;
  overflow: hidden;
`;

export const OverlayComponentWhiteAdd1Multiple = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background: var(--color-white);

  width: 100%;
  height: 100%;
  overflow: hidden;
`;

export const OverlayComponentWhiteTitleAdd1 = styled.div`
  position: relative;
  margin-top: 5.8%;
  background: transparent;
  font-size: var(--font-size-base);
  font-family: var(--font-titillium-web);
  color: var(--color-steelblue-700);

  text-align: center;
  height: 9%;
`;

export const OverlayComponentWhiteButtonFrameAdd1 = styled.div`
  margin-top: 4%;
  margin-bottom: 4%;
  background-color: transparent;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
  height: 1%;
  gap: 20px;
`;

export const FlexContainerCrea = styled.div`
  display: flex;
  align-items: stretch;
  width: 100%;
  height: 100%;

  background-color: var(--color-aliceblue);
`;

export const FlexContainerCreaMultiple = styled.div`
  display: flex;
  align-items: stretch;
  width: 100%;

  background-color: var(--color-aliceblue);
`;

export const DescriptionContainerCrea = styled.div`
  background-color: var(--color-menu-scheda-fascia);
  width: 100%;
  height: 15%;
  display: flex;
  align-items: center;
  justify-content: start;
  padding-left: 20px;
  padding-right: 20px;
  padding-top: 10px;
  padding-bottom: 10px;
`;

export const DescriptionContainerCreaMultiple = styled.div`
  background-color: var(--color-menu-scheda-fascia);
  width: 100%;
  height: 17%;
  display: flex;
  align-items: center;
  justify-content: start;
  padding-left: 15px;
  padding-right: 20px;
  padding-top: 10px;
  padding-bottom: 10px;
`;

export const DescriptionContainerCreaText = styled.div`
  text-align: justify;
  text-justify: inter-word;
  font-size: var(--font-size-micro);
  font-family: var(--font-titillium-web);
  color: var(--color-steelblue-700);
`;

export const IconSquare = styled.img`
  height: 100%;
  width: auto;
  margin-right: 20px;
`;

export const FlexContainerCreaVertical = styled.div`
  display: flex;
  flex-direction: column;
  align-items: stretch;
  width: 100%;
  height: 100%;
`;

export const CreaContainerColumnVertical = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  flex: 1;
  height: 100%;
  padding-top: 0px;
  padding-left: 0px;
  padding-right: 0px;
  padding-bottom: 100vh;
`;

export const CreaContainerColumnVerticalMultiple = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  flex: 1;
  height: 100%;
  padding-top: 0px;
  padding-left: 0px;
  padding-right: 0px;
  padding-bottom: 100vh;
`;

export const CreaContainerColumn = styled.div`
  position: relative;
  flex: 1;
  height: 100%;

  padding-top: 30px;
  padding-left: 20px;
  padding-right: 20px;
  padding-bottom: 20px;
`;

export const CreaContainerColumnMultiple = styled.div`
  position: relative;
  flex: 1;
  height: 100%;

  padding-top: 30px;
  padding-left: 20px;
  padding-right: 20px;
  padding-bottom: 20px;
`;

export const FrameTopGradient100 = styled.div`
  position: absolute;
  width: 110%;
  top: -1px;
  right: 0px;
  left: -1px;
  background: linear-gradient(90deg, #6f8aab, #425a77);

  height: 7.89%;
  overflow: hidden;
`;

export const SubMenuButtonContainerOrganigramma = styled.div`
  position: relative;
  background-color: var(--color-menu-scheda-fascia);
  width: 100%;
  height: 78px;
  top: 0;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  gap: 10px;
  padding-left: 20px;
  padding-right: 20px;
  margin-top: 0px;
  margin-bottom: 0px;
`;

export const SubMenuButtonContainerOrganigrammaMultiple = styled.div`
  position: relative;
  background-color: var(--color-menu-scheda-fascia);
  width: 100%;
  height: 13.5%;
  top: 0;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  gap: 10px;
  padding-left: 20px;
  padding-right: 20px;
  margin-top: 0px;
  margin-bottom: 0px;
`;

export const SubMenuButtonContainerOrganigrammaMultipleShort = styled.div`
  position: relative;
  background-color: var(--color-menu-scheda-fascia);
  width: 100%;
  height: 13.5%;
  top: 0;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: left;
  gap: 10px;
  padding-left: 20px;
  padding-right: 20px;
  margin-top: 0px;
  margin-bottom: 0px;
`;

export const SubMenuButtonContainerOrganigrammaNoScroll = styled.div`
  position: relative;
  background-color: var(--color-menu-scheda-fascia);
  width: 100%;
  height: 15%;
  top: 0;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  gap: 10px;
  padding-left: 20px;
  padding-right: 20px;
  margin-top: 0%;
  margin-bottom: 0px;
`;

export const CreaSubMenuButton = styled(NavLink)`
  background: transparent;
  border: none;
  cursor: pointer;
  font-size: var(--font-size-micro);
  font-family: var(--font-titillium-web);
  color: var(--color-steelblue-700);

  padding: 5px;
  position: relative;
  overflow: hidden;
  text-decoration: none;

  &:hover {
    &::after {
      content: "";
      position: absolute;
      bottom: 0;
      left: 0;
      right: 0;
      height: 2px;
      background: url("/line-green-horizontal.svg") no-repeat;
      background-size: cover;
    }
  }

  &.active {
    background: transparent;
    border: none;
    cursor: pointer;
    font-size: var(--font-size-micro);
    font-family: var(--font-titillium-web);
    color: var(--color-steelblue-700);
    padding: 5px;
    position: relative;
    overflow: hidden;
    text-decoration: none;
    &::after {
      content: "";
      position: absolute;
      bottom: 0;
      left: 0;
      right: 0;
      height: 2px;
      background: url("/line-green-horizontal.svg") no-repeat;
      background-size: cover;
    }
  }
`;

export const CreaSubMenuButtonMultiple = styled(NavLink)`
  background: transparent;
  border: none;
  cursor: pointer;
  font-size: var(--font-size-micro);
  font-family: var(--font-titillium-web);
  color: var(--color-steelblue-700);
  margin-top: 5px;
  padding-bottom: 5px;
  padding-left: 5px;
  padding-right: 5px;
  position: relative;
  overflow: hidden;
  text-decoration: none;

  &:hover {
    &::after {
      content: "";
      position: absolute;
      bottom: 0;
      left: 0;
      right: 0;
      height: 2px;
      background: url("/line-green-horizontal.svg") no-repeat;
      background-size: cover;
    }
  }

  &.active {
    background: transparent;
    border: none;
    cursor: pointer;
    font-size: var(--font-size-micro);
    font-family: var(--font-titillium-web);
    color: var(--color-steelblue-700);
    padding-bottom: 5px;
    position: relative;
    overflow: hidden;
    text-decoration: none;
    &::after {
      content: "";
      position: absolute;
      bottom: 0;
      left: 0;
      right: 0;
      height: 2px;
      background: url("/line-green-horizontal.svg") no-repeat;
      background-size: cover;
    }
  }
`;

export const StyledCreaSubMenuButton = styled(NavLink)`
  background: transparent;
  border: none;
  cursor: pointer;
  font-size: var(--font-size-micro);
  font-family: var(--font-titillium-web);
  color: var(--color-steelblue-700);
  padding: 5px;
  position: relative;
  overflow: hidden;
  text-decoration: none;

  &:hover {
    &::after {
      content: "";
      position: absolute;
      bottom: 0;
      left: 0;
      right: 0;
      height: 2px;
      background: url("/line-green-horizontal.svg") no-repeat;
      background-size: cover;
    }
  }

  &.active {
    padding: 10px;
    &::after {
      content: "";
      position: absolute;
      bottom: 0;
      left: 0;
      right: 0;
      height: 2px;
      background: url("/line-green-horizontal.svg") no-repeat;
      background-size: cover;
    }
  }
`;

export const InputContainer = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  width: 100%;
  margin-top: 10px;
`;

export const AttachedFilesList = styled.div`
  border: 1px solid #e1e1e1;
  overflow: hidden;

  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  padding: 10px 10px 10px 10px;
  margin-bottom: 20px;

  width: 100%;
  min-height: 20px;

  font-family: var(--font-titillium-web);
  font-size: var(--font-size-micro);
  line-height: 1.5;
  color: var(--color-steelblue-700);
  background-color: #ffffff;
  box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.2);
`;

export const InputTextbox = styled.input`
  border: 1px solid #e1e1e1;
  overflow: hidden;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  padding: 0px 0px 0px 8px;
  margin-bottom: 20px;
  flex-grow: 1;
  width: 100%;
  height: 35px;

  font-family: var(--font-titillium-web);
  font-size: var(--font-size-micro);
  line-height: 1.5;
  color: #5a5a5a;

  outline: none;
  box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.2);

  &::placeholder {
    color: var(--color-steelblue-700);
  }

  &:focus {
    border-color: #1a81c3;

    box-shadow: 0px 3px 5px rgba(0, 0, 0, 0.2);
  }
`;

export const IconContainer = styled.div`
  position: absolute;
  left: 0px;
  top: 33px;
  width: 10%;
  height: 2px;
  background: url("/line-blue-horizontal.svg") no-repeat left center;
`;

export const InputDescriptionText = styled.div`
  position: absolute;
  top: -18%;
  left: 2px;
  transform: translateY(-50%);
  font-size: var(--font-size-micro);
  font-family: var(--font-titillium-web);
  color: var(--color-steelblue-700);
  text-align: left;
  width: 210px;
  max-height: 32px;
  line-height: 16px;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  overflow: hidden;
`;

export const InputDescriptionTextDatePicker = styled.div`
  position: absolute;
  top: -30%;
  left: 2px;
  transform: translateY(-50%);
  font-size: var(--font-size-micro);
  font-family: var(--font-titillium-web);
  color: var(--color-steelblue-700);
  text-align: left;
  width: 210px;
  max-height: 32px;
  line-height: 16px;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  overflow: hidden;
`;

export const StyledCheckboxPanel = styled.div`
  display: flex;
  flex-direction: column;
  padding: 10px;
  background-color: #ffffff;
  border-radius: 0px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  margin-top: -20px;
  margin-left: 20px;
  margin-right: 20px;
  margin-bottom: 30px;
  border: 1px solid #e1e1e1;
`;

export const StyledCheckboxLabel = styled.label`
  display: flex;
  align-items: center;
  margin-bottom: 10px;
  font-size: var(--font-size-micro);
  font-family: var(--font-titillium-web);
  color: var(--color-steelblue-700);

  input[type="checkbox"] {
    margin-right: 10px;
  }
`;

export const NavButtonWhiteAccordion = styled.button`
  cursor: pointer;
  border: none;
  padding: 0;
  background-color: var(--white);
  position: relative;
  border: 1px solid #e1e1e1;
  box-sizing: border-box;
  width: 80%;
  height: 35px;
  overflow: visible;
  margin-top: 0px;
  margin-left: 20px;
  margin-right: 20px;
  margin-bottom: 30px;
  flex-shrink: 0;
  box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.2);
  &:hover {
    background-color: var(--color-whitesmoke);
  }
`;

export const NavButtonWhiteTextAccordion = styled.div`
  position: absolute;
  top: -27px;
  left: 1px;
  font-size: var(--font-size-micro);
  font-family: var(--font-titillium-web);
  color: var(--color-steelblue-700);
  text-align: left;
  display: flex;
  align-items: center;
  width: 206px;
  height: 35px;
`;

export const ArrowGreyAccordion = styled.img`
  position: absolute;
  right: 15px;
  top: 47%;
  height: 7px;
  max-width: 100%;
  overflow: hidden;
  max-height: 100%;
  object-fit: contain;
`;

export const IconContainerAccordion = styled.div`
  position: absolute;
  left: -1px;
  top: 32px;
  width: 10%;
  height: 2px;
  background: url("/line-blue-horizontal.svg") no-repeat left center;
`;

export const Backdrop = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;
`;

export const BackdropTransparent = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;
`;

export const MessageContainer = styled.div`
  padding: 20px;
  background: white;
  border-radius: 5px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const MessageboxText = styled.div`
  font-size: var(--font-size-micro);
  font-family: var(--font-titillium-web);
  color: var(--color-steelblue-700);
  text-align: center;
`;

export const CloseMessageButton = styled.button`
  cursor: pointer;
  border: none;
  padding: 0;
  background-color: transparent;
  border: 1px solid var(--color-steelblue-100);

  position: relative;
  border-radius: var(--br-8xs);

  width: 120px;
  height: 30px;
  overflow: hidden;
  &:hover {
    border: 2px solid var(--color-steelblue-100);
  }
`;

export const SelectionDatalist = styled.div`
  position: relative;
  width: 100%;

  &:after {
    content: "▼";
    position: absolute;
    top: 50%;
    right: 8px;
    transform: translateY(-50%);
    pointer-events: none;
    color: #888;
  }
`;

export const DivWhiteAccordionInfrastrutturaAsset = styled.div`
  position: relative;
  margin-top: 1.5vh;
  padding: 0;
  background-color: var(--white);
  height: 53.9vh;
  border: 1px solid #e1e1e1;
  box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.2);
  width: calc(100%);
  &:hover {
  }
`;

export const IconContainerDivInfrastrutturaAsset = styled.div`
  position: relative;
  left: -1px;
  top: 53.4vh;
  width: 10%;
  height: 2px;
  background: url("/line-blue-horizontal.svg") no-repeat left center;
`;

export const DivWhiteAccordionInfrastrutturaLuoghi = styled.div`
  position: relative;
  padding: 0;
  margin-top: 1.5vh;
  background-color: var(--white);
  height: 44.4vh;
  border: 1px solid #e1e1e1;
  box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.2);
  width: calc(100%);
  &:hover {
  }
`;

export const IconContainerDivInfrastrutturaLuoghi = styled.div`
  position: relative;
  left: -1px;
  top: 43.9vh;
  width: 10%;
  height: 2px;
  background: url("/line-blue-horizontal.svg") no-repeat left center;
`;

export const DivWhiteAccordionProcessi = styled.div`
  position: relative;
  margin-top: 5.9vh;
  padding: 0;
  background-color: var(--white);
  height: 54vh;
  border: 1px solid #e1e1e1;
  box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.2);
  width: calc(100% - 40px);
  &:hover {
  }
`;

export const IconContainerDivProcessi = styled.div`
  position: relative;
  left: -1px;
  top: 53.6vh;
  width: 10%;
  height: 2px;
  background: url("/line-blue-horizontal.svg") no-repeat left center;
`;

export const DivWhiteAccordionPartiInteressate = styled.div`
  margin-top: 0;
  position: relative;
  padding: 0;
  background-color: var(--white);
  height: 15.05vh;
  margin-bottom: 30px;
  border: 1px solid #e1e1e1;
  box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.2);
  width: calc(100% - 40px);
  &:hover {
  }
`;

export const IconContainerDivPartiInteressate = styled.div`
  position: relative;
  left: -1px;
  top: 14.6vh;
  width: 10%;
  height: 2px;
  background: url("/line-blue-horizontal.svg") no-repeat left center;
`;

export const DivContainer = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;

  align-items: center;
  margin-top: 6vh;
  position: relative;

  height: 70vh;
  width: 100%;
`;

export const DivWhiteAccordionCompliance = styled.div`
  margin-top: 0;
  position: relative;
  padding: 0;
  background-color: var(--white);
  height: 43vh;
  margin-bottom: 30px;
  margin-top: 20px;
  border: 1px solid #e1e1e1;
  box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.2);
  width: calc(100%);
  &:hover {
  }
`;

export const IconContainerDivCompliance = styled.div`
  position: relative;
  left: -1px;
  top: 42.6vh;
  width: 10%;
  height: 2px;
  background: url("/line-blue-horizontal.svg") no-repeat left center;
`;

export const StyledIconContainer = styled.img`
  position: absolute;
  margin-top: 33vh;
  margin-left: 63.9vh;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 25px;
  height: 25px;

  border-radius: 50%;
`;

export const DivWhiteAccordionDocumentazione = styled.div`
  position: relative;
  margin-top: 1.6vh;
  padding: 0;
  background-color: var(--white);
  height: 48vh;
  border: 1px solid #e1e1e1;
  box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.2);
  width: calc(100%);
  &:hover {
  }
`;

export const IconContainerDivDocumentazione = styled.div`
  position: relative;
  left: -1px;
  top: 47.6vh;
  width: 10%;
  height: 2px;
  background: url("/line-blue-horizontal.svg") no-repeat left center;
`;

export const ButtonGreyAllega = styled.button`
  cursor: pointer;
  border: none;
  padding: 0;
  background-color: transparent;
  border: 1px solid #d6d6d6;
  margin-top: 10px;
  position: relative;
  border-radius: var(--br-8xs);

  width: 100%;
  height: 30px;
  overflow: hidden;
  &:hover {
    border: 2px solid #d6d6d6;
  }
`;

export const ButtonGreyAllegaText = styled.b`
  position: absolute;
  height: calc(100% - 27px);
  width: 100%;
  top: 13px;
  left: 0px;

  display: flex;
  font-family: var(--font-titillium-web);
  font-size: var(--font-size-micro);
  color: var(--color-steelblue-700);
  text-align: center;
  align-items: center;
  justify-content: center;
`;
export const CustomDropdownContainer = styled.div`
  position: relative;
  display: inline-block;
`;

export const CustomDropdownList = styled.ul`
  width: 100%;
  max-height: 20vh;
  list-style: none;
  padding: 0;
  margin: 0;
  position: absolute;
  top: 80%;
  left: 0;
  background-color: #fff;

  border: 1px solid #dddddd;

  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
  z-index: 1;
  overflow-y: hidden;
`;

export const CustomDropdownItem = styled.li`
  border-bottom: 1px solid #f1f1f1;
  padding: 8px 16px;
  cursor: pointer;
  width: 100%;
  font-family: var(--font-titillium-web);
  font-size: var(--font-size-micro);
  color: var(--color-steelblue-700);

  &:hover {
    background-color: var(--color-menu-hover-light-blue);
  }
`;

export const DropDownMenuScrollbar = styled.div`
  overflow-y: auto;
  max-height: 19vh;
  width: 100%;

  &::-webkit-scrollbar {
    width: 4px;
  }

  &::-webkit-scrollbar-track {
    background-color: #f1f1f1;
  }

  &::-webkit-scrollbar-thumb {
    background-color: #888;
    border-radius: 4px;
  }

  &::-webkit-scrollbar-thumb:hover {
    background: #555;
  }
`;

export const TriangleContainer = styled.div`
  font-family: var(--font-titillium-web);
  font-size: var(--font-size-micro);
  color: #bbbbbb;
  position: absolute;
  right: 10px;
  top: 33%;
  transform: translateY(-50%);
`;
export const InputButtonText = styled.div`
  position: absolute;
  left: 10px;
  font-size: var(--font-size-micro);
  font-family: var(--font-titillium-web);
  color: var(--color-lightslategray);
  text-align: left;
  display: flex;
  align-items: center;
`;

export const InputTextboxButton = styled.button`
  cursor: pointer;
  background-color: #ffffff;
  border: 1px solid #e1e1e1;
  overflow: hidden;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  padding: 0px 0px 0px 8px;
  margin-bottom: 20px;
  flex-grow: 1;
  width: 100%;
  height: 35px;

  font-family: var(--font-titillium-web);
  font-size: var(--font-size-micro);
  line-height: 1.5;
  color: #5a5a5a;

  outline: none;
  box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.2);

  &:hover {
    border-color: #1a81c3;

    box-shadow: 0px 3px 5px rgba(0, 0, 0, 0.2);
  }

  &::placeholder {
    color: var(--color-steelblue-700);
  }

  /*  
  &:focus {
     
    border-color:  #1a81c3;
   
    box-shadow: 0px 3px 5px rgba(0, 0, 0, 0.20);
     
  } */
`;

export const FadeOutComponent = styled.div`
  font-family: var(--font-titillium-web);
  font-size: var(--font-size-micro);
  color: #bbbbbb;
  position: fixed;
  right: 0px;
  top: 0px;
  bottom: 0px;
  width: 81.9%;
  background-color: var(--color-menu-hover-light-blue);
`;

export const LastPopupCheckboxContainer = styled.div`
  font-family: var(--font-titillium-web);
  font-size: var(--font-size-micro);
  color: #bbbbbb;
  position: relative;
  height: 80%;

  width: 93%;

  background-color: var(--color-menu-hover-light-blue);
  border: 5px solid #ffffff;
  box-shadow: 0px 3px 5px rgba(0, 0, 0, 0.2);
`;

export const LastPopupButtonContainer = styled.div`
  position: relative;
  height: 10%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const LastPopupTitleContainer = styled.div`
  position: relative;
  font-family: var(--font-titillium-web);
  font-size: var(--font-size-base);
  height: 10%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const LastPopupHeaderContainer = styled.div`
  position: relative;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  top: 0px;
  height: 10%;
  background-color: var(--color-menu-scheda-fascia);
`;

export const LastPopupScrollbar = styled.div`
  overflow-y: auto;
  height: 90%;
  width: 100%;
  padding: 2%;

  &::-webkit-scrollbar {
    width: 4px;
  }

  &::-webkit-scrollbar-track {
    background-color: #f1f1f1;
  }

  &::-webkit-scrollbar-thumb {
    background-color: #cccccc;
    border-radius: 4px;
  }

  &::-webkit-scrollbar-thumb:hover {
    background: #555;
  }
`;

export const LastPopupSearchInputContainer = styled.div`
  position: relative;
  width: 97%;
  margin-right: 0px;
  height: 60%;
`;

export const LastPopupSearchIcon = styled.img`
  position: absolute;
  left: 10px;
  top: 50%;
  transform: translateY(-50%);
  width: 16px;
  height: 16px;
  pointer-events: none;
`;

export const LastPopupSearch = styled.input`
  border: 1px solid #e1e1e1;
  overflow: hidden;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  padding: 0px 0px 0px 30px;
  margin-right: 0px;
  width: 100%;
  height: 100%;

  font-family: var(--font-titillium-web);
  font-size: var(--font-size-micro);
  line-height: 1.5;
  color: var(--color-lightslategray);

  outline: none;
  box-shadow: none;

  &::placeholder {
    color: #d1d1d1;
  }

  &:focus {
    border-color: var(--color-primary);
  }
`;
