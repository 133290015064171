import React, { useState, useEffect, useMemo, useCallback } from "react";
import styled from "styled-components";
import { OverlayContext } from "../cybersecurity-board-contestoleadership-organizzazione";
import { useNavigate, Routes, Route, Outlet } from "react-router-dom";

import {
  IconButton,
  IconsGroup,
  BackgroundIcon,
  Backdrop,
  BackdropTransparent,
  MessageContainer,
  MessageboxText,
  CloseMessageButton,
  FadeOutComponent,
  FrameTopGradient100,
} from "../cybersecurity-board-contestoleadership-organizzazione-sub/cybersecurity-board-organizzazione-organigramma-styled-components";
import {
  StyledTable,
  StyledTbody,
  StyledTd,
  StyledTh,
  StyledThead,
  TableItemText,
  TableTitleText,
  TableScrollableDiv,
  TableContainer,
  TopContainer,
  Search,
  SearchIcon,
  SearchInputContainer,
  StyledTableItem,
  StyledThRifNorm,
  StyledTdRifNorm,
  StyledThComplianceRole,
  StyledTdComplianceRole,
} from "../cybersecurity-board-contestoleadership-organizzazione-sub/cybersecurity-board-organizzazione-unitaoperative-styled-components";

import { useRecoilState, useRecoilValue } from "recoil";
import { SelectedL1ID, SelectedL2ID, SelectedL3ID, SelectedL4ID, reFetchIndicatorComplianceRoles } from "../../../../recoil";
import { URL_ComplianceRoles, URL_ComplianceRolesShort, useApi, useToken } from "../../../../API";
import { RuoliComplianceDeleteConfirmationDialog } from "./RuoliCompliance-Add-recoil";

type DataItem = {
  id: string;
  compliance_role_role_description: string;
  compliance_role_responsibility: string;
  unit_prefLabel: string;
  has_unit: string;
  person_fullName: string;
  norm_ref: string;
};

type SortableKeys = keyof DataItem;

type ApiContentItem = {
  id: string;
  compliance_role: {
    role_description: string;
    responsibility: string;
  };
  unit: {
    prefLabel: string;
  };
  person: {
    name: string;
    surname: string;
  };
};

type StyledTrProps = {
  selected: boolean;
};

const StyledTr = styled.tr<StyledTrProps>`
  cursor: pointer;
  background-color: ${(props) => {
    return props.selected ? "#f3f7fb" : "#ffffff";
  }};

  &:hover {
    background-color: #e9eff7;
  }
`;

interface OverlayComponentProps {
  isAnimating: boolean;
}

const OverlayComponentAdd1 = styled.div<OverlayComponentProps>`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  z-index: 10;
  transition: opacity 300ms ease-in-out, visibility 300ms ease-in-out;
  opacity: ${(props) => (props.isAnimating ? "1" : "0")};
  visibility: ${(props) => (props.isAnimating ? "visible" : "hidden")};
`;

const useDeletePlace = () => {
  const { del } = useApi();
  const [reFetchIndicator, setRefetchIndicator] = useRecoilState(reFetchIndicatorComplianceRoles);
  const [isConfirmDialogVisible, setIsConfirmDialogVisible] = useRecoilState(RuoliComplianceDeleteConfirmationDialog);
  const [L2ID, setL2ID] = useRecoilState(SelectedL2ID);

  const handleDelete = useCallback(async () => {
    if (!L2ID) return;

    const url = URL_ComplianceRolesShort;
    try {
      await del(url, L2ID);
      console.log(`Deleted role with ID: ${L2ID}`);
      setRefetchIndicator((prev) => prev + 1);
    } catch (error) {
      console.error("Failed to delete role:", error);
    } finally {
      setIsConfirmDialogVisible(false);
    }
  }, [L2ID, del, setRefetchIndicator, setIsConfirmDialogVisible]);

  const showDeleteConfirmation = useCallback(() => {
    setIsConfirmDialogVisible(true);
  }, []);

  return { handleDelete, showDeleteConfirmation, isConfirmDialogVisible, setIsConfirmDialogVisible };
};

const DataTable: React.FC<{ data: DataItem[] }> = ({ data }) => {
  const [isOverlayVisibleAdd2, setIsOverlayVisibleAdd2] = useState(false);
  const [isOverlayAnimatingAdd2, setIsOverlayAnimatingAdd2] = useState(false);

  const navigate = useNavigate();

  const toggleOverlayAdd2 = () => {
    if (!isOverlayVisibleAdd2) {
      if (window.location.pathname === "/Cybersecurity/GestioneCompliance") {
        navigate(`GestioneCompliance/CreaRuoliCompliance/1`);
      } else {
        navigate(`${window.location.pathname}/CreaRuoliCompliance/1`);
      }

      setIsOverlayVisibleAdd2(true);
      setTimeout(() => setIsOverlayAnimatingAdd2(true), 10);
    } else {
      setIsOverlayAnimatingAdd2(false);
      setTimeout(() => setIsOverlayVisibleAdd2(false), 310);
    }
  };

  const closeOverlayAdd2 = () => {
    navigate(`/Cybersecurity/GestioneCompliance/RuoliCompliance`);
    setIsOverlayAnimatingAdd2(false);

    setTimeout(() => {
      setIsOverlayVisibleAdd2(false);
    }, 300);
  };

  const [selectedL1, setSelectedL1] = useState<string | null>(null);
  const [selectedL2, setSelectedL2] = useState<string | null>(null);
  const [selectedL3, setSelectedL3] = useState<string | null>(null);
  const [selectedL4, setSelectedL4] = useState<string | null>(null);

  const [L1ID, setL1ID] = useRecoilState(SelectedL1ID);
  const [L2ID, setL2ID] = useRecoilState(SelectedL2ID);
  const [L3ID, setL3ID] = useRecoilState(SelectedL3ID);
  const [L4ID, setL4ID] = useRecoilState(SelectedL4ID);

  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const [isMessageBoxVisible, setIsMessageBoxVisible] = useState(false);

  const [sortConfig, setSortConfig] = useState<{ key: SortableKeys; direction: "ascending" | "descending" } | null>(null);

  const sortedData = useMemo(() => {
    let sortableItems = [...data];
    if (sortConfig !== null) {
      sortableItems.sort((a, b) => {
        const aValue = a[sortConfig.key];
        const bValue = b[sortConfig.key];

        if (typeof aValue === "string" && typeof bValue === "string") {
          return sortConfig.direction === "ascending" ? aValue.localeCompare(bValue) : bValue.localeCompare(aValue);
        }

        return 0;
      });
    }
    return sortableItems;
  }, [data, sortConfig]);

  const requestSort = (key: SortableKeys) => {
    let direction: "ascending" | "descending" = "ascending";
    if (sortConfig && sortConfig.key === key && sortConfig.direction === "ascending") {
      direction = "descending";
    }
    setSortConfig({ key, direction });
  };

  const [selectedId, setSelectedId] = useState<string | null>(null);

  const handleRowSelect = (id: string) => {
    setSelectedId((prevId) => {
      return id;
    });

    setL2ID(id);
  };

  useEffect(() => {
    console.log("L2ID now has the value:", L2ID);
  }, [L2ID]);

  const [globalFilterText, setGlobalFilterText] = useState("");

  const handleGlobalFilterChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setGlobalFilterText(event.target.value);
  };

  const [filterText, setFilterText] = useState("");

  const handleFilterChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setFilterText(event.target.value);
  };

  const filteredAndSortedData = useMemo(() => {
    const filteredData = data
      .filter((item) => {
        return item.compliance_role_role_description.toLowerCase().includes(filterText.toLowerCase());
      })
      .filter((item) => {
        if (globalFilterText) {
          const allText = Object.values(item).join(" ").toLowerCase();
          return allText.includes(globalFilterText.toLowerCase());
        }
        return true;
      });

    if (sortConfig !== null) {
      filteredData.sort((a, b) => {
        const aValue = a[sortConfig.key];
        const bValue = b[sortConfig.key];

        if (typeof aValue === "string" && typeof bValue === "string") {
          return sortConfig.direction === "ascending" ? aValue.localeCompare(bValue) : bValue.localeCompare(aValue);
        }
        return 0;
      });
    }

    return filteredData;
  }, [data, sortConfig, filterText, globalFilterText]);

  type StateDisplay = {
    text: string;
    color: string;
  };

  const stateDisplayMapping: Record<string, StateDisplay> = {
    "asset.status.in_use": { text: "In uso", color: "#00c890" },
    "asset.status.eliminated": { text: "Ritirato", color: "#707070" },
    "asset.status.in_storage": { text: "In magazzino", color: "#1f78ff" },
    "asset.status.archived": { text: "Archiviato", color: "#ca7300" },

    "asset.status.transiting": { text: "In transito", color: "#e6bb00" },
    "asset.status.missing": { text: "Mancante", color: "#970019" },
  };

  const ColoredText = styled.span`
    color: ${(props) => props.color};
    font-family: "Titillium Web";
    font-size: 12px;
    font-weight: bold;
  `;

  interface StateTextProps {
    state: string;
  }

  const StateText: React.FC<StateTextProps> = ({ state }) => {
    const display = stateDisplayMapping[state] || { text: state, color: "black" };
    return <ColoredText color={display.color}>{display.text}</ColoredText>;
  };

  return (
    <TableContainer>
      <TopContainer>
        <SearchInputContainer>
          <Search placeholder="Filtra per ruolo" onChange={handleFilterChange} />
          <SearchIcon src="/funnel.svg" />
        </SearchInputContainer>
        <SearchInputContainer>
          <Search placeholder="Cerca in tutta la tabella" onChange={handleGlobalFilterChange} />
          <SearchIcon src="/search.svg" />
        </SearchInputContainer>

        <IconsGroup>
          {/* <IconButton>
            <BackgroundIcon loading="eager" alt="" src="/doc.svg" />
          </IconButton>
          <IconButton>
            <BackgroundIcon loading="eager" alt="" src="/edit.svg" />
          </IconButton> */}
          <IconButton>
            <BackgroundIcon loading="eager" alt="" src="/delete.svg" />
          </IconButton>
          <IconButton>
            <BackgroundIcon loading="eager" alt="" src="/add.svg" onClick={toggleOverlayAdd2} />
          </IconButton>
        </IconsGroup>
      </TopContainer>

      <StyledTable>
        <StyledThead>
          <StyledTh onClick={() => requestSort("compliance_role_role_description")}>
            <TableTitleText>▼ Ruolo Compliance</TableTitleText>
          </StyledTh>
          <StyledThComplianceRole onClick={() => requestSort("compliance_role_responsibility")}>
            <TableTitleText>▼ Descrizione responsabilitá</TableTitleText>
          </StyledThComplianceRole>
          <StyledTh onClick={() => requestSort("unit_prefLabel")}>
            <TableTitleText>▼ Unitá operativa</TableTitleText>
          </StyledTh>
          <StyledTh onClick={() => requestSort("person_fullName")}>
            <TableTitleText>▼ Nome dell'incaricato</TableTitleText>
          </StyledTh>
          <StyledThRifNorm onClick={() => requestSort("norm_ref")}>
            <TableTitleText>▼ Rif. norm.</TableTitleText>
          </StyledThRifNorm>
        </StyledThead>
      </StyledTable>
      <TableScrollableDiv>
        <StyledTableItem>
          <StyledTbody>
            {filteredAndSortedData.map((item) => (
              <StyledTr key={item.id} onClick={() => handleRowSelect(item.id)} selected={selectedId === item.id}>
                {" "}
                <StyledTd title={item.compliance_role_role_description}>
                  <TableItemText>{item.compliance_role_role_description}</TableItemText>
                </StyledTd>
                <StyledTdComplianceRole title={item.compliance_role_responsibility}>
                  <TableItemText>{item.compliance_role_responsibility}</TableItemText>
                </StyledTdComplianceRole>
                <StyledTd title={item.unit_prefLabel}>
                  <TableItemText>{item.unit_prefLabel}</TableItemText>
                </StyledTd>
                <StyledTd title={item.person_fullName}>
                  <TableItemText>{item.person_fullName}</TableItemText>
                </StyledTd>
                <StyledTdRifNorm title={item.norm_ref}>
                  <TableItemText>{item.norm_ref}</TableItemText>
                </StyledTdRifNorm>
              </StyledTr>
            ))}
          </StyledTbody>
        </StyledTableItem>
      </TableScrollableDiv>

      <OverlayContext.Provider value={{ closeOverlay: closeOverlayAdd2, selectedL1, setSelectedL1, selectedL2, setSelectedL2, selectedL3, setSelectedL3, selectedL4, setSelectedL4 }}>
        {isOverlayVisibleAdd2 && (
          <OverlayComponentAdd1 isAnimating={isOverlayAnimatingAdd2}>
            {/* <ButtonClose src="/close.svg" onClick={closeOverlayAdd2} /> */}
            <FadeOutComponent>
              <FrameTopGradient100></FrameTopGradient100>
            </FadeOutComponent>

            <Outlet />
          </OverlayComponentAdd1>
        )}
      </OverlayContext.Provider>
    </TableContainer>
  );
};

const Board27001Dashboard: React.FC = () => {
  const token = useToken();

  const [L1ID, setL1ID] = useRecoilState(SelectedL1ID);
  const [L2ID, setL2ID] = useRecoilState(SelectedL2ID);
  const [L3ID, setL3ID] = useRecoilState(SelectedL3ID);
  const [L4ID, setL4ID] = useRecoilState(SelectedL4ID);

  const [data, setData] = useState<DataItem[]>([]);
  const [selectedL1, setSelectedL1] = useState<string | null>(null);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const [isMessageBoxVisible, setIsMessageBoxVisible] = useState(false);
  const [reFetchIndicator, setRefetchIndicator] = useRecoilState(reFetchIndicatorComplianceRoles);

  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true);
      try {
        if (!token) throw new Error("User is not authenticated");

        const infoResponse = await fetch(`${URL_ComplianceRoles}/info`, {
          headers: { Authorization: `Bearer ${token}` },
        });

        if (!infoResponse.ok) {
          throw new Error(`API request for total failed: ${infoResponse.statusText}`);
        }

        const infoData = await infoResponse.json();
        const total = infoData.total;

        const dataResponse = await fetch(`${URL_ComplianceRoles}?size=${total}`, {
          headers: { Authorization: `Bearer ${token}` },
        });

        if (!dataResponse.ok) {
          throw new Error(`API request for data failed: ${dataResponse.statusText}`);
        }

        const responseData = await dataResponse.json();

        if (Array.isArray(responseData.content)) {
          const transformedData = responseData.content.map((item: any) => {
            return {
              id: item.id,
              compliance_role_role_description: item.compliance_role?.role_description || "",
              compliance_role_responsibility: item.compliance_role?.responsibility || "",
              unit_prefLabel: item.unit?.prefLabel || "",
              has_unit: item.has_unit,
              person_fullName: `${item.person?.name || ""} ${item.person?.surname || ""}`.trim(),
              norm_ref: item.compliance_role.norm_ref,
            };
          });

          console.log("Transformed Data:", transformedData);
          setData(transformedData);
        } else {
          throw new Error("Expected an array of items in content");
        }
      } catch (err) {
        setError(err instanceof Error ? err.message : "An unknown error occurred");
        setIsMessageBoxVisible(true);
      } finally {
        setIsLoading(false);
      }
    };

    fetchData();
  }, [token, reFetchIndicator]);

  const { handleDelete, showDeleteConfirmation, isConfirmDialogVisible, setIsConfirmDialogVisible } = useDeletePlace();

  return (
    <div>
      {isMessageBoxVisible && (
        <Backdrop>
          <MessageContainer>
            <p>
              <MessageboxText>{error}</MessageboxText>
            </p>
            <CloseMessageButton onClick={() => setIsMessageBoxVisible(false)}>
              <MessageboxText>Close</MessageboxText>
            </CloseMessageButton>
          </MessageContainer>
        </Backdrop>
      )}

      {isLoading && (
        <BackdropTransparent>
          <MessageContainer>
            <MessageboxText>Loading...</MessageboxText>
          </MessageContainer>
        </BackdropTransparent>
      )}

      {error && (
        <Backdrop>
          <MessageContainer>
            <p>
              <MessageboxText>{error}</MessageboxText>
            </p>
            <CloseMessageButton
              onClick={() => {
                setIsMessageBoxVisible(false);
                setError(null);
              }}
            >
              <MessageboxText>Close</MessageboxText>
            </CloseMessageButton>
          </MessageContainer>
        </Backdrop>
      )}

      {isConfirmDialogVisible && (
        <Backdrop>
          <MessageContainer>
            <p>
              <MessageboxText>Sei sicuro di voler rimuovere questo ruolo Compliance?</MessageboxText>
            </p>
            <div style={{ display: "flex", justifyContent: "center", gap: "20px" }}>
              <CloseMessageButton onClick={() => setIsConfirmDialogVisible(false)}>
                <MessageboxText>No</MessageboxText>
              </CloseMessageButton>
              <CloseMessageButton onClick={handleDelete}>
                <MessageboxText>Rimuovi</MessageboxText>
              </CloseMessageButton>
            </div>
          </MessageContainer>
        </Backdrop>
      )}

      <DataTable data={data} />
    </div>
  );
};

export default Board27001Dashboard;
