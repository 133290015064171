import dayjs from "dayjs";
import React, { Suspense, useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useRecoilState } from "recoil";
import { isLoadingRec, isMessageBoxVisibleClosingRec, isMessageBoxVisibleRec, reFetchIndicatorL4, setErrorRec, setMessageRec } from "../../../../recoil";
import { OverlayContext } from "../cybersecurity-board-contestoleadership-organizzazione";

import SchedaGenerale from "./cybersecurity-board-org-org-L4-Add-sub/organigramma-L4-Add-1-SchedaGenerale";

import Documentazione, { useInitiateUpload } from "./cybersecurity-board-org-org-L4-Add-sub/organigramma-L4-Add-7-Documentazione";

import { ButtonGrey, ButtonText } from "../../styled-components";

import {
  Backdrop,
  BackdropTransparent,
  CloseMessageButton,
  CreaSubMenuButtonMultiple,
  FrameTopGradient100,
  MessageboxText,
  MessageContainer,
  OverlayComponentBackgroundAdd1,
  OverlayComponentWhiteAdd1,
  OverlayComponentWhiteButtonFrameAdd1,
  OverlayComponentWhiteTitleAdd1,
  SubMenuButtonContainerOrganigrammaMultipleShort,
} from "./cybersecurity-board-organizzazione-organigramma-styled-components";

import { URL_LV4List_short, useApi, useToken } from "../../../../API";
import { PATH_Assets, PATH_L4 } from "../../../../docPaths";
import {
  addL4AttachedFilesInfo,
  addL4birthDate,
  addL4birthPlace,
  addL4DocumentsAttached,
  addL4DocumentsPath,
  addL4domicileAddress,
  addL4fiscalCode,
  addL4gender,
  addL4Name,
  addL4personalEmail,
  addL4PersonID,
  addL4privatePhone,
  addL4residenceAddress,
  addL4Surname,
  addL4workEmail,
  addL4workPhone,
} from "./L4-Add-recoil";

function generateUUID(): string {
  return "xxxxxxxx-xxxx-xxxx-yxxx-xxxxxxxxxxxx".replace(/[xy]/g, (c) => {
    const r = (Math.random() * 16) | 0;
    const v = c === "x" ? r : (r & 0x3) | 0x8;
    return v.toString(16);
  });
}

function useGenerateUUID(): string {
  const [personIdPost, setPersonIdPost] = useRecoilState<string>(addL4PersonID);

  useEffect(() => {
    const newUUID = generateUUID();
    setPersonIdPost(newUUID);
    console.log("New person ID: ", newUUID);
  }, [setPersonIdPost]);

  return personIdPost;
}

export const useHandleSave = () => {
  const [isLoading, setIsLoading] = useRecoilState(isLoadingRec);
  const [error, setError] = useRecoilState(setErrorRec);
  const [isMessageBoxVisible, setIsMessageBoxVisible] = useRecoilState(isMessageBoxVisibleRec);
  const [isMessageBoxVisibleClosing, setIsMessageBoxVisibleClosing] = useRecoilState(isMessageBoxVisibleClosingRec);
  const [message, setMessage] = useRecoilState(setMessageRec);

  const { closeOverlay } = useContext(OverlayContext);
  const { post } = useApi();

  const [personIdPost, setPersonIdPost] = useRecoilState<string>(addL4PersonID);

  const [AttachedFilesInfo, setAttachedFilesInfo] = useRecoilState(addL4AttachedFilesInfo);
  const [DocumentsAttached, setDocumentsAttached] = useRecoilState(addL4DocumentsAttached);
  const [DocumentsPath, setDocumentsPath] = useRecoilState(addL4DocumentsPath);

  const [surname, setSurname] = useRecoilState(addL4Surname);
  const [name, setName] = useRecoilState(addL4Name);
  const [birthDate, setBirthDate] = useRecoilState(addL4birthDate);
  const [birthPlace, setBirthPlace] = useRecoilState(addL4birthPlace);
  const [gender, setGender] = useRecoilState(addL4gender);
  const [fiscalCode, setFiscalCode] = useRecoilState(addL4fiscalCode);
  const [residenceAddress, setResidenceAddress] = useRecoilState(addL4residenceAddress);
  const [domicileAddress, setDomicileAddress] = useRecoilState(addL4domicileAddress);
  const [workEmail, setWorkEmail] = useRecoilState(addL4workEmail);
  const [personalEmail, setPersonalEmail] = useRecoilState(addL4personalEmail);
  const [workPhone, setWorkPhone] = useRecoilState(addL4workPhone);
  const [privatePhone, setPrivatePhone] = useRecoilState(addL4privatePhone);

  const handleSave = async () => {
    if (!surname || !name || !fiscalCode) {
      setMessage("Si prega di compilare tutti i campi obbligatori: nome, cognome e codice fiscale.");
      setIsMessageBoxVisibleClosing(true);
      return;
    }

    const newData = {
      id: personIdPost,

      surname: surname,
      name: name,

      member_of_formal_organization: "organizations.ecubit02",

      documents_folder_link: PATH_L4 + fiscalCode,

      personal_card: {
        national_identification_number: fiscalCode,
        date_of_birth: birthDate ? dayjs(birthDate).format("YYYY-MM-DD") : null,
        has_documents_folder_link: `${PATH_Assets}${fiscalCode}`,
        has_place_of_birth: birthPlace,
        has_primary_residence: residenceAddress,
        has_postal_address: domicileAddress,
        personal_email: personalEmail,
        work_email: workEmail,
        work_mobile_phone: workPhone,
        personal_mobile_phone: privatePhone,
        has_identity_document: "",
        has_citizenship: "",
      },
    };

    try {
      setIsLoading(true);
      console.log(JSON.stringify(newData));
      const responseData = await post(URL_LV4List_short, newData);

      console.log("Person created successfully:", responseData);
      setMessage("Person created successfully!");
      setIsMessageBoxVisible(true);
    } catch (error) {
      console.error("Error creating person:", error);
      setMessage(`Error creating person: ${error instanceof Error ? error.message : "Unknown error"}`);
      setIsMessageBoxVisible(true);
    } finally {
      setIsLoading(false);
    }
  };

  return { handleSave };
};

const MainComponent = () => {
  const token = useToken();

  const [personIdPost, setPersonIdPost] = useRecoilState<string>(addL4PersonID);

  const [AttachedFilesInfo, setAttachedFilesInfo] = useRecoilState(addL4AttachedFilesInfo);
  const [DocumentsAttached, setDocumentsAttached] = useRecoilState(addL4DocumentsAttached);

  const [DocumentsPath, setDocumentsPath] = useRecoilState(addL4fiscalCode);

  const [isMessageBoxVisibleClosing, setIsMessageBoxVisibleClosing] = useRecoilState(isMessageBoxVisibleClosingRec);

  const [surname, setSurname] = useRecoilState(addL4Surname);
  const [name, setName] = useRecoilState(addL4Name);
  const [birthDate, setBirthDate] = useRecoilState(addL4birthDate);
  const [birthPlace, setBirthPlace] = useRecoilState(addL4birthPlace);
  const [gender, setGender] = useRecoilState(addL4gender);
  const [fiscalCode, setFiscalCode] = useRecoilState(addL4fiscalCode);
  const [residenceAddress, setResidenceAddress] = useRecoilState(addL4residenceAddress);
  const [domicileAddress, setDomicileAddress] = useRecoilState(addL4domicileAddress);
  const [workEmail, setWorkEmail] = useRecoilState(addL4workEmail);
  const [personalEmail, setPersonalEmail] = useRecoilState(addL4personalEmail);
  const [workPhone, setWorkPhone] = useRecoilState(addL4workPhone);
  const [privatePhone, setPrivatePhone] = useRecoilState(addL4privatePhone);

  const { handleSave } = useHandleSave();
  const initiateUpload = useInitiateUpload();

  const { closeOverlay } = useContext(OverlayContext);

  const navigate = useNavigate();

  const [isLoading, setIsLoading] = useRecoilState(isLoadingRec);
  const [error, setError] = useRecoilState(setErrorRec);
  const [isMessageBoxVisible, setIsMessageBoxVisible] = useRecoilState(isMessageBoxVisibleRec);
  const [message, setMessage] = useRecoilState(setMessageRec);
  const [reFetchIndicator, setRefetchIndicator] = useRecoilState(reFetchIndicatorL4);

  useGenerateUUID();

  const handleCloseAndNavigate = () => {
    setRefetchIndicator((prev) => prev + 1);
    setIsMessageBoxVisible(false);
    closeOverlay();
  };

  const handleCloseMessage = () => {
    setRefetchIndicator((prev) => prev + 1);
    setIsMessageBoxVisibleClosing(false);
  };

  const handleSaveAndUpload = async () => {
    await handleSave();
    initiateUpload();
  };

  const handleClose = () => {
    closeOverlay();

    setAttachedFilesInfo([]);
    setDocumentsAttached([]);
    setDocumentsPath("");

    setSurname("");
    setName("");
    setBirthDate(dayjs());
    setBirthPlace("");
    setGender("");
    setFiscalCode("");
    setResidenceAddress("");
    setDomicileAddress("");
    setWorkEmail("");
    setPersonalEmail("");
    setWorkPhone("");
    setPrivatePhone("");
  };

  const [activeComponent, setActiveComponent] = useState("SchedaGenerale");

  const changeActiveComponent = (componentName: string) => {
    setActiveComponent(componentName);
  };

  const renderActiveComponent = () => {
    switch (activeComponent) {
      case "SchedaGenerale":
        return <SchedaGenerale />;

      case "Documentazione":
        return <Documentazione />;
      default:
        return <SchedaGenerale />;
    }
  };

  return (
    <OverlayComponentBackgroundAdd1>
      {isLoading && (
        <BackdropTransparent>
          <MessageContainer>
            <MessageboxText>Loading...</MessageboxText>
          </MessageContainer>
        </BackdropTransparent>
      )}

      {isMessageBoxVisible && (
        <Backdrop>
          <MessageContainer>
            <p>
              <MessageboxText>{message}</MessageboxText>
            </p>
            <CloseMessageButton onClick={handleCloseAndNavigate}>
              <MessageboxText>Close</MessageboxText>
            </CloseMessageButton>
          </MessageContainer>
        </Backdrop>
      )}

      {isMessageBoxVisibleClosing && (
        <Backdrop>
          <MessageContainer>
            <p>
              <MessageboxText>{message}</MessageboxText>
            </p>
            <CloseMessageButton onClick={handleCloseMessage}>
              <MessageboxText>Close</MessageboxText>
            </CloseMessageButton>
          </MessageContainer>
        </Backdrop>
      )}

      {error && (
        <Backdrop>
          <MessageContainer>
            <p>
              <MessageboxText>{error}</MessageboxText>
            </p>
            <CloseMessageButton
              onClick={() => {
                setIsMessageBoxVisible(false);
                setError(null);
              }}
            >
              <MessageboxText>Close</MessageboxText>
            </CloseMessageButton>
          </MessageContainer>
        </Backdrop>
      )}

      <FrameTopGradient100 />
      <OverlayComponentWhiteTitleAdd1>NUOVA PERSONA</OverlayComponentWhiteTitleAdd1>
      <OverlayComponentWhiteAdd1>
        <SubMenuButtonContainerOrganigrammaMultipleShort>
          <CreaSubMenuButtonMultiple onClick={() => changeActiveComponent("SchedaGenerale")} to="/Cybersecurity/Organizzazione/Persone/CreaL4/1">
            Scheda generale
          </CreaSubMenuButtonMultiple>
          {/* <CreaSubMenuButtonMultiple
            onClick={() => changeActiveComponent("Organizzazione")}
            to="/Cybersecurity/Organizzazione/Persone/CreaL4/2"
          >
            Organizzazione
          </CreaSubMenuButtonMultiple>
          <CreaSubMenuButtonMultiple
            onClick={() => changeActiveComponent("Infrastruttura")}
            to="/Cybersecurity/Organizzazione/Persone/CreaL4/3"
          >
            Infrastruttura
          </CreaSubMenuButtonMultiple>
          <CreaSubMenuButtonMultiple
            onClick={() => changeActiveComponent("ProcessiGenerali")}
            to="/Cybersecurity/Organizzazione/Persone/CreaL4/4"
          >
            Processi generali
          </CreaSubMenuButtonMultiple> */}
          {/* <CreaSubMenuButtonMultiple
            onClick={() => changeActiveComponent("PartiInteressate")}
            to="/Cybersecurity/Organizzazione/UnitaOperative/CreaL2/5"
          >
            Parti interessate
          </CreaSubMenuButtonMultiple> */}
          {/* <CreaSubMenuButtonMultiple
            onClick={() => changeActiveComponent("SistemaCompliance")}
            to="/Cybersecurity/Organizzazione/Persone/CreaL4/6"
          >
            Sistema gestione Compliance
          </CreaSubMenuButtonMultiple> */}
          <CreaSubMenuButtonMultiple onClick={() => changeActiveComponent("Documentazione")} to="/Cybersecurity/Organizzazione/Persone/CreaL4/7">
            Documentazione
          </CreaSubMenuButtonMultiple>
        </SubMenuButtonContainerOrganigrammaMultipleShort>
        <Suspense fallback={<div>Loading...</div>}>{renderActiveComponent()}</Suspense>
      </OverlayComponentWhiteAdd1>

      <OverlayComponentWhiteButtonFrameAdd1>
        <ButtonGrey onClick={handleClose}>
          <ButtonText>Annulla</ButtonText>
        </ButtonGrey>
        <ButtonGrey onClick={handleSaveAndUpload}>
          <ButtonText>Crea</ButtonText>
        </ButtonGrey>
      </OverlayComponentWhiteButtonFrameAdd1>
    </OverlayComponentBackgroundAdd1>
  );
};

export default MainComponent;
