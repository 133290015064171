import { useAuth } from "oidc-react";
import React, { useState } from "react";
import { organizationIp } from "../API";
import { ButtonGrey, ScrollableDiv } from "./components/styled-components";

const ApiCheck = () => {
  const [apiEndpoint, setApiEndpoint] = useState(organizationIp);
  const [apiResponse, setApiResponse] = useState(null);
  const [error, setError] = useState<string | null>(null);

  const fetchApiData = async () => {
    setApiResponse(null);
    setError(null);

    const auth = useAuth();
    const token = auth.isLoading || !auth.userData ? null : auth.userData.access_token;

    try {
      const response = await fetch(apiEndpoint, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      if (!response.ok) {
        throw new Error(`API request failed: ${response.statusText}`);
      }

      const data = await response.json();
      setApiResponse(data);
    } catch (err) {
      if (err instanceof Error) {
        setError(err.message);
      } else {
        setError("An unknown error occurred");
      }
    }
  };

  return (
    <ScrollableDiv>
      <div>
        <input type="text" value={apiEndpoint} onChange={(e) => setApiEndpoint(e.target.value)} placeholder="Enter API endpoint" style={{ width: "100%", padding: "10px", fontSize: "18px" }} />
        <div style={{ marginBottom: "1rem" }}></div>
        <ButtonGrey onClick={fetchApiData}>Fetch API Data</ButtonGrey>
        {error && <p>Error: {error}</p>}
        {apiResponse && <pre>{JSON.stringify(apiResponse, null, 2)}</pre>}
      </div>
    </ScrollableDiv>
  );
};

export default ApiCheck;
