import { useAuth } from "oidc-react";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { Outlet, useLocation, useNavigate } from "react-router-dom";
import styled from "styled-components";
import { OverlayContext } from "../cybersecurity-board-contestoleadership-organizzazione";
import {
  Backdrop,
  BackdropTransparent,
  BackgroundIcon,
  CloseMessageButton,
  FadeOutComponent,
  FrameTopGradient100,
  IconButton,
  IconsGroup,
  MessageContainer,
  MessageboxText,
} from "./cybersecurity-board-organizzazione-organigramma-styled-components";
import {
  Search,
  SearchIcon,
  SearchInputContainer,
  StyledRuoliGeneraliTd,
  StyledRuoligeneraliTh,
  StyledTable,
  StyledTableItem,
  StyledTbody,
  StyledTd,
  StyledTh,
  StyledThead,
  TableContainer,
  TableItemText,
  TableScrollableDiv,
  TableTitleText,
  TopContainer,
} from "./cybersecurity-board-organizzazione-unitaoperative-styled-components";

import { useRecoilState } from "recoil";

import { URL_RoleModels } from "../../../../API";
import { SelectedL1ID, SelectedL2ID, SelectedL3ID, SelectedL4ID } from "../../../../recoil";
import { showL3initialPath, showL3isAnimating, showL3isVisible } from "./L3-Show-recoil ";

interface DataItem {
  id: string;
  organization_model_name: string;
  role_description: string;
  role_long_description: string;
}

type SortableKeys = keyof DataItem;

type ApiContentItem = {
  id: string;
  name: string;
  responsible_name: string;
  responsible_id: string;
  site_name: string;
  site_id: string;
  email: string;
  phone: string;
};

type StyledTrProps = {
  selected: boolean;
};

const StyledTr = styled.tr<StyledTrProps>`
  cursor: pointer;
  background-color: ${(props) => {
    return props.selected ? "#f3f7fb" : "#ffffff";
  }};

  &:hover {
    background-color: #e9eff7;
  }
`;

interface OverlayComponentProps {
  isAnimating: boolean;
}

export function useOverlayControlL3Show(initialPath: string) {
  const [isVisibleL3, setIsVisibleL3] = useRecoilState(showL3isVisible);
  const [isAnimatingL3, setIsAnimatingL3] = useRecoilState(showL3isAnimating);
  const [originalPath, setOriginalPath] = useRecoilState(showL3initialPath);
  const navigate = useNavigate();

  const openOverlayL3 = useCallback(() => {
    navigate(initialPath);
    setIsVisibleL3(true);
    setTimeout(() => setIsAnimatingL3(true), 10);
  }, [navigate, initialPath]);

  const closeOverlayL3 = useCallback(() => {
    setIsAnimatingL3(false);
    setTimeout(() => {
      setIsVisibleL3(false);
      navigate(originalPath);
    }, 300);
  }, [navigate]);

  const toggleOverlayL3 = useCallback(() => {
    if (!isVisibleL3) {
      openOverlayL3();
    } else {
      closeOverlayL3();
    }
  }, [isVisibleL3, openOverlayL3, closeOverlayL3]);

  return {
    isVisibleL3,
    isAnimatingL3,
    toggleOverlayL3,
    openOverlayL3,
    closeOverlayL3,
  };
}

const OverlayComponentAdd1 = styled.div<OverlayComponentProps>`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  z-index: 10;
  transition: opacity 300ms ease-in-out, visibility 300ms ease-in-out;
  opacity: ${(props) => (props.isAnimating ? "1" : "0")};
  visibility: ${(props) => (props.isAnimating ? "visible" : "hidden")};
`;

const DataTable: React.FC<{ data: DataItem[] }> = ({ data }) => {
  const [isOverlayVisibleAdd2, setIsOverlayVisibleAdd2] = useState(false);
  const [isOverlayAnimatingAdd2, setIsOverlayAnimatingAdd2] = useState(false);

  const navigate = useNavigate();

  const toggleOverlayAdd2 = () => {
    if (!isOverlayVisibleAdd2) {
      if (window.location.pathname === "/Cybersecurity/Ruoli") {
        navigate(`Ruoli/CreaL3/1`);
      } else {
        navigate(`${window.location.pathname}/CreaL3/1`);
      }

      setIsOverlayVisibleAdd2(true);
      setTimeout(() => setIsOverlayAnimatingAdd2(true), 10);
    } else {
      setIsOverlayAnimatingAdd2(false);
      setTimeout(() => setIsOverlayVisibleAdd2(false), 310);
    }
  };

  const closeOverlayAdd2 = () => {
    navigate(`/Cybersecurity/Organizzazione/Ruoli`);
    setIsOverlayAnimatingAdd2(false);

    setTimeout(() => {
      setIsOverlayVisibleAdd2(false);
    }, 300);
  };

  const [selectedL1, setSelectedL1] = useState<string | null>(null);
  const [selectedL2, setSelectedL2] = useState<string | null>(null);
  const [selectedL3, setSelectedL3] = useState<string | null>(null);
  const [selectedL4, setSelectedL4] = useState<string | null>(null);

  const [L1ID, setL1ID] = useRecoilState(SelectedL1ID);
  const [L2ID, setL2ID] = useRecoilState(SelectedL2ID);
  const [L3ID, setL3ID] = useRecoilState(SelectedL3ID);
  const [L4ID, setL4ID] = useRecoilState(SelectedL4ID);

  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const [isMessageBoxVisible, setIsMessageBoxVisible] = useState(false);

  const [sortConfig, setSortConfig] = useState<{
    key: SortableKeys;
    direction: "ascending" | "descending";
  } | null>(null);

  const sortedData = useMemo(() => {
    let sortableItems = [...data];
    if (sortConfig !== null) {
      sortableItems.sort((a, b) => {
        const aValue = a[sortConfig.key];
        const bValue = b[sortConfig.key];

        if (typeof aValue === "string" && typeof bValue === "string") {
          return sortConfig.direction === "ascending" ? aValue.localeCompare(bValue) : bValue.localeCompare(aValue);
        }

        return 0;
      });
    }
    return sortableItems;
  }, [data, sortConfig]);

  const requestSort = (key: SortableKeys) => {
    let direction: "ascending" | "descending" = "ascending";
    if (sortConfig && sortConfig.key === key && sortConfig.direction === "ascending") {
      direction = "descending";
    }
    setSortConfig({ key, direction });
  };

  const [selectedId, setSelectedId] = useState<string | null>(null);

  const handleRowSelect = (id: string) => {
    setSelectedId((prevId) => {
      return id;
    });

    setL3ID(id);
  };

  useEffect(() => {
    console.log("L3ID now has the value:", L3ID);
  }, [L3ID]);

  const [globalFilterText, setGlobalFilterText] = useState("");

  const handleGlobalFilterChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setGlobalFilterText(event.target.value);
  };

  const [filterText, setFilterText] = useState("");

  const handleFilterChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setFilterText(event.target.value);
  };

  const filteredAndSortedData = useMemo(() => {
    const filteredData = data
      .filter((item) => {
        return item.organization_model_name.toLowerCase().includes(filterText.toLowerCase());
      })
      .filter((item) => {
        if (globalFilterText) {
          const allText = Object.values(item).join(" ").toLowerCase();
          return allText.includes(globalFilterText.toLowerCase());
        }
        return true;
      });

    if (sortConfig !== null) {
      filteredData.sort((a, b) => {
        const aValue = a[sortConfig.key];
        const bValue = b[sortConfig.key];

        if (typeof aValue === "string" && typeof bValue === "string") {
          return sortConfig.direction === "ascending" ? aValue.localeCompare(bValue) : bValue.localeCompare(aValue);
        }
        return 0;
      });
    }

    return filteredData;
  }, [data, sortConfig, filterText, globalFilterText]);

  const [isVisibleShow, setIsVisibleShow] = useRecoilState(showL3isVisible);
  const [isAnimatingShow, setIsAnimatingShow] = useRecoilState(showL3isAnimating);
  const { toggleOverlayL3, isVisibleL3, isAnimatingL3, closeOverlayL3 } = useOverlayControlL3Show("/Cybersecurity/Organizzazione/Ruoli/CreaL3/1");
  const [originalPath, setOriginalPath] = useRecoilState(showL3initialPath);
  const location = useLocation();

  const handleShowL3 = () => {
    setOriginalPath(location.pathname);
    toggleOverlayL3();
  };

  return (
    <TableContainer>
      <TopContainer>
        <SearchInputContainer>
          <Search placeholder="Filtra per modello organizzativo " onChange={handleFilterChange} />
          <SearchIcon src="/funnel.svg" />
        </SearchInputContainer>
        <SearchInputContainer>
          <Search placeholder="Cerca in tutta la tabella" onChange={handleGlobalFilterChange} />
          <SearchIcon src="/search.svg" />
        </SearchInputContainer>

        <IconsGroup>
          <IconButton onClick={handleShowL3}>
            <BackgroundIcon loading="eager" alt="" src="/doc.svg" />
          </IconButton>
          {/* <IconButton>
            <BackgroundIcon loading="eager" alt="" src="/edit.svg" />
          </IconButton> */}
          <IconButton>
            <BackgroundIcon loading="eager" alt="" src="/delete.svg" />
          </IconButton>
          <IconButton>
            <BackgroundIcon loading="eager" alt="" src="/add.svg" onClick={handleShowL3} />
          </IconButton>
        </IconsGroup>
      </TopContainer>

      <StyledTable>
        <StyledThead>
          <StyledRuoligeneraliTh onClick={() => requestSort("organization_model_name")}>
            <TableTitleText>▼ Modello organizzativo</TableTitleText>
          </StyledRuoligeneraliTh>
          <StyledRuoligeneraliTh onClick={() => requestSort("role_description")}>
            <TableTitleText>▼ Ruolo</TableTitleText>
          </StyledRuoligeneraliTh>
          <StyledTh onClick={() => requestSort("role_long_description")}>
            <TableTitleText>▼ Descrizione responsabilità</TableTitleText>
          </StyledTh>
        </StyledThead>
      </StyledTable>
      <TableScrollableDiv>
        <StyledTableItem>
          <StyledTbody>
            {filteredAndSortedData.map((item) => (
              <StyledTr key={item.id} onClick={() => handleRowSelect(item.id)} selected={selectedId === item.id}>
                <StyledRuoliGeneraliTd title={item.organization_model_name}>
                  <TableItemText>{item.organization_model_name}</TableItemText>
                </StyledRuoliGeneraliTd>
                <StyledRuoliGeneraliTd title={item.role_description}>
                  <TableItemText>{item.role_description}</TableItemText>
                </StyledRuoliGeneraliTd>
                <StyledTd>
                  <TableItemText title={item.role_long_description}>{item.role_long_description}</TableItemText>
                </StyledTd>
              </StyledTr>
            ))}
          </StyledTbody>
        </StyledTableItem>
      </TableScrollableDiv>

      <OverlayContext.Provider
        value={{
          closeOverlay: closeOverlayAdd2,
          selectedL1,
          setSelectedL1,
          selectedL2,
          setSelectedL2,
          selectedL3,
          setSelectedL3,
          selectedL4,
          setSelectedL4,
        }}
      >
        {isVisibleShow && (
          <OverlayComponentAdd1 isAnimating={isAnimatingShow}>
            {/* <ButtonClose src="/close.svg" onClick={closeOverlayAdd2} /> */}
            <FadeOutComponent>
              <FrameTopGradient100></FrameTopGradient100>
            </FadeOutComponent>

            <Outlet />
          </OverlayComponentAdd1>
        )}
      </OverlayContext.Provider>
    </TableContainer>
  );
};

const Board27001Dashboard: React.FC = () => {
  const [L1ID, setL1ID] = useRecoilState(SelectedL1ID);
  const [L2ID, setL2ID] = useRecoilState(SelectedL2ID);
  const [L3ID, setL3ID] = useRecoilState(SelectedL3ID);
  const [L4ID, setL4ID] = useRecoilState(SelectedL4ID);

  const [data, setData] = useState<DataItem[]>([]);

  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const [isMessageBoxVisible, setIsMessageBoxVisible] = useState(false);

  const auth = useAuth();
  const token = auth.isLoading || !auth.userData ? null : auth.userData.access_token;

  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true);
      try {
        if (!token) throw new Error("User is not authenticated");

        const response = await fetch(URL_RoleModels, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });

        if (!response.ok) {
          throw new Error(`API request failed: ${response.statusText}`);
        }

        const responseData = await response.json();
        console.log("Response Data:", responseData);

        if (Array.isArray(responseData)) {
          const transformedData = responseData.map((item) => ({
            id: item.id,
            organization_model_name: item.organization_model_name,
            role_description: item.role_description,
            role_long_description: item.role_long_description,
          }));

          console.log("Transformed Data:", transformedData);
          setData(transformedData);
        } else {
          console.error("responseData is not an array:", responseData);
        }
      } catch (error) {
        setError(error instanceof Error ? error.message : "An unknown error occurred");
        setIsMessageBoxVisible(true);
      } finally {
        setIsLoading(false);
      }
    };

    fetchData();
  }, [token]);

  return (
    <div>
      {isMessageBoxVisible && (
        <Backdrop>
          <MessageContainer>
            <p>
              <MessageboxText>{error}</MessageboxText>
            </p>
            <CloseMessageButton onClick={() => setIsMessageBoxVisible(false)}>
              <MessageboxText>Close</MessageboxText>
            </CloseMessageButton>
          </MessageContainer>
        </Backdrop>
      )}

      {isLoading && (
        <BackdropTransparent>
          <MessageContainer>
            <MessageboxText>Loading...</MessageboxText>
          </MessageContainer>
        </BackdropTransparent>
      )}

      {error && (
        <Backdrop>
          <MessageContainer>
            <p>
              <MessageboxText>{error}</MessageboxText>
            </p>
            <CloseMessageButton
              onClick={() => {
                setIsMessageBoxVisible(false);
                setError(null);
              }}
            >
              <MessageboxText>Close</MessageboxText>
            </CloseMessageButton>
          </MessageContainer>
        </Backdrop>
      )}

      <DataTable data={data} />
    </div>
  );
};

export default Board27001Dashboard;
