import dayjs from "dayjs";
import React, { useCallback, useEffect, useRef, useState } from "react";
import { Outlet, useLocation, useNavigate } from "react-router-dom";
import { useRecoilState } from "recoil";
import styled from "styled-components";
import { MainBoardContentContainerOrganizzazione } from "../../styled-components";
import { OverlayContext } from "../cybersecurity-board-contestoleadership-organizzazione";

import {
  SelectedL1ID,
  SelectedL2ID,
  SelectedL3ID,
  SelectedL4ID,
  addL1AttachedFilesInfo,
  addL1ComplianceDate,
  addL1ComplianceDirettoreCompliance,
  addL1ComplianceDirettoreComplianceID,
  addL1DocumentsAttached,
  addL1DocumentsPath,
  addL1OrganizzazioneDirettore,
  addL1OrganizzazioneDirettoreID,
  addL1SchedaGeneraleFax,
  addL1SchedaGeneraleIndirizzo,
  addL1SchedaGeneraleNomeAbbreviato,
  addL1SchedaGeneraleNomeCompleto,
  addL1SchedaGeneralePEC,
  addL1SchedaGeneraleTelefono,
  reFetchIndicatorL1,
  selectedItemsAsset,
} from "../../../../recoil";

import {
  Backdrop,
  BackdropTransparent,
  BackgroundIcon,
  CloseMessageButton,
  FadeOutComponent,
  FileButtonArrowGrey,
  FileButtonWhiteText,
  FrameTopGradient100,
  IconButton,
  IconsGroup,
  LineDivider,
  MainBoardContentContainerColumn,
  MainBoardContentContainerFile,
  MessageContainer,
  MessageboxText,
  Search,
  SearchBarWithIconsRoot,
  Title,
  TitleText,
} from "./cybersecurity-board-organizzazione-organigramma-styled-components";

import { URL_LV1List, URL_LV1_Detailed, URL_LV2ListByLV1, useApi } from "../../../../API";
import {
  BoardScrollableDivOrganigramma,
  MainBoardContentContainerFileOgranigramma,
} from "../cybersecurity-board-contestoleadership-infrastruttura-sub/cybersecurity-board-infrastruttura-organigramma-styled-components";
import { useOverlayControlL4Show } from "./cybersecurity-board-organizzazione-persone";
import { useOverlayControlL3Show } from "./cybersecurity-board-organizzazione-ruoligenerali";
import { useOverlayControlL2Show } from "./cybersecurity-board-organizzazione-unitaoperative";
import { L1DeleteConfirmationDialog } from "./L1-Add-Recoil";
import { showL2initialPath } from "./L2-Add-recoil";
import { showL3initialPath } from "./L3-Show-recoil ";
import { showL4initialPath } from "./L4-Show-recoil";

const FileButtonWhite = styled.button<{ isActive: boolean }>`
  cursor: pointer;
  border: none;
  padding: 0;
  background-color: ${(props) => (props.isActive ? "#f3f7fb" : "var(--color-white)")};
  /* box-shadow: ${(props) => (props.isActive ? "0px 2px 3px rgba(0, 0, 0, 0.25)" : "0px 0px 0px rgba(0, 0, 0, 0.25)")}; */
  position: relative;
  border-bottom: 1px solid #f1f1f1;
  box-sizing: border-box;
  width: 100%;
  height: 50px;
  overflow: hidden;
  flex-shrink: 0;
  &:hover {
    background-color: #e9eff7;
  }
`;

interface OverlayComponentProps {
  isAnimating: boolean;
}

const OverlayComponentAdd1 = styled.div<OverlayComponentProps>`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  z-index: 10;
  transition: opacity 300ms ease-in-out, visibility 300ms ease-in-out;
  opacity: ${(props) => (props.isAnimating ? "1" : "0")};
  visibility: ${(props) => (props.isAnimating ? "visible" : "hidden")};
`;

interface SearchBarProps {
  searchTerm: string;
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  onAddClick: () => void;
  onDeleteClick: () => void;
  onEditClick: () => void;
  onShowClick: () => void;
}

interface SearchBarSmallProps {
  searchTerm: string;
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;

  onShowClick: () => void;
}

const SearchBar = React.forwardRef<HTMLInputElement, SearchBarProps>(({ searchTerm, onChange, onAddClick, onDeleteClick, onEditClick, onShowClick }, ref) => {
  return (
    <SearchBarWithIconsRoot>
      <Search placeholder="Cerca" value={searchTerm} onChange={onChange} ref={ref} />
      <LineDivider />
      <IconsGroup>
        <IconButton>
          <BackgroundIcon loading="eager" alt="" src="/doc.svg" onClick={onShowClick} />
        </IconButton>
        {/* <IconButton>
          <BackgroundIcon loading="eager" alt="" src="/edit.svg" onClick={onEditClick} />
        </IconButton> */}
        <IconButton>
          <BackgroundIcon loading="eager" alt="" src="/delete.svg" onClick={onDeleteClick} />
        </IconButton>
        <IconButton>
          <BackgroundIcon loading="eager" alt="" src="/add.svg" onClick={onAddClick} />
        </IconButton>
      </IconsGroup>
      {/* Icons and other elements */}
    </SearchBarWithIconsRoot>
  );
});

const SearchBarSmall = React.forwardRef<HTMLInputElement, SearchBarSmallProps>(({ searchTerm, onChange, onShowClick }, ref) => {
  return (
    <SearchBarWithIconsRoot>
      <Search placeholder="Cerca" value={searchTerm} onChange={onChange} ref={ref} />
      <LineDivider />
      <IconsGroup>
        <IconButton>
          <BackgroundIcon loading="eager" alt="" src="/doc.svg" onClick={onShowClick} />
        </IconButton>
      </IconsGroup>
      {/* Icons and other elements */}
    </SearchBarWithIconsRoot>
  );
});

const SearchBarZero = React.forwardRef<HTMLInputElement, SearchBarSmallProps>(({ searchTerm, onChange, onShowClick }, ref) => {
  return (
    <SearchBarWithIconsRoot>
      <Search placeholder="Cerca" value={searchTerm} onChange={onChange} ref={ref} />
      {/* <LineDivider />
      <IconsGroup>
        <IconButton>
          <BackgroundIcon loading="eager" alt="" src="/doc.svg" onClick={onShowClick} />
        </IconButton>
      </IconsGroup> */}
      {/* Icons and other elements */}
    </SearchBarWithIconsRoot>
  );
});

const useDeleteSite = () => {
  const { del } = useApi();
  const [reFetchIndicator, setRefetchIndicator] = useRecoilState(reFetchIndicatorL1);
  const [isConfirmDialogVisible, setIsConfirmDialogVisible] = useRecoilState(L1DeleteConfirmationDialog);
  const [L1ID, setL1ID] = useRecoilState(SelectedL1ID);

  const handleDelete = useCallback(async () => {
    if (!L1ID) return;

    const url = URL_LV1_Detailed;
    try {
      await del(url, L1ID);
      console.log(`Deleted site with ID: ${L1ID}`);
    } catch (error) {
      console.error("Failed to delete site:", error);
    } finally {
      setRefetchIndicator((prev) => prev + 1);
      setIsConfirmDialogVisible(false);
    }
  }, [L1ID, del, setRefetchIndicator]);

  const showDeleteConfirmation = useCallback(() => {
    setIsConfirmDialogVisible(true);
  }, []);

  return { handleDelete, showDeleteConfirmation, isConfirmDialogVisible, setIsConfirmDialogVisible };
};

interface ValueItem {
  id: string;
  alias: string;
}

interface NestedItem {
  value: ValueItem;
  leaves: NestedItem[];
}

type DataItem = {
  id: string;
  text: string;
};

const CybersecurityOrganigramma = () => {
  const [L1ID, setL1ID] = useRecoilState(SelectedL1ID);
  const [L2ID, setL2ID] = useRecoilState(SelectedL2ID);
  const [L3ID, setL3ID] = useRecoilState(SelectedL3ID);
  const [L4ID, setL4ID] = useRecoilState(SelectedL4ID);
  const [selectedAssets, setSelectedAssets] = useRecoilState(selectedItemsAsset);
  const [directorLV1, setDirectorLV1] = useRecoilState(addL1OrganizzazioneDirettore);
  const [directorIdLV1, setDirectorIdLV1] = useRecoilState(addL1OrganizzazioneDirettoreID);
  const [directorComplianceLV1, setDirectorComplianceLV1] = useRecoilState(addL1ComplianceDirettoreCompliance);
  const [directorComplianceIdLV1, setDirectorComplianceIdLV1] = useRecoilState(addL1ComplianceDirettoreComplianceID);
  const [complianceDateLV1, setComplianceDateLV1] = useRecoilState(addL1ComplianceDate);
  const [FaxLV1, setFaxLV1] = useRecoilState(addL1SchedaGeneraleFax);
  const [IndirizzoLV1, setIndirizzoLV1] = useRecoilState(addL1SchedaGeneraleIndirizzo);
  const [NomeAbbreviatoLV1, setNomeAbbreviatoLV1] = useRecoilState(addL1SchedaGeneraleNomeAbbreviato);
  const [NomeCompletoLV1, setNomeCompletoLV1] = useRecoilState(addL1SchedaGeneraleNomeCompleto);
  const [PECLV1, setPECLV1] = useRecoilState(addL1SchedaGeneralePEC);
  const [TelefonoLV1, setTelefonoLV1] = useRecoilState(addL1SchedaGeneraleTelefono);

  const [AttachedFileInfoLV1, setAttachedFileInfoLV1] = useRecoilState(addL1AttachedFilesInfo);
  const [DocumentsAttachedLV1, setDocumentsAttachedLV1] = useRecoilState(addL1DocumentsAttached);
  const [DocumentsPathLV1, setDocumentsPathLV1] = useRecoilState(addL1DocumentsPath);

  const [message, setMessage] = useState("");
  const [isMessageBoxVisible, setIsMessageBoxVisible] = useState(false);

  const location = useLocation();
  const [originalPathL4, setOriginalPathL4] = useRecoilState(showL4initialPath);
  const { toggleOverlayL4, isVisibleL4, isAnimatingL4, closeOverlayL4 } = useOverlayControlL4Show("/Cybersecurity/Organizzazione/Persone/VisualizzaL4/1");

  const handleShowL4 = () => {
    setOriginalPathL4(location.pathname);
    console.log("Location: ", location.pathname);
    toggleOverlayL4();
  };

  const [originalPathL3, setOriginalPathL3] = useRecoilState(showL3initialPath);
  const { toggleOverlayL3, isVisibleL3, isAnimatingL3, closeOverlayL3 } = useOverlayControlL3Show("/Cybersecurity/Organizzazione/Ruoli/CreaL3/1");

  const handleShowL3 = () => {
    setOriginalPathL3(location.pathname);
    toggleOverlayL3();
  };

  const [originalPathL2, setOriginalPathL2] = useRecoilState(showL2initialPath);
  const { toggleOverlayL2, isVisibleL2, isAnimatingL2, closeOverlayL2 } = useOverlayControlL2Show("/Cybersecurity/Organizzazione/UnitaOperative/VisualizzaL2/1");

  const handleShowL2 = () => {
    setOriginalPathL2(location.pathname);
    toggleOverlayL2();
  };

  const [reFetchIndicator, setRefetchIndicator] = useRecoilState(reFetchIndicatorL1);

  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);

  const { get } = useApi();

  type ApiContentItem = {
    id: string;
    alias: string;
  };

  const fetchDataLV1 = async () => {
    setIsLoading(true);
    try {
      const data = await get(URL_LV1List);
      if (data && data.content) {
        const transformedData: DataItem[] = data.content.map((item: ApiContentItem) => ({
          id: item.id,
          text: item.alias,
        }));
        setL1Data(transformedData);
        if (setL1Data.length > 0) {
          setTriggerL1Selection(true);
        }
        setSelectedL1(transformedData.length > 0 ? transformedData[0].id : null);
      }
    } catch (err) {
      if (err instanceof Error) {
        setError(err.message);
      } else {
        setError("An unknown error occurred");
      }
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchDataLV1();
  }, [reFetchIndicator]);

  const handleSearchChange = (level: "L1" | "L2" | "L3" | "L4") => (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    switch (level) {
      case "L1":
        setSearchTermL1(value);
        break;
      case "L2":
        setSearchTermL2(value);
        break;
      case "L3":
        setSearchTermL3(value);
        break;
      case "L4":
        setSearchTermL4(value);
        break;
    }
  };

  const [isOverlayVisibleAdd1, setIsOverlayVisibleAdd1] = useState(false);
  const [isOverlayAnimatingAdd1, setIsOverlayAnimatingAdd1] = useState(false);
  const [isOverlayVisibleEdit1, setIsOverlayVisibleEdit1] = useState(false);
  const [isOverlayAnimatingEdit1, setIsOverlayAnimatingEdit1] = useState(false);
  const [isOverlayVisibleShow1, setIsOverlayVisibleShow1] = useState(false);
  const [isOverlayAnimatingShow1, setIsOverlayAnimatingShow1] = useState(false);

  const [isOverlayVisibleAdd2, setIsOverlayVisibleAdd2] = useState(false);
  const [isOverlayAnimatingAdd2, setIsOverlayAnimatingAdd2] = useState(false);

  const [isOverlayVisibleAdd3, setIsOverlayVisibleAdd3] = useState(false);
  const [isOverlayAnimatingAdd3, setIsOverlayAnimatingAdd3] = useState(false);

  const [isOverlayVisibleAdd4, setIsOverlayVisibleAdd4] = useState(false);
  const [isOverlayAnimatingAdd4, setIsOverlayAnimatingAdd4] = useState(false);

  const navigate = useNavigate();

  const searchInputRefL1 = useRef<HTMLInputElement>(null);
  const searchInputRefL2 = useRef<HTMLInputElement>(null);
  const searchInputRefL3 = useRef<HTMLInputElement>(null);
  const searchInputRefL4 = useRef<HTMLInputElement>(null);

  const [searchTermL1, setSearchTermL1] = useState("");
  const [searchTermL2, setSearchTermL2] = useState("");
  const [searchTermL3, setSearchTermL3] = useState("");
  const [searchTermL4, setSearchTermL4] = useState("");

  const [l1Data, setL1Data] = useState<DataItem[]>([]);
  const [selectedL1, setSelectedL1] = useState<string | null>(null);
  const [l2Data, setL2Data] = useState<DataItem[]>([]);
  const [selectedL2, setSelectedL2] = useState<string | null>(null);
  const [l3Data, setL3Data] = useState<DataItem[]>([]);
  const [selectedL3, setSelectedL3] = useState<string | null>(null);
  const [l4Data, setL4Data] = useState<DataItem[]>([]);
  const [selectedL4, setSelectedL4] = useState<string | null>(null);

  const [nestedData, setNestedData] = useState<NestedItem[]>([]);

  useEffect(() => {
    setFilteredL1Data(filterData(l1Data, searchTermL1));
  }, [l1Data, searchTermL1]);

  useEffect(() => {
    const filteredL2Data = filterData(l2Data, searchTermL2);
    setFilteredL2Data(filteredL2Data);
  }, [l2Data, searchTermL2]);

  useEffect(() => {
    const filteredL3Data = filterData(l3Data, searchTermL3);
    setFilteredL3Data(filteredL3Data);
  }, [l3Data, searchTermL3]);

  useEffect(() => {
    const filteredL4Data = filterData(l4Data, searchTermL4);
    setFilteredL4Data(filteredL4Data);
  }, [l4Data, searchTermL4]);

  const [filteredL1Data, setFilteredL1Data] = useState<DataItem[]>([]);
  const [filteredL2Data, setFilteredL2Data] = useState<DataItem[]>([]);
  const [filteredL3Data, setFilteredL3Data] = useState<DataItem[]>([]);
  const [filteredL4Data, setFilteredL4Data] = useState<DataItem[]>([]);

  const filterData = (data: DataItem[], searchTerm: string) => {
    return data.filter((item) => item.text.toLowerCase().includes(searchTerm.toLowerCase()));
  };

  const [displayableData, setDisplayableData] = useState<{
    L1: DataItem[];
    L2: DataItem[];
    L3: DataItem[];
    L4: DataItem[];
  }>({
    L1: [],
    L2: [],
    L3: [],
    L4: [],
  });

  const sortDataItems = (data: DataItem[]) => {
    return data.sort((a: DataItem, b: DataItem) => a.text.localeCompare(b.text));
  };

  const [triggerL1Selection, setTriggerL1Selection] = useState(false);
  const [triggerL2Selection, setTriggerL2Selection] = useState(false);
  const [triggerL3Selection, setTriggerL3Selection] = useState(false);
  const [triggerL4Selection, setTriggerL4Selection] = useState(false);

  useEffect(() => {
    if (triggerL1Selection && l1Data.length > 0) {
      handleSelectL1(l1Data[0].id);
      setTriggerL1Selection(false);
    }
  }, [triggerL1Selection, l1Data]);
  useEffect(() => {
    if (triggerL2Selection && l2Data.length > 0) {
      handleSelectL2(l2Data[0].id);
      setTriggerL2Selection(false);
    }
  }, [triggerL3Selection, l3Data]);
  useEffect(() => {
    if (triggerL3Selection && l3Data.length > 0) {
      handleSelectL3(l3Data[0].id);
      setTriggerL3Selection(false);
    }
  }, [triggerL3Selection, l3Data]);

  useEffect(() => {
    if (triggerL4Selection && l4Data.length > 0) {
      handleSelectL4(l4Data[0].id);
      setTriggerL4Selection(false);
    }
  }, [triggerL4Selection, l4Data]);

  const handleSelectL1 = async (id: string) => {
    const selectedItem = l1Data.find((item) => item.id === id);
    if (selectedItem) {
      setL1ID(id);
      setSelectedL1(id);
      console.log("LV1ID: ", id);
      setSelectedL2(null);
      setSelectedL3(null);
      setSelectedL4(null);
      try {
        setIsLoading(true);
        const data = await get(URL_LV2ListByLV1 + "/" + id);

        setNestedData(data);
        const newL2Data = sortDataItems(
          data.map((item: NestedItem) => ({
            id: item.value.id,
            text: item.value.alias,
          }))
        );
        setL2Data(newL2Data);
        if (newL2Data.length > 0) {
          setTriggerL2Selection(true);
        }
        setFilteredL2Data(filterData(newL2Data, searchTermL2));

        setL3Data([]);
        setFilteredL3Data([]);
        setSelectedL3(null);

        setL4Data([]);
        setFilteredL4Data([]);
        setSelectedL4(null);
        setDisplayableData((prevData) => ({
          ...prevData,
          L2: newL2Data,
          L3: [],
          L4: [],
        }));
      } catch (error) {
        if (error instanceof Error) {
          setError(error.message);
        } else {
          setError("An unknown error occurred");
        }
      } finally {
        setIsLoading(false);
      }
    }
  };

  const handleSelectL2 = async (id: string) => {
    const selectedItem = nestedData.find((item) => item.value.id === id);
    if (selectedItem) {
      setL2ID(id);
      setSelectedL2(id);
      console.log("LV2ID: ", id);
      setSelectedL3(null);
      setSelectedL4(null);
      const newL3Data = sortDataItems(
        selectedItem.leaves.map((leaf) => ({
          id: leaf.value.id,
          text: leaf.value.alias,
        }))
      );
      setL3Data(newL3Data);
      if (newL3Data.length > 0) {
        setTriggerL3Selection(true);
      }
      setFilteredL3Data(filterData(newL3Data, searchTermL3));

      setL4Data([]);
      setFilteredL4Data([]);
      setSelectedL4(null);
    }
  };

  const handleSelectL3 = async (l3Id: string) => {
    if (selectedL2) {
      const selectedL2Item = nestedData.find((item) => item.value.id === selectedL2);

      const selectedL3Item = selectedL2Item?.leaves.find((leaf) => leaf.value.id === l3Id);

      if (selectedL3Item) {
        setL3ID(l3Id);
        setSelectedL3(l3Id);
        console.log("LV3ID: ", l3Id);
        setSelectedL4(null);

        const newL4Data = selectedL3Item.leaves.map((leaf) => ({
          id: leaf.value.id,
          text: leaf.value.alias,
        }));

        setL4Data(sortDataItems(newL4Data));
        if (newL4Data.length > 0) {
          setTriggerL4Selection(true);
        }

        setFilteredL4Data(sortDataItems(newL4Data));
      }
    } else {
      console.error("No L2 item selected. Cannot select L3.");
    }
  };

  const handleSelectL4 = async (id: string) => {
    setL4ID(id);
    setSelectedL4(id);
    console.log("LV4ID: ", id);
  };

  const toggleOverlayAdd1 = () => {
    if (!isOverlayVisibleAdd1) {
      if (window.location.pathname === "/Cybersecurity/Organizzazione") {
        navigate(`Organigramma/CreaL1/1`);
      } else {
        navigate(`${window.location.pathname}/CreaL1/1`);
      }

      setIsOverlayVisibleAdd1(true);
      setTimeout(() => setIsOverlayAnimatingAdd1(true), 10);
    } else {
      setIsOverlayAnimatingAdd1(false);
      setTimeout(() => setIsOverlayVisibleAdd1(false), 310);
    }
  };

  const closeOverlayAdd1 = () => {
    setDirectorComplianceIdLV1("");
    setDirectorComplianceLV1("");
    setDirectorIdLV1("");
    setDirectorLV1("");
    setComplianceDateLV1(dayjs());
    setFaxLV1("");
    setPECLV1("");
    setTelefonoLV1("");
    setIndirizzoLV1("");
    setNomeCompletoLV1("");
    setNomeAbbreviatoLV1("");
    setDocumentsAttachedLV1([]);
    setAttachedFileInfoLV1([]);
    setDocumentsPathLV1("");

    navigate(`/Cybersecurity/Organizzazione/Organigramma`);
    setIsOverlayAnimatingAdd1(false);

    setTimeout(() => {
      setIsOverlayVisibleAdd1(false);
      fetchDataLV1();
    }, 300);
  };

  const toggleOverlayEdit1 = () => {
    if (!isOverlayVisibleEdit1) {
      if (window.location.pathname === "/Cybersecurity/Organizzazione") {
        navigate(`Organigramma/ModificaL1`);
      } else {
        navigate(`${window.location.pathname}/ModificaL1`);
      }

      setIsOverlayVisibleEdit1(true);
      setTimeout(() => setIsOverlayAnimatingEdit1(true), 10);
    } else {
      setIsOverlayAnimatingEdit1(false);
      setTimeout(() => setIsOverlayVisibleEdit1(false), 310);
    }
  };

  const closeOverlayEdit1 = () => {
    navigate(`/Cybersecurity/Organizzazione/Organigramma`);
    setIsOverlayAnimatingEdit1(false);

    setTimeout(() => {
      setIsOverlayVisibleEdit1(false);
      fetchDataLV1();
    }, 300);
  };

  const toggleOverlayShow1 = () => {
    if (!isOverlayVisibleShow1) {
      if (window.location.pathname === "/Cybersecurity/Organizzazione") {
        navigate(`Organigramma/VisualizzaL1/1`);
      } else {
        navigate(`${window.location.pathname}/VisualizzaL1/1`);
      }

      setIsOverlayVisibleShow1(true);
      setTimeout(() => setIsOverlayAnimatingShow1(true), 10);
    } else {
      setIsOverlayAnimatingShow1(false);
      setTimeout(() => setIsOverlayVisibleShow1(false), 310);
    }
  };

  const closeOverlayShow1 = () => {
    navigate(`/Cybersecurity/Organizzazione/Organigramma`);
    setIsOverlayAnimatingShow1(false);

    setTimeout(() => {
      setIsOverlayVisibleShow1(false);
      fetchDataLV1();
    }, 300);
  };

  const toggleOverlayAdd2 = () => {
    if (!isOverlayVisibleAdd2) {
      if (window.location.pathname === "/Cybersecurity/Organizzazione") {
        navigate(`Organigramma/CreaL2/1`);
      } else {
        navigate(`${window.location.pathname}/CreaL2/1`);
      }

      setIsOverlayVisibleAdd2(true);
      setTimeout(() => setIsOverlayAnimatingAdd2(true), 10);
    } else {
      setIsOverlayAnimatingAdd2(false);
      setTimeout(() => setIsOverlayVisibleAdd2(false), 310);
    }
  };

  const closeOverlayAdd2 = () => {
    navigate(`/Cybersecurity/Organizzazione/Organigramma`);
    setIsOverlayAnimatingAdd2(false);

    setTimeout(() => {
      setIsOverlayVisibleAdd2(false);
    }, 300);
  };

  const toggleOverlayAdd3 = () => {
    if (!isOverlayVisibleAdd3) {
      if (window.location.pathname === "/Cybersecurity/Organizzazione") {
        navigate(`Organigramma/Crea3`);
      } else {
        navigate(`${window.location.pathname}/Crea3`);
      }

      setIsOverlayVisibleAdd3(true);
      setTimeout(() => setIsOverlayAnimatingAdd3(true), 10);
    } else {
      setIsOverlayAnimatingAdd3(false);
      setTimeout(() => setIsOverlayVisibleAdd3(false), 310);
    }
  };

  const closeOverlayAdd3 = () => {
    navigate(`/Cybersecurity/Organizzazione/Organigramma`);
    setIsOverlayAnimatingAdd3(false);

    setTimeout(() => {
      setIsOverlayVisibleAdd3(false);
    }, 300);
  };

  const toggleOverlayAdd4 = () => {
    if (!isOverlayVisibleAdd4) {
      if (window.location.pathname === "/Cybersecurity/Organizzazione") {
        navigate(`Organigramma/Crea4`);
      } else {
        navigate(`${window.location.pathname}/Crea4`);
      }

      setIsOverlayVisibleAdd4(true);
      setTimeout(() => setIsOverlayAnimatingAdd4(true), 10);
    } else {
      setIsOverlayAnimatingAdd4(false);
      setTimeout(() => setIsOverlayVisibleAdd4(false), 310);
    }
  };

  const closeOverlayAdd4 = () => {
    navigate(`/Cybersecurity/Organizzazione/Organigramma`);
    setIsOverlayAnimatingAdd4(false);

    setTimeout(() => {
      setIsOverlayVisibleAdd4(false);
    }, 300);
  };

  const toggleOverlayShow4 = () => {
    if (!isOverlayVisibleShow1) {
      if (window.location.pathname === "/Cybersecurity/Organizzazione/Organigramma") {
        navigate(`Persone/VisualizzaL4/1`);
      } else {
        navigate(`${window.location.pathname}/Persone/VisualizzaL4/1`);
      }

      setIsOverlayVisibleShow1(true);

      setTimeout(() => setIsOverlayAnimatingShow1(true), 10);
    } else {
      setIsOverlayAnimatingShow1(false);
      setTimeout(() => setIsOverlayVisibleShow1(false), 310);
    }
  };

  const { handleDelete, showDeleteConfirmation, isConfirmDialogVisible, setIsConfirmDialogVisible } = useDeleteSite();

  return (
    <MainBoardContentContainerOrganizzazione>
      <MainBoardContentContainerColumn>
        <MainBoardContentContainerFileOgranigramma>
          <Title>
            <TitleText>L1: COMPONENTI ORGANIZZATIVE</TitleText>
          </Title>
          <SearchBar
            searchTerm={searchTermL1}
            onChange={handleSearchChange("L1")}
            onAddClick={toggleOverlayAdd1}
            onEditClick={toggleOverlayEdit1}
            onDeleteClick={showDeleteConfirmation}
            onShowClick={toggleOverlayShow1}
            ref={searchInputRefL1}
          />

          <BoardScrollableDivOrganigramma>
            {/* Map through L1 data (static for now, could be fetched from an API) */}
            {/* TODO: Replace with actual L1 data */}
            {filteredL1Data.map((item) => (
              <FileButtonWhite key={item.id} isActive={selectedL1 === item.id} onClick={() => handleSelectL1(item.id)}>
                <FileButtonWhiteText>{item.text}</FileButtonWhiteText>
                <FileButtonArrowGrey src="/arrow-line-right-grey.svg" alt="Arrow Icon" />
              </FileButtonWhite>
            ))}
          </BoardScrollableDivOrganigramma>
        </MainBoardContentContainerFileOgranigramma>
      </MainBoardContentContainerColumn>

      {/* Repeat the structure for L2, L3, and L4, mapping through l2Data, l3Data, and l4Data respectively */}
      {/* and using handleSelectL2, handleSelectL3 for onClick handlers */}

      {/* L2 Column */}
      <MainBoardContentContainerColumn>
        <MainBoardContentContainerFileOgranigramma>
          <Title>
            <TitleText>L2: UNITA' OPERATIVE</TitleText>
          </Title>
          <SearchBarSmall searchTerm={searchTermL2} onChange={handleSearchChange("L2")} onShowClick={handleShowL2} ref={searchInputRefL2} />

          <BoardScrollableDivOrganigramma>
            {/* Map through L1 data (static for now, could be fetched from an API) */}
            {/* TODO: Replace with actual L1 data */}
            {filteredL2Data.map((item) => (
              <FileButtonWhite key={item.id} isActive={selectedL2 === item.id} onClick={() => handleSelectL2(item.id)}>
                <FileButtonWhiteText>{item.text}</FileButtonWhiteText>
                <FileButtonArrowGrey src="/arrow-line-right-grey.svg" alt="Arrow Icon" />
              </FileButtonWhite>
            ))}
          </BoardScrollableDivOrganigramma>
        </MainBoardContentContainerFileOgranigramma>
      </MainBoardContentContainerColumn>
      {/* L3 Column */}
      <MainBoardContentContainerColumn>
        <MainBoardContentContainerFile>
          <Title>
            <TitleText>L3: RUOLI</TitleText>
          </Title>
          <SearchBarSmall searchTerm={searchTermL3} onChange={handleSearchChange("L3")} onShowClick={handleShowL3} ref={searchInputRefL3} />

          <BoardScrollableDivOrganigramma>
            {/* Map through L1 data (static for now, could be fetched from an API) */}
            {/* TODO: Replace with actual L1 data */}
            {filteredL3Data.map((item) => (
              <FileButtonWhite key={item.id} isActive={selectedL3 === item.id} onClick={() => handleSelectL3(item.id)}>
                <FileButtonWhiteText>{item.text}</FileButtonWhiteText>
                <FileButtonArrowGrey src="/arrow-line-right-grey.svg" alt="Arrow Icon" />
              </FileButtonWhite>
            ))}
          </BoardScrollableDivOrganigramma>
        </MainBoardContentContainerFile>
      </MainBoardContentContainerColumn>
      {/* L4 Column */}
      <MainBoardContentContainerColumn>
        <MainBoardContentContainerFile>
          <Title>
            <TitleText>L4: PERSONALE</TitleText>
          </Title>
          <SearchBarSmall searchTerm={searchTermL4} onChange={handleSearchChange("L4")} onShowClick={handleShowL4} ref={searchInputRefL4} />

          <BoardScrollableDivOrganigramma>
            {/* Map through L1 data (static for now, could be fetched from an API) */}
            {/* TODO: Replace with actual L1 data */}
            {filteredL4Data.map((item) => (
              <FileButtonWhite key={item.id} isActive={selectedL4 === item.id} onClick={() => handleSelectL4(item.id)}>
                <FileButtonWhiteText>{item.text}</FileButtonWhiteText>
                <FileButtonArrowGrey src="/arrow-line-right-grey.svg" alt="Arrow Icon" />
              </FileButtonWhite>
            ))}
          </BoardScrollableDivOrganigramma>
        </MainBoardContentContainerFile>
      </MainBoardContentContainerColumn>

      <OverlayContext.Provider
        value={{
          closeOverlay: closeOverlayAdd1,
          selectedL1,
          setSelectedL1,
          selectedL2,
          setSelectedL2,
          selectedL3,
          setSelectedL3,
          selectedL4,
          setSelectedL4,
        }}
      >
        {isOverlayVisibleAdd1 && (
          <OverlayComponentAdd1 isAnimating={isOverlayAnimatingAdd1}>
            {/* <ButtonClose src="/close.svg" onClick={closeOverlayAdd1} /> */}
            <FadeOutComponent>
              <FrameTopGradient100></FrameTopGradient100>
            </FadeOutComponent>
            <Outlet />
          </OverlayComponentAdd1>
        )}
      </OverlayContext.Provider>

      <OverlayContext.Provider
        value={{
          closeOverlay: closeOverlayEdit1,
          selectedL1,
          setSelectedL1,
          selectedL2,
          setSelectedL2,
          selectedL3,
          setSelectedL3,
          selectedL4,
          setSelectedL4,
        }}
      >
        {isOverlayVisibleEdit1 && (
          <OverlayComponentAdd1 isAnimating={isOverlayAnimatingEdit1}>
            {/* <ButtonClose src="/close.svg" onClick={closeOverlayEdit1} /> */}
            <FadeOutComponent>
              <FrameTopGradient100></FrameTopGradient100>
            </FadeOutComponent>

            <Outlet />
          </OverlayComponentAdd1>
        )}
      </OverlayContext.Provider>

      <OverlayContext.Provider
        value={{
          closeOverlay: closeOverlayShow1,
          selectedL1,
          setSelectedL1,
          selectedL2,
          setSelectedL2,
          selectedL3,
          setSelectedL3,
          selectedL4,
          setSelectedL4,
        }}
      >
        {isOverlayVisibleShow1 && (
          <OverlayComponentAdd1 isAnimating={isOverlayAnimatingShow1}>
            {/* <ButtonClose src="/close.svg" onClick={closeOverlayShow1} /> */}
            <FadeOutComponent>
              <FrameTopGradient100></FrameTopGradient100>
            </FadeOutComponent>

            <Outlet />
          </OverlayComponentAdd1>
        )}
      </OverlayContext.Provider>

      <OverlayContext.Provider
        value={{
          closeOverlay: closeOverlayAdd2,
          selectedL1,
          setSelectedL1,
          selectedL2,
          setSelectedL2,
          selectedL3,
          setSelectedL3,
          selectedL4,
          setSelectedL4,
        }}
      >
        {isOverlayVisibleAdd2 && (
          <OverlayComponentAdd1 isAnimating={isOverlayAnimatingAdd2}>
            {/* <ButtonClose src="/close.svg" onClick={closeOverlayAdd2} /> */}
            <FadeOutComponent>
              <FrameTopGradient100></FrameTopGradient100>
            </FadeOutComponent>

            <Outlet />
          </OverlayComponentAdd1>
        )}
      </OverlayContext.Provider>
      {isMessageBoxVisible && (
        <Backdrop>
          <MessageContainer>
            <p>
              <MessageboxText>{message}</MessageboxText>
            </p>
            <CloseMessageButton
              onClick={() => {
                setIsMessageBoxVisible(false);
              }}
            >
              <MessageboxText>Close</MessageboxText>
            </CloseMessageButton>
          </MessageContainer>
        </Backdrop>
      )}

      {isLoading && (
        <BackdropTransparent>
          <MessageContainer>
            <MessageboxText>Loading...</MessageboxText>
          </MessageContainer>
        </BackdropTransparent>
      )}

      {error && (
        <Backdrop>
          <MessageContainer>
            <p>
              <MessageboxText>{error}</MessageboxText>
            </p>
            <CloseMessageButton
              onClick={() => {
                setIsMessageBoxVisible(false);
                setError(null);
              }}
            >
              <MessageboxText>Close</MessageboxText>
            </CloseMessageButton>
          </MessageContainer>
        </Backdrop>
      )}
      {isConfirmDialogVisible && (
        <Backdrop>
          <MessageContainer>
            <p>
              <MessageboxText>Sei sicuro di voler rimuovere questa componente organizzativa?</MessageboxText>
            </p>
            <div style={{ display: "flex", justifyContent: "center", gap: "20px" }}>
              <CloseMessageButton onClick={() => setIsConfirmDialogVisible(false)}>
                <MessageboxText>No</MessageboxText>
              </CloseMessageButton>
              <CloseMessageButton onClick={handleDelete}>
                <MessageboxText>Rimuovi</MessageboxText>
              </CloseMessageButton>
            </div>
          </MessageContainer>
        </Backdrop>
      )}

      {/* {nestedData && (
        <div>
          <h2>API Data:</h2>
          <pre>{JSON.stringify(nestedData, null, 2)}</pre>
        </div>
      )} */}
    </MainBoardContentContainerOrganizzazione>
  );
};

export default CybersecurityOrganigramma;
