import React, { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { OverlayContext } from "../cybersecurity-board-contestoleadership-organizzazione";

import { useRecoilState } from "recoil";

import {
  complianceDataState,
  PartiAttachedFilesInfo,
  PartiComplianceDate,
  PartiComplianceDirettoreCompliance,
  PartiComplianceDirettoreComplianceID,
  PartiDocumentsAttached,
  PartiDocumentsPath,
  PartiLegalForm,
  PartiOrganizationID,
  PartiOrganizzazioneDH,
  PartiOrganizzazioneDirettore,
  PartiOrganizzazioneDirettoreID,
  PartiOrganizzazioneModello,
  PartiOrganizzazioneOrdinaria,
  PartiOrganizzazionePersonale,
  PartiOrganizzazioneSelectedL1,
  PartiOrganizzazioneSelectedL1ID,
  PartiSchedaGeneraleEmail,
  PartiSchedaGeneraleFax,
  PartiSchedaGeneraleIndirizzo,
  PartiSchedaGeneraleIVA,
  PartiSchedaGeneraleNomeAbbreviato,
  PartiSchedaGeneraleNomeCompleto,
  PartiSchedaGeneraleReferente,
  PartiSchedaGeneraleTelefono,
  PartiselectedCategory,
  PartiselectedCategoryID,
  PartiselectedCheckboxesProcessiState,
  PartiselectedLegalFormID,
} from "./Parti-Add-recoil";

import SchedaGenerale from "./cybersecurity-board-parti-parti-Add-sub/partiinteressate-partiinteressate-Add-1-SchedaGenerale";
import Organizzazione from "./cybersecurity-board-parti-parti-Add-sub/partiinteressate-partiinteressate-Add-2-Organizzazione";

import Compliance from "./cybersecurity-board-parti-parti-Add-sub/partiinteressate-partiinteressate-Add-6-GestioneCompliance";
import Documentazione, { useInitiateUploadParti } from "./cybersecurity-board-parti-parti-Add-sub/partiinteressate-partiinteressate-Add-7-Documentazione";

import { ButtonGrey, ButtonText } from "../../styled-components";

import {
  Backdrop,
  BackdropTransparent,
  CloseMessageButton,
  CreaSubMenuButtonMultiple,
  FrameTopGradient100,
  MessageboxText,
  MessageContainer,
  OverlayComponentBackgroundAdd1,
  OverlayComponentWhiteAdd1,
  OverlayComponentWhiteButtonFrameAdd1,
  OverlayComponentWhiteTitleAdd1,
  SubMenuButtonContainerOrganigrammaMultipleShort,
} from "../cybersecurity-board-contestoleadership-organizzazione-sub/cybersecurity-board-organizzazione-organigramma-styled-components";

import { URL_DirectorList, URL_Parti, URL_ProcessPost, useApi, useToken } from "../../../../API";
import { PATH_PartiInteressate } from "../../../../docPaths";
import { isLoadingRec, isMessageBoxVisibleClosingRec, isMessageBoxVisibleRec, reFetchIndicatorParties, setErrorRec, setMessageRec } from "../../../../recoil";

function generateUUID(): string {
  return "xxxxxxxx-xxxx-xxxx-yxxx-xxxxxxxxxxxx".replace(/[xy]/g, (c) => {
    const r = (Math.random() * 16) | 0;
    const v = c === "x" ? r : (r & 0x3) | 0x8;
    return v.toString(16);
  });
}

function useGenerateUUID(): string {
  const [organizationIdPost, setOrganizationIdPost] = useRecoilState<string>(PartiOrganizationID);

  useEffect(() => {
    const newUUID = generateUUID();
    setOrganizationIdPost(newUUID);
    console.log("New person ID: ", newUUID);
  }, [setOrganizationIdPost]);

  return organizationIdPost;
}

export const useFetchComplianceData = (token: string | null | undefined) => {
  const [ID, setID] = useRecoilState(PartiOrganizationID);
  const [complianceData, setComplianceData] = useRecoilState(complianceDataState);
  const [isLoading, setIsLoading] = useRecoilState(isLoadingRec);
  const [error, setError] = useRecoilState(setErrorRec);

  const fetchComplianceData = async () => {
    setIsLoading(true);
    setError(null);

    const url = `${URL_ProcessPost}/${ID}`;

    try {
      const response = await fetch(url, {
        headers: { Authorization: `Bearer ${token}` },
      });
      if (!response.ok) {
        throw new Error(`API request failed: ${response.statusText}`);
      }
      const data = await response.json();
      setComplianceData(data);
      console.log(`fetchComplianceData: `, data);
    } catch (err) {
      setError(err instanceof Error ? err.message : "An unknown error occurred");
    } finally {
      setIsLoading(false);
    }
  };
  return { fetchComplianceData, isLoading, error };
};

export const useProcessSave = () => {
  const [ID] = useRecoilState(PartiOrganizationID);
  const [complianceData] = useRecoilState(complianceDataState);
  const [isLoading, setIsLoading] = useRecoilState(isLoadingRec);
  const [isMessageBoxVisible, setIsMessageBoxVisible] = useRecoilState(isMessageBoxVisibleRec);
  const [message, setMessage] = useRecoilState(setMessageRec);
  const [selectedCheckboxes] = useRecoilState(PartiselectedCheckboxesProcessiState);
  const [error, setError] = useRecoilState(setErrorRec);
  const token = useToken();

  const handleSaveProcessi = async () => {
    setIsLoading(true);
    try {
      const updatedProcesses = complianceData.map((dataItem) => ({
        ...dataItem,
        selected: selectedCheckboxes.includes(dataItem.content.business_process.id),
      }));

      console.log("Checkboxes Processes for Submission:", selectedCheckboxes);
      console.log("Updated Processes for Submission:", updatedProcesses);

      const requestBody = JSON.stringify(updatedProcesses);
      console.log("Request Body:", requestBody);

      const url = `${URL_ProcessPost}/${ID}`;
      const response = await fetch(url, {
        method: "PATCH",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        body: requestBody,
      });

      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }

      const responseData = await response.json();
      console.log("Update successful:", responseData);
      setMessage("Update successful!");
    } catch (error) {
      console.error("Error during update process:", error);
      setError(error instanceof Error ? error.message : "An unknown error occurred");
      setMessage(`Error during update: ${error instanceof Error ? error.message : "Unknown error"}`);
    } finally {
      setIsLoading(false);
      setIsMessageBoxVisible(true);
    }
  };

  return {
    handleSaveProcessi,
    isLoading,
    error,
    message,
    isMessageBoxVisible,
    setError,
    setIsLoading,
    setMessage,
    setIsMessageBoxVisible,
  };
};

export function useSaveOrganization() {
  const [ID, setID] = useRecoilState(PartiOrganizationID);
  const [nomeCompleto, setNomeCompleto] = useRecoilState(PartiSchedaGeneraleNomeCompleto);
  const [nomeAbbreviato, setNomeAbbreviato] = useRecoilState(PartiSchedaGeneraleNomeAbbreviato);
  const [Email, setEmail] = useRecoilState(PartiSchedaGeneraleEmail);
  const [Telefono, setTelefono] = useRecoilState(PartiSchedaGeneraleTelefono);
  const [Fax, setFax] = useRecoilState(PartiSchedaGeneraleFax);
  const [IVA, setIVA] = useRecoilState(PartiSchedaGeneraleIVA);
  const [Referente, setReferente] = useRecoilState(PartiSchedaGeneraleReferente);
  const [Indirizzo, setIndirizzo] = useRecoilState(PartiSchedaGeneraleIndirizzo);
  const [Modello, setModello] = useRecoilState(PartiOrganizzazioneModello);
  const [AttachedFilesInfo, setAttachedFilesInfo] = useRecoilState(PartiAttachedFilesInfo);
  const [ComplianceDate, setComplianceDate] = useRecoilState(PartiComplianceDate);
  const [ComplianceDirettoreCompliance, setComplianceDirettoreCompliance] = useRecoilState(PartiComplianceDirettoreCompliance);
  const [ComplianceDirettoreComplianceID, setComplianceDirettoreComplianceID] = useRecoilState(PartiComplianceDirettoreComplianceID);
  const [DocumentsAttached, setDocumentsAttached] = useRecoilState(PartiDocumentsAttached);
  const [DocumentsPath, setDocumentsPath] = useRecoilState(PartiDocumentsPath);
  const [OrganizzazioneDH, setOrganizzazioneDH] = useRecoilState(PartiOrganizzazioneDH);
  const [OrganizzazioneDirettore, setOrganizzazioneDirettore] = useRecoilState(PartiOrganizzazioneDirettore);
  const [OrganizzazioneDirettoreID, setOrganizzazioneDirettoreID] = useRecoilState(PartiOrganizzazioneDirettoreID);
  const [rappresentanteLegale, setRappresentanteLegale] = useRecoilState(PartiOrganizzazioneOrdinaria);
  const [OrganizzazionePersonale, setOrganizzazionePersonale] = useRecoilState(PartiOrganizzazionePersonale);
  const [OrganizzazioneSelectedL1, setOrganizzazioneSelectedL1] = useRecoilState(PartiOrganizzazioneSelectedL1);
  const [OrganizzazioneSelectedL1ID, setOrganizzazioneSelectedL1ID] = useRecoilState(PartiOrganizzazioneSelectedL1ID);
  const [categoryID, setCategoryID] = useRecoilState(PartiselectedCategoryID);
  const [category, setCategory] = useRecoilState(PartiselectedCategory);

  const [entityLegalFormID, setEntityLegalFormID] = useRecoilState(PartiselectedLegalFormID);
  const [entityLegalForm, setEntityLegalForm] = useRecoilState(PartiLegalForm);

  const [selectedCheckboxes, setSelectedCheckboxes] = useRecoilState(PartiselectedCheckboxesProcessiState);
  const [complianceData, setComplianceData] = useRecoilState(complianceDataState);

  const [isLoading, setIsLoading] = useRecoilState(isLoadingRec);
  const [error, setError] = useState(setErrorRec);
  const [isMessageBoxVisible, setIsMessageBoxVisible] = useRecoilState(isMessageBoxVisibleRec);
  const [isMessageBoxVisibleClosing, setIsMessageBoxVisibleClosing] = useRecoilState(isMessageBoxVisibleClosingRec);
  const [message, setMessage] = useRecoilState(setMessageRec);

  const token = useToken();
  const { post } = useApi();

  const [reFetchIndicator, setRefetchIndicator] = useRecoilState(reFetchIndicatorParties);
  const initiateUpload = useInitiateUploadParti();
  const newSiteData = {
    id: ID,
    pref_label: nomeCompleto,
    has_responsible: OrganizzazioneDirettoreID,
    has_entity_legal_form: entityLegalFormID,
    has_organizational_card: "",
    identifier: IVA,
    has_primary_site: OrganizzazioneSelectedL1ID,
    has_legal_representative: rappresentanteLegale,
    description: OrganizzazioneDH,
    kind: categoryID,
    has_documents_folder_link: PATH_PartiInteressate + nomeCompleto,
    organizational_card: {
      alt_label: nomeAbbreviato,
      email: Email,
      main_web_site: Indirizzo,
      telephone: Telefono,
      fax: Fax,
      has_contact_person: Referente,
    },

    entity_legal_form: {
      id: entityLegalFormID,
      category: entityLegalForm,
    },
  };

  const handleSave = async () => {
    if (!nomeCompleto || !IVA || !entityLegalFormID || !categoryID) {
      setMessage("Si prega di compilare tutti i campi obbligatori: Ragione sociale, C.F./P.IVA, Forma giuridica e Categoria");
      setIsMessageBoxVisible(true);
      return;
    }

    console.log(JSON.stringify(newSiteData));

    try {
      setIsLoading(true);

      const responseData = await post(URL_Parti, newSiteData);

      console.log("Organization created successfully:", responseData);
      setMessage("La parte interessata è stata creata con successo!");
      initiateUpload();
      setRefetchIndicator((prev) => prev + 1);
      setIsLoading(false);
      setIsMessageBoxVisibleClosing(true);

      setID("");
      setNomeCompleto("");
      setNomeAbbreviato("");
      setEmail("");
      setTelefono("");
      setFax("");
      setIVA("");
      setReferente("");
      setIndirizzo("");
      setModello("");
      setAttachedFilesInfo([]);
      setComplianceDirettoreCompliance("");
      setComplianceDirettoreComplianceID("");
      setDocumentsAttached([]);
      setDocumentsPath("");
      setOrganizzazioneDH("");
      setOrganizzazioneDirettore("");
      setOrganizzazioneDirettoreID("");
      setRappresentanteLegale("");
      setOrganizzazionePersonale("");
      setOrganizzazioneSelectedL1("");
      setOrganizzazioneSelectedL1ID("");
      setCategoryID("");
      setCategory("");
      setEntityLegalFormID("");
      setEntityLegalForm("");
      setSelectedCheckboxes([]);
      setComplianceData([]);
    } catch (error) {
      console.error("Error creating Organization:", error);
      setMessage(`Error creating Organization: ${error instanceof Error ? error.message : "Unknown error"}`);
      setIsLoading(false);
      setIsMessageBoxVisible(true);
    }
  };

  return { handleSave };
}

const YourAddL1Component = () => {
  const [ID, setID] = useRecoilState(PartiOrganizationID);
  const [nomeCompleto, setNomeCompleto] = useRecoilState(PartiSchedaGeneraleNomeCompleto);
  const [nomeAbbreviato, setNomeAbbreviato] = useRecoilState(PartiSchedaGeneraleNomeAbbreviato);
  const [Email, setEmail] = useRecoilState(PartiSchedaGeneraleEmail);
  const [Telefono, setTelefono] = useRecoilState(PartiSchedaGeneraleTelefono);
  const [Fax, setFax] = useRecoilState(PartiSchedaGeneraleFax);
  const [IVA, setIVA] = useRecoilState(PartiSchedaGeneraleIVA);
  const [Referente, setReferente] = useRecoilState(PartiSchedaGeneraleReferente);
  const [Indirizzo, setIndirizzo] = useRecoilState(PartiSchedaGeneraleIndirizzo);
  const [Modello, setModello] = useRecoilState(PartiOrganizzazioneModello);
  const [AttachedFilesInfo, setAttachedFilesInfo] = useRecoilState(PartiAttachedFilesInfo);
  const [ComplianceDate, setComplianceDate] = useRecoilState(PartiComplianceDate);
  const [ComplianceDirettoreCompliance, setComplianceDirettoreCompliance] = useRecoilState(PartiComplianceDirettoreCompliance);
  const [ComplianceDirettoreComplianceID, setComplianceDirettoreComplianceID] = useRecoilState(PartiComplianceDirettoreComplianceID);
  const [DocumentsAttached, setDocumentsAttached] = useRecoilState(PartiDocumentsAttached);
  const [DocumentsPath, setDocumentsPath] = useRecoilState(PartiDocumentsPath);
  const [OrganizzazioneDH, setOrganizzazioneDH] = useRecoilState(PartiOrganizzazioneDH);
  const [OrganizzazioneDirettore, setOrganizzazioneDirettore] = useRecoilState(PartiOrganizzazioneDirettore);
  const [OrganizzazioneDirettoreID, setOrganizzazioneDirettoreID] = useRecoilState(PartiOrganizzazioneDirettoreID);
  const [OrganizzazioneOrdinaria, setOrganizzazioneOrdinaria] = useRecoilState(PartiOrganizzazioneOrdinaria);
  const [OrganizzazionePersonale, setOrganizzazionePersonale] = useRecoilState(PartiOrganizzazionePersonale);
  const [OrganizzazioneSelectedL1, setOrganizzazioneSelectedL1] = useRecoilState(PartiOrganizzazioneSelectedL1);
  const [OrganizzazioneSelectedL1ID, setOrganizzazioneSelectedL1ID] = useRecoilState(PartiOrganizzazioneSelectedL1ID);

  const [selectedCheckboxes, setSelectedCheckboxes] = useRecoilState(PartiselectedCheckboxesProcessiState);

  useGenerateUUID();

  const token = useToken();

  const { closeOverlay } = useContext(OverlayContext);

  const navigate = useNavigate();

  const { handleSave } = useSaveOrganization();
  const { fetchComplianceData } = useFetchComplianceData(token);
  const { handleSaveProcessi } = useProcessSave();
  const [complianceData, setComplianceData] = useRecoilState(complianceDataState);

  const [data, setData] = useState<DirectorDataItem[]>([]);
  const [isLoading, setIsLoading] = useRecoilState(isLoadingRec);
  const [error, setError] = useRecoilState<string | null>(setErrorRec);
  const [isMessageBoxVisible, setIsMessageBoxVisible] = useRecoilState(isMessageBoxVisibleRec);
  const [isMessageBoxVisibleClosing, setIsMessageBoxVisibleClosing] = useRecoilState(isMessageBoxVisibleClosingRec);
  const [message, setMessage] = useRecoilState(setMessageRec);

  const [complianceDataFetched, setComplianceDataFetched] = useState(false);

  const organizationLifecycle = async () => {
    try {
      setIsLoading(true);
      await handleSave();
      await fetchComplianceData();
      setComplianceDataFetched(true);
    } catch (err) {
      console.error("Error in organization lifecycle:", error);
      setError(err instanceof Error ? err.message : "An unknown error occurred");
      setIsMessageBoxVisible(true);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (complianceDataFetched && complianceData.length > 0 && selectedCheckboxes.length > 0) {
      handleSaveProcessi();
    }
  }, [complianceDataFetched, complianceData, selectedCheckboxes]);

  type DirectorApiContentItem = {
    id: string;
    alias: string;
  };

  type DirectorDataItem = {
    director_id: string;
    director_name: string;
  };

  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true);
      try {
        if (!token) throw new Error("User is not authenticated");

        const response = await fetch(URL_DirectorList, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });

        if (!response.ok) {
          throw new Error(`API request failed: ${response.statusText}`);
        }

        const responseData = await response.json();
        console.log("Response Data:", responseData);

        const transformedData: DirectorDataItem[] = responseData.map((item: DirectorApiContentItem) => {
          return {
            director_id: item.id,
            director_name: item.alias,
          };
        });

        console.log("Transformed Data:", transformedData);
        setData(transformedData);
      } catch (err) {
        setError(err instanceof Error ? err.message : "An unknown error occurred");
        setIsMessageBoxVisible(true);
      } finally {
        setIsLoading(false);
      }
    };

    fetchData();
  }, [token]);

  const directorNameToId = new Map(data.map((item) => [item.director_name, item.director_id]));

  const handleCloseAndNavigate = () => {
    setIsMessageBoxVisibleClosing(false);
    closeOverlay();
  };

  const handleClose = () => {
    setIsMessageBoxVisible(false);
  };

  const [activeComponent, setActiveComponent] = useState("SchedaGenerale");

  const changeActiveComponent = (componentName: string) => {
    setActiveComponent(componentName);
  };

  const renderActiveComponent = () => {
    switch (activeComponent) {
      case "SchedaGenerale":
        return <SchedaGenerale />;
      case "Organizzazione":
        return <Organizzazione />;

      case "SistemaCompliance":
        return <Compliance />;
      case "Documentazione":
        return <Documentazione />;
      default:
        return <SchedaGenerale />;
    }
  };

  return (
    <OverlayComponentBackgroundAdd1>
      {isLoading && (
        <BackdropTransparent>
          <MessageContainer>
            <MessageboxText>Loading...</MessageboxText>
          </MessageContainer>
        </BackdropTransparent>
      )}

      {isMessageBoxVisible && (
        <Backdrop>
          <MessageContainer>
            <p>
              <MessageboxText>{message}</MessageboxText>
            </p>
            <CloseMessageButton onClick={handleClose}>
              <MessageboxText>Close</MessageboxText>
            </CloseMessageButton>
          </MessageContainer>
        </Backdrop>
      )}

      {isMessageBoxVisibleClosing && (
        <Backdrop>
          <MessageContainer>
            <p>
              <MessageboxText>{message}</MessageboxText>
            </p>
            <CloseMessageButton onClick={handleCloseAndNavigate}>
              <MessageboxText>Close</MessageboxText>
            </CloseMessageButton>
          </MessageContainer>
        </Backdrop>
      )}

      {error && (
        <Backdrop>
          <MessageContainer>
            <p>
              <MessageboxText>{error}</MessageboxText>
            </p>
            <CloseMessageButton
              onClick={() => {
                setIsMessageBoxVisible(false);
                setError(null);
                closeOverlay();
              }}
            >
              <MessageboxText>Close</MessageboxText>
            </CloseMessageButton>
          </MessageContainer>
        </Backdrop>
      )}

      <FrameTopGradient100 />
      <OverlayComponentWhiteTitleAdd1>NUOVA PARTE INTERESSATA</OverlayComponentWhiteTitleAdd1>
      <OverlayComponentWhiteAdd1>
        <SubMenuButtonContainerOrganigrammaMultipleShort>
          <CreaSubMenuButtonMultiple onClick={() => changeActiveComponent("SchedaGenerale")} to="/Cybersecurity/PartiInteressate/PartiInteressate/CreaPartiInteressate/1">
            Scheda generale
          </CreaSubMenuButtonMultiple>
          <CreaSubMenuButtonMultiple onClick={() => changeActiveComponent("Organizzazione")} to="/Cybersecurity/PartiInteressate/PartiInteressate/CreaPartiInteressate/2">
            Organizzazione
          </CreaSubMenuButtonMultiple>
          {/* <CreaSubMenuButtonMultiple
            onClick={() => changeActiveComponent("Infrastruttura")}
            to="/Cybersecurity/PartiInteressate/PartiInteressate/CreaPartiInteressate/3"
          >
            Infrastruttura
          </CreaSubMenuButtonMultiple> */}
          {/* <CreaSubMenuButtonMultiple
            onClick={() => changeActiveComponent("ProcessiGenerali")}
            to="/Cybersecurity/PartiInteressate/PartiInteressate/CreaPartiInteressate/4"
          >
            Processi generali
          </CreaSubMenuButtonMultiple> */}
          {/* <CreaSubMenuButtonMultiple
            onClick={() => changeActiveComponent("PartiInteressate")}
            to="/Cybersecurity/PartiInteressate/PartiInteressate/CreaPartiInteressate/5"
          >
            Parti interessate
          </CreaSubMenuButtonMultiple> */}
          <CreaSubMenuButtonMultiple onClick={() => changeActiveComponent("SistemaCompliance")} to="/Cybersecurity/PartiInteressate/PartiInteressate/CreaPartiInteressate/6">
            Sistema gestione Compliance
          </CreaSubMenuButtonMultiple>
          <CreaSubMenuButtonMultiple onClick={() => changeActiveComponent("Documentazione")} to="/Cybersecurity/PartiInteressate/PartiInteressate/CreaPartiInteressate/7">
            Documentazione
          </CreaSubMenuButtonMultiple>
        </SubMenuButtonContainerOrganigrammaMultipleShort>

        {renderActiveComponent()}
      </OverlayComponentWhiteAdd1>

      <OverlayComponentWhiteButtonFrameAdd1>
        <ButtonGrey onClick={closeOverlay}>
          <ButtonText>Annulla</ButtonText>
        </ButtonGrey>
        <ButtonGrey onClick={handleSave}>
          <ButtonText>Crea</ButtonText>
        </ButtonGrey>
      </OverlayComponentWhiteButtonFrameAdd1>
    </OverlayComponentBackgroundAdd1>
  );
};

export default YourAddL1Component;
