import { keycloakIp, complereIp, localIp } from "./API";

// replace localIp with complereIp during production

const oidcConfig = {
  authority: `${keycloakIp}/realms/complere`, // "https://auth.ecubit.it/realms/complere"
  clientId: "complere-fe",
  redirectUri: `${complereIp}/Cybersecurity/GapAnalysis`, // "https://complere.ecubit.it/Cybersecurity/GapAnalysis"
  postLogoutRedirectUri: `${complereIp}/Cybersecurity/GapAnalysis`, // "https://complere.ecubit.it/Cybersecurity/GapAnalysis"
  silentRedirectUri: `${complereIp}/silent-check-sso.html`, // Update for production
  responseType: "code",
  scope: "openid profile email",
  automaticSilentRenew: false, // true by default
};

export default oidcConfig;