import CircleIcon from "@mui/icons-material/Circle";
import React, { useCallback, useContext, useEffect, useMemo, useState } from "react";
import { Outlet, useLocation, useNavigate } from "react-router-dom";
import { useRecoilState } from "recoil";
import styled from "styled-components";
import { FrameBoardMainControls, MainBoardSubMenuButton, MainBoardSubMenuButtonContainer } from "../styled-components";
import { URL_GAP_Analysis, useApi, useToken } from "./../../../API";
import { SelectedL1ID, SelectedL2ID, SelectedL3ID, SelectedL4ID, isMessageBoxVisibleClosingRec, isMessageBoxVisibleRec } from "./../../../recoil";
import {
  StyledIdentificativo20Td,
  StyledIdentificativo20Th,
  StyledIdentificativo40Td,
  StyledIdentificativo40Th
} from "./cybersecurity-board-contestoleadership-infrastruttura-sub/cybersecurity-board-infrastruttura-elencoasset-styled-components";
import { FrameTopGradient100 } from "./cybersecurity-board-contestoleadership-infrastruttura-sub/cybersecurity-board-infrastruttura-organigramma-styled-components";
import { addLuoghiDeleteConfirmationDialog } from "./cybersecurity-board-contestoleadership-infrastruttura-sub/Luoghi-Add-recoil";
import { OverlayContext } from "./cybersecurity-board-contestoleadership-organizzazione";
import {
  Backdrop,
  BackdropTransparent,
  BackgroundIcon,
  CloseMessageButton,
  FadeOutComponent,
  IconButton,
  IconsGroup,
  MessageContainer,
  MessageboxText,
} from "./cybersecurity-board-contestoleadership-organizzazione-sub/cybersecurity-board-organizzazione-organigramma-styled-components";
import {
  Search,
  SearchIcon,
  SearchInputContainer,
  StyledTable,
  StyledTableItem,
  StyledTbody,
  StyledTd10,
  StyledTh10,
  StyledThead,
  TableContainer,
  TableItemText,
  TableScrollableDiv,
  TableTitleText,
  TopContainer
} from "./cybersecurity-board-contestoleadership-organizzazione-sub/cybersecurity-board-organizzazione-unitaoperative-styled-components";
import {
  GAP_chartDataState5,
  GAP_control_value_2_avarage,
  GAP_control_value_2_total,
  GAP_control_value_2_total_004,
  GAP_control_value_2_total_0514,
  GAP_control_value_2_total_1524,
  GAP_control_value_2_total_2534,
  GAP_control_value_2_total_3544,
  GAP_control_value_2_total_455,
  GAP_control_value_2_total_5,
  averageDutyGroupsState,
  isControlliAnimating,
  isControlliAnimatingEdit,
  isControlliOpen,
  isControlliOpenEdit,
  reFetchIndicatorGAPControlli,
} from "./gap-recoil";

interface DataItem {
  id: string;
  control_id: string;
  control_category: string;
  control_description: string;
  control_duty: string;
  control_priority: string;
  control_value_1: number;
  control_value_2: number;
  control_note_1: string;
  control_note_2: string;
  control_note_3: string;
  has_documents_folder_link: string;
}

type SortableKeys = keyof DataItem;

interface RoleItem {
  responsibility: string;
  roleDescription: string;
}

type ApiContentItem = {
  id: string;
  site_name: string;
  name: string;
  plan_ref: string;
  roles: RoleItem[];
};

type StyledTrProps = {
  selected: boolean;
};

const StyledTr = styled.tr<StyledTrProps>`
  cursor: pointer;
  background-color: ${(props) => {
    return props.selected ? "#f3f7fb" : "#ffffff";
  }};

  &:hover {
    background-color: #e9eff7; // Make sure this doesn't override your selection color
  }
`;

interface OverlayComponentProps {
  isAnimating: boolean;
}

const OverlayComponentAdd1 = styled.div<OverlayComponentProps>`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  z-index: 10;
  transition: opacity 300ms ease-in-out, visibility 300ms ease-in-out;
  opacity: ${(props) => (props.isAnimating ? "1" : "0")};
  visibility: ${(props) => (props.isAnimating ? "visible" : "hidden")};
`;

//////////// DELETE PLACE
const useDeletePlace = () => {
  const { del } = useApi();
  const [reFetchIndicator, setRefetchIndicator] = useRecoilState(reFetchIndicatorGAPControlli);
  const [isConfirmDialogVisible, setIsConfirmDialogVisible] = useRecoilState(addLuoghiDeleteConfirmationDialog);
  const [L3ID, setL3ID] = useRecoilState(SelectedL3ID);

  const handleDelete = useCallback(async () => {
    if (!L3ID) return;

    const url = URL_GAP_Analysis;
    try {
      await del(url, L3ID);
      console.log(`Deleted control with ID: ${L3ID}`);
      // Move the setRefetchIndicator and dialog visibility updates to a finally block
    } catch (error) {
      console.error("Failed to delete control:", error);
    } finally {
      // Ensure these states are updated after the try/catch block
      setRefetchIndicator((prev) => prev + 1);
      setIsConfirmDialogVisible(false); // Close confirmation dialog
    }
  }, [L3ID, del, setRefetchIndicator]);

  const showDeleteConfirmation = useCallback(() => {
    setIsConfirmDialogVisible(true);
  }, []);

  return {
    handleDelete,
    showDeleteConfirmation,
    isConfirmDialogVisible,
    setIsConfirmDialogVisible,
  };
};

/////////////DELETE PLACE END

const DataTable: React.FC<{ data: DataItem[] }> = ({ data }) => {
  /////////////OVERLAY ADD OPEN CLOSE
  const [isOverlayVisibleAdd2, setIsOverlayVisibleAdd2] = useRecoilState(isControlliOpen);
  const [isOverlayAnimatingAdd2, setIsOverlayAnimatingAdd2] = useRecoilState(isControlliAnimating);

  const [isOverlayVisibleEdit2, setIsOverlayVisibleEdit2] = useRecoilState(isControlliOpenEdit);
  const [isOverlayAnimatingEdit2, setIsOverlayAnimatingEdit2] = useRecoilState(isControlliAnimatingEdit);

  const navigate = useNavigate();

  const location = useLocation();

  const isActive = (path: string) => {
    // Check if the current location is the index route of Organizzazione
    return location.pathname === "/Cybersecurity/GapAnalysis" && path === "GapAnalysis";
  };

  const toggleOverlayAdd2 = () => {
    if (!isOverlayVisibleAdd2) {
      // Check if the current path is '/Cybersecurity/Organizzazione'
      if (window.location.pathname === "/Cybersecurity/Controlli") {
        navigate(`CreaControllo/1`); // Navigate to 'Organigramma/AggiungiL1' if the condition is true
      } else {
        navigate(`${window.location.pathname}/CreaControllo/1`); // Navigate to '/AggiungiL1' if the condition is false
      }

      setIsOverlayVisibleAdd2(true); // Show the overlay
      setTimeout(() => setIsOverlayAnimatingAdd2(true), 10); // Start animation after it's visible
    } else {
      setIsOverlayAnimatingAdd2(false); // Start hiding animation
      setTimeout(() => setIsOverlayVisibleAdd2(false), 310); // Wait for animation to finish before hiding
    }
  };

  const toggleOverlayEdit2 = () => {
    if (!isOverlayVisibleEdit2) {
      // Check if the current path is '/Cybersecurity/Organizzazione'
      if (window.location.pathname === "/Cybersecurity/Controlli") {
        navigate(`ModificaControllo/1`); // Navigate to 'Organigramma/AggiungiL1' if the condition is true
      } else {
        navigate(`${window.location.pathname}/ModificaControllo/1`); // Navigate to '/AggiungiL1' if the condition is false
      }

      setIsOverlayVisibleEdit2(true); // Show the overlay
      setTimeout(() => setIsOverlayAnimatingEdit2(true), 10); // Start animation after it's visible
    } else {
      setIsOverlayAnimatingEdit2(false); // Start hiding animation
      setTimeout(() => setIsOverlayVisibleEdit2(false), 310); // Wait for animation to finish before hiding
    }
  };

  // Function to close the overlay
  const closeOverlayAdd2 = () => {
    //clean recoil variables here

    navigate(`/Cybersecurity/Controlli`);
    setIsOverlayAnimatingAdd2(false);
    setIsOverlayAnimatingEdit2(false); // Start the hide animation
    // Use a timeout equal to the transition duration to set visibility to false
    setTimeout(() => {
      setIsOverlayVisibleAdd2(false);
      setIsOverlayVisibleEdit2(false);
    }, 300); // 300ms is the duration of the exit transition
  };

  ////////////////overlay open close end
  const [selectedL1, setSelectedL1] = useState<string | null>(null);
  const [selectedL2, setSelectedL2] = useState<string | null>(null);
  const [selectedL3, setSelectedL3] = useState<string | null>(null);
  const [selectedL4, setSelectedL4] = useState<string | null>(null);

  const [L1ID, setL1ID] = useRecoilState(SelectedL1ID);
  const [L2ID, setL2ID] = useRecoilState(SelectedL2ID);
  const [L3ID, setL3ID] = useRecoilState(SelectedL3ID);
  const [L4ID, setL4ID] = useRecoilState(SelectedL4ID);

  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const [isMessageBoxVisible, setIsMessageBoxVisible] = useRecoilState(isMessageBoxVisibleRec);
  const [isMessageBoxVisibleClosing, setIsMessageBoxVisibleClosing] = useRecoilState(isMessageBoxVisibleClosingRec);

  const [sortConfig, setSortConfig] = useState<{
    key: SortableKeys;
    direction: "ascending" | "descending";
  } | null>(null);

  // Function to handle sorting
  const sortedData = useMemo(() => {
    let sortableItems = [...data]; // Create a new array to avoid mutating the original data
    if (sortConfig !== null) {
      sortableItems.sort((a, b) => {
        const aValue = a[sortConfig.key];
        const bValue = b[sortConfig.key];

        // Handle different types if necessary, e.g., string vs number
        if (typeof aValue === "string" && typeof bValue === "string") {
          return sortConfig.direction === "ascending" ? aValue.localeCompare(bValue) : bValue.localeCompare(aValue);
        }
        // Add more conditions for other types if necessary

        return 0;
      });
    }
    return sortableItems;
  }, [data, sortConfig]);

  // Click handler for headers
  const requestSort = (key: SortableKeys) => {
    let direction: "ascending" | "descending" = "ascending";
    if (sortConfig && sortConfig.key === key && sortConfig.direction === "ascending") {
      direction = "descending";
    }
    setSortConfig({ key, direction });
  };

  const [selectedId, setSelectedId] = useState<string | null>(null);

  const handleRowSelect = (id: string) => {
    setSelectedId((prevId) => {
      //console.log(`Previous ID: ${prevId}, New ID: ${id}`);
      return id;
    });

    setL3ID(id);
  };

  useEffect(() => {
    // This effect will run after `L2ID` has been updated.
    console.log("L3ID now has the value:", L3ID);
    // Here, you can add any logic that needs to run right after L2ID changes.
  }, [L3ID]); // Dependency array ensures this effect runs only when L2ID changes.

  //////////////////////GLOBAL SEARCH

  const [globalFilterText, setGlobalFilterText] = useState("");

  const handleGlobalFilterChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setGlobalFilterText(event.target.value);
  };

  ////////////////////////////////////// FILTER

  const [filterText, setFilterText] = useState("");
  // Event handler for the search input
  const handleFilterChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setFilterText(event.target.value);
  };

  // Filter and then sort the data
  const filteredAndSortedData = useMemo(() => {
    const filteredData = data
      .filter((item) => {
        const itemSiteName = item.control_category?.toLowerCase() ?? "";
        return itemSiteName.includes(filterText.toLowerCase());
      })
      .filter((item) => {
        // If there's global filter text, check all the relevant properties
        if (globalFilterText) {
          const allText = Object.values(item)
            .reduce((acc, cur) => {
              const value = (cur ?? "").toString().toLowerCase();
              return acc + value + " ";
            }, "")
            .trim();

          return allText.includes(globalFilterText.toLowerCase());
        }
        return true; // If there's no global filter text, don't filter further
      });

    // Then sort the filtered data
    if (sortConfig !== null) {
      filteredData.sort((a, b) => {
        const aValue = (a[sortConfig.key] ?? "").toString();
        const bValue = (b[sortConfig.key] ?? "").toString();

        if (sortConfig.direction === "ascending") {
          return aValue.localeCompare(bValue);
        } else {
          return bValue.localeCompare(aValue);
        }
      });
    }

    return filteredData;
  }, [data, sortConfig, filterText, globalFilterText]);

  const { handleDelete, showDeleteConfirmation, isConfirmDialogVisible, setIsConfirmDialogVisible } = useDeletePlace();

  const getCircleColor = (value: string) => {
    switch (value.toLowerCase()) {
      case "nd":
        return "#bebebe";
      case "bassa":
        return "#27B376";
      case "media":
        return "#F9A73E";
      case "alta":
        return "#BF212F";
      default:
        return "#bebebe"; // Default color if value doesn't match any condition
    }
  };

  return (
    <FrameBoardMainControls>
      <MainBoardSubMenuButtonContainer>
        <MainBoardSubMenuButton to="/Cybersecurity/GapAnalysis" className={isActive("GapAnalysis") ? "active" : ""}>
          Dashboard
        </MainBoardSubMenuButton>
        <MainBoardSubMenuButton to="/Cybersecurity/Controlli">Controlli</MainBoardSubMenuButton>
        {/* <MainBoardSubMenuButton to="/Cybersecurity/PartiInteressate/Fornitori">Fornitori</MainBoardSubMenuButton>
    <MainBoardSubMenuButton to="/Cybersecurity/PartiInteressate/AltriEnti">Altri enti</MainBoardSubMenuButton> */}
      </MainBoardSubMenuButtonContainer>
      <TableContainer>
        <TopContainer>
          <SearchInputContainer>
            <Search placeholder="Filtra per categoria" onChange={handleFilterChange} />
            <SearchIcon src="/funnel.svg" />
          </SearchInputContainer>
          <SearchInputContainer>
            <Search placeholder="Cerca in tutta la tabella" onChange={handleGlobalFilterChange} />
            <SearchIcon src="/search.svg" />
          </SearchInputContainer>

          <IconsGroup>
            {/* <IconButton>
            <BackgroundIcon loading="eager" alt="" src="/doc.svg" />
             </IconButton> */}
            <IconButton>
              <BackgroundIcon loading="eager" alt="" src="/edit.svg" onClick={toggleOverlayEdit2} />
            </IconButton>
            <IconButton onClick={() => showDeleteConfirmation()}>
              <BackgroundIcon loading="eager" alt="" src="/delete.svg" />
            </IconButton>
            <IconButton>
              <BackgroundIcon
                loading="eager"
                alt=""
                src="/add.svg"
                onClick={toggleOverlayAdd2} // Use the prop here
              />
            </IconButton>
          </IconsGroup>
        </TopContainer>

        <StyledTable>
          <StyledThead>
            <StyledTh10 onClick={() => requestSort("control_id")}>
              <TableTitleText>▼ ID</TableTitleText>
            </StyledTh10>

            <StyledIdentificativo20Th onClick={() => requestSort("control_category")}>
              <TableTitleText>▼ Categoria</TableTitleText>
            </StyledIdentificativo20Th>

            <StyledIdentificativo40Th onClick={() => requestSort("control_description")}>
              <TableTitleText>▼ Descrizione</TableTitleText>
            </StyledIdentificativo40Th>

            <StyledTh10 onClick={() => requestSort("control_duty")}>
              <TableTitleText>▼ Obbligo</TableTitleText>
            </StyledTh10>

            <StyledTh10 onClick={() => requestSort("control_priority")}>
              <TableTitleText>▼ Priorità</TableTitleText>
            </StyledTh10>

            <StyledTh10 onClick={() => requestSort("control_value_2")}>
              <TableTitleText>▼ Valore</TableTitleText>
            </StyledTh10>
          </StyledThead>
        </StyledTable>

        <TableScrollableDiv>
          <StyledTableItem>
            <StyledTbody>
              {filteredAndSortedData.map((item) => (
                <StyledTr
                  key={item.id} // Use the unique ID as key instead of index
                  onClick={() => handleRowSelect(item.id)} // Set the selected item ID on click
                  selected={selectedId === item.id} // Conditionally apply the background color
                >
                  <StyledTd10 title={item.control_id}>
                    <TableItemText>{item.control_id}</TableItemText>
                  </StyledTd10>
                  <StyledIdentificativo20Td title={item.control_category}>
                    <TableItemText>{item.control_category}</TableItemText>
                  </StyledIdentificativo20Td>
                  <StyledIdentificativo40Td title={item.control_description}>
                    <TableItemText>{item.control_description}</TableItemText>
                  </StyledIdentificativo40Td>
                  <StyledTd10 title={item.control_duty}>
                    <TableItemText>{item.control_duty}</TableItemText>
                  </StyledTd10>
                  <StyledTd10 title={item.control_priority}>
                    <CircleIcon style={{ color: getCircleColor(item.control_priority), marginLeft: "8px" }} />
                  </StyledTd10>
                  <StyledTd10 title={item.control_value_2.toString()}>
                    <TableItemText>{item.control_value_2.toString()}</TableItemText>
                  </StyledTd10>
                </StyledTr>
              ))}
            </StyledTbody>
          </StyledTableItem>
        </TableScrollableDiv>

        <OverlayContext.Provider
          value={{
            closeOverlay: closeOverlayAdd2,
            selectedL1,
            setSelectedL1,
            selectedL2,
            setSelectedL2,
            selectedL3,
            setSelectedL3,
            selectedL4,
            setSelectedL4,
          }}
        >
          {isOverlayVisibleAdd2 && (
            <OverlayComponentAdd1 isAnimating={isOverlayAnimatingAdd2}>
              {/* <ButtonClose src="/close.svg" onClick={closeOverlayAdd2} /> */}
              <FadeOutComponent>
                <FrameTopGradient100></FrameTopGradient100>
              </FadeOutComponent>

              <Outlet />
            </OverlayComponentAdd1>
          )}

          {isOverlayVisibleEdit2 && (
            <OverlayComponentAdd1 isAnimating={isOverlayAnimatingEdit2}>
              {/* <ButtonClose src="/close.svg" onClick={closeOverlayAdd2} /> */}
              <FadeOutComponent>
                <FrameTopGradient100></FrameTopGradient100>
              </FadeOutComponent>

              <Outlet />
            </OverlayComponentAdd1>
          )}
        </OverlayContext.Provider>
      </TableContainer>
    </FrameBoardMainControls>
  );
};

export const useControlliOverlay = () => {
  const [isOverlayVisibleAdd2, setIsOverlayVisibleAdd2] = useRecoilState(isControlliOpen);
  const [isOverlayAnimatingAdd2, setIsOverlayAnimatingAdd2] = useRecoilState(isControlliAnimating);
  const navigate = useNavigate();

  const closeOverlayControlli = () => {
    setIsOverlayAnimatingAdd2(false); // Start the hide animation
    setTimeout(() => {
      setIsOverlayVisibleAdd2(false);
      navigate(`/Cybersecurity/Controlli`);
    }, 300); // 300ms is the duration of the exit transition
  };

  return {
    closeOverlayControlli,
    toggleOverlayAdd2: () => {
      if (!isOverlayVisibleAdd2) {
        setIsOverlayVisibleAdd2(true); // Show the overlay
        setTimeout(() => setIsOverlayAnimatingAdd2(true), 300); // Start animation after it's visible
      } else {
        closeOverlayControlli();
      }
    },
  };
};

interface CategoryCount {
  obbligatorio: number;
  consigliato: number;
  libero: number;
  total: number;
}

export const useFetchGAPData = (token: string | undefined | null) => {
  const [data, setData] = useState<DataItem[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);
  const [reFetchIndicator] = useRecoilState(reFetchIndicatorGAPControlli);
  const [averageControlValue2, setAverageControlValue2] = useRecoilState(GAP_control_value_2_avarage);
  const [chartData5, setChartData5] = useRecoilState(GAP_chartDataState5);
  const [totalControlValue2, setTotalControlValue2] = useRecoilState(GAP_control_value_2_total);
  const [totalControlValue25, setTotalControlValue25] = useRecoilState(GAP_control_value_2_total_5);

  const [totalControlValue004, setTotalControlValue004] = useRecoilState(GAP_control_value_2_total_004);
  const [totalControlValue0514, setTotalControlValue0514] = useRecoilState(GAP_control_value_2_total_0514);
  const [totalControlValue1524, setTotalControlValue1524] = useRecoilState(GAP_control_value_2_total_1524);
  const [totalControlValue2534, setTotalControlValue2534] = useRecoilState(GAP_control_value_2_total_2534);
  const [totalControlValue3544, setTotalControlValue3544] = useRecoilState(GAP_control_value_2_total_3544);
  const [totalControlValue455, setTotalControlValue455] = useRecoilState(GAP_control_value_2_total_455);

  const [dutyGroups, setDutyGroups] = useRecoilState(averageDutyGroupsState);

  const fetchData = async () => {
    setIsLoading(true);
    try {
      if (!token) throw new Error("User is not authenticated");

      const dataResponse = await fetch(`${URL_GAP_Analysis}`, {
        headers: { Authorization: `Bearer ${token}` },
      });

      if (!dataResponse.ok) {
        throw new Error(`API request failed: ${dataResponse.statusText}`);
      }

      const responseData = await dataResponse.json();

      if (responseData && Array.isArray(responseData)) {
        const transformedData = responseData.map((item) => ({
          id: item.id,
          control_id: item.control_id,
          control_category: item.control_category,
          control_description: item.control_description,
          control_duty: item.control_duty,
          control_priority: item.control_priority,
          control_value_1: item.control_value_1,
          control_value_2: item.control_value_2,
          control_note_1: item.control_note_1,
          control_note_2: item.control_note_2,
          control_note_3: item.control_note_3,
          has_documents_folder_link: item.has_documents_folder_link,
        }));

        setData(transformedData);

        // CHART 1
        const totalObjects = transformedData.length;
        let totalValue2WithFive = 0;

        transformedData.forEach((item) => {
          if (item.control_value_2 === 5) {
            totalValue2WithFive += 1; // Correctly increment the count
          }
        });

        setTotalControlValue2(totalObjects);
        setTotalControlValue25(totalValue2WithFive);

        //CHART 2
        const totalValue2 = transformedData.reduce((acc, item) => acc + item.control_value_2, 0);
        const averageValue2 = transformedData.length > 0 ? totalValue2 / transformedData.length : 0;

        //CHART 3

        let countRange0to04 = 0;
        let countRange05to14 = 0;
        let countRange15to24 = 0;
        let countRange25to34 = 0;
        let countRange35to44 = 0;
        let countRange45to5 = 0;

        transformedData.forEach((item) => {
          const roundedValue = parseFloat(item.control_value_2.toFixed(2)); // Round to two decimals

          if (roundedValue >= 0 && roundedValue <= 0.49) {
            countRange0to04 += 1;
          } else if (roundedValue >= 0.5 && roundedValue <= 1.49) {
            countRange05to14 += 1;
          } else if (roundedValue >= 1.5 && roundedValue <= 2.49) {
            countRange15to24 += 1;
          } else if (roundedValue >= 2.5 && roundedValue <= 3.49) {
            countRange25to34 += 1;
          } else if (roundedValue >= 3.5 && roundedValue <= 4.49) {
            countRange35to44 += 1;
          } else if (roundedValue >= 4.5 && roundedValue <= 5) {
            countRange45to5 += 1;
          }
        });

        setTotalControlValue004(countRange0to04);
        setTotalControlValue0514(countRange05to14);
        setTotalControlValue1524(countRange15to24);
        setTotalControlValue2534(countRange25to34);
        setTotalControlValue3544(countRange35to44);
        setTotalControlValue455(countRange45to5);

        //CHART 4

        // Define types for control_duty and control_priority
        type ControlDuty = "obbligatorio" | "consigliato" | "libero";
        type ControlPriority = "nd" | "bassa" | "media" | "alta";

        interface GroupStats {
          sum: number;
          count: number;
        }

        const dutyGroups: Record<ControlDuty, Record<ControlPriority, GroupStats>> = {
          obbligatorio: { nd: { sum: 0, count: 0 }, bassa: { sum: 0, count: 0 }, media: { sum: 0, count: 0 }, alta: { sum: 0, count: 0 } },
          consigliato: { nd: { sum: 0, count: 0 }, bassa: { sum: 0, count: 0 }, media: { sum: 0, count: 0 }, alta: { sum: 0, count: 0 } },
          libero: { nd: { sum: 0, count: 0 }, bassa: { sum: 0, count: 0 }, media: { sum: 0, count: 0 }, alta: { sum: 0, count: 0 } },
        };

        transformedData.forEach((item) => {
          const priority = item.control_priority.toLowerCase() as ControlPriority; // Cast to ControlPriority
          const duty = item.control_duty.toLowerCase() as ControlDuty; // Cast to ControlDuty

          if (dutyGroups[duty]) {
            if (priority in dutyGroups[duty]) {
              dutyGroups[duty][priority].sum += item.control_value_2;
              dutyGroups[duty][priority].count += 1;
            }
          }
        });

        // Convert sums and counts to averages
        const averageDutyGroups = Object.keys(dutyGroups).reduce((acc, duty) => {
          acc[duty as ControlDuty] = Object.keys(dutyGroups[duty as ControlDuty]).reduce((priorityAcc, priority) => {
            const stats = dutyGroups[duty as ControlDuty][priority as ControlPriority];
            priorityAcc[priority as ControlPriority] = stats.count > 0 ? stats.sum / stats.count : 0;
            return priorityAcc;
          }, {} as Record<ControlPriority, number>);
          return acc;
        }, {} as Record<ControlDuty, Record<ControlPriority, number>>);

        setDutyGroups(averageDutyGroups);

        //CHART 5

        const categoryData = transformedData.reduce<Record<string, { obbligatorio: number; consigliato: number; libero: number; total: number; totalValue2: number }>>((acc, item) => {
          if (!acc[item.control_category]) {
            acc[item.control_category] = { obbligatorio: 0, consigliato: 0, libero: 0, total: 0, totalValue2: 0 };
          }

          acc[item.control_category].total += 1;
          acc[item.control_category].totalValue2 += item.control_value_2; // Sum up the control_value_2

          if (item.control_duty === "obbligatorio") {
            acc[item.control_category].obbligatorio += 1;
          } else if (item.control_duty === "consigliato") {
            acc[item.control_category].consigliato += 1;
          } else if (item.control_duty === "libero") {
            acc[item.control_category].libero += 1;
          }

          return acc;
        }, {});

        // Convert the categoryData object to an array format suitable for the chart
        const chartData = Object.entries(categoryData).map(([category, counts]) => {
          const truncatedCategory = category.length > 25 ? category.substring(0, 25) + "..." : category;

          // Calculate the average control_value_2 for this category
          const averageValue2 = counts.totalValue2 / counts.total;

          return {
            category: truncatedCategory,
            obbligatorio: (counts.obbligatorio / counts.total) * averageValue2, // scale by averageValue2
            consigliato: (counts.consigliato / counts.total) * averageValue2, // scale by averageValue2
            libero: (counts.libero / counts.total) * averageValue2, // scale by averageValue2
          };
        });

        setChartData5(chartData);

        setAverageControlValue2(parseFloat(averageValue2.toFixed(2)));
      } else {
        console.error("responseData.content is not an array or does not exist:", responseData);
      }
    } catch (err) {
      //setError(err instanceof Error ? err.message : "An unknown error occurred");
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchData();
  }, [token, reFetchIndicator]);

  return { data, isLoading, error, fetchData };
};

const Board27001Dashboard: React.FC = () => {
  const token = useToken();
  const [L1ID, setL1ID] = useRecoilState(SelectedL1ID);
  const [L2ID, setL2ID] = useRecoilState(SelectedL2ID);
  const [L3ID, setL3ID] = useRecoilState(SelectedL3ID);
  const [L4ID, setL4ID] = useRecoilState(SelectedL4ID);

  const [data, setData] = useState<DataItem[]>([]);
  const [selectedL1, setSelectedL1] = useState<string | null>(null);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const [isMessageBoxVisible, setIsMessageBoxVisible] = useState(false);

  const [isMessageBoxVisibleClosing, setIsMessageBoxVisibleClosing] = useRecoilState(isMessageBoxVisibleClosingRec);

  const { closeOverlay } = useContext(OverlayContext);

  const handleCloseAndNavigate = () => {
    setIsMessageBoxVisibleClosing(false); // Close the message box
    closeOverlay();
  };
  const [reFetchIndicator, setRefetchIndicator] = useRecoilState(reFetchIndicatorGAPControlli);
  const [averageControlValue2, setAverageControlValue2] = useRecoilState(GAP_control_value_2_avarage);
  // Initialize an array to collect all data across pages

  useEffect(() => {
    const fetchData = async () => {
      interface PlaceItem {
        id: string;
        creationTs: string;
        updateTs: string;
        createdBy: string;
        updatedBy: string;
        control_id: string;
        control_category: string;
        control_description: string;
        control_duty: string;
        control_priority: string;
        control_value_1: number;
        control_value_2: number;
        control_note_1: string;
        control_note_2: string;
        control_note_3: string;
        has_documents_folder_link: string;
      }

      setIsLoading(true);
      try {
        // Check for token existence before making the API call
        if (!token) throw new Error("User is not authenticated");

        // Fetch all data in one go using the total count
        const dataResponse = await fetch(`${URL_GAP_Analysis}`, {
          headers: { Authorization: `Bearer ${token}` },
        });

        if (!dataResponse.ok) {
          throw new Error(`API request failed: ${dataResponse.statusText}`);
        }

        const responseData = await dataResponse.json();

        // Assuming responseData.content is the array containing your data
        if (responseData && Array.isArray(responseData)) {
          const transformedData: DataItem[] = responseData.map((item: PlaceItem) => ({
            id: item.id,
            control_id: item.control_id,
            control_category: item.control_category,
            control_description: item.control_description,
            control_duty: item.control_duty,
            control_priority: item.control_priority,
            control_value_1: item.control_value_1,
            control_value_2: item.control_value_2,
            control_note_1: item.control_note_1,
            control_note_2: item.control_note_2,
            control_note_3: item.control_note_3,
            has_documents_folder_link: item.has_documents_folder_link,
            // Map other properties as needed
          }));

          console.log("Transformed Data:", transformedData);
          setData(transformedData);

          // Calculate the average control_value_2
          const totalValue2 = transformedData.reduce((acc, item) => acc + item.control_value_2, 0);
          const averageValue2 = transformedData.length > 0 ? totalValue2 / transformedData.length : 0;

          setAverageControlValue2(parseFloat(averageValue2.toFixed(2)));
        } else {
          console.error("responseData.content is not an array or does not exist:", responseData);
        }
      } catch (err) {
        // Error handling
        setError(err instanceof Error ? err.message : "An unknown error occurred");
        setIsMessageBoxVisible(true);
      } finally {
        setIsLoading(false);
      }
    };

    fetchData();
  }, [token, reFetchIndicator]); // Depend on token

  const { handleDelete, showDeleteConfirmation, isConfirmDialogVisible, setIsConfirmDialogVisible } = useDeletePlace();

  return (
    <div>
      {isMessageBoxVisible && (
        <Backdrop>
          <MessageContainer>
            <p>
              <MessageboxText>{error}</MessageboxText>
            </p>
            <CloseMessageButton onClick={() => setIsMessageBoxVisible(false)}>
              <MessageboxText>Close</MessageboxText>
            </CloseMessageButton>
          </MessageContainer>
        </Backdrop>
      )}

      {isMessageBoxVisibleClosing && (
        <Backdrop>
          <MessageContainer>
            <p>
              <MessageboxText>{error}</MessageboxText>
            </p>
            <CloseMessageButton onClick={handleCloseAndNavigate}>
              <MessageboxText>Close</MessageboxText>
            </CloseMessageButton>
          </MessageContainer>
        </Backdrop>
      )}

      {isLoading && (
        <BackdropTransparent>
          <MessageContainer>
            <MessageboxText>Loading...</MessageboxText>
          </MessageContainer>
        </BackdropTransparent>
      )}

      {error && (
        <Backdrop>
          <MessageContainer>
            <p>
              <MessageboxText>{error}</MessageboxText>
            </p>
            <CloseMessageButton
              onClick={() => {
                setIsMessageBoxVisible(false);
                setError(null);
              }}
            >
              <MessageboxText>Close</MessageboxText>
            </CloseMessageButton>
          </MessageContainer>
        </Backdrop>
      )}

      {isConfirmDialogVisible && (
        <Backdrop>
          <MessageContainer>
            <p>
              <MessageboxText>Sei sicuro di voler rimuovere questo controllo?</MessageboxText>
            </p>
            <div style={{ display: "flex", justifyContent: "center", gap: "20px" }}>
              <CloseMessageButton onClick={() => setIsConfirmDialogVisible(false)}>
                <MessageboxText>No</MessageboxText>
              </CloseMessageButton>
              <CloseMessageButton onClick={handleDelete}>
                <MessageboxText>Rimuovi</MessageboxText>
              </CloseMessageButton>
            </div>
          </MessageContainer>
        </Backdrop>
      )}

      <DataTable data={data} />
    </div>
  );
};

export default Board27001Dashboard;
