import InfoIcon from "@mui/icons-material/InfoOutlined";
import { Tooltip } from "@mui/material";
import React from "react";
import { useRecoilState } from "recoil";
import {
  CreaContainerColumn,
  DescriptionContainerCreaMultiple,
  DescriptionContainerCreaText,
  FlexContainerCrea,
  IconContainer,
  IconSquare,
  InputContainer,
  InputDescriptionText,
  InputTextbox,
  OverlayComponentWhiteAdd1Multiple
} from "../../cybersecurity-board-contestoleadership-organizzazione-sub/cybersecurity-board-organizzazione-organigramma-styled-components";
import { TAB_DESCRIPTION_GLOBAL, TAB_DESCRIPTION_PARTI_1 } from "../../tabs-description";
import {
  PartiSchedaGeneraleEmail,
  PartiSchedaGeneraleFax,
  PartiSchedaGeneraleIndirizzo,
  PartiSchedaGeneraleIVA,
  PartiSchedaGeneraleNomeAbbreviato,
  PartiSchedaGeneraleNomeCompleto,
  PartiSchedaGeneraleReferente,
  PartiSchedaGeneraleTelefono,
} from "../Parti-Add-recoil";

const MainComponent = () => {
  const [nomeCompleto, setNomeCompleto] = useRecoilState(PartiSchedaGeneraleNomeCompleto);
  const [nomeAbbreviato, setNomeAbbreviato] = useRecoilState(PartiSchedaGeneraleNomeAbbreviato);
  const [Email, setEmail] = useRecoilState(PartiSchedaGeneraleEmail);
  const [Telefono, setTelefono] = useRecoilState(PartiSchedaGeneraleTelefono);
  const [Fax, setFax] = useRecoilState(PartiSchedaGeneraleFax);
  const [IVA, setIVA] = useRecoilState(PartiSchedaGeneraleIVA);
  const [Referente, setReferente] = useRecoilState(PartiSchedaGeneraleReferente);
  const [Indirizzo, setIndirizzo] = useRecoilState(PartiSchedaGeneraleIndirizzo);

  return (
    <OverlayComponentWhiteAdd1Multiple>
      <FlexContainerCrea>
        <CreaContainerColumn>
          <InputContainer>
            <InputDescriptionText>
              Ragione sociale
              <Tooltip title="Campo obbligatorio">
                <InfoIcon
                  style={{
                    fontSize: "14px",
                    color: "#04446c",
                    cursor: "pointer",
                    marginLeft: "2px",
                  }}
                />
              </Tooltip>
            </InputDescriptionText>
            <InputTextbox placeholder="" value={nomeCompleto} onChange={(e) => setNomeCompleto(e.target.value)} />
            <IconContainer />
          </InputContainer>

          <InputContainer>
            <InputDescriptionText>Nome abbreviato</InputDescriptionText>
            <InputTextbox placeholder="" value={nomeAbbreviato} onChange={(e) => setNomeAbbreviato(e.target.value)} />
            <IconContainer />
          </InputContainer>

          <InputContainer>
            <InputDescriptionText>Sito web</InputDescriptionText>
            <InputTextbox placeholder="" value={Indirizzo} onChange={(e) => setIndirizzo(e.target.value)} />
            <IconContainer />
          </InputContainer>

          <InputContainer>
            <InputDescriptionText>
              C.F./P.IVA
              <Tooltip title="Campo obbligatorio">
                <InfoIcon
                  style={{
                    fontSize: "14px",
                    color: "#04446c",
                    cursor: "pointer",
                    marginLeft: "2px",
                  }}
                />
              </Tooltip>
            </InputDescriptionText>
            <InputTextbox placeholder="" value={IVA} onChange={(e) => setIVA(e.target.value)} />
            <IconContainer />
          </InputContainer>
        </CreaContainerColumn>

        <CreaContainerColumn>
          <InputContainer>
            <InputDescriptionText>Telefono</InputDescriptionText>
            <InputTextbox placeholder="" value={Telefono} onChange={(e) => setTelefono(e.target.value)} />
            <IconContainer />
          </InputContainer>

          <InputContainer>
            <InputDescriptionText>Fax</InputDescriptionText>
            <InputTextbox placeholder="" value={Fax} onChange={(e) => setFax(e.target.value)} />
            <IconContainer />
          </InputContainer>

          <InputContainer>
            <InputDescriptionText>Email</InputDescriptionText>

            <InputTextbox placeholder="" value={Email} onChange={(e) => setEmail(e.target.value)} />

            <IconContainer />
          </InputContainer>

          <InputContainer>
            <InputDescriptionText>Referente</InputDescriptionText>
            <InputTextbox placeholder="" value={Referente} onChange={(e) => setReferente(e.target.value)} />
            <IconContainer />
          </InputContainer>
        </CreaContainerColumn>
      </FlexContainerCrea>
      <DescriptionContainerCreaMultiple>
        <IconSquare src="/crea-L1-schedagenerale.svg" alt="Description Icon" />
        <DescriptionContainerCreaText>{TAB_DESCRIPTION_PARTI_1 ? TAB_DESCRIPTION_PARTI_1 : TAB_DESCRIPTION_GLOBAL}</DescriptionContainerCreaText>
      </DescriptionContainerCreaMultiple>
    </OverlayComponentWhiteAdd1Multiple>
  );
};

export default MainComponent;
