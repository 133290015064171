import UploadFileIcon from "@mui/icons-material/UploadFile";
import React, { useCallback, useEffect, useRef, useState } from "react";
import { useRecoilState, useRecoilValue } from "recoil";
import {
  AttachedFilesList,
  CreaContainerColumn,
  DescriptionContainerCreaMultiple,
  DescriptionContainerCreaText,
  FileListScrollbar,
  FlexContainerCrea,
  IconContainer,
  IconSquare,
  IconsGroup,
  InputButtonText,
  InputContainer,
  InputDescriptionText,
  InputTextbox,
  InputTextboxButton,
  OverlayComponentWhiteAdd1Multiple,
  TriangleContainer,
} from "../cybersecurity-board-organizzazione-organigramma-styled-components";

import CancelIcon from "@mui/icons-material/Cancel";
import DownloadIcon from "@mui/icons-material/Download";
import { IconButton } from "@mui/material";
import { URL_FileManager, URL_FileManagerList, useApi, useToken } from "../../../../../API";
import {
  ListContainer,
  ListDescriptionText,
  ListScrollbar,
  ListTextbox,
} from "../../cybersecurity-board-contestoleadership-infrastruttura-sub/cybersecurity-board-infrastruttura-organigramma-styled-components";
import { addL4AttachedFilesInfo, addL4DocumentsAttached, addL4fiscalCode } from "../L4-Add-recoil";
import { FileListItem, editL4DocPath, editL4FileListItemState } from "../L4-Edit-recoil";

export const useUploadFile = () => {
  const { post } = useApi();

  const upload = useCallback(
    async (dir: string, file: File, fileName: string) => {
      const formData = new FormData();
      formData.append("file", file, fileName);

      const url = `${URL_FileManager}${encodeURIComponent(dir)}&file=${encodeURIComponent(fileName)}`;

      try {
        const response = await post(url, formData);
        return response;
      } catch (error) {
        console.error(`Failed to upload file: ${fileName}`, error);
        throw error;
      }
    },
    [post]
  );

  return upload;
};

export const useHandleFileUpload = () => {
  const uploadFile = useUploadFile();

  const handleFileUpload = useCallback(
    async (selectedFiles: File[] | null, dir = "directory") => {
      if (selectedFiles) {
        const uploadPromises = selectedFiles.map((file) => uploadFile(dir, file, file.name));

        try {
          await Promise.all(uploadPromises);
          console.log("All files uploaded successfully");
        } catch (error) {
          console.error("Error uploading one or more files:", error);
        }
      }
    },
    [uploadFile]
  );

  return handleFileUpload;
};

export const useInitiateUpload = () => {
  const docAttached = useRecoilValue(addL4DocumentsAttached);
  const docPath = useRecoilValue(addL4fiscalCode);
  const handleFileUpload = useHandleFileUpload();

  const initiateUpload = useCallback(async () => {
    console.log("Files to upload:", docAttached);

    if (docAttached && docAttached.length > 0) {
      try {
        await handleFileUpload(docAttached, docPath);
        console.log("File(s) uploaded successfully.");
      } catch (error) {
        console.error("Error uploading file(s):", error);
      }
    } else {
      console.log("No files selected for upload.");
    }
  }, [docAttached, handleFileUpload]);

  return initiateUpload;
};

export const handleDownload = async (token: string | undefined | null, dir: string, fileName: string) => {
  const url = `${URL_FileManager}${encodeURIComponent(dir)}&file=${encodeURIComponent(fileName)}`;

  try {
    const response = await fetch(url, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });

    if (!response.ok) {
      const errorHeader = response.headers.get("X-App-Error");
      console.error("Error header:", errorHeader);
      throw new Error(`API request failed: ${response.statusText}`);
    }

    response.headers.forEach((value, key) => {
      console.log(`Response header: ${key} = ${value}`);
    });

    const data = await response.blob();
    const downloadUrl = window.URL.createObjectURL(data);
    const link = document.createElement("a");
    link.href = downloadUrl;
    link.download = fileName;
    document.body.appendChild(link);
    link.click();
    window.URL.revokeObjectURL(downloadUrl);
    document.body.removeChild(link);
  } catch (error) {
    console.error("Error downloading file:", error);
  }
};

const useFetchDirectoryContents = (docPath: string, token: string | null | undefined) => {
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const [directoryContents, setDirectoryContents] = useRecoilState(editL4FileListItemState);

  useEffect(() => {
    const fetchDirectoryContents = async () => {
      setIsLoading(true);
      setError(null);

      try {
        const response = await fetch(`${URL_FileManagerList}${docPath}`, {
          method: "GET",
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });

        if (!response.ok) {
          throw new Error("Failed to fetch directory contents");
        }

        const filePaths: string[] = await response.json();
        const transformedData: FileListItem[] = filePaths.map((filePath, index) => ({
          id: `file-${index}`,
          path: filePath,
          name: filePath.split("/").pop() || "",
        }));

        setDirectoryContents(transformedData);
      } catch (error: unknown) {
        if (error instanceof Error) {
          setError(error.message);
        } else {
          setError("An unexpected error occurred");
        }
      } finally {
        setIsLoading(false);
      }
    };

    fetchDirectoryContents();
  }, [docPath, token]);

  return { directoryContents };
};

const MainComponent = () => {
  const [, setDirectoryContents] = useState<FileListItem[]>([]);
  const token = useToken();

  const [docPath, setDocPath] = useRecoilState(editL4DocPath);
  const [docAttached, setDocAttached] = useRecoilState(addL4DocumentsAttached);
  const [filesInfo, setFilesInfo] = useRecoilState(addL4AttachedFilesInfo);
  const { directoryContents } = useFetchDirectoryContents(docPath, token);
  const fileInputRef = useRef<HTMLInputElement>(null);

  const removeFile = (index: number) => {
    setDocAttached((currentFiles) => currentFiles.filter((_, i) => i !== index));
    setFilesInfo((currentFilesInfo) => currentFilesInfo.filter((_, i) => i !== index));
  };

  const handleButtonClick = () => {
    if (fileInputRef.current !== null) {
      fileInputRef.current.value = "";
      fileInputRef.current.click();
    }
  };

  const extractDir = (filePath: string) => {
    const pathParts = filePath.split("/");
    pathParts.pop();
    return pathParts.join("/");
  };

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const files = event.target.files;
    if (files) {
      const newFileList = Array.from(files);

      setDocAttached((currentFiles) => [...currentFiles, ...newFileList]);

      const newFileInfoArray = newFileList.map((file) => ({
        name: file.name,
        size: file.size,
        type: file.type,
      }));
      console.log("File info: ", newFileInfoArray);

      setFilesInfo((currentFilesInfo) => [...currentFilesInfo, ...newFileInfoArray]);
    }
  };

  const handleDelete = async (token: string | undefined | null, dir: string, fileName: string) => {
    const url = `${URL_FileManager}${encodeURIComponent(dir)}&file=${encodeURIComponent(fileName)}`;

    try {
      const response = await fetch(url, {
        method: "DELETE",
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      if (!response.ok) {
        throw new Error(`Failed to delete file: ${response.statusText}`);
      }

      console.log("File deleted successfully:", fileName);
    } catch (error) {
      console.error("Error deleting file:", error);
    }
  };

  return (
    <OverlayComponentWhiteAdd1Multiple>
      <FlexContainerCrea>
        <CreaContainerColumn>
          <ListContainer>
            <ListDescriptionText>Documentazione</ListDescriptionText>
            <ListTextbox>
              <ListScrollbar>
                {directoryContents.map((file) => (
                  <li
                    key={file.id}
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                    }}
                  >
                    <span>{file.name}</span>
                    <IconsGroup>
                      <IconButton onClick={() => handleDownload(token, extractDir(file.path), file.name)} size="small" style={{ marginLeft: "auto" }}>
                        <DownloadIcon style={{ fontSize: "14px", color: "#cccccc" }} />
                      </IconButton>
                      <IconButton onClick={() => handleDelete(token, extractDir(file.path), file.name)} size="small" style={{ marginLeft: "auto" }}>
                        <CancelIcon style={{ fontSize: "14px", color: "#cccccc" }} />
                      </IconButton>
                    </IconsGroup>
                  </li>
                ))}
              </ListScrollbar>
            </ListTextbox>

            {/* <IconContainerList /> */}
          </ListContainer>
        </CreaContainerColumn>

        <CreaContainerColumn>
          <InputContainer>
            <InputDescriptionText>Nome cartella documentazione</InputDescriptionText>
            <InputTextbox disabled={true} placeholder="" value={docPath} />
            <IconContainer />
          </InputContainer>

          <InputContainer>
            <InputDescriptionText>Allega documenti</InputDescriptionText>
            <input style={{ display: "none" }} ref={fileInputRef} type="file" multiple onChange={handleFileChange} />

            <InputTextboxButton onClick={handleButtonClick}>
              {" "}
              <InputButtonText>Seleziona file dal computer</InputButtonText>{" "}
            </InputTextboxButton>
            <TriangleContainer>▼</TriangleContainer>
            <IconContainer />
          </InputContainer>

          {filesInfo.length > 0 && (
            <AttachedFilesList>
              <FileListScrollbar>
                {filesInfo.map(
                  (file, index) =>
                    file && (
                      <li
                        key={index}
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                          alignItems: "center",
                        }}
                      >
                        <UploadFileIcon
                          style={{
                            fontSize: "18px",
                            color: "#cccccc",
                            marginRight: "10px",
                          }}
                        />
                        <span>{file.name}</span>

                        <IconButton onClick={() => removeFile(index)} size="small" style={{ marginLeft: "auto" }}>
                          <CancelIcon style={{ fontSize: "14px", color: "#cccccc" }} />
                        </IconButton>
                      </li>
                    )
                )}
              </FileListScrollbar>
            </AttachedFilesList>
          )}

          <div>{/* <button onClick={handleDownload}>Download File</button> */}</div>
          {/* <button onClick={initiateUpload}>Upload Files</button> */}
        </CreaContainerColumn>
      </FlexContainerCrea>
      <DescriptionContainerCreaMultiple>
        <IconSquare src="/description_show_docs.svg" alt="Description Icon" />
        <DescriptionContainerCreaText>
          Nella scheda è descritto il percorso della cartella principale dove archiviare i documenti dell’entità selezionata e utilizzare la funzione di allegato per inserire direttamente la
          documentazione rilevante per la compliance in Cybersecurity in riferimento all’entità, garantendo così un accesso immediato e organizzato ai file essenziali.
        </DescriptionContainerCreaText>
      </DescriptionContainerCreaMultiple>
    </OverlayComponentWhiteAdd1Multiple>
  );
};

export default MainComponent;
