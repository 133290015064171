import React, { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { OverlayContext } from "../cybersecurity-board-contestoleadership-organizzazione";

import { ButtonGreen, ButtonGrey, ButtonText } from "../../styled-components";

import { URL_DirectorList, URL_LV1_Detailed, useToken } from "../../../../API";
import {
  Backdrop,
  BackdropTransparent,
  CloseMessageButton,
  CreaContainerColumn,
  CreaSubMenuButton,
  DescriptionContainerCrea,
  DescriptionContainerCreaText,
  FlexContainerCrea,
  FrameTopGradient100,
  IconContainer,
  IconSquare,
  InputContainer,
  InputDescriptionText,
  InputTextbox,
  MessageboxText,
  MessageContainer,
  OverlayComponentBackgroundAdd1,
  OverlayComponentWhiteAdd1,
  OverlayComponentWhiteButtonFrameAdd1,
  OverlayComponentWhiteTitleAdd1,
  SubMenuButtonContainerOrganigrammaNoScroll,
} from "./cybersecurity-board-organizzazione-organigramma-styled-components";

const useApi = () => {
  const token = useToken();

  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);

  const post = async (url: string, data: any) => {
    if (!token) {
      throw new Error("User is not authenticated");
    }
    try {
      const response = await fetch(url, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify(data),
      });

      let responseData;
      try {
        responseData = await response.json();
      } catch (parseError) {
        if (response.ok) {
          return null;
        } else {
          throw new Error(`API request failed: ${response.statusText}`);
        }
      }

      if (!response.ok) {
        console.error("Error response data:", responseData);

        throw new Error(`API request failed: ${response.statusText}`, responseData);
      }

      return responseData;
    } catch (error) {
      throw error;
    }
  };

  const patch = async (url: string, data: any) => {
    if (!token) {
      throw new Error("User is not authenticated");
    }
    try {
      const response = await fetch(url, {
        method: "PATCH",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify(data),
      });

      let responseData;
      try {
        responseData = await response.json();
      } catch (parseError) {
        if (response.ok) {
          return null;
        } else {
          throw new Error(`API request failed: ${response.statusText}`);
        }
      }

      if (!response.ok) {
        console.error("Error response data:", responseData);

        throw new Error(`API request failed: ${response.statusText}`, responseData);
      }

      return responseData;
    } catch (error) {
      throw error;
    }
  };

  const get = async (url: string) => {
    if (!token) throw new Error("User is not authenticated");

    const response = await fetch(url, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });

    if (!response.ok) {
      throw new Error(`API request failed: ${response.statusText}`);
    }

    return await response.json();
  };

  return { post, get, patch };
};

const YourEditL1Component = () => {
  const { selectedL1, setSelectedL1 } = useContext(OverlayContext);

  const { closeOverlay } = useContext(OverlayContext);

  const navigate = useNavigate();

  const token = useToken();
  const [data, setData] = useState<DirectorDataItem[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const [isMessageBoxVisible, setIsMessageBoxVisible] = useState(false);
  const [message, setMessage] = useState("");

  const { post } = useApi();
  const { get } = useApi();
  const { patch } = useApi();

  const [Id, setSiteId] = useState("");
  const [siteName, setSiteName] = useState("");
  const [siteShortName, setSiteShortName] = useState("");
  const [director, setDirector] = useState("");
  const [directorId, setDirectorId] = useState("");
  const [address, setAddress] = useState("");
  const [telephone, setTelephone] = useState("");
  const [fax, setFax] = useState("");

  const handleSave = async () => {
    const newSiteData = {
      id: selectedL1,
      site_name: siteName,
      site_short_name: siteShortName,
      fax: fax,
      site_telephone: telephone,
      has_site_responsible: directorId,
      address: {
        street_address: address,
      },
    };

    console.log(JSON.stringify(newSiteData));

    try {
      setIsLoading(true);

      const responseData = await patch(URL_LV1_Detailed, newSiteData);

      console.log("Site created successfully:", responseData);
      setMessage("Site created successfully!");
      setIsLoading(false);
      setIsMessageBoxVisible(true);

      setSiteName("");
      setSiteShortName("");
      setDirector("");
      setAddress("");
      setTelephone("");
      setFax("");
    } catch (error) {
      console.error("Error saving site:", error);
      setMessage(`Error saving site: ${error instanceof Error ? error.message : "Unknown error"}`);
      setIsLoading(false);
      setIsMessageBoxVisible(true);
    }
  };

  useEffect(() => {
    const fetchSiteDetails = async () => {
      if (!token) throw new Error("User is not authenticated");

      setIsLoading(true);

      try {
        const siteDetails = await get(`${URL_LV1_Detailed}/${selectedL1}`);

        const directorFullName = `${siteDetails.site_responsible.surname} ${siteDetails.site_responsible.name} `;
        setDirector(directorFullName);
        setSiteId(siteDetails.id);
        setSiteName(siteDetails.site_name);
        setSiteShortName(siteDetails.site_short_name);
        setDirector(directorFullName);
        setAddress(siteDetails.address.street_address);
        setTelephone(siteDetails.site_telephone);
        setFax(siteDetails.fax);
        setDirectorId(siteDetails.has_site_responsible);
      } catch (error) {
        console.error("Error fetching site details:", error);

        setError(`Error fetching site details: ${error instanceof Error ? error.message : "Unknown error"}`);
      } finally {
        setIsLoading(false);
      }
    };

    fetchSiteDetails();
  }, []);

  type DirectorApiContentItem = {
    id: string;
    alias: string;
  };

  type DirectorDataItem = {
    director_id: string;
    director_name: string;
  };

  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true);
      try {
        if (!token) throw new Error("User is not authenticated");

        const response = await fetch(URL_DirectorList, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });

        if (!response.ok) {
          throw new Error(`API request failed: ${response.statusText}`);
        }

        const responseData = await response.json();
        console.log("Response Data:", responseData);
        console.log("Selected L1", selectedL1);

        const transformedData: DirectorDataItem[] = responseData.map((item: DirectorApiContentItem) => {
          return {
            director_id: item.id,
            director_name: item.alias,
          };
        });

        console.log("Transformed Data:", transformedData);
        setData(transformedData);
      } catch (err) {
        setError(err instanceof Error ? err.message : "An unknown error occurred");
        setIsMessageBoxVisible(true);
      } finally {
        setIsLoading(false);
      }
    };

    fetchData();
  }, [token]);

  const directorNameToId = new Map(data.map((item) => [item.director_name, item.director_id]));

  const handleDirectorChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;
    setDirector(value);

    if (directorNameToId.has(value)) {
      setDirectorId(directorNameToId.get(value) || "");
      console.log(directorNameToId.get(value) || "");
    }
  };

  const handleCloseAndNavigate = () => {
    setIsMessageBoxVisible(false);
    closeOverlay();
  };

  return (
    <OverlayComponentBackgroundAdd1>
      {isLoading && (
        <BackdropTransparent>
          <MessageContainer>
            <MessageboxText>Loading...</MessageboxText>
          </MessageContainer>
        </BackdropTransparent>
      )}

      {isMessageBoxVisible && (
        <Backdrop>
          <MessageContainer>
            <p>
              <MessageboxText>{message}</MessageboxText>
            </p>
            <CloseMessageButton onClick={handleCloseAndNavigate}>
              <MessageboxText>Close</MessageboxText>
            </CloseMessageButton>
          </MessageContainer>
        </Backdrop>
      )}

      {error && (
        <Backdrop>
          <MessageContainer>
            <p>
              <MessageboxText>{error}</MessageboxText>
            </p>
            <CloseMessageButton
              onClick={() => {
                setIsMessageBoxVisible(false);
                setError(null);
                closeOverlay();
              }}
            >
              <MessageboxText>Close</MessageboxText>
            </CloseMessageButton>
          </MessageContainer>
        </Backdrop>
      )}

      <FrameTopGradient100 />
      <OverlayComponentWhiteTitleAdd1>NUOVA COMPONENTE ORGANIZZATIVA</OverlayComponentWhiteTitleAdd1>
      <OverlayComponentWhiteAdd1>
        <SubMenuButtonContainerOrganigrammaNoScroll>
          <CreaSubMenuButton to="/Cybersecurity/Organizzazione/Organigramma/ModificaL1">Scheda generale</CreaSubMenuButton>
          {/* <CreaSubMenuButton to="/Cybersecurity/Organizzazione/Organigramma/CreaL1">Unità operative</CreaSubMenuButton>
    <CreaSubMenuButton to="/Cybersecurity/Organizzazione/Organigramma/CreaL1">Ruoli</CreaSubMenuButton>
    <CreaSubMenuButton to="/Cybersecurity/Organizzazione/Organigramma/CreaL1">Personale</CreaSubMenuButton> */}
        </SubMenuButtonContainerOrganigrammaNoScroll>

        <FlexContainerCrea>
          <CreaContainerColumn>
            <InputContainer>
              <InputDescriptionText>Nome completo</InputDescriptionText>
              <InputTextbox placeholder="" value={siteName} onChange={(e) => setSiteName(e.target.value)} />
              <IconContainer />
            </InputContainer>

            <InputContainer>
              <InputDescriptionText>Nome abbreviato</InputDescriptionText>
              <InputTextbox placeholder="" value={siteShortName} onChange={(e) => setSiteShortName(e.target.value)} />
              <IconContainer />
            </InputContainer>

            <InputContainer>
              <InputDescriptionText>Direttore</InputDescriptionText>

              <InputTextbox list="directors-list" value={director} onChange={handleDirectorChange} placeholder="Seleziona" />
              <datalist id="directors-list">
                {data.map((item, index) => (
                  <option key={index} value={item.director_name} />
                ))}
              </datalist>
              <IconContainer />
            </InputContainer>
          </CreaContainerColumn>

          <CreaContainerColumn>
            <InputContainer>
              <InputDescriptionText>Indirizzo sede principale</InputDescriptionText>
              <InputTextbox placeholder="" value={address} onChange={(e) => setAddress(e.target.value)} />
              <IconContainer />
            </InputContainer>

            <InputContainer>
              <InputDescriptionText>Telefono</InputDescriptionText>
              <InputTextbox placeholder="" value={telephone} onChange={(e) => setTelephone(e.target.value)} />
              <IconContainer />
            </InputContainer>
            <InputContainer>
              <InputDescriptionText>Fax</InputDescriptionText>
              <InputTextbox placeholder="" value={fax} onChange={(e) => setFax(e.target.value)} />
              <IconContainer />
            </InputContainer>
          </CreaContainerColumn>
        </FlexContainerCrea>

        <DescriptionContainerCrea>
          <IconSquare src="/crea-L1-schedagenerale.svg" alt="Description Icon" />
          <DescriptionContainerCreaText>
            Inserire il nome completo della Componente Organizzativa per garantire un riconoscimento formale, utilizzare un nome abbreviato per facilitare la comunicazione interna, selezionare il
            Direttore a capo, fornire l'indirizzo completo e aggiungere altri contatti per assicurare una comunicazione efficace e diretta.
          </DescriptionContainerCreaText>
        </DescriptionContainerCrea>
      </OverlayComponentWhiteAdd1>

      <OverlayComponentWhiteButtonFrameAdd1>
        <ButtonGrey onClick={closeOverlay}>
          <ButtonText>Annulla</ButtonText>
        </ButtonGrey>
        <ButtonGreen onClick={handleSave}>
          <ButtonText>Salva</ButtonText>
        </ButtonGreen>
      </OverlayComponentWhiteButtonFrameAdd1>
    </OverlayComponentBackgroundAdd1>
  );
};

export default YourEditL1Component;
