import React from "react";
import { useRecoilState } from "recoil";
import {
  addL1SchedaGeneraleIndirizzo,
  addL2SchedaGeneraleEmail,
  addL2SchedaGeneraleFax,
  addL2SchedaGeneraleNomeAbbreviato,
  addL2SchedaGeneraleNomeCompleto,
  addL2SchedaGeneralePEC,
  addL2SchedaGeneraleReferente,
  addL2SchedaGeneraleTelefono,
} from "../../../../../recoil";
import {
  CreaContainerColumn,
  DescriptionContainerCreaMultiple,
  DescriptionContainerCreaText,
  FlexContainerCrea,
  IconContainer,
  IconSquare,
  InputContainer,
  InputDescriptionText,
  InputTextbox,
  OverlayComponentWhiteAdd1Multiple
} from "../../cybersecurity-board-contestoleadership-organizzazione-sub/cybersecurity-board-organizzazione-organigramma-styled-components";

const MainComponent = () => {
  const [nomeCompleto, setNomeCompleto] = useRecoilState(addL2SchedaGeneraleNomeCompleto);
  const [nomeAbbreviato, setNomeAbbreviato] = useRecoilState(addL2SchedaGeneraleNomeAbbreviato);
  const [Email, setEmail] = useRecoilState(addL2SchedaGeneraleEmail);
  const [Telefono, setTelefono] = useRecoilState(addL2SchedaGeneraleTelefono);
  const [Fax, setFax] = useRecoilState(addL2SchedaGeneraleFax);
  const [PEC, setPEC] = useRecoilState(addL2SchedaGeneralePEC);
  const [Referente, setReferente] = useRecoilState(addL2SchedaGeneraleReferente);
  const [Indirizzo, setIndirizzo] = useRecoilState(addL1SchedaGeneraleIndirizzo);

  return (
    <OverlayComponentWhiteAdd1Multiple>
      <FlexContainerCrea>
        <CreaContainerColumn>
          <InputContainer>
            <InputDescriptionText>Nome completo</InputDescriptionText>
            <InputTextbox placeholder="" value={nomeCompleto} onChange={(e) => setNomeCompleto(e.target.value)} />
            <IconContainer />
          </InputContainer>

          <InputContainer>
            <InputDescriptionText>Nome abbreviato</InputDescriptionText>
            <InputTextbox placeholder="" value={nomeAbbreviato} onChange={(e) => setNomeAbbreviato(e.target.value)} />
            <IconContainer />
          </InputContainer>

          <InputContainer>
            <InputDescriptionText>Sito web</InputDescriptionText>
            <InputTextbox placeholder="" value={Indirizzo} onChange={(e) => setIndirizzo(e.target.value)} />
            <IconContainer />
          </InputContainer>

          <InputContainer>
            <InputDescriptionText>Referente</InputDescriptionText>
            <InputTextbox placeholder="" value={Referente} onChange={(e) => setReferente(e.target.value)} />
            <IconContainer />
          </InputContainer>
        </CreaContainerColumn>

        <CreaContainerColumn>
          <InputContainer>
            <InputDescriptionText>Telefono</InputDescriptionText>
            <InputTextbox placeholder="" value={Telefono} onChange={(e) => setTelefono(e.target.value)} />
            <IconContainer />
          </InputContainer>

          <InputContainer>
            <InputDescriptionText>Fax</InputDescriptionText>
            <InputTextbox placeholder="" value={Fax} onChange={(e) => setFax(e.target.value)} />
            <IconContainer />
          </InputContainer>

          <InputContainer>
            <InputDescriptionText>Email</InputDescriptionText>

            <InputTextbox placeholder="" value={Email} onChange={(e) => setEmail(e.target.value)} />

            <IconContainer />
          </InputContainer>
          <InputContainer>
            <InputDescriptionText>PEC</InputDescriptionText>
            <InputTextbox placeholder="" value={PEC} onChange={(e) => setPEC(e.target.value)} />
            <IconContainer />
          </InputContainer>
        </CreaContainerColumn>
      </FlexContainerCrea>
      <DescriptionContainerCreaMultiple>
        <IconSquare src="/crea-L1-schedagenerale.svg" alt="Description Icon" />
        <DescriptionContainerCreaText>
          Inserire i dettagli identificativi dell'unità operativa, comprensivi di un nome completo che rifletta le competenze e i servizi offerti, un nome abbreviato per una rapida identificazione,
          oltre ai principali canali di comunicazione per facilitare un contatto efficace e una pronta corrispondenza
        </DescriptionContainerCreaText>
      </DescriptionContainerCreaMultiple>
    </OverlayComponentWhiteAdd1Multiple>
  );
};

export default MainComponent;
