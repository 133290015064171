import dayjs from "dayjs";
import { atom } from "recoil";

export const showL4Title = atom({
  key: "showL4Title",
  default: "",
});

//Scheda generale

export const showL4Surname = atom({
  key: "showL4Surname",
  default: "",
});

export const showL4Name = atom({
  key: "showL4Name",
  default: "",
});

export const showL4birthDate = atom<dayjs.Dayjs | null>({
  key: "showL4birthDate",
  default: dayjs(),
});

export const showL4birthPlace = atom({
  key: "showL4birthPlace",
  default: "",
});

export const showL4gender = atom({
  key: "showL4gender",
  default: "",
});

export const showL4fiscalCode = atom({
  key: "showL4fiscalCode",
  default: "",
});

export const showL4residenceAddress = atom({
  key: "showL4residenceAddress",
  default: "",
});

export const showL4domicileAddress = atom({
  key: "showL4domicileAddress",
  default: "",
});

export const showL4workEmail = atom({
  key: "showL4workEmail",
  default: "",
});

export const showL4personalEmail = atom({
  key: "showL4personalEmail",
  default: "",
});

export const showL4workPhone = atom({
  key: "showL4workPhone",
  default: "",
});

export const showL4privatePhone = atom({
  key: "showL4privatePhone",
  default: "",
});

export const showL4idDocumentNumber = atom({
  key: "showL4idDocumentNumber",
  default: "",
});

//Organizzazione

export const showL4RoleDescription = atom({
  key: "showL4RoleDescription",
  default: "",
});

export const showL4Responsibility = atom({
  key: "showL4Responsibility",
  default: "",
});

export const showL4prefLabel = atom({
  key: "showL4prefLabel",
  default: "",
});

export const showL4isResponsible = atom({
  key: "showL4isResponsible",
  default: "",
});

export const showL4ComponenteOrganizzativa = atom({
  key: "showL4ComponenteOrganizzativa",
  default: "",
});

export const showL4SuperioreDiretto = atom({
  key: "showL4SuperioreDiretto",
  default: "",
});

export const showL4UnitaOperative = atom<string[]>({
  key: "showL4UnitaOperative", // Unique ID (with respect to other atoms/selectors)
  default: [], // Default value (aka initial value)
});

export const showL4RuoliEnte = atom<string[]>({
  key: "showL4RuoliEnte", // Unique ID (with respect to other atoms/selectors)
  default: [], // Default value (aka initial value)
});

//Infrastruttura

export const showL4Luoghi = atom<[]>({
  key: "showL4Luoghi", // Unique ID (with respect to other atoms/selectors)
  default: [], // Default value (aka initial value)
});

//Documentazione

export const showL4DocPath = atom({
  key: "showL4DocPath",
  default: "",
});

// Assuming addL1DocumentsAttached is defined to store File objects
export const showL4DocumentsAttached = atom<File[]>({
  key: "showL4DocumentsAttached",
  default: [],
});

export const showL4AttachedFilesInfo = atom<Array<{ name: string; size: number; type: string } | null>>({
  key: "showL4AttachedFilesInfo",
  default: [],
});

export const showL4selectedModelId = atom({
  key: "showL4selectedModelId",
  default: "",
});

export const showL4selectedModelName = atom({
  key: "showL4selectedModelName",
  default: "",
});

export const showL4selectedAggrId = atom({
  key: "showL4selectedAggrId",
  default: "",
});

export const showL4selectedAggrIdCompliance = atom({
  key: "showL4selectedAggrIdCompliance",
  default: "",
});

export const showL4searchTerm = atom({
  key: "showL4searchTerm",
  default: "",
});

export const showL4searchTerm2 = atom({
  key: "showL4searchTerm2",
  default: "",
});

export const showL4isVisible = atom({
  key: "showL4isVisible",
  default: false,
});

export const showL4isVisiblePopUp2 = atom({
  key: "showL4isVisiblePopUp2",
  default: false,
});

export const showL4isAnimating = atom({
  key: "showL4isAnimating",
  default: false,
});

export const showL4isVisible2 = atom({
  key: "showL4isVisible2",
  default: false,
});

export const showL4initialPath = atom({
  key: "showL4initialPath",
  default: "",
});

export interface showL4BusinessData {
  id: string;
  selected: boolean;
  process_unit_association: {
    business_process: {
      id: string;
      name: string;
      description: string;
      kind: string;
      objective: string;
    };
  };
}

export const showL4BusinessDataState = atom<showL4BusinessData[]>({
  key: "showL4BusinessDataState", // Unique ID (with respect to other atoms/selectors)
  default: [], // Default value (aka initial value)
});

export interface showL4Role {
  id: string;
  role_description: string;
  responsibility: string;
}

export interface showL4Model {
  id: string;
  organizational_unit: {
    id: string;
    prefLabel: string;
  };
  company_role: showL4Role[];
}

export const showL4ModelDataState = atom<showL4Model[]>({
  key: "showL4ModelDataState", // Unique ID (with respect to other atoms/selectors)
  default: [], // Default value (aka initial value)
});

export const showL4RoleState = atom<showL4Role[]>({
  key: "showL4RoleState", // Unique ID (with respect to other atoms/selectors)
  default: [], // Default value (aka initial value)
});

export interface showL4SelectedPlaceUnitAssociation {
  id: string;
  role_description: string;
  responsibility: string;
}

export const showL4SelectedPlaceUnitAssociationState = atom<showL4SelectedPlaceUnitAssociation[]>({
  key: "showL4SelectedPlaceUnitAssociationState",
  default: [],
});

export interface showL4RoleCompliance {
  id: string;
  role_description: string;
  responsibility: string;
}

export interface showL4ModelCompliance {
  id: string;
  unit: {
    id: string;
    prefLabel: string;
    compliance_responsible_assignment_start: string;
  };
  compliance_role: showL4RoleCompliance[];
}

export const showL4ModelComplianceState = atom<showL4ModelCompliance[]>({
  key: "showL4ModelComplianceState",
  default: [],
});

export const showL4RoleComplianceState = atom<showL4RoleCompliance[]>({
  key: "showL4RoleComplianceState",
  default: [],
});

export interface showL4ComplianceData {
  id: string;
  selected: boolean;
  compliance_process_unit_association: {
    compliance_process: {
      name: string;
      description: string;
    };
    process_unit_association: {
      business_process: {
        id: string;
        name: string;
      };
    };
  };
}

export const showL4ComplianceDataState = atom<showL4ComplianceData[]>({
  key: "showL4ComplianceDataState",
  default: [],
});

export interface showL4Role2 {
  id: string;
  role_description: string;
  responsibility: string;
}

export const showL4Role2State = atom<showL4Role2[]>({
  key: "showL4Role2State",
  default: [],
});

export interface showL4Model2 {
  unit: {
    id: string;
    prefLabel: string;
    compliance_responsible_assignment_start: string;
  };
  compliance_role: showL4Role2[];
}

export const showL4Model2State = atom<showL4Model2[]>({
  key: "showL4Model2State",
  default: [],
});

export const showL4selectedModelId2 = atom({
  key: "showL4selectedModelId2",
  default: "",
});

export const showL4selectedModelName2 = atom({
  key: "showL4selectedModelName2",
  default: "",
});

export type showL4PersonItem = {
  id: string;

  surname: string;
  name: string;
  has_personal_card: string;
  member_of_formal_organization: string;
  reports_to: string;
  has_smart_working_site: string;
  documents_folder_link: string;
  based_at: string;

  national_identification_number: string;
  date_of_birth: string;
  has_place_of_birth: string;
  has_primary_residence: string;
  has_postal_address: string;
  personal_email: string;
  work_email: string;
  work_mobile_phone: string;
  personal_mobile_phone: string;
  has_identity_document: string;
  has_citizenship: string;
};

export const showL4PersonItemState = atom<showL4PersonItem[]>({
  key: "showL4PersonItemState",
  default: [],
});

export const showL4selecteModelId = atom({
  key: "showL4selecteModelId",
  default: "",
});

export const showL4selecteModelName = atom({
  key: "showL4selecteModelName",
  default: "",
});

export type showL4OrganizzazioneDataItem = {
  id: string;
  role_description: string;
  responsibility: string;
  prefLabel: string;
  isResponsible: boolean;
};

export const showL4OrgDataItemState = atom<showL4OrganizzazioneDataItem[]>({
  key: "showL4OrgDataItemState",
  default: [],
});

export type OrganizzazioneApiContentItem = {
  id: string;
  company_role: {
    role_description: string;
    responsibility: string;
  };
  organizational_unit: {
    prefLabel: string;
  };

  isResponsible: string;
};

export interface showL4OrgRole {
  role_description: string;
  responsibility: string;
  isResponsible: boolean;
}

export interface showL4OrgModel {
  id: string;
  isResponsible: boolean;
  organizational_unit: {
    prefLabel: string;
  };
  company_role: {
    id: string;
    role_description: string;
    responsibility: string;
  };
}

export const showL4OrgRoleState = atom<showL4OrgRole | null>({
  key: "showL4OrgRoleState",
  default: null,
});

export const showL4OrgModelState = atom<showL4OrgModel[]>({
  key: "showL4OrgModelState",
  default: [],
});

export interface SelectedPlaceUnitAssociationInfrastruttura {
  id: string;
  placeId: string;
  siteName: string;
  placeName: string;
  planRef: string;
}

export const showL4SelectedPlaceUnitAssociationInfrastrutturaState = atom<SelectedPlaceUnitAssociationInfrastruttura[]>({
  key: "showL4SelectedPlaceUnitAssociationInfrastrutturaState",
  default: [],
});

export interface showL4InfrDataItem {
  id: string;
  name: string;
  hasUnit: string;
  CategoryPath: string;
}

export const showL4InfrDataItemState = atom<showL4InfrDataItem[]>({
  key: "showL4InfrDataItemState",
  default: [],
});
