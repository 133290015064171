import dayjs from "dayjs";
import React, { lazy, Suspense, useCallback, useContext, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useRecoilState } from "recoil";
import { isLoadingRec, isMessageBoxVisibleClosingRec, isMessageBoxVisibleRec, reFetchIndicatorL4, SelectedL4ID, setErrorRec, setMessageRec } from "../../../../recoil";
import { OverlayContext } from "../cybersecurity-board-contestoleadership-organizzazione";

import SchedaGenerale from "./cybersecurity-board-org-org-L4-Edit-sub/organigramma-L4-Edit-1-SchedaGenerale";
import Organizzazione, { useFetchAndSetSelectedRoles } from "./cybersecurity-board-org-org-L4-Edit-sub/organigramma-L4-Edit-2-Organizzazione";
import Parti from "./cybersecurity-board-org-org-L4-Edit-sub/organigramma-L4-Edit-3-Infrastruttura";
import Processi from "./cybersecurity-board-org-org-L4-Edit-sub/organigramma-L4-Edit-4-Processi";
import Compliance from "./cybersecurity-board-org-org-L4-Edit-sub/organigramma-L4-Edit-6-GestioneCompliance";
import Documentazione, { useInitiateUpload } from "./cybersecurity-board-org-org-L4-Edit-sub/organigramma-L4-Edit-7-Documentazione";
const Infrastruttura = lazy(() => import("./cybersecurity-board-org-org-L4-Edit-sub/organigramma-L4-Edit-3-Infrastruttura").then((module) => ({ default: module.default })));

import { ButtonGrey, ButtonText } from "../../styled-components";

import {
  Backdrop,
  BackdropTransparent,
  CloseMessageButton,
  CreaSubMenuButtonMultiple,
  FrameTopGradient100,
  MessageboxText,
  MessageContainer,
  OverlayComponentBackgroundAdd1,
  OverlayComponentWhiteAdd1,
  OverlayComponentWhiteButtonFrameAdd1,
  OverlayComponentWhiteTitleAdd1,
  SubMenuButtonContainerOrganigrammaMultipleShort,
} from "./cybersecurity-board-organizzazione-organigramma-styled-components";

import { URL_GetPatchPersonRoles, URL_LV4List_short, URL_UnitProcessRolePerson, useApi, useToken } from "../../../../API";
import {
  editL4AttachedFilesInfo,
  editL4birthDate,
  editL4birthPlace,
  editL4DocumentsAttached,
  editL4DocumentsPath,
  editL4domicileAddress,
  editL4fiscalCode,
  editL4gender,
  editL4Name,
  editL4OrganizzazioneDirettoreID,
  editL4OrganizzazioneSelectedL1,
  editL4OrganizzazioneSelectedL1ID,
  editL4PersonalCardId,
  editL4personalEmail,
  editL4PersonID,
  editL4privatePhone,
  editL4residenceAddress,
  editL4selectedAggrId,
  editL4selectedRolesByModel,
  editL4Surname,
  editL4Title,
  editL4workEmail,
  editL4workPhone,
  selectedCheckboxesByUnitState
} from "./L4-Edit-recoil";

export const useHandleSave = () => {
  const [isLoading, setIsLoading] = useRecoilState(isLoadingRec);
  const [error, setError] = useRecoilState(setErrorRec);
  const [isMessageBoxVisible, setIsMessageBoxVisible] = useRecoilState(isMessageBoxVisibleRec);
  const [message, setMessage] = useRecoilState(setMessageRec);

  const { closeOverlay } = useContext(OverlayContext);
  const { patch } = useApi();

  const [personIdPost, setPersonIdPost] = useRecoilState<string>(editL4PersonID);

  const [AttachedFilesInfo, setAttachedFilesInfo] = useRecoilState(editL4AttachedFilesInfo);
  const [DocumentsAttached, setDocumentsAttached] = useRecoilState(editL4DocumentsAttached);
  const [DocumentsPath, setDocumentsPath] = useRecoilState(editL4DocumentsPath);

  const [surname, setSurname] = useRecoilState(editL4Surname);
  const [name, setName] = useRecoilState(editL4Name);

  const [birthDate, setBirthDate] = useRecoilState(editL4birthDate);
  const formattedBirthDate = birthDate ? birthDate.format("DD-MM-YYYY") : null;

  const [birthPlace, setBirthPlace] = useRecoilState(editL4birthPlace);
  const [gender, setGender] = useRecoilState(editL4gender);
  const [fiscalCode, setFiscalCode] = useRecoilState(editL4fiscalCode);
  const [residenceAddress, setResidenceAddress] = useRecoilState(editL4residenceAddress);
  const [domicileAddress, setDomicileAddress] = useRecoilState(editL4domicileAddress);
  const [workEmail, setWorkEmail] = useRecoilState(editL4workEmail);
  const [personalEmail, setPersonalEmail] = useRecoilState(editL4personalEmail);
  const [workPhone, setWorkPhone] = useRecoilState(editL4workPhone);
  const [privatePhone, setPrivatePhone] = useRecoilState(editL4privatePhone);
  const [personalCardID, setPersonalCardID] = useRecoilState(editL4PersonalCardId);
  const [directorId, setDirectorId] = useRecoilState(editL4OrganizzazioneDirettoreID);
  const [L4ID, setL4ID] = useRecoilState(SelectedL4ID);
  const [LV1ListId, setLV1ListId] = useRecoilState(editL4OrganizzazioneSelectedL1ID);
  const handleSave = async () => {
    if (!surname || !name || !fiscalCode) {
      setMessage("Si prega di compilare tutti i campi obbligatori: nome, cognome e codice fiscale.");
      setIsMessageBoxVisible(true);
      return;
    }

    const newData = {
      id: L4ID,

      surname: surname,
      name: name,
      has_personal_card: personalCardID,
      member_of_formal_organization: "organizations.ecubit02",
      reports_to: directorId,

      documents_folder_link: fiscalCode,
      based_at: LV1ListId,
      personal_card: {
        id: personalCardID,

        national_identification_number: fiscalCode,
        date_of_birth: formattedBirthDate,
        has_place_of_birth: birthPlace,
        has_primary_residence: residenceAddress,
        has_postal_address: domicileAddress,
        personal_email: personalEmail,
        work_email: workEmail,
        work_mobile_phone: workPhone,
        personal_mobile_phone: privatePhone,
        has_identity_document: "",
        has_citizenship: gender,
      },
    };

    try {
      setIsLoading(true);
      console.log(JSON.stringify(newData));
      const responseData = await patch(URL_LV4List_short, newData);

      console.log("Person edited successfully:", responseData);
      setMessage("Person edited successfully!");
      setIsMessageBoxVisible(true);
    } catch (error) {
      console.error("Error editing person:", error);
      setMessage(`Error editing person: ${error instanceof Error ? error.message : "Unknown error"}`);
      setIsMessageBoxVisible(true);
    } finally {
      setIsLoading(false);
    }
  };

  return { handleSave };
};

interface Role {
  content: {
    companyRoleId: string;
    selected: boolean;
  };
}

interface OrganizationRoles {
  [organizationalUnitId: string]: Role[];
}

const useHandleSaveRoles = () => {
  const [L4ID, setL4ID] = useRecoilState(SelectedL4ID);

  const [selectedRolesByModel, setSelectedRolesByModel] = useRecoilState(editL4selectedRolesByModel);
  const { patch, get } = useApi();

  const [isLoading, setIsLoading] = useRecoilState(isLoadingRec);
  const [error, setError] = useRecoilState(setErrorRec);
  const [isMessageBoxVisible, setIsMessageBoxVisible] = useRecoilState(isMessageBoxVisibleRec);
  const [message, setMessage] = useRecoilState(setMessageRec);
  const token = useToken();
  useFetchAndSetSelectedRoles(token);

  const updateRolesSelection = async () => {
    setIsLoading(true);
    setError(null);

    try {
      const rolesResponse = await get(`${URL_GetPatchPersonRoles}${L4ID}`);
      console.log("Roles API Response:", rolesResponse);

      const rolesData: OrganizationRoles = rolesResponse;

      let changesDetected = false;
      console.log(`RolesData: `, rolesData);

      const updatedRolesData: OrganizationRoles = {};

      Object.entries(rolesData).forEach(([orgId, rolesArray]) => {
        updatedRolesData[orgId] = rolesArray.map((role) => ({
          ...role,
          selected: selectedRolesByModel[orgId] === role.content.companyRoleId,
        }));
      });

      const saveResponse = await patch(`${URL_GetPatchPersonRoles}${L4ID}`, updatedRolesData);

      console.log(`Sending updated role data: `, updatedRolesData);
      setMessage("Roles updated successfully!");
      setIsMessageBoxVisible(true);

      console.log("Updated roles successfully:", saveResponse);
    } catch (error) {
      console.error("Error updating roles:", error);
      setError(`Error updating roles: ${error instanceof Error ? error.message : "Unknown error"}`);
      setIsMessageBoxVisible(true);
    } finally {
      setIsLoading(false);
    }
  };

  return { updateRolesSelection };
};

const usePatchProcessUnitRolePerson = (token: string | null | undefined) => {
  const [selectedCheckboxes, setSelectedCheckboxes] = useRecoilState(selectedCheckboxesByUnitState);
  const [selectedAggrId, setSelectedAggrId] = useRecoilState(editL4selectedAggrId);

  const fetchDataAndPatch = useCallback(async () => {
    const url = `${URL_UnitProcessRolePerson}${selectedAggrId}`;
    try {
      const response = await fetch(url, {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      });
      if (!response.ok) throw new Error("Failed to fetch data");
      const data = await response.json();
      console.log(`Received body to modify :`, data);

      const processedData = data.map((item: any) => {
        const isSelected = selectedCheckboxes[item.content.process_unit_association.has_unit]?.includes(item.content.process_unit_association.has_business_process);
        return { ...item, selected: isSelected };
      });

      const patchResponse = await fetch(url, {
        method: "PATCH",
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
        body: JSON.stringify(processedData),
      });
      console.log(`Sending modified data :`, JSON.stringify(processedData));
      if (!patchResponse.ok) throw new Error("Failed to patch data");
      return;
    } catch (error) {
      console.error("Error fetching and patching data:", error);
    }
  }, [selectedCheckboxes, token, selectedAggrId]);

  return fetchDataAndPatch;
};

const MainComponent = () => {
  const token = useToken();

  const [personIdPost, setPersonIdPost] = useRecoilState<string>(editL4PersonID);
  const [Title, setTitle] = useRecoilState(editL4Title);

  const [AttachedFilesInfo, setAttachedFilesInfo] = useRecoilState(editL4AttachedFilesInfo);
  const [DocumentsAttached, setDocumentsAttached] = useRecoilState(editL4DocumentsAttached);
  const [DocumentsPath, setDocumentsPath] = useRecoilState(editL4DocumentsPath);

  const [surname, setSurname] = useRecoilState(editL4Surname);
  const [name, setName] = useRecoilState(editL4Name);
  const [birthDate, setBirthDate] = useRecoilState(editL4birthDate);
  const [birthPlace, setBirthPlace] = useRecoilState(editL4birthPlace);
  const [gender, setGender] = useRecoilState(editL4gender);
  const [fiscalCode, setFiscalCode] = useRecoilState(editL4fiscalCode);
  const [residenceAddress, setResidenceAddress] = useRecoilState(editL4residenceAddress);
  const [domicileAddress, setDomicileAddress] = useRecoilState(editL4domicileAddress);
  const [workEmail, setWorkEmail] = useRecoilState(editL4workEmail);
  const [personalEmail, setPersonalEmail] = useRecoilState(editL4personalEmail);
  const [workPhone, setWorkPhone] = useRecoilState(editL4workPhone);
  const [privatePhone, setPrivatePhone] = useRecoilState(editL4privatePhone);
  const [LV1List, setLV1List] = useRecoilState(editL4OrganizzazioneSelectedL1);
  const [LV1ListId, setLV1ListId] = useRecoilState(editL4OrganizzazioneSelectedL1ID);

  const [selectedRolesByModel, setSelectedRolesByModel] = useRecoilState(editL4selectedRolesByModel);

  const { handleSave } = useHandleSave();
  const { updateRolesSelection } = useHandleSaveRoles();

  const initiateUpload = useInitiateUpload();

  const [reFetchIndicator, setRefetchIndicator] = useRecoilState(reFetchIndicatorL4);

  const { closeOverlay } = useContext(OverlayContext);

  const navigate = useNavigate();

  const [isLoading, setIsLoading] = useRecoilState(isLoadingRec);
  const [error, setError] = useRecoilState(setErrorRec);
  const [isMessageBoxVisible, setIsMessageBoxVisible] = useRecoilState(isMessageBoxVisibleRec);
  const [isMessageBoxVisibleClosing, setIsMessageBoxVisibleClosing] = useRecoilState(isMessageBoxVisibleClosingRec);

  const [message, setMessage] = useRecoilState(setMessageRec);

  const handleCloseAndNavigate = () => {
    setIsMessageBoxVisible(false);
    closeOverlay();
  };

  const handleCloseMessage = () => {
    setIsMessageBoxVisible(false);
  };

  const patchProcessUnitRolePerson = usePatchProcessUnitRolePerson(token);

  const handleSaveAndUpload = async () => {
    await handleSave();
    await updateRolesSelection();
    await patchProcessUnitRolePerson();
    initiateUpload();
    setRefetchIndicator((prev) => prev + 1);
  };

  const handleClose = () => {
    closeOverlay();
    setIsMessageBoxVisible(false);
    setAttachedFilesInfo([]);
    setDocumentsAttached([]);
    setDocumentsPath("");

    setSurname("");
    setName("");
    setBirthDate(dayjs());
    setBirthPlace("");
    setGender("");
    setFiscalCode("");
    setResidenceAddress("");
    setDomicileAddress("");
    setWorkEmail("");
    setPersonalEmail("");
    setWorkPhone("");
    setPrivatePhone("");
    setLV1List("");
    setLV1ListId("");
  };

  const [activeComponent, setActiveComponent] = useState("SchedaGenerale");

  const changeActiveComponent = (componentName: string) => {
    setActiveComponent(componentName);
  };

  const renderActiveComponent = () => {
    switch (activeComponent) {
      case "SchedaGenerale":
        return <SchedaGenerale />;
      case "Organizzazione":
        return <Organizzazione />;
      case "Infrastruttura":
        return <Infrastruttura />;
      case "ProcessiGenerali":
        return <Processi />;
      case "PartiInteressate":
        return <Parti />;
      case "SistemaCompliance":
        return <Compliance />;
      case "Documentazione":
        return <Documentazione />;
      default:
        return <SchedaGenerale />;
    }
  };

  return (
    <OverlayComponentBackgroundAdd1>
      {isLoading && (
        <BackdropTransparent>
          <MessageContainer>
            <MessageboxText>Loading...</MessageboxText>
          </MessageContainer>
        </BackdropTransparent>
      )}

      {isMessageBoxVisible && (
        <Backdrop>
          <MessageContainer>
            <p>
              <MessageboxText>{message}</MessageboxText>
            </p>
            <CloseMessageButton onClick={handleClose}>
              <MessageboxText>Close</MessageboxText>
            </CloseMessageButton>
          </MessageContainer>
        </Backdrop>
      )}

      {isMessageBoxVisibleClosing && (
        <Backdrop>
          <MessageContainer>
            <p>
              <MessageboxText>{message}</MessageboxText>
            </p>
            <CloseMessageButton onClick={handleCloseMessage}>
              <MessageboxText>Close</MessageboxText>
            </CloseMessageButton>
          </MessageContainer>
        </Backdrop>
      )}

      {error && (
        <Backdrop>
          <MessageContainer>
            <p>
              <MessageboxText>{error}</MessageboxText>
            </p>
            <CloseMessageButton
              onClick={() => {
                setIsMessageBoxVisible(false);
                setError(null);
                closeOverlay();
              }}
            >
              <MessageboxText>Close</MessageboxText>
            </CloseMessageButton>
          </MessageContainer>
        </Backdrop>
      )}

      <FrameTopGradient100 />
      <OverlayComponentWhiteTitleAdd1>MODIFICA SCHEDA PERSONALE DI {Title.toUpperCase()}</OverlayComponentWhiteTitleAdd1>
      <OverlayComponentWhiteAdd1>
        <SubMenuButtonContainerOrganigrammaMultipleShort>
          <CreaSubMenuButtonMultiple onClick={() => changeActiveComponent("SchedaGenerale")} to="/Cybersecurity/Organizzazione/Persone/ModificaL4/1">
            Scheda generale
          </CreaSubMenuButtonMultiple>
          <CreaSubMenuButtonMultiple onClick={() => changeActiveComponent("Organizzazione")} to="/Cybersecurity/Organizzazione/Persone/ModificaL4/2">
            Organizzazione
          </CreaSubMenuButtonMultiple>
          <CreaSubMenuButtonMultiple onClick={() => changeActiveComponent("Infrastruttura")} to="/Cybersecurity/Organizzazione/Persone/ModificaL4/3">
            Infrastruttura
          </CreaSubMenuButtonMultiple>
          <CreaSubMenuButtonMultiple onClick={() => changeActiveComponent("ProcessiGenerali")} to="/Cybersecurity/Organizzazione/Persone/ModificaL4/4">
            Processi generali
          </CreaSubMenuButtonMultiple>
          {/* <CreaSubMenuButtonMultiple
            onClick={() => changeActiveComponent("PartiInteressate")}
            to="/Cybersecurity/Organizzazione/UnitaOperative/CreaL2/5"
          >
            Parti interessate
          </CreaSubMenuButtonMultiple> */}
          <CreaSubMenuButtonMultiple onClick={() => changeActiveComponent("SistemaCompliance")} to="/Cybersecurity/Organizzazione/Persone/ModificaL4/6">
            Sistema gestione Compliance
          </CreaSubMenuButtonMultiple>
          <CreaSubMenuButtonMultiple onClick={() => changeActiveComponent("Documentazione")} to="/Cybersecurity/Organizzazione/Persone/ModificaL4/7">
            Documentazione
          </CreaSubMenuButtonMultiple>
        </SubMenuButtonContainerOrganigrammaMultipleShort>
        <Suspense fallback={<div>Loading...</div>}>{renderActiveComponent()}</Suspense>
      </OverlayComponentWhiteAdd1>

      <OverlayComponentWhiteButtonFrameAdd1>
        <ButtonGrey onClick={handleClose}>
          <ButtonText>Annulla</ButtonText>
        </ButtonGrey>
        <ButtonGrey onClick={handleSaveAndUpload}>
          <ButtonText>Salva</ButtonText>
        </ButtonGrey>
      </OverlayComponentWhiteButtonFrameAdd1>
    </OverlayComponentBackgroundAdd1>
  );
};

export default MainComponent;
