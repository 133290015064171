import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { Accordion, AccordionDetails, AccordionSummary, Box, Checkbox, Divider, FormControl, FormControlLabel, FormLabel, Grid, Radio, RadioGroup, Typography } from "@mui/material";
import React, { useCallback, useContext, useEffect, useState } from "react";
import styled from "styled-components";
import { OverlayContext } from "../../cybersecurity-board-contestoleadership-organizzazione";

import { useRecoilState } from "recoil";
import {
  SelectedL4ID,
  addL2OrganizzazioneDH,
  addL2OrganizzazioneDirettore,
  addL2OrganizzazioneDirettoreID,
  addL2OrganizzazioneModello,
  addL2OrganizzazioneOrdinaria,
  addL2OrganizzazionePersonale,
  addL2OrganizzazioneSelectedL1,
  addL2OrganizzazioneSelectedL1ID,
  isLoadingRec,
  isMessageBoxVisibleRec,
  setErrorRec,
  setMessageRec,
} from "../../../../../recoil";
import {
  Backdrop,
  BackdropTransparent,
  CloseMessageButton,
  CreaContainerColumn,
  DescriptionContainerCreaMultiple,
  DescriptionContainerCreaText,
  FlexContainerCrea,
  IconContainer,
  IconSquare,
  InputContainer,
  InputDescriptionText,
  InputTextbox,
  InputTextboxButton,
  LastPopupButtonContainer,
  LastPopupCheckboxContainer,
  LastPopupHeaderContainer,
  LastPopupScrollbar,
  LastPopupSearch,
  LastPopupSearchIcon,
  LastPopupSearchInputContainer,
  LastPopupTitleContainer,
  MessageContainer,
  MessageboxText,
  OverlayComponentWhiteAdd1Multiple,
  TriangleContainer
} from "../cybersecurity-board-organizzazione-organigramma-styled-components";

import { URL_PersonRoles, useToken } from "../../../../../API";
import { ArrowButtonText, ButtonGrey, ButtonText } from "../../../styled-components";
import {
  OrganizzazioneApiContentItem,
  showL4ComponenteOrganizzativa,
  showL4OrgDataItemState,
  showL4OrgModel,
  showL4OrgModelState,
  showL4OrgRole,
  showL4OrgRoleState,
  showL4OrganizzazioneDataItem,
  showL4Responsibility,
  showL4RoleDescription,
  showL4RuoliEnte,
  showL4SuperioreDiretto,
  showL4UnitaOperative,
  showL4isResponsible,
  showL4prefLabel,
  showL4selecteModelId,
  showL4selecteModelName,
} from "../L4-Show-recoil";

interface LastPopupProps {
  isVisible: boolean;
}

const LastPopupBlack = styled.div<LastPopupProps>`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 99;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: opacity 0.3s ease, visibility 0.3s ease;
  opacity: ${({ isVisible }) => (isVisible ? 1 : 0)};
  visibility: ${({ isVisible }) => (isVisible ? "visible" : "hidden")};
`;

const LastPopup = styled.div`
  font-family: var(--font-titillium-web);
  font-size: var(--font-size-micro);
  color: var(--color-steelblue-700);
  position: fixed;
  right: 0px;
  top: 0px;
  bottom: 0px;
  width: 75%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: var(--color-aliceblue);

  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.25);

  z-index: 100;
`;

interface RolesAccordionProps {
  role: showL4OrgRole | null;
  model: showL4OrgModel;
}

interface ModelSelectorProps {
  models: showL4OrgModel[];
  onModelChange: (newModelId: string) => void;
}

const ModelSelector: React.FC<ModelSelectorProps> = ({ models, onModelChange }) => {
  const [selectedModelId, setSelectedModelId] = useRecoilState(showL4selecteModelId);

  return (
    <FormControl component="fieldset">
      <FormLabel component="legend" sx={{ color: "#04446c", padding: "8px" }}></FormLabel>
      <RadioGroup aria-label="model" name="model" value={selectedModelId} onChange={(event) => onModelChange(event.target.value)} sx={{ padding: "5px 10px 10px 20px" }}>
        {models.map((model) => (
          <FormControlLabel
            key={model.id}
            value={model.id}
            control={<Radio sx={{ "& .MuiSvgIcon-root": { fontSize: "1rem" } }} />}
            label={model.organizational_unit.prefLabel}
            sx={{ color: "primary.main", "& .MuiTypography-root": { fontSize: "0.875rem" } }}
          />
        ))}
      </RadioGroup>
    </FormControl>
  );
};

const RolesAccordion: React.FC<RolesAccordionProps> = ({ role, model }) => {
  if (!role) return null;

  const isRoleResponsibleChecked = model.isResponsible;

  return (
    <Box sx={{ mt: 2 }}>
      {/* <Accordion expanded={true} >*/}
      <Accordion>
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <Typography>{role.role_description}</Typography>
        </AccordionSummary>

        <AccordionDetails sx={{ flexDirection: "column" }}>
          <Divider sx={{ my: 1, marginTop: "-10px" }} />
          <Typography>{role.responsibility}</Typography>
          <FormControlLabel
            control={
              <Checkbox
                checked={isRoleResponsibleChecked}
                inputProps={{ "aria-label": "Is Responsible" }}
                disabled={true}
                sx={{
                  color: "#425a77",
                  "& .MuiSvgIcon-root": { fontSize: 16 },
                  "&.Mui-checked": { color: "#425a77" },
                  padding: "0px",
                  marginRight: "10px",
                }}
              />
            }
            label="Responsabile dell'unità selezionata"
            sx={{
              marginTop: "10px",
              marginLeft: "0px",
              color: "#425a77",
              display: "flex",
              flexDirection: "row",
              justifyContent: "flex-start",
              alignItems: "center",
            }}
            labelPlacement="end"
          />
        </AccordionDetails>
      </Accordion>
    </Box>
  );
};

const useCloseAndNavigate = () => {
  const [, setIsMessageBoxVisible] = useRecoilState(isMessageBoxVisibleRec);
  const { closeOverlay } = useContext(OverlayContext);

  const handleCloseAndNavigate = () => {
    setIsMessageBoxVisible(false);
    closeOverlay();
  };

  return handleCloseAndNavigate;
};

const useToggleVisibility = () => {
  const [isVisible, setIsVisible] = useState(false);

  const toggleVisibility = useCallback(() => {
    setIsVisible((prevIsVisible) => !prevIsVisible);
  }, []);

  return { isVisible, toggleVisibility };
};

const useFetchOrganizationalRoles = (token: string | null | undefined) => {
  const [isLoading, setIsLoading] = useRecoilState(isLoadingRec);
  const [error, setError] = useRecoilState<string | null>(setErrorRec);
  const [isMessageBoxVisible, setIsMessageBoxVisible] = useRecoilState(isMessageBoxVisibleRec);
  const [message, setMessage] = useRecoilState(setMessageRec);
  const [data, setData] = useRecoilState(showL4OrgDataItemState);

  const [L4ID, setL4ID] = useRecoilState(SelectedL4ID);
  const [roleDescription, setRoleDescription] = useRecoilState(showL4RoleDescription);
  const [prefLabel, setPrefLabel] = useRecoilState(showL4prefLabel);
  const [isResponsible, setIsResponsible] = useRecoilState(showL4isResponsible);
  const [responsibility, setResponsibility] = useRecoilState(showL4Responsibility);
  const [selectedModelId, setSelectedModelId] = useRecoilState(showL4selecteModelId);
  const [selectedModelName, setSelectedModelName] = useRecoilState(showL4selecteModelName);
  const [selectedModelRole, setSelectedModelRole] = useRecoilState(showL4OrgRoleState);

  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true);
      try {
        if (!token) throw new Error("User is not authenticated");

        const response = await fetch(`${URL_PersonRoles}${L4ID}`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });

        if (!response.ok) {
          throw new Error(`API request failed: ${response.statusText}`);
        }

        const responseData = await response.json();
        console.log("Response Data:", responseData);

        const transformedData: showL4OrganizzazioneDataItem[] = responseData.map((item: OrganizzazioneApiContentItem) => ({
          id: item.id,
          role_description: item.company_role ? item.company_role.role_description : "N/A",
          responsibility: item.company_role ? item.company_role.responsibility : "N/A",
          prefLabel: item.company_role ? item.organizational_unit.prefLabel : "N/A",
          isResponsible: item.isResponsible,
        }));

        console.log("Transformed Data:", transformedData);
        setData(transformedData);

        setIsResponsible(responseData.isResponsible);

        if (responseData.company_role) {
          setRoleDescription(responseData.company_role.role_description);
          setResponsibility(responseData.company_role.responsibility);
        } else {
          setRoleDescription("N/A");
          setResponsibility("N/A");
        }

        setIsResponsible(responseData.isResponsible);

        if (responseData.organizational_unit) {
          setPrefLabel(responseData.organizational_unit.prefLabel);
        } else {
          setPrefLabel("N/A");
        }

        if (transformedData.length > 0) {
          setSelectedModelId(transformedData[0].id);
          setSelectedModelRole(transformedData[0]);
        }
      } catch (err) {
        setError(err instanceof Error ? err.message : "An unknown error occurred");
        setIsMessageBoxVisible(true);
      } finally {
        setIsLoading(false);
      }
    };

    fetchData();
  }, [token, L4ID, setData]);
};

const useFetchOrganizationalModel = (token: string | null | undefined) => {
  const [isLoading, setIsLoading] = useRecoilState(isLoadingRec);
  const [error, setError] = useRecoilState<string | null>(setErrorRec);
  const [isMessageBoxVisible, setIsMessageBoxVisible] = useRecoilState(isMessageBoxVisibleRec);
  const [message, setMessage] = useRecoilState(setMessageRec);

  const [L4ID, setL4ID] = useRecoilState(SelectedL4ID);
  const [Modeldata, setModelData] = useRecoilState(showL4OrgModelState);
  const [selectedModelId, setSelectedModelId] = useRecoilState(showL4selecteModelId);
  const [selectedModelName, setSelectedModelName] = useRecoilState(showL4selecteModelName);

  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true);
      try {
        if (!token) throw new Error("User is not authenticated");

        const dataResponse = await fetch(`${URL_PersonRoles}${L4ID}`, {
          headers: { Authorization: `Bearer ${token}` },
        });

        if (!dataResponse.ok) {
          throw new Error(`API request failed: ${dataResponse.statusText}`);
        }

        const responseData: showL4OrgModel[] = await dataResponse.json();

        const transformedData = responseData.map((item: showL4OrgModel) => ({
          id: item.id,
          isResponsible: item.isResponsible,
          organizational_unit: {
            prefLabel: item.organizational_unit.prefLabel,
          },
          company_role: item.company_role
            ? {
                id: item.company_role.id,
                role_description: item.company_role.role_description,
                responsibility: item.company_role.responsibility,
              }
            : {
                id: "N/A",
                role_description: "N/A",
                responsibility: "N/A",
              },
        }));

        setModelData(transformedData);
        if (transformedData.length > 0) {
          setSelectedModelId(transformedData[0].id);
          setSelectedModelName(transformedData[0].organizational_unit.prefLabel);
        }
        console.log(transformedData);
      } catch (err) {
        setError(err instanceof Error ? err.message : "An unknown error occurred");
      } finally {
        setIsLoading(false);
      }
    };

    if (token && L4ID) fetchData();
  }, [token, L4ID, setModelData, setSelectedModelId]);
};

const useHandleModelChange = () => {
  const [, setSelectedModelRole] = useRecoilState(showL4OrgRoleState);
  const [, setSelectedModelId] = useRecoilState(showL4selecteModelId);
  const [Modeldata] = useRecoilState(showL4OrgModelState);

  const handleModelChange = (newModelId: string) => {
    setSelectedModelId(newModelId);

    const newModel = Modeldata.find((model) => model.id === newModelId);
    if (newModel && newModel.company_role) {
      const roleWithResponsibility = {
        id: newModel.company_role.id,
        role_description: newModel.company_role.role_description,
        responsibility: newModel.company_role.responsibility,
        isResponsible: newModel.isResponsible,
      };

      setSelectedModelRole(roleWithResponsibility);
    } else {
      setSelectedModelRole(null);
    }
  };

  return handleModelChange;
};

const MainComponent = () => {
  const token = useToken();

  const [data, setData] = useRecoilState(showL4OrgDataItemState);
  const [isLoading, setIsLoading] = useRecoilState(isLoadingRec);
  const [error, setError] = useRecoilState<string | null>(setErrorRec);
  const [isMessageBoxVisible, setIsMessageBoxVisible] = useRecoilState(isMessageBoxVisibleRec);
  const [message, setMessage] = useRecoilState(setMessageRec);

  const [LV1List, setLV1List] = useRecoilState(addL2OrganizzazioneSelectedL1);
  const [LV1ListId, setLV1ListId] = useRecoilState(addL2OrganizzazioneSelectedL1ID);
  const [director, setDirector] = useRecoilState(addL2OrganizzazioneDirettore);
  const [directorId, setDirectorId] = useRecoilState(addL2OrganizzazioneDirettoreID);
  const [Modello, setModello] = useRecoilState(addL2OrganizzazioneModello);
  const [Personale, setPersonale] = useRecoilState(addL2OrganizzazionePersonale);
  const [DH, setDH] = useRecoilState(addL2OrganizzazioneDH);
  const [Ordinaria, setOrdinaria] = useRecoilState(addL2OrganizzazioneOrdinaria);

  const [ruoliEnte, setRuoliEnte] = useRecoilState(showL4RuoliEnte);
  const [unitaOperative, setUnitaOperative] = useRecoilState(showL4UnitaOperative);
  const [superioreDiretto, setSuperioreDiretto] = useRecoilState(showL4SuperioreDiretto);
  const [componenteOrganizzativa, setComponenteOrganizzativa] = useRecoilState(showL4ComponenteOrganizzativa);
  const [roleDescription, setRoleDescription] = useRecoilState(showL4RoleDescription);
  const [prefLabel, setPrefLabel] = useRecoilState(showL4prefLabel);
  const [isResponsible, setIsResponsible] = useRecoilState(showL4isResponsible);
  const [responsibility, setResponsibility] = useRecoilState(showL4Responsibility);

  const [L4ID, setL4ID] = useRecoilState(SelectedL4ID);

  const { isVisible, toggleVisibility } = useToggleVisibility();
  const { closeOverlay } = useContext(OverlayContext);

  const closeAndNavigate = useCloseAndNavigate();

  const [selectedModelRole, setSelectedModelRole] = useRecoilState(showL4OrgRoleState);
  const [Modeldata, setModelData] = useRecoilState(showL4OrgModelState);

  const [selectedModelId, setSelectedModelId] = useRecoilState(showL4selecteModelId);
  const [selectedModelName, setSelectedModelName] = useRecoilState(showL4selecteModelName);
  const selectedModel = Modeldata.find((model) => model.id === selectedModelId);

  useFetchOrganizationalRoles(token);
  useFetchOrganizationalModel(token);

  const handleModelChange = useHandleModelChange();

  const onModelSelect = (newModelId: string) => {
    handleModelChange(newModelId);
  };

  return (
    <OverlayComponentWhiteAdd1Multiple>
      {isLoading && (
        <BackdropTransparent>
          <MessageContainer>
            <MessageboxText>Loading...</MessageboxText>
          </MessageContainer>
        </BackdropTransparent>
      )}

      {isMessageBoxVisible && (
        <Backdrop>
          <MessageContainer>
            <p>
              <MessageboxText>{message}</MessageboxText>
            </p>
            <CloseMessageButton onClick={closeAndNavigate}>
              <MessageboxText>Close</MessageboxText>
            </CloseMessageButton>
          </MessageContainer>
        </Backdrop>
      )}

      {error && (
        <Backdrop>
          <MessageContainer>
            <p>
              <MessageboxText>{error}</MessageboxText>
            </p>
            <CloseMessageButton
              onClick={() => {
                setIsMessageBoxVisible(false);
                setError(null);
                closeOverlay();
              }}
            >
              <MessageboxText>Close</MessageboxText>
            </CloseMessageButton>
          </MessageContainer>
        </Backdrop>
      )}
      <FlexContainerCrea>
        <CreaContainerColumn>
          <InputContainer>
            <InputDescriptionText>Componente organizzativa</InputDescriptionText>
            <InputTextbox placeholder="" value={componenteOrganizzativa} onChange={(e) => setComponenteOrganizzativa(e.target.value)} />
            <IconContainer />
          </InputContainer>
          <InputContainer>
            <InputDescriptionText>Superiore diretto</InputDescriptionText>
            <InputTextbox placeholder="" value={superioreDiretto} onChange={(e) => setSuperioreDiretto(e.target.value)} />
            <IconContainer />
          </InputContainer>
        </CreaContainerColumn>

        <CreaContainerColumn>
          <InputContainer>
            <InputDescriptionText>Unità operative e ruoli</InputDescriptionText>
            <InputTextboxButton onClick={toggleVisibility}>
              <ArrowButtonText>Apri per visualizzare</ArrowButtonText>
            </InputTextboxButton>
            <IconContainer />
            <TriangleContainer>▶</TriangleContainer>
          </InputContainer>
        </CreaContainerColumn>

        <LastPopupBlack isVisible={isVisible}>
          <LastPopup>
            <LastPopupTitleContainer>UNITÀ OPERATIVE E RUOLI</LastPopupTitleContainer>
            <LastPopupCheckboxContainer>
              <LastPopupHeaderContainer>
                <LastPopupSearchInputContainer>
                  <LastPopupSearch placeholder="Cerca" />
                  <LastPopupSearchIcon src="/search.svg" />
                </LastPopupSearchInputContainer>
              </LastPopupHeaderContainer>

              <LastPopupScrollbar>
                {/* MUI TreeView to visualize the data */}
                <Grid container spacing={2} sx={{ width: "100%" }}>
                  <Grid item xs={4}>
                    <Box
                      sx={{
                        backgroundColor: "#fff",
                        borderRadius: "4px",
                        boxShadow: "0 2px 4px rgba(0, 0, 0, 0.2)",
                        padding: "0px 10px 10px 5px",
                        marginTop: "16px",
                        marginLeft: "20px",
                      }}
                    >
                      <ModelSelector models={Modeldata} onModelChange={onModelSelect} />
                    </Box>
                  </Grid>
                  <Grid item xs={8}>
                    {selectedModelRole && selectedModel && <RolesAccordion role={selectedModelRole} model={selectedModel} />}
                  </Grid>
                </Grid>
              </LastPopupScrollbar>
            </LastPopupCheckboxContainer>
            <LastPopupButtonContainer>
              <ButtonGrey onClick={toggleVisibility}>
                <ButtonText>Chiudi</ButtonText>
              </ButtonGrey>
            </LastPopupButtonContainer>
          </LastPopup>
        </LastPopupBlack>
      </FlexContainerCrea>
      <DescriptionContainerCreaMultiple>
        <IconSquare src="/description_show_organization.svg" alt="Description Icon" />
        <DescriptionContainerCreaText>
          La scheda offre una visione strutturata della collocazione gerarchica e funzionale dell’entità selezionata all'interno dell’Organizzazione. Presenta la componente organizzativa di
          appartenenza, le unità operative specifiche e gli altri elementi strutturali rilevanti ai fini della gestione generale e di Compliance.
        </DescriptionContainerCreaText>
      </DescriptionContainerCreaMultiple>
    </OverlayComponentWhiteAdd1Multiple>
  );
};

export default MainComponent;
