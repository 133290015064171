import dayjs from "dayjs";
import { atom } from "recoil";

// SCHEDA GENERALE

export const AssetID = atom({
  key: "AssetID",
  default: "",
});

export const AssetName = atom({
  key: "AssetName",
  default: "",
});

export const AssetIdentificativo = atom({
  key: "AssetIdentificativo",
  default: "",
});

export const AssetNomeAbbreviato = atom({
  key: "AssetNomeAbbreviato",
  default: "",
});

export const addAssetSpecificheTecniche = atom({
  key: "addAssetSpecificheTecniche",
  default: "",
});

export const addAssetSelectedCategoryID = atom({
  key: "addAssetSelectedCategoryID",
  default: "",
});

export const addAssetAssetStatusID = atom({
  key: "addAssetAssetStatusID",
  default: "",
});

export const addAssetDirector = atom({
  key: "addAssetDirector",
  default: "",
});

export const addAssetDirectorID = atom({
  key: "addAssetDirectorID",
  default: "",
});

export const addAssetDirectorCompliance = atom({
  key: "addAssetDirectorCompliance",
  default: "",
});

export const addAssetDirectorComplianceID = atom({
  key: "addAssetDirectorComplianceID",
  default: "",
});

export const AssetComplianceDate = atom<dayjs.Dayjs | null>({
  key: "AssetComplianceDate",
  //default: dayjs(),
  default: null,
});

// DOCUMENTAZIONE

export const AssetDocumentsPath = atom({
  key: "AssetDocumentsPath",
  default: "",
});

export const AssetDocumentsAttached = atom<File[]>({
  key: "AssetDocumentsAttached",
  default: [],
});

export const AssetAttachedFilesInfo = atom<Array<{ name: string; size: number; type: string } | null>>({
  key: "AssetAttachedFilesInfo",
  default: [],
});

// ORGANIZZAZIONE

export type DirectorDataItem = {
  director_id: string;
  director_name: string;
};
export const directorDataState = atom<DirectorDataItem[]>({
  key: "directorDataState", // Unique ID (with respect to other atoms/selectors)
  default: [], // Default value (aka initial value)
});

export type LV1DataItem = {
  LV1_id: string;
  LV1_name: string;
};

export const LV1DataItemState = atom<LV1DataItem[]>({
  key: "LV1DataItemState", // Unique ID (with respect to other atoms/selectors)
  default: [], // Default value (aka initial value)
});

export const AssetOrganizzazioneSelectedL1 = atom({
  key: "AssetOrganizzazioneSelectedL1",
  default: "",
});

export const AssetOrganizzazioneSelectedL1ID = atom({
  key: "AssetOrganizzazioneSelectedL1ID",
  default: "",
});

export const addAsset_2_isDropdownOpenLV1 = atom({
  key: "addAsset_2_isDropdownOpenLV1",
  default: false,
});

export const addAssetConfirmedLV1List = atom<string>({
  key: "addAssetConfirmedLV1List", // Unique ID (with respect to other atoms/selectors)
  default: "", // Updated default value to be an empty object
});

export const addAsset_2_isDropdownOpenDirectors = atom<boolean>({
  key: "addAsset_2_isDropdownOpenDirectors",
  default: false,
});

export const addAssetOrganizzazioneDirettore = atom({
  key: "addAssetOrganizzazioneDirettore",
  default: "",
});

export const addAssetOrganizzazioneDirettoreID = atom({
  key: "addAssetOrganizzazioneDirettoreID",
  default: "",
});

export const addAssetOrganizzazioneDirettoreCompliance = atom({
  key: "addAssetOrganizzazioneDirettoreCompliance",
  default: "",
});

export const addAssetOrganizzazioneDirettoreComplianceID = atom({
  key: "addAssetOrganizzazioneDirettoreComplianceID",
  default: "",
});

export const addAsset_2_isDropdownOpenDirectorsCompliance = atom<boolean>({
  key: "addAsset_2_isDropdownOpenDirectorsCompliance",
  default: false,
});

export const addAssetDeleteConfirmationDialog = atom<boolean>({
  key: "addAssetDeleteConfirmationDialog",
  default: false,
});

export interface TreeNode {
  value: {
    id: string;
    name: string;
  };
  leaves?: TreeNode[];
}

export const treeDataState = atom<TreeNode[]>({
  key: "treeDataState", // Unique ID (with respect to other atoms/selectors)
  default: [], // Default value (aka initial value)
});

export const addAssetLV1List = atom({
  key: "addAssetLV1List",
  default: "",
});

export const addAssetLV1ListID = atom({
  key: "addAssetLV1ListID",
  default: "",
});

export const addAssetLV2List = atom({
  key: "addAssetLV2List",
  default: "",
});

export const addAssetLV2ListID = atom({
  key: "addAssetLV2ListID",
  default: "",
});

export const addAssetLV3List = atom({
  key: "addAssetLV3List",
  default: "",
});

export const addAssetLV3ListID = atom({
  key: "addAssetLV3ListID",
  default: "",
});

export const AssetDeleteConfirmationDialog = atom<boolean>({
  key: "AssetDeleteConfirmationDialog",
  default: false,
});

export const AssetSelectedCategoryNode = atom({
  key: "AssetSelectedCategoryNode",
  default: "",
});

// PROCESSI

export interface addAssetComplianceData {
  id: string;
  name: string;
  kind: string;
  objective: string;
  description: string;
}

export const addAssetComplianceDataState = atom<addAssetComplianceData[]>({
  key: "addAssetComplianceDataState", // Unique ID (with respect to other atoms/selectors)
  default: [], // Default value (aka initial value)
});

export interface addAssetRole {
  id: string;
  role_description: string;
  responsibility: string;
}

export interface addAssetModel {
  id: string;
  organizational_unit: {
    id: string;
    prefLabel: string;
  };
  company_role: addAssetRole[];
}

export const addAssetModelDataState = atom<addAssetModel[]>({
  key: "addAssetModelDataState",
  default: [],
});

export const addAssetRoleState = atom<addAssetRole[]>({
  key: "addAssetRoleState",
  default: [],
});

export const addAssetSearchTermProcessi = atom({
  key: "addAssetSearchTermProcessi",
  default: "",
});

export const addAssetSelectedAggrId = atom({
  key: "addAssetSelectedAggrId",
  default: "",
});

type CheckboxProcessiIdType = string;

export const addAssetselectedCheckboxesProcessiState = atom<CheckboxProcessiIdType[]>({
  key: "addAssetselectedCheckboxesProcessiState",
  default: [], // default to an empty array
});

export const addAssetSelectedModelId = atom({
  key: "addAssetSelectedModelId",
  default: "",
});

export const addAssetSelectedModelName = atom({
  key: "addAssetSelectedModelName",
  default: "",
});
