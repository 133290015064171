import React, { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { OverlayContext } from "../cybersecurity-board-contestoleadership-organizzazione";

import { useRecoilState } from "recoil";

import {
  addL1AttachedFilesInfo,
  addL1ComplianceDate,
  addL1ComplianceDirettoreCompliance,
  addL1ComplianceDirettoreComplianceID,
  addL1DocumentsAttached,
  addL1DocumentsPath,
  addL1OrganizzazioneDirettore,
  addL1OrganizzazioneDirettoreID,
  addL1SchedaGeneraleFax,
  addL1SchedaGeneraleIndirizzo,
  addL1SchedaGeneraleNomeAbbreviato,
  addL1SchedaGeneraleNomeCompleto,
  addL1SchedaGeneralePEC,
  addL1SchedaGeneraleTelefono,
  TemplaterComplianceRole,
  TemplaterComplianceRoleId,
  TemplaterComplianceRoleResponsibility,
  TemplaterDate,
  TemplaterDateEnd,
  TemplaterDateStart,
  TemplaterDirector,
  TemplaterDirectorId,
  TemplaterDirectory,
  TemplaterDocN,
  TemplaterName,
  TemplaterSurname,
} from "../../../../recoil";

import SchedaGenerale from "../cybersecurity-board-documentazione-sub/cybersecurity-board-documentazione-letteraincarico-Add-sub/cybersecurity-board-documentazione-letteraincarico-Add-1";

import { ButtonGrey, ButtonText } from "../../styled-components";

import { URL_DirectorList, URL_DocGen_Lettera, URL_DocNumber, useToken } from "../../../../API";
import {
  Backdrop,
  BackdropTransparent,
  CloseMessageButton,
  CreaSubMenuButtonMultiple,
  FrameTopGradient100,
  MessageboxText,
  MessageContainer,
  OverlayComponentBackgroundAdd1,
  OverlayComponentWhiteAdd1,
  OverlayComponentWhiteButtonFrameAdd1,
  OverlayComponentWhiteTitleAdd1,
  SubMenuButtonContainerOrganigrammaMultipleShort,
} from "../cybersecurity-board-contestoleadership-organizzazione-sub/cybersecurity-board-organizzazione-organigramma-styled-components";

const useApi = () => {
  const token = useToken();

  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);

  const post = async (url: string, data: any) => {
    if (!token) {
      throw new Error("User is not authenticated");
    }
    try {
      const response = await fetch(url, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify(data),
      });

      let responseData;
      try {
        responseData = await response.json();
      } catch (parseError) {
        if (response.ok) {
          return null;
        } else {
          throw new Error(`API request failed: ${response.statusText}`);
        }
      }

      if (!response.ok) {
        console.error("Error response data:", responseData);

        throw new Error(`API request failed: ${response.statusText}`, responseData);
      }

      return responseData;
    } catch (error) {
      throw error;
    }
  };

  const get = async (url: string) => {
    if (!token) throw new Error("User is not authenticated");

    const response = await fetch(url, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });

    if (!response.ok) {
      throw new Error(`API request failed: ${response.statusText}`);
    }

    return await response.json();
  };

  return { post, get };
};

const YourAddL1Component = () => {
  const { closeOverlay } = useContext(OverlayContext);

  const navigate = useNavigate();

  const token = useToken();
  const [data, setData] = useState<DirectorDataItem[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const [isMessageBoxVisible, setIsMessageBoxVisible] = useState(false);
  const [message, setMessage] = useState("");

  const { post } = useApi();

  const [directorLV1, setDirectorLV1] = useRecoilState(addL1OrganizzazioneDirettore);
  const [directorIdLV1, setDirectorIdLV1] = useRecoilState(addL1OrganizzazioneDirettoreID);
  const [directorComplianceLV1, setDirectorComplianceLV1] = useRecoilState(addL1ComplianceDirettoreCompliance);
  const [directorComplianceIdLV1, setDirectorComplianceIdLV1] = useRecoilState(addL1ComplianceDirettoreComplianceID);
  const [complianceDateLV1, setComplianceDateLV1] = useRecoilState(addL1ComplianceDate);
  const [FaxLV1, setFaxLV1] = useRecoilState(addL1SchedaGeneraleFax);
  const [IndirizzoLV1, setIndirizzoLV1] = useRecoilState(addL1SchedaGeneraleIndirizzo);
  const [NomeAbbreviatoLV1, setNomeAbbreviatoLV1] = useRecoilState(addL1SchedaGeneraleNomeAbbreviato);
  const [NomeCompletoLV1, setNomeCompletoLV1] = useRecoilState(addL1SchedaGeneraleNomeCompleto);
  const [PECLV1, setPECLV1] = useRecoilState(addL1SchedaGeneralePEC);
  const [TelefonoLV1, setTelefonoLV1] = useRecoilState(addL1SchedaGeneraleTelefono);

  const [AttachedFileInfoLV1, setAttachedFileInfoLV1] = useRecoilState(addL1AttachedFilesInfo);
  const [DocumentsAttachedLV1, setDocumentsAttachedLV1] = useRecoilState(addL1DocumentsAttached);
  const [DocumentsPathLV1, setDocumentsPathLV1] = useRecoilState(addL1DocumentsPath);

  const [date, setDate] = useRecoilState(TemplaterDate);
  const [dateStart, setDateStart] = useRecoilState(TemplaterDateStart);
  const [dateEnd, setDateEnd] = useRecoilState(TemplaterDateEnd);
  const [DocN, setDocN] = useRecoilState(TemplaterDocN);
  const [Name, setName] = useRecoilState(TemplaterName);
  const [Surname, setSurname] = useRecoilState(TemplaterSurname);
  const [ComplianceRole, setComplianceRole] = useRecoilState(TemplaterComplianceRole);
  const [ComplianceRoleId, setComplianceRoleId] = useRecoilState(TemplaterComplianceRoleId);
  const [ComplianceRoleResponsibility, setComplianceRoleResponsibility] = useRecoilState(TemplaterComplianceRoleResponsibility);
  const [Director, setDirector] = useRecoilState(TemplaterDirector);
  const [DirectorId, setDirectorId] = useRecoilState(TemplaterDirectorId);
  const [Directory, setDirectory] = useRecoilState(TemplaterDirectory);

  async function fetchAndIncrementDocNumber() {
    const response = await fetch(URL_DocNumber, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });

    if (!response.ok) {
      throw new Error("Failed to fetch the current document number");
    }
    const currentNumberJson = await response.json();
    const currentDocumentNumber = currentNumberJson.document_number;

    const incrementResponse = await fetch(`${URL_DocNumber}/increment`, {
      method: "PATCH",

      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    });

    if (!incrementResponse.ok) {
      throw new Error("Failed to increment the document number");
    }

    return currentDocumentNumber;
  }

  function downloadFile(blob: Blob, fileName: string): void {
    const url = window.URL.createObjectURL(blob);
    const a = document.createElement("a");
    a.href = url;
    a.download = fileName;
    document.body.appendChild(a);
    a.click();
    window.URL.revokeObjectURL(url);
    a.remove();
  }

  async function generateAndDownloadLetter(templateFields: TemplateFields) {
    const apiUrl = URL_DocGen_Lettera;
    const documentFolderLink = Directory;
    const fileName = `lettera_di_incarico_${templateFields.person_surname}${templateFields.person_name}${templateFields.compliance_role_name}_${templateFields.document_n}.docx`;

    console.log("Template Fields: ", templateFields);
    console.log("File Name: ", fileName);
    console.log("Document Folder Link: ", documentFolderLink);

    try {
      const requestBody = JSON.stringify({
        ...templateFields,
      });

      console.log("Request Body: ", requestBody);

      const response = await fetch(`${apiUrl}?dir=${encodeURIComponent(documentFolderLink)}&file=${fileName}`, {
        method: "POST",

        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        body: requestBody,
      });

      console.log("Response Status: ", response.status);
      console.log("Response Status Text: ", response.statusText);
      console.log(`Link: ${apiUrl}?dir=${encodeURIComponent(documentFolderLink)}&file=${fileName}`);

      if (!response.ok) {
        const errorDetail = response.headers.get("X-Error-Detail");
        const errorMsg = errorDetail ? `${response.statusText}: ${errorDetail}` : response.statusText;
        throw new Error(`Error: ${errorMsg}`);
      }

      const blob = await response.blob();
      downloadFile(blob, fileName);
    } catch (error) {
      console.error("Failed to generate or download the document:", error);
    }
  }

  interface TemplateFields {
    person_name: string;
    person_surname: string;
    compliance_role_name: string;
    compliance_role_responsibility: string;
    compliance_role_assignment_start: string;
    compliance_role_assignment_end: string;
    date: string;
    document_n: string;
  }

  async function prepareAndGenerateDocument() {
    try {
      const documentNumber = await fetchAndIncrementDocNumber();
      const templateFields: TemplateFields = {
        person_name: Name,
        person_surname: Surname,
        compliance_role_name: ComplianceRole,
        compliance_role_responsibility: ComplianceRoleResponsibility,
        compliance_role_assignment_start: dateStart,
        compliance_role_assignment_end: "da definire",
        date: date,
        document_n: documentNumber.toString(),
      };

      await generateAndDownloadLetter(templateFields);
    } catch (error) {
      console.error("Error preparing or generating document:", error);
    }
  }

  const handleSave = async () => {
    prepareAndGenerateDocument();
  };

  type DirectorApiContentItem = {
    id: string;
    alias: string;
  };

  type DirectorDataItem = {
    director_id: string;
    director_name: string;
  };

  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true);
      try {
        if (!token) throw new Error("User is not authenticated");

        const response = await fetch(URL_DirectorList, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });

        if (!response.ok) {
          throw new Error(`API request failed: ${response.statusText}`);
        }

        const responseData = await response.json();
        console.log("Response Data:", responseData);

        const transformedData: DirectorDataItem[] = responseData.map((item: DirectorApiContentItem) => {
          return {
            director_id: item.id,
            director_name: item.alias,
          };
        });

        console.log("Transformed Data:", transformedData);
        setData(transformedData);
      } catch (err) {
        setError(err instanceof Error ? err.message : "An unknown error occurred");
        setIsMessageBoxVisible(true);
      } finally {
        setIsLoading(false);
      }
    };

    fetchData();
  }, [token]);

  const handleCloseAndNavigate = () => {
    setIsMessageBoxVisible(false);
    closeOverlay();
  };

  const [activeComponent, setActiveComponent] = useState("SchedaGenerale");

  const changeActiveComponent = (componentName: string) => {
    setActiveComponent(componentName);
  };

  const renderActiveComponent = () => {
    switch (activeComponent) {
      case "SchedaGenerale":
        return <SchedaGenerale />;

      default:
        return <SchedaGenerale />;
    }
  };

  return (
    <OverlayComponentBackgroundAdd1>
      {isLoading && (
        <BackdropTransparent>
          <MessageContainer>
            <MessageboxText>Loading...</MessageboxText>
          </MessageContainer>
        </BackdropTransparent>
      )}

      {isMessageBoxVisible && (
        <Backdrop>
          <MessageContainer>
            <p>
              <MessageboxText>{message}</MessageboxText>
            </p>
            <CloseMessageButton onClick={handleCloseAndNavigate}>
              <MessageboxText>Close</MessageboxText>
            </CloseMessageButton>
          </MessageContainer>
        </Backdrop>
      )}

      {error && (
        <Backdrop>
          <MessageContainer>
            <p>
              <MessageboxText>{error}</MessageboxText>
            </p>
            <CloseMessageButton
              onClick={() => {
                setIsMessageBoxVisible(false);
                setError(null);
                closeOverlay();
              }}
            >
              <MessageboxText>Close</MessageboxText>
            </CloseMessageButton>
          </MessageContainer>
        </Backdrop>
      )}

      <FrameTopGradient100 />
      <OverlayComponentWhiteTitleAdd1>NUOVA LETTERA D'INCARICO</OverlayComponentWhiteTitleAdd1>
      <OverlayComponentWhiteAdd1>
        <SubMenuButtonContainerOrganigrammaMultipleShort>
          <CreaSubMenuButtonMultiple onClick={() => changeActiveComponent("SchedaGenerale")} to="/Cybersecurity/Documentazione/LetteraIncarico/CreaLetteraIncarico/1">
            Scheda generale
          </CreaSubMenuButtonMultiple>
          {/* <CreaSubMenuButtonMultiple
            onClick={() => changeActiveComponent("Organizzazione")}
            to="/Cybersecurity/Documentazione/LetteraIncarico/CreaLetteraIncarico/2"
          >
            Organizzazione
          </CreaSubMenuButtonMultiple> */}
          {/* <CreaSubMenuButtonMultiple
            onClick={() => changeActiveComponent("Infrastruttura")}
            to="/Cybersecurity/Organizzazione/Organigramma/CreaL1/3"
          >
            Infrastruttura
          </CreaSubMenuButtonMultiple>
          <CreaSubMenuButtonMultiple
            onClick={() => changeActiveComponent("ProcessiGenerali")}
            to="/Cybersecurity/Organizzazione/Organigramma/CreaL1/4"
          >
            Processi generali
          </CreaSubMenuButtonMultiple>
          <CreaSubMenuButtonMultiple
            onClick={() => changeActiveComponent("PartiInteressate")}
            to="/Cybersecurity/Organizzazione/Organigramma/CreaL1/5"
          >
            Parti interessate
          </CreaSubMenuButtonMultiple> */}
          {/* <CreaSubMenuButtonMultiple
            onClick={() => changeActiveComponent("SistemaCompliance")}
            to="/Cybersecurity/Documentazione/LetteraIncarico/CreaLetteraIncarico/6"
          >
            Sistema Compliance
          </CreaSubMenuButtonMultiple>
          <CreaSubMenuButtonMultiple
            onClick={() => changeActiveComponent("Documentazione")}
            to="/Cybersecurity/Documentazione/LetteraIncarico/CreaLetteraIncarico/7"
          >
            Documentazione
          </CreaSubMenuButtonMultiple> */}
        </SubMenuButtonContainerOrganigrammaMultipleShort>

        {renderActiveComponent()}
      </OverlayComponentWhiteAdd1>

      <OverlayComponentWhiteButtonFrameAdd1>
        <ButtonGrey onClick={closeOverlay}>
          <ButtonText>Annulla</ButtonText>
        </ButtonGrey>
        <ButtonGrey onClick={handleSave}>
          <ButtonText>Crea</ButtonText>
        </ButtonGrey>
      </OverlayComponentWhiteButtonFrameAdd1>
    </OverlayComponentBackgroundAdd1>
  );
};

export default YourAddL1Component;
