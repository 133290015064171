// recoilState.js
import { atom, selector } from 'recoil';
import dayjs from 'dayjs';

/////////////////////////// GLOBAL

export const setErrorRec = atom<string | null>({
  key: 'setError', 
  default: null,
});

export const isLoadingRec = atom<boolean>({
  key: 'isLoadingRec', 
  default: false,
});

export const isMessageBoxVisibleRec = atom<boolean>({
  key: 'isMessageBoxVisibleRec', 
  default: false,
});

export const isMessageBoxVisibleClosingRec = atom<boolean>({
  key: 'isMessageBoxVisibleClosingRec', 
  default: false,
});

export const setMessageRec = atom<string>({
  key: 'setMessage', 
  default: '',
});

////////////////////////////ADD L1 SCHEDA GENERALE

export const addL1SchedaGeneraleNomeCompleto = atom({
  key: 'addL1SchedaGeneraleNomeCompleto', 
  default: '',
});

export const addL1SchedaGeneraleNomeAbbreviato = atom({
  key: 'addL1SchedaGeneraleNomeAbbreviato', 
  default: '',
});

export const addL1SchedaGeneraleIndirizzo = atom({
  key: 'addL1SchedaGeneraleIndirizzo', 
  default: '',
});

export const addL1SchedaGeneraleTelefono = atom({
  key: 'addL1SchedaGeneraleTelefono', 
  default: '',
});

export const addL1SchedaGeneraleFax = atom({
  key: 'addL1SchedaGeneraleFax', 
  default: '',
});

export const addL1SchedaGeneralePEC = atom({
  key: 'addL1SchedaGeneralePEC', 
  default: '',
});

//ADD L1 ORGANIZZAZIONE

export const addL1OrganizzazioneDirettore = atom({
  key: 'addL1OrganizzazioneDirettore', 
  default: '',
});

export const addL1OrganizzazioneDirettoreID = atom({
  key: 'addL1OrganizzazioneDirettoreID', 
  default: '',
});

//ADD L1 COMPLIANCE

export const addL1ComplianceDirettoreCompliance = atom({
  key: 'addL1ComplianceDirettoreCompliance', 
  default: '',
});

export const addL1ComplianceDirettoreComplianceID = atom({
  key: 'addL1ComplianceDirettoreComplianceID', 
  default: '',
});

export const addL1ComplianceDate = atom<dayjs.Dayjs | null>({
  key: 'addL1ComplianceDate', 
  default: dayjs(), // Initialize with the current date  
});


//ADD L1 DOCUMENTS

export const addL1DocumentsPath = atom({
  key: 'addL1DocumentsPath', 
  default: '',
});

// Assuming addL1DocumentsAttached is defined to store File objects
export const addL1DocumentsAttached = atom<File[]>({
  key: 'addL1DocumentsAttached',
  default: [],
});


export const addL1AttachedFilesInfo = atom<Array<{ name: string, size: number, type: string } | null>>({
  key: 'addL1AttachedFilesInfo',
  default: [],
});

///////////////////////////////////////////////////////////////////////////////

//ADD L2 SCHEDA GENERALE

export const addL2OrganizationID = atom({
  key: 'addL2OrganizationID', 
  default: '',
});

export const addL2SchedaGeneraleNomeCompleto = atom({
  key: 'addL2SchedaGeneraleNomeCompleto', 
  default: '',
});

export const addL2SchedaGeneraleNomeAbbreviato = atom({
  key: 'addL2SchedaGeneraleNomeAbbreviato', 
  default: '',
});

export const addL2SchedaGeneraleReferente = atom({
  key: 'addL2SchedaGeneraleReferente', 
  default: '',
});

export const addL2SchedaGeneraleIndirizzo= atom({
  key: 'addL2SchedaGeneraleIndirizzo', 
  default: '',
});

export const addL2SchedaGeneraleEmail = atom({
  key: 'addL2SchedaGeneraleEmail', 
  default: '',
});

export const addL2SchedaGeneraleTelefono = atom({
  key: 'addL2SchedaGeneraleTelefono', 
  default: '',
});

export const addL2SchedaGeneraleFax = atom({
  key: 'addL2SchedaGeneraleFax', 
  default: '',
});

export const addL2SchedaGeneralePEC = atom({
  key: 'addL2SchedaGeneralePEC', 
  default: '',
});

//ADD L2 ORGANIZZAZIONE

export const addL2OrganizzazioneSelectedL1 = atom({
  key: 'addL2OrganizzazioneSelectedL1', 
  default: '',
});

export const addL2OrganizzazioneSelectedL1ID = atom({
  key: 'addL2OrganizzazioneSelectedL1ID', 
  default: '',
});


export const addL2OrganizzazioneDirettore = atom({
  key: 'addL2OrganizzazioneDirettore', 
  default: '',
});

export const addL2OrganizzazioneDirettoreID = atom({
  key: 'addL2OrganizzazioneDirettoreID', 
  default: '',
});

export const addL2OrganizzazioneModello = atom({
  key: 'addL2OrganizzazioneModello', 
  default: '',
});

export const addL2OrganizzazionePersonale = atom({
  key: 'addL2OrganizzazionePersonale', 
  default: '',
});

export const addL2OrganizzazioneDH = atom({
  key: 'addL2OrganizzazioneDH', 
  default: '',
});

export const addL2OrganizzazioneOrdinaria = atom({
  key: 'addL2OrganizzazioneOrdinaria', 
  default: '',
});

//ADD L2 INFRASTRUTTURA

export const selectedItemsAsset = atom<string[]>({
  key: 'selectedItemsAsset', // Unique ID (with respect to other atoms/selectors)
  default: [], // Default value (aka initial value)
});

//ADD L2 COMPLIANCE

export const addL2ComplianceDirettoreCompliance = atom({
  key: 'addL2ComplianceDirettoreCompliance', 
  default: '',
});

export const addL2ComplianceDirettoreComplianceID = atom({
  key: 'addL2ComplianceDirettoreComplianceID', 
  default: '',
});

export const addL2ComplianceDate = atom<dayjs.Dayjs | null>({
  key: 'addL2ComplianceDate', 
  default: dayjs(), // Initialize with the current date  
});

//ADD L2 DOCUMENTS

export const addL2DocumentsPath = atom({
  key: 'addL2DocumentsPath', 
  default: '',
});

// Assuming addL2DocumentsAttached is defined to store File objects
export const addL2DocumentsAttached = atom<File[]>({
  key: 'addL2DocumentsAttached',
  default: [],
});


export const addL2AttachedFilesInfo = atom<Array<{ name: string, size: number, type: string } | null>>({
  key: 'addL2AttachedFilesInfo',
  default: [],
});

/////////////////////////////////////////////////ADD L4





// GENERAL





//SET SELECTED L1/L2/L3/L4

export const SelectedL1ID = atom({
  key: 'SelectedL1ID', 
  default: '',
});

export const SelectedL2ID = atom({
  key: 'SelectedL2ID', 
  default: '',
});

export const SelectedL3ID = atom({
  key: 'SelectedL3ID', 
  default: '',
});

export const SelectedL4ID = atom({
  key: 'SelectedL4ID', 
  default: '',
});

//ADD GESTION COMPLIANCE SELECTED CHECKBOXES

export const selectedCheckboxesCompliance = atom<string[]>({
  key: 'selectedCheckboxesCompliance', 
  default: [], 
});


//DOCUMENTAZIONE TEMPLATER

export const TemplaterDate = atom({
  key: 'TemplaterDate', 
  default: '', // Initialize with the current date  
});

export const TemplaterDateStart = atom({
  key: 'TemplaterDateStart', 
  default: '',
});


export const TemplaterDateEnd = atom({
  key: 'TemplaterDateEnd', 
  default: 'da definire',
});

export const TemplaterName = atom({
  key: 'TemplaterName', 
  default: '',
});

export const TemplaterSurname = atom({
  key: 'TemplaterSurname', 
  default: '',
});

export const TemplaterDocN = atom({
  key: 'TemplaterDocN', 
  default: '',
});

export const TemplaterComplianceRole= atom({
  key: 'TemplaterComplianceRole', 
  default: '',
});

export const TemplaterComplianceRoleId= atom({
  key: 'TemplaterComplianceRoleId', 
  default: '',
});

export const TemplaterComplianceRoleResponsibility= atom({
  key: 'TemplaterComplianceRoleResponsibility', 
  default: '',
});

export const TemplaterDirector= atom({
  key: 'TemplaterDirector', 
  default: '',
});

export const TemplaterDirectorId= atom({
  key: 'TemplaterDirectorId', 
  default: '',
});

export const TemplaterDirectory= atom({
  key: 'TemplaterDirectory', 
  default: '',
});

// TOOLS

export const reFetchIndicatorL4 = atom<number>({
  key: 'reFetchIndicatorL4', 
  default: 0,
});

export const reFetchIndicatorPlaces = atom<number>({
  key: 'reFetchIndicatorPlaces', 
  default: 0,
});

export const reFetchIndicatorBusinessProcesses = atom<number>({
  key: 'reFetchIndicatorBusinessProcesses', 
  default: 0,
});
export const reFetchIndicatorBusinessProcessesCompliance = atom<number>({
  key: 'reFetchIndicatorBusinessProcessesCompliance', 
  default: 0,
});

export const reFetchIndicatorComplianceProcesses = atom<number>({
  key: 'reFetchIndicatorComplianceProcesses', 
  default: 0,
});

export const reFetchIndicatorUnits = atom<number>({
  key: 'reFetchIndicatorUnits', 
  default: 0,
});

export const reFetchIndicatorObligations = atom<number>({
  key: 'reFetchIndicatorObligations', 
  default: 0,
});

export const reFetchIndicatorComplianceRoles = atom<number>({
  key: 'reFetchIndicatorComplianceRoles', 
  default: 0,
});

export const reFetchIndicatorParties = atom<number>({
  key: 'reFetchIndicatorParties', 
  default: 0,
});


export const reFetchIndicatorL3 = atom<number>({
  key: 'reFetchIndicatorL3', 
  default: 0,
});


export const reFetchIndicatorAssetsList = atom<number>({
  key: 'reFetchIndicatorAssetsList', 
  default: 0,
});

export const reFetchIndicatorL1 = atom<number>({
  key: 'reFetchIndicatorL1', 
  default: 0,
});

export const reFetchIndicatorL2 = atom<number>({
  key: 'reFetchIndicatorL1', 
  default: 0,
});






