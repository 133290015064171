import { Accordion, AccordionDetails, AccordionSummary, Box, Checkbox, FormControlLabel, Grid, Typography } from "@mui/material";
import Divider from "@mui/material/Divider";
import React, { useCallback, useContext, useEffect, useMemo, useRef, useState } from "react";
import { useRecoilState } from "recoil";
import styled from "styled-components";
import { isLoadingRec, isMessageBoxVisibleRec, SelectedL2ID, setErrorRec, setMessageRec } from "../../../../../recoil";
import { OverlayContext } from "../../cybersecurity-board-contestoleadership-organizzazione";
import {
  Backdrop,
  BackdropTransparent,
  CloseMessageButton,
  CreaContainerColumn,
  DescriptionContainerCreaMultiple,
  DescriptionContainerCreaText,
  FlexContainerCrea,
  IconContainer,
  IconSquare,
  InputContainer,
  InputDescriptionText,
  InputTextboxButton,
  LastPopupButtonContainer,
  LastPopupCheckboxContainer,
  LastPopupHeaderContainer,
  LastPopupScrollbar,
  LastPopupSearch,
  LastPopupSearchIcon,
  LastPopupSearchInputContainer,
  LastPopupTitleContainer,
  MessageboxText,
  MessageContainer,
  OverlayComponentWhiteAdd1Multiple,
  TriangleContainer
} from "../cybersecurity-board-organizzazione-organigramma-styled-components";

import { URL_ProcessList, URL_ProcessPost, useToken } from "../../../../../API";
import { ArrowButtonText, ButtonGrey, ButtonText } from "../../../styled-components";

import { TAB_DESCRIPTION_GLOBAL, TAB_DESCRIPTION_L2_4_SHOW } from "../../tabs-description";
import {
  addL2ComplianceData,
  addL2ComplianceDataState,
  addL2ModelDataState,
  addL2RoleState,
  addL2searchTerm,
  addL2selectedModelId,
  L2ShowProcess,
  showL2ProcessDataState,
  showL2selectedCheckboxesProcessiState
} from "../L2-Add-recoil";

interface RolesAccordionProps {
  token: string | null | undefined;
  unitId: string;
}

interface LastPopupProps {
  isVisible: boolean;
}

const LastPopupBlack = styled.div<LastPopupProps>`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 99;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: opacity 0.3s ease, visibility 0.3s ease;
  opacity: ${({ isVisible }) => (isVisible ? 1 : 0)};
  visibility: ${({ isVisible }) => (isVisible ? "visible" : "hidden")};
`;

const LastPopup = styled.div`
  font-family: var(--font-titillium-web);
  font-size: var(--font-size-micro);
  color: var(--color-steelblue-700);
  position: fixed;
  right: 0px;
  top: 0px;
  bottom: 0px;
  width: 75%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: var(--color-aliceblue);

  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.25);

  z-index: 100;
`;

interface Props {
  token: string | null | undefined;
  unitId: string;
}

interface UseProcessCheckboxesReturn {
  processes: L2ShowProcess[];
  selectedCheckboxes: string[];
  isLoading: boolean;
  error: string | null;
}
export const useProcessCheckboxes = ({ token, unitId }: Props): UseProcessCheckboxesReturn => {
  const [processes, setProcesses] = useRecoilState(showL2ProcessDataState);
  const [selectedCheckboxes, setSelectedCheckboxes] = useRecoilState(showL2selectedCheckboxesProcessiState);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    const fetchProcesses = async () => {
      setIsLoading(true);
      setError(null);
      try {
        const response = await fetch(`${URL_ProcessPost}/${unitId}`, {
          headers: { Authorization: `Bearer ${token}` },
        });
        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }
        const data: L2ShowProcess[] = await response.json();
        setProcesses(data);
        console.log("Show selected processes: ", data);
        const selectedIds = data.filter((process) => process.selected).map((process) => process.content.has_business_process);
        setSelectedCheckboxes(selectedIds);
        console.log(selectedIds);
      } catch (err) {
        setError(err instanceof Error ? err.message : "An unknown error occurred");
      } finally {
        setIsLoading(false);
      }
    };

    if (token && unitId) {
      fetchProcesses();
    }
  }, [token, unitId, setProcesses, setSelectedCheckboxes]);

  return { processes, selectedCheckboxes, isLoading, error };
};

const RolesAccordion: React.FC<RolesAccordionProps> = ({ token, unitId }) => {
  const { complianceData } = useFetchProcessData(token);
  const [isLoading, setIsLoading] = useRecoilState(isLoadingRec);
  const [error, setError] = useRecoilState<string | null>(setErrorRec);
  const [searchTerm, setSearchTerm] = useRecoilState(addL2searchTerm);
  const [L2ID, setL2ID] = useRecoilState(SelectedL2ID);

  const { processes, selectedCheckboxes } = useProcessCheckboxes({
    token,
    unitId,
  });

  const sortedProcesses = useMemo(() => {
    const processesCopy = [...processes];
    return processesCopy.sort((a, b) => {
      const aSelected = selectedCheckboxes.includes(a.content.id) ? 1 : 0;
      const bSelected = selectedCheckboxes.includes(b.content.id) ? 1 : 0;
      return bSelected - aSelected;
    });
  }, [processes, selectedCheckboxes]);

  const filteredProcessData = useMemo(
    () =>
      searchTerm
        ? sortedProcesses.filter(
            (dataItem) =>
              dataItem.content.business_process.name.toLowerCase().includes(searchTerm.toLowerCase()) ||
              dataItem.content.business_process.description.toLowerCase().includes(searchTerm.toLowerCase()) ||
              dataItem.content.business_process.kind.toLowerCase().includes(searchTerm.toLowerCase()) ||
              dataItem.content.business_process.objective.toLowerCase().includes(searchTerm.toLowerCase())
          )
        : sortedProcesses,
    [sortedProcesses, searchTerm]
  );

  return (
    <Box sx={{ mt: 2, ml: 4, width: "100%" }}>
      {filteredProcessData.length > 0 ? (
        filteredProcessData.map((process, index) => (
          <Accordion key={index} expanded={true}>
            <AccordionSummary
              sx={{
                alignItems: "center",
                fontWeight: 500,
                fontStyle: "italic",
                marginBottom: "-10px",
              }}
            >
              <Typography>{process.content.business_process?.name}</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Divider />
              <Box sx={{ mt: 1 }}>
                <Typography>
                  <br />
                </Typography>

                <Typography>Categoria: {process.content.business_process?.kind}</Typography>
                <Typography>
                  <br />
                </Typography>
                <Typography>Obiettivo: {process.content.business_process?.objective}</Typography>
                <Typography>
                  <br />
                </Typography>
                <Typography>Descrizione: {process.content.business_process?.description}</Typography>
                <Typography>
                  <br />
                </Typography>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={selectedCheckboxes.includes(process.content.has_business_process)}
                      inputProps={{ "aria-label": "Is Responsible" }}
                      disabled={true}
                      sx={{
                        color: "#04446c",
                        "& .MuiSvgIcon-root": { fontSize: 16 },
                        "&.Mui-checked": { color: "#04446c" },
                        padding: "0px",
                        marginRight: "10px",
                      }}
                    />
                  }
                  label="Questo processo é associato all'unità selezionata"
                  sx={{
                    marginTop: "10px",
                    marginLeft: "0px",
                    color: "#04446c",
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "flex-start",
                    alignItems: "center",
                  }}
                  labelPlacement="end"
                />
              </Box>
            </AccordionDetails>
          </Accordion>
        ))
      ) : (
        <Typography sx={{ textAlign: "center", width: "100%", my: 2 }}>Nessun dato esistente.</Typography>
      )}
    </Box>
  );
};

const useFetchProcessData = (token: string | null | undefined) => {
  const [complianceData, setComplianceData] = useRecoilState(addL2ComplianceDataState);
  const [isLoading, setIsLoading] = useRecoilState(isLoadingRec);
  const [error, setError] = useRecoilState(setErrorRec);
  const [selectedCheckboxes, setSelectedCheckboxes] = useRecoilState(showL2selectedCheckboxesProcessiState);
  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true);
      setError(null);

      try {
        const response = await fetch(URL_ProcessList, {
          headers: { Authorization: `Bearer ${token}` },
        });
        if (!response.ok) {
          throw new Error(`API request failed: ${response.statusText}`);
        }
        const data = await response.json();
        setComplianceData(data);
      } catch (err) {
        setError(err instanceof Error ? err.message : "An unknown error occurred");
      } finally {
        setIsLoading(false);
      }
    };

    if (token) {
      fetchData();
    }
  }, [token, setIsLoading, setError, setComplianceData]);

  return { complianceData };
};

const groupComplianceByBusinessProcess = (complianceData: addL2ComplianceData[]) => {
  return complianceData.reduce((acc, item) => {
    const bpId = item.id;
    if (!acc[bpId]) {
      acc[bpId] = [];
    }
    acc[bpId].push(item);
    return acc;
  }, {} as { [key: string]: addL2ComplianceData[] });
};

const useHandleModelChange = () => {
  const [Modeldata, setModelData] = useRecoilState(addL2ModelDataState);
  const [, setSelectedModelId] = useRecoilState(addL2selectedModelId);
  const [, setSelectedModelRoles] = useRecoilState(addL2RoleState);

  const handleModelChange = useCallback(
    (newModelId: string) => {
      setSelectedModelId(newModelId);

      const newModel = Modeldata.find((model) => model.organizational_unit.id === newModelId);
      if (newModel) {
        setSelectedModelRoles(newModel.company_role);
      } else {
        setSelectedModelRoles([]);
      }
    },
    [Modeldata, setSelectedModelId, setSelectedModelRoles]
  );

  return handleModelChange;
};

interface SearchInputProps {
  value: string;
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
}

const SearchInput: React.FC<SearchInputProps> = React.memo(({ value, onChange }) => {
  const inputRef = useRef<HTMLInputElement>(null);

  return <LastPopupSearch ref={inputRef} type="text" value={value} onChange={onChange} placeholder="Cerca" />;
});

const MainComponent = () => {
  const [L2ID, setL2ID] = useRecoilState(SelectedL2ID);
  const token = useToken();
  const [isLoading, setIsLoading] = useRecoilState(isLoadingRec);
  const [error, setError] = useRecoilState<string | null>(setErrorRec);
  const [isMessageBoxVisible, setIsMessageBoxVisible] = useRecoilState(isMessageBoxVisibleRec);
  const [message, setMessage] = useRecoilState(setMessageRec);
  const [searchTerm, setSearchTerm] = useRecoilState(addL2searchTerm);
  const handleModelChange = useHandleModelChange();
  const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchTerm(event.target.value);
  };

  const { closeOverlay } = useContext(OverlayContext);

  const handleCloseAndNavigate = () => {
    setIsMessageBoxVisible(false);
    closeOverlay();
  };

  const [isVisible, setIsVisible] = useState(false);
  const togglePopup = () => {
    setIsVisible(!isVisible);
  };

  return (
    <OverlayComponentWhiteAdd1Multiple>
      {isLoading && (
        <BackdropTransparent>
          <MessageContainer>
            <MessageboxText>Loading...</MessageboxText>
          </MessageContainer>
        </BackdropTransparent>
      )}

      {isMessageBoxVisible && (
        <Backdrop>
          <MessageContainer>
            <p>
              <MessageboxText>{message}</MessageboxText>
            </p>
            <CloseMessageButton onClick={handleCloseAndNavigate}>
              <MessageboxText>Close</MessageboxText>
            </CloseMessageButton>
          </MessageContainer>
        </Backdrop>
      )}

      {error && (
        <Backdrop>
          <MessageContainer>
            <p>
              <MessageboxText>{error}</MessageboxText>
            </p>
            <CloseMessageButton
              onClick={() => {
                setIsMessageBoxVisible(false);
                setError(null);
                closeOverlay();
              }}
            >
              <MessageboxText>Close</MessageboxText>
            </CloseMessageButton>
          </MessageContainer>
        </Backdrop>
      )}
      <FlexContainerCrea>
        <CreaContainerColumn>
          {/*       
        <InputContainer>
            <InputDescriptionText>Ruoli</InputDescriptionText>
            <InputTextboxButton onClick={togglePopup2} >

            <ArrowButtonText>Clicca per visualizzare</ArrowButtonText>
            </InputTextboxButton>
            <IconContainer />
            <TriangleContainer>▶</TriangleContainer>
          </InputContainer> */}

          <InputContainer>
            <InputDescriptionText>Processi generali</InputDescriptionText>
            <InputTextboxButton onClick={togglePopup}>
              <ArrowButtonText>Apri per visualizzare</ArrowButtonText>
            </InputTextboxButton>
            <IconContainer />
            <TriangleContainer>▶</TriangleContainer>
          </InputContainer>
        </CreaContainerColumn>

        <CreaContainerColumn></CreaContainerColumn>

        <LastPopupBlack isVisible={isVisible}>
          <LastPopup>
            <LastPopupTitleContainer>LISTA PROCESSI GENERALI</LastPopupTitleContainer>
            <LastPopupCheckboxContainer>
              <LastPopupHeaderContainer>
                <LastPopupSearchInputContainer>
                  <SearchInput value={searchTerm} onChange={handleSearchChange} />
                  <LastPopupSearchIcon src="/search.svg" />
                </LastPopupSearchInputContainer>
              </LastPopupHeaderContainer>

              <LastPopupScrollbar>
                <Grid container spacing={2} sx={{ width: "100%" }}>
                  {/* <Grid item xs={4}>
                    <Box
                      sx={{
                        backgroundColor: "#fff",  
                        borderRadius: "4px",  
                        boxShadow: "0 2px 4px rgba(0, 0, 0, 0.2)",  
                        padding: "0px 10px 10px 5px",  
                        marginTop: "16px",
                        marginLeft: "20px",
                      }}
                    >
                      <ModelSelector models={Modeldata} onModelChange={handleModelChange} selectedModelId={selectedModelId} />
                    </Box>
                  </Grid> */}

                  <RolesAccordion token={token} unitId={L2ID} />
                </Grid>
              </LastPopupScrollbar>
            </LastPopupCheckboxContainer>
            <LastPopupButtonContainer>
              <div
                style={{
                  display: "flex",
                  gap: "20px",
                  justifyContent: "center",
                }}
              >
                <ButtonGrey onClick={togglePopup}>
                  <ButtonText>Chiudi</ButtonText>
                </ButtonGrey>

                {/* <ButtonGrey onClick={togglePopup}>
                  <ButtonText>Salva</ButtonText>
                </ButtonGrey> */}
              </div>
            </LastPopupButtonContainer>
          </LastPopup>
        </LastPopupBlack>
      </FlexContainerCrea>
      <DescriptionContainerCreaMultiple>
        <IconSquare src="/description_show_process.svg" alt="Description Icon" />
        <DescriptionContainerCreaText>{TAB_DESCRIPTION_L2_4_SHOW ? TAB_DESCRIPTION_L2_4_SHOW : TAB_DESCRIPTION_GLOBAL}</DescriptionContainerCreaText>
      </DescriptionContainerCreaMultiple>
    </OverlayComponentWhiteAdd1Multiple>
  );
};

export default MainComponent;
